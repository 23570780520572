const state = {
  workspaces: [
    {
      id: 'workspace1',
      title: 'Workspace 1',
      group: 'standard',
      template: 'oil', // 4 cards
      data: [
        {
          key: 'dms33', // ng
          feeds: [],
          column: [],
        },
        {
          key: 'dmsww5',
        },
        {
          key: 'ssss2',
        },
        {
          key: 'ssss2',
        },
      ],
    },
    {
      id: 'workspace2',
      title: 'Workspace 2',
      group: 'standard',
      template: 'gas', // two cards
      data: [
        { key: 'dms33' },
        { key: 'dmsww5' },
      ],
    },
    {
      id: 'workspace3',
      title: 'Workspace 3',
      group: 'standard',
      template: 'power',
      data: [
        { key: 'dms33' },
      ],
    },
    {
      id: 'workspace5',
      title: 'Workspace 5',
      group: 'custom',
      widgetsLayout: 'threeCards',
      filledWidgetCount: 1,
      widgets: {
        firstCard: {
          id: 'widget1',
          name: 'a tab',
          type: 'chart',
        },
      },
    }],
};
export default state;

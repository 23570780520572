
const BubbleTypeText = {
    DATA: "Data",
    QA: "QA",
    FORMULA: "Formula",
    SAVE: "Publish",
    NOTIFICATION: "Notification",
    SINGLE_VAR: "Variable",
    AMQP_TASK: "AMQP Task"
};

export default BubbleTypeText;

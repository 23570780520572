
import { EnumBase, EnumItem } from './enums.es6';

/**
 * @namespace
 * @alias WorkflowHistoryJobStatus
 */
const JobStatus = EnumBase.finalize(/** @lends {WorkflowHistoryJobStatus} */ {
  COMPLETED: new EnumItem('COMPLETED'),
  NOT_AVAILABLE: new EnumItem('NOT_AVAILABLE'),
  RUNNING: new EnumItem('RUNNING'),
  NO_NEW_DATA: new EnumItem('NO_NEW_DATA'),
  NOT_SUBMITTED: new EnumItem('NOT_SUBMITTED'),
  SUBMITTED: new EnumItem('SUBMITTED'),
}, 'WorkflowHistoryJobStatus');

export default JobStatus;

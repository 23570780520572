const mutations = {
  SET_COMPANY_LIST: (state, list) => {
    state.companyList = list;
  },
  COMPANY_USERS: (state, users) => {
    state.companyUsers = users;
  },
};

export default mutations;

import axios from 'axios';
import { getUserName } from '@/utils/authService';
import templateString from '@/utils/stringUtils';
import getAPIConfigs from './config';


const config = getAPIConfigs();
let replacement;

const actions = {
  getUserData({ commit }) {
    const USER_NAME = getUserName();
    replacement = {
      USER_NAME,
    };
    return axios
      .get(templateString(config.API_FEED_DATASOURCES, replacement))
      .then((response) => {
        const sources = response.data.map(item => item.name);
        commit('setSources', sources);
        return response;
      })
      .catch(errors => errors);
  },
  getFeedData({ commit }, sourceName) {
    const SOURCE_NAME = sourceName;
    replacement = {
      SOURCE_NAME,
    };
    const url = templateString(config.API_FEEDS, replacement);
    return axios
      .get(url)
      .then((response) => {
        const feeds = response.data.filter(e => !e.toLowerCase().includes('retired_'));
        commit('setFeeds', feeds);
        return response;
      })
      .catch(errors => errors);
  },
  getKeyData({ commit }, payload) {
    const FEED_NAME = payload.feedName;
    const PAGE = payload.pageNo;
    replacement = {
      FEED_NAME,
      PAGE,
    };
    const url = templateString(config.API_KEYS, replacement);
    return axios
      .get(url)
      .then((response) => {
        const totalItems = Number(response.data.pop()['totRecords']);
        commit('setTotalItems', totalItems);
        const keysList = response.data;
        keysList.forEach((obj) => {
          obj['feedName'] = FEED_NAME;
        });
        commit('setKeys', keysList);
        const arr = [];
        commit('setDataPreview', arr);
        return response;
      })
      .catch((errors) => {
        // temporarily commits until staging data is fixed from trailing comma
        const arr = [];
        commit('setTotalItems', 0);
        commit('setKeys', arr);
        commit('setColumns', arr);
        commit('setMetaData', arr);
        commit('setDataPreview', arr);
        return errors;
      });
  },
  getColData({ commit }, feedName) {
    const FEED_NAME = feedName;
    replacement = {
      FEED_NAME,
    };
    return axios
      .get(templateString(config.API_COLS, replacement))
      .then((response) => {
        commit('setColumns', response.data);
        return response;
      })
      .catch(errors => errors);
  },
  getMetadataKeys({ commit }, payload) {
    replacement = {
      FEED_NAME: payload.feedName,
    };
    const url = templateString(config.API_METADATA, replacement);
    return axios
      .get(url, {
        params: {
          keyValue: payload.keys,
        },
      })
      .then((response) => {
        commit('setMetaData', response.data);
        return response;
      })
      .catch(error => error.response);
  },
  getDataPreviewKeys({ commit }, payload) {
    replacement = {
      FEED_NAME: payload.feedName,
    };
    const url = templateString(config.API_DATAPREVIEW, replacement);
    return axios
      .get(url, {
        params: {
          keyValue: payload.timeseries,
        },
      })
      .then((response) => {
        if (response.data.length === 0) {
          const arr = [];
          const obj = {
            date: 'No Date Preview',
            value: '',
          };
          arr.push(obj);
          commit('setDataPreview', arr);
        } else {
          commit('setDataPreview', response.data);
        }
        return response;
      })
      .catch(error => error.response);
  },
  getSearchKeyResult({ commit }, payload) {
    const requestPayLoad = {
      feed: payload.feedName,
      key: payload.key,
    };
    return axios.post(config.API_KEY_SEARCH, requestPayLoad).then((response) => {
      const keysList = response.data;
      keysList.forEach((obj) => {
        obj['feedName'] = payload.feedName;
      });
      commit('setSearchResult', keysList);
      return response;
    }).catch(error => error.response);
  },
  clearALLSections({ commit }) {
    const tempArr = [];
    commit('setClearSections', tempArr);
  },
  clearSectionsFeeds({ commit }) {
    const tempArr = [];
    commit('setClearFeedSections', tempArr);
  },
  addKeyToBasket({ commit }, basketItem) {
    commit('pushKeyToBasket', basketItem);
  },
  deleteKeyFromBasket({ commit, state }, basketItem) {
    const itemIndex = state.basket.findIndex(obj => obj.Id === basketItem.Id);
    state.basket.splice(itemIndex, 1);
    commit('removeKeyFromBasket', state.basket);
  },
  clearBasketButton({ commit }) {
    commit('clearBasket', []);
  },
  checkItemToFalse({ commit, state }, basketItem) {
    const itemIndex = state.basket.findIndex(obj => obj.Id === basketItem.Id);
    commit('changeToUnchecked', itemIndex);
  },
  updateSelectedColumns({ commit }, basketObj) {
    commit('updateSelectedCols', basketObj);
  },
};

export default actions;

<template>
  <div class="search-bar-container">
    <mds-search-field
      v-model="modelSearchField1"
      :on-dark="darkMode"
      size="small"
      placeholder="Search for Feeds"
      label="Search"
      @keyup="handleInput($event)"
      @mds-search-field-input-cleared="clearAll"
    />
  </div>
</template>
<script>
import MdsSearchField from '@mds/search-field';
import { ThemeMixin } from '../../mixins/themeMixin';

const ENTER_KEY_CODE = 13;

export default {
  name: 'ToolBar',
  components: { MdsSearchField },
  mixins: [ThemeMixin],
  data() {
    return {
      modelSearchField1: '',
    };
  },
  methods: {
    handleInput(e) {
      if (e.keyCode === ENTER_KEY_CODE) {
        this.$emit('handleSearchInputEvent', this.modelSearchField1);
      }
    },
    clearAll() {
      this.modelSearchField1 = '';
      this.$emit('clearSearchInputEvent');
    },
  },
};
</script>
<style lang="scss" scoped>
  .search-bar-container{
    text-align: left;
    margin: 10px 0;
    overflow: hidden;
    height:auto;
  }
</style>

<template>
  <div
    class="mds-form__field-group"
    :class="{ 'mds-form__field-group--error': v.$error }"
  >
    <mds-label
      :for="id"
      required
    >
      {{ title }}
    </mds-label>
    <mds-input
      :id="id"
      v-model="inputVal"
      :password="isPasswordField"
      :aria-describedby="described"
      :type="type"
      :label="id"
      :hidden-label="true"
      @focus="hideErrorAlert()"
    />
    <span
      v-show="v.$error"
      :id="described"
      class="mds-form__field-error"
      role="alert"
    >{{ errorText }}</span>
  </div>
</template>

<script>
import MdsInput from '@mds/input';

export default {
  name: 'InputField',
  components: {
    MdsInput,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    v: {
      type: Object,
      required: true,
    },
    id: { type: String, default: '' },
    title: { type: String, default: '' },
    isMorningstarPolicy: { type: Boolean, default: true },
    described: { type: String, default: '' },
    type: { type: String, default: '' },
    error: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      hasError: false,
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(value) {
        this.v.$touch();
        this.$emit('input', value);
      },
    },
    isPasswordField() {
      if (this.id === 'passwordInput' && this.type === 'password') {
        return true;
      }
      return false;
    },
    passwordText() {
      if (this.id === 'passwordInput' && this.type === 'password') {
        return 'Show';
      }
      return 'Hide';
    },
    errorText() {
      if (this.isMorningstarPolicy) {
        if (this.type === 'email') {
          if (!this.v.email) {
            return this.error.email;
          }
        } else {
          if (!this.v.minLength) {
            return this.error.minLength;
          }
          if (!this.v.maxLength) {
            return this.error.maxLength;
          }
          if (!this.v.oneDigit) {
            return this.error.oneDigit;
          }
          if (!this.v.upCaseLetter) {
            return this.error.upCaseLetter;
          }
          if (!this.v.repeatCharacter) {
            return this.error.repeatCharacter;
          }
        }
      } else if (this.type === 'email') {
        if (!this.v.email) {
          return this.error.email;
        }
      } else if (!this.v.pwdStrength) {
        return this.error.pwdStrength;
      }
      return '';
    },
  },
  methods: {
    handleShowPassword() {
      this.$emit('handleShowPassword');
    },
    hideErrorAlert() {
      this.$emit('hideErrorAlert');
    },
  },
};
</script>

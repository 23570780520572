<template>
  <mds-col
    :cols="12"
    :cols-at-m="6"
    :cols-at-l="4"
  >
    <div class="mds-module-container mcd-card-container">
      <mds-header
        border="primary"
        class=".cmd-module-card-header"
        size="level 4"
      >
        <mds-icon :name="productData.icon" />
        {{ productData.name }}
        <mds-button
          v-if="productIgnored"
          :id="`Visit_` + productData.name.replace(/ /g, '_')"
          slot="mds-header-actions"
          variation="primary"
          @click="handleCardClick(productData.urlType)"
        >
          {{ productData.urlText ? productData.urlText : 'Visit ' + productData.name + ' Site' }}
        </mds-button>
      </mds-header>
      <div>
        <p
          class="cmd-module-card-p"
          style="height: 4em"
          v-html="productData.info.length > 151 ? productData.info.substring(0, 151) + '....' : productData.info"
        />
      </div>
      <div style="height: 5em">
        <ul class="mds-list-group mds-list-group--medium mds-list-group--with-icon">
          <template v-for="(link, index) in linksList">
            <li
              v-if="link.header"
              :key="index"
              class="mds-list-group__item mds-list-group__item--header"
              v-html="link.text"
            />
            <li
              v-else
              :key="index"
              class="mds-list-group__item"
            >
              <a
                id="mds-card__list-link"
                :href="link.href"
                class="mds-list-group__link"
                target="_blank"
              >
                <mds-icon
                  :name="link.iconLeft"
                  additional-class="mds-list-group__left-icon"
                  aria-hidden="true"
                >
                  <svg
                    class="mds-icon mds-icon--s mds-list-group__left-icon"
                    aria-hidden="true"
                  >
                    <use :href="'#' + link.iconLeft" />
                  </svg>
                </mds-icon>
                <span
                  class="mds-list-group__item-text"
                  v-html="link.text"
                />
              </a>
            </li>
          </template>
        </ul>
      </div>
      <br>
      <div
        v-if="productData.componentType"
        style="float: right"
      >
        <mds-button
          id="mds-card__info-btn"
          variation="secondary"
          icon="info-circle"
          text="More Information"
          @click="moreInfoBtnClick()"
        />
      </div>
      <br>
      <div
        class="module-image"
        :style="'background: url(' + getImgUrl(productData.cardImage) + ');'"
      />
    </div>
  </mds-col>
</template>


<script>
import { MdsCol } from '@mds/layout-grid';

export default {
  name: 'McdCard',
  components: { MdsCol },
  props: {
    productData: { type: Object, default: null },
    productId: { type: String, default: '' },
    image: { type: String, default: '' },
  },
  computed: {
    linksList() {
      const { resources, skipResourcesOnCard } = this.productData;
      const linksList = [];
      Object.entries(resources).forEach((item, idx) => {
        if (skipResourcesOnCard) {
          return;
        }
        if (linksList.length > 2) {
          return;
        }
        linksList.push(this.addLink(item[1].name, null, item[1].url, true, 'large'));
        Object.entries(item[1].links).forEach((link, index) => {
          if (index > 1) {
            if (index < 3) {
              linksList.push({
                text: '... and more',
              });
            }
            return;
          }
          /* eslint-disable no-nested-ternary */
          linksList.push(
            this.addLink(
              link[1].name,
              link[1].icon
                ? link[1].icon
                : link[1].url.includes('pdf')
                  ? 'download--s'
                  : 'open-new--s',
              link[1].url,
              false,
              undefined,
              link[1].fileType,
            ),
          );
        });
      });
      return linksList;
    },
    productIgnored() {
      return this.productData.primaryButton ? true : this.productData.primaryButton === undefined;
    },
  },
  methods: {
    addLink(text, iconLeft, href, boolHeader, value, fileType) {
      return {
        text,
        iconLeft,
        href,
        header: boolHeader,
        value,
        fileType,
      };
    },
    getImgUrl(pic) {
      // eslint-disable-next-line import/no-dynamic-require
      return require(`../../assets/images/cardImages/${pic}`);
    },
    handleCardClick(urlType) {
      if (urlType && urlType.toUpperCase() === 'INTERNAL') {
        this.$router.push({
          path: this.productData.url,
        });
      } else {
        window.open(this.productData.url, '_blank');
      }
    },
    moreInfoBtnClick() {
      this.$router.push({
        path: `/all/${this.productId}`,
        params: {
          productId: this.productId,
        },
      });
    },
  },
};
</script>

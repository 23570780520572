<template>
  <div v-if="warningZone">
    <div
      id="overlayDiv"
      class="overlay-Idle"
    />
    <mds-alert
      role="alertdialog"
      title="Your session is about to expire!"
      variation="warning"
      class="div-centered-Idle"
      dismissible="false"
    >
      <p>{{ $t('labels.idleTimeout.timeoutMessage') }}<br>{{ time }} {{ $t('labels.idleTimeout.timeLeft') }}</p>
      <button
        id="stayId"
        class="mds-button mds-button--small mds-button--primary"
        type="button"
        @click="addEvents"
      >
        {{ $t('labels.idleTimeout.stay') }}
      </button>&nbsp;
      <button
        id="logoutId"
        class="mds-button mds-button--small mds-button--primary"
        type="button"
        @click="signOut"
      >
        {{ $t('labels.idleTimeout.logout') }}
      </button>
    </mds-alert>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoginLayout from '../Layouts/LoginLayout';
import EventBus from '../../main';

export default {
  name: 'IdleTimeout',
  props: {
    events: {
      type: Array,
      default: () => ['click'],
    },
    inactiveUserTime: {
      type: Number,
      default: () => 60 * 60 * 1000,
    },
    ignoreRoutes: {
      type: Array,
      default: () => [],
    },
    timeout: {
      type: Number,
      default: () => 30,
    },
  },
  data() {
    return {
      logoutTimer: null,
      warningZone: false,
      time: 0,
      routeFlag: false,
    };
  },

  watch: {
    '$route.name': {
      handler(name) {
        if (!this.ignoreRoutes.includes(name) && !this.routeFlag) {
          this.addEvents();
          this.routeFlag = true;
        } else if (this.ignoreRoutes.includes(name)) {
          this.removeEvents();
          this.routeFlag = false;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (!this.ignoreRoutes.includes(this.$route.name)) {
      this.addEvents();
      this.routeFlag = true;
    }
  },
  destroyed() {
    this.removeEvents();
  },
  methods: {
    ...mapActions(['dispatchAction']),
    setTimers() {
      this.logoutTimer = setTimeout(() => this.warningMessage(), parseInt(this.inactiveUserTime, 10)); // 15 Minutes - 14*60*1000
      this.warningZone = false;
    },
    warningMessage() {
      this.removeEvents();
      this.warningZone = true;
      this.countDown();
    },
    resetTimer() {
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
    countDown() {
      this.time = this.timeout;
      const timerId = setInterval(() => {
        this.time -= 1;
        if (!this.warningZone) {
          clearInterval(timerId);
        }
        if (this.time < 1) {
          clearInterval(timerId);
          this.$store.state.loginModule.idleTimeout = true;
          this.signOut();
        }
      }, 1000);
    },
    signOut() {
      try {
        this.warningZone = false;
        this.removeEvents();
        this.dispatchAction('signOut');
        this.$router.push({
          name: 'home',
        });
        this.$emit('update:layout', LoginLayout);
        EventBus.$emit('layoutChange', LoginLayout);
      } catch (error) {
        console.log(error);
      }
    },
    addEvents() {
      this.events.forEach((event) => {
        window.addEventListener(event, this.resetTimer);
      }, this);
      this.setTimers();
    },
    removeEvents() {
      this.events.forEach((event) => {
        window.removeEventListener(event, this.resetTimer);
      }, this);
      clearTimeout(this.logoutTimer);
    },
    eventListenerFunction() {
      console.log('New Event Added 1');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/idle_timeout';
</style>

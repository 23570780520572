<template>
  <div>
    <template v-if="currentStatus == 'SUCCESS'">
      <div class="mds-header border-bottom-solid">
        <h1 class="mds-header__title component-title">
          {{ $t('labels.feed.feedList') }}
        </h1>
        <div class="mds-header__actions">
          <div
            class="mds-search-field"
            role="search"
          >
            <input
              id="searchField"
              v-model="searchFilter"
              type="text"
              class="mds-search-field__input"
              aria-label="Search"
              :placeholder="$t('labels.feed.searchFeed')"
              @keyup="filterFeedsByName($event.target.value)"
            >
            <svg
              class="mds-icon mds-search-field__search-icon"
              aria-hidden="true"
            >
              <use xlink:href="#search--s" />
            </svg>
            <button
              class="mds-button mds-search-field__clear-button mds-button--small mds-button--icon-only"
              type="button"
              aria-label="Clear search field"
              @click="clearSearchBar()"
            >
              <svg
                class="mds-icon mds-button__icon mds-button__icon--right"
                aria-hidden="true"
              >
                <use xlink:href="#remove--s" />
              </svg>
            </button>
            <span class="mds-search-field__input-focus-outline" />
          </div>

          <router-link
            :to="{ path: 'feeds/new' }"
            class="mds-list-group__link hide-actions"
            replace
          >
            <button
              class="mds-button mds-button--flat"
              type="button"
            >
              <svg
                class="mds-icon mds-button__icon mds-button__icon--left"
                aria-hidden="true"
              >
                <use xlink:href="#plus" />
              </svg>
              <span class="mds-button__text mcd-page__content-hide--600">{{
                $t('labels.feed.newFeed')
              }}</span>
            </button>
          </router-link>
          <div class="mds-list-group__link hide-actions">
            <button
              class="mds-button mds-button--flat"
              type="button"
              @click="openPrivateFeedModal"
            >
              <svg
                class="mds-icon mds-button__icon mds-button__icon--left"
                aria-hidden="true"
              >
                <use xlink:href="#gear" />
              </svg>
              <span
                class="mds-button__text mcd-page__content-hide--600"
              >
                {{ $t('labels.feed.accessPrivateFeed') }}
              </span>
            </button>
          </div>
          <template v-if="privateFeed">
            <private-feed-access :toggle-value="toggle" />
          </template>
        </div>
      </div>
      <div style="margin-top:30px;">
        <mds-data-table
          row-hover
          size="large"
          :pagination="needsPagination"
          :pagination-config="{
            pageSizes: [25],
            pageSize: 25,
            totalItems: totalFeeds,
            showItemsInfo: true,
          }"
          :header-configs="friendlyFeed ? friendlyHeaders : headers"
          :row-data="feedPaginationList"
          @mds-data-table-sort-change="sort"
          @mds-data-table-page-change="pagenate"
        >
          <template
            v-if="totalFeeds > 0"
            #body-cell="cellData"
          >
            <template v-if="cellData.colIndex === 0">
              <span
                v-if="friendlyFeed"
                class="cursor-pointer"
                @click="goToFeedDetails(cellData.rowData.name)"
              >{{ cellData.rowData.friendlyName }}</span>

              <span
                v-else
                class="cursor-pointer"
                @click="goToFeedDetails(cellData.rowData.name)"
              >{{ cellData.rowData.name }}</span>
            </template>
          </template>
          <template
            v-else
            #body=""
          >
            <span
              id="error-id"
              class="mds-form__field-error mds-form__field-error--large"
              role="alert"
            >
              <span class="mds-form__field-error-text">
                {{ $t('labels.feed.emptyFeedResults') }}
              </span>
            </span>
          </template>
        </mds-data-table>
      </div>
    </template>
    <template v-else-if="currentStatus == 'ERROR'">
      <div class="reports-error">
        <mds-notification
          visible
          title="ERROR"
          status="error"
          dismissible="false"
        >
          {{ $t('labels.feed.feedListError') }}
        </mds-notification>
      </div>
    </template>
    <template v-else-if="currentStatus == 'LOADING'">
      <mds-loader size="large" />
    </template>
    <template v-if="showNotification">
      <div
        id="mds-notification"
        class="mds-notification mds-notification--top-center mds-notification--below-masthead mds-notification--active custom-top"
      >
        <mds-notification-group>
          <mds-notification
            ref="successDialog"
            role="alertdialog"
            status="success"
            size="small"
            dismiss-delay="10000"
            dismissible="true"
            removable="false"
            class="z-index-9999"
            @mds-notification-dismissed="hideNotification"
          >
            {{ $t('labels.feed.readAccess') }}
          </mds-notification>
        </mds-notification-group>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { MdsDataTable } from '@mds/data-table-v3';
import EventBus from '../../main';
import PrivateFeedAccess from './PrivateFeedAccess.vue';
import friendlyFeeds from '../../filters/friendlyFeedFilter';

export default {
  name: 'FeedList',
  components: { PrivateFeedAccess, MdsDataTable },
  data() {
    return {
      searchFilter: '',
      pageSize: 25,
      feedPaginationList: {},
      activeFeedList: [],
      noOfpages: 0,
      currentPage: 1,
      previousPage: '',
      currentStatus: 'LOADING',
      toggle: false,
      privateFeed: false,
      headers: [{
        fieldName: 'name',
        text: 'Feed Name',
        width: '70%',
        sortable: true,
      },
      {
        fieldName: 'createdOn',
        text: 'Date Created',
        width: '30%',
        sortable: true,
        sorted: -1,
      },
      ],
      friendlyHeaders: [{
        fieldName: 'friendlyName',
        text: 'Feed Name',
        width: '70%',
        sortable: true,
      },
      {
        fieldName: 'createdOn',
        text: 'Date Created',
        width: '30%',
        sortable: true,
        sorted: -1,
      },
      ],
      friendlyFeed: false,
      showNotification: false,
    };
  },
  computed: {
    ...mapGetters({
      feedList: 'feedModule/getPrivateFeedList',
      pageStatus: 'feedModule/getPageStatus',
    }),
    totalFeeds() {
      return this.activeFeedList.length;
    },
    needsPagination() {
      if (this.totalFeeds > 25) {
        return 'below';
      }
      return '';
    },
  },
  created() {
    this.setCompanyName();
    this.getUserFeeds().then((response) => {
      if (response.status === 204) {
        this.$router.push({
          path: '/feeds/new',
        });
      }
      this.currentStatus = this.pageStatus;
      this.activeFeedList = this.feedList;
      this.activeFeedList.forEach((o, i) => {
        o.id = i + 1;
      });
      if (this.friendlyFeed) {
        this.activeFeedList.forEach((obj) => {
          obj.friendlyName = friendlyFeeds(obj.name);
        });
      }
      this.getFeedListCountForPagination();
    });
    EventBus.$on('readAcessSuccess', (value) => {
      this.showNotification = true;
      this.$nextTick(() => {
        if (this.$refs.successDialog) {
          if (value) {
            this.getUserFeeds().then(() => {
              EventBus.$emit('hidePrivateFeedModal', false);
              this.$refs.successDialog.open();
              this.currentStatus = this.pageStatus;
              this.activeFeedList = this.feedList;
              this.activeFeedList.forEach((o, i) => {
                o.id = i + 1;
              });
              this.getFeedListCountForPagination();
            });
          } else {
            EventBus.$emit('hidePrivateFeedModal', false);
            this.$refs.successDialog.open();
          }
        }
      });
    });
  },
  methods: {
    ...mapActions('feedModule', ['getUserFeeds', 'setCompanyName']),
    goToFeedDetails(feedName) {
      this.$router.push({
        path: `${this.$route.path}/${feedName}`,
        params: {
          feedName,
        },
      });
      this.$store.commit('feedModule/SET_PAGE_STATUS', 'LOADING');
    },
    sort(sortOptions) {
      this.currentSort = sortOptions.sortField;
      this.feedPaginationList = this.activeFeedList.sort((a, b) => {
        const modifier = sortOptions.sortOrder;

        if (a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) {
          return -1 * modifier;
        }
        if (a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) {
          return 1 * modifier;
        }
        return 0;
      });
      this.getFeedListCountForPagination();
      this.getDataForParticularPage(this.currentPage);
    },
    pagenate(paginationOptions) {
      const pageNum = paginationOptions.page;
      const { pageSize } = paginationOptions;
      this.goToPage(pageNum, pageSize);
    },
    goToPage(pageNum, pageSize) {
      this.currentPage = pageNum;
      this.feedPaginationList = this.activeFeedList.filter((row, index) => {
        const start = (pageNum - 1) * pageSize;
        const end = pageNum * pageSize;
        return index >= start && index < end;
      });
    },
    getFeedListCountForPagination() {
      const feedSize = this.activeFeedList.length;
      this.noOfpages = Math.ceil(feedSize / this.pageSize);
      this.feedPaginationList = this.activeFeedList.slice(0, this.pageSize);
    },
    getDataForParticularPage(pageNum) {
      this.goToPage(pageNum, this.pageSize);
    },
    filterFeedsByName(txt) {
      this.searchFilter = txt;
      if (this.friendlyFeed) {
        this.activeFeedList = this.feedList.filter((item) => {
          const vm = this;
          return item.friendlyName.toLowerCase().includes(vm.searchFilter.toLowerCase());
        });
      } else {
        this.activeFeedList = this.feedList.filter((item) => {
          const vm = this;
          return item.name.toLowerCase().includes(vm.searchFilter.toLowerCase());
        });
      }

      this.getFeedListCountForPagination();
      if (this.currentPage > 1 && this.currentPage > this.noOfpages) {
        this.currentPage = this.noOfpages;
      }
      this.getDataForParticularPage(this.currentPage);
    },
    clearSearchBar() {
      this.searchFilter = '';
      this.filterFeedsByName('');
    },
    openPrivateFeedModal() {
      this.privateFeed = true;
      this.toggle = true;
      EventBus.$emit('toggleChange', true);
      EventBus.$emit('initPrivateModel', true);
    },
    hideNotification() {
      this.showNotification = false;
    },
  },
};
</script>

<style scoped>
.custom-top{
  top:50px;
}
</style>

import axios from 'axios';
import { formatLDSURL } from '../../server/util/stringUtils';

const Cookies = require('js-cookie');
const loggerInstance = require('../../server/logger');
const { standard } = require('../../server/logger/formats');

const logger = loggerInstance([standard]);


export function getUserName() {
  let userInfo = Cookies.get('userInfoVue');
  if (typeof userInfo !== 'undefined') {
    if (typeof userInfo === 'string') {
      userInfo = JSON.parse(userInfo);
    }

    if (userInfo.userName) {
      return userInfo.userName;
    }
    window.open('/', '_self');
  }
  return null;
}

export function getUserRoles() {
  let userInfo = Cookies.get('userInfoVue');
  if (typeof userInfo !== 'undefined') {
    if (typeof userInfo === 'string') {
      userInfo = JSON.parse(userInfo);
    }

    if (userInfo.roles) {
      return userInfo.roles;
    }
  }
  return [];
}

export function getEntitledAppFeatures() {
  let userInfo = Cookies.get('userInfoVue');
  if (typeof userInfo !== 'undefined') {
    if (typeof userInfo === 'string') {
      userInfo = JSON.parse(userInfo);
    }

    if (userInfo.features) {
      return userInfo.features;
    }
  }
  return [];
}
export function isLoggedIn() {
  return Cookies.get('loggedIn');
}

export async function removeMpCookie() {
  const res = await axios.get(formatLDSURL('/marketplace/j_spring_security_logout'), {
    withCredentials: true,
  });

  if (res.status !== 200) {
    logger.error('Unable to remove Marketplace cookies.');
  }
}

export async function removeCookies() {
  Cookies.remove('loggedIn', { path: '' });
  Cookies.remove('userInfoVue', { path: '' });
  await removeMpCookie();
}

export function getUserCompany() {
  let userInfo = Cookies.get('userInfoVue');
  if (typeof userInfo !== 'undefined') {
    if (typeof userInfo === 'string') {
      userInfo = JSON.parse(userInfo);
    }

    if (userInfo.company) {
      return userInfo.company;
    }
  }
  return '';
}

export async function setupMpCookie(token) {
  const res = await axios.get(formatLDSURL('/marketplace/account/publisher/ping'), {
    headers: {
      Authorization: `Bearer${ token}`,
    },
    withCredentials: true,
  });

  if (res.status !== 200) {
    logger.error('Unable to fetch cookie from Marketplace.');
  }
}

<template>
  <div class="mcd-page-shell__content mds-page-shell__content">
    <not-entitled-user v-if="!authorized" />
    <div v-else>
      <mds-loader
        v-show="showLoader"
        size="large"
      />
      <mds-layout-grid v-if="!showLoader">
        <div
          id="mds-notification"
          class="mds-notification mds-notification--top-center mds-notification--below-masthead
          mds-notification--active custom-top"
        >
          <mds-notification
            v-if="showErrorDialog"
            variation="error"
            title="Marketplace Login Error"
            :dismiss-delay="dialogDelay"
            size="small"
            dismissible="true"
            class="z-index-9999"
            @mds-notification-dismissed="removeNotification()"
          >
            <span v-html="$t('labels.reports.error_lable')" />
          </mds-notification>
        </div>
        <div class="border-bottom-solid">
          <mds-row>
            <mds-col :cols="8">
              <h1 class="mds-header__title component-title">
                <small>Reports</small>
              </h1>
            </mds-col>
          </mds-row>
        </div>
        <div class="custom-padding">
          <mds-row>
            <mds-col :cols="8">
              <mds-row>
                <mds-col
                  :cols-at-l="3"
                  :cols-at-m="4"
                  :cols="12"
                >
                  <label class="mds-form__label custom-margin">
                    {{ $t('labels.reports.combo_box_label') }}
                  </label>
                </mds-col>
                <mds-col
                  :cols-at-l="4"
                  :cols-at-m="5"
                  :pull="3"
                  :cols="12"
                >
                  <mds-combo-box
                    label=""
                    :data-set="companies"
                    :placeholder="$t('labels.reports.placeholder')"
                    :selected-items="selectedCompany"
                    @input="companySelected"
                  />
                </mds-col>
              </mds-row>
            </mds-col>
          </mds-row>
          <div class="padding-top">
            <mds-row>
              <mds-col>
                <mds-data-table
                  size="medium"
                  row-hover
                  :header-configs="headers"
                  :row-data="rows"
                >
                  <template #body-cell="cellData">
                    <template v-if="cellData.colIndex === 2 && cellData.rowIndex === 0">
                      <mds-button
                        id="auditButton"
                        variation="primary"
                        icon-right="caret-right"
                        :disabled="hideButton"
                        @click="runAuditReport()"
                      >
                        View
                      </mds-button>
                      <form
                        ref="report_form"
                        method="post"
                        :action="mpReportUrl"
                        target="reportWindow"
                      >
                        <input
                          type="hidden"
                          name="company_name"
                          :value="selectedCompany"
                        >
                      </form>
                    </template>
                  </template>
                </mds-data-table>
              </mds-col>
            </mds-row>
          </div>
        </div>
      </mds-layout-grid>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsLoader from '@mds/loader';
import MdsComboBox from '@mds/combo-box';
import { MdsDataTable } from '@mds/data-table-v3';
import { mapGetters, mapActions } from 'vuex';
import { MdsButton } from '@mds/button';
import { MdsNotification } from '@mds/notification';
import NotEntitledUser from '../Errors/NotEntitledUser';
import { getUserRoles, getUserCompany } from '../../utils/authService';

export default {
  name: 'Reports',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsLoader,
    MdsComboBox,
    MdsDataTable,
    MdsButton,
    MdsNotification,
    NotEntitledUser,
  },
  data() {
    return {
      companies: [],
      selectedCompany: [],
      showLoader: true,
      needsCompanyDropdown: false,
      authorized: false,
      blockMpSeamless: true,
      showErrorDialog: false,
      toggle: false,
      dialogDelay: 10000,
      mpReportUrl: String,
      headers: [
        {
          text: 'Name',
          fieldName: 'name',
          width: '17%',
        },
        {
          text: 'Description',
          fieldName: 'description',
          width: '60%',
        },
        {
          text: 'Action',
          fieldName: 'action',
          width: '23%',
        },
      ],
      rows: [
        {
          name: this.$t('labels.reports.name'),
          description: this.$t('labels.reports.description'),
          action: '',
          id: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getCompanies: 'reportsModule/getCompanyList',
      getCurrentMpUser: 'reportsModule/getCurrentMpUser',
      getCurrentUiaUser: 'homeModule/getUserName',
    }),
    showTooltip: {
      get() {
        return this.blockMpSeamless && this.toggle;
      },
      set() {
        return this.blockMpSeamless && this.toggle;
      },
    },
    hideButton() {
      return this.selectedCompany.length === 0;
    },
  },
  created() {
    this.mpReportUrl = `${process.env.VUE_APP_LDS_HOST }/marketplace/admin/reports/workflows_by_company`;
    const roles = getUserRoles();
    if (roles.includes('ROLE_LDS_CO_ADMIN') || roles.includes('ROLE_LDS_SUPPORT_ADMIN')) {
      this.authorized = true;
    }

    if (roles.includes('ROLE_LDS_SUPPORT_ADMIN')) {
      this.needsCompanyDropdown = true;
      this.getCompanyList().then(() => {
        this.companies = this.getCompanies;
        this.showLoader = false;
      });
    } else {
      const userCompany = getUserCompany();
      this.companies.push({ text: userCompany, value: userCompany });
      this.selectedCompany.push(userCompany);
      this.showLoader = false;
    }
    this.checkSeamless();
  },
  methods: {
    ...mapActions('reportsModule', ['getCompanyList', 'getAllUsersForCompany', 'getActiveMpUser']),
    companySelected(company) {
      this.selectedCompany = [];
      if (company[0]) {
        this.selectedCompany.push(company[0]);
      }
    },
    runAuditReport() {
      const route = this.$router.resolve({ name: 'View Report', params: { company: this.selectedCompany[0] } });
      window.open(route.href, '_blank');
    },
    checkSeamless() {
      this.getActiveMpUser().then(() => {
        this.blockMpSeamless = this.getCurrentUiaUser !== this.getCurrentMpUser;
      });
    },
    removeNotification() {
      this.showErrorDialog = false;
    },
    showNotification() {
      this.showErrorDialog = true;
    },
  },
};
</script>

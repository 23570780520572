import { requireNonEmptyString } from './strings.es6';
import Objects, { isObject } from './objects.es6';

/**
 * Converts a string that represents JSON into simple text, used to display key-or-roots
 * in a concise manner to users.
 *
 * This method first parses the string into a JSON Object.
 * If the object is an Array, a comma-separated list is returned.
 * If the object is an Object, its properties are sorted alphabetically,
 * then the values of these properties are returned as an comma-separated list.
 *
 * @param {string} keyOrRoots MP key (Object) or MP roots (string[]). `keyOrRoots` start
 *        with "{" or "[".
 * @returns {string} String representation of `keyOrRoots`, displayable to users.
 * @throws {TypeError} If `keyOrRoots` is not a string.
 * @throws {Error} If `keyOrRoots` does not represent a stringified JSON Object or Array.
 */

export function parameterSetKeyToString(keyOrRoots) {
  requireNonEmptyString(keyOrRoots, 'keyOrRoots');

  let json;
  try {
    json = JSON.parse(keyOrRoots);
  } catch (e) {
    throw new Error('`keyOrRoots` is unparsable');
  }

  if (Array.isArray(json)) {
    return json.join(', ');
  } if (isObject(json)) {
    return Objects.properties(json, true).map(prop => json[prop]).join(', ');
  }
  throw new Error('`keyOrRoots` does not represent Object or Array');
}


export const
        PARAM_PREFIX_SINGLE_VAR  = "udef.var.",
        PARAM_PREFIX_DATASET     = "udef.ds.",
        DATASET_NAME             = "name",
        DATASET_SUFFIX_FEED      = "feed",
        DATASET_SUFFIX_KEY_ROOTS = "key",
        DATASET_SUFFIX_COLS      = "cols",
        DATASET_SUFFIX_PROVIDER  = "prov",
        DATASET_SUFFIX_SOURCE    = "source",
        DATASET_SUFFIXES         = [
            DATASET_SUFFIX_FEED,
            DATASET_SUFFIX_KEY_ROOTS,
            DATASET_SUFFIX_COLS,
            DATASET_SUFFIX_PROVIDER,
            DATASET_SUFFIX_SOURCE
        ],
        BUBBLE_NAME               = "bubbleName",
        BUBBLE_DESCR              = "bubbleDescr",
        PARAM_SET_NAME            = "priv.name",
        PARAM_SET_DESCR           = "priv.descr",
        PARAM_SET_UUID            = "priv.uuid";
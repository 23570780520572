<template>
  <div class="view-scheduler">
    <div>
      <mds-loader
        v-show="showLoader"
        size="large"
      />
      <div>
        <div class="d-flex">
          <div class="pd-right">
            <strong class="heading-label">
              {{ scheduler.scheduleName }}
            </strong> ({{ $t('labels.schedulerManagement.scLabelMachineID') }}: {{ scheduler.machineId }})
          </div>
          <div class="sch-vs-enable">
            <mds-switch
              id="schEnabaleDisable"
              value="scheduler.enabled.toString()"
              :checked="scheduler.enabled.toString() === 'true'"
            >
              <strong>{{ scheduler.enabled === 'true' ? 'Enable' : 'Disable' }}</strong> {{ schEnabaleDisable }}
            </mds-switch>
          </div>
        </div>
        <mds-row
          v-if="scheduler.description"
          class="padding-top"
        >
          <mds-col :cols="2">
            <strong>{{ $t('labels.schedulerManagement.scLabelDescription') }}: </strong>
          </mds-col>
          <mds-col><span>{{ scheduler.description }}</span></mds-col>
        </mds-row>
        <mds-row
          class="padding-top"
          :cols="12"
        >
          <mds-col :cols="2">
            <strong>{{ $t('labels.schedulerManagement.scLabelPublistOnly') }}: </strong>
          </mds-col>
          <mds-col
            :cols="7"
            class="sch-vw-listName"
          >
            <div
              v-for="listName in scheduler.listNames"
              :key="'c' + listName"
              class="sch-vw-lst-name"
            >
              &diams; {{ listName }}
            </div>
          </mds-col>
        </mds-row>
        <mds-row
          class="padding-top"
        >
          <mds-col :cols="2">
            <strong>{{ $t('labels.schedulerManagement.scLabelSaveFile') }}: </strong>
          </mds-col>
          <mds-col>{{ scheduler.scheduleName }}_{{ scheduler.fileDateFormat }}.{{ scheduler.fileSuffix }}</mds-col>
        </mds-row>
        <mds-row
          class="padding-top"
        >
          <mds-col :cols="2">
            <strong>{{ $t('labels.schedulerManagement.scLabelFilePath') }}: </strong>
          </mds-col>
          <mds-col>{{ scheduler.fileDirectory }}</mds-col>
        </mds-row>
        <mds-row
          class="padding-top"
        >
          <mds-col :cols="2">
            <strong>{{ $t('labels.schedulerManagement.scLabelCheckData') }}: </strong>
          </mds-col>
          <mds-col>
            <span
              v-if="scheduler.newData === 'false' || scheduler.newData === false"
              class="pd-right"
            >
              <strong
                class="sch-vw-fw-400"
              >
                {{ $t('labels.schedulerManagement.scChkBack') }}: </strong> {{ scheduler.numberDaysBack }}
            </span>
            <span
              v-if="scheduler.newData === 'true' || scheduler.newData === true"
              class="pd-right"
            >
              <strong class="sch-vw-fw-400">{{ $t('labels.schedulerManagement.scOnlyChkNewData') }} </strong>
            </span>
            <span v-if="scheduler.numberOfPubDaysBack">
              <strong class="sch-vw-fw-400">
                {{ $t('labels.schedulerManagement.scLabelFPubDate') }}: </strong>{{ scheduler.numberOfPubDaysBack }}
            </span>
          </mds-col>
        </mds-row>
        <mds-row
          class="padding-top sch-dip-align"
        >
          <mds-col :cols="2">
            <strong class="sch-vw-fw-400">{{ $t('labels.schedulerManagement.scLabelRmtoPubDateTime') }}: </strong>
          </mds-col>
          <mds-col class="sch-vw-lst-name">
            {{ scheduler.disableToPubDate.toString().toUpperCase() }}
          </mds-col>
        </mds-row>
        <mds-row
          class="padding-top sch-dip-align"
        >
          <mds-col :cols="2">
            <strong class="sch-vw-fw-400">{{ $t('labels.schedulerManagement.scLabelIncHistory') }}: </strong>
          </mds-col>
          <mds-col>{{ scheduler.includeHistory.toString().toUpperCase() }}</mds-col>
        </mds-row>
        <mds-row
          class="padding-top"
        >
          <mds-col :cols="2">
            <strong>{{ $t('labels.schedulerManagement.scLabelLastValue') }}: </strong>
          </mds-col>
          <mds-col> {{ scheduler.lastValue.toString().toUpperCase() }} </mds-col>
        </mds-row>
        <mds-row
          class="padding-top"
        >
          <mds-col :cols="2">
            <strong>{{ $t('labels.schedulerManagement.scLabelFreq') }}: </strong>
          </mds-col>
          <mds-col class="d-flex">
            <div
              v-for="(freq, i) in frequency"
              :key="i"
            >
              <span
                v-if="freq.value === true"
                class="sch-vw-pd-right"
              > &diams; {{ freq.day }} </span>
            </div>
          </mds-col>
        </mds-row>
        <mds-row
          class="padding-top"
        >
          <mds-col :cols="2">
            <strong>{{ $t('labels.schedulerManagement.scLabelTime') }}: </strong>
          </mds-col>
          <mds-col class="d-flex">
            <div v-if="timeIncreament">
              <span>Every {{ checkEvery }}, between {{ tIStartAt }} - {{ tIStopAt }}.</span>
            </div>
            <div v-if="timeRange">
              At &nbsp; <span
                v-for="hrs in actualTRHours"
                :key="'A' + hrs"
                class="sch-vw-pd-right"
              > &diams; {{ hrs }}  </span>
            </div>
          </mds-col>
        </mds-row>
        <mds-row
          class="padding-top"
        >
          <mds-col :cols="2">
            <strong>{{ $t('labels.schedulerManagement.scLabelTimeZone') }}: </strong>
          </mds-col>
          <mds-col>{{ scheduler.timezone }}</mds-col>
        </mds-row>
      </div>
      <div>
        <mds-row
          class="padding-top"
        >
          <mds-col :cols="2">
            <strong>{{ $t('labels.schedulerManagement.scMsgBusInt') }}: </strong>
          </mds-col>
          <mds-col :cols="1">
            {{ scheduler.msgBusCheck.toString().toUpperCase() }}
          </mds-col>
          <mds-col
            v-if="scheduler.msgBusCheck === 'true'"
            :cols="2"
          >
            <strong>{{ $t('labels.schedulerManagement.scMsgBusType') }}: </strong>
          </mds-col>
          <mds-col
            v-if="scheduler.msgBusCheck === 'true'"
          >
            {{ scheduler.messageBusType }}
          </mds-col>
          <mds-col
            v-if="scheduler.msgBusCheck === 'true'"
            :cols="2"
          >
            <strong>{{ $t('labels.schedulerManagement.scMsgBusName') }}: </strong>
          </mds-col>
          <mds-col
            v-if="scheduler.msgBusCheck === 'true'"
          >
            {{ scheduler.busName }}
          </mds-col>
        </mds-row>
      </div>
    </div>
  </div>
</template>

<script>
import { MdsRow, MdsCol } from '@mds/layout-grid';
import MdsLoader from '@mds/loader';
import { mapGetters, mapActions } from 'vuex';
import MdsSwitch from '@mds/switch';

export default {
  name: 'ViewScheduler',
  components: {
    MdsRow,
    MdsCol,
    MdsSwitch,
    MdsLoader,
  },
  props: {
    scheduler: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showLoader: false,
      evalCorn: '',
      separatedCorn: [],
      frequency: [
        { day: 'Everyday', value: false },
        { day: 'Sunday', value: false },
        { day: 'Monday', value: false },
        { day: 'Tuesday', value: false },
        { day: 'Wednesday', value: false },
        { day: 'Trusday', value: false },
        { day: 'Friday', value: false },
        { day: 'Saturday', value: false },
      ],
      timeRange: false,
      timeIncreament: false,
      tRSecond: '',
      tRMinute: '',
      tRHours: [],
      actualTRHours: [],
      tIStartAt: '',
      tIStopAt: '',
      tISecond: '',
      tIMinute: '',
      tIHours: '',
      cronStatement: '',
      checkEvery: '',
      schEnabaleDisable: '',
    };
  },
  computed: {
    ...mapGetters({
    }),
  },
  created() {
    this.evaluateCronExpression();
  },
  methods: {
    ...mapActions('schedulerModule', []),
    evaluateCronExpression() {
      if (this.scheduler.cron) {
        this.evalCorn = this.scheduler.cron;
        this.separatedCorn = this.evalCorn.split(' ');
        if (this.evalCorn.includes('/')) {
          this.evalFreq();
        } else {
          this.evalTime();
        }
      }
      return true;
    },
    evalFreq() {
      let startStopHrs = '';
      this.timeIncreament = true;
      this.timeRange = false;

      if (this.separatedCorn[0].includes('/')) {
        [, this.tISecond] = this.separatedCorn[0].split('/');
        this.checkEvery = `${this.tISecond } Seconds`;
      } else {
        [this.tISecond] = this.separatedCorn;
      }

      if (this.separatedCorn[1].includes('/')) {
        [, this.tIMinute] = this.separatedCorn[1].split('/');
        this.checkEvery = `${this.tIMinute } Minutes`;
      } else {
        [, this.tIMinute] = this.separatedCorn;
      }

      if (this.separatedCorn[2].includes('/')) {
        [, this.tIHours] = this.separatedCorn[2].split('/');
        this.checkEvery = `${this.tIHours } Hours`;
        startStopHrs = this.separatedCorn[2].split('/')[0].split('-');
      } else {
        startStopHrs = this.separatedCorn[2].split('-');
      }
      if (startStopHrs.length === 1) {
        startStopHrs = [startStopHrs[0], startStopHrs[0]];
      }
      // eslint-disable-next-line max-len
      this.tIStopAt = `${(startStopHrs[1] % 12 === 0) ? '12' : startStopHrs[1] % 12 }:59${ (startStopHrs[1] > 11) ? ' PM' : ' AM'}`;
      // eslint-disable-next-line max-len
      this.tIStartAt = `${(startStopHrs[0] % 12 === 0) ? '12' : startStopHrs[0] % 12 }:00${ (startStopHrs[0] > 11) ? ' PM' : ' AM'}`;

      if (this.separatedCorn[3] === '*') {
        this.frequency[0].value = true;
      } else if (this.separatedCorn[3] === '?') {
        const days = this.separatedCorn[5].split(',');
        for (let i = 0; i < days.length; i++) {
          this.frequency[Number(days[i])].value = true;
        }
      }
      return true;
    },
    evalTime() {
      this.timeRange = true;
      this.timeIncreament = false;
      [this.tRSecond, this.tRMinute] = this.separatedCorn;
      this.tRHours = this.separatedCorn[2].split(',');
      this.tRHours = this.tRHours.sort((a, b) => +a - +b);
      for (let i = 0; i < this.tRHours.length; i++) {
        const Minute = this.tRMinute < 10 ? (`0${ this.tRMinute}`) : this.tRMinute;
        const ampm = this.tRHours[i] > 11 ? ' PM' : ' AM';
        this.actualTRHours.push(`${(this.tRHours[i] % 12 === 0) ? '12' : this.tRHours[i] % 12 }:${ Minute }${ampm}`);
      }
      if (this.separatedCorn[3] === '*') {
        this.frequency[0].value = true;
      } else if (this.separatedCorn[3] === '?') {
        const days = this.separatedCorn[5].split(',');
        for (let i = 0; i < days.length; i++) {
          this.frequency[Number(days[i])].value = true;
        }
      }
      return true;
    },
  },
};
</script>

<style scoped>
.heading-label {
  font-size: 25px;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
}
.padding-top {
  padding-top: 1rem;
}
.d-flex {
  display: flex !important;
}
.pd-right {
  padding-right: 2rem;
}
.sch-vw-enable {
  padding-top: 0.25rem;
  pointer-events: none;
}
.sch-vw-listName {
  height: 8rem;
  overflow: auto;
  border: 1px ridge;
  border-radius: 5px;
  margin-left: 1rem
}
.sch-vw-lst-name {
  padding-right: 5px;
}
.sch-vw-fw-400 {
  font-weight: 400;
}
.sch-vw-pd-right {
  padding-right: 1rem;
}
.sch-vs-enable {
  pointer-events: none;
}
.sch-dip-align {
  display: flex;
  align-items: flex-end;
}
</style>

const getters = {
  getAuditLogs: state => state.auditLogs.logs,
  getTotalRecords: state => (state.auditLogs.totalRecords !== undefined ? state.auditLogs.totalRecords : 0),
  getTimeFrames: state => state.timeFrame,
  getLogTypes: state => state.logTypes,
  getDataAlertLogs: state => state.dataAlertLogs.logs,
  getDaTotalRecords: state => (state.dataAlertLogs.totalRecords !== undefined ? state.dataAlertLogs.totalRecords : 0),
  getDataAlertFeeds: state => state.dataAlertFeeds,
};

export default getters;

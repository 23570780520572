const getters = {
  getReports: state => state.reports,
  getReportsStatus: state => state.reportsStatus,
  getReportsByType: state => type => state.reports.filter(c => c.commodityType.toLowerCase() === type.toLowerCase()),
  getReportsByName: state => name => state.reports.filter(c => c.mainTitle.toLowerCase().indexOf(name.toLowerCase()) > -1),
  getReportTypeImage: state => type => state.reports.filter(c => c.commodityType.toLowerCase() === type.toLowerCase())[0]
    .iconName.toLowerCase(),
  getReportTypes: state => state.reports.map(report => report.commodityType.toUpperCase())
    .filter((item, index, reportTypes) => reportTypes.indexOf(item) === index),
};

export default getters;

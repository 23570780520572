import { render, staticRenderFns } from "./YellowKeyDetails.vue?vue&type=template&id=6ef12ecf&scoped=true"
import script from "./YellowKeyDetails.vue?vue&type=script&lang=js"
export * from "./YellowKeyDetails.vue?vue&type=script&lang=js"
import style0 from "./YellowKeyDetails.vue?vue&type=style&index=0&id=6ef12ecf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ef12ecf",
  null
  
)

export default component.exports
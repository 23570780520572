<template>
  <div class="mcd-page-shell__content mds-page-shell__content">
    <template v-if="showPageError">
      <div class="reports-error">
        <mds-notification
          visible
          title="ERROR"
          status="error"
          dismissible="false"
        >
          {{ pageError !== ''? pageError : $t('labels.feed.feedListError') }}
        </mds-notification>
      </div>
    </template>
    <template v-else>
      <div class="mcd-page-tabs__content-wrap">
        <div class="mcd-page-tabs__content-pane mcd-page-tabs__content-pane--visible">
          <div class="mcd-page-tabs__content-pane-content">
            <router-view />
          </div>
          <div
            v-show="overlay"
            id="overlayDiv"
            class="overlay"
          />
          <div class="mcd-page-tabs__content-pane-navigation">
            <!-- TODO: Could make generic actions component for all tools -->
            <div
              class="mds-doc-page-nav mds-doc-toc-template__page-nav mds-doc__page-nav--fixed"
              style="margin-top: 0px;"
            >
              <div
                class="mds-doc-page-nav__list-groups-wrap"
              >
                <ul
                  class="mds-list-group mds-list-group--medium mds-list-group--with-icon mds-doc-page-nav--list-group-expanded"
                >
                  <li
                    v-if="isActivatedField"
                    class="mds-list-group__item mds-list-group__item--header"
                  >
                    <span>{{ $t('labels.common.actions') }}</span>
                  </li>
                  <li
                    v-for="(action, index) in feedActions"
                    :key="index"
                    class="mds-list-group__item"
                  >
                    <template v-if="action.name == 'labels.feed.editEntitlements'">
                      <a
                        class="mds-list-group__link"
                        href="javascript:void(0)"
                      >
                        <svg
                          class="mds-icon mds-list-group__left-icon"
                          aria-hidden="true"
                        >
                          <use :xlink:href="'#' + action.icon" />
                        </svg>
                        <span
                          class="mds-list-group__item-text"
                          @click="editEntitlements"
                        >
                          {{ $t(action.name) }}
                        </span>
                      </a>
                    </template>
                    <template v-else>
                      <template v-if="action.name == 'labels.feed.accessPrivateFeed'">
                        <a
                          class="mds-list-group__link"
                          href="javascript:void(0)"
                        >
                          <svg
                            class="mds-icon mds-list-group__left-icon"
                            aria-hidden="true"
                          >
                            <use :xlink:href="'#' + action.icon" />
                          </svg>
                          <span
                            class="mds-list-group__item-text"
                            @click="openPrivateFeedModal"
                          >
                            {{ $t(action.name) }}
                          </span>
                        </a>
                      </template>
                      <template v-else>
                        <router-link
                          :to="{ path: action.path }"
                          class="mds-list-group__link"
                          replace
                        >
                          <svg
                            class="mds-icon mds-list-group__left-icon"
                            aria-hidden="true"
                          >
                            <use :xlink:href="'#' + action.icon" />
                          </svg>
                          <span class="mds-list-group__item-text">{{ $t(action.name) }}</span>
                        </router-link>
                      </template>
                    </template>
                  </li>
                </ul>
                <template v-if="privateFeed">
                  <private-feed-access :toggle-value="toggle" />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <transition name="slide">
          <template v-if="showFeedEnt">
            <FeedEntitlements />
          </template>
        </transition>
      </div>
    </template>
  </div>
</template>
<script>
import { FEATURES } from '@/utils/constants.js';
import LayoutDefault from '../Layouts/LayoutDefault.vue';
import SubpageLayout from '../Layouts/Subpage/SubpageLayout.vue';
import FeedEntitlements from './FeedEntitlements.vue';
import { getUserRoles } from '../../utils/authService';
import EventBus from '../../main';
import PrivateFeedAccess from './PrivateFeedAccess.vue';


export default {
  name: 'FeedManagement',
  components: { FeedEntitlements, PrivateFeedAccess },
  data() {
    return {
      showFeedEnt: false,
      overlay: false,
      showPageError: false,
      pageError: '',
      toggle: false,
      privateFeed: false,
    };
  },
  computed: {
    feedActions() {
      let actions = [];
      const userRoles = getUserRoles();
      const actionList = { name: 'labels.feed.feedList', icon: 'list', path: '/feeds' };
      const actionNewFeed = { name: 'labels.feed.newFeed', icon: 'plus', path: '/feeds/new' };
      const actionFeedGroup = { name: 'labels.feedGroup.feedGroup', icon: 'plus', path: '/feeds/feedGroup' };
      const accessPrivateFeed = {
        name: 'labels.feed.accessPrivateFeed',
        icon: 'object-plus',
        path: '',
      };
      if (this.$route.name === 'FeedList') {
        actions.push(actionNewFeed);
        actions.push(actionFeedGroup);
        actions.push({
          name: 'labels.feed.editGroups',
          icon: 'person-group',
          path: '/feeds/groups',
        });
        if (userRoles.includes('ROLE_LDS_ADMIN') || userRoles.includes('ROLE_LDS_SUPPORT_ADMIN')) {
          actions.push(accessPrivateFeed);
        } else if ((userRoles.includes('ROLE_LDS_CO_USER')
        || userRoles.includes('ROLE_LDS_USER'))
        && userRoles.length <= 2) {
          actions = [];
        }
      }

      if (this.$route.name === 'NewFeed' || this.$route.name === 'feedGroup') {
        actions.push(actionList);
      }

      if (this.$route.name === 'FeedDetails') {
        actions.push(actionList);
        if (userRoles.includes('ROLE_LDS_ADMIN') || userRoles.includes('ROLE_LDS_SUPPORT_ADMIN')
        || userRoles.includes('ROLE_LDS_CO_ADMIN')) {
          actions.push({
            name: 'labels.feed.editEntitlements',
            icon: 'person-plus',
            path: '/feeds/new',
          });
          actions.push({ name: 'labels.feed.cloneFeed', icon: 'open-new', path: '/feeds/clone' });
        }
        if (!(userRoles.includes('ROLE_LDS_CO_USER') || userRoles.includes('ROLE_LDS_USER'))) {
          actions.push({ name: 'labels.feed.cloneFeed', icon: 'open-new', path: '/feeds/clone' });
        }
      }
      if (this.$route.name === 'groups') {
        actions.push(actionList);
        actions.push(actionNewFeed);
      }

      if (this.$route.name === 'CloneFeed') {
        actions.push(actionList);
      }
      return actions;
    },

    isActivatedField() {
      const userRoles = getUserRoles();
      if ((userRoles.includes('ROLE_LDS_CO_USER') || userRoles.includes('ROLE_LDS_USER'))
      && (this.$route.name === 'FeedList') && !(userRoles.includes('ROLE_LDS_ADMIN')
      || userRoles.includes('ROLE_LDS_SUPPORT_ADMIN')
      || userRoles.includes('ROLE_LDS_CO_ADMIN'))) {
        return false;
      }
      return true;
    },
  },
  created() {
    if ((this.isUserEntitled && !this.isUserEntitled([FEATURES.FEED_MANAGEMENT]))) {
      this.showPageError = true;
      this.pageError = this.$t('labels.common.featureNotEntitled');
    }
    if (this.$route.query.marketsFrame) {
      this.$emit('update:layout', SubpageLayout);
    } else {
      this.$emit('update:layout', LayoutDefault);
    }
    EventBus.$on('hideOverLay', (value) => {
      this.overlay = value;
    });
    EventBus.$on('hideFeedEntitlements', (value) => {
      this.showFeedEnt = value;
    });
    EventBus.$on('closePrivateFeedModal', (value) => {
      this.toggle = value;
    });
  },
  methods: {
    editEntitlements() {
      this.showFeedEnt = true;
      this.overlay = true;
    },
    openPrivateFeedModal() {
      this.privateFeed = true;
      this.toggle = true;
      EventBus.$emit('toggleChange', true);
      EventBus.$emit('initPrivateModel', true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/feed';
@import "src/assets/styles/components/custom/transition";
</style>

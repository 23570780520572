const mutations = {
  ADD_CONFIGS: (state, configs) => {
    state.configList = configs;
  },
  SET_CONFIG_DETAILS: (state, configDetails) => {
    state.configDetails = configDetails;
  },
  ADD_ACCOUNT: (state, name) => {
    state.accountList.push(name);
  },
  ADD_ACCOUNTLIST: (state, accountList) => {
    state.accountList = accountList;
  },
  ADD_YELLOWKEY: (state, key) => {
    state.yellowKeyList.push(key);
  },
  ADD_YELLOWKEYLIST: (state, keyList) => {
    state.yellowKeyList = keyList;
  },
  SET_PAGE_STATUS: (state, status) => {
    state.pageStatus = status;
  },
  SET_ERROR_STATUS: (state, status) => {
    state.errorStatus = status;
  },
  SET_ERROR_MESSAGE: (state, exception) => {
    state.errorMessage = exception;
  },
  SET_YELLOWKEY_MAPPINGS: (state, mappings) => {
    state.yellowKeyMappings = [
      {
        name: 'default',
        types: mappings.types.split(','),
        fields: mappings.fields.split(','),
        metas: mappings.metas.split(','),
      },
    ];
  },
};

export default mutations;

/* eslint-disable no-use-before-define */

import { isInteger, requireInteger } from '../numbers.es6';
import { requireNonEmptyString } from '../strings.es6';
import MpFileUtils from './utils.es6';

/* ***************************************************************
 * Interfaces
 * *************************************************************** */
/**
 * Defines the payload returned by Markets server.
 *
 * @typedef Object MpFile.ServerMetadata
 * @property {string} owner - Owner of the file.
 * @property {string} name - Full path.
 * @property {int} created - Time of creation (millis UTC)
 * @property {int} [deleted=-1] - Time of deletion, or -1 (millis UTC)
 * @property {string} content-type
 * @property {int} size - Size of file
 * @property {string} mid
 * @property {string} uuid
 * @property {string} md5
 */

/* ***************************************************************
 * Private variables
 * *************************************************************** */

let _canConstruct = false;

let _apiHandler = null;


/* ***************************************************************
 * Private methods
 * *************************************************************** */

/**
 * Returns whether `arg` is a function.
 * @param {*} arg
 * @returns {boolean}
 * @private
 */
function _isFunction(arg) {
  return (typeof arg === 'function');
}

/**
 * Validates `arg` to be an integer.  If valid, this
 * method returns that argument.  Otherwise it throws.
 * @param {int} arg
 * @param {string} argName
 * @param {int} defaultValue
 * @returns {int}
 * @private
 */
function _validInt(arg, argName, defaultValue) {
  if (isInteger(arg)) return arg;
  if (arguments.length > 2) return defaultValue;
  throw new TypeError(`${argName }: Integer`);
}


/**
 * Validates `arg` to be an instance of MpFile.
 * If valid, this method returns that argument.
 * Otherwise it throws.
 * @param {MpFile} arg
 * @param {string} argName
 * @returns {MpFile}
 * @private
 */
function _validMpFile(arg, argName) {
  if (!(arg instanceof MpFile)) { throw new TypeError(`${argName }: MpFile`); }

  return arg;
}

/**
 * Validates an argument within the Arguments array to be a function,
 * if it was provided in the first place.  This function returns nothing.
 * @param {Arguments} args - Arguments array.
 * @param {int} argIdx - Index position where a function might be provided.
 * @param {string} argName - Name of the argument, in case we need to throw.
 * @returns {undefined}
 * @throws TypeError - If an argument is found at `argIdx` and it's not a function.
 * @private
 */
function _validCallbackIfProvided(args, argIdx, argName) {
  if (args.length > argIdx
        && typeof args[argIdx] !== 'function') { throw new TypeError(`${argName }: Function`); }
}

/**
 * Returns the singleton ApiHandler used to manipulate files.
 * @returns {lim.LimitlessApiHandler}
 * @private
 */
function _getAH() {
  if (_apiHandler === null) { _apiHandler = new lim.LimitlessApiHandler(2); }

  return _apiHandler;
}

/**
 * Returns a new, generic callback that forward the call to an optional
 * callback function.
 * @param {*} callback
 * @returns {Function}
 * @private
 */
function _fwdCallback(callback) {
  return function () {
    if (_isFunction(callback)) { callback.apply(this, arguments); }
  };
}

/* ***************************************************************
 * Class: MpFile
 * *************************************************************** */
/**
 * Marketplace file.
 */
export default class MpFile {
  /**
     * @param {string} name
     * @param {string} owner
     * @param {int} created
     * @param {int} deleted
     * @param {string} contentType
     * @param {int} size
     * @param {string} uuid
     * @param {string} md5
     * @param {string} mid
     */
  constructor(name, owner, created, deleted, contentType, size, uuid, md5, mid) {
    // Enforce private constructor
    if (!_canConstruct) { throw new Error('IllegalStateException: private constructor, access denied'); }

    _canConstruct = false;

    this._name = name;
    this._owner = owner;

    this._created = created;
    this._deleted = deleted;

    this._contentType = contentType;
    this._size = size;

    this._uuid = uuid;
    this._md5 = md5;
    this._mid = mid;

    Object.freeze(this);
  }

  /** @returns {string} String representation, for debugging. */
  toString() {
    return `{${ this._name } @ ${ this._uuid }}`;
  }

  /**
     * @param {(MpFile|*)} that
     * @returns {boolean} Whether this file represents the same file as `that`.
     */
  equals(that) {
    return (that instanceof MpFile
                && this._uuid === that._uuid);
  }

  /**
     * @param {(MpFile|*)} that
     * @returns {number} Numeric value for the purpose of sorting files.
     */
  compareTo(that) {
    return MpFile.compare(this, that);
  }

  /** @returns {string} Full path to the file. */
  fullPath() {
    return this._name;
  }


  /** @returns {string} Path of the parent directory. */
  parentPath() {
    const name = this._name;
    const lastSlash = name.lastIndexOf('/');

    return ((lastSlash >= 0) ? name.substring(0, lastSlash) : '');
  }

  /** @returns {string} File name minus its path. */
  name() {
    return this._name.substring(this._name.lastIndexOf('/') + 1);
  }

  /** @returns {string} Owner of the file (his or her login ID.) */
  owner() {
    return this._owner;
  }

  /** @returns {int} Size of the file, in bytes. */
  size() {
    return this._size;
  }

  /**
     * @returns {string} Content-type used to upload the file, which can be used to download
     * that same file.
     */
  contentType() {
    return this._contentType;
  }

  /** @returns {int} Time (epoch) at which the file was created. */
  createTime() {
    return this._created;
  }

  /** @returns {int} Time (epoch) at which the file was deleted, -1 if not deleted. */
  deleteTime() {
    return this._deleted;
  }

  /** @returns {boolean} Whether this file is currently in the trash folder. */
  isDeleted() {
    return (this._deleted >= 0);
  }

  /** @returns {string} Marketplace ID given to this file. */
  uuid() {
    return this._uuid;
  }

  /** @returns {string} Computed MD5 value for this file, useful to validate a file. */
  md5() {
    return this._md5;
  }

  /** @returns {string} Unique ID that Markets assigned to this file. */
  mid() {
    return this._mid;
  }

  /**
     * @returns {string} URL to download the file; to be used by any user, even if they are
     * not entitled to the *repo* feed.
     */
  downloadUrl() {
    return `${MpFileUtils.getBasePath() }?mid=${ this._mid}`;
  }

  /**
     * @returns {string} URL to download the file; to be used by users entitled to the *repo* feed.
     */
  downloadEntitledUrl() {
    return `${MpFileUtils.getBasePath() }?uuid=${ this._uuid}`;
  }

  /**
     * Compares two objects for the purpose of sorting them (ascending).
     * @param {(MpFile|*)} mpFile1
     * @param {(MpFile|*)} mpFile2
     * @returns {number}
     * @method
     */
  static compare(mpFile1, mpFile2) {
    const is1 = (mpFile1 instanceof MpFile);
    const is2 = (mpFile2 instanceof MpFile);

    if (is1) {
      if (is2) {
        let rv = lim.String.compare(mpFile1._name, mpFile2._name);

        if (rv === 0) rv = lim.String.compare(mpFile1._uuid, mpFile2._uuid); // support duplicate names

        return rv;
      }
      return -1;
    }
    if (is2) return 1;
    return 0;
  }

  /**
     * Creates a new instance of MpFile from the given
     * server payload (aka server metadata).
     * @param {MpFile.ServerMetadata} serverMetadata
     * @returns {MpFile}
     */
  static fromServerMetadata(serverMetadata) {
    if (!lim.Object.is(serverMetadata)) { throw new TypeError('serverMetadata: Object'); }

    // Enable one-time call to constructor
    _canConstruct = true;

    return new MpFile(

      requireNonEmptyString(serverMetadata['name'], 'serverMetadata["name"]'),
      requireNonEmptyString(serverMetadata['owner'], 'serverMetadata["owner"]'),
      requireInteger(serverMetadata['created'], 'serverMetadata["created"]'),
      _validInt(serverMetadata['deleted'], 'serverMetadata["deleted"]', -1),
      requireNonEmptyString(serverMetadata['content-type'], 'serverMetadata["content-type"]'),
      requireInteger(serverMetadata['size'], 'serverMetadata["size"]'),
      requireNonEmptyString(serverMetadata['uuid'], 'serverMetadata["uuid"]'),
      requireNonEmptyString(serverMetadata['md5'], 'serverMetadata["md5"]'),
      requireNonEmptyString(serverMetadata['mid'], 'serverMetadata["mid"]'),
    );
  }

  /**
     * Changes the full path of `mpFile` to `fullPath`.
     * This method is asynchronous; it makes a call
     * to the server API and calls `complete` once the
     * operation is, well, complete.
     * @param {MpFile} mpFile - File to rename.
     * @param {string} fullPath - Full path to new file name, including leading '/'.
     * @param {Function} [complete] - Callback function, executed upon completion.
     *        This function receives one argument: if the operation succeeds it receives
     *        the new MpFile instance; otherwise it receives an HTTP status code or a
     *        ServerError object.
     */
  static rename(mpFile, fullPath, complete) {
    _validMpFile(mpFile, 'mpFile');
    requireNonEmptyString(fullPath, 'fullPath');

    if (!lim.String.startsWith(fullPath, '/')) { throw new Error("IllegalArgumentException: fullPath must start with '/'"); }

    _validCallbackIfProvided(arguments, 2, 'complete');

    _getAH().call('lim.MpApi.Files.Rename', _fwdCallback(complete), mpFile, fullPath);
  }

  /**
     * Sends a file to the trash folder, preserving its original name.
     * This method is asynchronous; it makes a call
     * to the server API and calls `complete` once the
     * operation is, well, complete.
     *
     * @param {MpFile} mpFile - File to send to the /trash folder.
     * @param {Function} [complete] - Callback function, executed upon completion.
     *        This function receives one argument: if the operation succeeds it receives
     *        the new MpFile instance; otherwise it receives an HTTP status code or a
     *        ServerError object.
     * @returns {MpFile}
     */
  static sendToTrash(mpFile, complete) {
    _validMpFile(mpFile, 'mpFile');
    _validCallbackIfProvided(arguments, 1, 'complete');

    _getAH().call('lim.MpApi.Files.Delete', _fwdCallback(complete), mpFile);
  }

  /**
     * Moves a file out of the trash folder and back into its original (last known) folder.
     * This method is asynchronous; it makes a call
     * to the server API and calls `complete` once the
     * operation is, well, complete.
     *
     * @param {MpFile} mpFile - File to send to the /trash folder.
     * @param {Function} [complete] - Callback function, executed upon completion.
     *        This function receives one argument: if the operation succeeds it receives
     *        the new MpFile instance; otherwise it receives an HTTP status code or a
     *        ServerError object.
     * @returns {MpFile}
     */
  static restore(mpFile, complete) {
    _validMpFile(mpFile, 'mpFile');
    _validCallbackIfProvided(arguments, 1, 'complete');

    _getAH().call('lim.MpApi.Files.Undelete', _fwdCallback(complete), mpFile);
  }

  /**
     * Validates an argument to be an instance of MpFile, throws otherwise.
     * @param {MpFile} arg Argument to validate.
     * @param {string} argName Name given to `arg`.
     * @returns {MpFile} Always returns `arg`.
     * @throws {TypeError} If `arg` is not a MpFile object.
     */
  static requireMpFile(arg, argName) {
    if (!(arg instanceof MpFile)) { throw new TypeError(`${argName }: MpFile`); }

    return arg;
  }

  /** @returns {string} For backward compatibility, until all modules are converted to ES6. */
  static getBasePath() {
    return MpFileUtils.getBasePath();
  }
}


import Objects, { requireNonVoid, isVoid, isObject } from './objects.es6';
import { requireString, requireNonEmptyString } from './strings.es6';
import Dates from './dates/dates.es6';
import Functions from './functions.es6';

/**
 * @typedef Object SerializedMetadata
 * @property {string} className Class name used to construct an object.
 * @property {string} purpose Purpose for which the object was serialized.
 * @property {boolean} isSerialized Always `true`.
 */

/**
 * @typedef Object Serialized
 * @property {SerializedMetadata} _ Internal serialization information.
 * @property {Object} info Instance-specific serialized data.
 */

/**
 * @param {Object} obj
 * @returns {boolean} Whether `obj` can be serialized.
 * @private
 */
function _isImplementedUnchecked(obj) {
  return (Functions.is(obj.serialize)
            && obj.constructor.hasOwnProperty('_serializeInfo')
            && (Array.isArray(obj.constructor._serializeInfo)
                || (isObject(obj.constructor._serializeInfo)
                    && Array.isArray(obj.constructor._serializeInfo.save))));
}

function _serializeOne(obj, name, purpose) {
  const hasName = (typeof name === 'string');
  const value = (hasName) ? obj[name] : obj;

  const typeOf = typeof value;
  if (typeOf === 'undefined') {
    return Objects.undef();
  } if (typeOf === 'string'
               || typeOf === 'number'
               || typeOf === 'boolean'
               || value === null) {
    return value;
  } if (typeOf === 'function') {
    if (hasName === false
            || name.indexOf('serialized') < 0) {
      return Objects.undef();
    }
    return value.call(obj);
  } if (value instanceof Date) {
    return Dates.serialize(value);
  } if (value.constructor === Array) {
    const newArray = [];
    for (let cnt = 0; cnt < value.length; cnt++) newArray.push(_serializeOne(value[cnt]));
    return newArray;
  } if (_isImplementedUnchecked(value)) {
    return value.serialize(purpose);
  } if (value.constructor === Object) {
    return Objects.copy(value, true);
  }
}


const Serializable = Object.freeze(/** @lends {Serializable} */ {

  /**
     *
     * @param {Object} obj Instance of a class to serialize.
     * @param {string} className Class name used to construct `obj`.
     * @param {string} [purpose="save"] Purpose of the serialization.
     * @returns {Serialized}
     */
  serialize(obj, className, purpose) {
    requireNonVoid(obj, 'obj');
    requireNonEmptyString(className, 'className');

    const constructor = Functions.findClass(className);
    if (constructor === null) {
      throw new Error(`className: not a known class: ${ className}`);
    }

    if (constructor !== obj.constructor) {
      throw new Error(`className: mismatch \`obj.constructor\`: ${ className}`);
    }

    const purp = (
      (arguments.length > 2)
        ? requireNonEmptyString(purpose, 'purpose')
        : 'save'
    );

    let purposeUsed = purp;
    let propNames = null;

    if (!_isImplementedUnchecked(obj)) {
      propNames = Objects.properties(obj);
      purposeUsed = 'save';
    } else if (Array.isArray(constructor._serializeInfo)) {
      propNames = constructor._serializeInfo;
      purposeUsed = 'save';
    } else if (!Array.isArray(constructor._serializeInfo[purp])) {
      propNames = constructor._serializeInfo['save'];
      purposeUsed = 'save';
    } else {
      propNames = constructor._serializeInfo[purp];
    }

    /** @type {Serialized} */
    const serialized = {
      _: {
        className,
        purpose: purposeUsed,
        isSerialized: true,
      },
      info: {},
    };


    for (let cnt = 0; cnt < propNames.length; cnt++) {
      const attr = propNames[cnt];

      requireString(attr, `${className }._serializedInfo[${ purposeUsed }][${ cnt }]`);

      if (attr === ''
                || attr.charAt(0) === '!') {
        /* Do not serialize those.  '!' indicates
                 * an attribute that should only be deserialized
                 * (for backward compatibility). */
        continue;
      }

      const typeOf = typeof obj[attr];
      if (typeOf === 'undefined') {
        continue;
      }

      if (typeOf !== 'function'
                && !obj.hasOwnProperty(attr)) {
        throw new Error(`obj[${ attr }] belongs to prototype`);
      }

      const value = _serializeOne(obj, attr, purp);
      if (typeof value !== 'undefined') {
        serialized.info[attr] = value;
      }
    }

    return serialized;
  },


  /**
     * @param {(Serialized|*)} serialized
     * @returns {boolean} Whether `serialized` is an object previously serialized by `Serializable`.
     */
  isSerialized(serialized) {
    return (!isVoid(serialized)
                && !isVoid(serialized.info)
                && !isVoid(serialized._)
                && typeof serialized._.className === 'string'
                && serialized._.isSerialized === true);
  },


  /**
     * @param {Serialized} serialized Serialized data to deserialize into `instance`.
     * @param {?Object} [instance] A instance to deserialize into. If null, an instance will be
     *        created using the class' no-argument constructor.
     * @returns {Object} A new instance, or `instance` if it was provided.
     */
  deserialize(serialized, instance) {
    requireNonVoid(serialized, 'serialized');

    if (!Serializable.isSerialized(serialized)) {
      throw new Error(
        `serialized: not a recognized serialized object: ${ JSON.stringify(serialized)}`,
      );
    }

    if (isVoid(instance)) {
      const constructor = Functions.findClass(serialized._.className);
      if (constructor === null) {
        throw new Error(`ClassNotFoundException: ${ serialized._.className}`);
      }

      try {
        instance = new constructor();
      } catch (err) {
        throw new Error(
          `Unable to create a new ${serialized._.className} instance, caused by: ${err}`,
        );
      }
    }

    let propNames = null;

    if (!_isImplementedUnchecked(instance)) {
      propNames = Objects.properties(serialized.info);
    } else if (Array.isArray(instance.constructor._serializeInfo)) {
      propNames = instance.constructor._serializeInfo;
    } else if (typeof serialized._.purpose === 'string'
                   && Array.isArray(instance.constructor._serializeInfo[serialized._.purpose])) {
      propNames = instance.constructor._serializeInfo[serialized._.purpose];
    } else {
      propNames = instance.constructor._serializeInfo['save'];
    }

    for (let cnt = 0; cnt < propNames.length; cnt++) {
      let prop = propNames[cnt];
      let isBackwardOnly = false;

      if (prop !== ''
                && prop.charAt(0) === '!') {
        /* '!' indicate an old property that isn't
                 * being serialized anymore.  We still deserialize it
                 * - without the '!' - for backward compatibility. */
        prop = prop.substring(1);
        isBackwardOnly = true;
      }

      const value = serialized.info[prop];
      const typeOf = typeof value;
      const isFunction = (typeof instance[prop] === 'function');

      // Is this a function to execute during deserialization?
      if (isFunction === true) {
        if (typeOf !== 'undefined') instance[prop].call(instance, value);
        else if (!isBackwardOnly) instance[prop].call(instance);
      } else if (typeOf === 'boolean'
                       || typeOf === 'number'
                       || value === null) {
        instance[prop] = value;
      } else if (Dates.isSerialized(value)) {
        instance[prop] = Dates.deserialize(value);
      } else if (typeOf === 'string') {
        instance[prop] = value;
      } else if (typeOf === 'object') {
        if (Serializable.isSerialized(value)) {
          const propObj = instance[prop];
          instance[prop] = Serializable.deserialize(value, propObj);
        } else {
          instance[prop] = Objects.clone(value);
        }
      } else if (typeOf !== 'undefined') {
        throw new Error(
          `failed to deserialize property "${ prop }" of ${ serialized._.className}`,
        );
      }
    }

    return instance;
  },
});

export default Serializable;

<template>
  <div class="mcd-sc">
    <not-entitled-user v-if="showPageError" />
    <div
      v-if="overlay"
      id="mds-overlay"
      class="mds-overlay"
    />
    <mds-loader
      v-show="showloader"
      class="div-centered"
      size="large"
    />
    <mds-layout-grid>
      <mds-row>
        <mds-col :squeeze="1">
          <div class="border-bottom-solid">
            <mds-row>
              <mds-col :cols="12">
                <h1 class="heading-label">
                  {{ $t('labels.schedulerManagement.name') }}
                </h1>
              </mds-col>
            </mds-row>
          </div>
          <div class="custom-padding">
            <mds-row>
              <mds-col :cols="7">
                <label class="sch-lbl-subHead">
                  <strong>{{ $t('labels.schedulerManagement.subHeading') }}</strong>
                </label>
              </mds-col>
              <mds-col :cols="5">
                <mds-row>
                  <mds-col :cols="8">
                    <mds-search-field
                      id="medium-trigger"
                      v-model="searchFilter"
                      placeholder="Search by publist"
                      label="Search"
                      @keyup.prevent="filterKeywords($event)"
                      @mds-search-field-input-cleared="clearSearchBar()"
                      @mouseover="enableToolTip()"
                      @mouseleave="disableToolTip()"
                      @focus="enableToolTip()"
                      @blur="disableToolTip()"
                    />
                    <mds-tooltip
                      v-model="toggleForTooltip"
                      size="medium"
                      triggered-by="medium-trigger"
                      :position="['top-center']"
                    >
                      Press enter to search
                    </mds-tooltip>
                  </mds-col>
                  <mds-col :cols="4">
                    <mds-button
                      variation="primary"
                      size="medium"
                      @click="openCreateSchedulePopUp({}, 'Create')"
                    >
                      {{ $t('labels.schedulerManagement.create') }}
                    </mds-button>
                  </mds-col>
                </mds-row>
              </mds-col>
            </mds-row>
            <div class="padding-top">
              <mds-row>
                <mds-col :cols="12">
                  <div v-if="schListsPaginationList.length !== 0">
                    <mds-table row-hover>
                      <mds-thead class="width-100">
                        <mds-th
                          v-for="(schHeader, index) in schHeaders"
                          :key="schHeader.id"
                          :sortable="schHeader.sortable"
                          :sorted="schHeader.sorted"
                          :style="schHeader.style"
                          @mds-th-sort-changed="onSort(index, schHeader.fieldName, $event)"
                        >
                          {{ schHeader.text }}
                        </mds-th>
                      </mds-thead>
                      <mds-tbody>
                        <mds-tr
                          v-for="scl in schListsPaginationList"
                          :key="scl.scheduleId"
                        >
                          <mds-td
                            class="position-relative align-list-data"
                            :class="{'enable-color': scl.enabled == 'true', 'disable-error': scl.enabled == 'false'}"
                          >
                            <mds-row>
                              <mds-col
                                :cols="9"
                              >
                                <div
                                  class="sch-name"
                                  @click="openViewSchedulePopUp(scl)"
                                >
                                  <strong>{{ scl.scheduleName }}</strong>
                                </div>
                              </mds-col>
                              <mds-col
                                :cols="3"
                              >
                                <div>
                                  <mds-button
                                    id="large-trigger"
                                    variation="icon-only"
                                    icon="pencil"
                                    class="actions__bttn-edit"
                                    type="button"
                                    @click="openEditScheduleNamePopUp(scl)"
                                  />
                                </div>
                              </mds-col>
                            </mds-row>
                          </mds-td>
                          <mds-td
                            :class="{'enable-color': scl.enabled == 'true', 'disable-error': scl.enabled == 'false'}"
                            class="align-list-data"
                          >
                            {{ scl.description }}
                          </mds-td>
                          <mds-td
                            :class="{'enable-color': scl.enabled == 'true', 'disable-error': scl.enabled == 'false'}"
                            class="align-list-data"
                          >
                            {{ lastUpadtedTime(scl.lastUpdatedTime) }}
                          </mds-td>
                          <mds-td
                            :class="{'enable-color': scl.enabled == 'true', 'disable-error': scl.enabled == 'false'}"
                            class="align-list-data"
                          >
                            <mds-switch
                              :value="scl.enabled.toString()"
                              :checked="scl.enabled == 'true'"
                              hidden-label
                              size="small"
                              @change="switchToggle($event, scl.scheduleName, scl.enabled)"
                            >
                              True
                            </mds-switch>
                          </mds-td>
                          <mds-td
                            :class="{'enable-color': scl.enabled == 'true', 'disable-error': scl.enabled == 'false'}"
                            class="align-list-data"
                          >
                            <mds-row>
                              <mds-col
                                :cols="3"
                                class="padding-right-25"
                              >
                                <mds-button
                                  variation="secondary"
                                  size="small"
                                  @click="openCreateSchedulePopUp(scl, 'Edit')"
                                >
                                  {{ $t('labels.schedulerManagement.edit') }}
                                </mds-button>
                              </mds-col>
                              <mds-col :cols="3">
                                <mds-button
                                  variation="secondary"
                                  size="small"
                                  @click="openCopySchedulePopUp(scl)"
                                >
                                  {{ $t('labels.schedulerManagement.copy') }}
                                </mds-button>
                              </mds-col>
                              <mds-col :cols="3">
                                <mds-button
                                  variation="secondary"
                                  size="small"
                                  @click="openDeleteSchedulePopUp(scl.scheduleName)"
                                >
                                  {{ $t('labels.schedulerManagement.delete') }}
                                </mds-button>
                              </mds-col>
                            </mds-row>
                          </mds-td>
                        </mds-tr>
                      </mds-tbody>
                    </mds-table>
                  </div>
                  <div v-if="schListsPaginationList.length === 0">
                    <mds-row>
                      <mds-col :cols="12">
                        <h1 class="heading-label">
                          {{ $t('labels.schedulerManagement.scNoScheduleYet') }}
                        </h1>
                      </mds-col>
                    </mds-row>
                  </div>
                  <div class="custom-padding" />
                  <div v-if="schListsPaginationList.length !== 0">
                    <mds-pagination
                      :key="componentKey"
                      show-items-info
                      show-items-select
                      :total-items="paginationOpts.totalItems"
                      :page-sizes="paginationOpts.pageSizes"
                      :page="paginationOpts.page"
                      :page-size="paginationOpts.pageSize"
                      @mds-pagination-page-changed="pagenate"
                    />
                  </div>
                </mds-col>
              </mds-row>
            </div>
          </div>
          <mds-modal
            v-model="toggleDeletePopUp"
            title="Delete a list"
            :width="'600px'"
            class="delete-confirmation-modal"
          >
            {{ $t('labels.schedulerManagement.deleteConfirmation') }}
            <div class="custom-padding" />
            <mds-row>
              <mds-col
                :push="5"
              >
                <mds-row>
                  <mds-col>
                    <mds-button
                      variation="secondary"
                      size="small"
                      @click="closeDeletePopUp"
                    >
                      {{ $t('labels.schedulerManagement.cancel') }}
                    </mds-button>
                  </mds-col>
                  <mds-col>
                    <mds-button
                      variation="primary"
                      size="small"
                      @click="deleteScheduleByName"
                    >
                      {{ $t('labels.schedulerManagement.delete') }}
                    </mds-button>
                  </mds-col>
                </mds-row>
              </mds-col>
            </mds-row>
          </mds-modal>
          <mds-modal
            v-model="viewSchedulerModal"
            size="small"
            aria-label="Small Modal"
            :width="'900px'"
            title="Scheduler Details"
          >
            <view-scheduler v-bind="{ scheduler: schObjForViewSchedule}" />
          </mds-modal>
          <div class="sc-model create-new-scheduler">
            <mds-modal
              v-model="createSchedulerModal"
              size="large"
              aria-label="Default Modal"
              title="Scheduler configuration"
            >
              <create-new-scheduler
                v-bind="schObjForCreateSchedule"
                @createScheduleSucess="changeCreateScheduleSucess($event)"
                @createScheduleError="changeCreateScheduleError($event)"
              />
            </mds-modal>
          </div>
          <div>
            <mds-modal
              v-model="editSchNameModal"
              size="small"
              width="600px"
              aria-label="Small Modal"
              title="Edit Schedule"
            >
              <edit-schedule-name
                v-bind="schObjForEditScheduleName"
                @editScheduleNameSucess="changeEditScheduleNameSucess($event)"
                @editScheduleNameError="changeEditScheduleNameError($event)"
              />
            </mds-modal>
          </div>
          <div>
            <mds-modal
              v-model="copySchedule"
              size="small"
              width="600px"
              aria-label="Small Modal"
              title="Copy Schedule Settings"
            >
              <copy-schedule
                v-bind="copyHoldScheduler"
                @copyScheduleNameSucess="copyScheduleSucess($event)"
                @copyScheduleNameError="copyScheduleError($event)"
              />
            </mds-modal>
          </div>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
    <scheduler-notification
      ref="notificationComponent"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import MdsLoader from '@mds/loader';
import MdsModal from '@mds/modal';
import MdsSwitch from '@mds/switch';
import { MdsButton } from '@mds/button';
import MdsSearchField from '@mds/search-field';
import MdsPagination from '@mds/pagination';
import moment from 'moment';
import LayoutDefault from '../Layouts/LayoutDefault.vue';
import EventBus from '../../main';
import CreateNewScheduler from './CreateNewScheduler.vue';
import SchedulerNotification from './SchedulerNotification.vue';
import NotEntitledUser from '../Errors/NotEntitledUser';
import ViewScheduler from './ViewScheduler.vue';
import EditScheduleName from './EditScheduleName.vue';
import CopySchedule from './CopySchedule.vue';

export default {
  name: 'Scheduler',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsLoader,
    MdsSearchField,
    MdsPagination,
    MdsSwitch,
    CreateNewScheduler,
    MdsModal,
    MdsButton,
    NotEntitledUser,
    SchedulerNotification,
    ViewScheduler,
    EditScheduleName,
    CopySchedule,
  },
  filters: {},
  data() {
    return {
      schObjForViewSchedule: {},
      schObjForCreateSchedule: {},
      schObjForEditScheduleName: {},
      overlay: false,
      showloader: false,
      showPageError: false,
      pageError: '',
      toggleDeletePopUp: false,
      viewSchedulerModal: false,
      createSchedulerModal: false,
      editSchNameModal: false,
      copySchedule: false,
      copyHoldScheduler: {},
      schList: [],
      dataSetPubList: [],
      respond: false,
      searchFilter: '',
      publistConfig: [[], []],
      errorText: [],
      notificationText: [],
      notificationType: '',
      schListsPaginationList: [],
      schListFilterList: [],
      deleteHoldScheduleName: '',
      sortOrder: 1,
      width: '',
      sortColIndex: 0,
      internalServerErrMsg: 'Internal Server Error with status code 500',
      schHeaders: [
        {
          id: '1',
          text: 'Name',
          fieldName: 'scheduleName',
          sortable: true,
          sortOrder: 0,
          sortColIndex: 0,
          sorted: 1,
          style: {
            width: '20%',
          },
        },
        {
          id: '2',
          text: 'Description',
          fieldName: 'description',
          sortable: false,
          style: {
            width: '40%',
          },
        },
        {
          id: '3',
          text: 'Last Updated Time',
          fieldName: 'lastUpdatedTime',
          sortable: true,
          sortOrder: 0,
          sortColIndex: 0,
          style: {
            width: '23%',
          },
        },
        {
          id: '4',
          text: 'Enabled',
          fieldName: 'enabled',
          sortable: true,
          sortOrder: 0,
          sortColIndex: 0,
          style: {
            width: '7%',
          },
        },
        {
          id: '5',
          text: '',
          fieldName: 'configurations',
          sortable: false,
          style: {
            width: '17%',
          },
        },
      ],
      keyCodes: [9, 13, 16, 17, 18, 27, 45, 122, 144, 20, 27],
      paginationOpts: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        page: 1,
        totalItems: this.schList === undefined ? 0 : this.schList.length,
        showItemsInfo: true,
        showItemsSelect: true,
      },
      componentKey: 0,
      toggleForTooltip: false,
      previousSelectionSchList: [],
    };
  },
  computed: {
    ...mapGetters({
      pubList: 'schedulerModule/getPubListForScheduler',
      schedulerList: 'schedulerModule/getSchedulerList',
    }),
  },
  created() {
    EventBus.$emit('layoutChange', LayoutDefault);
    this.schListsPaginationList = [];
    this.callSchedulerList();
    this.getPublistDataSet();
  },
  methods: {
    ...mapActions('schedulerModule', ['getPubListForScheduler', 'getSchedulerList', 'deleteScheduler', 'toggleEnable']),
    getPublistDataSet() {
      this.overlay = true;
      this.showloader = true;
      this.getPubListForScheduler().then((response) => {
        if (response !== undefined && response.status === 500) {
          this.$refs.notificationComponent.triggerError(this.internalServerErrMsg);
          this.overlay = false;
          this.showloader = false;
        } else if (this.pubList !== undefined && this.pubList.length > 0) {
          this.pubList.sort().forEach((listName) => {
            if (listName) {
              this.dataSetPubList.push({
                text: listName,
                value: listName,
              });
            }
          });
          this.overlay = false;
          this.showloader = false;
        } else {
          this.overlay = false;
          this.showloader = false;
        }
      });
    },
    callSchedulerList() {
      this.overlay = true;
      this.showloader = true;
      this.publistConfig = [[], []];
      this.getSchedulerList().then((response) => {
        if (response !== undefined && response.status === 500) {
          this.$refs.notificationComponent.triggerError(this.internalServerErrMsg);
          this.overlay = false;
          this.showloader = false;
        } else if (this.schedulerList !== undefined && this.schedulerList.length > 0) {
          this.schListsPaginationList = [];
          this.schList = this.schedulerList;
          this.schListsPaginationList = this.schList;
          for (let i = 0; i < this.schedulerList.length; i++) {
            if (this.schedulerList[i].listNames) {
              if (typeof this.schedulerList[i].listNames === 'string') {
                this.schedulerList[i].listNames = [this.schedulerList[i].listNames];
                this.schList[i].listNames = this.schedulerList[i].listNames;
                this.schListsPaginationList[i].listNames = this.schedulerList[i].listNames;
              } else if (typeof this.schedulerList[i].listNames === 'object') {
                this.schedulerList[i].listNames = this.schedulerList[i].listNames.sort();
                this.schListsPaginationList[i].listNames = this.schedulerList[i].listNames.sort();
                this.schList[i].listNames = this.schedulerList[i].listNames.sort();
              }
              if (this.schedulerList[i].listNames && this.schedulerList[i].listNames.length > 0) {
                this.publistConfig[0].push(this.schedulerList[i].listNames);
                this.publistConfig[1].push(i);
              }
            }
          }
          this.overlay = false;
          this.showloader = false;
          this.previousSelectionSchList = this.schList;
          this.getschedulerListCountForPagination();
        } else {
          this.schListsPaginationList = [];
          this.overlay = false;
          this.showloader = false;
        }
      });
    },
    lastUpadtedTime(timestamp) {
      return moment(timestamp)
        .toString();
    },
    getschedulerListCountForPagination() {
      this.paginationOpts['totalItems'] = this.schList.length;
      this.schListsPaginationList = this.schList.slice(0, this.paginationOpts.pageSize);
      this.goToSelectedPage(this.paginationOpts.page, this.paginationOpts.pageSize);
      this.componentKey++;
    },
    pagenate(paginationOpts) {
      if (paginationOpts) {
        this.paginationOpts.page = paginationOpts.page;
        this.paginationOpts.pageSize = paginationOpts.pageSize;
        const pageNum = paginationOpts.page;
        const { pageSize } = paginationOpts;
        this.goToSelectedPage(pageNum, pageSize);
      }
    },
    goToSelectedPage(pageNum, pageSize) {
      this.schListsPaginationList = this.schList.filter((row, index) => {
        const start = (pageNum - 1) * pageSize;
        const end = pageNum * pageSize;
        if (index >= start && index < end) {
          return true;
        }
        return false;
      });
    },
    onSort(colIndex, headingName, event) {
      // if your tracked sort order is 0 (none), set it 1 (ascending)
      // if there is already an order being tracked inverse it
      this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

      if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
        this.$set(this.schHeaders[this.sortColIndex], 'sorted', 0);
        this.schHeaders[colIndex].sortOrder = 1;
      }

      // track the current column as the one being sorted on
      this.sortColIndex = colIndex;
      this.$set(this.schHeaders[colIndex], 'sorted', this.sortOrder);

      // eslint-disable-next-line max-len
      this.schListsPaginationList.sort((a, b) => (a[`${headingName}`].toLowerCase() >= b[`${headingName}`].toLowerCase() ? this.sortOrder : -this.sortOrder));
    },
    filterKeywords(event) {
      const keyCode = event.keyCode || event.which;
      if (this.keyCodes.includes(keyCode) && (keyCode === 13)) {
        this.searchKeyword();
      } else if (event.keyCode === 8) {
        if (this.searchFilter === '') {
          this.callSchedulerList();
        }
      }
    },
    searchKeyword() {
      this.previousSelectionSchList = this.schList;
      if (this.searchFilter !== '') {
        const result = [];
        for (let i = 0; i < this.publistConfig[0].length; i++) {
          const publistConfigEle = this.publistConfig[0][i];
          for (let j = 0; j < publistConfigEle.length; j++) {
            if (publistConfigEle[j].toLowerCase() === this.searchFilter.toLowerCase()) {
              result.push(this.schedulerList[this.publistConfig[1][i]]);
            }
          }
        }
        this.schList = result;
        this.getschedulerListCountForPagination();
      } else if (this.searchFilter === '') {
        this.callSchedulerList();
      }
    },
    clearSearchBar() {
      this.searchFilter = '';
      this.callSchedulerList();
    },
    enableToolTip() {
      this.toggleForTooltip = true;
    },
    disableToolTip() {
      this.toggleForTooltip = false;
    },
    openDeleteSchedulePopUp(scheduleName) {
      this.toggleDeletePopUp = !this.toggleDeletePopUp;
      this.deleteHoldScheduleName = scheduleName;
    },
    openCopySchedulePopUp(schedule) {
      this.copySchedule = !this.copySchedule;
      const arrayOfSchNames = [];
      for (let i = 0; i < this.schedulerList.length; i++) {
        const element = this.schedulerList[i];
        if (schedule.scheduleName !== element.scheduleName) {
          arrayOfSchNames.push(element.scheduleName);
        }
      }
      this.copyHoldScheduler = { scheduler: schedule, schNamesArray: arrayOfSchNames };
    },
    closeDeletePopUp() {
      this.toggleDeletePopUp = false;
      this.deleteHoldScheduleName = '';
    },
    deleteScheduleByName() {
      this.showloader = true;
      this.overlay = true;
      this.deleteScheduler(this.deleteHoldScheduleName)
        .then((response) => {
          if (response && response.status === 200) {
            this.$refs.notificationComponent.triggerSuccess(response.data);
          } else {
            this.errorText = response.Message || response.data;
            this.$refs.notificationComponent.triggerError(this.errorText);
          }
          this.callSchedulerList();
        })
        .catch((error) => {
          this.errorText = this.internalServerErrMsg;
          this.$refs.notificationComponent.triggerError(this.errorText);
          this.callSchedulerList();
        });
      this.closeDeletePopUp();
    },
    switchToggle(event, scheduleName, scheduleEnable) {
      this.showloader = true;
      this.overlay = true;
      let payload = {};
      if (scheduleEnable === 'true') {
        payload = {
          schName: scheduleName,
          enabled: false,
        };
      } else {
        payload = {
          schName: scheduleName,
          enabled: true,
        };
      }
      this.toggleEnable(payload).then((response) => {
        if (response && response.status === 200) {
          this.$refs.notificationComponent.triggerSuccess(response.data);
        } else {
          this.errorText = response.Message || response.data;
          this.$refs.notificationComponent.triggerError(this.errorText);
        }
        this.callSchedulerList();
      }).catch((error) => {
        this.errorText = this.internalServerErrMsg;
        this.$refs.notificationComponent.triggerError(this.errorText);
        this.callSchedulerList();
      });
    },
    openViewSchedulePopUp(schedule) {
      this.viewSchedulerModal = !this.viewSchedulerModal;
      this.schObjForViewSchedule = schedule;
    },
    setMessage(payload) {
      this.callSchedulerList();
    },
    openCreateSchedulePopUp(schedule, btnType) {
      this.createSchedulerModal = !this.createSchedulerModal;
      this.schObjForCreateSchedule = { btnName: btnType, scheduler: schedule, publistFromSch: this.dataSetPubList };
    },
    openEditScheduleNamePopUp(schedule) {
      this.editSchNameModal = !this.editSchNameModal;
      this.schObjForEditScheduleName = { scheduler: { scheduleName: schedule.scheduleName, description: schedule.description } };
    },
    changeCreateScheduleSucess(message) {
      this.$refs.notificationComponent.triggerSuccess(message);
      this.createSchedulerModal = !this.createSchedulerModal;
      this.callSchedulerList();
    },
    changeCreateScheduleError(message) {
      this.$refs.notificationComponent.triggerError(message);
      this.createSchedulerModal = !this.createSchedulerModal;
    },
    changeEditScheduleNameSucess(message) {
      this.$refs.notificationComponent.triggerSuccess(message);
      this.editSchNameModal = !this.editSchNameModal;
      this.callSchedulerList();
    },
    changeEditScheduleNameError(message) {
      this.$refs.notificationComponent.triggerError(message);
      this.editSchNameModal = !this.editSchNameModal;
    },
    copyScheduleSucess(message) {
      this.$refs.notificationComponent.triggerSuccess(message);
      this.copySchedule = !this.copySchedule;
      this.callSchedulerList();
    },
    copyScheduleError(message) {
      this.$refs.notificationComponent.triggerError(message);
      this.copySchedule = !this.copySchedule;
    },
  },
};
</script>
<style>
.delete-confirmation-modal .mds-modal__container___Mcd-ui {
  min-width: 25vw !important;
}
.heading-label {
  font-size: 35px;
  font-weight: 200;
}
.padding-top {
  padding-top: 1rem;
}
.width-100 {
  width: 100%
}
.position-relative {
  position: relative;
}
.sch-name {
  width: 115%;
  word-break: break-all !important;
  position: relative;
  height: 50px;
}
.padding-right-25 {
  padding-right: 2.5em;
}
.sch-name:hover {
  color: #006fba;
}
.custom-padding {
  padding-left: 0px !important;
}
.align-list-data {
  padding-left: 0.5rem !important;
  padding-top: 0.5rem !important;
}
.sch-lbl-subHead {
  font-size: 25px !important;
}
</style>

<template>
  <div>
    <table class="mds-data-table mds-data-table--large">
      <tbody class="mds-data-table__body">
        <tr>
          <td class="mds-data-table__cell">
            <mds-button
              :id="'addUser-btn' + index"
              icon="person-plus"
              variation="secondary"
              type="button"
              @click="addUserPopOver(group)"
            >
              {{ $t('labels.group.addUser') }}
            </mds-button>
            <mds-popover
              :id="'addUser' + index"
              :ref="'addUser' + index"
              :triggered-by="'addUser-btn' + index"
              position="right-center"
              :title="'Add User-' + index"
              title-hidden
              width="300px"
            >
              <div class="account-menu__user-info">
                <template v-if="noUserError">
                  <mds-field-error
                    id="error-id"
                    visible
                  >
                    {{ $t('labels.group.noUserLeftError') }}
                  </mds-field-error>
                </template>
                <template v-else>
                  <div class="mds-form__select demo-width-100">
                    <select
                      :id="'userList' + index"
                      :ref="'userList' + index"
                      class="mds-form__select-input col-75"
                    >
                      <option
                        v-for="(user, index3) in userListForGroup "
                        :key="index3"
                        class="mds-form__select-option"
                        :value="user"
                      >
                        {{ user }}
                      </option>
                    </select>
                    <div class="mds-form__select-visual-wrap" />
                    <span class="mds-form__select-open-indicator">
                      <svg
                        class="mds-icon mds-form__select-open-icon"
                        aria-hidden="true"
                      >
                        <use xlink:href="#caret-down--s" />
                      </svg>
                    </span>
                  </div>
                  <div
                    class="account-menu__action"
                    style="margin-top:10px"
                  >
                    <mds-button
                      variation="primary"
                      @click="addUserToGroup(group,index)"
                    >
                      {{ $t('labels.group.addUser') }}
                    </mds-button>
                  </div>
                </template>
              </div>
            </mds-popover>
          </td>
          <td class="mds-data-table__cell mds-data-table__cell--right">
            <mds-button
              icon="trash"
              variation="secondary"
              @click="deleteGroup(index)"
            >
              {{ $t('labels.group.deleteGroupButton') }}
            </mds-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'GroupAction',
  props: {
    group: { type: Object, default: null },
    index: { type: Number, default: null },
    companyUsers: { type: Array, default: null },
  },
  data() {
    return {
      userListForGroup: '',
      noUserError: false,
    };
  },
  methods: {
    addUserPopOver(group) {
      this.noUserError = false;
      this.userListForGroup = this.companyUsers.slice().sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .filter(user => user !== '');
      if (group.userList) {
        group.userList.forEach((element) => {
          this.userListForGroup.splice(this.userListForGroup.indexOf(element), 1);
        });
      }
      if (this.userListForGroup.length === 0) {
        this.noUserError = true;
      }
    },
    addUserToGroup(group, index) {
      const userid = this.$refs[`userList${index}`].value;
      group.userList.push(userid);
      this.userListForGroup.splice(this.userListForGroup.indexOf(userid), 1);
      const pop = this.$refs[`addUser${index}`];
      pop.dismiss();
    },
    deleteGroup(index) {
      this.$parent.deleteGroup(index);
    },
  },
};
</script>

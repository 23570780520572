import moment from 'moment';
import * as d3 from 'd3';

/** based on momentJS */
const TIMEUNITS = {
  SECOND: 'second',
  MINUTE: 'minute',
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

export const xAxisPerData = { // hour, year, quarter, month, week, day.
  'hour-1D': { timelineLevel: 'day', xFormat: d3.time.format('%b %d') },
  'hour-5D': { timelineLevel: 'day', xFormat: d3.time.format('%b %d') },
  'hour-15D': { timelineLevel: 'week', xFormat: d3.time.format('%b %d') },
  'day-15D': { timelineLevel: 'week', xFormat: d3.time.format('%b %d') },
  'day-1M': { timelineLevel: 'week', xFormat: d3.time.format('%b %d') },
  'day-3M': { timelineLevel: 'week', xFormat: d3.time.format('%b %d') },
  'day-6M': { timelineLevel: 'month', xFormat: d3.time.format('%b %d') },
  'day-1Y': { timelineLevel: 'quarter', xFormat: d3.time.format('%b %d') },
  'day-YTD': { timelineLevel: 'quarter', xFormat: d3.time.format('%b %d') },
  'day-3Y': { timelineLevel: 'year', xFormat: d3.time.format('%b %d') },
  'day-5Y': { timelineLevel: 'year', xFormat: d3.time.format('%b %d') },
  'week-10Y': { timelineLevel: 'year', xFormat: d3.time.format('%b %d') },
  'week-MAX': { timelineLevel: 'year', xFormat: d3.time.format('%b %d') },
};

export function computeIntervalData(intervalType) {
  const currentDate = moment();
  return {
    '1D': () => moment().subtract(1, 'days').startOf('day'),
    '5D': () => currentDate.subtract(5, 'days').startOf('day'),
    '15D': () => currentDate.subtract(15, 'days').startOf('day'),
    '1M': () => currentDate.subtract(1, 'months').startOf('day'),
    '3M': () => currentDate.subtract(3, 'months').startOf('day'),
    '6M': () => currentDate.subtract(6, 'months').startOf('day'),
    YTD: () => currentDate.subtract(1, 'years').startOf('day'),
    '1Y': () => currentDate.subtract(1, 'years').startOf('day'),
    '3Y': () => currentDate.subtract(3, 'years').startOf('day'),
    '5Y': () => currentDate.subtract(5, 'years').startOf('day'),
    '10Y': () => currentDate.subtract(1, 'years').startOf('day'),
    MAX: () => currentDate.subtract(1, 'years').startOf('day'),
  }[intervalType](currentDate);
}

export function computeRollupStrategy(selectedWindow, FeedTimeUnit) {
  /** Should be either (second, minute, hour, day, week, month and year) */
  /* map based on the FeedTimeUnit from the feeds and the selected Interval window */
  return {
    // map per feed if the feed time unit is Minute > calcualte minutly
    // if feed of type day (daily) use daily roundup strategy
    '1D': { MINUTE: TIMEUNITS.MINUTE, HOUR: TIMEUNITS.HOUR, DAY: TIMEUNITS.DAY },
    '5D': { MINUTE: TIMEUNITS.HOUR, HOUR: TIMEUNITS.HOUR, DAY: TIMEUNITS.DAY },
    '15D': { MINUTE: TIMEUNITS.HOUR, HOUR: TIMEUNITS.HOUR, DAY: TIMEUNITS.DAY },
    '1M': { MINUTE: TIMEUNITS.DAY, HOUR: TIMEUNITS.DAY, DAY: TIMEUNITS.DAY },
    '3M': { MINUTE: TIMEUNITS.DAY, HOUR: TIMEUNITS.DAY, DAY: TIMEUNITS.DAY },
    '6M': { MINUTE: TIMEUNITS.DAY, HOUR: TIMEUNITS.DAY, DAY: TIMEUNITS.DAY },
    YTD: { MINUTE: TIMEUNITS.DAY, HOUR: TIMEUNITS.DAY, DAY: TIMEUNITS.DAY },
    '1Y': { MINUTE: TIMEUNITS.DAY, HOUR: TIMEUNITS.DAY, DAY: TIMEUNITS.DAY },
    '3Y': { MINUTE: TIMEUNITS.DAY, HOUR: TIMEUNITS.DAY, DAY: TIMEUNITS.DAY },
    '5Y': { MINUTE: TIMEUNITS.DAY, HOUR: TIMEUNITS.DAY, DAY: TIMEUNITS.DAY },
    '10Y': { MINUTE: TIMEUNITS.WEEK, HOUR: TIMEUNITS.WEEK, DAY: TIMEUNITS.WEEK },
    MAX: { MINUTE: TIMEUNITS.WEEK, HOUR: TIMEUNITS.WEEK, DAY: TIMEUNITS.WEEK },
  }[selectedWindow][FeedTimeUnit];
}

// initial values for button groups
export const btnGroupOptions = () => [
  {
    id: '1D',
    text: '1D',
    active: true,
  },
  {
    id: '5D',
    text: '5D',
    active: false,
  },
  {
    id: '15D',
    text: '15D',
    active: false,
  },
  {
    id: '1M',
    text: '1M',
    active: false,
  },
  {
    id: '3M',
    text: '3M',
    active: false,
  },
  {
    id: '6M',
    text: '6M',
    active: false,
  },
  {
    id: 'YTD',
    text: 'YTD',
    active: false,
  },
  {
    id: '1Y',
    text: '1Y',
    active: false,
  },
  {
    id: '3Y',
    text: '3Y',
    active: false,
  },
  {
    id: '5Y',
    text: '5Y',
    active: false,
  }, {
    id: '10Y',
    text: '10Y',
    active: false,
  }, {
    id: 'MAX',
    text: 'MAX',
    active: false,
  },
];

export default {
  computeRollupStrategy,
  computeIntervalData,
  btnGroupOptions,
  xAxisPerData,
};

export const treeDataTableOption = {
  option: {
    layout: 'mbg',
    removable: true,
    focusable: true,
    rowsPerPage: 5,
    groupKey: 'group',
  },
  responsiveOption: {
    rowsPerPage: 5,
    removable: true,
    focusable: true,
    groupKey: 'group',
    stickyLeadBreakPoints: [
      {
        stickyColIndices: [0, 1],
        columnsPerPage: 1,
        minWidth: 0,
        maxWidth: 320,
      },
      {
        stickyColIndices: [0, 1],
        columnsPerPage: 2,
        minWidth: 320,
        maxWidth: 640,
      },
    ],
  },
  responsiveTreeOption: {
    removable: true,
    rowsPerPage: 5,
    groupKey: 'group',
    stickyLeadBreakPoints: [
      {
        stickyColIndices: [0, 1],
        columnsPerPage: 1,
        minWidth: 0,
        maxWidth: 320,
      },
      {
        stickyColIndices: [0, 1],
        columnsPerPage: 3,
        minWidth: 320,
        maxWidth: 640,
      },
    ],
  },
  mobileOption: {
    removable: true,
    mobileBreakPoint: 300,
    rowsPerPage: 5,
    groupKey: 'group',
    highlightColumns: ['symbol'],
  },
  columns: [
    {
      id: 'symbol',
      name: 'Symbol',
      dataType: 'tree',
      width: 200,
    },
    {
      id: 'value',
      width: 120,
      name: 'Value',
    },
    {
      id: 'column',
      width: 150,
      name: 'Column',
    },
  ],
};

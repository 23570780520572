<template>
  <div>
    <mds-row
      v-if="isOneCard && (!isTwoCards)"
      class="layout-grid__middle-cards"
      :align-horizontal="'center'"
    >
      <mds-col
        :cols-at-m="11"
        :cols-at-s="12"
        :cols-at-l="11"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="firstCard" />
        </div>
      </mds-col>
    </mds-row>
    <mds-row
      v-if="isTwoCards && !(isThreeCards)"
      :align-horizontal="'center'"
    >
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot
            name="firstCard"
          />
        </div>
      </mds-col>
      <mds-col
        :cols-at-m="5"
        :cols-at-s="12"
        :cols-at-l="5"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot
            name="secondCard"
          />
        </div>
      </mds-col>
    </mds-row>
    <mds-row
      v-if="isThreeCards && !(isFourCards)"
      class="layout-grid__big-card"
      :align-horizontal="'center'"
    >
      <mds-col
        :cols-at-m="4"
        :cols-at-s="12"
        :cols-at-l="4"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="firstCard" />
        </div>
      </mds-col>
      <mds-col
        :cols-at-m="4"
        :cols-at-s="12"
        :cols-at-l="4"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="secondCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="thirdCard" />
        </div>
      </mds-col>
    </mds-row>
    <mds-row
      v-if="isFourCards && !(isFiveCards)"
      class="layout-grid__small-card-height"
      :align-horizontal="'center'"
    >
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="firstCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="secondCard" />
        </div>
      </mds-col>


      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="thirdCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="fourthCard" />
        </div>
      </mds-col>
    </mds-row>
    <mds-row
      v-if="isFiveCards && !(isSixCards)"
      class="layout-grid__small-card-height"
      :align-horizontal="'center'"
    >
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="firstCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="secondCard" />
        </div>
      </mds-col>
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="thirdCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="fourthCard" />
        </div>
      </mds-col>
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="fifthCard" />
        </div>
      </mds-col>
    </mds-row>
    <mds-row
      v-if="isSixCards && !(isSevenCards)"
      class="layout-grid__small-card-height"
      :align-horizontal="'center'"
    >
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="firstCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="secondCard" />
        </div>
      </mds-col>
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="thirdCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="fourthCard" />
        </div>
      </mds-col>
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="fifthCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="sixthCard" />
        </div>
      </mds-col>
    </mds-row>
    <mds-row
      v-if="isSevenCards && !(isEightCards)"
      class="layout-grid__small-card-height"
      :align-horizontal="'center'"
    >
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="firstCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="secondCard" />
        </div>
      </mds-col>
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="thirdCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="fourthCard" />
        </div>
      </mds-col>
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="fifthCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="sixthCard" />
        </div>
      </mds-col>
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="seventhCard" />
        </div>
      </mds-col>
    </mds-row>
    <mds-row
      v-if="isEightCards"
      class="layout-grid__small-card-height"
      :align-horizontal="'center'"
    >
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="firstCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="secondCard" />
        </div>
      </mds-col>
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="thirdCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="fourthCard" />
        </div>
      </mds-col>
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="fifthCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="sixthCard" />
        </div>
      </mds-col>
      <mds-col
        :cols-at-m="6"
        :cols-at-s="12"
        :cols-at-l="6"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="seventhCard" />
        </div>
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="eighthCard" />
        </div>
      </mds-col>
    </mds-row>
  </div>
</template>
<script>
import { MdsCol, MdsRow } from '@mds/layout-grid';


export default {
  name: 'MdsSmartRow',
  components: {
    MdsCol,
    MdsRow,
  },
  computed: {
    hasOne() {
      return (this.$slots.firstCard && this.$slots.firstCard.length);
    },
    hasTwo() {
      return (this.$slots.secondCard && this.$slots.secondCard.length);
    },
    hasThree() {
      return (this.$slots.thirdCard && this.$slots.thirdCard.length);
    },
    hasFour() {
      return (this.$slots.fourthCard && this.$slots.fourthCard.length);
    },
    hasFive() {
      return (this.$slots.fifthCard && this.$slots.fifthCard.length);
    },
    hasSix() {
      return (this.$slots.sixthCard && this.$slots.sixthCard.length);
    },
    hasSeven() {
      return (this.$slots.seventhCard && this.$slots.seventhCard.length);
    },
    hasEight() {
      return (this.$slots.eighthCard && this.$slots.eighthCard.length);
    },
    isOneCard() {
      return (this.hasOne && !(this.hasTwo && this.hasThree && this.hasFour
                        && this.hasFive && this.hasSix && this.hasSeven && this.hasEight));
    },
    isTwoCards() {
      return (this.hasOne && this.hasTwo) && !(this.hasThree && this.hasFour
                        && this.hasFive && this.hasSix && this.hasSeven && this.hasEight);
    },
    isThreeCards() {
      return (this.hasOne && this.hasTwo && this.hasThree)
                         && !(this.hasFour && this.hasFive && this.hasSix && this.hasSeven && this.hasEight);
    },
    isFourCards() {
      return (this.hasOne && this.hasTwo && this.hasThree && this.hasFour)
                        && !(this.hasFive && this.hasSix && this.hasSeven && this.hasEight);
    },
    isFiveCards() {
      return (this.hasOne && this.hasTwo && this.hasThree && this.hasFour
                   && this.hasFive && !(this.hasSix && this.hasSeven && this.hasEight));
    },
    isSixCards() {
      return (this.hasOne && this.hasTwo && this.hasThree && this.hasFour
                   && this.hasFive && this.hasSix && !(this.hasSeven && this.hasEight));
    },
    isSevenCards() {
      return (this.hasOne && this.hasTwo && this.hasThree && this.hasFour
                    && this.hasFive && this.hasSix && this.hasSeven && !(this.hasEight));
    },
    isEightCards() {
      return (this.hasOne && this.hasTwo && this.hasThree && this.hasFour
                    && this.hasFive && this.hasSix && this.hasSeven && this.hasEight);
    },
  },
};
</script>
<style>
  .widget-container-dynamic{
    background-color:  var(--app-background-color-light);
    color: white;
    -webkit-transition: background 1s;
    -moz-transition: background 1s;
    -ms-transition: background 1s;
    -o-transition: background 1s;
    transition: background 1s;
  }
</style>


import Strings from "../../strings.es6";
import Arrays from "../../arrays.es6";
import MpField from "../field/field.es6";
import MpRoot from "../root/root.es6";
import MpFeed, {requireMpFeed} from "../feed/feed.es6";
import MpKeySet from "../keyset/keyset.es6";

/**
 * Callback for asynchronous creation of MpProduct instance.
 * @callback MpProductReceiver
 * @param {?MpProduct}
 */


/**
 * @param {(MpField|*)} mpField
 * @returns {boolean} Whether the given object is a MpField object of type VALUE.
 * @private
 */
function _isValidCol (mpField) {
    return (   mpField instanceof MpField
            && mpField.type() === MpField.Type.VALUE );
}

/**
 * <p>
 *  A product is defined by a feed, key or root(s) and column(s).
 *  Use a product instance to avoid specifying these values
 *  (feed, key, root(s), column(s)) repeatedly.
 * </p>
 */
export default class MpProduct {
    /**
     * @param {MpFeed} feed A feed object
     * @param {(MpKeySet|MpRoot[])} keysetOrRoots A list of key-values or list of roots.
     * @param {MpField[]} columns A list of fields, all must be of type VALUE.
     */
    constructor (feed, keysetOrRoots, columns) {
        requireMpFeed(feed, "feed");

        let isKeySet = (keysetOrRoots instanceof MpKeySet);

        if (   (   !isKeySet
                && !Arrays.isArrayOf(keysetOrRoots, MpRoot) )
            || keysetOrRoots.length === 0 ) {
            throw new TypeError("keysetOrRoots: Array-of-MpKeyValue or Array-of-MpRoot, non-empty");
        }

        if (   !Arrays.isValid(columns, _isValidCol)
            || columns.length === 0 ) {
            throw new TypeError("columns: Array-of-MpField, of type VALUE");
        }

        /*
         * We could validate that the keys, roots and columns all belong
         * to the given feed.  When `keysetOrRoots`, we could even validate
         * that all keys have been provided for that feed.
         *
         * But that might just be overkill.  It might even be
         * counter-productive.  So we don't.
         */

        let key;
        if (isKeySet) {
            key = keysetOrRoots;
        } else {
            key = Arrays.slice(keysetOrRoots);
            key.sort(MpRoot.compare);
            key = Object.freeze(key);
        }

        // Do not sort columns, that would change the definition of the "product".

        this._feed  = feed;
        this._key   = key;
        this._cols  = Object.freeze(Arrays.slice(columns));

        Object.freeze(this);
    }

    /** @returns {MpFeed} Product's feed. */
    feed () { return this._feed; }

    /**
     * Returns the product's columns.
     * @returns {MpField[]} Immutable list of MpField objects, all of type VALUE.
     */
    columns () { return this._cols; }

    /**
     * @returns {boolean} Whether this product is comprised of a key-set (true) or roots (false).
     */
    isKeySet () {
        return (this._key instanceof MpKeySet);
    }

    /** @returns {?(MpKeySet)} Product's key-set, may be `null`. */
    keySet () {
        if (this.isKeySet()) {
            return this._key;
        } else {
            return null;
        }
    }

    /** @returns {?(MpRoot[])} Product's roots sorted by name (immutable), may be null. */
    roots () {
        if (this.isKeySet()) {
            return null;
        } else {
            return this._key;
        }
    }

    /**
     * Returns a string representation of this product.
     * @returns {string}
     */
    toString () {
        return Strings.build(
            "{ feed: {}, {}, cols: {} }",
            JSON.stringify(this._feed),
            (  (this.isKeySet())
             ? "key: " + this._key.toString()
             : "roots: " + JSON.stringify(this._key) ),
            JSON.stringify(this._cols)
        );
    }

    /**
     * Returns whether *that* is a MpProduct object that represents
     * the same product.  The same product is defined as the same
     * feed, same keys or same roots (in any order), and same columns
     * (in same order.)
     *
     * @param that {*}
     * @returns {boolean} Returns true if *that* instance represents
     *                    the same product as this instance.
     */
    equals (that) {

        let isEqual = (   (that instanceof MpProduct)
                       && this._feed.equals(that._feed)
                       && Arrays.areEqual( this._cols,
                                               that._cols,
                                               MpField.areEqual ) );

        if (isEqual) {
            if (this.isKeySet()) {
                isEqual = this._key.equals(that._key);
            } else {
                isEqual = Arrays.areEqualShuffled(
                    this._key,
                    that._key,
                    true,
                    MpRoot.areEqual
                );
            }
        }

        return isEqual;
    }
}

/* eslint-disable no-use-before-define */

import $ from 'jquery';
import _ from 'underscore';
import Text from './lang/mpproductsel_en-us.es6';

import { EnumBase } from '../enums.es6';
import Strings, { requireNonEmptyString } from '../strings.es6';
import Objects, { isVoid } from '../objects.es6';
import Arrays from '../arrays.es6';
import Functions from '../functions.es6';
import MpFeed from '../mp/feed/feed.es6';
import MpField from '../mp/field/field.es6';
import MpRoot from '../mp/root/root.es6';
import MpKeyValue from '../mp/keyvalue/keyvalue.es6';
import MpKeySet from '../mp/keyset/keyset.es6';
import Events from '../events.es6';
import Timer from '../timer.es6';
// import UI from '../ui.es6';
import MpUtils from '../mp/utils.es6';
import MpProduct from '../mp/product/product.es6';
// import RemoteMcdUi from '../remotemcdui/remotemcdui.es6';
// import {
//   isConfigured as isConfiguredServerlessApp,
//   getHostUrl as getServerlessAppHostUrl,
// } from '../../js/runtime/mpui.es6';


/**
 * An object that contains properties used during multi-selection search.
 * @typedef {Object} SearchUI
 * @property topDiv {JQuery} - Top, wrapper element.
 * @property listDiv {JQuery} - Parent element for attaching row elements.
 * @property searchBox {JQuery} - Search-box for users to filter their search.
 * @property addNew {JQuery} - Button to add a new item.
 * @property {?SearchRow[]} rows - Rows available for search.
 * @property lastFilter {?string} - Last filter applied.
 * @property feedName {?string} - The feed for which the rows are for.
 */

/**
 * A row available for search.
 * @typedef Object SearchRow
 * @property item {(MpField|MpRoot)} - Object associated with this row.
 * @property wrap {JQuery} - Wrapper element (DIV)
 * @property checkbox {JQuery} - Checkbox element (INPUT[type="checkbox"])
 */

/**
 * A selection to display on the screen.  We use `Selection` when
 * caller chooses what to display.  We keep the `Selection` object
 * handy as all the asynchronous calls occur, to remember what
 * we're trying to display.
 * @typedef Object ProductSelection
 * @property feed {string} - Feed name.
 * @property roots {?string[]} - Roots; may be null.
 * @property key {?Object} - Key-values; may be null.
 * @property cols {string[]} - Column names.
 */

/* **************************************************
 * Private variables
 * ************************************************** */

const DATA_IS_INITIALIZED = 'mp-is-initialized';
const PROP_SEARCH_ROOTS = 'rootSearch';
const PROP_SEARCH_COLS = 'colSearch';
const CSS_LOADING = 'loading';
const CSS_NOT_AVAIL = 'not_avail';
const CSS_NOT_CONFIRMED = 'not_confirmed';

/** @type MpFeedController */
let _feedCtrl = null;


/* **************************************************
 * Enum: Type
 * ************************************************** */

/**
 * A type of product.  Use items of this enum type to filter
 * products to be selected within an instance of MpProductSel.
 * @enum
 * @name MpProductSel.Type
 */
const Type = EnumBase.finalize({
  /**
     * Root-type items - a.k.a. aggregation of contracts.
     * @see MpProductSel.Type
     */
  ROOTS: new EnumBase.Item('ROOTS', 'roots'),

  /**
     * Key-type items - a.k.a. symbols.
     * @see MpProductSel.Type
     */
  KEYS: new EnumBase.Item('KEYS', 'keys'),
}, 'MpProductSel.Type');

/* **************************************************
 * Private methods
 * ************************************************** */

/**
 * No-op, returns false.
 * @returns {boolean}
 * @private
 */
function _returnFalse() {
  return false;
}

/**
 * Validates `arg` to be a boolean, throws if invalid.
 * @param {boolean} arg - Argument to validate.
 * @param {string} argName - Name given to `arg`.
 * @returns {boolean} Always returns `arg`.
 * @throws {TypeError} If `arg` is not valid.
 * @private
 */
function _requireBool(arg, argName) {
  if (typeof arg !== 'boolean') { throw new TypeError(`${argName }: Boolean`); }

  return arg;
}

/**
 * Returns the name of any given item.
 * Expected items are (but not limited to):
 *
 * <ul>
 *  <li> MpField </li>
 *  <li> MpRoot </li>
 *  <li> MpKeyValue </li>
 * </ul>
 *
 * As long as `item` has a method called `name()`,
 * this function will return its name.
 *
 * @param {(MpField|MpRoot|MpKeyValue|*)} item
 * @returns {string}
 * @private
 */
function _mapToName(item) {
  return item.name();
}

/**
 *
 * @param feedName {string}
 * @param key {?Object}
 * @param roots {?string[]}
 * @param cols {string[]}
 * @returns {ProductSelection}
 * @private
 */
function _newSelection(feedName, key, roots, cols) {
  return {
    feed: feedName,
    key,
    roots,
    cols,
  };
}

/**
 * @param {string} name
 * @returns {MpRoot} Newly created root.
 * @private
 */
function _newRoot(name) {
  return MpRoot.fromName(name, Text.SearchRootCreate.newRootDescr);
}

/**
 * Execute this method when user makes a change on the screen.
 * This method sends *change* event; it can do other things too.
 *
 * @param mpProdSel {MpProductSel}
 * @private
 */
function _userChg(mpProdSel) {
  // User is taking control, reset *display* memory.
  mpProdSel._priv.display = null;

  // Send *change* event.
  mpProdSel.events.send('change', mpProdSel);
}

/**
 * Keep track of busy vs. idle messages, and sends
 * appropriate event when cnt moves up from zero,
 * or comes back down to zero again.
 *
 * @param mpProdSel {MpProductSel}
 * @param isBusy {boolean}
 * @private
 */
function _setBusy(mpProdSel, isBusy) {
  // Protect against bad code, internally.
  if (!(mpProdSel instanceof MpProductSel)) { throw new TypeError('mpProdSel: MpProductSel'); }

  _requireBool(isBusy, 'isBusy');

  const priv = mpProdSel._priv;
  if (isBusy) {
    priv.busyCnt++;
    if (priv.busyCnt === 1) { mpProdSel.events.send('busy', mpProdSel); }
  } else {
    priv.busyCnt--;
    if (priv.busyCnt === 0) {
      mpProdSel.events.send('idle', mpProdSel);
    } else if (priv.busyCnt < 0) { priv.busyCnt = 0; } // Protect against too many calls to _setBusy(false).
  }
}

/**
 * Convenience method for dealing with MP server errors.
 *
 * @param mpProdSel {MpProductSel}
 * @param payload {(int|ServerError|*)}
 * @returns {boolean} Whether `payload` was an error that
 *          this method handled.
 * @private
 */
function _isHandledError(mpProdSel, payload) {
  return MpUtils.isHandledError(payload, mpProdSel._diagCtrl);
}

/**
 * Sets the list of options to a temporary "loading...".
 * @param {JQuery} select
 * @private
 */
function _setLoading(select) {
  select.empty().addClass(CSS_LOADING);
  _addSelOption(select, '', Text.loading);

  return select;
}

/**
 * Returns whether data-sources and feeds are currently being fetched
 * (during initialization.)
 * @param mpProdSel {MpProductSel}
 * @returns {boolean}
 * @private
 */
function _isLoadingDataSources(mpProdSel) {
  const { datasrc } = mpProdSel._ui;
  return (datasrc.hasClass(CSS_LOADING)
            || datasrc[0].options.length === 0);
}

/**
 * Adds an OPTION element to a SELECT element.
 * @param select {JQuery}
 * @param val {string}
 * @param [text=val] {string}
 * @returns {JQuery} `select` argument.
 * @private
 */
function _addSelOption(select, val, text) {
  if (arguments.length < 3) { text = val; }

  $('<option>').appendTo(select).val(val).text(text);

  return select;
}

/**
 * Clears the inputs that are dependent of the selected feed.
 * @param mpProdSel {MpProductSel}
 * @private
 */
function _clearFeedDependents(mpProdSel) {
  const ui = mpProdSel._ui;
  const priv = mpProdSel._priv;

  ui.cols.empty();
  ui.colsBtn.disable();
  ui.rsRoots.disable();
  ui.lblRoots.disable();
  ui.rsKey.disable();
  ui.lblKey.disable();
  ui.roots.empty();
  ui.rootsBtn.disable();
  ui.keyArea.empty();


  priv.keys = null;
  priv.cols = null;
  priv.roots = null;
  priv.keyForFeed = null;
}

/**
 * Loads the data-source names in the appropriate SELECT element.
 * @param mpProdSel {MpProductSel}
 * @private
 */
function _buildDataSources(mpProdSel) {
  _setBusy(mpProdSel, false);

  const ui = mpProdSel._ui;
  const selDatasrc = ui.datasrc;
  const selFeed = ui.feed;

  selDatasrc.empty().removeClass(CSS_LOADING);
  selFeed.empty().removeClass(CSS_LOADING);

  _addSelOption(selDatasrc, '', Text.selectOne);

  _.each(_feedCtrl.dataSources(), (dataSourceName) => {
    _addSelOption(selDatasrc, dataSourceName);
  });

  const priv = mpProdSel._priv;
  const { display } = priv;

  if (display !== null) { _displayFeed(mpProdSel, display); }

  ui.feedCreate.isSensitive(priv.isSensitive); // If visible, this button is now safe to use.
}


/**
 *
 * @param mpProdSel {MpProductSel}
 * @private
 */
function _loadFeeds(mpProdSel) {
  if (_feedCtrl !== null) { _buildDataSources(mpProdSel); } else {
    const callback = function (feedCtrl) {
      if (!_isHandledError(mpProdSel, feedCtrl)) {
        _feedCtrl = feedCtrl;
        _buildDataSources(mpProdSel);
      }
    };

    if (MpFeed.load(callback)) {
      _setBusy(mpProdSel, true);
      _setLoading(mpProdSel._ui.datasrc);
      _setLoading(mpProdSel._ui.feed);
    }
  }
}

/**
 * Starts building the area where keys are shown.
 * This method empties the given element and creates
 * TABLE and TBODY elements.
 * @param keyArea {JQuery} HTMLElement
 * @returns {JQuery} TBODY element
 * @private
 */
function _buildKeyAreaStart(keyArea) {
  keyArea.empty();
  const table = $('<table>').appendTo(keyArea);

  return $('<tbody>').appendTo(table);
}

/**
 * Adds a row to the table of keys.
 * @param mpProdSel {MpProductSel}
 * @param tbody {JQuery} HTML TBODY element.
 * @param name {string} Key name and label.
 * @param isEnable {boolean} Whether the input is enable for changes.
 * @returns {{tr: JQuery, input: JQuery}}
 * @private
 */
function _buildKeyRow(mpProdSel, tbody, name, isEnable) {
  const tr = $('<tr>').appendTo(tbody);
  const th = $('<td>').appendTo(tr).text(name);
  const td = $('<td>').appendTo(tr);
  const input = $('<input type="text">').attr('name', name).appendTo(td);

  if (!mpProdSel._priv.isSensitive) {
    // Disable (as opposed to read-only) for consistent look throughout the dialog.
    input.disable();
  } else if (isEnable === false) { input.prop('readonly', true); } else {
    /*
         * I'm taking the lazy route right now, monitoring the
         * input's *change* event, to propagate the *change* event
         * of MpProductSel.
         *
         * This requires users to leave the INPUT element in order for
         * the *change* event to be sent.
         *
         * Ideally, we'd monitor *keyup*, *paste*, *cut* events, and
         * send *change* after a short delay (to avoid excessive
         * computation.)
         */

    input.on('change', null, mpProdSel, _keyValueChange);

    // TODO - monitor *keyup*, *paste*, *cut* events for searching
  }

  return {
    tr,
    input,
  };
}

/**
 * Builds the are where users "search" for key values.
 * This method only builds the area if the selected
 * feed differs from the last one.
 *
 * @param mpProdSel {MpProductSel}
 * @param feedName {string}
 * @returns {boolean} Whether the area was re-built.
 * @private
 */
function _buildKeyArea(mpProdSel, feedName) {
  const ui = mpProdSel._ui;
  const priv = mpProdSel._priv;
  const { keyArea } = ui;

  if (priv.keyForFeed === feedName
        || priv.keys === null) { // Keys haven't arrived yet.
    return false;
  }

  const tbody = _buildKeyAreaStart(keyArea);

  _.each(priv.keys, (mpKeyField) => {
    _buildKeyRow(mpProdSel, tbody, mpKeyField.name(), true);
  });

  priv.keyForFeed = feedName;
  return true;
}

/**
 * Shows a list of keys.
 * @param mpProdSel {MpProductSel}
 * @param key {Object} A JSON representation of the key-values to display.
 * @param cssClass {string} CSS class to use for when a key from `key`
 *                          isn't found in `avail`.
 * @param avail {MpField[]} A list of valid keys.
 * @private
 */
function _showKeys(mpProdSel, key, cssClass, avail) {
  const ui = mpProdSel._ui;
  const { keyArea } = ui;
  const tbody = _buildKeyAreaStart(keyArea);
  const keyNames = Objects.properties(key, true);

  _.each(keyNames, (keyName) => {
    const isAvail = (_getItemIndex(keyName, avail) >= 0);
    const row = _buildKeyRow(mpProdSel, tbody, keyName, isAvail);

    row.input.val(key[keyName]);

    if (!isAvail) row.tr.addClass(cssClass);
  });
}

/**
 * Shows columns, roots or keys of an unconfirmed (or no longer available) feed.
 * @param mpProdSel {MpProductSel}
 * @param display {Object}
 * @param fnItemToText {function}
 * @param cssClass {string}
 * @private
 */
function _showUnverifiedFeedInfo(mpProdSel, display, fnItemToText, cssClass) {
  const ui = mpProdSel._ui;

  _setSelectValuesPrimitive(ui.cols,
    display.cols,
    fnItemToText,
    cssClass);

  if (display.roots !== null) {
    _setSelectValuesPrimitive(ui.roots,
      display.roots,
      fnItemToText,
      cssClass);
  } else { _showKeys(mpProdSel, display.key, cssClass, []); }
}


/**
 * Returns whether user has entered a non-empty string value for all keys.
 * @param mpProdSel {MpProductSel}
 * @returns {boolean}
 * @private
 */
function _hasAllKeys(mpProdSel) {
  const elms = mpProdSel._ui.keyArea.find('input[type="text"]');
  const hasSomeEmpty = _.some(elms, elm => Strings.isEmpty(elm.value));

  return !hasSomeEmpty;
}

/**
 * Returns the list of INPUT elements within `keyArea`.
 * @param mpProdSel {MpProductSel}
 * @returns {JQuery} List of HTMLInputElement (array-like).
 * @private
 */
function _getKeyAreaInputs(mpProdSel) {
  return mpProdSel._ui.keyArea.find('input[type="text"]');
}

/**
 * Returns a MpKeySet object based on current key values.
 * @param mpProdSel {MpProductSel}
 * @return {MpKeySet}
 * @private
 */
function _getKeySet(mpProdSel) {
  const elms = _getKeyAreaInputs(mpProdSel);

  const keyValues = _.map(
    elms,
    /** @param {HTMLInputElement} elm */
    (elm) => {
      const mpKey = _getItem(elm.name, mpProdSel._priv.keys);
      return new MpKeyValue(mpKey, elm.value.trim());
    },
  );

  return new MpKeySet(keyValues);
}

/**
 * Sets the on-screen key values to match the given key-set.
 * If some value(s) could not be set, they are returned
 * to the caller (as a list of MpKeyValue objects).
 * Callers should check the returned value of this method
 * and behave appropriately.
 * @param mpProdSel {MpProductSel}
 * @param keySet {MpKeySet}
 * @returns {MpKeyValue[]} Key-values that couldn't be set.
 * @private
 */
function _setKeySet(mpProdSel, keySet) {
  const failList = [];
  const keyElms = _getKeyAreaInputs(mpProdSel);
  const keyElmByName = _.indexBy(keyElms, elm => elm.name);

  _.each(keySet.list(), (keyVal) => {
    const keyName = keyVal.name();
    if (!Object.hasOwnProperty.call(keyElmByName, keyName)) failList.push(keyVal);
    else {
      keyElmByName[keyName].value = keyVal.value();
    }
  });

  return failList;
}

/**
 * Returns whether the SELECT element contains valit OPTION element(s).
 * This method does not treat "loading..." as a valid OPTION element.
 * @param select {JQuery} SELECT element
 * @returns {boolean} *true* if `select` contains at least one non-blank option.
 * @private
 */
function _containsOptions(select) {
  return (select[0].options.length > 0
            && !select.hasClass(CSS_LOADING));
}

/**
 * Returns the index position of the item found in `list`.
 * @param {string} name - Name of the sought item - field or root. Case-insensitive.
 * @param {(MpField[]|MpRoot[])} list - List to look through.
 * @returns {int} Index position of the item found in `list`.
 * @private
 */
function _getItemIndex(name, list) {
  return Arrays.indexOf(name, list, 'name()', Strings.equalsIgnoreCase);
}

/**
 * Returns the named item (key-field or root)
 * @param {string} name
 * @param {(MpField[]|MpRoot[])} avail - Item superset.
 * @returns {(MpField|MpRoot)}
 * @private
 */
function _getItem(name, avail) {
  const idx = _getItemIndex(name, avail);
  if (idx < 0) { throw new Error(`IllegalStateException: item not found in superset (${ name })`); }

  return avail[idx];
}

/**
 * Returns the selected item(s) as a list (MpField or MpRoot).
 * @param select {JQuery} SELECT element.
 * @param avail {(MpField[]|MpRoot[])} Item superset.
 * @returns {(MpField[]|MpRoot[])}
 * @private
 */
function _getItems(select, avail) {
  const names = _.map(select[0].options, _getOptionValue);

  return _.map(names, name => _getItem(name, avail));
}

/**
 * Returns whether an instance of MpProductSel
 * allows the given `type` to be selected.
 * @param {MpProductSel} mpProdSel
 * @param {MpProductSel.Type} type
 * @private
 */
function _passesFilter(mpProdSel, type) {
  const filter = mpProdSel._priv.typeFilter;

  return (filter === null
            || filter[type.valueOf()] === type);
}

/**
 * Returns `type` if it passes the current filter set in
 * `mpProdSel.  Otherwise, returns `fallbackValue`.
 * @param {MpProductSel} mpProdSel
 * @param {MpProductSel.Type} type
 * @param {*} fallbackValue
 * @returns {(MpProductSel.Type|*)}
 * @private
 */
function _ifPassesFilter(mpProdSel, type, fallbackValue) {
  if (_passesFilter(mpProdSel, type)) return type;
  return fallbackValue;
}

/**
 * Returns whether the instance's radio button for
 * key-vs-roots is currently set on *key*.
 *
 * @param mpProdSel {MpProductSel}
 * @returns {boolean}
 * @private
 */
function _isUsingKey(mpProdSel) {
  return mpProdSel._ui.rsKey.is(':checked');
}

/**
 * Applies the selection of the key/roots radio buttons.
 * @param mpProdSel {MpProductSel}
 * @private
 */
function _applyKeyVsRoots(mpProdSel) {
  const ui = mpProdSel._ui;
  const feedName = ui.feed.val();
  const isKey = _isUsingKey(mpProdSel);

  ui.roots.isVisible(!isKey);
  ui.rootsBtn.isVisible(!isKey);
  ui.keyArea.isVisible(isKey);
  ui.prodLabel.text((isKey) ? Text.key : Text.roots);

  if (isKey) { _buildKeyArea(mpProdSel, feedName); }
}

/**
 * Sets the key area to prompt for keys or roots, based on `showRootsView`.
 * @param {MpProductSel} mpProdSel
 * @param {boolean} showRootsView
 * @param {boolean} isUserAction
 * @private
 */
function _setRootView(mpProdSel, showRootsView, isUserAction) {
  const ui = mpProdSel._ui;
  const isRootView = !ui.rsKey.prop('checked'); // Using `!` because initially, both radio buttons are unchecked.
  let isSomething = true;

  if (showRootsView
        && _passesFilter(mpProdSel, Type.ROOTS)) {
    ui.rsRoots.prop('checked', true);
  } else if (_passesFilter(mpProdSel, Type.KEYS)) {
    ui.rsKey.prop('checked', true);
  } else { isSomething = false; }

  if (isSomething) {
    _applyKeyVsRoots(mpProdSel);

    if (isUserAction
            && isRootView !== showRootsView) {
      Functions.delay(() => {
        _userChg(mpProdSel);
      });
    }
  }
}

/**
 * Returns whether the currently selected feed is futures-enabled, which means
 * it either has contract-roots already defined, or its definition is comprised of
 * exactly three (3) key fields: Root, DeliveryStart, DeliveryEnd.
 *
 * This method can only run after both *fields* and *roots* have arrived, otherwise
 * it returns false.
 *
 * @param {MpProductSel} mpProdSel
 * @returns {boolean} Whether this feed is futures-enabled, false if *fields* or *roots*
 *          have not arrived yet.
 * @private
 */
function _isFuturesFeed(mpProdSel) {
  return (_isFeedWithRoots(mpProdSel)
            || _isContractEnabledFeed(mpProdSel));
}

/**
 * @param {MpProductSel} mpProdSel
 * @returns {boolean} Whether roots exists for this feed, false if *roots* have not arrived yet.
 * @private
 */
function _isFeedWithRoots(mpProdSel) {
  const { roots } = mpProdSel._priv;

  return (roots !== null
            && roots.length > 0);
}

/**
 * Returns whether the currently selected feed is contract-enabled, which means its
 * key is comprised of three (3) fields: Root, DeliveryStart, DeliveryEnd.
 * @param {MpProductSel} mpProdSel
 * @returns {boolean} Whether the current feed is contract-enabled, false if *fields*
 *          have not arrived yet.
 * @private
 */
function _isContractEnabledFeed(mpProdSel) {
  const { keys } = mpProdSel._priv;

  return (keys !== null
            && keys.length === 3
            && _getItemIndex('root', keys) >= 0
            && _getItemIndex('deliverystart', keys) >= 0
            && _getItemIndex('deliveryend', keys) >= 0);
}

/**
 * Callback for `MpFeed.fields()`.
 * @param mpFields {(MpField[]|ServerError|int)}
 * @private
 */
function _fieldsCB(mpFields) {
  const { feedName } = this;
  const { mpProdSel } = this;
  const ui = mpProdSel._ui;
  const priv = mpProdSel._priv;

  _setBusy(mpProdSel, false);

  if (ui.feed.val() !== feedName) { return; } // Ignore this response, user has moved on.

  if (_isHandledError(mpProdSel, mpFields)) { return; }

  const keys = [];
  const cols = [];

  priv.keys = keys;
  priv.cols = cols;

  _.each(mpFields, (mpField) => {
    if (mpField.type() === MpField.Type.KEY) keys.push(mpField);
    else if (mpField.type() === MpField.Type.VALUE) cols.push(mpField);
  });

  ui.cols.empty().removeClass(CSS_LOADING);

  const canChooseKeys = (keys.length > 0 && _passesFilter(mpProdSel, Type.KEYS));

  if (priv.isSensitive) {
    ui.colsBtn.isSensitive(cols.length > 0);
    ui.rsKey.isSensitive(canChooseKeys);
    ui.lblKey.isSensitive(canChooseKeys);
  }

  // Check to see if we're displaying a specific product.
  const { display } = mpProdSel._priv;
  if (display !== null) {
    _displayFields(mpProdSel, display);
  } else if (priv.roots !== null) { // Roots have arrived
    _setRootView(mpProdSel, _isFuturesFeed(mpProdSel), true);
  }
}

/**
 * Callback for `MpFeed.roots()`.
 * @param mpRoots {(MpRoot[]|ServerError|int)} If array of roots, array
 *        is immutable, sorted by name.
 * @type {MpFeedRootsCallback}
 * @private
 */
function _rootsCB(mpRoots) {
  const { feedName } = this;
  const { mpProdSel } = this;
  const ui = mpProdSel._ui;
  const priv = mpProdSel._priv;

  _setBusy(mpProdSel, false);

  if (ui.feed.val() !== feedName) { return; } // Ignore this response, user has moved on.

  if (_isHandledError(mpProdSel, mpRoots)) { return; }

  priv.roots = [];
  Arrays.addAll(priv.roots, mpRoots);

  const isFutures = _isFuturesFeed(mpProdSel);
  const canChooseRoots = (isFutures
                          && _passesFilter(mpProdSel, Type.ROOTS));

  ui.roots.empty().removeClass(CSS_LOADING);

  if (priv.isSensitive) {
    ui.rootsBtn.isSensitive(canChooseRoots);
    ui.rsRoots.isSensitive(canChooseRoots);
    ui.lblRoots.isSensitive(canChooseRoots);
  }

  // If we're in the process of re-displaying a specific product
  // and that product uses keys, set for the key view (not roots.)

  const { display } = priv;
  if (display === null) {
    _setRootView(mpProdSel, isFutures, true);
  } else if (display.roots !== null) {
    _displayRoots(mpProdSel, display);
  }
}

/**
 * Applies the data-source selection, showing its feeds.
 * @param mpProdSel
 * @returns {string[]} List of feed names inserted into SELECT element.
 * @private
 */
function _applyDataSourceSelection(mpProdSel) {
  const ui = mpProdSel._ui;
  const dsName = ui.datasrc.val();
  const selFeed = ui.feed;
  const feedNames = [];

  selFeed.empty();

  if (Strings.isNonEmpty(dsName)) {
    const mpFeeds = _feedCtrl.feeds(dsName);

    _addSelOption(selFeed, '', Text.selectOne).val('');

    _.each(mpFeeds, (mpFeed) => {
      const feedName = mpFeed.name();

      _addSelOption(selFeed, feedName);
      feedNames.push(feedName);
    });
  }

  _clearFeedDependents(mpProdSel);

  return feedNames;
}

/**
 * Fetches the selected feed's fields and roots.
 * @param mpProdSel {MpProductSel}
 * @private
 */
function _applyFeedSelection(mpProdSel) {
  const ui = mpProdSel._ui;
  const feedName = ui.feed.val();
  let isPrivateFeed = false;

  _clearFeedDependents(mpProdSel);

  if (Strings.isNonEmpty(feedName)) {
    const mpFeed = _feedCtrl.feed(feedName);
    const ctx = {
      mpProdSel,
      feedName,
    };

    isPrivateFeed = mpFeed.isPrivateFeed();

    if (mpFeed.fields(_fieldsCB.bind(ctx))) {
      _setLoading(ui.cols);
      _setBusy(mpProdSel, true);
    }

    if (mpFeed.roots(_rootsCB.bind(ctx))) {
      _setLoading(ui.roots);
      _setBusy(mpProdSel, true);
    }
  }

  ui.feedEdit.isSensitive(isPrivateFeed);
}

/**
 * Callback for *change* event of data source SELECT element.
 * @param event {JQuery.Event}
 * @private
 */
function _datasrcChange(event) {
  const mpProdSel = event.data;

  _applyDataSourceSelection(mpProdSel);
  _userChg(mpProdSel);
}

/**
 * Callback for *change* event of data source SELECT element.
 * @param event {JQuery.Event}
 * @private
 */
function _feedChange(event) {
  const mpProdSel = event.data;

  _applyFeedSelection(mpProdSel);
  _userChg(mpProdSel);
}


/**
 * Callback for *click* event of "new feed" button.
 * @param {JQuery.Event} event
 * @private
 */
function _newFeedClick(event) {
  _openFeedUi(event.data, '/feeds/new?embedded=true');
}

/**
 * Callback for *click* event of "edit feed" button.
 * @param {JQuery.Event} event
 * @private
 */
function _editFeedClick(event) {
  /** @type {MpProductSel} */
  const mpProdSel = event.data;

  _openFeedUi(
    mpProdSel,
    `/feeds/view/${encodeURIComponent(mpProdSel._ui.feed.val())}?embedded=true`,
  );
}

/**
 * Opens a dialog for the purpose of maintaining a feed -
 * either create or edit a feed.
 * @param {MpProductSel} mpProdSel
 * @param {string} targetUrl
 * @private
 */
function _openFeedUi(mpProdSel, targetUrl) {
  const win = mpProdSel._diagCtrl;

  const feedUi = new RemoteMcdUi(getServerlessAppHostUrl(), targetUrl);

  feedUi.events.bind('message', (msg) => {
    if (msg.type === 'routes/FeedsNew/SAVE_FEED_SUCCESS') {
      // Update the global cache, assuming current user has READ access to the newly created feed.
      const mpFeed = MpFeed.addToCache(msg.feed);
      _selectNewFeed(mpProdSel, mpFeed);
    }
  });

  win.prompt(feedUi.render(win), {
    className: 'mp-edit-feed',
    buttons: ['done'],
    callback: () => {
      Functions.delay(() => {
        feedUi.destroy();
      });
    },
  });
}

/**
 * Sets the selected feed, calls for fields, roots.
 * @param {MpProductSel} mpProdSel
 * @param {MpFeed} mpFeed
 * @private
 */
function _selectNewFeed(mpProdSel, mpFeed) {
  // Select the newly created feed (by setting both data-source and feed SELECT).
  const ui = mpProdSel._ui;

  // User is taking control, reset *display* memory.
  mpProdSel._priv.display = null;

  ui.datasrc.val(mpFeed.dataSource());
  _applyDataSourceSelection(mpProdSel);

  ui.feed.val(mpFeed.name());
  _applyFeedSelection(mpProdSel);

  // Send *change* event.
  mpProdSel.events.send('change', mpProdSel);
}


/**
 *
 * @param item {(MpField|MpRoot)}
 * @param wrap {JQuery}
 * @param checkbox {JQuery}
 * @returns {SearchRow}
 * @private
 */
function _newSearchRow(item, wrap, checkbox) {
  return {
    item,
    wrap,
    checkbox,
  };
}

/**
 * Creates a search UI.
 * @param cssClass {string} Additional CSS class to add to top element.
 * @returns {SearchUI}
 * @private
 */
function _newSearchUI(cssClass) {
  const topDiv = $('<div>').addClass('mp-product-select-search')
    .addClass(cssClass);

  $('<label>').appendTo(topDiv).text(Text.search);

  const searchBox = $('<input type="text">').appendTo(topDiv);
  const addNew = $('<button type="button">').appendTo(topDiv).hide()
    .attr('title', Text.SearchRootCreate.tooltip);
  const list = $('<div>').addClass('list').appendTo(topDiv);

  return {
    topDiv,
    searchBox,
    addNew,
    listDiv: list,
    rows: null,
    lastFilter: null,
    feedName: null,
  };
}

/**
 * Destroys a search UI.
 * @param searchUI {SearchUI}
 * @private
 */
function _destroySearchUI(searchUI) {
  if (searchUI !== null) {
    searchUI.rows = null;
    searchUI.topDiv.remove();
  }
}


/**
 * Returns the search UI that belongs to the given MpProductSel instance.
 * @param mpProdSel {MpProductSel}
 * @param prop {string} Name of `_priv` property in which the search UI resides.
 * @param cssClass {string} Additional CSS class to add to top element.
 * @returns {SearchUI}
 * @private
 */
function _getSearchUI(mpProdSel, prop, cssClass) {
  const priv = mpProdSel._priv;
  let searchUI = priv[prop];

  if (searchUI === null) {
    searchUI = _newSearchUI(cssClass);
    priv[prop] = searchUI;

    searchUI.searchBox.on('keyup', null, mpProdSel, _searchBoxChanged)
      .on('paste', null, mpProdSel, _searchBoxChanged)
      .on('cut', null, mpProdSel, _searchBoxChanged)
      .on('keydown', null, mpProdSel, _searchBoxGo);
  }

  return searchUI;
}

/**
 * Returns an option element's value.
 * @param option {HTMLOptionElement}
 * @returns {string}
 * @private
 */
function _getOptionValue(option) {
  return option.value;
}

/**
 * Creates an index of currently selected values.
 * @param selectElm {JQuery}
 * @returns {Object} An object with a key set to `true` for
 *                   each option within the SELECT
 *                   element.
 * @private
 */
function _indexOfSelectedValues(selectElm) {
  return _.indexBy(selectElm[0].options, _getOptionValue);
}

/**
 * Resets the rows available for search.  Resets the filter too.
 * @param {string} feedName - Name of the feed.
 * @param {SearchUI} ui - UI object.
 * @param {(MpField[]|MpRoot[])} list - Available list to choose from.
 * @param {function} toText - Function to convert list-items to text.
 * @param {Object.<string. *>} selected - Map of selected values.
 * @param {boolean} isMulti - Whether multi-selection is allowed.
 * @returns {boolean} Whether a reset actually occurred.
 * @private
 */
function _resetSearchList(feedName, ui, list, toText, selected, isMulti) {
  if (feedName === ui.feedName) { return false; }

  const objType = ((isMulti) ? 'checkbox' : 'radio');
  const rows = [];

  ui.listDiv.empty();

  _.each(list, /** @param {(MpField|MpRoot)} item */ (item) => {
    _newSearchRowHtml(rows, item, objType, _.has(selected, item.name()), toText)
      .appendTo(ui.listDiv);
  });

  ui.searchBox.val('');
  ui.rows = rows;
  ui.feedName = feedName;
  ui.lastFilter = '';

  return true;
}

/**
 * Creates HTML elements necessary to append a new row in the superset of searchable rows.
 * @param {SearchRow[]} rows - Current list of rows.
 * @param {(MpField|MpRoot)} item - Item to append.
 * @param {string} objType - "checkbox" or "radio"
 * @param {boolean} isChecked - Whether the INPUT element is checked.
 * @param {function} toText - Function to convert `item` to user-friendly text.
 * @returns {JQuery} Unattached DIV element; caller must attach it to DOM document.
 * @private
 */
function _newSearchRowHtml(rows, item, objType, isChecked, toText) {
  const wrap = $('<div>');
  const checkbox = $(`<input type="${ objType }" name="list_val">`)
    .val(item.name())
    .appendTo(wrap);

  if (isChecked) { checkbox.prop('checked', true); }

  $('<span>').appendTo(wrap).text(toText(item));

  if (rows.length % 2 === 0) { wrap.addClass('rowodd'); }

  rows.push(_newSearchRow(item, wrap, checkbox));

  return wrap;
}

/**
 * Applies a search filter, updates the visible rows.
 * @param ui {SearchUI}
 * @param fnMatch {function}
 * @private
 */
function _searchBoxNow(ui, fnMatch) {
  const filter = ui.searchBox.val();

  if (filter === ui.lastFilter) { return; }

  ui.lastFilter = filter;

  const wildPatt = Strings.wildcardPattern(filter);
  const regex = new RegExp(`\\b${ wildPatt.replace(/\s+/, '.*\\b')}`, 'i');

  const { rows } = ui;
  let numVisible = 0;

  for (let i = 0, len = rows.length; i < len; i++) {
    const row = rows[i];
    const { wrap } = row;

    if (!fnMatch(row.item, regex)
            && !row.checkbox.prop('checked')) {
      wrap.hide();
    } else {
      wrap.show();
      if ((++numVisible) % 2 === 0) { wrap.addClass('rowodd'); } else { wrap.removeClass('rowodd'); }
    }
  }
}

/**
 * Returns a list of *item* chosen by user within search dialog-box.
 * @param searchRows {SearchRow[]}
 * @returns {(MpField[]|MpRoot[])}
 * @private
 */
function _getSearchSelection(searchRows) {
  const items = [];

  for (let i = 0, len = searchRows.length; i < len; i++) {
    const searchRow = searchRows[i];

    if (searchRow.checkbox[0].checked) { items.push(searchRow.item); }
  }

  return items;
}

function _clearSelectValues(select) {
  return select.empty()
    .removeClass(CSS_NOT_AVAIL)
    .removeClass(CSS_NOT_CONFIRMED);
}

/**
 *
 * @param select {JQuery} HTMLSelectElement
 * @param items {(MpField[]|MpRoot[])}
 * @param fnItemToText {function}
 * @private
 */
function _setSelectValues(select, items, fnItemToText) {
  _clearSelectValues(select);

  _.each(items, (item) => {
    _addSelOption(select, item.name(), fnItemToText(item));
  });

  return select;
}

function _setSelectValuesPrimitive(select, strItems, fnItemToText, cssClass) {
  _clearSelectValues(select);

  _.each(strItems, (strItem) => {
    _addSelOption(select, strItem, fnItemToText(strItem));
  });

  return select.addClass(cssClass);
}

/**
 * Displays the requested value in a SELECT element, flagging the ones
 * that are not (or no longer) available.
 * @param {string[]} requested
 * @param {(MpField[]|MpRoot[])} items
 * @param {JQuery} select - HTMLSelectElement
 * @param {function} fnItemToText
 * @private
 */
function _displayVsActual(requested, items, select, fnItemToText) {
  _clearSelectValues(select);

  _.each(requested, (nameStr) => {
    const idx = _getItemIndex(nameStr, items);
    if (idx >= 0) _addSelOption(select, nameStr, fnItemToText(items[idx]));
    else {
      const text = Text.optionNotFound.replace('[name]', nameStr);
      _addSelOption(select, nameStr, text).addClass(CSS_NOT_AVAIL);
    }
  });
}

/**
 * Applies the selected values chosen in a dialog-box to
 * a (multi-line) SELECT element.
 * @param searchRows {SearchRow[]}
 * @param select {JQuery} HTMLSelectElement
 * @param fnItemToText {function}
 * @private
 */
function _applySelection(searchRows, select, fnItemToText) {
  _setSelectValues(select, _getSearchSelection(searchRows), fnItemToText);
}


/**
 * Converts a MpField object into a single, user-friendly string.
 * @param mpField {MpField}
 * @returns {string}
 * @private
 */
function _colToText(mpField) {
  return mpField.name();
}

/**
 * Converts a MpRoot object into a single, user-friendly string.
 * @param mpRoot {MpRoot}
 * @returns {string}
 * @private
 */
function _rootToText(mpRoot) {
  return Text.combinedRootString.replace('[name]', mpRoot.name())
    .replace('[descr]', mpRoot.description());
}

/**
 * Adds a "loading" indicator to the given option to be shown
 * (temporarily) in a HTMLSelectOption element.
 * @param text {string}
 * @returns {string}
 * @private
 */
function _textToLoading(text) {
  return Text.optionLoading.replace('[name]', text);
}

/**
 * Adds a "not found" indicator to the given option to be shown
 * in a HTMLSelectOption element.
 * @param text {string}
 * @returns {string}
 * @private
 */
function _textToNotFound(text) {
  return Text.optionNotFound.replace('[name]', text);
}

/**
 * Returns whether the MpField name matches the given regular expression.
 * @param mpField {MpField}
 * @param regex {RegExp}
 * @returns {boolean}
 * @private
 */
function _isFldMatch(mpField, regex) {
  return regex.test(mpField.name());
}

/**
 * Returns whether the MpField name matches the given regular expression.
 * @param mpRoot {MpRoot}
 * @param regex {RegExp}
 * @returns {boolean}
 * @private
 */
function _isRootMatch(mpRoot, regex) {
  return (regex.test(mpRoot.name())
            || regex.test(mpRoot.description()));
}

/**
 * Performs the column search based on current filter.
 * @private
 */
function _colsSearchBoxNow() {
  _searchBoxNow(this._priv[PROP_SEARCH_COLS], _isFldMatch);
}

/**
 * Performs the root search based on current filter.
 * @private
 */
function _rootsSearchBoxNow() {
  _searchBoxNow(this._priv[PROP_SEARCH_ROOTS], _isRootMatch);
}

/**
 * Applies selected columns.
 * @param btnName {string} "apply" or "cancel"
 * @returns {boolean} Returns *false* if dialog must remain open.
 * @this {MpProductSel}
 * @private
 */
function _applyColSelection(btnName) {
  if (btnName === 'apply') {
    const mpProdSel = this;
    const searchUI = mpProdSel._priv[PROP_SEARCH_COLS];

    _applySelection(searchUI.rows, mpProdSel._ui.cols, _colToText);
    _userChg(mpProdSel);
  }
}

/**
 * Applies selected roots.
 * @param btnName {string} "apply" or "cancel"
 * @returns {boolean} Returns *false* if dialog must remain open.
 * @this {MpProductSel}
 * @private
 */
function _applyRootSelection(btnName) {
  if (btnName === 'apply') {
    const mpProdSel = this;
    const searchUI = mpProdSel._priv[PROP_SEARCH_ROOTS];

    _applySelection(searchUI.rows, mpProdSel._ui.roots, _rootToText);
    _userChg(mpProdSel);
  }
}

/**
 * Callback for *change* event of search-box (keyup, paste, cut).
 * @param event {JQuery.Event}
 * @private
 */
function _searchBoxChanged(event) {
  const mpProdSel = event.data; // Injected at binding
  const timer = mpProdSel._priv.searchTimer;

  timer.reset();
}

/**
 * Callback for *mousedown* event of search-box.
 * @param {JQuery.Event} event
 * @private
 */
function _searchBoxGo(event) {
  if (event.which === 13) { // enter
    const mpProdSel = event.data; // Injected at binding
    const timer = mpProdSel._priv.searchTimer;

    timer.tick();
    event.preventDefault();
  }
}


/**
 * Callback for choosing columns.
 * @param event {JQuery.Event}
 * @private
 */
function _chooseCols(event) {
  /** @type {MpProductSel} */
  const mpProdSel = event.data;
  const searchUI = _getSearchUI(mpProdSel, PROP_SEARCH_COLS, 'cols');
  const ui = mpProdSel._ui;
  const priv = mpProdSel._priv;

  _resetSearchList(ui.feed.val(),
    searchUI,
    priv.cols,
    _colToText,
    _indexOfSelectedValues(ui.cols),
    true);

  priv.searchTimer = new Timer(250, _colsSearchBoxNow.bind(mpProdSel));

  mpProdSel._diagCtrl.prompt(searchUI.topDiv, {
    title: Text.searchCols,
    buttons: ['apply', 'cancel'],
    callback: _applyColSelection.bind(mpProdSel),
  });
}

/**
 * Callback for choosing roots.
 * @param {JQuery.Event} event
 * @private
 */
function _chooseRoots(event) {
  /** @type {MpProductSel} */
  const mpProdSel = event.data;

  const searchUI = _getSearchUI(mpProdSel, PROP_SEARCH_ROOTS, 'roots');
  const newItemBtn = searchUI.addNew;
  const diagCtrl = mpProdSel._diagCtrl;
  const ui = mpProdSel._ui;
  const priv = mpProdSel._priv;

  _resetSearchList(
    ui.feed.val(),
    searchUI,
    priv.roots,
    _rootToText,
    _indexOfSelectedValues(ui.roots),
    priv.isMultiRoot,
  );

  priv.searchTimer = new Timer(250, _rootsSearchBoxNow.bind(mpProdSel));

  if (priv.isNewRootAllowed
        && !newItemBtn.data(DATA_IS_INITIALIZED)) {
    newItemBtn
      .data(DATA_IS_INITIALIZED, true)
      .show()
      .on('click', (e) => {
        if (_addNewRootItemInSearch(mpProdSel, searchUI, searchUI.searchBox.val())) priv.searchTimer.tick();
      });
  }

  diagCtrl.prompt(searchUI.topDiv, {
    title: Text.searchRoots,
    buttons: ['apply', 'cancel'],
    callback: _applyRootSelection.bind(mpProdSel),
  });
}

/**
 * Appends a row to the searchable list for a newly created root.
 * @param {MpProductSel} mpProdSel
 * @param {SearchUI} ui - Search UI in which to add `newItem`.
 * @param {string} newRootName - New item to add.
 * @returns {boolean} Whether the addition was successful.
 * @private
 */
function _addNewRootItemInSearch(mpProdSel, ui, newRootName) {
  if (Strings.isEmpty(newRootName)) { return false; }

  const root = _newRoot(newRootName);
  const { roots } = mpProdSel._priv;

  if (_getItemIndex(newRootName, roots) >= 0) { return false; }

  _newSearchRowHtml(
    ui.rows,
    root,
    ((mpProdSel._priv.isMultiRoot) ? 'checkbox' : 'radio'),
    true, // selected by default
    _rootToText,
  ).appendTo(ui.listDiv);

  _insertRootInList(root, roots);

  return true;
}

/**
 * Inserts `root` in the given list, taking care of preserving the list's sort order.
 * @param {MpRoot} root
 * @param {MpRoot[]} roots
 * @return {int} Index position at which `root` was inserted.
 * @private
 */
function _insertRootInList(root, roots) {
  const insertAt = 1 + Arrays.indexFloor(root, roots, null, MpRoot.compare);
  roots.splice(insertAt, 0, root);

  return insertAt;
}

/**
 * Callback for radio buttons *key* and *roots*.
 * @param {JQuery.Event} event
 * @private
 */
function _keyVsRootChange(event) {
  const mpProdSel = event.data; // Injected at binding.

  _applyKeyVsRoots(mpProdSel);
  _userChg(mpProdSel);
}

/**
 * Callback for *change* event on a key-value INPUT element.
 * @param event {JQuery.Event}
 * @private
 */
function _keyValueChange(event) {
  const mpProdSel = event.data;
  _userChg(mpProdSel);
}

/**
 * Restoring a product, step 1/3: set the feed name
 * and call for its fields and roots.
 * @param mpProdSel {MpProductSel}
 * @param display {Object} {{feed: string, key: (?Object), roots: (?string[]), cols: string[]}}
 * @private
 */
function _displayFeed(mpProdSel, display) {
  const ui = mpProdSel._ui;
  const feedName = display.feed;
  const feed = _feedCtrl.feed(feedName, null);
  let dsName = '';

  // If feed no longer exists (aka `feed === null`), we set the
  // Data-Source selection to "choose one" and "inject" (temporarily)
  // the feed name into the feed selection-list.

  if (feed !== null) { dsName = feed.dataSource(); }

  ui.datasrc.val(dsName);
  const availFeeds = _applyDataSourceSelection(mpProdSel);

  if (Arrays.indexOf(feedName, availFeeds) >= 0) {
    ui.feed.val(feedName);
    _applyFeedSelection(mpProdSel);
  } else {
    const optionText = Text.optionNotFound.replace('[name]', feedName);
    _addSelOption(ui.feed, feedName, optionText).val(feedName);

    _showUnverifiedFeedInfo(mpProdSel, display, _textToNotFound, CSS_NOT_AVAIL);
  }
}

/**
 * Restoring a product's fields (keys and columns).
 * @param mpProdSel {MpProductSel}
 * @param display {Object} {{feed: string, key: (?Object), roots: (?string[]), cols: string[]}}
 * @private
 */
function _displayFields(mpProdSel, display) {
  _displayCols(mpProdSel, display);

  if (display.key !== null) { _displayKeys(mpProdSel, display); }
}

/**
 * Restoring a product, step 2/3: set the columns.
 * @param mpProdSel {MpProductSel}
 * @param display {Object} {{feed: string, key: (?Object), roots: (?string[]), cols: string[]}}
 * @private
 */
function _displayCols(mpProdSel, display) {
  _displayVsActual(
    display.cols,
    mpProdSel._priv.cols,
    mpProdSel._ui.cols,
    _colToText,
  );
}

/**
 * Restoring a product, step 3a/3: set the roots.
 * @param mpProdSel {MpProductSel}
 * @param display {Object} {{feed: string, key: (?Object), roots: (?string[]), cols: string[]}}
 * @private
 */
function _displayRoots(mpProdSel, display) {
  const priv = mpProdSel._priv;
  const avail = priv.roots;

  if (priv.isNewRootAllowed) {
    // Create roots if they don't exist.
    _.each(display.roots, (rootName) => {
      if (_getItemIndex(rootName, avail) < 0) _insertRootInList(_newRoot(rootName), avail);
    });
  }

  _displayVsActual(
    display.roots,
    avail,
    mpProdSel._ui.roots,
    _rootToText,
  );
}

/**
 * Restoring a product, step 3b/3: set the key values.
 * @param mpProdSel {MpProductSel}
 * @param display {Object} {{feed: string, key: (?Object), roots: (?string[]), cols: string[]}}
 * @private
 */
function _displayKeys(mpProdSel, display) {
  _showKeys(mpProdSel, display.key, CSS_NOT_AVAIL, mpProdSel._priv.keys);
}

/**
 * Applies the request for a specific product to be displayed.
 * @param mpProdSel {MpProductSel}
 * @private
 */
function _applyNewDisplay(mpProdSel) {
  const priv = mpProdSel._priv;
  const { display } = priv;
  const colSearch = priv[PROP_SEARCH_COLS];
  const rootSearch = priv[PROP_SEARCH_ROOTS];

  // Force reset of search screens (columns and roots)
  if (colSearch !== null) colSearch.feedName = null;
  if (rootSearch !== null) rootSearch.feedName = null;

  /*
     * Show columns, roots or keys first, in a state that
     * tells user what's going on.  When selected feed
     * is ready to be displayed, we'll replace those values
     * - columns, roots or keys - with the correct ones.
     *
     * If the feed takes a while to display, users know
     * what's going on.  If it happens quickly, users who notice
     * the quick changes will - I think - appreciate the "coolness".
     */

  _setRootView(mpProdSel, (display.roots !== null), false);
  _showUnverifiedFeedInfo(mpProdSel, display, _textToLoading, CSS_NOT_CONFIRMED);

  // If instance is done initializing, start setting
  // the product values now.
  if (!_isLoadingDataSources(mpProdSel)) { _displayFeed(mpProdSel, display); }
}

/**
 * Creates a TR element with two inner TD elements.
 * @param table {JQuery} Parent element.
 * @param label {string} Row label
 * @param [cssClass] {string} CSS class name
 * @returns {{label: JQuery, input: JQuery}}
 * @private
 */
function _newRow(table, label, cssClass) {
  const tr = $('<tr>').appendTo(table);

  if (arguments.length > 2) { tr.addClass(cssClass); }

  return {
    label: $('<td>').appendTo(tr).text(label),
    input: $('<td>').appendTo(tr),
  };
}

/**
 * @param {JQuery} parentElm
 * @returns {JQuery} Newly created BUTTON element.
 * @private
 */
function _newButton(parentElm) {
  return $('<button type="button">').appendTo(parentElm);
}

/**
 * Builds the UI for an instance of MpProductSel.
 * @param mpProdSel {MpProductSel}
 * @returns {{top: JQuery, datasrc: JQuery, feed: JQuery, feedCreate: JQuery, feedEdit: JQuery,
 *     cols: JQuery, colsBtn: JQuery,
 *     rsRoots: JQuery, rsKey: JQuery, lblRoots: JQuery, lblKey: JQuery, roots: JQuery,
 *     rootsBtn: JQuery, keyArea: JQuery}}
 * @private
 */
function _buildUI(mpProdSel) {
  const form = $('<form>').addClass('mp-product-select');
  const table = $('<table>').appendTo(form);
  const tbody = $('<tbody>').appendTo(table);
  const datasrc = $('<select>').appendTo(_newRow(tbody, Text.datasrc).input);
  const feedRow = _newRow(tbody, Text.feed);
  const feed = $('<select>').appendTo(feedRow.input);
  const colsElm = _newRow(tbody, Text.columns, 'cols').input;
  const cols = $('<select size="3">').appendTo(colsElm);
  const colsBtn = $('<button type="button">').appendTo(colsElm).text(Text.select);

  const keyVsRootElm = _newRow(tbody, '').input;
  const keyVsRootHtml = '<input type="radio" name="rsKeyVsRoots">';

  const lblRoots = $('<label>').appendTo(keyVsRootElm);
  const rsRoots = $(keyVsRootHtml).val('roots').appendTo(lblRoots);

  const lblKey = $('<label>').appendTo(keyVsRootElm);
  const rsKey = $(keyVsRootHtml).val('key').appendTo(lblKey);

  const prodTmp = _newRow(tbody, Text.roots, 'product');
  const prodLbl = prodTmp.label;
  const prodElm = prodTmp.input;
  const roots = $('<select size="3">').appendTo(prodElm);
  const rootsBtn = $('<button type="button">').appendTo(prodElm).text(Text.select);
  const keyArea = $('<div>').appendTo(prodElm).addClass('key-area');
  let newFeed = $();
  let editFeed = $();

  form[0].onsubmit = _returnFalse;

  lblRoots.append(Text.fwdCurve);
  lblKey.append(Text.timeSeries);

  if (isConfiguredServerlessApp()) {
    newFeed = _newButton(feedRow.input).addClass('feed-new').hide().disable();
    editFeed = _newButton(feedRow.input).addClass('feed-edit').hide().disable();
  }

  datasrc.on('change', null, mpProdSel, _datasrcChange);
  feed.on('change', null, mpProdSel, _feedChange);
  newFeed.on('click', null, mpProdSel, _newFeedClick);
  editFeed.on('click', null, mpProdSel, _editFeedClick);
  rsRoots.on('change', null, mpProdSel, _keyVsRootChange);
  rsKey.on('change', null, mpProdSel, _keyVsRootChange);
  colsBtn.on('click', null, mpProdSel, _chooseCols);
  rootsBtn.on('click', null, mpProdSel, _chooseRoots);

  return {
    top: form,
    datasrc,
    feed,
    feedCreate: newFeed,
    feedEdit: editFeed,
    cols,
    colsBtn,

    rsRoots,
    rsKey,
    lblRoots,
    lblKey,

    prodLabel: prodLbl,
    roots,
    rootsBtn,
    keyArea,
  };
}

/**
 * Marketplace product selection.  A "product" is used to fetch
 * data from Marketplace.  It can be a key (one or more values)
 * or root(s) from a feed.  In addition, a product contains
 * one or more column(s).
 */
class MpProductSel {
  /**
     * @param diagCtrl {{ error: function, warn: function,
     *                    prompt: function, info: function }} Dialog-box controller.
     */
  constructor(diagCtrl) {
    if (isVoid(diagCtrl)
            || typeof diagCtrl.error !== 'function'
            || typeof diagCtrl.warn !== 'function'
            || typeof diagCtrl.prompt !== 'function') {
      throw new TypeError('diagCtrl: Must implement methods error(), warn() and prompt().');
    }

    this.events = new Events('busy', 'idle', 'change');

    this._diagCtrl = diagCtrl;
    this._ui = _buildUI(this);

    this._priv = {
      /** @type {int}           */ busyCnt: 0,
      /** @type {?MpField[]}    */ keys: null,
      /** @type {?MpField[]}    */ cols: null,

      /**
             * List of roots available in the feed, sorted by name.
             * @type {?MpRoot[]}
             */
      roots: null,

      /** @type {?string}           */ keyForFeed: null,
      /** @type {?SearchUI}         */ colSearch: null,
      /** @type {?SearchUI}         */ rootSearch: null,
      /** @type {?Timer}            */ searchTimer: null,
      /** @type {?ProductSelection} */ display: null,
      /** @type {boolean}           */ isSensitive: true,
      /** @type {boolean}           */ isMultiRoot: true,
      /** @type {boolean}           */ isNewRootAllowed: false,
      /** @type {boolean}           */ isFeedMaintAllowed: false,
      /** @type {?Object.<String, MpProductSel.Type>} */ typeFilter: null,
    };

    Object.freeze(this);

    _clearFeedDependents(this);

    // Delay call to _loadFeeds so that callers interested in the
    // *busy* event have a chance to bind their handler.
    Functions.delay(() => {
      _loadFeeds(this);
    });
  }

  /**
     * Attaches this UI to the given element.
     * If this UI is currently attached to another element,
     * it is first detached before being re-attached to the new
     * parent.
     * @param parentElm {(HTMLElement|JQuery)}
     * @returns {MpProductSel}
     */
  attachTo(parentElm) {
    const $parent = UI.toJQuery(parentElm);
    this._ui.top.detach().appendTo($parent);
    return this;
  }

  /**
     * Returns the container element for this component.
     * That is, the top-most element that contains all other
     * elements necessary to display this UI.
     * @returns {JQuery} HTMLElement
     */
  container() {
    return this._ui.top;
  }

  /** Destroys this instance. */
  destroy() {
    this._ui.top.remove();
    _destroySearchUI(this._priv[PROP_SEARCH_COLS]);
    _destroySearchUI(this._priv[PROP_SEARCH_ROOTS]);
  }

  /**
     * Gets or sets a product-type filter, restricting
     * product selection to the given type(s).
     *
     * In getter mode, the returned list is in no particular order.  Specifically,
     * the order used for setting the filter is not preserved/guaranteed.
     *
     * In setter mode, this method has no immediate effect.  The filter will
     * take effect next time users change the on-screen controls.  This method
     * should be called immediately after the instance has been constructed to prevent
     * conflicts.
     *
     * @param {MpProductSel.Type[]} [typeList] - Pass an empty list to remove restrictions.
     * @returns {(MpProductSel.Type[]|MpProductSel)}
     */
  typeFilter(typeList) {
    const priv = this._priv;

    if (arguments.length < 1) {
      return _.values(priv.typeFilter);
    } if (!Arrays.isValid(typeList, Type.isEnumOf)) {
      throw new TypeError('typeList: MpProductSel.Type[]');
    } else {
      const ui = this._ui;

      if (typeList.length === 0) priv.typeFilter = null;

      else {
        priv.typeFilter = _.indexBy(typeList, type => type.valueOf());
      }

      // If filtered, hide unavailable product type.
      if (!_passesFilter(this, Type.ROOTS)) {
        // Hide "roots" option, auto-select "keys".
        ui.rsRoots.add(ui.lblRoots).isVisible(false);
        _setRootView(this, false, false);
      } else if (!_passesFilter(this, Type.KEYS)) {
        // Hide "keys" option, auto-select "roots".
        ui.rsKey.add(ui.lblKey).isVisible(false);
        _setRootView(this, true, false);
      }

      return this;
    }
  }

  /**
     * Returns whether the current on-screen selection the
     * type of product (KEYS, ROOTS or null).
     *
     * @returns {?MpProductSel.Type} May be `null`.
     */
  type() {
    const type = ((_isUsingKey(this)) ? Type.KEYS : Type.ROOTS);
    return _ifPassesFilter(this, type, null);
  }

  /**
     * Gets the product as currently defined within the UI.
     * @param [product] {?MpProduct}
     * @returns {(MpProduct|MpProductSel)}
     */
  product(product) {
    const ui = this._ui;
    const priv = this._priv;

    if (arguments.length < 1) {
      // getter

      if (!this.isFilled()) throw new Error('IllegalStateException: missing some values, cannot create product.');

      const isKey = _isUsingKey(this);
      const feedName = ui.feed.val();
      const feed = _feedCtrl.feed(feedName);
      const cols = _getItems(ui.cols, priv.cols);
      let keyOrRoots;

      if (isKey) keyOrRoots = _getKeySet(this);

      else { // roots
        keyOrRoots = _getItems(ui.roots, priv.roots);
      }

      return new MpProduct(feed, keyOrRoots, cols);
    }

    if (product === null) {
      // setter

      priv.display = product;
      return this;
    }

    if (!(product instanceof MpProduct)) throw new TypeError('product: MpProduct');

    else {
      // setter

      const isKeySet = product.isKeySet();

      priv.display = _newSelection(product.feed().name(),
        ((isKeySet) ? product.keySet().toJson() : null),
        ((!isKeySet) ? _.map(product.roots(), _mapToName) : null),
        _.map(product.columns(), _mapToName));

      _applyNewDisplay(this);

      return this;
    }
  }

  /**
     * Displays the given values.  These values will persist
     * until user takes action to change any of them.
     * @param {{feed: string, roots: string[], key: Object, columns: string[]}} jsonProduct
     *                 Caller cannot provide values for both `roots` and `key`.
     * @returns {MpProductSel}
     */
  display(jsonProduct) {
    if (!Objects.is(jsonProduct)) { throw new TypeError('jsonProduct: Object, with `feed`, `roots` or `key`, `columns`'); }

    const { feed } = jsonProduct;
    const { roots } = jsonProduct;
    const { key } = jsonProduct;
    const cols = jsonProduct.columns;
    const hasRoots = !isVoid(roots);
    const hasKey = !isVoid(key);

    requireNonEmptyString(feed, 'feed');

    if (hasRoots
            && (!Arrays.isValid(roots, Strings.isNonEmpty)
                || roots.length === 0)) { throw new TypeError('roots: Array-of-String, non-empty'); }

    if (hasKey
            && (!Objects.isAllPrimitives(key)
                || !Objects.hasProperties(key))) { throw new TypeError('key: Object, non-empty, of primitive values only'); }

    if (!Arrays.isValid(cols, Strings.isNonEmpty)
            || cols.length === 0) { throw new TypeError('columns: Array-of-String, non-empty'); }

    if (hasRoots && hasKey) { throw new Error('UnsupportedOperationException: cannot have both key and roots'); }

    this._priv.display = _newSelection(feed,
      ((hasKey) ? Objects.clone(key) : null),
      ((hasRoots) ? Arrays.slice(roots) : null),
      Arrays.slice(cols));

    _applyNewDisplay(this);

    return this;
  }

  /**
     * Returns whether all values have been provided to create a
     * MpProduct object.
     * @returns {boolean}
     */
  isFilled() {
    const ui = this._ui;
    const priv = this._priv;
    const isKey = _isUsingKey(this);

    return (Strings.isNonEmpty(ui.feed.val())
                && priv.keys !== null
                && priv.cols !== null
                && priv.roots !== null
                && _containsOptions(ui.cols)
                && ((isKey && _hasAllKeys(this))
                    || (!isKey && _containsOptions(ui.roots))));
  }


  /**
     * Returns whether the currently selected feed is contract-enabled, which means its
     * key is comprised of three (3) fields: Root, DeliveryStart, DeliveryEnd.
     * @returns {boolean} Whether the current feed is contract-enabled, false if *fields*
     *          have not arrived yet.
     */
  isContractEnabledFeed() {
    return _isContractEnabledFeed(this);
  }

  /**
     * Gets or sets the visibility of this component.
     * @param {boolean} [isVisible]
     * @returns {(MpProductSel|boolean)}
     */
  isVisible(isVisible) {
    const { top } = this._ui;

    if (arguments.length < 1) { // getter
      return top.isVisible();
    }

    // setter
    top.isVisible(_requireBool(isVisible, 'isVisible'));
    return this;
  }

  /**
     * Gets or sets the sensitivity of this component.
     * @param {boolean} isSensitive
     * @returns {(MpProductSel|boolean)}
     */
  isSensitive(isSensitive) {
    const priv = this._priv;
    const ui = this._ui;

    if (arguments.length < 1) { // getter
      return priv.isSensitive;
    }


    priv.isSensitive = _requireBool(isSensitive, 'isSensitive');
    if (!isSensitive) UI.bulkDisable(ui.top);

    else {
      // TODO - Enable each element based on current data / state of the component.
      throw new Error('not implemented');
    }

    return this;
  }

  /**
     * Gets or sets whether this instance allows for single or multiple
     * root(s) selection.
     * @param {boolean} [isMultiRoot]
     * @returns {(boolean|MpProductSel)}
     */
  isMultiRoot(isMultiRoot) {
    const priv = this._priv;
    if (arguments.length < 1) return priv.isMultiRoot;


    priv.isMultiRoot = _requireBool(isMultiRoot, 'isMultiRoot');
    if (priv[PROP_SEARCH_ROOTS] !== null) priv[PROP_SEARCH_ROOTS].feedName = null; // Force a re-build of the list.

    return this;
  }

  /**
     * Gets or sets whether this instance allows for root names that don't exist yet.
     * @param {boolean} [isAllowed]
     * @returns {(boolean|MpProductSel)}
     */
  allowRootCreation(isAllowed) {
    const priv = this._priv;
    if (arguments.length < 1) return priv.isNewRootAllowed;


    priv.isNewRootAllowed = _requireBool(isAllowed, 'isAllowed');
    return this;
  }

  /**
     * Gets or sets whether this instance allows for feeds to be maintained - created or edited.
     * @param {boolean} [isAllowed]
     * @returns {(boolean|MpProductSel)}
     */
  isFeedMaintenanceAllowed(isAllowed) {
    const priv = this._priv;
    if (arguments.length < 1) return priv.isFeedMaintAllowed;


    priv.isFeedMaintAllowed = _requireBool(isAllowed, 'isAllowed');
    this._ui.feedCreate.isVisible(isAllowed);
    this._ui.feedEdit.isVisible(isAllowed);
    return this;
  }
}

Object.assign(MpProductSel, /** @lends {MpProductSel} */ {
  Type,
});

export default MpProductSel;

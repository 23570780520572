<template>
  <div class="edit-scheduler-name">
    <div
      v-if="overlay"
      id="mds-overlay"
      class="mds-overlay"
    />
    <mds-loader
      v-show="showloader"
      class="div-centered"
      size="large"
    />
    <mds-row>
      <mds-col :cols="12">
        <form
          class="mds-form mds-form--medium-layout custom_form"
          method=""
          action=""
        >
          <div class="mds-form__fieldset">
            <mds-row>
              <mds-col
                :cols="2"
              >
                <label
                  class="mds-form__label pading-top_1rem"
                  for="datasource"
                >
                  {{ $t('labels.schedulerManagement.scLabelName') }}
                  <abbr class="mds-form__label-required-indicator">*</abbr>
                </label>
              </mds-col>
              <mds-col
                class="pading-top_1rem"
              >
                <mds-row>
                  <mds-col>
                    <input
                      id="schName"
                      v-model="schName"
                      class="mds-form__input"
                      type="text"
                      maxlength="100"
                      placeholder="Schedule Name"
                      @focusin="schPatternError = scheduleExists = schNameError = false"
                      @focusout="checkScheduleNameErrors($event)"
                    >
                    <span
                      v-if="schPatternError"
                      id="schName"
                      class="mds-form__field-error"
                      role="alert"
                    >
                      <span class="mds-form__field-error-text">
                        {{ $t('labels.schedulerManagement.schedulePatternError') }}
                      </span>
                    </span>
                    <span
                      v-if="scheduleExists"
                      id="schName"
                      class="mds-form__field-error"
                      role="alert"
                    >
                      <span class="mds-form__field-error-text">
                        {{ $t('labels.schedulerManagement.scheduleDuplicateError') }}
                      </span>
                    </span>
                    <span
                      v-if="schNameError"
                      id="schName"
                      class="mds-form__field-error"
                      role="alert"
                    >
                      <span class="mds-form__field-error-text">
                        {{ $t('labels.schedulerManagement.schValidNameError') }}
                      </span>
                    </span>
                  </mds-col>
                </mds-row>
              </mds-col>
            </mds-row>
            <hr class="horizontal-bar">
            <mds-row>
              <mds-col
                :cols="2"
              >
                <label
                  class="mds-form__label pading-top_05rem"
                  for="datasource"
                >{{ $t('labels.schedulerManagement.scLabelDescription') }}
                </label>
              </mds-col>
              <mds-col>
                <textarea
                  id="schDescription"
                  v-model="schDescription"
                  maxlength="255"
                  class="mds-form__textarea custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
                  @focusout="trimInputData('schDescription')"
                />
              </mds-col>
            </mds-row>
          </div>
        </form>
        <mds-row>
          <mds-col class="pading-top_1rem">
            <mds-row>
              <mds-col :push="10">
                <button
                  id="new-feed-save-button"
                  class="mds-button mds-button--primary font-size-small new-feed-save-button"
                  type="button"
                  :disabled="(schName === '') ||
                    (schPatternError === true) ||
                    (scheduleExists === true) ||
                    (schNameError === true)"
                  @click="editScheduler($event)"
                >
                  {{ $t('labels.schedulerManagement.scLabelSave') }}
                </button>
              </mds-col>
            </mds-row>
          </mds-col>
        </mds-row>
      </mds-col>
    </mds-row>
    <scheduler-notification
      ref="notificationComponent"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { MdsRow, MdsCol } from '@mds/layout-grid';
import SchedulerNotification from './SchedulerNotification.vue';

export default {
  name: 'EditScheduleName',
  components: {
    MdsRow,
    MdsCol,
    SchedulerNotification,
  },
  props: {
    scheduler:
    {
      type: Object,
      default() {
        return {};
      },
    },
    size: {
      type: String,
      default() {
        return '';
      },
    },
    width: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      overlay: false,
      showloader: false,
      showPageError: false,
      pageError: '',
      internalServerErrMsg: 'Internal Server Error with status code 500',
      schNameError: false,
      schPatternError: false,
      scheduleExists: false,
      intialSchName: '',
      schName: '',
      schDescription: '',
    };
  },
  computed: {
    ...mapGetters({
      schedulerList: 'schedulerModule/getSchedulerList',
    }),
  },
  created() {
    if (this.scheduler) {
      this.loadSchedulerDetails();
    }
  },
  methods: {
    ...mapActions('schedulerModule', ['getSchedulerList', 'updateScheduleName']),
    loadSchedulerDetails() {
      this.intialSchName = this.scheduler.scheduleName;
      this.schName = this.scheduler.scheduleName;
      this.schDescription = this.scheduler.description;
    },
    checkScheduleNameErrors(event) {
      this.schPatternError = false;
      this.scheduleExists = false;
      this.schName = this.schName.trim();
      if (this.schName && this.schName.length > 0) {
        const allowedSPattern = /^[a-zA-Z0-9_]+$/;
        const allowedSLength = 100;
        const matchedString = this.schName.match(allowedSPattern);
        if (!(matchedString && matchedString[0].length === this.schName.length)
        || this.schName.length > allowedSLength
        || this.schName.length === 0) {
          this.schPatternError = true;
        } else if (this.schedulerList.length > 0) {
          let schedulerListToFileter = this.schedulerList;
          const IntialSchedulerName = this.scheduler.scheduleName.toLowerCase();
          schedulerListToFileter = schedulerListToFileter.filter(obj => (obj.scheduleName.toLowerCase() !== IntialSchedulerName));
          schedulerListToFileter.forEach((item, index) => {
            if (this.schName.toLowerCase() === item.scheduleName.toLowerCase()) {
              this.scheduleExists = true;
            }
          });
        }
      }
    },
    resetAllValues() {
      this.overlay = false;
      this.showloader = false;
      this.showPageError = false;
      this.pageError = '';
      this.internalServerErrMsg = 'Internal Server Error with status code 500';
      this.schNameError = false;
      this.schPatternError = false;
      this.scheduleExists = false;
      this.intialSchName = '';
      this.schName = '';
      this.schDescription = '';
    },
    editScheduler(event) {
      event.preventDefault();
      this.checkScheduleNameErrors();
      if (this.scheduleExists === true || this.schPatternError === true || this.schNameError === true) {
        return;
      }
      const schedule = {};
      if (this.schName === '') {
        schedule.scheduleName = '';
        this.schNameError = true;
        return;
      }
      schedule.scheduleNameInitial = `${this.intialSchName}`;
      schedule.scheduleName = `${this.schName }`;
      schedule.description = `${this.schDescription }`;
      this.overlay = true;
      this.showloader = true;
      this.updateScheduleName(schedule).then((response) => {
        if (response !== undefined && response.status !== 200) {
          this.errorText = response.Message || response.data;
          this.$refs.notificationComponent.triggerError(this.errorText);
          this.overlay = false;
          this.showloader = false;
          this.$emit('editScheduleNameError', this.errorText);
        } else if (response) {
          this.$refs.notificationComponent.triggerSuccess(response.data);
          this.overlay = false;
          this.showloader = false;
          this.$emit('editScheduleNameSucess', response.data);
        } else {
          this.errorText = response.Message || response.data;
          this.$refs.notificationComponent.triggerError(this.errorText);
          this.showPageError = true;
          this.overlay = false;
          this.showloader = false;
          this.$emit('editScheduleNameError', this.errorText);
        }
        this.resetAllValues();
      });
    },
  },
};
</script>
<style scoped>
.mds-form__label {
  font-weight: 450;
}
.padding-top-bottom {
  padding: 2rem 1rem 2rem 1rem;
}
.pading_1rem {
  padding: 1rem;
}
.pading-top_1rem {
  padding-top: 1rem;
}
.pading-top_05rem {
  padding-top: 0.5rem;
}
.horizontal-bar {
  border-top: 0.10px solid #dadada;
}
.padding-right_05rem {
  padding-right: 0.5rem;
}
.padding-right_2rem {
  padding-right: 2rem;
}
</style>

import moment from 'moment';
import { SESSION_EXPIRE_MS } from './constants.js';


const DateUtils = {
  formatDate(format, date) {
    try {
      return moment(date).format(format, date);
    } catch (error) {
      console.log(error);
      return error;
    }
  },
};
export function getCookieDate() {
  let dateObj = Date.now();
  dateObj += SESSION_EXPIRE_MS;
  dateObj = new Date(dateObj);
  return dateObj;
}
export default DateUtils;

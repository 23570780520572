import Task from './task.es6';

/**
 * A task specific to a user-action.  Similar to Task, but always `override=true`.
 */
export default class UserActionTask extends Task {
  /**
     * @param {string} topic
     * @param {Object} props
     */
  constructor(topic, props) {
    super(topic, props);
  }

  /** @returns {Object} JSON payload, to be posted to Marketplace's workflow API. */
  toJson() {
    return { ...super.toJson(), override: true };
  }
}

<template>
  <div class="wrapper-column--source">
    <mds-section
      class="section-wrapper"
      title="Sources"
      border="tertiary"
      border-position="bottom"
    >
      <template #mds-section-actions>
        <mds-button
          class="refresh-icon"
          variation="icon-only"
          :disabled="disabled"
          icon="refresh"
          type="button"
          @mouseover="hover = true"
          @mouseleave="hover = false"
          @click="refreshSourcesData"
        />
      </template>
      <mds-search-field
        v-model="searchSourceInput"
        class="clickable-search-field"
        placeholder="Search for Sources"
        label="Search"
        @keyup.prevent="clearAllPanels"
      />
    </mds-section>
    <div class="list-content-wrapper">
      <mds-list-group class="list-content">
        <mds-list-group-item
          v-for="source in filteredListSources"
          id="list-content-mds-list-group-item"
          :key="source.index"
          :text="source"
          @mds-list-item-clicked="sourceSelected"
        />
      </mds-list-group>
    </div>
  </div>
</template>
<script>
import MdsSearchField from '@mds/search-field';
import MdsSection from '@mds/section';
import { mapGetters, mapActions } from 'vuex';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group-v4';
import { MdsButton } from '@mds/button';


export default {
  name: 'FeedSource',
  components: {
    MdsSearchField,
    MdsSection,
    MdsListGroup,
    MdsListGroupItem,
    MdsButton,
  },
  data() {
    return {
      activeSourceName: '',
      searchSourceInput: '',
      hover: false,
      disabled: false,
      timeout: null,
      clearSearchFromSources: '',
    };
  },
  computed: {
    ...mapGetters({
      sources: 'dashboardModule/allSources',
    }),
    filteredListSources() {
      return this.sources.filter(item => item.toLowerCase().includes(this.searchSourceInput.toLowerCase()));
    },
  },
  created() {
    this.getUserData();
  },
  mounted() {
    if (this.$route.query.sourceName) {
      this.sourceSelected(this.$route.query.sourceName);
    }
  },
  methods: {
    ...mapActions('dashboardModule', ['getUserData', 'getFeedData', 'clearALLSections']),
    sourceSelected(selectedSourceName) {
      this.activeSourceName = typeof selectedSourceName === 'string'
      || selectedSourceName instanceof String
        ? selectedSourceName : selectedSourceName.textContent;
      this.$eventBus.$emit('searchClear', this.clearSearchFromSources);
      this.getFeedData(this.activeSourceName);
    },
    // refresh button in sources
    refreshSourcesData() {
      this.disabled = true;
      // Re-enable after 2 seconds
      this.timeout = setTimeout(() => {
        this.disabled = false;
      }, 2000);
      this.getUserData();
    },
    clearAllPanels() {
      this.$eventBus.$emit('searchClear', this.clearSearchFromSources);
      return this.clearALLSections();
    },
  },
};
</script>
<style lang='scss'>
@import '~@mds/constants';
@import '~@mds/fonts';
@import '~@mds/typography';

.wrapper-column--source {
  box-sizing: border-box;
  height: calc(90vh);
  background-color: white;
  width: 300px;
  border: 7px solid whitesmoke;
  display: flex;
  flex-direction: column;
}
.refresh-icon:hover {
  border: 1px solid Grey;
}

.section-wrapper {
  padding: $mds-space-1-x;
  flex: 1;
}
.list-content-wrapper {
  flex: 6;
  overflow-y: scroll;
  padding-left: $mds-space-1-x;
}
.list-content-prev {
  flex: 6;
  overflow-y: scroll;
  padding-left: $mds-space-1-x;
  padding-top: $mds-space-1-x;
}
.list-content {
  padding-top: $mds-space-1-x;
  padding-right: $mds-space-1-x;
}
</style>

<template>
  <div class="list-content-prev">
    <mds-table
      class="list-content"
      size="medium"
      row-hover
    >
      <mds-thead>
        <mds-th
          v-for="(header, index) in headers"
          :key="index"
        >
          {{ header.text }}
        </mds-th>
      </mds-thead>
      <mds-tbody>
        <mds-tr
          v-for="(row, index) in rows"
          :key="index"
        >
          <mds-td
            v-for="(header, i) in headers"
            :key="i"
          >
            {{ row[header.fieldName] }}
          </mds-td>
        </mds-tr>
      </mds-tbody>
    </mds-table>
  </div>
</template>
<script>
import {
  MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr,
} from '@mds/data-table';
import { mapGetters } from 'vuex';

export default {
  name: 'FeedMetadata',
  components: {
    MdsTable,
    MdsTh,
    MdsThead,
    MdsTbody,
    MdsTr,
    MdsTd,
  },
  data() {
    return {
      headers: [
        {
          text: 'Field',
          fieldName: 'field',
        },
        {
          text: 'Value',
          fieldName: 'value',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      metaData: 'dashboardModule/allMetaData',
    }),
    rows() {
      const arr = [];
      if (this.metaData.length !== 0) {
        Object.entries(this.metaData[0]).forEach(([key, value]) => {
          const metadataObj = {};
          metadataObj['field'] = key;
          metadataObj['value'] = value;
          arr.push(metadataObj);
        });
      }
      return arr;
    },
  },
};
</script>
<style lang='scss'>
@import '~@mds/constants';
@import '~@mds/fonts';
@import '~@mds/typography';
</style>

<template>
  <div>
       <div class="sign-in-container">
      <form-logo :logo-url="logoUrl" />
      <login-fail
        v-show="hasError"
        @hideErrorAlert="hideAlert()"
      />
      <br>
      <form
        class="mds-form mds-form--medium-layout"
        name="loginForm"
        method="get"
        @submit.prevent="validateInput"
      >
        <fieldset class="mds-form__fieldset">
          <legend class="mds-form__legend">
            Login Information fields
          </legend>
          <input-field
            v-for="group in formInputs"
            :id="group.id"
            :key="group.id"
            v-model="$data[group.id]"
            :error="group.error"
            :title="group.title"
            :type="group.type"
            :described="group.described"
            :is-morningstar-policy="isMorningstarPolicy"
            :v="$v[group.id]"
            @handleShowPassword="handleShowPassword"
            @hideErrorAlert="hideAlert"
          />
          <login-controls
            :disable-login-btn="!disableLoginBtn"
            :user-name="$mwc.settings.useEmail ? emailInput : userNameInput"
            :password="passwordInput"
            :reset-password-url="resetPasswordUrl"
            @handleFailedInputs="handleFailedInputs"
          />
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import util from './util/formsFields';
import FormLogo from './FormLogo.vue';
import InputField from './InputField.vue';
import LoginControls from './LoginControls.vue';
import LoginFail from './LoginFail.vue';

export default {
  name: 'LoginForm',
  components: {
    FormLogo,
    InputField,
    LoginControls,
    LoginFail,
  },
  mixins: [validationMixin],
  inject: ['mwcId', 'eventBus'],
  props: {
    isEmail: { type: Boolean, default: true },
    logoUrl: { type: String, default: '' },
    errorText: { type: String, default: '' },
    resetPasswordUrl: { type: String, default: '' },
    isMorningstarPolicy: { type: Boolean, default: true },
  },
  data() {
    return {
      emailInput: '',
      passwordInput: '',
      userNameInput: '',
      loginNotValid: false, // shows error
      injectedInputsConfig: [],
    };
  },
  computed: {
    hasError() {
      return (this.errorText);
    },
    disableLoginBtn() {
      return (this.$mwc.settings.useEmail
        ? util.comparingMethod.email(this.$v)
        : util.comparingMethod.userName(this.$v));
    },
    formInputs() {
      let controls = [];
      controls = this.isEmail
        ? this.injectedInputsConfig.filter(item => item.type === 'email' || item.id === 'passwordInput')
        : this.injectedInputsConfig.filter(item => item.type !== 'email' || item.id === 'passwordInput');
      return controls;
    },
  },
  created() {
    this.$mwcRegister(this);
    this.injectedInputsConfig = util.fields(this.$mwc.labeller);
  },
  validations: util.validations,
  methods: {
    validateInput() {
      const data = {
        userName: this.emailInput,
        password: this.passwordInput,
        keepUserLoggedIn: this.isRememberMeChecked,
      };
      this.handleLoginRequest(data);
    },
    handleLoginRequest(value) {
      this.eventBus.$emit('signInRequested', value);// calls great parent
    },
    handleFailedInputs(failed) {
      this.loginNotValid = failed;
    },
    handleShowPassword() {
      this.formInputs[1].type = this.formInputs[1].type === 'password'
        ? 'text'
        : 'password';
    },
    hideAlert() {
      this.$emit('hideErrorAlert');
    },
  },
};
</script>

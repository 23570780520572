import { requireNonEmptyString } from './strings.es6';
import Objects, { requireObject } from './objects.es6';

/**
 * A Task is something to do in Marketplace, represented as a message to be send on MP message bus.
 */
export default class Task {
  /**
     * @param {string} topic Message topic.
     * @param {Object} props Properties that pertain to the topic.
     */
  constructor(topic, props) {
    this._topic = requireNonEmptyString(topic, 'topic');
    this._props = Object.freeze(Objects.clone(requireObject(props, 'props')));
    Object.freeze(this);
  }

  /**
     * @param {Task} task Task (or sub-class) instance.
     * @returns {Object} JSON payload for the given `task` argument.
     */
  static toJson(task) {
    if (!(task instanceof Task)) {
      throw new TypeError('task: Task');
    }
    return task.toJson();
  }

  /** @returns {Object} JSON payload, to be posted to Marketplace's workflow API. */
  toJson() {
    return {
      topic: this._topic,
      props: Objects.clone(this._props),
    };
  }

  /** @returns {string} Topic of this task. */
  topic() { return this._topic; }

  /** @returns {Object} Properties of this task (cloned, safe to modify). */
  properties() { return Objects.clone(this._props); }
}

<template>
  <div v-if="loadingStatus==='Loading'">
    <mds-loader
      size="large"
      :on-dark="darkMode"
    />
  </div>
  <div
    v-else
    class="time-series-line-chart-container"
  >
    <div
      v-if="!chartData"
      class="empty-state-container"
    >
      <mds-empty-state
        size="large"
        :on-dark="darkMode"
        title="No Results"
        message="Try adjusting your navigation query above."
      />
    </div>
    <div v-if="chartData">
      <time-series-line-chart
        id="mcd__chart"
        :data-set="chartData"
        :options="chartOptions"
        class="mcd__chart"
      />
    </div>
    <b v-if="loadingStatus==='Loading'">Refresh's in progress</b>
  </div>
</template>
<script>
import * as d3 from 'd3';
import MdsEmptyState from '@mds/empty-state';
import MdsLoader from '@mds/loader';
import TimeSeriesLineChart from '../../Charts/TimeSeriesLineChart';

export default {
  name: 'DashboardChartSection',
  components: {
    MdsEmptyState, MdsLoader, TimeSeriesLineChart,
  },
  props: {
    loadingStatus: {
      type: String,
      default: undefined,
    },
    xAxisType: {
      type: [Array, Object],
      default: () => [d3.time.hour, 4],
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
    chartData: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    chartOptions() {
      return {
        legendValuesFormat: d3.format('$,.0f'),
        y1TickSymbol: '',
        y1AxisOrient: 'left',
        useDefaultParsers: true,
        showLegendValues: true,
        showToolTip: true,
        firstTickDate: true, // true or false
        timelineLevel: this.xAxisType.timelineLevel,
        xTickFormat: this.xAxisType.xFormat,
        tooltipTimeFormat: d3.time.format('%m/%d/%y %I:%M%p <span class="price-chart__condensed">CST</span>'),
        y2: false,
        y2AxisLabel: 'y2 label',
      };
    },
  },
  watch: {
    darkMode: {
      handler(val, oldVal) {
        if (val) {
          this.chartOptions.skin = 'dark';
        } else {
          this.chartOptions.skin = '';
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">

    .empty-state-container{
      margin: calc(22% - 100px);
      display:flex;
      justify-content: center;
      min-width: 300px;
    }

    .intervals-btn-group {
        padding: 15px;
    }
  .mcd__chart {

  }
  .time-series-line-chart-container{

  }

</style>

<template>
  <mds-alert
    variation="error"
    title="Login Error"
    :size="'small'"
    :tined="true"
    :dismissable="false"
    @mds-alert-dismissed="hideErrorAlert()"
  >
    {{ errorText }}
  </mds-alert>
</template>

<script>
import MdsAlert from '@mds/alert';
import { mapState } from 'vuex';

export default {
  name: 'LoginFail',
  components: { MdsAlert },
  computed: {
    ...mapState({
      errorText: state => state.loginModule.loginError,
    }),
  },
  methods: {
    hideErrorAlert() {
      this.$emit('hideErrorAlert');
    },
  },
};
</script>

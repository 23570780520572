<template>
  <div class="entitlements-slidein">
    <div>
      <div class="mds-header border-bottom-solid">
        <h1 class="mds-header__title component-title">
          <small v-if="friendlyFeed">{{ feedName | friendlyFeed }}</small>
          <small v-else>{{ feedName }}</small>
        </h1>
      </div>
      <template v-if="loader">
        <overlay />
      </template>
      <div
        v-show="loader == true"
        class="div-centered"
      >
        <mds-loader />
      </div>
      <mds-layout-grid>
        <mds-row>
          <mds-col :cols="4">
            <user-group
              :user-list="userList"
              :group-list="groupList"
              :site-list="siteList"
            />
          </mds-col>
          <mds-col :cols="8">
            <entitled-user
              :key="siteLicenseCheck"
              :feed-name="feedName"
              :user-entitlements="entitledUsers"
              :group-entitlements="entitledGroups"
              :site-entitlements="entitledSite"
              :initial-feed-entitlements="initialFeedEntitlements"
              :initial-group-entitlements="initialGroupEntitlements"
              :initial-site-entitlements="initialSiteEntitlements"
              :removed-list="removedList"
            />
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import EventBus from '../../main';
import Overlay from './Overlay.vue';
import UserGroup from './UserGroup.vue';
import EntitledUser from './EntitledUser.vue';

const SITE_ALL = 'Site License (all users)';
export default {
  name: 'FeedEntilements',
  components: {
    Overlay, UserGroup, EntitledUser, MdsLayoutGrid, MdsRow, MdsCol,
  },
  data() {
    return {
      loader: '',
      userList: [],
      groupList: [],
      siteList: [SITE_ALL],
      removedList: [],
      initialFeedEntitlements: [],
      initialGroupEntitlements: [],
      initialSiteEntitlements: [],
      readUsers: [],
      writeUsers: [],
      siteUsers: [],
      entitledGroups: [],
      entitledSite: [],
      siteLicenseCheck: false,
      friendlyFeed: false,
    };
  },
  computed: {
    ...mapGetters({
      feedName: 'feedModule/getActiveFeedname',
      feedEntitlements: 'feedModule/getFeedEntitlements',
    }),
    entitledUsers() {
      return this.getEntitledUsers();
    },
  },
  created() {
    EventBus.$on('addEntitlements', (value, type) => {
      this.addEntitlements(value, type);
    });
    this.loader = true;
    this.$store.state.feedModule.feedEntitlements = {};
    this.initialFeedEntitlements = [];
    this.getActiveUsers().then((responses) => {
      if (responses.status === 200) {
        this.userList = responses.data.userList;
        if (responses.data.groupList) {
          this.groupList = responses.data.groupList.map(group => group.trim());
        }
        this.getEntitlements(this.feedName).then((response) => {
          if (response.status === 200) {
            this.initialFeedEntitlements = Object.assign([], this.getEntitledUsers());
            this.initialGroupEntitlements = Object.assign([], this.getEntitledGroups());
            this.initialSiteEntitlements = Object.assign([], this.getInitialEntitledSite());
            this.entitledGroups = Object.assign([], this.feedEntitlements.groupEnt);
            this.entitledSite = Object.assign([], this.getEntitledSite());
            this.removesEntitledFromUserList();
            this.userList = this.userList.filter(Boolean); // Ignore Blank Entry from the user list
          } else if (response.status === 500) {
            EventBus.$emit('showErrorNotification');
          } else if (response.status >= 400) {
            EventBus.$emit('showWarningNotification');
          }
          this.loader = false;
        });
      }
    });

    EventBus.$on('showLoader', (value) => {
      this.loader = true;
    });

    EventBus.$on('refreshPage', this.loadPage);

    EventBus.$on('removeEntitlements', (ent, type, read, write) => {
      this.removeEntitlements(ent, type, read, write);
    });

    EventBus.$on('updateEntitlementsPermission', (ent, permission, event) => {
      this.updateEntitlementsPermission(ent, permission, event);
    });
  },
  methods: {
    ...mapActions('feedModule', ['getActiveUsers', 'getEntitlements']),
    // ...mapGetters('feedModule', ['getFeedEntitlements']),
    getEntitledGroups() {
      return [...this.feedEntitlements.groupEnt];
    },
    getInitialEntitledSite() {
      return this.feedEntitlements.readers
        .filter(ent => ent.numsubs === '-1');
    },
    getEntitledSite() {
      this.feedEntitlements.readers
        .filter(ent => ent.numsubs === '-1')
        .map((ent) => {
          this.siteUsers.push(ent);
          return ent;
        });
      if (this.siteUsers.length === 1) {
        this.entitledUsers.map(
          (user) => {
            user.check = true;
            return user;
          },
        );
        this.siteLicenseCheck = true;
        this.entitledSite = [SITE_ALL];
        return this.entitledSite;
      }
      return null;
    },
    getEntitledUsers() {
      const users = [];
      if (this.feedEntitlements.readers && this.feedEntitlements.writers) {
        this.feedEntitlements.writers
          .map((ent) => {
            this.writeUsers.push(ent.username);
            return ent;
          });
        this.feedEntitlements.readers
          .map(ent => ent.users.map((user) => {
            const flag = !!this.writeUsers.includes(user.login);
            if (ent.isFeedGroupLicense) {
              users.push({
                name: user.login, license: ent.name, read: true, write: false, check: true, isFeedGroupLicense: true,
              });
            } else {
              users.push({
                name: user.login, license: ent.name, read: true, write: flag, check: true, isFeedGroupLicense: false,
              });
            }
            this.readUsers.push(user.login);
            return user;
          }));

        this.writeUsers.map((user) => {
          if (users.filter(u => u.name === user).length <= 0) {
            users.push({
              name: user, license: null, read: false, write: true, check: false,
            });
          }
          return user;
        });
      }
      return users.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    },
    addEntitlements(name, type) {
      if (type === 'user') {
        let license = this.initialFeedEntitlements
          .filter(ent => ent.name === name)
          .map(ent => ent.license)[0];
        license = license === undefined || license === '' ? null : license;
        if (this.entitledSite.length === 1) {
          this.entitledUsers.push({
            name, license, read: false, write: false, check: true,
          });
        } else {
          this.entitledUsers.push({
            name, license, read: true, write: false, check: true,
          });
        }
      } else if (type === 'group') {
        this.entitledGroups.push(name);
      } else if (type === 'site') {
        this.entitledSite.push(name);
        this.siteList = [];
        this.removeReadEntitlementsPermission();
        this.entitledUsers.map(
          (user) => {
            user.check = true;
            return user;
          },
        );
        this.siteLicenseCheck = true;
      }
      this.userList = this.userList
        .filter(user => user !== name)
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
      this.groupList = this.groupList
        .filter(user => user !== name)
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
      this.entitledUsers.sort((a, b) => a.name.localeCompare(b.name), undefined, {
        sensitivity: 'base',
      });
      this.removedList = this.removedList.filter(user => user.name !== name);
    },
    removeEntitlements(ent, type, read, write) {
      if (type === 'user') {
        this.entitledUsers.splice(
          this.entitledUsers.findIndex(user => user.name === ent.name),
          1,
        );
        if (this.entitledUsers.filter(u => u.name === ent.name).length <= 0) {
          this.userList.push(ent.name);
          this.userList.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
        }

        this.removedList.push({
          name: ent.name,
          license: ent.license,
          type,
          read,
          write,
        });
      } else if (type === 'group') {
        this.entitledGroups.splice(
          this.entitledGroups.findIndex(g => g === ent),
          1,
        );
        this.groupList.push(ent);
        this.groupList.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
        this.removedList.push({
          name: ent,
          license: null,
          type,
          read,
          write,
        });
      } else if (type === 'site') {
        this.entitledSite = [];
        this.siteList = [SITE_ALL];
        this.entitledUsers.map(
          (user) => {
            user.read = true;
            user.check = true;
            return user;
          },
        );
        this.siteLicenseCheck = false;
      }
      this.entitledUsers.sort((a, b) => a.name.localeCompare(b.name), undefined, {
        sensitivity: 'base',
      });
    },
    removesEntitledFromUserList() {
      const userNames = [...this.readUsers, ...this.writeUsers, ...this.feedEntitlements.groupEnt];
      this.userList = this.userList
        .filter(user => !userNames.includes(user))
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
      this.groupList = this.groupList
        .filter(user => !userNames.includes(user))
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
      if (this.siteUsers.length === 1) {
        this.siteList = [];
      }
    },

    updateEntitlementsPermission(ent, permission, event) {
      const index = this.entitledUsers.findIndex(
        user => user.name === ent.name && user.license === ent.license,
      );
      this.entitledUsers[index][permission] = event.target.checked;
    },
    removeReadEntitlementsPermission() {
      this.entitledUsers.map(
        (user) => {
          user.read = false;
          return user;
        },
      );
    },
    loadPage() {
      this.getEntitlements(this.feedName).then((response) => {
        this.removedList.length = 0;
        this.removesEntitledFromUserList();
        this.initialFeedEntitlements = Object.assign([], this.entitledUsers);
        this.loader = false;
      });
    },
  },
};
</script>

<style lang="scss" >
@import 'src/assets/styles/components/custom/feed_entitlements';
</style>

<template>
  <div class="center">
    <not-entitled-user v-if="showPageError" />
    <div v-else>
      <analytics-workspace :workspace="dataAnalyticsConfigs.workspaces[0]" />
    </div>
  </div>
</template>
<script>
import { FEATURES } from '@/utils/constants';
import AnalyticsWorkspace from '@/components/Dashboard/Workspaces/AnalyticsWorkspace';
import * as dataAnalyticsConfigs from '@/configs/dataAnalytics.json';
import NotEntitledUser from '../Errors/NotEntitledUser';

export default {
  name: 'AnalyticsHome',
  components: {
    AnalyticsWorkspace,
    NotEntitledUser,
  },
  data() {
    return {
      showPageError: false,
      dataAnalyticsConfigs,
    };
  },
  created() {
    if ((this.isUserEntitled && !this.isUserEntitled([
      FEATURES.POWER_FORECAST_ERCOT,
      FEATURES.POWER_FORECAST_PJM,
    ]))) {
      this.showPageError = true;
      this.pageError = this.$t('labels.common.featureNotEntitled');
    }
  },
};
</script>
<style>
.center {
  text-align: center;
}
</style>

<template>
  <div>
    <div class="mds-doc-homepage__row-inner research-reports">
      <template v-if="reportsStatus == 'SUCCESS'">
        <div class="report-categories">
          <ul class="responsive-tabs report-tabs clear-both">
            <li
              v-for="(reportType, index) in reportTypes"
              :id="`reportCat_` + reportType.replace(/ /g, '_')"
              :key="index"
              :class="reportType == activeReportFilter? 'active' : ''"
              @click="setReportFiler(reportType)"
            >
              <a class="report-tab">
                <img :src="getImgUrl(reportType)">
                <p class="title">{{ reportType }}</p>
              </a>
            </li>
          </ul>
        </div>
        <div
          class="clear-both"
          style="padding-top:40px; text-align:left;"
        >
          <!-- //TODO: revisit this for MDS-V3 -->
          <div
            class="mds-search-field"
            role="search"
          >
            <input
              id="searchField"
              v-model="searchFilter"
              type="text"
              class="mds-search-field__input"
              aria-label="Search"
              :placeholder="$t('labels.report.searchReport')"
              @keyup="filterReportsByName($event.target.value)"
            >
            <svg
              class="mds-icon mds-search-field__search-icon"
              aria-hidden="true"
            >
              <use xlink:href="#search--s" />
            </svg>
            <span class="mds-search-field__input-focus-outline" />
            <button
              class="mds-button mds-search-field__clear-button mds-button--small mds-button--icon-only"
              type="button"
              aria-label="Clear search field"
              @click="clearSearchBar()"
            >
              <svg
                class="mds-icon mds-button__icon mds-button__icon--right"
                aria-hidden="true"
              >
                <use xlink:href="#remove--s" />
              </svg>
            </button>
          </div>
          <template v-if="activeReports==''">
            <span
              id="error-id"
              class="mds-form__field-error mds-form__field-error--large"
              role="alert"
            >
              <span class="mds-form__field-error-text">{{ $t("labels.common.emptyResults") }}</span>
            </span>
          </template>
          <template v-else>
            <article
              v-for="(report, index) in activeReports"
              :key="index"
              class="mds-article mds-article--responsive"
            >
              <mds-layout-grid
                class="mds-article__container"
                @click="downloadReportPDF(report.mainTitle, report.pdfName, index)"
              >
                <a
                  class="mds-link mds-link--no-underline"
                  href="javascript:void(0);"
                >
                  <mds-row>
                    <mds-col
                      :cols="9"
                      :cols-at-s="10"
                    >
                      <p class="mds-article__eyebrow">
                        <!-- <span v-html="getFormattedDate(report.date)" /> -->
                        <span>{{ report.date|formatDate }}</span>
                      </p>

                      <h3
                        class="title"
                        v-html="report.mainTitle"
                      />
                      <p v-html="report.subTitle" />
                    </mds-col>
                    <mds-col
                      ref="report"
                      :cols="3"
                      :cols-at-s="2"
                    />
                  </mds-row>
                </a>
              </mds-layout-grid>
            </article>
          </template>
        </div>
        <div id="mds-notification">
          <mds-notification
            ref="reportNotification"
            title="Download Failure"
            status="error"
            dismiss-delay="5000"
            dismissible="true"
            removable="false"
            class="mds-notification mds-notification--active mds-notification--top-center mds-notification--below-masthead"
            @mds-notification-dismissed="removeDownLoadLoader()"
          >
            {{ $t('labels.report.reportDownloadError',{reportTitle: activeReportTitle}) }}
          </mds-notification>
        </div>
      </template>
      <template v-else-if="reportsStatus == 'ERROR'">
        <div class="reports-error">
          <mds-notification
            visible
            title="ERROR"
            status="error"
            dismissible="false"
          >
            {{ $t('labels.report.reportError') }}
          </mds-notification>
        </div>
      </template>
      <template v-else-if="reportsStatus == 'LOADING'">
        <mds-loader />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import LayoutDefault from '../Layouts/LayoutDefault.vue';
import EventBus from '../../main';
import DateUtils from '../../utils/dateUtils';

export default {
  name: 'ResearchReports',
  components: { MdsLayoutGrid, MdsRow, MdsCol },
  filters: {
    formatDate(date) {
      return DateUtils.formatDate('MMM DD,YYYY', date);
    },
  },
  data() {
    return {
      activeReportTitle: '',
      activeReportFilter: 'Oil',
      searchFilter: '',
    };
  },
  computed: {
    ...mapGetters({
      reports: 'researchReports/getReports',
      reportTypes: 'researchReports/getReportTypes',
      reportsStatus: 'researchReports/getReportsStatus',
    }),
    activeReports() {
      if (this.searchFilter !== '') {
        return this.$store.getters['researchReports/getReportsByName'](this.searchFilter);
      }
      return this.$store.getters['researchReports/getReportsByType'](this.activeReportFilter);
    },
  },
  created() {
    this.getResearchReports();
    EventBus.$emit('layoutChange', LayoutDefault);
  },
  methods: {
    ...mapActions('researchReports', ['getResearchReports', 'setReportStatus']),
    getImgUrl(type) {
      const iconName = this.$store.getters['researchReports/getReportTypeImage'](type);
      return require(`../../assets/images/research/${iconName}`);
    },
    setReportFiler(reportType) {
      this.activeReportFilter = reportType;
    },
    filterReportsByName(txt) {
      this.searchFilter = txt;
    },
    clearSearchBar() {
      this.searchFilter = '';
    },
    downloadReportPDF(reportTitle, reportName, reportIndex) {
      const webApiUrl = `/api/research/reporturl/${reportName}`;
      this.activeReportTitle = reportTitle;
      this.addDownLoadLoader(reportIndex);
      return axios
        .get(webApiUrl)
        .then((response) => {
          window.open(response.data, reportTitle);
          this.removeDownLoadLoader();
        })
        .catch((error) => {
          console.log('Download Failure', error);
          this.$refs.reportNotification.open();
        });
    },
    addDownLoadLoader(index) {
      if (document.getElementById('downloadProgress')) {
        document.getElementById('downloadProgress').remove();
      }
      const mdsLoader = document.createElement('mds-loader');
      mdsLoader.size = 'small';
      mdsLoader.id = 'downloadProgress';
      this.$refs.report[index].appendChild(mdsLoader);
    },
    removeDownLoadLoader() {
      this.activeReportTitle = '';
      if (document.getElementById('downloadProgress')) {
        document.getElementById('downloadProgress').remove();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/research_reports';
</style>

<template>
  <div>
    <div>
      <mcd-card-section
        :key="$root.$i18n.locale"
        item-type="products"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import McdCardSection from '../components/McdCard/McdCardSection';

export default {
  name: 'Products',
  components: { McdCardSection },
  data() {
    return {

    };
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.loginModule.isAuthenticated,
    }),
  },
};
</script>

<template>
  <div>
    <mds-popover
      id="account-po"
      :visible="showAccount"
      triggered-by="popover-account"
      :position="['bottom-left']"
      width="300px"
      fixed
      class="responsive-popover-contents accounts-popover"
    >
      <div class="account-menu__user-info">
        <div class="account-menu__user">
          {{ username }}
        </div>
        <div class="account-menu__provider">
          {{ companyName }}
        </div>
        <hr>
        <mds-list-group>
          <mds-list-group-item>
            <div @click="globalSettingsClick()">
              {{ $t('navigation.globalSettings') }}
            </div>
          </mds-list-group-item>
          <mds-list-group-item>
            <div @click="signOut()">
              {{ $t('navigation.signOut') }}
            </div>
          </mds-list-group-item>
        </mds-list-group>
      </div>
    </mds-popover>
    <mds-modal
      v-model="gspoToggle"
      aria-label="Default Modal"
      :title="$t('navigation.globalSettings')"
    >
      <global-settings />
    </mds-modal>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group-v4';
import MdsPopover from '@mds/popover';
import MdsModal from '@mds/modal';
import GlobalSettings from './UserSettings/GlobalSettings';
import EventBus from '../main';

export default {
  name: 'PopoverItems',
  components: {
    GlobalSettings, MdsModal, MdsListGroup, MdsListGroupItem, MdsPopover,
  },
  props: {
    showAccount: { type: Boolean, default: false },
  },
  data() {
    return {
      friendlyFeeds: false,
      gspoToggle: false,
    };
  },
  computed: {
    ...mapState({
      username: state => state.homeModule.userName,
      companyName: state => state.homeModule.company,
    }),
  },
  created() {
    EventBus.$on('showGlobalSettings', this.globalSettingsClick);
  },
  methods: {
    ...mapActions(['dispatchAction']),
    signOut() {
      try {
        this.dispatchAction('signOut');
        this.$router.push({
          name: 'home',
        });
      } catch (error) {
        console.log(error);
      }
    },
    globalSettingsClick() {
      this.gspoToggle = !this.gspoToggle;
      EventBus.$emit('hideAccounts');
    },
  },
};
</script>
<style >
.custom-font-style span{
  font-weight: bold;
  color:red;
}
.accounts-popover{
  z-index: 999;
  top:40px !important;
}

</style>

<template>
  <div class="list-content-prev">
    <mds-table
      class="list-content"
      size="medium"
      row-hover
    >
      <mds-thead>
        <mds-th
          v-for="(header, index) in headers"
          :key="index"
        >
          {{ header.text }}
        </mds-th>
      </mds-thead>
      <mds-tbody>
        <mds-tr
          v-for="(row, index) in rows"
          :key="index"
        >
          <mds-td
            v-for="(header, i) in headers"
            :key="i"
          >
            {{ row[header.fieldName] }}
          </mds-td>
        </mds-tr>
      </mds-tbody>
    </mds-table>
  </div>
</template>
<script>
import {
  MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr,
} from '@mds/data-table';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'FeedPreview',
  components: {
    MdsTable,
    MdsTh,
    MdsThead,
    MdsTbody,
    MdsTr,
    MdsTd,
  },
  data() {
    return {
      headers: [
        {
          text: 'Date',
          fieldName: 'date',
        },
        {
          text: 'Value',
          fieldName: 'value',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      dataPreview: 'dashboardModule/allDataPreview',
    }),
    rows() {
      return this.checkDataPreviewList();
    },
  },
  methods: {
    checkDataPreviewList() {
      const arr = [];
      if (this.dataPreview.length !== 0) {
        this.dataPreview.forEach((obj) => {
          const dataPreviewObj = {};
          if (obj['value'] === '') {
            dataPreviewObj['date'] = obj['date'];
            dataPreviewObj['value'] = '';
          } else {
            dataPreviewObj['date'] = moment(obj['date']).format('YYYY-MM-DD HH:mm:ss');
            dataPreviewObj['value'] = obj['value'];
          }
          arr.push(dataPreviewObj);
        });
      }
      return arr;
    },
  },
};
</script>
<style lang='scss'>
@import '~@mds/constants';
@import '~@mds/fonts';
@import '~@mds/typography';

</style>

import MpField from "../field/field.es6";
import Strings, {isNonEmptyString} from "../../strings.es6";
import Numbers, {isNumber} from "../../numbers.es6";
import {isBoolean} from "../../booleans.es6";

const STRING = "string";
const NUMBER = "number";
const BOOL   = "boolean";

export default class MpKeyValue {

    /**
     * A Marketplace key-value combination.
     * @param {MpField} mpKey Of type KEY.
     * @param {(string|number|boolean)} value String must be non-blank; number must be finite.
     * @constructor
     */
    constructor (mpKey, value) {

        if (   !(mpKey instanceof MpField)
            || mpKey.type() !== MpField.Type.KEY ) {
            throw new TypeError("mpKey: MpField, of type KEY");
        }

        if (   !isNonEmptyString(value)
            && !isNumber(value)
            && !isBoolean(value) ) {
            throw new TypeError("value: String, Number or Boolean");
        }

        this._key = mpKey;
        this._val = value;
        Object.freeze(this);
    }

    /** @returns {MpField} A MpField object of type KEY. */
    key () { return this._key; }

    /** @returns {string} Name of this key, short for `key().name()`. */
    name () { return this._key.name(); }

    /** @returns {(string|number|boolean)} The value. */
    value () { return this._val; }

    /** @returns {string} Data-type of the value: "string", "number" or "boolean". */
    dataType () { return typeof this._val; }

    /**
     * Returns whether *that* is a MpKeyValue object that represents
     * the same key-value.  Note that for a value to be treated as equal,
     * it must be of the same data-type; this method does not use type coercion.
     * @param {(MpKeyValue|*)} that
     * @returns {boolean} Whether `that` represents the same key-value as this instance.
     */
    equals (that) {
        return (   (that instanceof MpKeyValue)
                && this._key.equals(that._key)
                && this._val === that._val );
    }

    /**
     * Returns whether `kv1` and `kv2` are two instances of MpKeyValue
     * that represent the same key-value.  Returns *false* if neither
     * object is an instance of MpKeyValue.
     *
     * @param {(MpKeyValue|*)} kv1
     * @param {(MpKeyValue|*)} kv2
     * @returns {boolean} Whether `kv1` and `kv2` represent the same key-value, false if either
     *          is not an instance of MpKeyValue.
     */
    static areEqual (kv1, kv2) {
        return (   kv1 instanceof MpKeyValue
                && kv1.equals(kv2) );
    }

    /**
     * Compares two MpKeyValue objects for the purpose
     * of sorting them by name, alphabetically, ascending.
     * This method uses the value as a secondary sort, if needed.
     *
     * This method pushes objects that are not instances of MpKeyValue
     * at the end of the sort order.
     *
     * @param {(MpKeyValue|*)} kv1
     * @param {(MpKeyValue|*)} kv2
     * @return {number}
     */
    static compare (kv1, kv2) {

        const is1 = (kv1 instanceof MpKeyValue),
              is2 = (kv2 instanceof MpKeyValue);

        if (is1 && is2) {

            let rv = Strings.compare(kv1.name(), kv2.name());
            if (rv === 0) {

                // Sort order for different data-types is: string, number, boolean, unknown.

                let v1  = kv1._val,
                    v2  = kv2._val,
                    to1 = (typeof v1),
                    to2 = (typeof v2);

                switch (to1) {
                    case STRING:
                        if (to2 === STRING)
                            return Strings.compare(v1, v2);
                        else
                            return -1;  // string first
                        break;

                    case NUMBER:
                        switch (to2) {
                            case NUMBER: return Numbers.compare(v1, v2);
                            case STRING: return 1;
                            case BOOL:   return -1;
                            default:     return -1;
                        }
                        break;

                    case BOOL:
                        if (to2 === BOOL)
                            // Tested in all browsers.
                            // See webapp/jsUnit/bool.js#testBool_comparison
                            return (v1 - v2);

                        else if (   to2 === STRING
                                 || to2 === NUMBER )
                            return 1;

                        else
                            return -1;

                        break;

                    default:
                        if (   to2 === STRING
                            || to2 === NUMBER
                            || to2 === BOOL )
                            return 1;
                        else
                            return 0;
                }
            }

        }
        else if (is1) return -1;
        else if (is2) return 1;
        else          return 0;
    }
}

<template>
  <div>
    <mds-loader
      v-if="$apolloData && $apolloData.queries.Sources.loading"
    />
    <mds-section
      v-else
      :key="refreshSignal"
      :on-dark="darkMode"
      container
    >
      <div
        v-if="overlay"
        id="mds-overlay"
        v-observe-visibility="visibilityChanged"
        class="mds-overlay"
      >
        <div class="overlayText">
          Generating PDF...
        </div>
      </div>
      <template #mds-section-actions>
        <mds-button
          id="default-trigger"
          variation="icon-only"
          icon="save"
          @click="generateReport"
          @mouseover="toggle=true"
          @mouseleave="toggle=false"
          @focus="toggle=true"
          @blur="toggle=false"
        >
          Export PDF
        </mds-button>
        <mds-tooltip
          v-model="toggle"
          triggered-by="default-trigger"
          :position="['bottom-center']"
        >
          Save As PDF
        </mds-tooltip>
        <div>
          <vendor-report-pdf
            slot="pdf-content"
            :sources="Sources.sources"
            :nav-data="navData"
            :search-data="searchItem"
            hidden="true"
          />
        </div>
      </template>
      <mds-table>
        <mds-thead>
          <mds-th
            v-for="(header, index) in headers"
            :key="index"
            :class="header.text === 'Description' ? 'customWidth' : 'defaultWidth'"
          >
            {{ header.text === "Link" ? '' : header.text }}
          </mds-th>
        </mds-thead>
        <mds-tbody>
          <mds-tr
            v-for="(row,index2) in filteredSources"
            :key="index2"
          >
            <mds-td
              v-for="(header, i) in headers"
              :key="i"
            >
              <div
                v-if="header.fieldName !== 'sourceUrl' "
              >
                <mds-button
                  variation="flat"
                  class="text-format"
                  @click="emitFeedsPage(row,index2)"
                >
                  {{ row[header.fieldName] }}
                </mds-button>
              </div>
              <div
                v-if="header.fieldName === 'sourceUrl'"
              >
                <mds-button
                  variation="flat"
                  class="text-format"
                  @click="emitFeedsPage(row,index2)"
                >
                  <img
                    crossorigin="anonymous"
                    class="img-init"
                    alt="Go to Source"
                    aria-label="Source Provider Image"
                    :src="getImgUrl(row['source'].toLowerCase())"
                  >
                </mds-button>
              </div>
            </mds-td>
          </mds-tr>
        </mds-tbody>
      </mds-table>
    </mds-section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MdsSection from '@mds/section';
import {
  MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr,
} from '@mds/data-table';
import gql from 'graphql-tag';
import { MdsButton } from '@mds/button';
import splunkLogger from '@/utils/splunkLogger';
import moment from 'moment';
import { ThemeMixin } from '../../mixins/themeMixin';
import { getUserName } from '../../utils/authService';
import VendorReportPdf from './VendorReportPdf.vue';
import EventBus from '../../main';


export default {
  name: 'MainTable',
  components: {
    MdsSection, MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr, MdsButton, VendorReportPdf,
  },
  mixins: [ThemeMixin],
  props: {
    searchData: {
      type: String,
      default: '',
    },
    navData: {
      type: Object,
      default: () => {},
    },
    refreshSignal: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      Feeds: [],
      Sources: {
        sources: [],
      },
      selectedRowData: {},
      showDrawerPage: false,
      loading: 0,
      page: 0,
      currentPage: 0,
      currentPageSize: 20,
      pageSize: 20,
      lastSelectedRow: {},
      brandQuery: '',
      lastSelectedIndex: 0,
      paginationOpts:
          {
            pageSizes: [20, 50, 100, 150],
            pageSize: 20,
            totalItems: 100,
            page: 1,
            showItemsInfo: true,
            showItemsSelect: true,
          },
      headers: [
        {
          fieldName: 'sourceUrl',
          text: 'Link',
          class: 'mds_test__column-class',
        },
        {
          fieldName: 'source',
          text: 'Source',
        },
        {
          fieldName: 'noOfFeeds',
          text: 'Number of Feeds',
        },
        {
          fieldName: 'sourceDescription',
          text: 'Description',
        },
      ],
      pdfHeaders: [
        {
          fieldName: 'sourceUrl',
          text: 'Link',
          class: 'mds_test__column-class',
        },
        {
          fieldName: 'sourceDescription',
          text: 'Description',
        },
      ],
      toggle: Boolean,
      overlay: false,
    };
  },
  computed: {
    ...mapGetters({
      searchItemFromStore: 'searchItem',
    }),
    searchItem: {
      get() {
        return this.searchData;
      },
      set(newName) {
        return newName;
      },
    },
    selectedNavMenu() {
      return this.navData;
    },
    filteredSources() {
      if (this.Sources && this.Sources.sources.length) {
        return this.Sources.sources.filter(i => i.source !== 'Morningstar_QA');
      }
      return [];
    },
    getFilter() {
      let filterStr = '';
      if (this.navData && Object.keys(this.navData).length > 0) {
        filterStr = this.getFilterStr(this.navData.market, filterStr, 'Market');
        filterStr = this.getFilterStr(this.navData.country, filterStr, 'Country');
        filterStr = this.getFilterStr(this.navData.licensingType, filterStr, 'Licensing Type');
      } else {
        filterStr = this.getFilterStr([], filterStr, 'Market');
        filterStr = this.getFilterStr([], filterStr, 'Country');
        filterStr = this.getFilterStr([], filterStr, 'Licensing Type');
      }
      return filterStr;
    },
    getTodaysDate() {
      return moment().format('MMMM D, YYYY');
    },
  },
  watch: {
    Sources: {
      deep: true,
      handler(newVal) {
        if (this.Sources && this.Sources.sources.length) {
          this.$emit('mcd-refresh-data', [this.Sources.sources[this.lastSelectedIndex]['feeds'],
            {
              source: this.Sources.sources[this.lastSelectedIndex]['source'],
              sourceDescription: this.Sources.sources[this.lastSelectedIndex]['sourceDescription'],
            }]);
        }
      },
    },
  },
  created() {
    EventBus.$on('pdfGenerated', this.finisedDownload);
  },
  apollo: {
    Sources: {
      query: gql` query Sources($filters:FiltersInput){
              Sources(filters:$filters) {
                sources {
                  sourceUrl
                  source
                  noOfFeeds
                  sourceDescription
                  countries
                  licensingType
                  market
                  feeds {
                    feedName,
                    feedDescription
                    keywords
                  }
                }
                count
              }
      }`,
      variables() {
        return {
          filters: {
            search: this.searchItem,
            ...this.selectedNavMenu,
          },
        };
      },
    },
  },
  methods: {
    emitFeedsPage(row, index) {
      splunkLogger([{
        user: getUserName(),
      },
      { sourceClicked: row['source'], featurePage: 'catalog' },
      ]);
      this.lastSelectedRow = row;
      this.lastSelectedIndex = index;
      this.$emit('mcd-feed-page-data', [row['feeds'], {
        source: row['source'],
        sourceDescription: row['sourceDescription'],
        sourceUrl: row['sourceUrl'],
      }]);
    },
    getImgUrl(name) {
      return `https://${process.env.VUE_APP_STATIC_RESOURCES_S3_BUCKET_CF_URL}/images/${name}.PNG`;
    },
    handleHideDrawerPage() {
      this.showDrawerPage = !this.showDrawerPage;
    },
    async generateReport() {
      this.overlay = true;
    },
    getFilterStr(filterArr, filterStr, keyStr) {
      if (filterArr.length > 0) {
        filterStr += `<span style='font-size: 18px;'>${keyStr}:</span><span style='font-size: 16px'>`;
        filterArr.forEach((market) => {
          filterStr += ` ${market[0].toUpperCase() + market.substring(1)},`;
        });
      } else {
        filterStr += `<span style='font-size: 18px;'>${keyStr}:</span><span style='font-size: 16px'> All `;
      }
      filterStr = filterStr.slice(0, filterStr.length - 1);
      filterStr += '</span><br>';
      return filterStr;
    },
    finisedDownload() {
      this.overlay = false;
    },
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        EventBus.$emit('generatePdf');
      }
    },
    clearData() {
      this.searchItem = '';
    },
  },
};
</script>
<style lang="scss">
  @import '@mds/constants';
  @import '@mds/typography';
  @import '@mds/link';

  h3,h2 {
    color : $mds-text-color-primary;
  }
  .text-format{
    @include mds-body-text-m();
    text-align: left !important;
    color: #1e1e1e !important;
  }
  .img-init{
    float: left;
    width:  100%;
    height: 100%;
    object-fit: cover;
  }
  .img-pdf{
    max-width: 100%;
    max-height: 70px;
    object-fit: contain;
    flex: 1;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  }
  .customWidth {
    width:500px
  }
  .defaultWidth {
    width:100px
  }
  .overlayText{
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 70px;
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}
</style>

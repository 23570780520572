
const MpDataWorkflowGuiText = {

  updatingLegacyWorkflow: `
        Workflow "[name]" was not created using this tool; it cannot be rendered.
        \n\n
        The only way to update this workflow is to re-create it
        from scratch or use the original tool.
    `,

  updatingHackedWorkflow: `
        Workflow "[name]" is not supported by this tool and cannot be updated safely.
        \n\n
        The only way to update this workflow safely is to use the original tool.
    `,

  cantSaveReadOnlyWorkflow: 'This workflow is read-only and cannot be modified.',

  linkAlreadyExists: 'A link already exists between these two bubbles.',
  circRefFound: 'Circular reference found, link refused.',

  paramSetName: 'Name',
  paramSetDescr: 'Description',
  paramSetSuffix: {
    DATA: ' (Data)',
    SAVE: ' (Publish)',
    SINGLE_VAR: ' (Var)',
  },

  paramSetDefaultName: 'unnamed',
  paramSetDefaultDescr: '',
  paramSetUnassigned: '',

  datasetText: '[key_or_roots]\n\n[cols]\n\n[feed]',

  multiColEditNotAvail: 'Updating multiple columns is not supported.',
  multiRowEditNotAvail: 'Updating multiple rows is not supported in this column.',

  Menu: {
    Link: {
      remove: 'Disconnect',
    },
    Bubble: {
      remove: 'Remove',
      edit: 'Edit...',
      view: 'View...',
      preview: 'Preview results...',
      launch: 'Launch this phase...',
      runHistory: 'Launch history...',
      debug: 'Debug...',
      test: 'Test...',
    },
  },

  Edit: {
    VariableName: {
      label: 'Variable name (case-sensitive):',
      err: 'Bad variable name',
      isRequired: 'A variable name is required.',
      isInvalid: 'Invalid variable name.  Try removing special characters.',
      inUseByOther: 'This variable name is already taken.',
    },

    Data: {
      title: 'Data - Edit',
    },
    SingleVar: {
      title: 'Variable - Edit',
    },

    TopicTask: {
      title: 'AMQP - Edit',
    },

    SignalEvents: {
      signal: 'Runs:',
      onData: 'When data arrives',
      atTimePrefix: 'At ',
      atTimeSuffix: " if data hasn't arrived",
    },

    Notification: {
      title: 'Notification - Edit',
      subjectLbl: 'Subject:',
      recipientsLbl: 'To:',
      messageLbl: 'Message:',
    },

    Save: {
      title: 'Publish - Edit',
      varToSave: 'What would you like to publish?',
      chooseVar: '*** Variables from previous formula ***',
      chosenVarNotAvail: '!!! VARIABLE `[var]` IS NO LONGER AVAILABLE !!!',

      delivType: 'Contract size:',
      withGaps: 'Allow gaps between contracts',
      curveDate: 'Curve date:',
      varNotChosen: 'Choose what you want to publish.',
      badCurveDate: 'Invalid curve date.',
      mustLinkToOneFormula: 'You must link one (and only one) Formula into Publish.',
      noVariableFound: 'Cannot find any variable being defined in the previous formula;'
                           + ' nothing to publish.\n\nTry editing the formula first.',
    },

    ProductSel: {
      conflict: 'Multiple products are defined across different input sets.',
      overwrite: 'Overwrite all products.',
      incomplete: 'Product selection is incomplete, please fill all values.',
      notContractEnabled: 'This feed does not accept futures contracts. Please choose another feed.',
    },

    SingleVarEdit: {
      conflict: 'Multiple values are defined across different input sets.',
      overwrite: 'Overwrite all values.',
      invalid: 'Entered value is not valid per the selected data-type.',
      tooLong: 'Value too long; cannot save.',
    },

    DeliveryType: {
      '30-minute': '30-Minute',
      hour: 'Hour',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      quarter: 'Quarter',
      season: 'Season',
      year: 'Year',
    },

    RelativeDateValue: {
      d: 'days',
      D: 'business days',
      H: 'hours',
      m: 'minutes',
    },

    ParamSet: {
      'priv.name': {
        title: 'Input Set - Name',
        prompt: 'Name for this input set:',
        isEmpty: 'Name cannot be empty.',
        tooLong: 'Name cannot exceed 50 characters.',
        isDuplicate: '"[name]" is already used for another input set.',
      },
      'priv.descr': {
        title: 'Input Set - Description',
        prompt: 'Description for this input set (optional):',
        tooLong: 'Description cannot exceed 250 characters.',
      },
    },

    Formula: {
      varHeader: 'Variables available at this point:',
      jsdocs: 'Documentation',

      userDef: 'User-defined',

      Buttons: {
        preview: 'Preview...',
      },

      Validation: {
        title: 'Formula Validation',
        isEmpty: 'Cannot save empty formula.',
        saveNotAllowedForUser: 'Saving data within a formula is not allowed.',
        saveNotAllowedInBubbleType: 'Saving data explicitly can only be accomplished in a Formula bubble.',
      },

      ValidationFreeform: {
        foundAnalyzeInstr: 'ANALYZE instruction is not allowed in advanced mode.',
      },

      Title: {
        formula: 'Formula - Edit',
        qa: 'QA - Edit',
      },

      HardCoded: {
        $TIME_ZONE: 'Workflow time-zone',
        $RUN_DATE: 'Date-time for this run',
        $TODAY: "Today's date, no time",
        $NOW: 'Current time',
      },

      Prompts: {
        Name: 'Bubble Name:',
        Descr: 'Description:',
      },
    },

    FormulaGui: {
      steps: 'Steps:',
      type: 'What:',
      varName: 'Variable name:',
      qaName: 'QA name:',
      preview: 'View',

      errNoType: 'A type of step must be selected.',
      errNoVarName: 'A variable name must be entered',
      errBadVarName: 'Variable name "[name]" contains at least one invalid character.',
      errDupVarName: 'Variable name "[name]" is already taken. Try another name.',
      errCharSeqNotAllowed: 'The following sequence of characters is not allowed in a QA name: "[char_seq]".',

      Buttons: {
        stepNew: 'New',
        stepCopy: 'Copy',
        stepFind: 'Already declared...',
        save: 'Save',
        add: 'Add',
        cancel: 'Cancel',
        freeform: 'Advanced...',
      },

      StepType: {
        forward_curve: 'forward curve',
        time_series: 'history/time-series',
        basic_math: 'basic computation',
        extrapolation: 'extrapolation',
        merge: 'merge',
        fill: 'fill',
        free_text: 'free text',
        missing_data_qa: 'check missing data',
        spike_qa: 'check data spike',
        in_range_qa: 'check data in range',
      },

      Find: {
        title: 'Select Declared Variable',
        bubbleType: 'Type',
        bubbleName: 'Name',
        varName: 'Variable',
        varType: 'Data-type',
        errNoStepToImport: 'At least one step must be selected.',
        errDupNamesFound: 'The following variable name(s) already exist within the current formula:\n\n[dup_names]\n\nYou must either rename the variables at the source or rename the variables in the current formula before proceeding.',
      },

      Freeform: {
        title: 'Advanced Mode',
        notice: 'UI controls are disabled when switching to Advanced mode.',
        ok: 'Proceed',
      },
    },
  },

  FormulaDebug: {
    title: 'Complete Formula',
    uuid: 'UUID:',
    isModified: 'Modified:',
    owner: 'Owner:',
    na: 'N/A',
    yes: 'yes',
    no: 'no',
  },

  FormulaTest: {
    prompt: 'Choose which parameter-set to run against:',
    title: 'Select a Parameter-Set',
    debugWinTitle: 'Debugging -- [workflow_name]',
    debugTab: '[formula_name] - [param_set_name]',
  },

  FormulaPreview: {
    title: 'Preview',
    close: 'Close',
  },


  Validation: {
    title: 'Validation',
    header: '<strong>Address these findings before continuing:</strong><br /><br />\n\n[errors]<br /> ',
    more: '[more]',
    nameIsTaken: 'Duplicate workflow name: "[name]".\n\nTry another name.<br />',
    noName: 'Needs a name.<br />',
    noSchedule: 'Needs a schedule.<br />',
    noDuration: 'Needs a maximum duration.<br />',
    noDataExpTime: 'Data bubble "[name]" has its timeout set but time of expiration is not set correctly.<br />',
    outOfBoundExpireTime: "Data bubble \"[name]\" has its timeout set outside of workflow's running hours, or timeout is too close to the workflow's wake-up or shutdown times.<br />",
    doesNothing: 'No Publish, no Notification: This workflow does nothing.<br />',
    freeStanding: 'Found [count] task bubble(s) without a trigger.<br />',
    emptyFormulas: 'Found [count] formula bubble(s) without an actual formula.<br />',
    saveHasBadVariable: 'Found [count] Publish bubble(s) with a bad variable reference.<br />',
    missingSingleVar: 'Found [count] Variable bubble(s) with no variable name.<br />',
    missingDataset: 'Found [count] Data and/or Publish bubble(s) with no variable name.<br />',
    missingNotif: 'Found [count] notification bubble(s) with missing information.<br />',
    missingAmqpInfo: 'Found [count] AMQP bubble(s) with missing information.<br />',
    noParamSet: 'Has no input set.<br />',
    datasetConflict: '[bubble_type] bubble "[name]" has product conflict across input sets.<br />',
    paramSetWithoutName: 'Found [count] input set(s) without a name.<br />',
    paramSetIncomplete: 'Incomplete input set: "[name]" is missing [var_list].<br />',
    paramSetDuplicate: 'Duplicate input sets: [group]<br />',
  },

  UserAction: {
    approve: 'Skip error and continue?',
  },

  LaunchTarget: {
    title: 'Launching "[target]"',
    header: 'Choose which input set(s) to launch:',
    paramSet: 'Input Set',
    status: 'Status',
    running: 'Running...',
    notStarted: 'Not started',
    stopped: 'Stopped',
    markDone: 'Mark "complete" after it succeeds',
    markDoneTooltip: 'This affects the completion percentage shown on the status window.',
    ok: 'Launch',
    noSelection: 'At least one input set must be selected.',
    unexpectedError: 'An error occurred.  This phase cannot be started, try again later.',

    Failure: {
      title: 'Launch Failure(s)',
      msg: 'Some phases failed to launch:\n\n[errors]',
    },
  },

  LaunchHistory: {
    title: 'Run History',
    subTitle: 'Choose inputs and dates.',
    mustSaveFirst: 'Changes must be saved (or discarded) before launching a history run.',
    queueIsEmpty: 'The queue is empty, try adding inputs and dates to the queue.',
    launch: 'Launch',
    launchSuccessful: '[count] requests for history have been enqueued.',

    footNote: `
            * History jobs can delay regular data loads; consider running history during off hours,
            when the target feed is idle.
        `,
    closeWarning: 'Jobs are still in running phase or not submitted. Do you still want to close it?',

  },

};

export default MpDataWorkflowGuiText;

/* eslint-disable prefer-destructuring */
import formulaEditActionsConstants from '../constants/formulaEditActions';
import qaEditActionsConstants from '../constants/qaEditActions';

import Numbers, { isNumber } from './utils/numbers.es6';
import Dates from './utils/dates/dates.es6';
import Strings from './utils/strings.es6';

const EOL = '\n';
const REGEX_RELATIVE_RUN_DATE = new RegExp('^(-?\\d+)([hdDMy])$');

const PATT_VAR_NAME_USER_DEF = '[a-zA-Z][a-zA-Z_0-9]*';
const PATT_VAR_NAME_BUILT_IN = '\\$[a-zA-Z][a-zA-Z_0-9]*';
const PATT_VAR_NAME_ANY = '\\$?[a-zA-Z][a-zA-Z_0-9]*';
const PATT_RESOLVED_MATH = '\\{\\{@(\\d+)\\}\\}';
const PATH_BASIC_MATH_INPUT = `(${PATT_VAR_NAME_ANY}|${PATT_RESOLVED_MATH}|${Numbers.FLOAT_PATTERN})`;

const REGEX_VAR_NAME_ANY = new RegExp(`^${PATT_VAR_NAME_ANY}$`);
const REGEX_MATH_RESOLVED = new RegExp(`^${PATT_RESOLVED_MATH}$`);
const REGEX_FLOAT = new RegExp(`^${Numbers.FLOAT_PATTERN}$`);
const REGEX_BASIC_MATH_1 = new RegExp(
  `\\s*${PATH_BASIC_MATH_INPUT}\\s*([\\*\\/])\\s*${PATH_BASIC_MATH_INPUT}\\s*`,
);
const REGEX_BASIC_MATH_2 = new RegExp(
  `\\s*${PATH_BASIC_MATH_INPUT}\\s*([\\+\\-])\\s*${PATH_BASIC_MATH_INPUT}\\s*`,
);
const EXTRACT_NUMBER_FROM_STRING_REGEX = /(-?\d+(\.\d+)?)/;

/** @type {{h: "addHours", d: "addDays", M: "addMonths", y: "addYears"}} */
const DATE_CODE_MAP = Object.freeze({
  h: 'addHours',
  d: 'addDays',
  M: 'addMonths',
  y: 'addYears',
});

function extractNumberFromString(inputString) {
  const match = inputString.match(EXTRACT_NUMBER_FROM_STRING_REGEX);
  return match ? parseFloat(match[0]) : NaN;
}

/**
 * Converts JavaScript code that represents a date into a date value.
 * @param {string} code - JavaScript code that represents a date.
 * @returns {string} Date value, such as "-0d", "-2D" or "yyyy-MM-dd".
 * @throws IllegalArgumentException - If the given JavaScript code
 *                                    is not recognized.
 * @private
 */
function _getDateValueFromCode(code) {
  // Today
  if (code === '$RUN_DATE') {
    return '-0d';
  }

  // Minus X days
  let m = new RegExp(
    `\\$RUN_DATE\\.(addHours|addDays|addMonths|addYears)\\s*\\(\\s*(${Numbers.INTEGER_PATTERN})\\s*(?:,.*?)?\\)`,
  ).exec(code);
  if (m !== null) {
    return m[2] + _.invert(DATE_CODE_MAP)[m[1]];
  }

  // Minus X business days
  m = new RegExp(`add_business_days\\(\\$RUN_DATE, (${Numbers.INTEGER_PATTERN})\\)`).exec(code);
  if (m !== null) {
    return `${m[1]}D`;
  }

  // Fixed date
  m = new RegExp('as\\.date\\([\'"]([\\d\\-T:.]+)[\'"]\\)').exec(code);
  if (m !== null && Dates.isIsoDate(m[1])) {
    return m[1];
  }

  throw new Error(
    `IllegalArgumentException: Unable to convert date code into date value (\`${code}\`)`,
  );
}

function reverseParseForwardCurveConfigFromFormula(code) {
  const templateObject = formulaEditActionsConstants.formulaNodeTemplateObject.forward_curve;
  const regex = new RegExp(
    `^\\s*var ${PATT_VAR_NAME_USER_DEF} = (forward_curve(?:_arbitrage_free)?)(_basic)?\\(` // [1], [2]
    + `(${PATT_VAR_NAME_BUILT_IN}), ` // product [3]
    + '(.*?), ' // curve_date [4]
    + '"([A-Za-z0-9-]+)"' // delivery [5]
      + '\\);\\s*'
      + `(${PATT_VAR_NAME_USER_DEF} = drop_nans\\(${PATT_VAR_NAME_USER_DEF}\\);\\s*)?`,
  ); // drop nans [6]
  const match = regex.exec(code);

  templateObject.variableName = match[0].split(' ')[1];
  templateObject.product = [match[3]];
  templateObject.delivery = [formulaEditActionsConstants.forwardCurveUI.deliveryTypeMap[match[5]]];
  templateObject.method = [match[1].includes('arbitrage_free') ? 'Arbitrage-free' : 'Plain/Arbitrage'];
  templateObject.includeNans = false || !match[6];
  templateObject.arbitrageFree.basic = false || (!!match[2] && match[2].includes('basic'));
  templateObject.curveDaysBackValue = extractNumberFromString(_getDateValueFromCode(match[4])) * -1;
  templateObject.curveDate = [isNumber(templateObject.curveDaysBackValue) ? 'today-minus' : 'fixed-date'];

  if (match[4] && match[4].includes('as.date')) {
    templateObject.curveDate = ['fixed-date'];
    templateObject.curveFixedDate = new Date(/(\d+-\d+-\d+)/gm.exec(match[4])[0]);
    templateObject.curveDaysBackValue = 0;
  }

  return templateObject;
}

function reverseParseTimeSeriesConfigFromFormula(code) {
  const templateObject = formulaEditActionsConstants.formulaNodeTemplateObject.time_series;
  const timeDropdownMapping = {
    y: 'years',
    d: 'days',
    h: 'hours',
    M: 'months',
    D: 'business days',
  };
  const match = new RegExp(
    `^var\\s*${PATT_VAR_NAME_ANY}\\s*=`
      + '\\s*(time_series|time_series_tz)\\s*\\('
      + `\\s*(${PATT_VAR_NAME_ANY})\\s*`
      + '(,\\s*["\']([^"\']+)["\']\\s*)?'
      + ',\\s*(.*?)\\s*\\)\\s*(?:;?\\s*)?$',
  ).exec(code);

  if (match === null) {
    throw new Error('IllegalStateException: unable to parse unrecognized TIME_SERIES code snipet.');
  }

  const matchStartDate = REGEX_RELATIVE_RUN_DATE.exec(_getDateValueFromCode(match[5]));
  if (matchStartDate === null) {
    throw new Error(
      'IllegalStateException: unable to parse START_DATE argument from TIME_SERIES code snipet.',
    );
  }

  templateObject.variableName = match[0].split(' ')[1];
  templateObject.product = [match[2]];
  templateObject.intradayData = match[1] === 'time_series_tz' || matchStartDate[2] === 'H';

  if (match[3]) {
    templateObject.timeZone = [match[4]];
  }

  templateObject.howFarBack = parseInt(matchStartDate[1], 10) * -1;
  templateObject.time = [timeDropdownMapping[matchStartDate[2]]];

  return templateObject;
}

function reverseParseFreeTextConfigFromFormula(code) {
  const templateObject = formulaEditActionsConstants.formulaNodeTemplateObject.free_text;
  const variableName = code.split(' ')[1].trim();
  const parsed = code.split('=')[1].trim();

  templateObject.variableName = variableName;
  templateObject.code = parsed;

  return templateObject;
}

function reverseParseFillConfigFromFormula(code) {
  const templateObject = formulaEditActionsConstants.formulaNodeTemplateObject.fill;
  const regex = new RegExp(`^var ${ PATT_VAR_NAME_ANY } = (fill_forward|fill_backward)\\((${ PATT_VAR_NAME_ANY })\\);$`);
  const match = regex.exec(code);

  if (match === null) { throw new Error('IllegalStateException: unable to parse unrecognized FILL code snipet.'); }

  templateObject.variableName = match[0].split(' ')[1];
  templateObject.curve = [match[2]];
  templateObject.direction = [match[1] === 'fill_forward' ? 'forward' : 'backward'];

  return templateObject;
}

function reverseParseMergeConfigFromFormula(code) {
  const templateObject = formulaEditActionsConstants.formulaNodeTemplateObject.merge;
  const pattOther = `\\s*,\\s*(${ PATT_VAR_NAME_ANY })`;
  const curvesPatt = /([(][a-zA-Z][a-zA-Z_0-9,\s+]*[)])/gm;
  const regexMain = new RegExp(`^var\\s+${ PATT_VAR_NAME_ANY }\\s*=\\s*`
                                + '(union|intersection|complement|overwrite)'
                                + '\\s*\\(\\s*'
                                + `(${ PATT_VAR_NAME_ANY })(?:${ pattOther })+`
                                + '\\s*\\);$');
  const match = regexMain.exec(code);

  if (match === null) { throw new Error('IllegalStateException: unable to parse unrecognized MERGE code snipet.'); }

  templateObject.variableName = match[0].split(' ')[1];
  templateObject.func = match[1];

  const curvesArray = code.match(curvesPatt)[0].replaceAll(/\(|\)/gm, '').split(',').map(c => c.trim());
  templateObject.intoCurve = [curvesArray[0]];
  templateObject.mergeCurve = [curvesArray[1]];

  if (curvesArray.length > 2) {
    templateObject.additionalCurvesToMerge = curvesArray.slice(2);
  }

  return templateObject;
}

function reverseParseMissingDataFromFormula(code) {
  const templateObject = qaEditActionsConstants.formulaNodeTemplateObject.missing_data_qa;
  const regex = /(\d+)(,\s+)(reduce_to_count\((\$?[a-zA-Z][a-zA-Z_0-9]*)\))/gm;
  const regexVar = /(Test.fail\("(\$?[a-zA-Z][a-zA-Z_0-9]*))/gm;
  const regexCustomMessage = /\\n\\nCustom Message:\\n" \+ "(.*)"/gm;

  const match = regex.exec(code);
  const varMatch = regexVar.exec(code);

  if (code.includes('Custom Message')) {
    const customMessageMatch = regexCustomMessage.exec(code);
    console.log(customMessageMatch);
    templateObject.customMessage = customMessageMatch[1];
  }

  templateObject.contains = +match[1];
  templateObject.curve = [match[4]];
  templateObject.variableName = varMatch[2];

  if (match === null) { throw new Error('IllegalStateException: unable to parse unrecognized MISSING_DATA_QA code snipet.'); }

  return templateObject;
}

function reverseParseDataSpikeFromFormula(code) {
  const templateObject = qaEditActionsConstants.formulaNodeTemplateObject.spike_qa;

  const sidebySideRegex = /cell_diff\((\$?[a-zA-Z][a-zA-Z_0-9]*), (\$?[a-zA-Z][a-zA-Z_0-9]*)\), (\d+)/gm;
  const linearityRegex = /change\((\$?[a-zA-Z][a-zA-Z_0-9]*), (\d+)\), (\d+)/gm;
  const regexCustomMessage = /\\n\\nCustom Message:\\n" \+ "(.*)"/gm;
  const isLinear = code.includes('when_date_in');
  const regexVar = /(Test.fail\("(\$?[a-zA-Z][a-zA-Z_0-9]*))/gm;
  let match = null;

  if (isLinear) {
    match = linearityRegex.exec(code);
    templateObject.comparison = ['linearity'];
  } else {
    match = sidebySideRegex.exec(code);
    templateObject.comparison = ['side_by_side'];
    templateObject.compareWith = [match[2]];
  }

  if (code.includes('Custom Message')) {
    const customMessageMatch = regexCustomMessage.exec(code);
    console.log(customMessageMatch);
    templateObject.customMessage = customMessageMatch[1];
  }

  templateObject.curve = [match[1]];
  templateObject.spikeValue = [match[3]];
  templateObject.variableName = regexVar.exec(code)[2];

  return templateObject;
}


function reverseParseInRangeDataFromFormula(code) {
  const templateObject = qaEditActionsConstants.formulaNodeTemplateObject.in_range_qa;
  const regex = /((\$?[a-zA-Z][a-zA-Z_0-9]*), (\d+), (\d+))/gm;
  const regexVar = /(Test.fail\("(\$?[a-zA-Z][a-zA-Z_0-9]*))/gm;
  const regexCustomMessage = /\\n\\nCustom Message:\\n" \+ "(.*)"/gm;

  const match = regex.exec(code);
  const varMatch = regexVar.exec(code);

  if (code.includes('Custom Message')) {
    const customMessageMatch = regexCustomMessage.exec(code);
    console.log(customMessageMatch);
    templateObject.customMessage = customMessageMatch[1];
  }

  console.log(match);

  templateObject.lowest = +match[3];
  templateObject.highest = +match[4];
  templateObject.curve = [match[2]];
  templateObject.variableName = varMatch[2];

  if (match === null) { throw new Error('IllegalStateException: unable to parse unrecognized IN_RANGE_QA code snipet.'); }

  return templateObject;
}

function reverseParseBasicMath(step) {
  const templateObject = formulaEditActionsConstants.formulaNodeTemplateObject.basic_math;
  const code = step.scriptSnippet();
  const parsed = Strings.parseAutoGenJS(code);
  const origExpr = parsed.metadata.expr.replace('\\n', EOL);

  templateObject.computation = origExpr;
  templateObject.variableName = step.name();
  return templateObject;
}

function reverseParseExtrapolation(step) {
  const templateObject = formulaEditActionsConstants.formulaNodeTemplateObject.extrapolation;
  const parsed = Strings.parseAutoGenJS(step.scriptSnippet());
  const md = parsed.metadata;

  templateObject.timeSeriesVar.push(md.ts_var);
  templateObject.method.push(md.method);

  if (md.method === 'follow_dataset') {
    templateObject.methCtrls[0].other.push(md.ts_other);
    templateObject.methCtrls[0].extendTo = md.extend_to;
    templateObject.methCtrls[0].fixedLen = md.fixed_length;
    templateObject.methCtrls[0].fixedDate = md.fixed_date;
  } else {
    templateObject.methCtrls[1].repeats = md.size;
    templateObject.methCtrls[1].delivery.push(md.delivery);
    const date = Dates.isoStringToUTCDate(md.stop_date);
    let text = md.stop_date;
    if (date !== null) {
      text = Dates.getFormatter('yyyy-MM-dd', true)(date);
    }
    templateObject.methCtrls[1].stopDate = text;
  }
  templateObject.variableName = step.name();
  return templateObject;
}

export default function reverseParseConfigFromFormula(dataNode) {
  const [formulaType = '', code = ''] = [dataNode.type()._name, dataNode._code];
  switch (formulaType) {
    case 'forward_curve':
      return reverseParseForwardCurveConfigFromFormula(code);
    case 'time_series':
      return reverseParseTimeSeriesConfigFromFormula(code);
    case 'free_text':
      return reverseParseFreeTextConfigFromFormula(code);
    case 'fill':
      return reverseParseFillConfigFromFormula(code);
    case 'merge':
      return reverseParseMergeConfigFromFormula(code);
    case 'missing_data_qa':
      return reverseParseMissingDataFromFormula(code);
    case 'spike_qa':
      return reverseParseDataSpikeFromFormula(code);
    case 'in_range_qa':
      return reverseParseInRangeDataFromFormula(code);
    case 'basic_math':
      return reverseParseBasicMath(dataNode);
    case 'extrapolation':
      return reverseParseExtrapolation(dataNode);
    default:
      return {};
  }
}

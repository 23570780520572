const state = {
  sources: [],
  feeds: [],
  keys: [],
  cols: [],
  totalItems: 0,
  metadata: [],
  dataPreview: [],
  keySearchResults: [],
  basket: [],
};
export default state;

/* **************************************
 * Private variables
 * ************************************** */

const INTEGER_EPSILON = 0.0000000000001;

const PATT_FLOAT = '\\-?(?:\\d+(?:\\.\\d+)?|\\.\\d+)';
const PATT_INT = '\\-?\\d+';

const _integerRegex = new RegExp(`^\\s*${ PATT_INT }\\s*$`);
const _floatRegex = new RegExp(`^\\s*${ PATT_FLOAT }\\s*$`);
const _regexNaN = new RegExp('^\\s*(NaN|[\\+\\-]?Infinity)\\s*$');
const _regexDecFormat = new RegExp('^([\\-\\+])?0+(\\.(0+))?(%)?$');
const _regexFracFormat = new RegExp("^([\\-\\+])?0+'1/(\\d+)$");

const _formatFnDec = {}; // Cached formatters - getDecimalFormatter
const _formatFnFract = {}; // Cached formatters - getFractionFormatter
const _formatFnAll = {}; // Cached formatters - getFormatter

/* **************************************
 * Private functions
 * ************************************** */

/**
 * Throws a TypeError object.
 * @param {string} expectedType - Expected data-type.
 * @param {?string} argName1 - Primary name given to the argument that failed validation.
 * @param {string} argName2 - Fallback name given to the argument that failed validation.
 * @private
 * @throws TypeError Always.
 */
function _throwTypeError(expectedType, argName1, argName2) {
  const name = ((typeof argName1 === 'string') ? argName1 : argName2);
  throw new TypeError(`${name }: ${ expectedType}`);
}

/**
 * @param {(number|*)} number Argument to validate.
 * @returns {boolean} Whether `typeof number === "number"`.
 * @private
 */
function _isAnyNumber(number) {
  return (typeof number === 'number');
}

/**
 * @param {(number|*)} number Argument to validate.
 * @returns {boolean} Whether `number` is a finite number.
 * @private
 */
function _isNumber(number) {
  return (_isAnyNumber(number)
            && isFinite(number));
}

function _isIntegerString(numberAsString) {
  return (typeof numberAsString === 'string'
        && _integerRegex.test(numberAsString));
}

function _isFloatString(numberAsString) {
  return (typeof numberAsString === 'string'
        && _floatRegex.test(numberAsString));
}

function _isNumberString(str) {
  return (typeof str === 'string'
        && (_floatRegex.test(str)
            || _regexNaN.test(str))
  );
}

function _isLiteralNaN(number) {
  return (typeof number === 'number'
        && String(number) === 'NaN');
}

function _isInteger(number) {
  return (_isNumber(number)
        && number % 1 === 0);
}

function _isMinInteger(number, min) {
  return (_isInteger(number) && number >= min);
}

function _isPosNumber(number) {
  return (_isNumber(number) && number > 0);
}

function _isPosInt(number) {
  return _isMinInteger(number, 1);
}

function _isNonNegNumber(number) {
  return (_isNumber(number) && number >= 0);
}

function _isNonNegInt(number) {
  return _isMinInteger(number, 0);
}

/**
 * @param {(number|*)} number
 * @param {(int|number)} min
 * @param {(int|number)} max
 * @returns {boolean} Whether `min <= number <= max`.
 * @private
 */
function _isNumberBetween(number, min, max) {
  return (_isNumber(number)
        && number >= min
        && number <= max);
}

/**
 * @param {(number|*)} number
 * @param {number} min
 * @param {number} max
 * @returns {boolean} Whether `min <= number <= max`.
 * @private
 */
function _isIntBetween(number, min, max) {
  return (_isInteger(number)
        && number >= min
        && number <= max);
}

/**
 * @param {Arguments} args
 * @param {Function.<boolean, (number|*)>} validator
 * @returns {number} First number.
 * @throws {Error} If no number found in `args`.
 * @private
 */
function _firstNumber(args, validator) {
  const len = args.length;

  for (let i = 0; i < len; i++) {
    if (validator(args[i])) { return args[i]; }
  }

  throw new Error(`No number found within the ${ len } arguments provided.`);
}


function _validFormat(format) {
  if (typeof format !== 'string'
        || format === '') { throw 'IllegalArgumentException: format must be a non-empty String.'; }
}

/**
 * Returns the value of an optional boolean argument.
 * @param boolVal {Boolean} or <em>undefined</em>
 * @param defaultVal {Boolean}
 * @returns {Boolean}
 */
function _getBoolValue(boolVal, defaultVal) {
  const typeOf = typeof boolVal;

  if (typeOf === 'boolean') return boolVal;
  if (typeOf === 'undefined') return defaultVal;
  throw 'IllegalArgumentException: boolVal must be a Boolean.';
}


function _getDecFormatter(numDecimals, isPlus, isPercent) {
  Numbers.requireNonNegativeInteger(numDecimals, 'numDecimals');

  isPlus = _getBoolValue(isPlus, false);
  isPercent = _getBoolValue(isPercent, false);

  const key = `${numDecimals.toString(10)
  }_${ (isPlus) ? 't' : 'f'
  }_${ (isPercent) ? 't' : 'f'}`;

  if (_formatFnDec.hasOwnProperty(key)) return _formatFnDec[key];


  const fn = function (num) {
    if (!_isNumber(num)) return num;


    if (isPercent) num *= 100;

    let str = ((isPlus
                    && num > 0)
      ? '+'
      : '')
                    + num.toFixed(numDecimals);

    if (isPercent) str += '%';

    return str;
  };

  _formatFnDec[key] = fn;
  return fn;
}

function _getFractFormatter(denominator, isPlus) {
  if (!_isNonNegInt(denominator)) throw 'IllegalArgumentException: denominator must be a non-negative Integer.';

  isPlus = _getBoolValue(isPlus, false);

  const key = `${denominator.toString(10)
  }_${ (isPlus) ? 't' : 'f'}`;

  if (_formatFnFract.hasOwnProperty(key)) return _formatFnFract[key];


  const fn = function (num) {
    if (!_isNumber(num)) return num;

    let prefix = null;
    if (num < 0) {
      num *= -1;
      prefix = '-';
    } else if (isPlus
                && num > 0) prefix = '+';
    else prefix = '';

    const whole = Math.floor(num);
    const fract = Math.abs((num % 1) * denominator);
    const rounded = Math.round(fract);

    if (Math.abs(rounded - fract) < 0.001) // Account for "float" inaccuracy
    { return `${prefix + whole.toString() }'${ rounded.toString()}`; }
    return prefix + num.toString();
  };

  _formatFnFract[key] = fn;
  return fn;
}


/**
 * Returns the index position of the last character that's not `c`.
 * This method looks backward, starting at `start`.
 * @param {string} str
 * @param {string} c - `c.length` must be 1.
 * @param {int} start
 * @returns {int} Index position of the last character that's not `c`.
 * @private
 */
function _lastDiff(str, c, start) {
  let idx = start;
  while (idx >= 0
    && str.charAt(idx) === c) { idx--; }

  return idx;
}

/**
 * Returns the index of the last character which isn't zero.
 * @param {string} str
 * @returns {int} Returns -1 if `str.length === 0` or if "000".
 * @private
 */
function _lastNonZero(str) {
  return _lastDiff(str, '0', str.length - 1);
}


/**
 * <p>
 *     Returns the number of significant decimals in a floating-decimal value.
 * </p>
 *
 * <p>
 *     How it works: this method assumes that the mathematical operations
 *     that resulted in the double value `d` did not involve tiny fractions
 *     in the first place.  With that assumption, this method treats
 *     any "tiny fraction" as IEEE side-effects, and ignores them.
 *     If the inputs to `d` might contain such tiny fractions
 *     and callers need 100% accuracy, callers should use BigDecimal
 *     instances to calculate their results.
 * </p>
 *
 * @param {number} number
 * @param {boolean} [noError=false] Whether a non-numerical `number` show fail quietly and return
 *     zero `0`.
 * @returns {int} Number of significant decimals: max 14, 0=integer, negative values indicate
 *     integers with a at least one zero (10 => -1, 700 => -2, 5000 => -3, etc.)
 * @private
 */
function _getPrecision(number, noError) {
  if (!_isNumber(number)) {
    if (noError === true) return 0;
    throw new TypeError('number: Number');
  }

  const remains = Math.abs(number % 1);
  if (remains < INTEGER_EPSILON
        || 1 - remains < INTEGER_EPSILON) { // Integer
    const str = number.toString(10);
    let len = str.indexOf('.');

    if (len < 0) len = str.length;

    const lastInt = len - 1;
    return -(lastInt - _lastDiff(str, '0', lastInt));
  }


  /* To work around the IEEE weirdness, we fix the format
         * to 14 decimals then drop the trailing zeros. */
  const str = number.toFixed(14);
  const dot = str.indexOf('.');
  const lastDigit = _lastNonZero(str);
  let numDigits = lastDigit - dot;

  if (numDigits < 10) return numDigits;

  // The previous trick failed, see if we have repetitive decimals "ad infinitum".
  // Examples: 99.0894444444 (99.0894444444),
  //           99.7088888889 (99.7088888889),
  //           74.36000000000001 (74.36),
  //           153.26999999999998 (153.27),
  //           998.29499999999905 (998.295),
  //           594.03500000000042 (594.035), etc.

  // Look for 4 or more repeated digit anywhere within the fraction.
  // Finding such scenario means we likely stumbled upon a IEEE-induced
  // fraction.
  let idx = lastDigit;
  let numRepeat = 1;
  let repeatChar = str.charAt(idx);
  let charAt;

  while ((--idx) > dot) {
    charAt = str.charAt(idx);
    if (charAt !== repeatChar) {
      repeatChar = charAt;
      numRepeat = 1;
    } else if ((++numRepeat) >= 4) {
      // Found a sequence of 4 repeating digits

      if (repeatChar === '0'
                    || repeatChar === '9') {
        // Found "0000" or "9999" which indicates
        // a tiny fraction.  The precision stops at the point
        // before the sequence starts.
        numDigits = _lastDiff(str, repeatChar, idx) - dot;

        // Look for non-0 and non-9 digits between '.' and
        // the sequence (i.e. "0.10000000005").  If found,
        // the precision is at the point before the sequence starts.
        // Otherwise (i.e. "0.00000000005") we preserve all decimals.

        if (numDigits > 0
                        || numRepeat === numDigits) return numDigits;
        return lastDigit - dot;
      }


      // Found sequence of other digits, like "4444" or "6666",
      // in which case the precision is at the point where
      // the repeating sequence ends.
      return Math.min(14, (idx - dot) + numRepeat);
    }
  }

  // We didn't find a tiny fraction, preserve all decimals
  // (calculated as "number of bytes between '.' and end-of-string".
  return lastDigit - dot;
}


/** @namespace */
const Numbers = {

  /* *********************************************
     * FUNCTIONS - public
     * ********************************************* */

  /**
     * A string pattern that represents a floating-decimal number.
     * The pattern does not contain any capturing group and can be
     * used within bigger patterns.
     * @type {string}
     */
  FLOAT_PATTERN: PATT_FLOAT,

  /**
     * A string pattern that represents an integer.
     * The pattern does not contain any capturing group and can be
     * used within bigger patterns.
     * @type {string}
     */
  INTEGER_PATTERN: PATT_INT,

  /** @return {boolean} Whether <code>number</code> is a finite number. */
  isNumber: _isNumber,

  /**
     * Returns a normalized number.  If <code>number</code>
     * is not a number (according to <code>Numbers.isNumber()</code>)
     * then <code>Number.NaN</code> is returned.
     * @param {(number|*)} number
     * @returns {number} May be NaN.
     */
  normalizedNumber(number) {
    return (_isNumber(number) ? number : Number.NaN);
  },

  /**
     * @param {...(number|*)} args
     * @returns {number} First number found in argument list. This method considers NaN,
     *          +Infinity and -Infinity as acceptable numbers.
     * @throws {Error} If none of the arguments evaluates to a number.
     */
  firstNumber(args) {
    return _firstNumber(arguments, _isAnyNumber);
  },

  /**
     * @param {...(number|*)} args
     * @returns {number} First number found in argument list. This method considers NaN,
     *          +Infinity and -Infinity as **unacceptable** numbers.
     * @throws {Error} If none of the arguments evaluates to a finite number.
     */
  firstFiniteNumber(args) {
    return _firstNumber(arguments, _isNumber);
  },

  /**
     * Compares two numbers for the purpose of sorting.
     * This function pushes items that aren't numbers at the end
     * of the sort order.
     *
     * @param n1 {*}
     * @param n2 {*}
     * @returns {number}
     */
  compare(n1, n2) {
    const isOk1 = _isNumber(n1);
    const isOk2 = _isNumber(n2);

    if (isOk1 && isOk2) {
      // both are finite numbers
      return (n1 - n2);
    } if (isOk1) return -1;
    if (isOk2) return 1;
    return 0;
  },

  /**
     * Returns whether <code>str</code> is a float-like
     * String, "NaN", "Infinity", "+Infinity" or "-Infinity".
     * @param {(string|*)} str
     * @return {boolean}
     */
  isNumberString: _isNumberString,

  isFloatString: _isFloatString,

  isIntegerString: _isIntegerString,

  isInteger: _isInteger,

  isLiteralNaN: _isLiteralNaN,


  /**
     * Returns <code>true</code> if <code>number</code>
     * is a non-negative Integer; otherwise returns
     * <code>false</code>.  This method does not throw
     * any exception.  Instead it returns
     * <code>false</code> if <code>number</code>
     * is is undefined, null, or not of type <code>Number</code>.
     * @param {number} number
     * @returns {boolean}
     * @method
     */
  isNonNegativeInteger: _isNonNegInt,


  /**
     * Returns <code>true</code> if <code>number</code>
     * is a positive Integer; otherwise returns
     * <code>false</code>.  This method does not throw
     * any exception.  Instead it returns
     * <code>false</code> if <code>number</code>
     * is is undefined, null, or not of type <code>Number</code>.
     * @param {number} number
     * @returns {boolean}
     * @method
     */
  isPositiveInteger: _isPosInt,

  /**
     * @param {*} i
     * @param {number} min
     * @param {number} max
     * @returns {boolean} Whether `i` is an integer that fits the equation `min &lt;= i &lt;= max`.
     * @method
     */
  isIntegerBetween: _isIntBetween,

  /**
     * @param {(number|*)} n
     * @param {(int|number)} min
     * @param {(int|number)} max
     * @returns {boolean} Whether `n` is a finite number that fits the equation
     *          `min &lt;= number &lt;= max`.
     * @method
     */
  isNumberBetween: _isNumberBetween,

  /**
     * Validates `n` to be an Number, returns it.
     * This method throws if `n` is not a number.
     * @param {number} n - Argument to validate.
     * @param {string} [argName] - Name given to argument, if error must be thrown.
     * @returns {number} Always `n`.
     * @throws TypeError If `n` is not a number.
     */
  requireNumber(n, argName) {
    if (!_isNumber(n)) _throwTypeError('Number', argName, 'n');

    return n;
  },

  /**
     * Validates `i` to be an Integer, returns it.
     * This method throws if `i` is not an integer.
     * @param {int} i - Argument to validate.
     * @param {string} [argName] - Name given to argument, if error must be thrown.
     * @returns {int} Always `i`.
     * @throws TypeError If `i` is not an integer.
     */
  requireInteger(i, argName) {
    if (!_isInteger(i)) _throwTypeError('Integer', argName, 'i');

    return i;
  },

  /**
     * Validates `i` to be a positive Integer, returns it.
     * This method throws if `i` is not a positive integer.
     * @param {int} i - Argument to validate.
     * @param {string} [argName] - Name given to argument, if error must be thrown.
     * @returns {int} Always `i`.
     * @throws TypeError If `i` is not a positive integer.
     */
  requirePositiveInteger(i, argName) {
    if (!_isPosInt(i)) _throwTypeError('Integer, positive', argName, 'i');

    return i;
  },

  /**
     * Validates `i` to be a non-negative integer, returns it.
     * This method throws if `i` is not a non-negative integer.
     * @param {int} i - Argument to validate.
     * @param {string} [argName] - Name given to argument, if error must be thrown.
     * @returns {int} Always `i`.
     * @throws TypeError If `i` is not a non-negative integer.
     */
  requireNonNegativeInteger(i, argName) {
    if (!_isNonNegInt(i)) _throwTypeError('Integer, non-negative', argName, 'i');

    return i;
  },

  /**
     * Validates `i` to be an integer between `min` and `max`, inclusive.
     * If valid, this method returns `i`.
     * This method throws if `i` is not valid.
     * @param {int} i - Argument to validate.
     * @param {(int|number)} min - Lowest acceptable value.
     * @param {(int|number)} max - Highest acceptable value.
     * @param {string} [argName] - Name given to argument, if error must be thrown.
     * @returns {int} Always `i`.
     * @throws TypeError If `i` is not an integer or outside the expected range.
     */
  requireIntegerBetween(i, min, max, argName) {
    if (!_isIntBetween(i, min, max)) _throwTypeError(`Integer, range ${ min }-${ max}`, argName, 'i');

    return i;
  },

  /**
     * Validates `n` to be a positive number, returns it.
     * This method throws if `n` is not a positive number.
     * @param {number} n - Argument to validate.
     * @param {string} [argName] - Name given to argument, if error must be thrown.
     * @returns {number} Always `n`.
     * @throws TypeError If `n` is not a positive number.
     */
  requirePositiveNumber(n, argName) {
    if (!_isPosNumber(n)) _throwTypeError('Number, positive', argName, 'n');

    return n;
  },

  /**
     * Validates `n` to be a non-negative number, returns it.
     * This method throws if `n` is not a non-negative number.
     * @param {number} n - Argument to validate.
     * @param {string} [argName] - Name given to argument, if error must be thrown.
     * @returns {number} Always `n`.
     * @throws TypeError If `n` is not a non-negative number.
     */
  requireNonNegativeNumber(n, argName) {
    if (!_isNonNegNumber(n)) _throwTypeError('Number, non-negative', argName, 'n');

    return n;
  },

  /**
     * Validates `n` to be an number between `min` and `max`, inclusive.
     * If valid, this method returns `n`.
     * This method throws if `n` is not valid.
     * @param {number} n - Argument to validate.
     * @param {(int|number)} min - Lowest acceptable value.
     * @param {(int|number)} max - Highest acceptable value.
     * @param {string} [argName] - Name given to argument, if error must be thrown.
     * @returns {number} Always `n`.
     * @throws TypeError If `n` is not a finite number or outside the expected range.
     */
  requireNumberBetween(n, min, max, argName) {
    if (!_isNumberBetween(n, min, max)) _throwTypeError(`Number, range ${ min }-${ max}`, argName, 'n');

    return n;
  },

  /**
     * Similar to the native <code>parseFloat()</code> function
     * except that this function recognizes strings like
     * "NaN", "Infinity", "+Infinity" and "-Infinity".
     *
     * @param str {Object}
     * @return {Number}
     */
  parseFloat(str) {
    const typeOf = typeof str;
    if (typeOf === 'number') return str;

    if (typeOf !== 'string') return Number.NaN;

    if (_floatRegex.test(str)) return parseFloat(str);

    if (str === 'Infinity'
            || str === '+Infinity') return Number.POSITIVE_INFINITY;

    if (str === '-Infinity') return Number.NEGATIVE_INFINITY;

    return Number.NaN;
  },

  normalizedInteger(number) {
    if (_isInteger(number)) return number;

    if (_isIntegerString(number)) return parseInt(number, 10);

    return Number.NaN;
  },

  /**
     * Returns <code>true</code> if <code>number</code>
     * is a non-negative Number; otherwise returns
     * <code>false</code>.  This method does not throw
     * any exception.  Instead it returns
     * <code>false</code> if <code>number</code>
     * is is undefined, null, or not of type <code>Number</code>.
     * @param {(number|*)} number
     * @returns {boolean} Whether the given argument is a number greater than or equal to 0.
     */
  isNonNegativeNumber: _isNonNegNumber,

  /**
     * Returns <code>true</code> if <code>number</code>
     * is a positive Number; otherwise returns
     * <code>false</code>.  This method does not throw
     * any exception.  Instead it returns
     * <code>false</code> if <code>number</code>
     * is is undefined, null, or not of type <code>Number</code>.
     * @param {(number|*)} number
     * @returns {boolean} Whether the given argument is a number greater than 0.
     */
  isPositiveNumber: _isPosNumber,

  /**
     * Converts `i` to its string representation, and prepends 0s
     * until the string reaches a minimum length.
     *
     * If `i` is negative, the '-' sign is not considered part of the minimum length.
     *
     * Examples:
     * <ul>
     *  <li> leadingZeros(5, 2) => "05" </li>
     *  <li> leadingZeros(12, 2) => "12" </li>
     *  <li> leadingZeros(148, 2) => "148" </li>
     *  <li> leadingZeros(-5, 2) => "-05" </li>
     * </ul>
     * @param {int} i
     * @param {int} minLength
     * @returns {string}
     */
  leadingZeros(i, minLength) {
    let str = i.toString(10);
    let sign = '';

    if (i < 0) {
      sign = '-';
      str = str.substring(1);
    }

    while (str.length < minLength) str = `0${ str}`;

    return sign + str;
  },

  /**
     * Returns the highest precision found within an Iterator of numbers.
     * If this is a float, this method returns the number of decimals.
     * If this is an integer, this method returns the 10th power as a negative number.
     *
     * @param {Arrays.Iterator} itr
     * @param {boolean} [noError=false] Whether encountering a non-numeric
     *                          value should be handled gracefully
     *                          (<em>true</em>) or throw an exception
     *                          (<em>false</em>).
     *                          Default is <em>false</em>.
     * @return {int}
     * @see Numbers.precision
     */
  maxPrecision(itr, noError) {
    if (typeof itr.hasNext !== 'function'
            || typeof itr.next !== 'function') throw 'IllegalArgumentException: itr must implement methods hasNext() and next().';

    let prec = 0;
    while (itr.hasNext()) {
      const p = _getPrecision(itr.next(), noError);
      if (p > prec) prec = p;
    }

    return prec;
  },


  /**
     * <p>
     *  Returns the precision of a number.
     *  If this is a float, this method returns the number of decimals.
     *  If this is an integer, this method returns the 10th power as a negative number.
     * </p>
     *
     * <ul> Examples:
     *  <li> 2.34 returns 2; </li>
     *  <li> 2 returns 0; </li>
     *  <li> 9 returns 0; </li>
     *  <li> 100 returns -2; </li>
     * </ul>
     */
  precision: _getPrecision,

  round(number, numDecimals) {
    if (typeof number !== 'number'
            || typeof numDecimals !== 'number') return number;

    const pow = Math.pow(10, numDecimals);
    return Math.round(number * pow) / pow;
  },


  /**
     * Converts a number to a String as humans expect.
     * This function hides IEEE 754 adjustments.
     *
     * This function is useful when converting to String
     * without knowing how many decimals are needed.
     *
     * Note that there is a penalty for calling this function,
     * for determining the number of decimals to round to.
     * If caller knows how many decimals it wants in the
     * resulting String, it should use the native
     * Number.toFixed() method instead.
     *
     * @param num {Number}
     * @return {String}
     */
  toString(num) {
    // Support a no-argument version, for when browsers uses
    // `toString()` to create an exception message.
    if (arguments.length < 1) return 'Numbers';

    if (typeof num !== 'number') throw 'TypeMismatch: num: Number';

    else if (!isFinite(num)) return num.toString();

    else {
      const p = _getPrecision(num);
      return num.toFixed(Math.max(0, p));
    }
  },


  /**
     * Returns a Function that formats numbers
     * with the given number of decimals (<code>numDecimals</code>).
     *
     * For example, 5.125 represented
     * with 2 decimals becomes "5.13".
     *
     * @param {int} numDecimals - Non-negative.
     * @param {boolean} [isPlus] - Whether positive numbers are prefixed with '+' character.
     * @param {boolean} [isPercent] - Whether numbers that will be formatted
     *                            represent a percentage value.  If <em>true</code>
     *                            numbers are multiplied by 100 and '%' is appended
     *                            to the output.
     * @return {Function} A function that takes one <em>number</em>
     *                    argument and converts it into a String value.
     */
  getDecimalFormatter: _getDecFormatter,

  /**
     * Returns a Function that formats numbers
     * into fractions.
     *
     * For example, 5.125 represented as a fraction
     * of eighth (1/8) becomes "5'1".
     *
     * @param {int} denominator - Non-negative.
     * @param {boolean} [isPlus] - Whether positive numbers are prefixed with '+' character.
     * @return {Function} A function that takes one <em>number</em>
     *                    argument and converts it into a String value.
     */
  getFractionFormatter: _getFractFormatter,


  /**
     * <p>
     *  Returns a Function that formats numbers
     *  into a <em>string</em> value.
     * </p>
     * <p>
     *  Examples:
     * </p>
     * <ul>
     *  <li> Numbers.getFormatter("0.00")(5.125) == "5.13"; </li>
     *  <li> Numbers.getFormatter("0.0000")(5.125) == "5.1250"; </li>
     *  <li> Numbers.getFormatter("0'1/8")(5.125) == "5'1"; </li>
     * </ul>
     *
     * @param format {String}
     * @return {Function} A function that takes one <em>number</em>
     *                    argument and converts it into a String value.
     */
  getFormatter(format) {
    _validFormat(format);

    let fn = null;

    if (_formatFnAll.hasOwnProperty(format)) fn = _formatFnAll[format];

    else {
      let m = _regexDecFormat.exec(format);
      if (m !== null) {
        fn = _getDecFormatter(
          ((typeof m[3] === 'string')
            ? m[3].length
            : 0),
          (m[1] === '+'),
          (m[4] === '%'),
        );
      } else {
        m = _regexFracFormat.exec(format);
        if (m !== null) {
          fn = _getFractFormatter(
            parseInt(m[2], 10),
            (m[1] === '+'),
          );
        } // isPlus
      }

      _formatFnAll[format] = fn;
    }

    if (fn !== null) return fn;
    throw `IllegalArgumentException: unsupported number format (${ format })`;
  },

  /**
     * Returns the number of decimals necessary to accurately perform
     * mathematical operations with the specified <code>format</code>.
     *
     * @param {string} format
     * @return {int} Number of decimals, zero or higher.
     */
  getFormatPrecision(format) {
    _validFormat(format);

    let m = _regexDecFormat.exec(format);
    if (m !== null) {
      return ((typeof m[3] === 'string') ? m[3].length : 0)
                + ((m[4] === '%') ? 2 : 0);
    }


    m = _regexFracFormat.exec(format);
    if (m !== null) return _getPrecision(1 / parseInt(m[2], 10));


    throw `IllegalArgumentException: format is not recognized (${ format })`;
  },
};

export default Numbers;
export const { requireNumber } = Numbers;
export const { requireInteger } = Numbers;

export const { requirePositiveNumber } = Numbers;
export const { requirePositiveInteger } = Numbers;

export const { requireNonNegativeNumber } = Numbers;
export const { requireNonNegativeInteger } = Numbers;

export const { requireNumberBetween } = Numbers;
export const { requireIntegerBetween } = Numbers;

export const { isNumber } = Numbers;
export const { isInteger } = Numbers;

export const { isPositiveNumber } = Numbers;
export const { isPositiveInteger } = Numbers;

export const { isNonNegativeNumber } = Numbers;
export const { isNonNegativeInteger } = Numbers;

export const { isNumberBetween } = Numbers;
export const { isIntegerBetween } = Numbers;

export const { isNumberString } = Numbers;
export const { isFloatString } = Numbers;
export const { isIntegerString } = Numbers;

export const { leadingZeros } = Numbers;

export const { firstNumber } = Numbers;
export const { firstFiniteNumber } = Numbers;

import _ from 'underscore';
import CryptoJS from 'crypto-js';
import { requireBoolean } from './booleans.es6';
import Numbers, {
  isInteger, isNonNegativeInteger, requireInteger, requireNonNegativeInteger,
} from './numbers.es6';
import Strings, { requireString, requireNonEmptyString } from './strings.es6';
import Dates, { requireDate } from './dates/dates.es6';
import DateRange from './daterange.js';
import Arrays from './arrays.es6';
import Objects, { isVoid, requireObject } from './objects.es6';
import Console from './console.es6';
import TimeZone from './timezone.es6';
import HttpCode from './httpcodes.es6';
import Url from './url.es6';
import MpDataWorkflow, {
  Report, ReportHeader, ReportEvent,
  ParameterSet, ParameterSetGroup, ScheduledJob,
  WorkflowConfig, WorkflowStatus,
  Target,
  Dependency, KeyArrivalDependency, TopicDependency,
  Timeout, TaskedTimeout,
  Permission, Permissions, PermissionSet, UserPermissions,
  JobStatus, HistoryRunStatus,
  requireWorkflowConfigBuilder,
} from './wf';
import Task from './task.es6';
import UserActionTask from './user_action_task.es6';
import UserAction from './user_action.es6';
import HistoryJobStatus from './histjobstatus.es6';
import ServerError from './servererror.es6';
import MpFile from './file/file.es6';
import MpFileUtils from './file/utils.es6';
import MpField, { MpFieldType } from './field/field.js';
import MpRoot from './root/root.es6';
import CommonDataFormat from './cdf.es6';
import MpUserFeatureSet from './userfeatures/userfeatureset.js';
import { requireSymbol } from './symbol.js';
import { parseJsEngineResponse } from './formulas/js/responseparser.es6';


/* ************************************************************
 * Local definitions
 * ************************************************************ */
/**
 * @typedef {Object} JobPropertyItem
 * @property {string} key
 * @property {string} value
 */

/**
 * @typedef {Object} SavedFormulaResponse
 * @property {string} name
 * @property {string} uuid
 * @property {string} type
 * @property {int} version
 */

/* ************************************************************
 * Private variables
 * ************************************************************ */

const POST = 'POST';
const GET = 'GET';
const PUT = 'PUT';
const DELETE = 'DELETE';

const APPLICATION_JSON = 'application/json';
const TEXT_CSV = 'text/csv';
const TEXT_PLAIN = 'text/plain';

const COMPANY_USER_NAME = '<<ENTIRE_COMPANY>>';
// var HOME_DIR = "/home";
const TRASH_DIR = '/trash';


const ACCEPT_APPLICATION_JSON = Object.freeze({
  Accept: APPLICATION_JSON,
});
const ACCEPT_TEXT_CSV = Object.freeze({
  Accept: TEXT_CSV,
});
const ACCEPT_TEXT_PLAIN = Object.freeze({
  Accept: TEXT_PLAIN,
});

let _reqSeq = 0;


/* ************************************************************
 * Private methods
 * ************************************************************ */

/**
 * Validates an argument to be a boolean, if provided.
 * If provided and valid, this method returns that boolean.
 * If not provided, this method returns `defaultValue`.
 * If provided with a non-boolean, this method throws.
 *
 * @param {(Arguments|IArguments)} args - Caller's Arguments object.
 * @param {int} argIdx - Index of the boolean argument to validate.
 * @param {string} argName - Name of the boolean argument.
 * @param {boolean} defaultValue - Default value to return, when boolean
 *                  argument is not provided.
 * @returns {boolean}
 * @private
 */
function _validBoolIfAvail(args, argIdx, argName, defaultValue) {
  if (argIdx >= args.length) {
    return defaultValue;
  }
  return requireBoolean(args[argIdx], argName);
}

/**
 * Validates a programming language.  If valid,
 * this method returns it.  Otherwise it throws.
 * @param {string} progLang - Non-empty. "JS" or "R".
 * @returns {string}
 * @private
 */
function _validProgLang(progLang) {
  requireNonEmptyString(progLang, 'progLang');
  if (progLang !== 'JS'
        && progLang !== 'R') {
    throw new Error(`IllegalArgumentException: progLang "${ progLang }" not supported.`);
  }
  return progLang;
}

/** @returns {boolean} Whether `arg` is a Report instance. */
function _isWfReport(arg) {
  return (arg instanceof Report);
}

/** @returns {boolean} Whether `arg` is a ReportHeader instance. */
function _isWfReportHeader(arg) {
  return (arg instanceof ReportHeader);
}

/**
 * Validates `arg` to be an instance of `MpDataWorkflow` or `Config`.
 *
 * @param {(MpDataWorkflow|WorkflowConfig)} arg
 * @param {string} argName
 * @returns {MpDataWorkflow} Always returns the Workflow object.
 * @private
 */
function _validWorkflow(arg, argName) {
  if (arg instanceof MpDataWorkflow) return arg;
  if (arg instanceof WorkflowConfig) return arg.workflow();
  throw new TypeError(`${argName }: MpDataWorkflow or WorkflowConfig`);
}

/**
 * Validates `arg` to be an instance of `Report`
 * or `ReportHeader`.
 * If valid, this method returns that argument.
 * Otherwise this method throws.
 *
 * @param {(Report|ReportHeader)} arg
 * @param {string} argName
 * @returns {(Report|ReportHeader)}
 * @private
 */
function _validWfReportOrHeader(arg, argName) {
  if (!_isWfReport(arg)
        && !_isWfReportHeader(arg)) { throw new TypeError(`${argName }: Report or ReportHeader`); }

  return arg;
}

/**
 * Returns `obj[prop]` if `obj.hasOwnProperty(prop) == true`.  Otherwise,
 * returns `fallbackValue`.
 * @param {Object} obj
 * @param {string} prop
 * @param {*} fallbackValue
 * @returns {*}
 * @private
 */
function _ifAvail(obj, prop, fallbackValue) {
  if (obj.hasOwnProperty(prop)) {
    return obj[prop];
  }
  return fallbackValue;
}

/**
 * Converts `str` to an integer.
 * @param {string} str
 * @returns {int} Integer representation of `str`, or NaN.
 * @private
 */
function _toInt(str) {
  return parseInt(str, 10);
}

/**
 * Converts `i` to a string.
 * @param {int} i
 * @returns {string} String representation of `i`.
 * @private
 */
function _toStr(i) {
  return i.toString(10);
}

/** @returns {string} Current user's login name. */
function _username() {
  return Runtime.CurrentUser.username;
}

/**
 * Returns a unique request ID.
 * @return {string}
 * @private
 */
function _newReqId() {
  return CryptoJS.SHA1(
    `${_toStr(++_reqSeq)
    }#${
      _toStr(Dates.now())}`,
  ).toString();
}

/**
 * Returns the base URL to a remote resource (aka Marketplace resource).
 * @returns {string}
 * @private
 */
function _remRscUrl() {
  return `${Url.rootPath(1) }func/mp/remote-rsc?remRscPath=`;
}

/**
 * Returns the base URL to a Marketplace formulas resource
 * (aka "/lds/formulas".)
 *
 * @returns {string}
 * @private
 */
function _remRscUrlFormulas() {
  return `${_remRscUrl() }lds/formulas`;
}


/**
 * Returns the URL encoded value of `s`.
 * This is a convenience method for those tired of typing
 * `window.encodeURIComponent()` repeatedly.
 * @param {string} s
 * @returns {string} `window.encodeURIComponent(s)`.
 * @private
 */
function _escape(s) {
  return window.encodeURIComponent(s);
}

/**
 * Escapes commas as is expected by Marketplace.
 *
 * Add an escape character (\) in front of each comma (,).
 * Also adds an escape character (\) in front of each backslash.
 *
 * <ul>
 *  <li> , becomes \, </li>
 *  <li> \ becomes \\ </li>
 *  <li> \, becomes \\\, </li>
 * </ul>
 *
 * @param text {string}
 * @return {string}
 */
function _escapeCommas(text) {
  return text.replace(new RegExp('\\\\', 'g'), '\\\\')
    .replace(new RegExp(',', 'g'), '\\,');
}

function _getOptionalArg(args, idx, defaultValue, dataType, argName) {
  if (args.length <= idx) { return defaultValue; }

  const arg = args[idx];
  if (typeof arg !== dataType) { throw `TypeMismatch: ${ argName }: ${ dataType}`; } else { return arg; }
}


/**
 * Creates a URI-component-encoded string for the purpose of forwarding them
 * to LDS as regular query parameters.  The returned string should be inserted
 * in Markets URL as encoded parameter "remQueryParams". (Yes, it needs to
 * be double-encoded!)
 *
 * @param obj {Object}
 * @param [doEscapeCommas=false] {boolean}
 * @param [keyNamePrefix=""] {string}
 * @returns {string}
 * @private
 */
function _toQueryParams(obj, doEscapeCommas, keyNamePrefix) {
  if (!(obj instanceof Object)
        || obj.constructor !== Object) { throw 'TypeMismatch: obj: Object'; }

  doEscapeCommas = _getOptionalArg(arguments, 1, false, 'boolean', 'doEscapeCommas');
  keyNamePrefix = _getOptionalArg(arguments, 2, '', 'string', 'keyNamePrefix');

  let qryParams = '';

  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      let val = obj[prop].toString(); // assuming non-null value

      if (doEscapeCommas) { val = _escapeCommas(val); }

      qryParams += `&${ _escape(keyNamePrefix + prop)
      }=${ _escape(val)}`;
    }
  }

  return qryParams.substring(1);
}


/**
 * Gets or sets a Long value as the property of instance.
 * @param {Object} instance
 * @param {string} prop
 * @param {(Arguments|IArguments)} args
 * @param {string} argName
 * @returns {?(int|Object)}
 * @private
 */
function _getsetIntOrNull(instance, prop, args, argName) {
  if (args.length < 1) return instance[prop];

  if (args[0] !== null
        && !isInteger(args[0])) throw new TypeError(`${argName }: Integer or null`);

  else {
    instance[prop] = args[0];
    return instance;
  }
}


/**
 * Freezes all objects within the given array (of shallow objects).
 * This method does a *shallow* freeze (not recursive.)
 * @param a {Object[]}
 * @returns {Object[]} A frozen array of frozen objects.
 * @private
 */
function _freezeArray(a) {
  let i = 0;
  const len = a.length;
  for (; i < len; i++) { Objects.freeze(a[i]); }

  return Objects.freeze(a);
}

/**
 * Response formatter for CreateFormula and UpdateFormula APIs.
 * @param payload {{name: string, type: string, uuid: string, [version]: int,
 *                  formula: string, [insertedTime]: string}}
 * @returns {SavedFormulaResponse}
 * @private
 */
function _formatSavedFormulaPayload(payload) {
  requireObject(payload, 'payload');
  return Object.freeze({
    name: requireString(payload.name, 'payload.name'),
    type: requireString(payload.type, 'payload.type'),
    uuid: requireString(payload.uuid, 'payload.uuid'),
    version: ((isInteger(payload.version)) ? payload.version : 1),
  });
}


/**
 * Creates a new comparator function which can then
 * be used to compare two objects of a given *constructor*
 * by their (private) `_name` property.
 *
 * @param {function} ctor - Constructor.
 * @param {function} comparator - Function to use to compare names.
 * @returns {function}
 * @private
 */
function _newCompareByName(ctor, comparator) {
  return function (o1, o2) {
    const is1 = (o1 instanceof ctor);
    const is2 = (o2 instanceof ctor);

    if (is1 && is2) return comparator(o1._name, o2._name);
    if (is1) return -1;
    if (is2) return 1;
    return 0;
  };
}

/**
 * Local function to compare two strings.
 *
 * If `strings.es6` loaded before `mpapi.es6`, we wouldn't
 * need this local function.
 * @param {string} s1
 * @param {string} s2
 * @returns {number}
 * @private
 */
function _compareString(s1, s2) {
  return Strings.compare(s1, s2);
}

/**
 * @param {string} complement URL suffix.
 * @returns {string} `MpFileUtils.getBasePath() + complement`.
 * @private
 */
function _fullFileApiUrl(complement) {
  return MpFileUtils.getBasePath() + complement;
}


/**
 * Throws an Error that describes how an RPC request didn't get its expected status code(s).
 * @param {string} expected
 * @param {int} actual
 * @private
 */
function _throwRpcStatus(expected, actual) {
  throw new Error([
    'Expected RPC results status code [', expected, '], ',
    'but got [', actual, '] instead.',
  ].join(''));
}

/**
 * Validates a RPC response results status.
 * If valid, this method returns the results `content`,
 * otherwise it throws.
 * @param {Object} result - {{ status: int, content: (Object|Array) }}
 * @param {(int|int[])} expectedStatus - Expected status code(s).
 * @private
 */
function _validRpcResults(result, expectedStatus) {
  if (!result.hasOwnProperty('status')) { throw new Error('RPC response `result.status` is missing.'); }

  requireInteger(result.status, 'RPC response `result.status`');

  if (isInteger(expectedStatus)) {
    if (result.status !== expectedStatus) { _throwRpcStatus(expectedStatus, result.status); }
  } else if (Arrays.indexOf(result.status, expectedStatus) < 0) { _throwRpcStatus(expectedStatus.join(','), result.status); }

  return result.content;
}

/* *************************************************************
 * Process response from scheduler service by converting content to a ScheduledJob.
 * ************************************************************* */
function _processScheduledJobResponse(payload, expectedStatus) {
  /**
     * @type {{ hasOwnProperty: function, jobName: string, cronExpression: string,
     *          lastFireTime: string, nextFireTime: string, properties: Object }}
     */
  const content = _validRpcResults(payload, expectedStatus);

  requireObject(content, 'content');

  const requiredProps = ['cronExpression', 'jobName', 'properties'];
  for (let ii = 0; ii < requiredProps.length; ii++) {
    if (!content.hasOwnProperty(requiredProps[ii])) { throw new Error(`GetJobSchedule - missing property [${ requiredProps[ii] }].`); }
  }

  return new ScheduledJob(
    content.jobName,
    content.cronExpression,
    content.lastFireTime,
    content.nextFireTime,
    content.properties,
  );
}

/* ***************************************************************
 * API GetTimeSeries
 * *************************************************************** */

function GetTimeSeries() { }
GetTimeSeries.prototype = {

  constructor: GetTimeSeries,

  getUrl(symbol, startDt, endDt) {
    requireSymbol(symbol, 'symbol');
    requireDate(startDt, 'startDt');
    if (arguments.length > 2) {
      Dates.requireDateOrNull(endDt, 'endDt');
    }

    return `${Url.rootPath(1) }func/mp/getTs`
            + `?p=${ _escape(symbol.displayName)
            }&c=${ _escape(symbol.column)
            }&sd=${ Dates.utcDateToIsoString(startDt, 5)
            }${(endDt instanceof Date)
              ? `&ed=${ Dates.utcDateToIsoString(endDt, 5)}`
              : ''}`;
  },

  formatResponse(payload) {
    if (isVoid(payload)) return null;

    if (!(payload.labels instanceof Array)
            || !(payload.data instanceof Array)) throw 'IllegalArgumentException: Unexpected response format, expected JSON object with "labels" and "data" arrays.';

    return payload;
  },
};

/**
 * Calls teh API response parser method - `$formatResponse` - within a try/catch
 * block; catches any errors from it and converts it to ServerError.
 *
 * @param {{$formatResponse: function}} api
 * @param {*} payload - Response payload
 * @param {Arguments} reqArgs - Arguments given to the request.
 * @param {string} url - Request URL.
 * @param {string} methodName - Request method name.
 * @returns {(*|ServerError)}
 * @private
 */
function _safelyParseResponse(api, payload, reqArgs, url, methodName) {
  /*
     * Protect UI so that it doesn't crumble when parsing errors occur.
     * Using try/catch block here converts any unexpected exception into a made up
     * ServerError object (for showing to user) and log full details to the JS console.
     */

  try {
    return api.$formatResponse(payload, reqArgs);
  } catch (ex) {
    Console.warn(
      'Error parsing MP response:\n  API URL: [{}]\n  Error: [{}]\n  Payload: [{}]\n  Stack: [{}]',
      url, ex, JSON.stringify(payload), ex.stack,
    );

    return new ServerError(-415,
      ['Error parsing server response:',
        ex.toString(),
        'See full details in JavaScript console.'],
      url,
      methodName);
  }
}

/* ***************************************************************
 * Abstract class: _Common
 * *************************************************************** */

function _Common() { }
_Common.prototype = {
  constructor: _Common,

  /**
     * Overridable method.
     * @returns {string}
     */
  methodType() {
    return GET;
  },

  /**
     * Overridable method, accepting "application/json" by default.
     * @returns {{Accept: "application/json"}}
     */
  headers() {
    return ACCEPT_APPLICATION_JSON;
  },

  /**
     * Returns the LDS URL without the remote-resource prefix.
     * @param args {Object[]}
     */
  $cleanUrl(args) {
    const url = this.getUrl.apply(this, args);

    return url.substring(_remRscUrl().length);
  },

  /**
     * Allows responses with 400 error to pass back server-error info object.
     * @param {int} statusCode
     * @return {boolean} Whether <code>statusCode</code> corresponds
     *                   to a parsable response.
     */
  isParsableResponse(statusCode) {
    requireInteger(statusCode, 'statusCode');

    return ((statusCode >= 200
            && statusCode < 300)
            || statusCode === 400);
  },

  /**
     * Overridable method for whether a null payload is acceptable.
     * @returns {boolean}
     */
  $isVoidResponseOk() {
    return false;
  },

  /**
     * Basic response formatting ensures that JSON is received, or otherwise
     * <code>null</code>.
     * @param {*} payload
     * @param {Arguments} args
     * @returns {Object} JSON or <code>null</code>.
     */
  formatResponse(payload, args) {
    if (isVoid(payload) && !this.$isVoidResponseOk()) {
      return null;
    } if (ServerError.isMpErrorResponse(payload)) {
      return ServerError.fromMpErrorResponse(payload);
    } if (typeof this.$formatResponse === 'function') {
      return _safelyParseResponse(
        this,
        payload,
        args,
        this.$cleanUrl(args),
        this.methodType(),
      );
    }
    return payload;
  },
};

/* ***************************************************************
 * Abstract class: _Proxy
 * *************************************************************** */
function _Proxy() { }
_Proxy.prototype = Object.assign(new _Common(), {
  constructor: _Proxy,

  $baseUrl(remoteUrl) {
    return _remRscUrl() + requireNonEmptyString(remoteUrl, 'remoteUrl');
  },
});

/**
 * Abstract class for proxying RPC requests.  Sub-classes must
 * implement two methods:
 * <ul>
 *  <li> `$getRequestParams` : given the arguments passed to `getUrl`, this
 *       method returns an object to be included as the `params` property
 *       in the JSON request payload. </li>
 *  <li> `$getRpcMethod` : Name of method to be included as `method` property
 *       in the JSON request payload. </li>
 * </ul>
 *
 * @param {string} rpcUrl - URL to RPC service within LDS system.
 * @private
 */
function _RpcProxy(rpcUrl) {
  this._rpcUrl = requireNonEmptyString(rpcUrl, 'rpcUrl');
  this._reqPayload = null;
  this._reqCtx = null;
}

_RpcProxy.prototype = Object.assign(new _Proxy(), /** @lends {_RpcProxy.prototype} */ {
  constructor: _RpcProxy,

  methodType() { return POST; },
  contentType() { return APPLICATION_JSON; },
  requestBody() { return this._reqPayload; },

  getUrl() {
    const reqId = _newReqId();

    this._reqCtx = reqId;

    this._reqPayload = JSON.stringify({
      id: reqId,
      jsonrpc: '2.0',
      method: this.$getRpcMethod(),
      params: this.$getRequestParams.apply(this, arguments),
    });

    return this.$baseUrl(this._rpcUrl);
  },

  /**
     * Returns the context established during the last
     * call to `getUrl()`.
     * @return {*}
     */
  requestContext() {
    return this._reqCtx;
  },

  formatResponse(payload, reqArgs, reqCtx) {
    requireObject(payload, 'server response');

    if (!payload.hasOwnProperty('id')
            || payload.id !== reqCtx) throw new Error('IllegalStateException: server response ID mismatch with request ID');

    if (payload.hasOwnProperty('error')) {
      return new ServerError(payload.error.code,
        [payload.error.message],
        this._rpcUrl,
        this.$getRpcMethod());
    }

    if (!payload.hasOwnProperty('result')) throw new Error('IllegalStateException: server response does not contain `result`');

    return _safelyParseResponse(
      this,
      payload.result,
      reqArgs,
      this._rpcUrl,
      this.$getRpcMethod(),
    );
  },

  /** Overridable method */
  $formatResponse(result) {
    return result;
  },
});

/* ***************************************************************
 * Abstract class: _ReactorProxy
 * *************************************************************** */
/**
 * Abstract class for proxying requests to workflow worker (aka reactor)
 * via RPC protocol. Sub-classes must implement the `$getRequestParams` method,
 * which takes the arguments given to the `getUrl` method and returns an object
 * to be included as the `params` property in the JSON request payload.
 * @param {string} rpcMethod
 * @private
 */
function _ReactorProxy(rpcMethod) {
  this._rpcMethod = requireNonEmptyString(rpcMethod, 'rpcMethod');
}

_ReactorProxy.prototype = Object.assign(
  new _RpcProxy('/lds/rpc/reactor'),

  /** @lends {_ReactorProxy.prototype} */ {
    constructor: _ReactorProxy,

    $getRpcMethod() {
      return this._rpcMethod;
    },
  },
);

/* ***************************************************************
 * Abstract class: _WorkflowListenerProxy
 * *************************************************************** */
/**
 * Abstract class for proxying requests to workflow event listener (aka WEW)
 * via RPC protocol. Sub-classes must implement the `$getRequestParams` method,
 * which takes the arguments given to the `getUrl` method and returns an object
 * to be included as the `params` property in the JSON request payload.
 * @param {string} rpcMethod
 * @private
 */
function _WorkflowListenerProxy(rpcMethod) {
  this._rpcMethod = requireNonEmptyString(rpcMethod, 'rpcMethod');
}

_WorkflowListenerProxy.prototype = Object.assign(
  new _RpcProxy('/lds/rpc/wew'),

  /** @lends {_WorkflowListenerProxy.prototype} */ {
    constructor: _WorkflowListenerProxy,

    $getRpcMethod() {
      return this._rpcMethod;
    },
  },
);

/* ***************************************************************
 * Abstract class: _SchedulerProxy
 * *************************************************************** */
/**
 * Abstract class for proxying requests to MP schedule worker (aka MP scheduler)
 * via RPC protocol. Sub-classes must implement the `$getRequestParams` method,
 * which takes the arguments given to the `getUrl` method and returns an object
 * to be included as the `params` property in the JSON request payload.
 * @param {string} rpcMethod
 * @private
 */
function _SchedulerProxy(rpcMethod) {
  this._rpcMethod = requireNonEmptyString(rpcMethod, 'rpcMethod');
}

_SchedulerProxy.prototype = Object.assign(
  new _RpcProxy('/lds/rpc/schedule'),

  /** @lends {_SchedulerProxy.prototype} */ {
    constructor: _SchedulerProxy,

    $getRpcMethod() {
      return this._rpcMethod;
    },
  },
);

/* ***************************************************************
 * Abstract class: _FormulaBase
 * *************************************************************** */

function _FormulaBase() {
  this._reqPayload = null;
}

_FormulaBase.prototype = Object.assign(new _Common(), /** @lends {_FormulaBase.prototype} */ {
  constructor: _FormulaBase,

  _setReqPayload(payload) {
    if (typeof payload !== 'string') throw 'IllegalArgumentException: payload must be a String.';

    this._reqPayload = payload;
  },

  _getNewOrUpdateUrl(isNew, name, progLang, srcCode) {
    if (typeof isNew !== 'boolean') throw 'IllegalArgumentException: isNew must be a Boolean.';

    requireNonEmptyString(name, 'name');
    _validProgLang(progLang);
    requireString(srcCode, 'srcCode');

    this._setReqPayload(JSON.stringify({
      name,
      type: progLang,
      formula: srcCode,
    }));

    return _remRscUrlFormulas() + ((!isNew) ? `/${ name}` : '');
  },
});

/* ***************************************************************
 * API GetFormulaList
 * *************************************************************** */

function GetFormulaList() { }
GetFormulaList.prototype = Object.assign(new _FormulaBase(), /** @lends {GetFormulaList.prototype} */ {
  constructor: GetFormulaList,

  getUrl() {
    return `${_remRscUrlFormulas() }/id`;
  },
});

/* ***************************************************************
 * API GetFormula
 * *************************************************************** */

function GetFormula() { }
GetFormula.prototype = Object.assign(new _FormulaBase(), /** @lends {GetFormula.prototype} */ {
  constructor: GetFormula,

  getUrl(name) {
    requireNonEmptyString(name, 'name');
    // Double-escape because url-item is decoded once by CDB server, once by MP server.
    return `${_remRscUrlFormulas() }/${ _escape(_escape(name))}`;
  },

  $formatResponse(payload) {
    if (!(payload instanceof Array)) throw 'IllegalArgumentException: payload is not an Array.';

    if (payload.length === 0) {
      /* The server should have returned 404, but it didn't.
             * This bug lives in the Marketplace API; we just fix
             * it here by injecting what Marketplace should have
             * responded with. */
      return 404;
    }

    if (payload.length > 1) throw 'IllegalArgumentException: payload contains more than 1 formula.';

    return payload[0];
  },
});

/* ***************************************************************
* API GetFormula
* *************************************************************** */

function GetFormulaByUuid() { }
GetFormulaByUuid.prototype = Object.assign(new _FormulaBase(), /** @lends {GetFormulaByUuid.prototype} */ {
  constructor: GetFormulaByUuid,

  getUrl(uuid) {
    requireNonEmptyString(uuid, 'uuid');

    return `${_remRscUrlFormulas() }/id/${ uuid}`;
  },

  $formatResponse(payload) {
    if (!Objects.is(payload)
            || typeof payload.system !== 'boolean') throw new TypeError('payload: Object, with `system` (boolean) property');

    if (typeof payload.formula !== 'string'
            || typeof payload.uuid !== 'string') {
      /* The server should have returned 404, but it didn't.
             * This bug lives in the Marketplace API; we just fix
             * it here by injecting what Marketplace should have
             * responded with. */
      return 404;
    }

    return payload;
  },
});

/* ***************************************************************
 * Abstract class _RunFormula
 * *************************************************************** */

function _RunFormula() { }
_RunFormula.prototype = Object.assign(new _FormulaBase(), /** @lends {_RunFormula.prototype} */ {
  constructor: _RunFormula,

  headers() {
    return ACCEPT_TEXT_CSV;
  },

  $formatResponse(payload) {
    return parseJsEngineResponse(payload);
  },
});

/* ***************************************************************
 * API RunFormula
 * *************************************************************** */
function RunFormula() { }
RunFormula.prototype = Object.assign(new _RunFormula(), {
  constructor: RunFormula,

  getUrl(name) {
    requireNonEmptyString(name, 'name');
    return `${_remRscUrlFormulas() }/${ name }/run`;
  },
});

/* ***************************************************************
 * API RunFormulaAdHoc
 * *************************************************************** */
function RunFormulaAdHoc() {
  this._reqPayload = null;
}

RunFormulaAdHoc.prototype = Object.assign(new _RunFormula(), {
  constructor: RunFormulaAdHoc,

  methodType() { return POST; },
  contentType() { return APPLICATION_JSON; },
  requestBody() { return this._reqPayload; },

  getUrl(progLang, srcCode, props) {
    this._setReqPayload(JSON.stringify({
      type: _validProgLang(progLang),
      formula: requireNonEmptyString(srcCode, 'srcCode'),
      propMap: ((arguments.length > 2) ? Objects.requirePlain(props, 'props') : {}),
    }));
    return `${_remRscUrlFormulas() }/run`;
  },
});


/* ***************************************************************
 * API RunFormulaAdHoc
 * *************************************************************** */
function RunFormulaHistory() {
  this._reqPayload = null;
}

RunFormulaHistory.prototype = Object.assign(new _Proxy(), {
  constructor: RunFormulaHistory,

  methodType() { return POST; },
  contentType() { return APPLICATION_JSON; },
  requestBody() { return this._reqPayload; },

  /**
     * @param {(MpDataWorkflow|WorkflowConfig)} workflow
     * @param {string} formulaUuid
     * @param {Array.<{date: string, parameterSet: ParameterSet}>} runs
     * @param {?string} dataSetName String representing the bubble output data set name
     * @param {string} timeZone
     * @returns {string} URL of POST resource.
     */
  getUrl(workflow, formulaUuid, runs, dataSetName, timeZone) {
    const wf = _validWorkflow(workflow, 'workflow');

    requireNonEmptyString(formulaUuid, 'formulaUuid');
    requireNonEmptyString(timeZone, 'timeZone');
    Arrays.requireValid(runs, e => (!isVoid(e)
                && typeof e.date === 'string'
                && Dates.isIsoDateOnly(e.date)
                && (e.parameterSet instanceof ParameterSet)), 'runs');

    if (runs.length === 0) throw new Error('`runs` is empty');

    this._reqPayload = JSON.stringify({
      formulaUuid,
      runs: _.map(runs, (run) => {
        const retVal = {
          runDate: run.date,
          parameterSetId: run.parameterSet.id(),
        };
        if (typeof dataSetName === 'string') {
          retVal['feed'] = run.parameterSet.get(dataSetName);
        }
        return retVal;
      }),
      timeZone,
    });

    return this.$baseUrl(`/lds/workflows/${ wf.id() }/jobs`);
  },

  $formatResponse(payload) {
    return payload; // No transformation
  },
});

/* ***************************************************************
 * API UpdateFormula
 * *************************************************************** */

function UpdateFormula() { }
UpdateFormula.prototype = Object.assign(new _FormulaBase(), /** @lends {UpdateFormula.prototype} */ {
  constructor: UpdateFormula,

  methodType() { return PUT; },
  contentType() { return APPLICATION_JSON; },
  requestBody() { return this._reqPayload; },

  getUrl(name, progLang, srcCode) {
    return this._getNewOrUpdateUrl(false, name, progLang, srcCode);
  },

  $formatResponse: _formatSavedFormulaPayload,
});

/* ***************************************************************
 * API UpdateFormulaByUUID
 * *************************************************************** */

function UpdateFormulaByUUID() { }
UpdateFormulaByUUID.prototype = Object.assign(new _FormulaBase(), /** @lends {UpdateFormulaByUUID.prototype} */ {
  constructor: UpdateFormulaByUUID,

  methodType() { return PUT; },
  contentType() { return APPLICATION_JSON; },
  requestBody() { return this._reqPayload; },

  getUrl(uuid, name, progLang, srcCode) {
    requireNonEmptyString(uuid, 'uuid');
    requireNonEmptyString(name, 'name');
    _validProgLang(progLang);
    requireString(srcCode, 'srcCode');

    this._setReqPayload(JSON.stringify({
      uuid,
      name,
      type: progLang,
      formula: srcCode,
    }));

    return `${_remRscUrlFormulas() }/id/${ uuid}`;
  },

  $formatResponse: _formatSavedFormulaPayload,
});

/* ***************************************************************
 * API DeleteFormula
 * *************************************************************** */
function DeleteFormula() { }
DeleteFormula.prototype = Object.assign(new _FormulaBase(), /** @lends {DeleteFormula.prototype} */ {
  constructor: DeleteFormula,

  methodType() { return DELETE; },

  getUrl(uuid) {
    requireNonEmptyString(uuid, 'uuid');

    return `${_remRscUrlFormulas() }/id/${ uuid}`;
  },
});

/* ***************************************************************
 * API DeleteFormulaByName
 * *************************************************************** */
function DeleteFormulaByName() { }
DeleteFormulaByName.prototype = Object.assign(new _FormulaBase(), /** @lends {DeleteFormulaByName.prototype} */ {
  constructor: DeleteFormulaByName,

  methodType() { return DELETE; },

  getUrl(name) {
    requireNonEmptyString(name, 'name');

    return `${_remRscUrlFormulas() }/${ name}`;
  },
});

/* ***************************************************************
 * API CreateFormula
 * *************************************************************** */

function CreateFormula() { }
CreateFormula.prototype = Object.assign(new _FormulaBase(), /** @lends {CreateFormula.prototype} */ {
  constructor: CreateFormula,

  methodType() { return POST; },
  contentType() { return APPLICATION_JSON; },
  requestBody() { return this._reqPayload; },

  getUrl(name, progLang, srcCode) {
    return this._getNewOrUpdateUrl(true, name, progLang, srcCode);
  },

  $formatResponse: _formatSavedFormulaPayload,
});


/* ***************************************************************
 * API UploadData
 * *************************************************************** */

function UploadData() { }
UploadData.prototype = Object.assign(new _FormulaBase(), /** @lends {UploadData.prototype} */ {
  constructor: UploadData,

  methodType() { return POST; },
  contentType() { return APPLICATION_JSON; },
  requestBody() { return this._reqPayload; },

  /**
     * Returns the LDS URL without the remote-resource prefix.
     * @param args {Object[]}
     */
  $cleanUrl(args) {
    return this.getUrl.apply(this, args);
  },

  getUrl(filename, cdfFiles) {
    requireNonEmptyString(filename, 'filename');
    Arrays.requireNonEmpty(cdfFiles, 'cdfFiles');
    Arrays.requireValid(cdfFiles, o => (o instanceof CommonDataFormat), 'cdfFiles');

    /* Because of this scheme in use, users cannot use '&' or '='
         * in their filename. */
    filename = filename.replace(new RegExp('[&=,]+', 'g'), '.');

    const zip = [];

    for (const cdfFile of cdfFiles) {
      zip.push({
        id: requireNonEmptyString(cdfFile.name(), 'cdfFile.name()'),
        data: requireNonEmptyString(cdfFile.getPayload(), 'cdfFile.payload()'),
      });
    }

    this._setReqPayload(JSON.stringify(zip));

    return `${Url.rootPath(1) }func/mp/remote-zip-rsc`
            + `?remQueryParams=${ _escape(_toQueryParams({ id: filename }, false))}`;
  },
});


/* ***************************************************************
 * API GetDataSources
 * *************************************************************** */

function GetDataSources() { }
GetDataSources.prototype = Object.assign(new _Proxy(), {
  constructor: GetDataSources,

  getUrl() {
    return this.$baseUrl('/lds/providers/LIM/datasources');
  },

  $formatResponse(payload) {
    if (payload instanceof Array) return _freezeArray(payload);
    throw new TypeError('payload: Array');
  },
});


/* ***************************************************************
 * API GetFeeds
 * *************************************************************** */

function GetFeeds() { }
GetFeeds.prototype = Object.assign(new _Proxy(), {
  constructor: GetFeeds,

  getUrl(dataSourceName) {
    requireNonEmptyString(dataSourceName, 'dataSourceName');
    return this.$baseUrl(`/lds/providers/LIM/datasources/${ dataSourceName}`);
  },

  $formatResponse(payload) {
    if (payload instanceof Object
            && payload.hasOwnProperty('feeds')
            && payload.hasOwnProperty('name')) {
      _freezeArray(payload.feeds);
      return Object.freeze(payload);
    } throw new TypeError('payload: Object with `feeds` and `name` properties');
  },
});

/* ***************************************************************
 * API GetFeedRoots
 * *************************************************************** */

/**
 * Returns a list of roots for the given feed, sorted by name.
 * @constructor
 */
function GetFeedRoots() { }
GetFeedRoots.prototype = Object.assign(new _Proxy(), {
  constructor: GetFeedRoots,

  getUrl(feedName) {
    requireNonEmptyString(feedName, 'feedName');
    const qryParams = _toQueryParams({
      includeDesc: true,
    });
    return this.$baseUrl(`/lds/feeds/${ feedName }/contractroots`
            + `&remQueryParams=${ _escape(qryParams)}`);
  },

  /**
     *
     * @param {{feedName: string, rootDesc: Array.<{root: string, rootDescription: string}> }} payload
     * @returns {*}
     */
  $formatResponse(payload) {
    if (!(payload instanceof Object)
            || !payload.hasOwnProperty('feedName')) throw new TypeError('payload: Object, with `feedName` property');

    let roots;
    if (payload.rootDesc instanceof Array) {
      roots = payload.rootDesc.map(rawRoot => (
        new MpRoot(rawRoot.root, rawRoot.rootDescription)
      ));

      roots.sort(MpRoot.compare);
    } else roots = [];

    return Object.freeze(roots);
  },
});

/* ***************************************************************
 * API GetFeedFields
 * *************************************************************** */

function GetFeedFields() { }
GetFeedFields.prototype = Object.assign(new _Proxy(), {
  constructor: GetFeedFields,

  getUrl(feedName, fieldType) {
    requireNonEmptyString(feedName, 'feedName');
    if (!isVoid(fieldType)) {
      MpFieldType.requireEnumOf(fieldType, 'fieldType');
    }

    // Double-escape `feedName` because its being decoded twice: once by CDB server, another by MP's web service.
    return this.$baseUrl(`/lds/feeds/${ _escape(_escape(feedName))}`);
  },

  $formatResponse(payload, args) {
    const fieldTypeFilter = ((args.length > 1
            && MpFieldType.isEnumOf(args[1]))
      ? args[1]
      : null);
    const feedName = args[0];

    if (!(payload instanceof Object)
            || payload.name !== feedName) throw new TypeError('payload: Object, with `name` property matching the requested feed');

    const { fields } = payload;
    let a = Arrays.EMPTY;

    if (fields instanceof Array) {
      a = [];
      Arrays.addAll(a, MpField.fromServerArray(fields));

      if (fieldTypeFilter !== null) a = a.filter(mpField => (mpField.type() === fieldTypeFilter));

      Object.freeze(a);
    }

    return a;
  },
});


/* ***************************************************************
 * API GetFeedKeyVals
 * *************************************************************** */

function GetFeedKeyVals() { }
GetFeedKeyVals.prototype = Object.assign(new _Proxy(), {
  constructor: GetFeedKeyVals,

  getUrl(feedName, keys, options) {
    requireNonEmptyString(feedName, 'feedName');
    // Should pass `true` but MP does not support encoding here (not yet anyway)
    let qryParams = _toQueryParams(keys, false);
    if (arguments.length > 2) {
      qryParams += `&${ _toQueryParams(options, false)}`;
    }
    return this.$baseUrl(`/lds/feeds/${ feedName }/keys&remQueryParams=${ _escape(qryParams)}`);
  },

  $formatResponse(payload) {
    if (!(payload instanceof Array)
            || !payload[0].hasOwnProperty('keys')) throw new TypeError('payload: Object with `keys` property');

    const keyset = [];

    for (let i = 0; i < payload.length; i++) {
      const row = payload[i].keys;
      const set_ = {};

      if (row instanceof Array) {
        for (let j = 0; j < row.length; j++) set_[row[j].key] = row[j].value;

        keyset.push(set_);
      }
    }

    return Object.freeze(keyset);
  },
});

/* ***************************************************************
 * API GetTSRange
 * *************************************************************** */

function GetTSRange() {
}
GetTSRange.prototype = Object.assign(new _Proxy(), {
  constructor: GetTSRange,

  getUrl(feedName, keys) {
    requireNonEmptyString(feedName, 'feedName');
    const qryParams = _toQueryParams(keys, true);
    return this.$baseUrl(`/lds/feeds/${ feedName }/ts/range&remQueryParams=${ _escape(qryParams)}`);
  },

  /**
     * @param {Array.<{start: string, end: string}>} payload
     * @returns {DateRange} May be UNKNOWN, never null.
     */
  $formatResponse(payload) {
    Arrays.requireArray(payload, 'payload');
    if (payload.length === 0) {
      return DateRange.UNKNOWN;
    }
    const item0 = payload[0];
    if (!Dates.isIsoDate(item0.start)
                || !Dates.isIsoDate(item0.end)) {
      throw new TypeError('payload: Array.<{start: string (ISO), end: string (ISO)}>');
    }
    return DateRange.fromDates(
      Dates.isoStringToDate(item0.start),
      Dates.isoStringToDate(item0.end),
    );
  },
});

/* ***************************************************************
 * API GetTSDataRR
 * *************************************************************** */

function GetTSDataRR() {
  this._reqPayload = null;
}

GetTSDataRR.prototype = Object.assign(new _Proxy(), {
  constructor: GetTSDataRR,

  methodType() { return POST; },
  contentType() { return 'application/x-www-form-urlencoded'; },
  requestBody() { return this._reqPayload; },

  getUrl(feedName, keys, options) {
    requireNonEmptyString(feedName, 'feedName');
    this._reqPayload = _toQueryParams(keys, true);
    const qryParams = (
      (arguments.length > 2)
        ? _toQueryParams(options, false)
        : ''
    );
    return `${this.$baseUrl(`/lds/feeds/${ feedName }/ts`)
    }&remQueryParams=${ _escape(qryParams)}`;
  },

  headers() {
    return ACCEPT_TEXT_CSV;
  },

  $formatResponse(payload) {
    if (typeof payload !== 'string') throw new TypeError('payload: String');

    return payload;
  },
});

/* ***************************************************************
 * API GetEntitledFeeds
 * *************************************************************** */

function GetEntitledFeeds() { }
GetEntitledFeeds.prototype = Object.assign(new _Proxy(), {
  constructor: GetEntitledFeeds,

  getUrl() {
    // Double-escape because url-item is decoded once by CDB server, once by MP server.
    return this.$baseUrl(`/lds/users/${ _escape(_escape(_username())) }/feeds`);
  },

  $formatResponse(payload) {
    if (!(payload instanceof Array)) throw new TypeError('payload: Array-of-Object');

    return _freezeArray(payload);
  },
});


/* ***************************************************************
 * MP Data Workflows - private methods
 * *************************************************************** */
/**
 * @param {Array.<{type: string, msg: string, tasks: Object[]}>}serverArr
 * @returns {UserAction[]}
 * @private
 */
function _newWfUserActions(serverArr) {
  const toUserActionTask = rawTask => new UserActionTask(
    rawTask.topic,
    rawTask.props,
  );
  return serverArr.map(raw => new UserAction(
    raw.type,
    raw.msg,
    raw.tasks.map(toUserActionTask),
  ));
}

/**
 *
 * @param {Array.<{topic: string, props: Object.<string, string>}>} serverArr
 * @returns {Task[]}
 * @private
 */
function _newWfTasks(serverArr) {
  return serverArr.map(rawTask => new Task(rawTask.topic, rawTask.props));
}

/**
 *
 * @param {Object} serverObj
 * @returns {(Dependency|KeyArrivalDependency|TopicDependency)}
 * @private
 */
function _newWfDependency(serverObj) {
  requireObject(serverObj, 'dependency');

  const scope = _ifAvail(serverObj, 'scope', null);

  if (serverObj.type === 'kaw_sub') {
    return new KeyArrivalDependency(
      serverObj.feed,
      _ifAvail(serverObj, 'keys', null),
      _ifAvail(serverObj, 'roots', null),
      serverObj.columns,
      (!isVoid(serverObj.timeout)
        ? new Timeout(serverObj.timeout.type, serverObj.timeout.params)
        : null
      ),
      scope,
    );
  }
  return new TopicDependency(
    serverObj.topic,
    serverObj.props,
    scope,
  );
}

function _newWfDependencies(serverArr) {
  return serverArr.map(_newWfDependency);
}

/**
 * Creates a target based on the server payload.
 * @param serverObj {Object}
 * @return {Target}
 * @private
 */
function _newWfTarget(serverObj) {
  const builder = new Target.Builder(serverObj.name);

  if (serverObj.hasOwnProperty('timeout')) {
    const { timeout } = serverObj;
    const tasks = _newWfTasks(timeout.tasks);

    builder.timeout(new TaskedTimeout(
      timeout.type,
      timeout.params,
      tasks,
    ));
  }

  if (serverObj.hasOwnProperty('tasks')) {
    builder.tasks(_newWfTasks(serverObj.tasks));
  }
  if (serverObj.hasOwnProperty('user_actions')) {
    builder.userActions(_newWfUserActions(serverObj.user_actions));
  }
  if (serverObj.hasOwnProperty('dependencies')) {
    builder.dependencies(_newWfDependencies(serverObj.dependencies));
  }
  if (serverObj.hasOwnProperty('required')) {
    builder.isRequired(serverObj.required);
  }

  return builder.build();
}

/**
 * Creates a Workflow object based on server JSON.
 * @param {{ id: int, name: string, owner: string, [company]: string,
 *           creationDate: int, changeDate: int, timeZone: string, description: string }} serverObj
 * @returns {MpDataWorkflow}
 * @private
 */
function _newWorkflow(serverObj) {
  return new MpDataWorkflow(
    serverObj.id,
    serverObj.name,
    serverObj.owner,
    serverObj.creationDate,
    serverObj.changeDate,
    serverObj.timeZone,
    _ifAvail(serverObj, 'company', ''),
    _ifAvail(serverObj, 'description', ''),
  );
}

/**
 * Creates a workflow Config object based on server JSON.
 * @param {{ id: int, name: string, owner: string, permissions: string[],
 *           creationDate: int, changeDate: int, timeZone: string,
 *           targets: Object[], ui: Object, description: string }} serverObj
 * @return {WorkflowConfig}
 */
function _newWfConfig(serverObj) {
  const rawTargets = serverObj.targets;
  const targets = [];

  let i = 0;
  const len = rawTargets.length;
  for (; i < len; i++) { targets.push(_newWfTarget(rawTargets[i])); }

  return new WorkflowConfig(
    _newWorkflow(serverObj),
    serverObj.permissions,
    targets,
    serverObj.ui,
    serverObj,
    serverObj.psgId,
  );
}

/**
 * Converts a list of workflows from the server into an array of workflow objects.
 *
 * @param {Array.<Object>} serverPayload - Server response payload.
 * @param {function} factory - Factory method to convert each item of the array
 *        into a workflow object.
 * @returns {(MpDataWorkflow[]|WorkflowConfig[])} A list containing
 *          constructed objects (object types depends on `factory`.
 * @private
 */
function _serverPayloadToWorkflows(serverPayload, factory) {
  if (!(serverPayload instanceof Array)) { throw new TypeError('serverPayload: Array-of-Workflow'); }

  const list = [];
  let i = 0;
  const len = serverPayload.length;
  for (; i < len; i++) {
    const item = serverPayload[i];
    try {
      list.push(factory(item));
    } catch (e) {
      Console.error('Error while parsing workflow id [{}]:\n Exception: [{}]\n Stack trace: [{}]',
        item.id, e, e.stack);
    }
  }
  return list;
}

/* ***************************************************************
 * API GetEntitledWorkflows
 * *************************************************************** */

function GetEntitledWorkflows() { }
GetEntitledWorkflows.prototype = Object.assign(new _Proxy(), {
  constructor: GetEntitledWorkflows,

  getUrl() {
    return this.$baseUrl('/lds/workflows');
  },

  $formatResponse(payload) {
    return _serverPayloadToWorkflows(payload, _newWfConfig);
  },
});

/**
 * @namespace
 * @alias mp.Workflow
 */

/**
 * @typedef Object mp.Workflow.ReportHeader
 * @property {int} id
 * @property {string} name
 * @property {string} desc
 * @property {string} owner
 */

/**
 * @typedef Object mp.Workflow.ReportEvent
 * @property {int} id - Workflow ID.
 * @property {string[]} events - Event names
 * @property {string[]} psets - Parameter-set UUIDs.
 */

/**
 * @typedef Object mp.Workflow.Report
 * @property {int} id
 * @property {string} name
 * @property {string} desc
 * @property {string} owner
 * @property {string[]} recipients
 * @property {mp.Workflow.ReportEvent[]} workflows
 * @property {?string} [cron]
 * @property {string} timezone
 */

/**
 * Converts plain POJO from server into ReportHeader class instance.
 * @param {mp.Workflow.ReportHeader} o
 * @return {ReportHeader}
 * @throws TypeError - If POJO properties don't match expectations.
 * @private
 */
function _toWorkflowReportHeader(o) {
  return new ReportHeader(
    o.id,
    o.name,
    o.desc,
  );
}

/**
 * Converts plain POJO from server into Report class instance.
 * @param {mp.Workflow.Report} o
 * @returns {Report}
 * @private
 */
function _toWorkflowReport(o) {
  return new Report(
    o.id,
    o.name,
    o.desc,
    // o.owner,
    o.recipients,
    o.workflows.map(_toReportEvent),
    o.cron,
    o.timezone,
  );
}

/**
 * Returns the RPC parameters for creating or updating a workflow.
 * @param {Report} rpt - Report that contains the information to be saved.
 * @param {boolean} withId - Whether the URL should contain the report ID; this is
 *                  the only difference between a *create* and an *update*.
 * @returns {{path: string, accept: string, content-type: string, content: Object}}
 * @private
 */
function _wfRptUpdateParams(rpt, withId) {
  if (!_isWfReport(rpt)) { throw new TypeError('rpt: Report'); }

  const pojo = rpt.toJson();
  delete pojo.id;
  delete pojo.owner;

  return {
    path: `/reportcfgs${ (withId) ? `/${ _toStr(rpt.id())}` : ''}`,
    accept: APPLICATION_JSON,
    'content-type': APPLICATION_JSON,
    content: pojo,
  };
}

/* ***************************************************************
 * API CreateWorkflowReport
 * *************************************************************** */
function CreateWorkflowReport() { }

CreateWorkflowReport.prototype = Object.assign(new _WorkflowListenerProxy(POST), {
  constructor: CreateWorkflowReport,

  /** @param {Report} rpt */
  $getRequestParams(rpt) {
    return _wfRptUpdateParams(rpt, false);
  },

  /** @param {{status: int, content: mp.Workflow.Report}} result */
  $formatResponse(result) {
    const content = _validRpcResults(result, HttpCode.CREATED);
    return _toWorkflowReport(requireObject(content, 'result.content'));
  },
});

/* ***************************************************************
 * API UpdateWorkflowReport
 * *************************************************************** */
function UpdateWorkflowReport() { }

UpdateWorkflowReport.prototype = Object.assign(new _WorkflowListenerProxy(PUT), {
  constructor: UpdateWorkflowReport,

  /** @param {Report} rpt */
  $getRequestParams(rpt) {
    return _wfRptUpdateParams(rpt, true);
  },

  /** @param {{status: int, content: mp.Workflow.Report}} result */
  $formatResponse(result) {
    const content = _validRpcResults(result, HttpCode.OK);
    return _toWorkflowReport(requireObject(content, 'result.content'));
  },
});

/* ***************************************************************
 * API GetWorkflowReports
 * *************************************************************** */
function GetWorkflowReports() { }
GetWorkflowReports.prototype = Object.assign(new _WorkflowListenerProxy(GET), {
  constructor: GetWorkflowReports,

  $getRequestParams() {
    return {
      path: '/reportcfgs',
      accept: APPLICATION_JSON,
    };
  },

  /** @param {{status: int, content: mp.Workflow.ReportHeader[]}} result */
  $formatResponse(result) {
    const content = _validRpcResults(result, HttpCode.OK);

    if (!Arrays.isArrayLike(content)) throw new TypeError('result: Object[]');

    return content.map(_toWorkflowReportHeader);
  },
});

/**
 * @typedef Object mp.Workflow.ReportEvent
 * @property {int} id - Workflow ID.
 * @property {string[]} events - Name of events that trigger notification.
 * @property {string[]} psets - Parameter-set UUIDs.
 */

/**
 * Converts POJO from server into ReportEvent class instance.
 * @param {mp.Workflow.ReportEvent} o
 * @returns {ReportEvent}
 * @throws TypeError - If POJO properties don't match expectations.
 * @private
 */
function _toReportEvent(o) {
  return new ReportEvent(
    o.id,
    o.psets,
    o.events,
  );
}

/* ***************************************************************
 * API GetWorkflowReport
 * *************************************************************** */
function GetWorkflowReport() { }
GetWorkflowReport.prototype = Object.assign(new _WorkflowListenerProxy(GET), {
  constructor: GetWorkflowReport,

  /** @param {(Report|ReportHeader)} rpt */
  $getRequestParams(rpt) {
    _validWfReportOrHeader(rpt, 'rpt');

    return {
      path: `/reportcfgs/${ rpt.id()}`,
      accept: APPLICATION_JSON,
    };
  },

  /** @param {{status: int, content: mp.Workflow.Report}} result */
  $formatResponse(result) {
    const content = _validRpcResults(result, HttpCode.OK);
    return _toWorkflowReport(requireObject(content, 'payload'));
  },
});

/* ***************************************************************
 * API DeleteWorkflowReport
 * *************************************************************** */
function DeleteWorkflowReport() { }
DeleteWorkflowReport.prototype = Object.assign(new _WorkflowListenerProxy(DELETE), {
  constructor: DeleteWorkflowReport,

  /** @param {(Report|ReportHeader)} rpt */
  $getRequestParams(rpt) {
    _validWfReportOrHeader(rpt, 'rpt');

    return {
      path: `/reportcfgs/${ rpt.id()}`,
    };
  },

  /** @param {{status: int}} result */
  $formatResponse(result) {
    _validRpcResults(result, [
      HttpCode.OK, // 200 --> as of time of implementation
      HttpCode.NO_CONTENT, // 204 --> what I'd expect in response to DELETE.
    ]);

    return 'OK';
  },
});


/* ***************************************************************
 * API SearchWorkflows
 * *************************************************************** */
function SearchWorkflows() { }
SearchWorkflows.prototype = Object.assign(new _Proxy(), {
  constructor: SearchWorkflows,

  getUrl(nameRegex, feedRegex, symbolRegex, ownerRegex, companyRegex, workflowId, formula) {
    requireString(nameRegex, 'nameRegex');
    requireString(feedRegex, 'feedRegex');
    requireString(symbolRegex, 'symbolRegex');
    requireString(ownerRegex, 'ownerRegex');
    requireString(companyRegex, 'companyRegex');
    requireString(workflowId, 'workflowId');
    requireString(formula, 'formula');

    const qryParams = _toQueryParams({
      name_regex: nameRegex,
      feed_regex: feedRegex,
      symbol_regex: symbolRegex,
      owner_regex: ownerRegex,
      company_regex: companyRegex,
      workflowId,
      formula_str: formula,

    });

    return `${this.$baseUrl('/lds/workflows/search')
    }&remQueryParams=${ _escape(qryParams)}`;
  },

  $formatResponse(payload) {
    return _serverPayloadToWorkflows(payload, _newWorkflow);
  },
});

/* ***************************************************************
 * API WorkflowSupportAccessTest
 * *************************************************************** */
function WorkflowSupportAccessTest() { }
WorkflowSupportAccessTest.prototype = Object.assign(new _Proxy(), {
  constructor: WorkflowSupportAccessTest,

  getUrl() {
    return this.$baseUrl('/lds/workflows/support/test');
  },

  headers() {
    return ACCEPT_TEXT_PLAIN;
  },
});

/* ***************************************************************
 * API GetWorkflowsByIds
 * *************************************************************** */

function GetWorkflowsByIds() {
  this._reqPayload = null;
}
GetWorkflowsByIds.prototype = Object.assign(new _Proxy(), {
  constructor: GetWorkflowsByIds,

  methodType() { return POST; },
  contentType() { return 'application/x-www-form-urlencoded'; },
  requestBody() { return this._reqPayload; },

  getUrl(ids) {
    if (!Arrays.isValid(ids, isNonNegativeInteger)
            || ids.length === 0) throw new TypeError('ids: Non-empty array of non-negative integer(s)');

    this._reqPayload = `ids=${ _escape(ids.join(','))}`;

    return this.$baseUrl('/lds/workflows/support/get-by-ids');
  },

  $formatResponse(payload) {
    requireObject(payload, 'payload');
    const newMap = {};
    _.each(payload, (wf, id) => {
      newMap[_toInt(id)] = _newWfConfig(wf);
    });
    return newMap;
  },
});

/* ***************************************************************
 * API GetWorkflow
 * *************************************************************** */

/**
 * Retrieves one workflow's config.
 * @constructor
 * @name MpApi.GetWorkflowConfig
 */
function GetWorkflowConfig() { }
GetWorkflowConfig.prototype = Object.assign(new _Proxy(), /** @lends MpApi.GetWorkflowConfig.prototype */ {
  constructor: GetWorkflowConfig,

  /**
     * Returns the URL to retrieve a workflow config from MP server.
     * @param {int} id - Non-negative
     * @returns {string}
     */
  getUrl(id) {
    requireNonNegativeInteger(id, 'id');

    return this.$baseUrl(`/lds/workflows/${ _toStr(id)}`);
  },

  /**
     * @param {Object} payload - Response from the server.
     */
  $formatResponse(payload) {
    return _newWfConfig(payload);
  },
});

/* ***************************************************************
 * API SaveWorkflowConfig
 * *************************************************************** */
function SaveWorkflowConfig() {
  this._meth = POST;
  this._reqPayload = null;
}
SaveWorkflowConfig.prototype = Object.assign(new _Proxy(), {
  constructor: SaveWorkflowConfig,

  methodType() { return this._meth; },
  contentType() { return APPLICATION_JSON; },
  requestBody() { return this._reqPayload; },

  /** @param {WorkflowConfigBuilder} configBuilder */
  getUrl(configBuilder) {
    requireWorkflowConfigBuilder(configBuilder, 'configBuilder');
    const url = this.$baseUrl('/lds/workflows');
    this._reqPayload = JSON.stringify(configBuilder.toJson());

    if (configBuilder.isNewConfig()) {
      this._meth = POST;
      return url;
    }
    this._meth = PUT;
    return `${url }/${ _toStr(configBuilder.config().id())}`;
  },

  $formatResponse(workflowConfig) {
    return _newWfConfig(requireObject(workflowConfig, 'workflowConfig'));
  },
});

/**
 * API to call DELETE API on a workflow config.
 * @constructor
 * @name MpApi.DeleteWorkflowConfig
 */
function DeleteWorkflowConfig() { }
DeleteWorkflowConfig.prototype = Object.assign(new _Proxy(), /** @lends MpApi.DeleteWorkflowConfig.prototype */ {
  constructor: DeleteWorkflowConfig,

  /**
     * Indicates remote API is HTTP method DELETE.
     * @returns {string} "DELETE"
     */
  methodType() { return DELETE; },

  /**
     * Returns the MP URL for the DELETE API.
     * @param {(MpDataWorkflow|WorkflowConfig)} workflow
     * @returns {string}
     */
  getUrl(workflow) {
    const wf = _validWorkflow(workflow, 'workflow');

    if (!wf.isPersisted()) throw new Error('IllegalArgumentException: config was never persisted, cannot be deleted');

    return this.$baseUrl(`/lds/workflows/${ _toStr(wf.id())}`);
  },


  $formatResponse(payload, reqArgs) {
    if (typeof payload !== 'string'
            || Strings.isNonEmpty(payload)) {
      Console.warn(
        'Call to DeleteWorkflowConfig received an unexpected response: {}',
        JSON.stringify(payload),
      );

      return new ServerError(
        -415,
        ['Unexpected response format.'],
        // this.getUrl(reqArgs[0]),
        DELETE,
      );
    }
    // Workflow config deleted successfully. we don't have
    // anything meaningful to return, so send whatever caller
    // gave us in the first place.
    return reqArgs[0];
  },

});


/* ***************************************************************
 * API ExecuteUserAction
 * *************************************************************** */
function ExecuteUserAction() { }
ExecuteUserAction.prototype = Object.assign(new _ReactorProxy('execute_user_action'), {
  constructor: ExecuteUserAction,

  $getRequestParams(actionId) {
    requireNonEmptyString(actionId, 'actionId');

    return actionId;
  },
});

/* ***************************************************************
 * API StartWorkflowTarget
 * *************************************************************** */

/**
 * This API launches a specific target within a workflow instance.
 *
 * @constructor
 * @name MpApi.StartWorkflowTarget
 * @see https://docs.google.com/document/d/1pLcqOXt7FmKfyQghOzwwoVq6DtLntWw6eqkj1VQaVd0/edit#heading=h.miffbk54o1t2
 */
function StartWorkflowTarget() { }
StartWorkflowTarget.prototype = Object.assign(new _ReactorProxy('run_target'), /** @lends MpApi.StartWorkflowTarget.prototype */ {
  constructor: StartWorkflowTarget,

  /**
     * Converts the arguments given to the API call (aka API handler) into
     * a request payload recognized by the server.
     *
     * @param {Target} target
     * @param {string} runId
     * @param {boolean} [markDone=true]
     * @returns {{target_name: string, run_id: string}}
     */
  $getRequestParams(target, runId, markDone) {
    if (!(target instanceof Target)) throw new TypeError('target: Target');

    requireNonEmptyString(runId, 'runId');

    const markComplete = _validBoolIfAvail(arguments, 2, 'markDone', true);

    return {
      target_name: target.name(),
      run_id: runId,
      mark_done: markComplete,
    };
  },
});


/* ***************************************************************
 * API StartWorkflow
 * *************************************************************** */
function StartWorkflow() { }
StartWorkflow.prototype = Object.assign(new _ReactorProxy('start_workflow_runs'), {
  constructor: StartWorkflow,

  $getRequestParams(workflowId, id, isGroup) {
    requireInteger(workflowId, 'workflowId');
    requireInteger(id, 'id');
    requireBoolean(isGroup, 'isGroup');

    const params = {};
    params.workflow_id = workflowId;

    if (isGroup) {
      params.param_set_group_id = id;
    } else {
      params.param_set_id = id;
    }

    return params;
  },
});

/* ***************************************************************
 * API StopWorkflow
 * *************************************************************** */
function StopWorkflow() { }
StopWorkflow.prototype = Object.assign(new _ReactorProxy('stop_workflow_runs'), {
  constructor: StopWorkflow,

  $getRequestParams(runIds) {
    if (!Arrays.isValid(runIds, Strings.isNonEmpty)) {
      throw new TypeError('runIds: String[]');
    }

    return Arrays.slice(runIds);
  },
});


/* ***************************************************************
 * API GetWorkflowStatus
 * *************************************************************** */

/**
 * @typedef {Object} StatusRequestRecord
 * @property {WorkflowConfig} config
 * @property {int[]} psuuids
 */

/**
 * Argument to provide to API handler when making requests to
 * MpApi.GetWorkflowStatus API.
 * @constructor
 * @name MpApi.GetWorkflowStatus.Request
 */
function GetWorkflowStatusRequest() {
  /** @type {Object.<int, StatusRequestRecord>} */
  this._md = {};
}

GetWorkflowStatusRequest.prototype = /** @lends MpApi.GetWorkflowStatus.Request.prototype */ {
  constructor: GetWorkflowStatusRequest,

  /**
     * Adds a workflow config to the current request,
     * along with parameter-set IDs.
     * @param {WorkflowConfig} config
     * @param {ParameterSetGroup} psg
     */
  add(config, psg) {
    if (!(config instanceof WorkflowConfig)) throw new TypeError('config: WorkflowConfig');

    if (!(psg instanceof ParameterSetGroup)) throw new TypeError('psg: ParameterSetGroup');

    const md = this._md;
    const cfgId = config.id();

    if (!md.hasOwnProperty(cfgId)) {
      md[cfgId] = {
        config,
        psuuids: [],
      };
    }

    Arrays.addAllMissing(
      md[cfgId].psuuids,
      psg.parameterSets().map(paramSet => paramSet.uuid()),
    );
  },
};

Object.freeze(GetWorkflowStatusRequest);
Object.freeze(GetWorkflowStatusRequest.prototype);

function GetWorkflowStatus() { }
GetWorkflowStatus.prototype = Object.assign(new _ReactorProxy('get_workflow_status_3'), {
  constructor: GetWorkflowStatus,

  $getRequestParams(request) {
    if (!(request instanceof GetWorkflowStatusRequest)) throw new TypeError('request: MpApi.GetWorkflowStatus.Request');

    const md = request._md;
    const configIds = _.keys(md);

    return _.reduce(configIds, (memo, configId) => {
      memo[configId] = md[configId].psuuids;
      return memo;
    }, {});
  },

  /**
     * Returns a list of ConfigStatus objects, one for each requested Config.
     * @param {Object.<int, Object>} payload
     * @param {{0: MpApi.GetWorkflowStatus.Request}} reqArgs
     * @returns {WorkflowStatus[]}
     */
  $formatResponse(payload, reqArgs) {
    const ConfigStatus = WorkflowStatus;

    /** @type {MpApi.GetWorkflowStatus.Request} */
    const statReq = reqArgs[0]; // the only argument
    const md = statReq._md;
    const configIds = _.keys(md);

    return configIds.map((configId) => {
      const statReqRec = md[configId];
      const { config } = statReqRec;

      if (payload.hasOwnProperty(configId)) return new ConfigStatus(config, payload[configId]);
      return ConfigStatus.missing(config);
    });
  },
});

GetWorkflowStatus.Request = GetWorkflowStatusRequest;


/* ***************************************************************
 * API GetJobSchedule
 * *************************************************************** */

function GetJobSchedule() { }
GetJobSchedule.prototype = Object.assign(new _SchedulerProxy(GET), {
  constructor: GetJobSchedule,

  $getRequestParams(jobId) {
    if (typeof jobId === 'string' || jobId instanceof String) {
      return {
        path: `/genericscheduler/${ jobId}`,
        accept: APPLICATION_JSON,
      };
    }
    throw new TypeError('jobId: Not a string');
  },

  $formatResponse(payload) {
    return _processScheduledJobResponse(payload, HttpCode.OK);
  },
});

/* ***************************************************************
 * API CreateJobSchedule
 * *************************************************************** */

function CreateJobSchedule() { }
CreateJobSchedule.prototype = Object.assign(new _SchedulerProxy(POST), {
  constructor: CreateJobSchedule,

  $getRequestParams(config, cronExpression, parameterSetGroup, timezone) {
    const Workflow = MpDataWorkflow;

    if (!(config instanceof Workflow.Config)) throw new TypeError('config: WorkflowConfig');

    requireNonEmptyString(cronExpression, 'cronExpression');

    if (!(parameterSetGroup instanceof Workflow.ParameterSetGroup)) throw new TypeError('parameterSetGroup: ParameterSetGroup');

    TimeZone.requireValidID(timezone, 'timezone');

    return {
      path: '/genericscheduler',
      accept: APPLICATION_JSON,
      params: {
        detail: 'true', // Tells the server to return the json for the Job. Not just the uuid.
        unique: 'workflow.id', // Ensures only one schedule exists for this workflow.
      },
      'content-type': APPLICATION_JSON,
      content: {
        topic: 'workflow.start',
        cronExpression,
        timeZoneId: timezone,
        jobPrefix: 'workflow',
        properties: {
          'workflow.id': config.id(),
          'parameter-set-group.id': parameterSetGroup.id(),
        },
      },
    };
  },

  $formatResponse(payload) {
    return _processScheduledJobResponse(payload, HttpCode.OK);
  },
});

/* ***************************************************************
 * API DeleteJobSchedule
 * *************************************************************** */

/**
 * Returns the MP URL path to the *delete scheduled job* API.
 * @param {(ScheduledJob|string)} job
 * @returns {string}
 * @private
 */
function _getDeleteJobUrlPath(job) {
  if (job instanceof ScheduledJob) return `/genericscheduler/${ job.jobId()}`;

  if (typeof job === 'string') return `/genericscheduler/${ job}`;

  throw new TypeError('job: ScheduledJob or string');
}

function DeleteJobSchedule() { }
DeleteJobSchedule.prototype = Object.assign(new _SchedulerProxy(DELETE), {
  constructor: DeleteJobSchedule,


  /**
     * Returns the object to use as `params` property in RPC call.
     * @param {(ScheduledJob|string)} job
     * @returns {{path: string}}
     */
  $getRequestParams(job) {
    return {
      path: _getDeleteJobUrlPath(job),
    };
  },

  $formatResponse(payload, reqArgs) {
    _validRpcResults(payload, HttpCode.NO_CONTENT);

    // Job deleted successfully. we don't have
    // anything meaningful to return, so send whatever caller
    // gave us in the first place.
    return reqArgs[0];
  },
});

/* ***************************************************************
 * API GetWorkflowPermissions
 * *************************************************************** */

function GetWorkflowPermissions() {
}
GetWorkflowPermissions.prototype = Object.assign(new _Proxy(), {
  constructor: GetWorkflowPermissions,

  getUrl(workflow) {
    const wf = _validWorkflow(workflow, 'workflow');

    return this.$baseUrl(`/lds/workflows/${ wf.id() }/permissions`);
  },

  $formatResponse(payload) {
    if (!(payload instanceof Array)) throw new TypeError('payload: Array-of-WorkflowPermissions');

    const users = [];
    let coPerms = PermissionSet.NONE;

    let i = 0;
    const len = payload.length;
    for (; i < len; i++) {
      const up = payload[i];

      if (!Objects.is(up)
                || !up.hasOwnProperty('userName')
                || !up.hasOwnProperty('perms')) throw new TypeError(`payload[${ i }]: Object, with \`userName\` and \`perms\` properties`);

      const username = up.userName;
      const { perms } = up;
      let permset;

      if (perms.length === 0) permset = PermissionSet.NONE;
      else {
        permset = new PermissionSet(perms.map(perm =>
        // We don't have to fail if `perm` is not recognized,
        // but I chose to fail, because the UI wouldn't be able to
        // preserve that unrecognized value and hence would have
        // negative impact downstream.

          Permission.valueOf(perm)));
      }

      if (username === COMPANY_USER_NAME) coPerms = permset;
      else users.push(new UserPermissions(username, permset));
    }

    return new Permissions(coPerms, users);
  },
});
/* ***************************************************************
 * API SetWorkflowOwner
 * *************************************************************** */

function SetWorkflowOwner() {
  this._reqPayload = null;
}
SetWorkflowOwner.prototype = Object.assign(new _Proxy(), {
  constructor: SetWorkflowOwner,

  methodType() { return PUT; },
  contentType() { return APPLICATION_JSON; },

  getUrl(workflow, ownerName) {
    const wf = _validWorkflow(workflow, 'workflow');
    return this.$baseUrl(`/lds/workflows/${ wf.id() }/owner&remQueryParams=${ _escape(_toQueryParams({ ownerName }, false))}`);
  },
});
/* ***************************************************************
 * API SetWorkflowPermissions
 * *************************************************************** */

function SetWorkflowPermissions() {
  this._reqPayload = null;
}

SetWorkflowPermissions.prototype = Object.assign(new _Proxy(), {
  constructor: SetWorkflowPermissions,

  methodType() { return POST; },
  contentType() { return APPLICATION_JSON; },
  requestBody() { return this._reqPayload; },

  getUrl(workflow, permissions) {
    const wf = _validWorkflow(workflow, 'workflow');

    if (!(permissions instanceof Permissions)) throw new TypeError('permissions: Permissions');

    const list = permissions.users().map(up => ({
      userName: up.username(),
      perms: up.permissionSet().listValues(),
    }));

    // Insert "company-level" permissions at beginning of array.
    list.unshift({
      userName: COMPANY_USER_NAME,
      perms: permissions.companySet().listValues(),
    });

    this._reqPayload = JSON.stringify(list);

    return this.$baseUrl(`/lds/workflows/${ wf.id() }/permissions`);
  },
});

/* ***************************************************************
 * Private methods for the next few APIs
 * > GetWorkflowSchedule
 * > GetParameterSetGroup
 * > GetWorkflowParameterSetGroup
 * > SetParameterSetGroup
 * *************************************************************** */

/**
 * Converts server properties attached to a scheduled job into a POJO.
 * @param {{entry: JobPropertyItem[]}} jobProps
 *        An object with one `entry` property, which
 *        is an array containing (inner) objects each
 *        with `key` and `value` properties.
 *
 *        Example:
 *        <code>
 *             {
 *                 "entry": [
 *                     {
 *                         "key": "parameter-set-group.id",
 *                         "value": "5"
 *                     }, {
 *                         "key": "workflow.id",
 *                         "value": "11"
 *                     }
 *                 ]
 *             }
 *        </code>
 * @returns {Object.<string, string>}
 * @private
 */
function _serverModelToJobProps(jobProps) {
  // console.log(jobProps);
  if (!(jobProps.entry instanceof Array)) { throw new TypeError('entry: Array-of-Object'); }

  return _.reduce(jobProps.entry, (memo, prop) => {
    memo[prop.key] = prop.value;
    return memo;
  }, {});
}

/**
 * Converts one parameter into a server-recognized payload.
 * @param paramVal {string}
 * @param paramName {string}
 * @returns {Object} An object with properties `propKey` and `propValue`.
 * @private
 */
function _paramToServerModel(paramVal, paramName) {
  return {
    propKey: paramName,
    propValue: paramVal,
  };
}

function _serverModelToParams(memo, paramModel, index) {
  const { propKey } = paramModel;
  const propVal = paramModel.propValue;

  if (typeof propKey !== 'string') { throw new Error(`Property \`propKey\` is invalid for \`parameterModels[${ index }]\``); }

  if (typeof propVal !== 'string') { throw new Error(`Property \`propValue\` is invalid for \`parameterModels[${ index }]\``); }

  memo[propKey] = propVal;
  return memo;
}

/**
 * Creates a server-model-to-parameter-set mapper.
 * @param {boolean} withParams
 * @returns {Function}
 * @private
 */
function _newServerModelToParamSetMapper(withParams) {
  return function (paramSetModel, index) {
    let params;
    if (!withParams) { params = {}; } else {
      const paramModels = paramSetModel.parameterModels;
      if (!Arrays.isArrayOf(paramModels, Object)) { throw new Error(`Property \`parameterModels\` not found in \`parameterSetModels[${ index }]\``); }

      params = _.reduce(paramModels, _serverModelToParams, {});
    }

    return new ParameterSet(
      _toInt(paramSetModel.id),
      paramSetModel.uuid,
      paramSetModel.name,
      params,
      paramSetModel.description,
    );
  };
}

/**
 * Converts a parameter-set-group server model into a ParameterSetGroup instance.
 * @param {Object} psgModel
 * @param {boolean} withParams
 * @returns {ParameterSetGroup}
 * @private
 */
function _serverModelToParamSetGroup(psgModel, withParams) {
  let paramSetModels = psgModel['parameterSetModels'];

  if (!Arrays.isArrayOf(paramSetModels, Object)) {
    paramSetModels = [paramSetModels];
  }

  if (!Arrays.isArrayOf(paramSetModels, Object)) { throw new TypeError('parameterSetModels.parameterModels: Array.<Object>'); }

  return new ParameterSetGroup(
    _toInt(psgModel.id),
    psgModel.name,
    paramSetModels.map(_newServerModelToParamSetMapper(withParams)),
  );
}

/**
 * Converts one ParameterSet into a server-recognized payload.
 * @param {ParameterSet} paramSet
 * @returns {{uuid: string, name: string, description: string, parameterModels: Object[]}}
 *          JSON object that matches model expected by server.
 * @private
 */
function _paramSetToServerModel(paramSet) {
  return {
    uuid: paramSet.uuid(),
    name: paramSet.name(),
    description: paramSet.description(),
    parameterModels: _.map(paramSet.parameters(), _paramToServerModel),
  };
}

/* ***************************************************************
 * Super-class for ParameterSetGroup APIs
 * > GetWorkflowSchedule
 * > GetWorkflowParameterSetGroup
 * *************************************************************** */
function _ProxyPsg(withParams) {
  this._withParams = withParams;
}

_ProxyPsg.prototype = Object.assign(new _Proxy(), {
  constructor: _ProxyPsg,

  /**
     * Returns the URL to retrieve the parameter-set-group associated
     * with a workflow config.
     * @param {(MpDataWorkflow|WorkflowConfig)} workflow
     * @returns {string}
     */
  getUrl(workflow) {
    const wf = _validWorkflow(workflow, 'workflow');

    const qryParamsStr = _toQueryParams({
      expand: this._withParams,
    });

    return this.$baseUrl(`/lds/workflows/${ wf.id() }/parameter-set-groups`
            + `&remQueryParams=${ _escape(qryParamsStr)}`);
  },

  /**
     * Tell super-class that a *void* response is acceptable,
     * and that we'd like it forwarded to method `$formatResponse()`.
     * @returns {boolean}
     */
  $isVoidResponseOk() {
    return true;
  },

  /**
     * Converts parameter-set-groups response payload into local classes.
     * @param {Object} payload server payload
     * @param {Arguments} reqArgs An array that contains only 1 argument: WorkflowConfig
     * @returns {Object} An object with two properties: `schedule` and `paramSetGroup`.
     */
  $formatResponse(payload, reqArgs) {
    // console.log('payload', payload);
    const embeddedName = 'workFlowJobModel';
    const psgName = 'parameterSetGroupModel';

    const withParams = this._withParams;

    try {
      let job;
      let paramSetGroup;

      if (payload === null) {
        job = null;
        paramSetGroup = ParameterSetGroup.none();
      } else if (!Objects.is(payload)) throw new Error('Payload is not an Object.');
      else if (!payload.hasOwnProperty(embeddedName)) throw new Error(`Property [${ embeddedName }] not found in response payload.`);
      else if (!Objects.is(payload[embeddedName])) throw new Error(`Property [${ embeddedName }] not an Object.`);
      else {
        /**
                 * @type {{ jobId: string, cronExpression: string,
                 *          lastFireTime: string, nextFireTime: string,
                 *          properties: Object }}
                 */
        const embedded = payload[embeddedName];

        const jobProps = _serverModelToJobProps(embedded.properties);

        job = new ScheduledJob(
          embedded.jobId,
          embedded.cronExpression,
          embedded.lastFireTime,
          embedded.nextFireTime,
          jobProps,
        );

        const psgModel = embedded[psgName];

        if (!Objects.is(psgModel)) paramSetGroup = ParameterSetGroup.none();

        else paramSetGroup = _serverModelToParamSetGroup(psgModel, withParams);
      }

      return {
        schedule: job,
        paramSetGroup,
      };
    } catch (ex) {
      console.log(ex);
      return new ServerError(-415,
        [ex.toString()],
        // this.getUrl(reqArgs[0]),
        GET);
    }
  },
});

/* ***************************************************************
 * API GetWorkflowSchedule
 * *************************************************************** */
function GetWorkflowSchedule() { }
GetWorkflowSchedule.prototype = Object.assign(new _ProxyPsg(false), {
  constructor: GetWorkflowSchedule,
});


/* ***************************************************************
 * API GetWorkflowParameterSetGroup
 * *************************************************************** */
function GetWorkflowParameterSetGroup() { }
GetWorkflowParameterSetGroup.prototype = Object.assign(new _ProxyPsg(true), {
  constructor: GetWorkflowParameterSetGroup,
});


/* ***************************************************************
 * API GetParameterSetGroup
 * *************************************************************** */
/**
 * @constructor
 * @name MpApi.GetParameterSetGroup
 */
function GetParameterSetGroup() { }
GetParameterSetGroup.prototype = Object.assign(new _Proxy(), /** @lends MpApi.GetParameterSetGroup.prototype */ {
  constructor: GetParameterSetGroup,

  getUrl(psgId) {
    requireInteger(psgId, 'psgId');

    const qryParamsStr = _toQueryParams({
      expand: true,
    });

    return this.$baseUrl(`/lds/parameter-set-groups/${ _toStr(psgId)
    }&remQueryParams=${ _escape(qryParamsStr)}`);
  },

  $formatResponse(payload) {
    return _serverModelToParamSetGroup(payload, true);
  },
});

/* ***************************************************************
 * API SetParameterSetGroup
 * *************************************************************** */
/**
 * @constructor
 * @name MpApi.SetParameterSetGroup
 */
function SetParameterSetGroup() {
  this._reqPayload = null;
}

SetParameterSetGroup.prototype = Object.assign(new _Proxy(), /** @lends MpApi.SetParameterSetGroup.prototype */ {
  constructor: SetParameterSetGroup,

  methodType() { return POST; },
  contentType() { return APPLICATION_JSON; },
  requestBody() { return this._reqPayload; },

  getUrl(paramsetGroup) {
    const payload = SetParameterSetGroup.getPayload(paramsetGroup);
    this._reqPayload = JSON.stringify(payload);

    return this.$baseUrl('/lds/parameter-set-groups');
  },

  $formatResponse(payload) {
    return _serverModelToParamSetGroup(payload, true);
  },
});

Object.assign(SetParameterSetGroup, /** @lends MpApi.SetParameterSetGroup */ {

  /**
     * Returns the payload that will be posted to Marketplace API.
     * @param paramsetGroup {ParameterSetGroup}
     */
  getPayload(paramsetGroup) {
    if (!(paramsetGroup instanceof ParameterSetGroup)) throw new TypeError('paramsetGroup: ParameterSetGroup');

    return {
      name: paramsetGroup.name(),
      parameterSetModels: paramsetGroup.parameterSets().map(_paramSetToServerModel),
    };
  },
});


/* *********************************************
 * Class: MpApi.Files.Search.Parameters
 * ********************************************* */

/**
 *
 * @constructor
 * @name MpApi.Files.Search.Parameters
 */
function SearchParams() {
  /**
     * Path to search - without /home or /trash.  Starts with '/'.
     * @type {string}
     */
  this._dirPrefix = '/';

  /**
     * Search expression, may includes wildcards ('*' and '?').
     * Wildcards are converted to regular expressions within the API;
     * callers should only use wildcards.
     * @type {string}
     */
  this._regex = '';

  /**
     * Excludes files created before `_from`.
     * @type {?int}
     */
  this._from = null;

  /**
     * Excludes files created after `_to`.
     * @type {?int}
     */
  this._to = null;

  /**
     * Owner of files to be searched.
     * @type {string}
     */
  this._owner = '';

  /**
     * Limit the result sets, default 100 (max: 10,000).
     * @type {number}
     */
  this._limit = 100;

  /**
     * Whether the search should return deleted files.
     * @type {boolean}
     */
  this._includeDeleted = false;
}


SearchParams.prototype = /** @lends {MpApi.Files.Search.Parameters.prototype} */ {
  constructor: SearchParams,

  /**
     * Servers two purposes:
     *
     * 1) Overrides the default `Object.toString()` method;
     * 2) Converts the parameters object into an URL query-string.
     *
     * @returns {string} URL query-string, the part that comes after '?'.
     */
  toString() {
    const root = '';
    let qryStr = `name=${ _escape(root + this._dirPrefix)
    }&limit=${ this._limit.toString()}`;

    if (this._includeDeleted) {
      qryStr += '&include_deleted=true';
    }
    if (Strings.isNonEmpty(this._owner)) {
      qryStr += `&owner=${ _escape(this._owner)}`;
    }
    if (Strings.isNonEmpty(this._regex)) {
      qryStr += `&name_regex=${ _escape(Strings.wildcardPattern(this._regex))}`;
    }
    if (this._from !== null) {
      qryStr += `&time=${ this._from.toString()}`;
    }
    if (this._to !== null) {
      qryStr += `&endtime=${ this._to.toString()}`;
    }

    return qryStr;
  },

  /**
     * Gets or sets the path to search; may include file prefix.
     * @param {string} [path="/"] - Must begin with '/'.
     * @returns {(string|MpApi.Files.Search.Parameters)}
     */
  pathBegins(path) {
    if (arguments.length < 1) {
      return this._dirPrefix;
    }
    requireNonEmptyString(path, 'path');
    if (!Strings.startsWith(path, '/')) {
      throw new Error("Path must be absolute, start with '/'.");
    }
    this._dirPrefix = path;
    return this;
  },

  /**
     * Gets or sets the pattern of file names to be returned.
     * @param {string} [pattern=""] - May contain wildcards '*' and '?'.
     * @returns {(string|MpApi.Files.Search.Parameters)}
     */
  pattern(pattern) {
    if (arguments.length < 1) return this._regex;

    if (typeof pattern !== 'string') throw new TypeError('pattern: String');

    else {
      this._regex = pattern;
      return this;
    }
  },

  /**
     * Gets or sets an instant in time used to narrow down the search.
     * If set, `creationTimeStart` causes the result-set to only contain
     * files created on-or-after `millisUtc`.
     * @param {?int} millisUtc
     * @returns {?(int|MpApi.Files.Search.Parameters)}
     */
  creationTimeStart(millisUtc) {
    return _getsetIntOrNull(this, '_from', arguments, 'millisUtc');
  },

  /**
     * Gets or sets an instant in time used to narrow down the search.
     * If set, `creationTimeEnd` causes the result-set to only contain
     * files created on-or-before `millisUtc`.
     * @param {?int} millisUtc
     * @returns {?(int|MpApi.Files.Search.Parameters)}
     */
  creationTimeEnd(millisUtc) {
    return _getsetIntOrNull(this, '_to', arguments, 'millisUtc');
  },

  /**
     * Gets or sets the owner of files to be searched.
     * @param {string} [owner=""] - Owner username (login ID) or blank ("").
     * @returns {(string|MpApi.Files.Search.Parameters)}
     */
  owner(owner) {
    if (arguments.length < 1) return this._owner;

    if (typeof owner !== 'string') throw new TypeError('owner: String');

    else {
      this._owner = owner;
      return this;
    }
  },

  /**
     * Gets or sets the maximum size of the results-set.
     * @param {int} [limit=100] Maximum size of result-set.
     * @returns {(int|MpApi.Files.Search.Parameters)}
     */
  limit(limit) {
    if (arguments.length < 1) return this._limit;

    if (!Numbers.isPositiveInteger(limit)) throw new TypeError('limit: Integer, positive');

    else {
      this._limit = limit;
      return this;
    }
  },

  /**
     * Gets or sets whether the search returns deleted files.
     * @param {boolean} [includeDeleted=false]
     * @returns {(boolean|MpApi.Files.Search.Parameters)}
     */
  includeDeleted(includeDeleted) {
    if (arguments.length < 1) return this._includeDeleted;

    if (typeof includeDeleted !== 'boolean') throw new TypeError('includeDeleted: Boolean');

    else {
      this._includeDeleted = includeDeleted;
      return this;
    }
  },
};

Object.freeze(SearchParams);
Object.freeze(SearchParams.prototype);


/* *********************************************
 * API: MpApi.Files.Search
 * ********************************************* */

/**
 * File Search API.
 * @constructor
 * @name MpApi.Files.Search
 */
function FileSearch() { }
FileSearch.prototype = /** @lends {MpApi.Files.Search.prototype} */ {
  constructor: FileSearch,

  getUrl(params) {
    if (!(params instanceof SearchParams)) throw new TypeError('params: MpApi.Files.Search.Parameters');

    return _fullFileApiUrl(`/list?${ params.toString()}`);
  },

  formatResponse(payload, reqArgs) {
    if (!Objects.is(payload)
            || !payload.hasOwnProperty('files')) {
      Console.warn('Invalid payload replaced with an empty list in response to URL {}.', this.getUrl(reqArgs[0]));
      return Arrays.EMPTY;
    }

    return payload.files.map(md => MpFile.fromServerMetadata(md));
  },
};

Object.assign(FileSearch, /** @lends {MpApi.Files.Search} */ {
  Parameters: SearchParams,
});

Object.freeze(FileSearch);
Object.freeze(FileSearch.prototype);


/* *********************************************
 * Abstract class: _FilePut
 * ********************************************* */

function _FilePut() { }

_FilePut.prototype = /** @lends {MpApi.Files.Rename.prototype} */ {
  constructor: _FilePut,

  methodType() {
    return PUT;
  },

  /**
     *
     * @param {MpFile} mpFile
     * @param {string} action
     * @param {string} newPath
     * @returns {string}
     */
  $getUrl(mpFile, action, newPath) {
    const qryParams = `?action=${ _escape(action)
    }&uuid=${ _escape(mpFile.uuid())
    }&name=${ _escape(newPath)}`;

    return _fullFileApiUrl(qryParams);
  },

  /**
     * @param {MpFile.ServerMetadata} payload
     * @param {{0: MpFile}} reqArgs
     * @returns {(MpFile|ServerError)}
     */
  formatResponse(payload, reqArgs) {
    try {
      return MpFile.fromServerMetadata(payload);
    } catch (ex) {
      const url = this.getUrl(reqArgs[0]);

      Console.warn('Error parsing Markets response:\n  API URL: [{}]\n  Error: [{}]\n  Payload: [{}]\n  Stack: [{}]',
        url, ex, JSON.stringify(payload), ex.stack);

      return new ServerError(-415, [
        'Error parsing server response:',
        ex.toString(),
        'See full details in JavaScript console.',
      ],
      url,
      this.methodType());
    }
  },
};

/* *********************************************
 * API: MpApi.Files.Rename
 * ********************************************* */
/**
 * API to rename a Marketplace file.
 * @constructor
 * @name MpApi.Files.Rename
 */
function FileRename() { }
FileRename.prototype = Object.assign(new _FilePut(), /** @lends {MpApi.Files.Rename.prototype} */ {

  /**
     * @param {MpFile} mpFile
     * @param {string} fullPath New path to file, excluding root directory.
     */
  getUrl(mpFile, fullPath) {
    MpFile.requireMpFile(mpFile, 'mpFile');
    requireNonEmptyString(fullPath, 'fullPath');

    if (!Strings.startsWith(fullPath, '/')) throw new Error("IllegalArgumentException: fullPath must start with '/'.");

    if (Strings.startsWith(fullPath, `${TRASH_DIR }/`)) throw new Error('IllegalArgumentException: cannot send file into /trash using Rename API; use Delete.');

    if (mpFile.isDeleted()) throw new Error(`IllegalArgumentException: cannot rename deleted file (${ mpFile.fullPath() })`);

    return this.$getUrl(mpFile, 'rename', fullPath);
  },
});

/* *********************************************
 * API: MpApi.Files.Delete
 * ********************************************* */
/**
 * API to delete a Marketplace file.  This is a soft-delete.
 * @constructor
 * @name MpApi.Files.Delete
 */
function FileDelete() { }
FileDelete.prototype = Object.assign(new _FilePut(), /** @lends {MpApi.Files.Delete.prototype} */ {

  /**
     * @param {MpFile} mpFile
     */
  getUrl(mpFile) {
    MpFile.requireMpFile(mpFile, 'mpFile');

    if (mpFile.isDeleted()) throw new Error(`IllegalArgumentException: file is already in /trash (${ mpFile.fullPath() })`);

    return this.$getUrl(mpFile, 'delete', TRASH_DIR + mpFile.fullPath());
  },
});

/* *********************************************
 * API: MpApi.Files.Undelete
 * ********************************************* */
/**
 * API to un-delete (aka restore) a Marketplace file.
 * @constructor
 * @name MpApi.Files.Undelete
 */
function FileUndelete() { }
FileUndelete.prototype = Object.assign(new _FilePut(), /** @lends {MpApi.Files.Undelete.prototype} */ {

  /**
     * @param {MpFile} mpFile
     */
  getUrl(mpFile) {
    MpFile.requireMpFile(mpFile, 'mpFile');

    if (!mpFile.isDeleted()) throw new Error(`IllegalArgumentException: file is not in /trash (${ mpFile.fullPath() })`);

    let newPath = mpFile.fullPath();
    if (Strings.startsWith(newPath, `${TRASH_DIR }/`)) newPath = newPath.substring(TRASH_DIR.length);

    return this.$getUrl(mpFile, 'undelete', newPath);
  },
});


/* ***************************************************************
 * API GetJobStatus
 * *************************************************************** */
/**
 *  API to get the JobStatus based on JobId
 * @constructor
 * @name MpApi.GetJobStatus
 */
function GetJobStatus() { }
GetJobStatus.prototype = _.extend(new _Proxy(), {
  constructor: GetJobStatus,


  getUrl(jobId) {
    const qryParamsStr = _toQueryParams({
      jobid: jobId,
      all: true,
    });
    return this.$baseUrl(`${'/lds/jobs'
            + '&remQueryParams='}${ _escape(qryParamsStr)}`);
  },

  $formatResponse(payload) {
    return _jobStatusResponseToHistoryRunStatus(payload);
  },
});

/**
 *
 * @param {string} phaseName
 * @returns {function(JobStatus): boolean} New JobStatus filter
 * @private
 */
function _completePhaseFilter(phaseName) {
  return job => (job.phase() === phaseName && job.status() === HistoryJobStatus.COMPLETED);
}

/**
 * Converts the payload of JobStatus to HistoryRunStatus
 * @param {Array.<{ exitCode: int, id: string, jobId: string,
 *                  phase: string, status: string, time: int,
 *                 [datasource]: string, [feed]: string }>} payload Array of job statuses.
 * @returns {HistoryRunStatus} RUNNING if the payload response is of empty array,
 *                             RUNNING if at least one job is complete or running
 *                             COMPLETE if all all the jobs are complete
 * @private
 */
function _jobStatusResponseToHistoryRunStatus(payload) {
  // Set's the status to running if the jobStatus response has length of zero or 1;if the formula
  // status is running or if it's empty then the status is set to running
  if (!Arrays.isArrayOf(payload, Object) || payload.length <= 1) {
    return new HistoryRunStatus(HistoryJobStatus.RUNNING, 0);
  }

  const jobStatus = payload.map(job => new JobStatus(
    job.exitCode,
    job.id,
    job.jobId,
    job.phase,
    job.status,
    job.time,
    _ifAvail(job, 'datasource', ''), // `datasource` is not always present on Formula logs.
    _ifAvail(job, 'feed', ''), // `feed` is not always present on Formula logs.
  ));
    // Number of phases that has job status has to be complete before setting status as complete
  const MAX_COMPLETED_JOBS = 5;

  // If the status has no new data then the job is complete and status is set to NO_NEW_DATA.
  if (jobStatus.some(job => (job.status() === HistoryJobStatus.NO_NEW_DATA))) {
    return new HistoryRunStatus(HistoryJobStatus.NO_NEW_DATA, 100);
  }

  // If the job status has the ts and atdb phase as complete then mark the job as complete.
  // If the number of completed jobs is less then 5 then mark it as running as the phases are
  // sequential and the ts and atdb should be complete.
  if (jobStatus.some(_completePhaseFilter('atdb'))
        && jobStatus.some(_completePhaseFilter('ts'))) {
    return new HistoryRunStatus(HistoryJobStatus.COMPLETED, 100);
  }
  const cntDone = jobStatus.filter(job => job.status() === HistoryJobStatus.COMPLETED).length;
  if (cntDone < MAX_COMPLETED_JOBS) {
    return new HistoryRunStatus(HistoryJobStatus.RUNNING,
      Math.floor((cntDone / MAX_COMPLETED_JOBS) * 100));
  }

  return new HistoryRunStatus(HistoryJobStatus.NOT_AVAILABLE, 100);
}


function GetUserFeatures() { }
GetUserFeatures.prototype = Object.assign(new _Proxy(), {
  constructor: GetUserFeatures,

  getUrl() {
    // Double-escape because url-item is decoded once by CDB server, once by MP server.
    return this.$baseUrl(`/lds/users/${ _escape(_escape(_username())) }/features`);
  },

  $formatResponse(payload) {
    return MpUserFeatureSet.fromMpResponse(payload);
  },
});

/* **********************************************
 * PUBLIC OBJECT
 * ********************************************** */

const MpApi = Object.freeze({

  GetTimeSeries,

  GetDataSources,
  GetEntitledFeeds,
  GetFeedFields,
  GetFeedRoots,
  GetFeeds,
  GetFeedKeyVals,
  GetTSRange,
  GetTSDataRR,

  GetFormulaList,
  GetFormula,
  GetFormulaByUuid,
  CreateFormula,
  UpdateFormula,
  UpdateFormulaByUUID,
  DeleteFormula,
  DeleteFormulaByName,

  RunFormula,
  RunFormulaAdHoc,
  RunFormulaHistory,

  ServerError, // for backward compatibility
  UploadData,

  GetParameterSetGroup,
  SetParameterSetGroup,

  GetWorkflowConfig,
  GetEntitledWorkflows,
  WorkflowSupportAccessTest,
  SearchWorkflows,
  GetWorkflowsByIds,
  SaveWorkflowConfig,
  DeleteWorkflowConfig,
  ExecuteUserAction,
  GetWorkflowStatus,
  GetWorkflowSchedule,
  GetWorkflowParameterSetGroup,
  GetWorkflowPermissions,
  GetJobSchedule,
  CreateJobSchedule,
  DeleteJobSchedule,
  SetWorkflowPermissions,
  SetWorkflowOwner,
  StartWorkflowTarget,
  StartWorkflow,
  StopWorkflow,

  GetWorkflowReports,
  GetWorkflowReport,
  CreateWorkflowReport,
  UpdateWorkflowReport,
  DeleteWorkflowReport,

  GetJobStatus,
  GetUserFeatures,

  Files: Object.freeze(/** @lends {MpApi.Files} */ {
    Search: FileSearch,
    Rename: FileRename,
    Delete: FileDelete,
    Undelete: FileUndelete,
  }),
});

export default MpApi;
export { _serverModelToParamSetGroup, _paramSetToServerModel };


import Numbers, { requireNonNegativeInteger } from './numbers.es6';
import Dates from './dates/dates.es6';

// Need to review/test the code before upgrading to "strict" interpreter.
// "use strict";

/* ****************************************************
 * Private methods
 * **************************************************** */

/** @returns {boolean} Whether `obj` is `null` or *undefined*. */
function _isVoid(obj) {
  return (typeof obj === 'undefined'
            || obj === null);
}

/** @returns {boolean} Whether `arg` is an instance of Array. */
function _isTrueArray(arg) {
  return (arg instanceof Array);
}

/**
 * @param {(Array|Arguments|*)} a
 * @returns {boolean} Whether `a` is an object with a numeric "length" property.
 */
function _isArrayLike(a) {
  return (a instanceof Object
            && typeof a !== 'function'
            && Numbers.isNonNegativeInteger(a.length));
}


/**
 * Validates `arg` to be anything but *undefined*.
 * If valid, this method returns `arg`; it throws otherwise.
 * @param {*} arg
 * @param {string} name
 * @returns {*} `arg`
 * @private
 */
function _requireDefined(arg, name) {
  if (typeof arg === 'undefined') { throw new TypeError(`${name }: anything but undefined`); }

  return arg;
}

/**
 * Validates `arg` to be an instance of Array.
 * If valid, this method returns `arg`; it throws otherwise.
 * @param {Array} arg
 * @param {string} name
 * @returns {Array}
 * @private
 */
function _requireTrueArray(arg, name) {
  if (!_isTrueArray(arg)) { throw new TypeError(`${name }: Array`); }

  return arg;
}

/**
 * Validates `arg` to be an array-like object (Array or Arguments).
 * @param {(Array|Arguments)} arg Argument to validate.
 * @param {string} name Name given to `arg` for when an error must be thrown.
 * @returns {(Array|Arguments)} Always returns `arg`.
 * @throws {TypeError} If `arg` is not an array-like object.
 * @private
 */
function _requireArrayLike(arg, name) {
  if (!_isArrayLike(arg)) {
    throw new TypeError(`${name } Array-like object`);
  }
  return arg;
}

/**
 * Throws a TypeError object.
 * @param {string} expectedType - Expected data-type.
 * @param {?string} argName1 - Primary name given to the argument that failed validation.
 * @param {string} [argName2] - Fallback name given to the argument that failed validation.
 * @private
 * @throws TypeError Always.
 */
function _throwTypeError(expectedType, argName1, argName2) {
  const name = ((typeof argName1 === 'string') ? argName1 : argName2);
  throw new TypeError(`${name }: ${ expectedType}`);
}

/** @namespace */
const Arrays = /** @lends {Arrays} */ {

  /**
     * An empty, immutable array.
     * @returns {Array} empty.
     */
  EMPTY: Object.freeze([]),


  /**
     * Validates that `idx` represents a valid index value within
     * `arrayOrLength`.
     * @param {int} idx - Index value to validate.
     * @param {(Array|int)} [arrayOrLength] - Array to be validated, or its length.
     * @param {string} [argName="idx"] - Name of `idx` argument, for when we throw.
     * @return {int} Always returns `idx`.
     * @throws {TypeError} - If `idx` is not an Integer or is negative.
     * @throws {RangeError} If `idx` is out of bound - only applies when `arrayOrLength` is valid.
     */
  validIndex(idx, arrayOrLength, argName) {
    requireNonNegativeInteger(idx, (arguments.length > 2) ? argName : 'idx');

    const arrLen = ((_isArrayLike(arrayOrLength))
      ? arrayOrLength.length
      : arrayOrLength);

    if (typeof arrLen === 'number'
            && idx >= arrLen) {
      const name = ((arguments.length > 2) ? argName : 'idx');
      throw new RangeError(`${name }: out of range 0-${ arrLen - 1 }: ${ idx}`);
    }

    return idx;
  },

  /**
     * @param {int} idx
     * @param {(Array|int)} arrayOrLength
     * @returns {boolean} Whether `idx` represents a valid index value within `arrayOrLength`.
     */
  isValidIndex(idx, arrayOrLength) {
    try {
      Arrays.validIndex(idx, arrayOrLength);
      return true;
    } catch (ex) {
      return false;
    }
  },

  /**
     * Creates a new array of the specified size, pre-populated with the given default value.
     * @param {int} size
     * @param {*} defaultValue
     * @returns {Array} A new array.
     */
  newInstance(size, defaultValue) {
    Numbers.requireNonNegativeInteger(size, 'size');
    const a = new Array(size);
    for (let i = 0; i < size; i++) a[i] = defaultValue;
    return a;
  },

  /**
     * Compares two arguments and returns *true* if both are arrays that
     * contains the same items at the same positions.
     * @param {(Array|*)} a1
     * @param {(Array|*)} a2
     * @param {function} [fnEqual] - Function to use to compare the objects, receives two arguments:
     *        item from `a1`, item from `a2` (in that order).
     *        If not provided, this function uses the `===` comparator.
     * @return {boolean}
     */
  areEqual(a1, a2, fnEqual) {
    if (!_isArrayLike(a1)
            || !_isArrayLike(a2)
            || a1.length !== a2.length) return false;

    if (typeof fnEqual === 'undefined') fnEqual = Arrays._valueEquals;
    else if (typeof fnEqual !== 'function') throw 'IllegalArgumentException: fnEqual must be a Function.';

    let areEqual = true;
    let i = 0;
    const len = a1.length;
    for (;
      i < len && areEqual === true;
      i++) {
      if (!fnEqual(a1[i], a2[i])) areEqual = false;
    }
    return areEqual;
  },

  /**
     * Compares two arguments and returns *true* if both are arrays that
     * contains the same items, in any order.
     * @param {(Array|*)} a1
     * @param {(Array|*)} a2
     * @param {boolean} [areUnique=false] - If the arrays contain unique items
     *        - that is, one item cannot repeat within the array -
     *        set this argument to <em>true</em> for better performance.
     * @param {function} [fnEqual] - Function to use to compare the objects.
     *        If not provided, this function uses the `===` comparator.
     * @return {boolean}
     */
  areEqualShuffled(a1, a2, areUnique, fnEqual) {
    if (!_isArrayLike(a1)
            || !_isArrayLike(a2)
            || a1.length !== a2.length) return false;

    let isSubset = Arrays._isSubsetOf(a1, a2, fnEqual);
    if (isSubset && areUnique !== true) {
      isSubset = Arrays._isSubsetOf(a2, a1, fnEqual);
    }

    return isSubset;
  },

  /**
     * <p>
     *  Return whether all items of `subset`
     *  are found in `superset`, in any order.
     *  This function uses the '===' operator to compare
     *  the items with each other.
     * </p>
     *
     * <p>
     *  If any of the argument is not an Array,
     *  this function returns <em>false</em>.
     * </p>
     * @param subset {Array}
     * @param superset {Array}
     * @param [fnEqual] {function} Function to use to compare the objects.
     *                             If not provided, this function uses the `===` comparator.
     * @return {Boolean}
     */
  isSubsetOf(subset, superset, fnEqual) {
    if (!_isArrayLike(subset)
            || !_isArrayLike(superset)
            || subset.length > superset.length) return false;

    return Arrays._isSubsetOf(subset, superset, fnEqual);
  },

  _isSubsetOf(subset, superset, fnEqual) {
    let isFound = true;
    let i = 0;
    const len = subset.length;
    for (;i < len && isFound === true; i++) {
      if (Arrays.indexOf(subset[i], superset, null, fnEqual) < 0) {
        isFound = false;
      }
    }
    return isFound;
  },

  _arrayItemFinder(subArrayKey) {
    if (typeof subArrayKey === 'undefined'
            || subArrayKey === null) return Arrays._arrayItemFinderImpl;

    if (typeof subArrayKey === 'number') {
      return Arrays._arrayItemFinderByIndex(requireNonNegativeInteger(subArrayKey, 'subArrayKey'));
    } if (typeof subArrayKey === 'string') {
      if (subArrayKey === '') throw new Error('IllegalArgumentException: subArrayKey cannot be blank');

      const keyLen = subArrayKey.length;
      if (keyLen > 2
                && subArrayKey.substring(keyLen - 2) === '()') return Arrays._arrayItemFinderByMethod(subArrayKey.substring(0, keyLen - 2));
      return Arrays._arrayItemFinderByProperty(subArrayKey);
    } throw new TypeError('subArrayKey: String or Integer');
  },

  _arrayItemFinderImpl(array, index) {
    return array[index];
  },

  _arrayItemFinderByIndex(subArrayIndex) {
    return function (array, index) {
      return array[index][subArrayIndex];
    };
  },

  _arrayItemFinderByProperty(propertyName) {
    return function (array, index) {
      return array[index][propertyName];
    };
  },

  _arrayItemFinderByMethod(methodName) {
    return function (array, index) {
      return array[index][methodName]();
    };
  },

  /**
     * Compares two Number values.  Use this function
     * to sort an Array of Number objects.
     * @param v1 {Number}
     * @param v2 {Number}
     * @returns {Number}
     */
  _numCompare(v1, v2) {
    return (v1 - v2);
  },

  /**
     * Compares two String values.  This function
     * returns:
     *     -1 if v1 comes before v2 in the ascii table,
     *     1 if v1 comes after v2 in the ascii table
     *     0 if v1 and v2 are equal.
     * @param v1 {String}
     * @param v2 {String}
     * @returns {Number} -1, 0 or 1.
     */
  _strCompare(v1, v2) {
    if (v1 < v2) return -1;
    if (v1 > v2) return 1;
    return 0;
  },

  _valueEquals(v1, v2) {
    return (v1 === v2);
  },

  _selectCompare(fnCompare, key) {
    if (typeof fnCompare === 'undefined'
            || fnCompare === null) {
      if (key instanceof Date) return Dates.compare;
      if (typeof key === 'number') return Arrays._numCompare;
      if (typeof key === 'string') return Arrays._strCompare;
      throw `IllegalArgumentException: unable to find comparison function for key (${ key }).`;
    } else if (typeof fnCompare !== 'function'
                 || fnCompare.length !== 2) throw 'IllegalArgumentException: fnCompare must be a Function with a signature of two arguments.';
    else return fnCompare;
  },


  _sliceIdx(idx, defaultIdx, max) {
    if (typeof idx === 'undefined') return defaultIdx;

    requireNonNegativeInteger(idx, 'idx');

    if (idx > max) throw new Error(`ArrayOutOfBoundException: idx (${ idx }/${ max })`);

    return idx;
  },


  /**
     * @param {Array} array Argument to validate.
     * @param {(string|function)} ctor String data-type or constructor function.
     * @returns {boolean} Whether `array` is an array-like object that only contains elements
     *         of the expected type; true if `array` is empty; never throws for a bad `array` value.
     * @throws {TypeError} If `ctor` is neither a Function nor a String.
     */
  isArrayOf(array, ctor) {
    if (!_isArrayLike(array)) {
      return false;
    }

    let validator;
    if (typeof ctor === 'string') {
      validator = item => (typeof item === ctor);
    } else if (typeof ctor === 'function') {
      validator = (item => (item instanceof ctor));
    } else {
      throw new TypeError('ctor: Function or String');
    }

    let isOk = true;
    for (let i = 0, len = array.length; i < len && isOk; i++) {
      if (!validator(array[i])) {
        isOk = false;
      }
    }

    return isOk;
  },


  /**
     * Returns whether `array` is an Array where
     * each item is valid as per `itemValidator`.
     *
     * This method returns `true` if the array is empty.
     *
     * @param {Array} array
     * @param {function} itemValidator - A one-argument function that returns
     *                      a Boolean value indicating whether the item
     *                      is valid (true) or not (false).
     * @return {boolean} True if `itemValidator` returns true
     *                   for all items in `array`, or if
     *                   `array.length == 0`.  Otherwise, false.
     * @throws IllegalArgumentException if `itemValidator` does not return
     *                                  a Boolean value.
     */
  isValid(array, itemValidator) {
    if (!_isArrayLike(array)) return false;

    return (Arrays.indexOfInvalid(array, itemValidator) < 0);
  },

  /**
     * Returns the index of the first invalid item found in `array`, or
     * -1 if all items are valid.
     * @param {Array} array - Array to validate
     * @param {function} itemValidator - Item validator, takes one argument and returns a
     *        Boolean value (true indicating that an item is valid).
     * @returns {int} 0-base index position of first invalid item, or the index position of the first
     *          item for which `itemValidator` returns false.
     * @throws TypeError - If `array` is not array-like, or if `itemValidator` is not a function
     *         that returns boolean values.
     */
  indexOfInvalid(array, itemValidator) {
    _requireArrayLike(array, 'array');

    if (typeof itemValidator !== 'function') _throwTypeError('Function', 'itemValidator');

    let idxInvalid = -1;
    let isValid = false;

    for (let i = 0, len = array.length;
      i < len && idxInvalid < 0;
      i++) {
      isValid = itemValidator(array[i]);

      if (typeof isValid !== 'boolean') throw new Error('IllegalArgumentException: itemValidator did not return a Boolean');

      else if (!isValid) idxInvalid = i;
    }

    return idxInvalid;
  },

  /**
     * Validates `arg` to be a true Array object. This method considers an empty array to be valid.
     * @param {(Array)} arg Argument to validate.
     * @param {string} name Name given to `arg` for when an error must be thrown.
     * @returns {Array} Always returns `arg`.
     * @throws {TypeError} If `arg` is not a Array object.
     * @method
     */
  requireArray(arg, name) {
    if (!Array.isArray(arg)) {
      throw new TypeError(`${name }: Array`);
    }
    return arg;
  },

  /**
     * Validates `array` to be a non-empty array (or array-like) object.
     * @param {(Array|Arguments)} array Argument to validate.
     * @param {string} arrayName Name given to `array`, for when an error must be thrown.
     * @returns {(Array|Arguments)} Always returns `array`.
     * @throws {TypeError} If `array` is not an array or array-like object.
     * @throws {Error} If `array` is empty.
     */
  requireNonEmpty(array, arrayName) {
    _requireArrayLike(array, arrayName);

    if (array.length === 0) {
      throw new Error(`${arrayName }: cannot be empty`);
    }

    return array;
  },

  /**
     * Validates `arg` to be an array-like object (Array or Arguments).
     * @param {(Array|Arguments)} arg Argument to validate.
     * @param {string} name Name given to `arg` for when an error must be thrown.
     * @returns {(Array|Arguments)} Always returns `arg`.
     * @throws {TypeError} If `arg` is not an array-like object.
     * @method
     */
  requireArrayLike: _requireArrayLike,

  /**
     * Validates `array` to be an array-like object that only contain valid items.
     * If valid, this method returns that array.  It throws a TypeError otherwise.
     * Note that this method considers an empty array to be valid.
     * @param {(Array|Arguments)} array - Array to validate.
     * @param {function} itemValidator - Item validator. This callback is executed for each item
     *        of `array`; it must return a boolean, *true* if an item is valid.
     * @param {string} [arrayName] - Name given to `array`, for when an error must be thrown.
     * @returns {Array} Always returns `array`.
     * @throws TypeError if `array` is not an array-like object, or if any of its items is
     *         not valid per `itemValidator`.
     */
  requireValid(array, itemValidator, arrayName) {
    const idx = Arrays.indexOfInvalid(array, itemValidator);
    if (idx >= 0) {
      _throwTypeError(
        'not valid per `itemValidator`',
        `${(typeof arrayName === 'string') ? arrayName : 'array' }[${ idx }]`,
      );
    }
    return array;
  },

  /**
     * Validates `arg` to be an array (or array-like) object that contains instances of `ctor` only.
     * If valid, this method returns that object.  Otherwise a TypeError is thrown.
     * Note that this method considers an empty array to be valid.
     * @param {(Array|Arguments)} arg Argument to validate.
     * @param {Function} ctor Pointer to class constructor.
     * @param {string} argName Name given to `arg` for when error must be thrown.
     * @param {string} ctorName Name given to `ctor` for when error must be thrown.
     * @returns {(Array|Arguments)} Always returns `arg`.
     * @throws {TypeError} If `arg` is not an array-like object or if any of its items are not
     *         an instance of `ctor`.
     */
  requireArrayOf(arg, ctor, argName, ctorName) {
    _requireArrayLike(arg, argName);
    for (let i = 0, len = arg.length; i < len; i++) {
      if (!(arg[i] instanceof ctor)) {
        throw new TypeError(`${argName}[${i}]: + ${ctorName}`);
      }
    }
    return arg;
  },

  /**
     * @param {(Array|Arguments|*)} a
     * @returns {boolean} Whether `a` is an object with a numeric "length" property.
     * @method
     */
  isArrayLike: _isArrayLike,

  /**
     * @param {(Array.<T>|Arguments)} arrayLike An array-like object, cannot be empty.
     * @returns {(T|*)} First item from the array.
     * @throws {TypeError} If `arrayLike` is not an array-like object.
     * @throws {Error} If `arrayLike` is empty.
     */
  first(arrayLike) {
    return Arrays.requireNonEmpty(arrayLike, 'arrayLike')[0];
  },

  /**
     * @param {(Array.<T>|Arguments)} arrayLike An array-like object, may be empty.
     * @param {?T} defaultValue Value used if `arrayLike` is empty.
     * @returns {?(T|*)} First item from `arrayLike`, or `defaultValue`, may be null.
     * @throws {TypeError} If `arrayLike` is not an array-like object.
     * @throws {Error} If `arrayLike` is empty and `defaultValue` is not provided.
     */
  firstOr(arrayLike, defaultValue) {
    _requireArrayLike(arrayLike, 'arrayLike');
    if (arrayLike.length > 0) {
      return arrayLike[0];
    } if (arguments.length > 1) {
      return defaultValue;
    }
    throw new Error('`firstOr` was not supplied with `defaultValue`.');
  },

  /**
     * @param {(Array.<T>|Arguments)} arrayLike An array-like object, cannot be empty.
     * @returns {(T|*)} Last item from the array.
     * @throws {TypeError} If `arrayLike` is not an array-like object.
     * @throws {Error} If `arrayLike` is empty.
     */
  last(arrayLike) {
    Arrays.requireNonEmpty(arrayLike, 'arrayLike');
    return arrayLike[arrayLike.length - 1];
  },

  /**
     * @param {(Array.<T>|Arguments)} arrayLike An array-like object, may be empty.
     * @param {?T} defaultValue Value used if `arrayLike` is empty.
     * @returns {?(T|*)} Last item from `arrayLike`, or `defaultValue`, may be null.
     * @throws {TypeError} If `arrayLike` is not an array-like object.
     * @throws {Error} If `arrayLike` is empty and `defaultValue` is not provided.
     */
  lastOr(arrayLike, defaultValue) {
    _requireArrayLike(arrayLike, 'arrayLike');
    if (arrayLike.length > 0) {
      return arrayLike[arrayLike.length - 1];
    } if (arguments.length > 1) {
      return defaultValue;
    }
    throw new Error('`firstOr` was not supplied with `defaultValue`.');
  },

  /**
     * This function behaves identical to the Array prototype's slice() method.
     * We implement this version to provide slice() for Array-like object
     * (objects that are similar to Array, but aren't constructed by the
     * Array() constructor - i.e. a function's "arguments").
     * @param {(Array|Arguments)} array - Array-like object.
     * @param {int} [startIdx=0] - 0-based index of the first element to be included.
     * @param {int} [endIdx=array.length] - 0-based index of the first element to be excluded.
     * @returns {Array}
     * @throws {TypeError} If `array` is not an array-like object, if `startIdx` or `endIdx` are
     *         anything but non-negative integers (if provided).
     * @throws {Error} If `startIdx` or `endIdx` are out of bound (if provided),
     *         if `startIdx > endIdx` (when both are provided).
     */
  slice(array, startIdx, endIdx) {
    _requireArrayLike(array, 'array');

    const len = array.length;
    if (len === 0) {
      return [];
    }

    startIdx = Arrays._sliceIdx(startIdx, 0, len);
    endIdx = Arrays._sliceIdx(endIdx, len, len);

    if (startIdx > endIdx) {
      throw new Error('IllegalArgumentException: startIdx is greater than endIdx');
    }

    const a = [];
    for (let i = startIdx; i < endIdx; i++) {
      a.push(array[i]);
    }
    return a;
  },

  /**
     * Pushes `item` at the back of the array, inserts or moves as needed.
     * This method expects that `item` isn't in `array` more than once, if there at all.
     * After this method returns, `item` will for sure be at `array[array.length - 1]`.
     * @param {*} item
     * @param {Array} array
     * @returns {Array} Always returns `array`
     */
  pushBack(item, array) {
    Arrays.requireArray(array, 'array');
    Arrays.remove(item, array);
    array.push(item);
    return array;
  },

  /**
     * Appends `item` to `array`,
     * but only if it isn't already in the array.
     *
     * Note: this function may modify `array`.
     *
     * @param {Object} item
     * @param {Array} array
     * @param {Function} [fnEqual]
     * @return {Boolean} Whether `array` was modified.
     */
  addIfMissing(item, array, fnEqual) {
    if (Arrays.indexOf(item, array, null, fnEqual) < 0) {
      array.push(item);
      return true;
    }
    return false;
  },

  /**
     * This function behaves similar to the native Array.push() method,
     * except that it expects all elements to be added to be grouped
     * in one Array object - as returned by Array.splice() or Array.slice().
     *
     * Note: this function modifies `array`.
     *
     * @param {Array} array Array to which to add items to.
     * @param {Array} items Items to add to `array`.
     * @returns {Arrays} To enable call-chaining.
     * @throws {TypeError} - If `array` is not an Array, if `items` is not an Array.
     */
  addAll(array, items) {
    if (!(array instanceof Array)) throw new TypeError('array: Array');

    if (!(items instanceof Array)) throw new TypeError('items: Array');

    if (arguments.length > 2) throw new Error('Too many arguments');

    const numItems = items.length;
    if (numItems < 20) { // avoid "max call stack size"
      // Faster than "pushing" each item individually
      array.push.apply(array, items);
    } else {
      for (let i = 0; i < numItems; i++) array.push(items[i]);
    }

    return Arrays;
  },

  /**
     * This function adds each item from `items`
     * into `array`, but only if the item isn't
     * already in the array.
     *
     * Note: this function modifies `array`.
     *
     * @param {Array} array
     * @param {(Array|Arguments)} items
     * @param {Function} [fnEqual]
     * {Boolean} Whether `array` was modified.
     */
  addAllMissing(array, items, fnEqual) {
    Arrays.requireArray(array, 'array');
    Arrays.requireArrayLike(items, 'items');
    let isMod = false;
    for (let i = 0, len = items.length; i < len; i++) {
      if (Arrays.addIfMissing(items[i], array, fnEqual)) {
        isMod = true;
      }
    }
    return isMod;
  },

  /**
     * Returns a list of items found in `master`
     * but not `other`
     *
     * @param {Array} master
     * @param {Array} other
     * @param {function} [fnEqual]
     * @return {Array}
     */
  missingItems(master, other, fnEqual) {
    _requireArrayLike(master, 'master');
    _requireArrayLike(other, 'other');

    const missing = [];

    let i = 0;
    const len = master.length;
    for (; i < len; i++) {
      const item = master[i];

      if (Arrays.indexOf(item, other, null, fnEqual) < 0) missing.push(item);
    }

    return missing;
  },


  /**
     * Searches left to right.
     * @param {*} key Item to look for within `array`.
     * @param {Array} array Array to search.
     * @param {?(int|string)} [index] Property within each item of `array`.
     * @param {?Function} [fnEqual] Predicate to determine whether `key` equals an array item.
     * @returns {int} Index position where `key` was found within `array`, -1 if not found.
     */
  indexOf(key, array, index, fnEqual) {
    return Arrays._indexOf(key, array, index, fnEqual, 1);
  },

  /**
     * Searches right to left.
     * @param {*} key Item to look for within `array`.
     * @param {Array} array Array to search.
     * @param {?(int|string)} [index] Property within each item of `array`.
     * @param {?Function} [fnEqual] Predicate to determine whether `key` equals an array item.
     * @returns {int} Index position where `key` was found within `array`, -1 if not found.
     */
  lastIndexOf(key, array, index, fnEqual) {
    return Arrays._indexOf(key, array, index, fnEqual, -1);
  },

  _indexOf(key, array, index, fnEqual, increment) {
    if (typeof key === 'undefined') {
      throw new Error('IllegalArgumentException: a valid key must be provided (indexOf).');
    }

    if (!(array instanceof Array)) {
      throw new Error('IllegalArgumentException: array must be of type Array.');
    }

    const len = array.length;
    if (len === 0) return -1;

    // Arrays._arrayItemFinder() validates "index" argument.
    const itemFinder = Arrays._arrayItemFinder(index);
    let idx = -1;
    let startIdx = 0;
    let endIdx = len;

    if (increment < 0) {
      startIdx = len - 1;
      endIdx = -1;
    }

    if (typeof fnEqual === 'number') {
      if (!Numbers.isNonNegativeInteger(fnEqual)) {
        throw new Error('IllegalArgumentException: fnEqual is a number but not a non-negative integer.');
      }

      startIdx = fnEqual;
      fnEqual = null;
    }

    if (_isVoid(fnEqual)) {
      fnEqual = (key instanceof Date)
        ? Dates.areEqualOrNull
        : Arrays._valueEquals;
    } else if (typeof fnEqual !== 'function' || fnEqual.length !== 2) {
      throw new Error('IllegalArgumentException: fnEqual must be a Function with 2 arguments.');
    }

    for (let i = startIdx;
      i !== endIdx && idx < 0;
      i += increment) {
      if (fnEqual(key, itemFinder(array, i))) idx = i;
    }

    return idx;
  },

  /**
     * Removes an item from an Array.  The search for the item is
     * left-to-right.  This method returns the index position at which
     * the item was found.
     *
     * This method expects no duplicate; it stops at the first match.
     * @param {Object} key
     * @param {Array} array
     * @param {(int|string)} [index]
     * @param {function} [fnEqual] The function to use to compare "key" with each item
     *        within `array`.
     * @returns {int} Index position of the removed item, or -1 if the array was not modified.
     */
  remove(key, array, index, fnEqual) {
    const idx = Arrays.indexOf(key, array, index, fnEqual);
    if (idx >= 0) {
      array.splice(idx, 1);
    }
    return idx;
  },

  /**
     * Removes an item from an array.  Like {@link Arrays.remove} but removes duplicates too.
     *
     * @param {Object} key
     * @param {Array} array
     * @param {(int|string)} [index]
     * @param {function} [fnEqual] The function to use to compare "key" with each item
     *        within `array`.
     * @returns {Array} Removed items, in undefined order.  An empty array if nothing was removed.
     */
  removeMulti(key, array, index, fnEqual) {
    const removed = [];
    let idx = Arrays.indexOf(...arguments);
    while (idx >= 0) {
      removed.push(array[idx]);
      array.splice(idx, 1);
      idx = Arrays.indexOf(...arguments);
    }
    return removed;
  },

  /**
     * Removes an item from an Array.  The search for the item is
     * right-to-left.  This method returns the index position at which
     * the item was found.
     * @param {Object} key
     * @param {Array} array
     * @param {(int|string)} [index]
     * @returns {int} Index position of the removed item, or -1 if the array was not modified.
     */
  removeLast(key, array, index) {
    const idx = Arrays.lastIndexOf(key, array, index);
    if (idx >= 0) array.splice(idx, 1);
    return idx;
  },

  /**
     * Removes a list of items from an Array.
     * @param {Array} items Items to be removed
     * @param {Array} array Array from which to remove the items.
     * @param {(int|string)} [index]
     * @returns {int} The number of successfully removed items.
     */
  removeItems(items, array, index) {
    // There might be some optimization to be made here.
    // Right now, I'm just going for a simple solution.

    if (!(items instanceof Array)
            || !(array instanceof Array)) throw 'IllegalArgumentException: items and array must both be Array objects.';

    let numRemoved = 0;
    let i = 0;
    const len = items.length;
    for (; i < len; i++) {
      if (Arrays.remove(items[i], array, index) >= 0) numRemoved++;
    }

    return numRemoved;
  },

  /**
     * Convenience method for removing all items of an array,
     * since `Array.splice()` is a bit cryptic.
     * This method returns the removed items as another Array
     * object.
     *
     * @param {Array} array
     * @return {Array} The removed items.
     */
  removeAll(array) {
    if (!(array instanceof Array)) throw 'TypeMismatch: array: Array';

    return array.splice(0, array.length);
  },

  /**
     * Empty the array and returns the removed items (as an array).
     * @param array {Array}
     * @return {Array} The removed items.
     */
  empty(array) {
    return Arrays.removeAll(array);
  },

  /**
     * Moves an item within the array. This method modifies the given array.
     * @param {Array} array Array to manipulate.
     * @param {int} currentIndex Current index of the item to move.
     * @param {int} destinationIndex Destination index for the item being moved, pre-operation.
     * @returns {Array} Always returns `array`.
     * @throws {TypeError} If `array` is not an Array, or if `currentIndex` or `destinationIndex`
     *         are not integers or are negative.
     * @throws {RangeError} If `currentIndex` or `destinationIndex` is out of bound.
     */
  move(array, currentIndex, destinationIndex) {
    _requireTrueArray(array, 'array');
    Arrays.validIndex(currentIndex, array, 'currentIndex');
    Arrays.validIndex(destinationIndex, array, 'destinationIndex');

    const item = array[currentIndex];
    if (currentIndex < destinationIndex) {
      array.splice(destinationIndex + 1, 0, item);
      array.splice(currentIndex, 1);
    } else if (currentIndex > destinationIndex) {
      array.splice(currentIndex, 1);
      array.splice(destinationIndex, 0, item);
    }
    return array;
  },

  /**
     * Returns the index position of the item within `array` that equals `key`,
     * or `-1` if no such item is found.
     *
     * This function requires that `array` be sorted.
     *
     * @param {*} key - What to look for.
     * @param {Array} array - Array to be searched.
     * @param {?(string|int)} [index] - Property name or index position to retrieve the
     *         object within each item of `array` to be compared to `key`.  If provided,
     *         the array must contain items that are sorted (ascending) based on that
     *         property.  If not provided, this method compares the items themselves.
     * @param {function} [fnCompare] - Comparison function for navigating `array`; must abide
     *        to the array's sort order.
     * @returns {int} 0-based index position where `key` was found.
     * @throws TypeError - If
     *           <ul>
     *            <li> `key` is `undefined`; </li>
     *            <li> `array` is not an `Array`. </li>
     *            <li> `index` is specified but is neither `String` nor `Integer`. </li>
     *           </ul>
     * @throws Error (IllegalArgumentException) - When
     *           <ul>
     *            <li> `array` is not sorted; </li>
     *            <li> `index` is a negative integer; </li>
     *            <li> `index` is an empty string. </li>
     *           </ul>
     */
  indexSearch(key, array, index, fnCompare) {
    _requireDefined(key, 'key');
    _requireTrueArray(array, 'array');

    const len = array.length;
    if (len === 0) return -1;

    // Arrays._arrayItemFinder() validates "index" argument.
    const itemFinder = Arrays._arrayItemFinder(index);

    fnCompare = Arrays._selectCompare(fnCompare, key);

    let min = 0;
    let max = len - 1;
    let item = itemFinder(array, min);
    let dir = fnCompare(item, key);

    if (dir > 0) // If the first item is bigger than "key", we won't find anything.
    { return -1; }
    if (dir === 0) return min;

    if (len === 1) return -1;

    item = itemFinder(array, max);
    dir = fnCompare(item, key);
    if (dir < 0) // If the last item is smaller than "key", we won't find anything.
    { return -1; }
    if (dir === 0) return max;

    if (len === 2) return -1;

    let foundAt = -1;
    let loop = true;
    let pos;
    while (foundAt < 0 && loop === true) {
      pos = Math.floor((min + max) / 2);
      if (pos === min) // we're done.
      { loop = false; } else {
        item = itemFinder(array, pos);
        dir = fnCompare(item, key);

        if (dir === 0) foundAt = pos;
        else if (dir < 0) min = pos;
        else max = pos;
      }
    }

    return foundAt;
  },


  /**
     * Returns the index position of the greatest element within `array` with a value
     * less than or equal to `key`, or `-1` if there is no such element.
     *
     * This function requires that `array` be sorted.
     *
     * @param {*} key - What to look for.
     * @param {Array} array - Array to be searched.
     * @param {?(string|int)} [index] - Property name or index position to retrieve the
     *         object within each item of `array` to be compared to `key`.  If provided,
     *         the array must contain items that are sorted (ascending) based on that
     *         property.  If not provided, this method compares the items themselves.
     * @param {function} [fnCompare] - Comparison function for navigating `array`; must abide
     *        to the array's sort order.
     * @returns {int} 0-based index position where `key` was found.
     * @throws TypeError - If
     *           <ul>
     *            <li> `key` is `undefined`; </li>
     *            <li> `array` is not an `Array`. </li>
     *            <li> `index` is specified but is neither `String` nor `Integer`. </li>
     *           </ul>
     * @throws Error (IllegalArgumentException) - When
     *           <ul>
     *            <li> `array` is not sorted; </li>
     *            <li> `index` is a negative integer; </li>
     *            <li> `index` is an empty string. </li>
     *           </ul>
     */
  indexFloor(key, array, index, fnCompare) {
    _requireDefined(key, 'key');
    _requireTrueArray(array, 'array');

    const len = array.length;
    if (len === 0) return -1;

    // Arrays._arrayItemFinder() validates "index" argument.
    const itemFinder = Arrays._arrayItemFinder(index);

    fnCompare = Arrays._selectCompare(fnCompare, key);

    let min = 0;
    let max = len - 1;
    let item = itemFinder(array, max);
    let dir;

    if (fnCompare(item, key) <= 0) // If the last item is smaller or equal than "key", it's our answer
    { return max; }

    item = itemFinder(array, min);
    dir = fnCompare(item, key);
    if (dir > 0) // If the first item is bigger than "key", we won't find anything.
    { return -1; }
    if (dir === 0 || len < 3) return min;

    let foundAt = -1;
    let loop = true;
    let pos;
    while (foundAt < 0 && loop === true) {
      if (max - min === 1) // we're done.
      { loop = false; } else {
        pos = Math.floor((min + max) / 2);
        item = itemFinder(array, pos);
        dir = fnCompare(item, key);
        if (dir === 0) foundAt = pos;
        else if (dir < 0) min = pos;
        else max = pos;
      }
    }

    if (foundAt < 0) foundAt = min;

    return foundAt;
  },


  /**
     * Returns the index position of the least element within `array` with a value
     * greater than or equal to `key`, or `-1` if there is no such element.
     *
     * This function requires that `array` be sorted.
     *
     * @param {*} key - What to look for.
     * @param {Array} array - Array to be searched.
     * @param {?(string|int)} [index] - Property name or index position to retrieve the
     *         object within each item of `array` to be compared to `key`.  If provided,
     *         the array must contain items that are sorted (ascending) based on that
     *         property.  If not provided, this method compares the items themselves.
     * @param {function} [fnCompare] - Comparison function for navigating `array`; must abide
     *        to the array's sort order.
     * @returns {int} 0-based index position where `key` was found.
     * @throws TypeError - If
     *           <ul>
     *            <li> `key` is `undefined`; </li>
     *            <li> `array` is not an `Array`. </li>
     *            <li> `index` is specified but is neither `String` nor `Integer`. </li>
     *           </ul>
     * @throws Error (IllegalArgumentException) - When
     *           <ul>
     *            <li> `array` is not sorted; </li>
     *            <li> `index` is a negative integer; </li>
     *            <li> `index` is an empty string. </li>
     *           </ul>
     */
  indexCeiling(key, array, index, fnCompare) {
    _requireDefined(key, 'key');
    _requireTrueArray(array, 'array');

    const len = array.length;
    if (len === 0) return -1;

    // Arrays._arrayItemFinder() validates "index" argument.
    const itemFinder = Arrays._arrayItemFinder(index);

    fnCompare = Arrays._selectCompare(fnCompare, key);

    let min = 0;
    let max = len - 1;
    let item = itemFinder(array, min);
    let dir;

    if (fnCompare(item, key) >= 0) // If the first item is greater or equal to "key", it's our answer
    { return min; }

    item = itemFinder(array, max);
    dir = fnCompare(item, key);
    if (dir < 0) // If the last item is smaller than "key", we won't find anything.
    { return -1; }
    if (dir === 0 || len < 3) return max;

    let foundAt = -1;
    let loop = true;
    let pos;
    while (foundAt < 0 && loop === true) {
      if (max - min === 1) // we're done.
      { loop = false; } else {
        pos = Math.floor((min + max) / 2);
        item = itemFinder(array, pos);
        dir = fnCompare(item, key);
        if (dir === 0) foundAt = pos;
        else if (dir < 0) min = pos;
        else max = pos;
      }
    }

    if (foundAt < 0) foundAt = max;

    return foundAt;
  },


  iterator(array, startIdx, endIdx) {
    const params = Arrays._validateIteratorParams(array, startIdx, endIdx);
    const itemGetter = Arrays._arrayItemFinderImpl;

    return new Iterator(
      array,
      params.start,
      params.end,
      itemGetter,
    );
  },


  iteratorOfArrayItem(subArrayIndex, array, startIdx, endIdx) {
    if (typeof (subArrayIndex) !== 'number'
            || subArrayIndex < 0) throw 'IllegalArgumentException: subArrayIndex must be a Number greater or equal to zero.';

    const params = Arrays._validateIteratorParams(array, startIdx, endIdx);
    const itemGetter = Arrays._arrayItemFinderByIndex(subArrayIndex);

    return new Iterator(
      array,
      params.start,
      params.end,
      itemGetter,
    );
  },

  iteratorOfObjectProperty(propertyName, array, startIdx, endIdx) {
    if (typeof (propertyName) !== 'string'
            || propertyName === '') throw 'IllegalArgumentException: propertyName must be a non-empty String.';

    const params = Arrays._validateIteratorParams(array, startIdx, endIdx);
    const itemGetter = Arrays._arrayItemFinderByProperty(propertyName);

    return new Iterator(
      array,
      params.start,
      params.end,
      itemGetter,
    );
  },


  _validateIteratorParams(array, startIdx, endIdx) {
    _requireArrayLike(array, 'array');

    if (typeof startIdx === 'undefined') startIdx = 0;
    else if (typeof (startIdx) !== 'number'
                 || startIdx < 0
                 || (startIdx >= array.length
                     && startIdx > 0)) throw `IllegalArgumentException: startIdx must be a Number between 0 and ${ array.length - 1 }(${ startIdx })`;

    if (typeof endIdx === 'undefined') endIdx = array.length;
    else if (typeof (endIdx) !== 'number'
                 || endIdx < startIdx
                 || endIdx > array.length) throw `IllegalArgumentException: endIdx must be a Number between startIdx and ${ array.length } (${ endIdx })`;

    return { start: startIdx, end: endIdx };
  },

  getProperties(array, propName) {
    const a = [];
    let i = 0;
    const len = array.length;
    for (; i < len; i++) a.push(array[i][propName]);
    return a;
  },


};


/* *******************************************************
 * PRIVATE CLASS: Iterator
 * ******************************************************* */

/**
 * @constructor
 * @name Arrays.Iterator
 */
function Iterator(array, startIdx, endIdx, itemGetter, isDescending) {
  this._array = array;
  this._startIdx = startIdx;
  this._endIdx = endIdx;
  this._itemGetter = itemGetter;
  this._idx = startIdx;
  this._length = endIdx - startIdx;
  this._dir = (isDescending === true) ? -1 : 1;

  this._setFunctions();
}


Object.assign(Iterator.prototype, /** @lends {Arrays.Iterator.prototype} */ {

  /* *******************************************************
     * METHODS
     * ******************************************************* */

  /* The following functions dynamically assigned based on direction
     * (this._dir).  Their initial value is "null". */
  /** @type {Function.<boolean>} */
  hasNext: null,
  _get: null,
  _seek: null,

  _setFunctions() {
    const suffix = (this._dir > 0) ? 'Asc' : 'Desc';

    this.hasNext = this[`_hasNext${ suffix}`];
    this._get = this[`_get${ suffix}`];
    this._seek = this[`_seek${ suffix}`];
  },

  _hasNextAsc() {
    return (this._idx < this._endIdx);
  },

  _hasNextDesc() {
    return (this._idx >= 0);
  },

  next() {
    const item = this._itemGetter(this._array, this._idx);
    this._idx += this._dir;
    return item;
  },

  peek() {
    return this._itemGetter(this._array, this._idx);
  },

  /** @returns {int} Number of items in this iterator. */
  size() {
    return this._length;
  },

  /** @returns {boolean} Whether this iterator is empty. */
  isEmpty() {
    return (this.size() === 0);
  },

  reset() {
    if (this._dir > 0) this._idx = this._startIdx;
    else this._idx = this._endIdx - 1;

    return this;
  },

  reverse() {
    this._dir = (this._dir > 0) ? -1 : 1;
    this.reset();
    this._setFunctions();
    return this;
  },

  get(idx) {
    Arrays.validIndex(idx, this._length);
    return this._get(idx);
  },

  _getAsc(idx) {
    return this._itemGetter(this._array, this._startIdx + idx);
  },

  _getDesc(idx) {
    return this._itemGetter(this._array, this._endIdx - 1 - idx);
  },

  seek(idx) {
    Arrays.validIndex(idx, this._length);

    this._seek(idx);
  },

  _seekAsc(idx) {
    this._idx = idx;
  },
  _seekDesc(idx) {
    this._idx = this._endIdx - 1 - idx;
  },

  move(offset) {
    if (!Numbers.isInteger(offset)) throw 'IllegalArgumentException: offset must be an Integer.';

    if (offset !== 0) {
      const newIdx = this._idx + (offset * this._dir);
      if (newIdx < 0 || newIdx >= this._length) throw `IndexArrayOutOfBoundException: move() attempted to reposition outside of the scope of the iterator instance (index ${ newIdx })`;

      this._idx = newIdx;
    }
  },

  /** @returns {Arrays.Iterator} */
  iterator(offset) {
    let start = this._startIdx;
    let end = this._endIdx;

    if (typeof offset !== 'undefined') {
      if (!Numbers.isInteger(offset)) throw 'IllegalArgumentException: offset must be an Integer.';

      if (this._dir > 0) start = Math.max(0, this._idx + offset);
      else end = Math.min(this._endIdx - 1, this._idx - offset);
    }
    return new Iterator(
      this._array,
      start,
      end,
      this._itemGetter,
      (this._dir === -1),
    );
  },

});

export default Arrays;
export const
  requireNonEmptyArray = Arrays.requireNonEmpty;

import axios from 'axios';

function splunkLogger(logArray) {
  if (process.env.VUE_APP_TITLE === 'STG' || process.env.VUE_APP_TITLE === 'PROD') {
    return axios.post('/api/splunkLogger', {
      logArr: logArray,
    });
  }
  return false;
}

export default splunkLogger;

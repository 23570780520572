<template>
  <div
    id="mds-modal"
    class="mds-modal  mds-modal--600px mds-modal--width-600px"
    :class="{'mds-modal--open  mds-modal--active':saveGroupsPopOver}"
  >
    <div
      id="mds-modal-wrapper"
      class="mds-modal__wrapper"
    >
      <section
        role="dialog"
        class="mds-modal__container"
        aria-hidden="false"
      >
        <div class="mds-modal__inner">
          <header class="mds-modal__header">
            <h1 class="mds-modal__title">
              {{ $t('labels.group.saveGroupHeader') }}
            </h1>
          </header>
          <div class="mds-modal__content">
            <template v-if="groupChangesAdd || groupChangesUpdate || groupChangesDelete">
              <p>{{ $t('labels.group.confirmMessage') }}</p>
            </template>
            <template v-else>
              <p>{{ $t('labels.group.noChanges') }}</p>
            </template>
            <div
              v-if="groupChangesAdd"
              class="group-changes-block"
            >
              <label class="change-action-header">{{ $t('labels.group.toBeAdded') }}</label>
              <table class="mds-data-table">
                <th id="emptyId" />
                <tbody class="mds-data-table__body">
                  <template v-for="(group,index) in groupChanges.get('ADD')">
                    <tr
                      :id="'ga' + index"
                      :key="'ga' + index"
                      class="mds-data-table__row save-group-row ADD"
                    >
                      <td
                        class="mds-data-table__cell"
                        style="width:3%"
                      >
                        <mds-icon name="plus--s" />
                      </td>
                      <td class="mds-data-table__cell">
                        {{ group.groupName }}
                      </td>
                    </tr>
                    <tr
                      v-for="(user,index2) in group.userList"
                      :key="'gau' + index2"
                      class="save-group-row"
                      :class="user.userAction"
                    >
                      <td
                        class="mds-data-table__cell"
                        style="width:3%"
                      >
                        <mds-icon name="plus--s" />
                      </td>
                      <td class="save-group-sec-col">
                        {{ user.userid }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div
              v-if="groupChangesUpdate"
              class="group-changes-block"
            >
              <label class="change-action-header">{{ $t('labels.group.toBeUpdated') }}</label>
              <table class="mds-data-table">
                <th id="emptyId2" />
                <tbody class="mds-data-table__body">
                  <template v-for="(group,index) in groupChanges.get('UPDATE')">
                    <tr
                      :id="'gu' + index"
                      :key="'gu' + index"
                      class="mds-data-table__row save-group-row update"
                    >
                      <td
                        class="mds-data-table__cell"
                        style="width:3%"
                      >
                        <mds-icon name="pencil--s" />
                      </td>
                      <td class="mds-data-table__cell">
                        {{ group.groupName }}
                      </td>
                    </tr>
                    <tr
                      v-for="(user,index2) in group.userList"
                      :key="'guu' +index + index2"
                      class="save-group-row"
                      :class="user.userAction"
                    >
                      <td
                        class="mds-data-table__cell"
                        style="width:3%"
                      >
                        <template v-if="user.userAction=='ADD'">
                          <mds-icon name="plus--s" />
                        </template>
                        <template v-if="user.userAction=='REMOVE'">
                          <mds-icon name="minus--s" />
                        </template>
                      </td>
                      <td class="save-group-sec-col">
                        {{ user.userid }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div
              v-if="groupChangesDelete"
              class="group-changes-block"
            >
              <label class="change-action-header">{{ $t('labels.group.toBeDeleted') }}</label>
              <table class="mds-data-table">
                <th id="emptyId3" />
                <tbody class="mds-data-table__body">
                  <template v-for="(group,index) in groupChanges.get('DELETE')">
                    <tr
                      :id="'gd' + index"
                      :key="'gd' + index"
                      class="mds-data-table__row save-group-row REMOVE"
                    >
                      <td
                        class="mds-data-table__cell"
                        style="width:3%"
                      >
                        <mds-icon name="minus--s" />
                      </td>
                      <td class="mds-data-table__cell">
                        {{ group.groupName }}
                      </td>
                    </tr>
                    <tr
                      v-for="(user,index2) in group.userList"
                      :key="'gdu' + index2"
                      class="save-group-row"
                      :class="user.userAction"
                    >
                      <td
                        class="mds-data-table__cell "
                        style="width:3%"
                      >
                        <mds-icon name="minus--s" />
                      </td>
                      <td class="save-group-sec-col">
                        {{ user.userid }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mds-button__container  save-group-actions">
            <mds-button
              v-show="groupChangesAdd || groupChangesUpdate || groupChangesDelete"
              variation="primary"
              type="button"
              @click="saveGroupsChild()"
            >
              {{ $t('labels.group.saveButton') }}
            </mds-button>
            <mds-button
              id="cancel-btn"
              variation="secondary"
              type="button"
              @click="cancelPopOver"
            >
              {{ $t('labels.group.cancelButton') }}
            </mds-button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import EventBus from '../../main';

export default {
  name: 'GroupPopOver',
  props: {
    companyGroups: { type: Array, default: null },
    companyGroupsOrig: { type: Array, default: null },
  },
  data() {
    return {
      groupChangesAdd: false,
      groupChangesUpdate: false,
      groupChangesDelete: false,
      saveGroupsPopOver: false,
    };
  },
  mounted() {
    EventBus.$on('saveGroupPop', this.saveGroupsPop);
  },
  methods: {
    cancelPopOver() {
      this.saveGroupsPopOver = false;
    },
    saveGroupsChild() {
      this.saveGroupsPopOver = false;
      this.$parent.saveGroups(this.groupChanges);
    },
    saveGroupsPop() {
      this.groupChanges = new Map();
      this.groupChanges.set('ADD', []);
      this.groupChanges.set('UPDATE', []);
      this.groupChanges.set('DELETE', []);
      this.groupChangesAdd = false;
      this.groupChangesUpdate = false;
      this.groupChangesDelete = false;
      // check for added groups and users
      this.addGroupChanges();
      // check for updated groups
      this.updateGroupChanges();
      // check for deleted group
      this.deleteGroupChanges();
      this.saveGroupsPopOver = true;
    },
    addGroupChanges() {
      this.companyGroups.forEach(((group) => {
        if (this.$parent.getGroupIndex(this.companyGroupsOrig, group.groupName) < 0) {
          if (group.userList && group.userList.length > 0) {
            const groupObj = {
              groupName: group.groupName,
              userList: [],
            };
            group.userList.forEach(((user) => {
              groupObj.userList.push({
                userid: user,
                userAction: 'ADD',
              });
            }));
            this.groupChanges.get('ADD').push(groupObj);
            this.groupChangesAdd = true;
          }
        }
      }));
    },
    updateGroupChanges() {
      this.companyGroups.forEach(((group) => {
        const origIndex = this.$parent.getGroupIndex(this.companyGroupsOrig, group.groupName);
        if (origIndex > -1) {
          let updateFlag = false;
          const groupObjOrig = this.companyGroupsOrig[origIndex];
          if (group.userList && group.userList.length > 0) {
            const groupObj = {
              groupName: group.groupName,
              userList: [],
            };
            for (let userIndex = 0; userIndex < group.userList.length; userIndex += 1) {
              const user = group.userList[userIndex];
              if (groupObjOrig.userList.indexOf(user) < 0) {
                groupObj.userList.push({
                  userid: user,
                  userAction: 'ADD',
                });
                updateFlag = true;
              }
            }
            for (let userIndex = 0; userIndex < groupObjOrig.userList.length; userIndex += 1) {
              const user = groupObjOrig.userList[userIndex];
              if (group.userList && group.userList.indexOf(user) < 0) {
                groupObj.userList.push({
                  userid: user,
                  userAction: 'REMOVE',
                });
                updateFlag = true;
              }
            }
            if (updateFlag) {
              this.groupChanges.get('UPDATE').push(groupObj);
              this.groupChangesUpdate = true;
            }
          }
        }
      }));
    },
    deleteGroupChanges() {
      this.companyGroupsOrig.forEach(((group) => {
        if (this.$parent.getGroupIndex(this.companyGroups, group.groupName) < 0) {
          const groupObj = {
            groupName: group.groupName,
            userList: [],
          };
          this.groupChanges.get('DELETE').push(groupObj);
          this.groupChangesDelete = true;
        }
      }));
    },
  },
};

</script>
<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/popover';
</style>

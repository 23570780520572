
import _ from 'underscore';
import * as CONSTANTS from './constants.es6';
import Console from './console.es6';

export function dfs(bubble, visited, relatedInputBubbles, relatedOutputBubbles) {
  const id = bubble._id;
  visited[id] = true;
  if (bubble._type == 'data') {
    relatedInputBubbles.push(bubble);
  }
  if (bubble._type == 'save') {
    relatedOutputBubbles.push(bubble);
  }
  bubble._ins.forEach((link) => {
    const src = link._src;
    const srcId = src._id;
    if (!visited[srcId]) {
      dfs(src, visited, relatedInputBubbles, relatedOutputBubbles);
    }
  });

  bubble._outs.forEach((link) => {
    const target = link._target;
    const targetId = target._id;
    if (!visited[targetId]) {
      dfs(target, visited, relatedInputBubbles, relatedOutputBubbles);
    }
  });
}

/**
 * Returns the prefix of a dataset parameter.
 * @param {string} varName
 * @private
 */
export function datasetPrefix(varName) {
  return `${CONSTANTS.PARAM_PREFIX_DATASET + varName }.`;
}

export function convertParams(bubbles, psgSet, bubbleNames) {
  const params = new Array();
  bubbles.forEach((bubble) => {
    const varName = bubbleNames[bubble._id];
    const newPrefix = datasetPrefix(varName);
    let paramSet = {};
    paramSet[CONSTANTS.DATASET_NAME] = varName;
    paramSet[CONSTANTS.DATASET_SUFFIX_FEED] = psgSet[newPrefix + CONSTANTS.DATASET_SUFFIX_FEED];
    try {
      paramSet[CONSTANTS.DATASET_SUFFIX_KEY_ROOTS] = JSON.parse(psgSet[newPrefix + CONSTANTS.DATASET_SUFFIX_KEY_ROOTS]);
      paramSet[CONSTANTS.DATASET_SUFFIX_COLS] = JSON.parse(psgSet[newPrefix + CONSTANTS.DATASET_SUFFIX_COLS]);
    } catch (error) {
      Console.error(
        'Unable to parse JSON data in BubbleUtils due to: {}',
        error,
      );
      paramSet = {};
    }
    params.push(paramSet);
  });
  return params;
}

/**
 * The overlap wf is defined as the same
 * feed, same keys or same roots (in any order), and intersection columns
 * (in any order.)
 */
export function isOverlap(obj1, obj2) {
  return obj1[CONSTANTS.DATASET_SUFFIX_FEED] == obj2[CONSTANTS.DATASET_SUFFIX_FEED]
        && _.intersection(obj1[CONSTANTS.DATASET_SUFFIX_COLS], obj2[CONSTANTS.DATASET_SUFFIX_COLS]).length > 0
        && _.isEqual(obj1[CONSTANTS.DATASET_SUFFIX_KEY_ROOTS], obj2[CONSTANTS.DATASET_SUFFIX_KEY_ROOTS]);
}

export function findIntersectParams(paramSet1, paramSet2) {
  const intersect = [];
  paramSet1.forEach((param1) => {
    paramSet2.forEach((param2) => {
      if (isOverlap(param1, param2)) {
        intersect.push(param2);
      }
    });
  });
  return intersect;
}

export function findIntersectParams2(paramSet) {
  const intersect = [];
  for (let i = 0, len = paramSet.length; i < len; i++) {
    for (let j = i + 1; j < len; j++) {
      if (isOverlap(paramSet[i], paramSet[j])) {
        intersect.push(paramSet[j]);
      }
    }
  }
  return intersect;
}

export function groupBubbles(datasets) {
  const visited = [];
  const groups = [];
  datasets.forEach((sub) => {
    const bubble = sub._bbl;
    if (!visited[bubble._id]) {
      if (bubble._type == 'save') {
        const relatedInputBubbles = [];
        const relatedOutputBubbles = [];
        dfs(bubble, visited, relatedInputBubbles, relatedOutputBubbles);
        groups.push({ input: relatedInputBubbles, output: relatedOutputBubbles });
      }
    }
  });
  return groups;
}

export function findIntersectBubbles(bubbleGroups, psgSets, bubbleNames) {
  const warnItems = [];
  psgSets.forEach((psgSet) => {
    bubbleGroups.forEach((item) => {
      const inputBubbles = item['input'];
      const outputBubbles = item['output'];
      const inputParams = convertParams(inputBubbles, psgSet, bubbleNames);
      const outputParams = convertParams(outputBubbles, psgSet, bubbleNames);
      if (inputParams.length > 0 && outputParams.length > 0) {
        const overlapParam = findIntersectParams(inputParams, outputParams);
        // comment the duplicate output checks since there is a special case in CMD-241
        // let overlapOutParam = findIntersectParams2(outputParams);
        const overlapOutParam = [];

        if (overlapParam.length > 0 || overlapOutParam.length > 0) {
          warnItems.push({
            setName: psgSet[CONSTANTS.PARAM_SET_NAME],
            groupLen: bubbleGroups.length,
            outputLen: outputBubbles.length,
            inputLen: inputBubbles.length,
            overlap: overlapParam,
            overlapOut: overlapOutParam,
          });
        }
      }
    });
  });

  return warnItems;
}

export function hasOverwriteData(datasets, psgSets) {
  if (datasets.length <= 1) {
    return;
  }

  const groups = groupBubbles(datasets);

  const bubbleNames = {};
  datasets.forEach((sub) => {
    bubbleNames[sub._bbl._id] = sub._name;
  });

  const warnItems = findIntersectBubbles(groups, psgSets, bubbleNames);

  const errorParamSet = {};
  const warnParamSet = {};
  const outParamSet = {};
  warnItems.forEach((item) => {
    const setName = item['setName'];
    if (item['overlap'].length > 0) {
      if (item['overlap'].length == item['inputLen']) {
        const dataSet = errorParamSet[setName] = errorParamSet[setName] || new Set();
        // hidden the data set name if there is only one output bubbles.
        if (item['groupLen'] > 1 || item['outputLen'] > 1) {
          item['overlap'].forEach(e => dataSet.add(e[CONSTANTS.DATASET_NAME]));
        }
        errorParamSet[setName] = dataSet;
      } else {
        const dataSet = warnParamSet[setName] || new Set();
        // hidden the data set name if there is only one output bubbles.
        if (item['groupLen'] > 1 || item['outputLen'] > 1) {
          item['overlap'].forEach(e => dataSet.add(e[CONSTANTS.DATASET_NAME]));
        }
        warnParamSet[setName] = dataSet;
      }
    }
    if (item['overlapOut'].length > 0) {
      const dataSet = outParamSet[setName] || new Set();
      item['overlapOut'].forEach(e => dataSet.add(e[CONSTANTS.DATASET_NAME]));
      outParamSet[setName] = dataSet;
    }
  });

  const errorParam = [];
  Object.keys(errorParamSet).forEach((key) => {
    const values = errorParamSet[key];
    if (values.size > 0) {
      errorParam.push(`${key } ( ${ Array.from(errorParamSet[key]).join(',') } ) `);
    } else {
      errorParam.push(key);
    }
  });

  let errorMsg = '';
  if (errorParam.length) {
    errorMsg = `Parameter Set: ${ errorParam.join(', ') } contains an input that is being saved in a loop with a duplicate output.`;
  }

  const errorOutParam = [];
  Object.keys(outParamSet).forEach((key) => {
    errorOutParam.push(`${key } ( ${ Array.from(outParamSet[key]).join(',') } ) `);
  });

  if (errorOutParam.length) {
    errorMsg += `\nParameter Set: ${ errorOutParam.join(', ') } contains multiple outputs that are duplicates.`;
  }

  const warnParam = [];
  Object.keys(warnParamSet).forEach((key) => {
    const values = warnParamSet[key];
    if (values.size > 0) {
      warnParam.push(`${key } ( ${ Array.from(warnParamSet[key]).join(',') } ) `);
    } else {
      warnParam.push(key);
    }
  });

  let warnMsg = '';
  if (warnParam.length) {
    warnMsg = `Parameter Set: ${ warnParam.join(', ') } contains an input that is being saved in a loop with a duplicate output.`;
  }

  return { warn: warnMsg, error: errorMsg };
}

const BubbleUtils = {
  dfs,
  datasetPrefix,
  convertParams,
  isOverlap,
  findIntersectParams,
  findIntersectParams2,
  groupBubbles,
  findIntersectBubbles,
  hasOverwriteData,
};

export default BubbleUtils;

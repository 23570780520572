/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-restricted-syntax */

import Dates from './dates/dates.es6';
import { isString } from './strings.es6';
import Arrays from './arrays.es6';

/**
 * @typedef Object Console.Record
 * @property {string} type Log type
 * @property {string} msg Message
 * @property {int} timestamp Timestamp
 */

/** @type {Console.Record[]} */
const _hist = [];

/**
 * Day of month.
 * @type {int}
 */
let _lastDay = -1;

function _noop() {}

/**
 *
 * @param {string} t Type
 * @param {int} ts Timestamp
 * @param {string} msg Message
 * @returns {Console.Record} New record.
 * @private
 */
function _newRec(t, ts, msg) {
  return {
    type: t,
    msg,
    timestamp: ts,
  };
}

/** @returns {string} String representation of `arg`, never fails. */
function _argToString(arg) {
  const typeOf = typeof arg;

  if (typeOf === 'string') return arg;
  if (typeOf === 'undefined') return 'undefined';
  if (arg === null) return 'null';
  if (arg instanceof Date) return Dates.utcDateToIsoString(arg, 6);
  if (arg instanceof Array) return arg.join(',');
  return arg.toString();
}

/**
 *
 * @param {(Array|Arguments)} args
 * @returns {string} Message built from the given arguments.
 * @private
 */
function _msgToString(args) {
  const numArgs = args.length;
  if (numArgs < 1) { return ''; }

  let msg = args[0];

  if (numArgs === 1
        || typeof msg !== 'string') { return _argToString(msg); }

  let hasExtra = false;
  let phIdx = -1;
  let startIdx = 0;
  let arg;

  for (let i = 1; i < numArgs; i++) {
    arg = _argToString(args[i]);

    phIdx = ((hasExtra === false)
      ? msg.indexOf('{}', startIdx)
      : -1);

    if (phIdx >= 0) {
      msg = msg.substring(0, phIdx)
                    + arg
                    + msg.substring(phIdx + 2);
      startIdx = phIdx + arg.length;
    } else {
      if (hasExtra === false) {
        hasExtra = true;
        msg += ' [';
      } else { msg += ', '; }

      msg += arg;
    }
  }

  if (hasExtra === true) { msg += ']'; }

  return msg;
}

function _add(t, msg, ms) {
  const x = _hist.length - 10000; // Only keep the last 10000 records.
  const msgStr = ((typeof msg === 'object'
                    && msg !== null)
    ? msg.toString()
    : msg);

  _hist.push(_newRec(t, ms, msgStr));

  if (x > 0) { _hist.splice(0, x); }
}

function _newNoop(logType) {
  if (logType !== 'DEBUG') {
    return function (msg) {
      _add(logType, _msgToString(arguments), Dates.now());
    };
  }
  return _noop;
}

function _bindToConsole(logType, fnName) {
  const isDebug = (logType === 'DEBUG');

  return function (msg) {
    const time = Dates.now();
    const dt = new Date(time);
    const ts = Dates.timeToString(dt, 3);
    const msgStr = _msgToString(arguments);

    if (_lastDay !== dt.getDate()) {
      _lastDay = dt.getDate();
      console[fnName](`[${ Dates.dateToIsoString(dt, 2) }]`);
    }

    console[fnName](`${ts } - ${ msgStr}`);

    if (!isDebug) { _add(logType, msgStr, time); }
  };
}

function _newLogFn(name) {
  const logType = name.toUpperCase();

  if (typeof console !== 'object') return _newNoop(logType);

  if (typeof console[name] !== 'undefined') return _bindToConsole(logType, name);

  if (typeof console['log'] !== 'undefined') {
    // Fall back to "log()" if the function we want isn't available.
    return _bindToConsole(logType, 'log');
  }

  return _newNoop(logType);
}

const Console = Object.freeze(/** @lends {Console} */ {

  debug: _newLogFn('debug'),
  log: _newLogFn('log'),
  info: _newLogFn('info'),
  warn: _newLogFn('warn'),
  error: _newLogFn('error'),

  /**
     * @returns {string[]} History of logs with timestamp prefix.
     */
  getHistory() {
    const a = [];
    let day = -1;

    for (const rec of _hist) {
      const dt = new Date(rec.timestamp);

      if (day !== dt.getDate()) {
        a.push(Dates.dateToIsoString(dt, 2));
        day = dt.getDate();
      }

      a.push(`${Dates.timeToString(dt, 4) }  ${ rec.type }  ${ rec.msg}`);
    }
    return a;
  },

  /**
     * Adds a line-item to the console history.
     * @param {string} type Logging type: ERROR, WARN, INFO, LOG, DEBUG.
     * @param {string} msg Log message, may contain markers ("{}").
     * @param {...(string|number|boolean|Object|*)} values Values used to replace markers
     *        found in `msg`.
     */
  addToHistory(type, msg, values) {
    if (arguments.length >= 2
            && isString(type)
            && isString(msg)) {
      _add(
        type.toUpperCase(),
        _msgToString(Arrays.slice(arguments, 1)),
        Dates.now(),
      );
    } else {
      Console.warn('Bad call to Console.addToHistory could not be logged.');
    }
  },
});

export default Console;

import Vue from 'vue';
import Vuex from 'vuex';

import loginModule from './modules/login';
import homeModule from './modules/home';
import researchReports from './modules/researchReports';
import feedModule from './modules/feed';
import navigation from './modules/navigation';
import groupModule from './modules/group';
import workspaceModule from './modules/workspace';
import bloombergModule from './modules/bloomberg';
import auditLogModule from './modules/auditLog';
import userModule from './modules/user';
import dashboardModule from './modules/dashboard';
import reportsModule from './modules/reports';
import schedulerModule from './modules/scheduler';
import workflowModule from './modules/workflow';


Vue.use(Vuex);

// actions with no Payload
const actionMap = {
  toggleRememberMe: 'loginModule/toggleRememberMe',
  signInSuccess: 'loginModule/signInSuccess',
  signInFailure: 'loginModule/signInFailure',
  signOut: 'loginModule/signOut',
};

// mapper to actions with no payload
function getAction(actionName) {
  return actionMap[actionName] || actionName;
}

export default new Vuex.Store({
  state: {
    appVersion: process.env.VUE_APP_VERSION,
  },
  modules: {
    loginModule,
    homeModule,
    researchReports,
    feedModule,
    navigation,
    groupModule,
    workspaceModule,
    bloombergModule,
    auditLogModule,
    userModule,
    dashboardModule,
    reportsModule,
    schedulerModule,
    workflowModule,
  },
  mutations: {},
  actions: {
    dispatchAction({ dispatch }, payload) {
      if (typeof payload === 'string') {
        return dispatch(getAction(payload));
      }
      const { actionName } = payload;
      if (!actionName) {
        throw new Error(
          'dispatchAction payload must have an actionName property',
        );
      }
      const newPayload = JSON.parse(JSON.stringify(payload.data));
      return dispatch(getAction(actionName), newPayload);
    },
  },
  getters: {
    appVersion: state => state.appVersion,
  },
});

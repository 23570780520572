export const ThemeMixin = {
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
};

export default ThemeMixin;

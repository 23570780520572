import axios from 'axios';
import { formatLDSURL } from '../../../../server/util/stringUtils';
import templateString from '../../../utils/stringUtils';
import getAPIConfigs from './config.js';

const config = getAPIConfigs();
let replacement;

const actions = {
  getCompanyList({ commit }) {
    return axios
      .get(templateString(config.GET_ALL_COMPANIES))
      .then((response) => {
        commit('SET_COMPANY_LIST', response.data);
        return response;
      })
      .catch(errors => errors);
  },
  getActiveMpUser({ commit }) {
    return axios
      .get(formatLDSURL('/marketplace/checkUser'), { withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          commit('SET_CURRENT_MP_USER', response.data.username);
        } else {
          commit('SET_CURRENT_MP_USER', '');
        }
        return response;
      }).catch((error) => {
        commit('SET_CURRENT_MP_USER', '');
      });
  },
  getWorkflowReports({ commit }, company) {
    const reqPayload = {
      companies: [company],
    };

    return axios
      .post(templateString(config.GET_WORKFLOW_REPORTS), reqPayload).then((response) => {
        commit('SET_WORKFLOW_REPORTS', response.data);
        return response;
      }).catch(errors => errors);
  },
  getWorkflowReportsForCompAdmins({ commit }) {
    return axios
      .get(templateString(config.GET_WORKFLOW_REPORTS)).then((response) => {
        commit('SET_WORKFLOW_REPORTS', response.data);
        return response;
      }).catch(errors => errors);
  },
};


export default actions;

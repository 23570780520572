<template>
  <div>
    <div class="mds-doc-homepage__row-inner">
      <div
        class="mds-top-hat mds-top-hat--error"
        role="alert"
      >
        <div class="mds-top-hat__body">
          <svg
            class="mds-icon mds-top-hat__icon mds-icon--large"
            aria-hidden="true"
          >
            <use xlink:href="#alert" />
          </svg>
          <h2>&nbsp;{{ $t('labels.error.pageNotFound') }}</h2>
        </div>
      </div>
    </div>
    <p class="text-center">
      {{ $t('labels.error.pageNotFoundMessage') }}
    </p>
  </div>
</template>

<script>

export default {
  name: 'PageNotFound',
};
</script>

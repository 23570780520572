<template>
  <div
    :ref="`chart${feedName+1}`"
  >
    <mds-form
      size="small"
      class="chart-widget-form-spacer"
    >
      <div class="chart-controls-container">
        <mds-button
          :on-dark="darkMode"
          variation="primary"
          class="add-data-btn"
          @click.prevent="openNavigation"
        >
          {{ $t('dashboard.widgets.navigation.addData') }}
        </mds-button>
      </div>
      <mds-button-group
        :on-dark="darkMode"
        class="intervals-btn-group flex-item"
        full-width
        :content="btnGroups"
        :size="'small'"
        @mds-button-group-item-active="handleIntervalToggle"
      />
      <dashboard-chart-section
        class="flex-item"
        :chart-data="TSData"
        :dark-mode="darkMode"
        :x-axis-type="xAxisType"
        :loading-status="loadingStatus"
      />
    </mds-form>
    <feed-navigation-modal
      ref="navigationModal"
      :dark-mode="darkMode"
      :selected-rows="selectedRows"
      @handleSelectedSymbols="handleSelectedSymbols"
    />
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import MdsForm from '@mds/form';
import MdsButtonGroup from '@mds/button-group';
import { MdsButton } from '@mds/button';
import DashboardChartSection from '@/components/Dashboard/Widgets/ChartWidget/DashboardChartSection';
import {
  btnGroupOptions, computeIntervalData, computeRollupStrategy, xAxisPerData,
} from './chartHelper';
import { ThemeMixin } from '../../../../mixins/themeMixin';
import FeedNavigationModal from '../../Modals/FeedNavigationModal';

export default {
  name: 'DashboardChartWidget',
  components: {
    DashboardChartSection,
    FeedNavigationModal,
    MdsForm,
    MdsButtonGroup,
    MdsButton,
  },
  mixins: [ThemeMixin],
  props: {
    widgetData: {
      type: Object,
      default: undefined,
    },
    parentTabId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedRows: [],
      btnGroups: btnGroupOptions(),
      timeSeriesArray: null,
      loadingStatus: '',
      id: 'MarketsTimeSeries',
      feedDefinition: {},
      feedName: '',
      keyName: '',
      keyValue: '',
      selectedGraphType: 'line',
      selectedInterval: '1D',
      selectedSymbols: [],
      intervalID: '',
    };
  },
  computed: {
    connectivityModeColor() {
      if (this.isRtOnline && this.selectedInterval === '1D') {
        return 'green';
      }
      return 'yellow';
    },
    popoverText() {
      if (this.isRtOnline && this.selectedInterval === '1D') {
        return this.$t('labels.dashboard.realTimeLabel');
      }
      return this.$t('labels.dashboard.refreshLabel');
    },
    TSData() {
      return this.timeSeriesArray;
    },
    xAxisType() {
      let rollupType = '';
      if (!this.selectedSymbols.length <= 0) {
        rollupType = computeRollupStrategy(this.selectedInterval,
          this.selectedSymbols[0].feedDefinition['timeUnits']);
        if (rollupType === 'minute' || rollupType === 'second') {
          rollupType = 'hour';
        }
      }
      return xAxisPerData[`${rollupType}-${this.selectedInterval}`];
    },
  },
  watch: {
    selectedInterval: {
      handler(val, oldVal) {
        // when btn interval changes refresh chart
        this.selectedInterval = val;
        this.fetchChartData();
      },
      nextTick: true, // refresh in the next repaint
    },
  },
  methods: {
    filterComma(symbolVal) {
      if (symbolVal.includes(',')) {
        symbolVal = symbolVal.replaceAll(',', '&');
      }
      return symbolVal;
    },
    fetchChartData() {
      try {
        this._getTimeSeries();
      } catch (e) {
        this.loadingStatus = 'Error';
      }
    },
    handleIntervalToggle(e) {
      clearTimeout(this.intervalID);
      this.btnGroups.forEach((item) => {
        if (item.id === e.target.id) {
          item.active = true;
          this.selectedInterval = item.id;
        } else {
          item.active = false;
        }
      });
      this.$emit('input:intervalType', e.target.id); // each btn group ID
    },
    async _getTimeSeries(isRefresh = false) {
      if (this.selectedSymbols.length) {
        if (isRefresh) {
          this.loadingStatus = 'Refreshing';
        } else {
          this.loadingStatus = 'Loading';
        }
        const dt = moment().format('YYYY-MM-DD');
        const intervalDate = computeIntervalData(this.selectedInterval).format('YYYY-MM-DD');
        const urlsArr = [];

        for (let i = 0, len = this.selectedSymbols.length; i < len; i++) {
          urlsArr.push('/api/getTimeSeries/?'
            + `feedName=${this.selectedSymbols[i].feed}`
            + `&rollupStrategy=${computeRollupStrategy(this.selectedInterval,
              this.selectedSymbols[i].feedDefinition['timeUnits'])}`
            + `&fromDateTime=${intervalDate}`
            + `&toDateTime=${dt}`
            + '&version=v2'
            + `&symbol=${this.filterComma(this.selectedSymbols[i].symbol)}`
            + `&cols=${this.selectedSymbols[i].selectedColumns
              ? this.selectedSymbols[i].selectedColumns
              : this.selectedSymbols[i].columns.flatMap(item => item.value).join()
            }`);
        }

        await axios.all(urlsArr.map(l => axios.get(l)))
          .then(axios.spread((...res) => {
            // all requests are now complete
            // merge both objs to be in one for the chart
            const merged = res.reduce((acc, item) => {
              // acc = { ...acc, ...item.data };
              acc.push(...item.data);
              return acc;
            }, []);

            this.timeSeriesArray = { y1: [...merged] };
            this.loadingStatus = 'Success';
          }))
          .catch((error) => {
            console.log(error);
          });
        // if its not the first window we use Refresh
        if (this.selectedInterval !== '1D') {
          this.intervalID = setTimeout(this._getTimeSeries.bind(this, true), 300000);
        }
      }
    },
    handleSelectedSymbols(data) {
      this.selectedSymbols = data;
      this.fetchChartData();
    },
    openNavigation() {
      this.$refs.navigationModal.$refs.navigationModal.show();
    },
  },
};
</script>

<style lang="scss">
.flex-item{
  flex:1;
  margin:0 25px 25px 0;
}
.chart-widget-form-spacer{
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  justify-content: space-around;
}
.chart-controls-container{
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px;
  margin: 0 10px;
}
</style>

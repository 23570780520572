const config = {
  USER_CONFIG_API: '/api/bb/config',
  USER_CONFIG_LIST_API: 'api/bb/config/list/$[USER_NAME]',
  CONFIG_DETAILS_API: 'api/bb/config/$[CONFIG_NAME]',
  USER_ACCOUNT_API: 'api/bb/account',
  USER_ACCOUNT_LIST_API: 'api/bb/account/list',
  USER_ACCOUNT_DETAILS_API: 'api/bb/account/$[ACCOUNT_NAME]',
  USER_YELLOW_KEY_API: 'api/bb/yellowkey',
  USER_YELLOWKEY_LIST_API: 'api/bb/yellowkey/list',
  USER_YELLOWKEY_DETAILS_API: 'api/bb/yellowkey/$[KEY_NAME]',
  USER_YELLOWKEY_MAPPINGS_API: 'api/bb/yellowkey/mappings/$[MAPPING_NAME]',
};

export default function getAPIConfigs() {
  return config;
}

<template>
  <div>
    <div
      id="mds-overlay"
      :class="{ 'mds-overlay': saveGroupsPopOver || loader || showNotification }"
    />
    <div
      v-if="overlay"
      id="overlayDiv"
      class="overlay"
    />
    <template v-if="pageStatus == 'SUCCESS'">
      <feed-group-pop-over
        :feed-groups="feedGroups"
        :feed-groups-orig="feedGroupsOrig"
      />
      <div
        id="mds-modal"
        class="mds-modal  mds-modal--600px mds-modal--width-600px"
        :class="{ 'mds-modal--open  mds-modal--active': showNotification }"
      >
        <feed-group-update-response
          :group-save-success="groupSaveSuccess"
          :error-list="errorList"
        />
      </div>
      <div class="mds-header border-bottom-solid">
        <h1 class="mds-header__title component-title">
          {{ $t('labels.feedGroup.feedGroup') }}
        </h1>
        <div class="mds-header__actions">
          <add-feed-group-pop />
          <router-link
            :to="{ path: 'feeds' }"
            class="mds-list-group__link hide-actions"
            replace
          >
            <button
              class="mds-button mds-button--flat"
              type="button"
            >
              <svg
                class="mds-icon mds-button__icon mds-button__icon--left"
                aria-hidden="true"
              >
                <use xlink:href="#list" />
              </svg>
              <span class="mds-button__text mcd-page__content-hide--600">{{
                $t('labels.feed.feedList')
              }}</span>
            </button>
          </router-link>
        </div>
      </div>
      <div style="margin-top:30px;">
        <template v-if="loader">
          <div v-show="loader == true">
            <mds-loader />
          </div>
        </template>
        <template v-else>
          <div
            v-for="(group, index) in feedGroups"
            :key="'group' + index"
            style="margin-top:15px;"
            class="mds-accordion"
          >
            <input
              :id="'group' + index"
              :ref="'group' + index"
              type="checkbox"
              :checked="group.checked"
              aria-controls="groupAccordian"
              class="mds-accordion__trigger"
              @click="getFeedsList(group.groupName, index)"
            >
            <label
              :for="'group' + index"
              class="mds-accordion__label"
            >
              <div class="mds-header  mds-header--level-4">
                <h2 class="mds-header__title ent-header">{{ group.groupName }}</h2>
              </div>
            </label>
            <section v-show="group.checked">
              <div v-show="feedGroups[index].loader == true">
                <mds-loader />
              </div>
              <template
                v-if="feedGroups[index].feedNames && feedGroups[index].feedNames.length > 0"
              >
                <table class="mds-data-table mds-data-table--large">
                  <tbody class="mds-data-table__body">
                    <tr
                      v-for="(user, index2) in feedGroups[index].feedNames"
                      :key="index2"
                      class="mds-data-table__row"
                    >
                      <td
                        class="mds-data-table__cell"
                        v-html="user"
                      />
                      <td class="mds-data-table__cell mds-data-table__cell--right">
                        <mds-button
                          variation="icon-only"
                          icon="remove"
                          @click="removeUserGroup(group, index2)"
                        >
                          {{ $t('labels.group.removeUserButton') }}
                        </mds-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <template v-else-if="!group.loader">
                <label>{{ $t('labels.feedGroup.noFeedWarning') }}</label>
              </template>
              <feed-group-action
                :group="group"
                :index="index"
                :user-feeds="userFeeds"
              />
            </section>
            <hr>
          </div>
        </template>
      </div>
      <div class="mds-button__container">
        <mds-button
          icon="caret-circle-left"
          variation="secondary"
          class="group-back-button"
          type="button"
          @click="goBack"
        >
          {{ $t('labels.group.groupBackButton') }}
        </mds-button>
        <mds-button
          icon="save"
          variation="primary"
          class="group-save-button"
          type="button"
          @click="saveGroupsPop()"
        >
          {{ $t('labels.group.saveGroupButton') }}
        </mds-button>
      </div>
    </template>
    <template v-else-if="pageStatus == 'ERROR'">
      <div class="details-error">
        <mds-notification
          visible
          title="ERROR"
          status="error"
          dismissible="false"
        >
          {{ errorMessage }}
        </mds-notification>
      </div>
    </template>
    <template v-else-if="pageStatus == 'LOADING'">
      <mds-loader />
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import LayoutDefault from '../Layouts/LayoutDefault.vue';
import EventBus from '../../main';
import AddFeedGroupPop from './AddFeedGroupPop';
import FeedGroupAction from './FeedGroupAction';
import FeedGroupPopOver from './FeedGroupPopOver';
import FeedGroupUpdateResponse from './FeedGroupUpdateResponse';

export default {
  name: 'FeedGroup',
  components: {
    AddFeedGroupPop,
    FeedGroupAction,
    FeedGroupPopOver,
    FeedGroupUpdateResponse,
  },
  data() {
    return {
      loader: true,
      groupChanges: new Map(),
      saveGroupsPopOver: false,
      feedGroupsOrig: [],
      groupSaveSuccess: false,
      showNotification: false,
      errorList: [],
      curResponseCount: 0,
      overlay: false,
    };
  },
  computed: {
    ...mapGetters({
      errorMessage: 'feedModule/getGroupErrorMessage',
      pageStatus: 'feedModule/getFeedGroupPageStatus',
      feedGroups: 'feedModule/getFeedGroups',
      userFeeds: 'feedModule/getFeedsForFeedGroup',
    }),
  },
  created() {
    this.setFeedGroups();
    this.getUserFeedsForFeedGroup();
    EventBus.$emit('layoutChange', LayoutDefault);
    EventBus.$on('hideOverLay', (value) => {
      this.overlay = value;
    });
  },
  mounted() {
    EventBus.$on('addGroup', this.addGroup);
  },
  methods: {
    ...mapActions('feedModule', [
      'getFeedGroups',
      'getGroupFeedsList',
      'saveGroupChanges',
      'getUserFeedsForFeedGroup',
      'getGroupEntitlements',
    ]),
    saveGroupsPop() {
      EventBus.$emit('saveGroupPop', FeedGroupPopOver);
    },
    goBack() {
      this.$router.push({
        path: '/feeds',
      });
      this.$store.commit('feedModule/SET_PAGE_STATUS', 'LOADING');
    },
    setFeedGroups() {
      this.getFeedGroups().then((response) => {
        this.loader = true;
        this.feedGroupsOrig = [];
        this.feedGroups.forEach((group) => {
          const feedNames = group.feedNames.slice();
          group.isSaved = true;
          this.feedGroupsOrig.push({
            groupName: group.groupName,
            feedNames,
            loaded: group.loaded,
            loader: group.loader,
            checked: group.checked,
          });
        });
        this.loader = false;
      });
    },
    getFeedsList(group, index) {
      const groupObj = this.feedGroups[index];
      groupObj.checked = !groupObj.checked;
      if (!groupObj.loaded) {
        this.feedGroups[index].loader = true;
        this.getGroupFeedsList(group).then((response) => {
          this.feedGroups[index].loader = false;
          this.feedGroupsOrig[
            this.getGroupIndex(this.feedGroupsOrig, groupObj.groupName)
          ].feedNames = this.feedGroups[index].feedNames == null
            ? []
            : this.feedGroups[index].feedNames.slice();
        });
      }
    },
    removeUserGroup(group, index) {
      group.feedNames.splice(index, 1);
    },
    addGroup(groupName) {
      if (this.getGroupIndex(this.feedGroups, groupName) < 0) {
        this.feedGroups.unshift({
          groupName,
          feedNames: [],
          loaded: true,
          loader: false,
          checked: true,
          isSaved: false,
        });
      }
    },
    getGroupIndex(groupList, groupName) {
      for (let index = 0; index < groupList.length; index += 1) {
        if (groupList[index].groupName.trim().toLowerCase() === groupName.trim().toLowerCase()) {
          return index;
        }
      }
      return -1;
    },
    deleteGroup(index) {
      this.feedGroups[index].loaded = false;
      this.feedGroups[index].checked = false;
      this.feedGroups.splice(index, 1);
    },
    closeNotification() {
      this.showNotification = false;
      this.$router.go(0);
    },
    saveGroups(groupChanges) {
      this.groupSaveSuccess = true;
      this.saveGroupsPopOver = false;
      this.loader = true;
      const totalReqCount = groupChanges.get('ADD').length
        + groupChanges.get('UPDATE').length
        + groupChanges.get('DELETE').length;
      if (totalReqCount === 0) {
        this.loader = false;
      }
      this.curResponseCount = 0;
      const actionArr = ['ADD', 'UPDATE', 'DELETE'];
      for (let actionCount = 0; actionCount < actionArr.length; actionCount += 1) {
        const actionName = actionArr[actionCount];
        let { params } = {};
        for (let i = 0; i < groupChanges.get(actionName).length; i += 1) {
          const { groupName } = groupChanges.get(actionName)[i];
          let groupIndex;
          let groupObj = {};
          if (actionName === 'ADD' || actionName === 'UPDATE') {
            groupIndex = this.getGroupIndex(this.feedGroups, groupName);
            groupObj = this.feedGroups[groupIndex];
            if (groupObj.isSaved) {
              this.getGroupEntitlements(groupName).then((response) => {
                if (response.status === 200) {
                  groupObj.users = response.data;
                  this.postOrPutGroup(groupObj, actionName, groupName, totalReqCount);
                } else if (response.status === 500) {
                  EventBus.$emit('showErrorNotification');
                } else if (response.status >= 400) {
                  EventBus.$emit('showWarningNotification');
                }
              });
            } else {
              this.postOrPutGroup(groupObj, actionName, groupName, totalReqCount);
            }
          } else if (actionName === 'DELETE') {
            groupObj = groupChanges.get(actionName)[i];
            const entGroup = {
              name: groupObj.groupName,
              feeds: groupObj.feedNames,
            };
            params = {
              action: actionName,
              value: entGroup,
            };
            this.saveGroupChangesApi(groupObj, groupName, params, totalReqCount);
          }
        }
      }
    },
    postOrPutGroup(groupObj, actionName, groupName, totalReqCount) {
      const entGroup = {
        name: groupObj.groupName,
        feeds: groupObj.feedNames,
        users: groupObj.users,
      };
      const params = {
        action: actionName,
        value: entGroup,
      };
      this.saveGroupChangesApi(groupObj, groupName, params, totalReqCount);
    },
    saveGroupChangesApi(groupObj, groupName, params, totalReqCount) {
      this.saveGroupChanges(params).then((response) => {
        if (response.status !== 200) {
          this.groupSaveSuccess = false;
          this.errorList.push({
            groupName: groupObj && groupName != null ? groupName : groupObj.groupName,
            message:
              response.status
              + (response.data.errorMessage == null
                ? response.statusText
                : response.data.errorMessage),
          });
        }
        this.curResponseCount += 1;
        if (this.curResponseCount === totalReqCount) {
          this.loader = false;
          this.showNotification = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.group-save-button {
  float: right !important;
  margin-top: 20px;
}
.group-back-button {
  margin-top: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>

import Arrays from './arrays.es6';
import { requireScriptVar } from './../utils/script/scriptvar.es6';
import { requireNonEmptyString } from './strings.es6';

/**
 * A variable stack frame, contains variables.
 * @constructor
 * @name ScriptVarStackFrame
 * @private
 */
class Frame {
  constructor() {
    /** @type {ScriptVar[]} */
    this._vars = [];
    Object.freeze(this);
  }

  /**
     * Sets a variable in this frame.  If a variable of the same name
     * already exists, it is overwritten with the new one.
     * @param {ScriptVar} scriptVar
     */
  set(scriptVar) {
    requireScriptVar(scriptVar, 'scriptVar');
    const list = this._vars;
    // Remove any existing variable of the same name.
    Arrays.remove(scriptVar.name(), list, 'name()');
    // Add new variable, at the end.
    list.push(scriptVar);
  }

  /**
     * Returns the ScriptVar of the given name.  If the named variable
     * cannot be found, this method returns `fallbackValue` if provided;
     * Otherwise it throws.
     *
     * @param {string} varName
     * @param {*} [fallbackValue]
     * @returns {(ScriptVar|*)}
     */
  get(varName, fallbackValue) {
    requireNonEmptyString(varName, 'varName');
    return this._getScriptVar(...arguments);
  }

  /** @returns {int} Number of variables currently set in this frame. */
  numVars() {
    return this._vars.length;
  }

  /**
     * Returns the ScriptVar of the given name.  If the named variable
     * cannot be found, this method returns `fallbackValue` if provided;
     * Otherwise it throws.
     *
     * @param {string} varName
     * @param {*} [fallbackValue]
     * @returns {(ScriptVar|*)}
     * @private
     */
  _getScriptVar(varName, fallbackValue) {
    for (const scriptVar of this._vars) {
      if (scriptVar.name() === varName) {
        return scriptVar;
      }
    }
    if (arguments.length > 1) {
      return fallbackValue;
    }
    throw new Error(`Variable not found in stack-frame: ${ varName}`);
  }
}

/**
 * A variable stack.
 * @constructor
 * @name ScriptVarStack
 */
export default class Stack {
  constructor() {
    /** @type {ScriptVarStackFrame[]} */
    this._frames = [];
    Object.freeze(this);
  }

  /**
     * Creates a new stack-frame, sets that frame as *current* and returns it.
     * @returns {ScriptVarStackFrame}
     */
  push() {
    const frame = new Frame();
    this._frames.unshift(frame);

    return frame;
  }

  /**
     * Creates a new stack-frame, pushes it at the bottom of the stack and returns it.
     * @returns {ScriptVarStackFrame}
     */
  pushAtBottom() {
    const frame = new Frame();
    this._frames.push(frame);

    return frame;
  }

  /**
     * Removes current stack-frame, setting previous frame as "current" (if any).
     * @returns {ScriptVarStackFrame}
     * @throws IllegalStateException If the stack is empty.
     */
  pop() {
    return this._getFrames().shift();
  }

  /**
     * Sets a variable into the current frame in the stack.
     * If a variable of the same name already exists in that frame,
     * it is overwritten with the new one.
     *
     * Equivalent to `topFrame().set(scriptVar)`.
     *
     * @param {ScriptVar} scriptVar
     * @throws IllegalStateException If the stack is empty.
     */
  setVar(scriptVar) {
    this._getFrames()[0].set(scriptVar);
  }

  /**
     * Returns the ScriptVar of the given name.  If the named variable
     * cannot be found, this method returns `fallbackValue` if provided;
     * Otherwise it throws.
     *
     * @param {string} varName
     * @param {*} [fallbackValue]
     * @returns {(ScriptVar|*)}
     */
  get(varName, fallbackValue) {
    requireNonEmptyString(varName, 'varName');

    let foundVar = null;
    const frames = this._frames;

    let i = 0;
    const len = frames.length;
    for (;
      i < len && foundVar === null;
      i++) {
      foundVar = frames[i]._getScriptVar(varName, null);
    }

    if (foundVar !== null) return foundVar;

    if (arguments.length > 1) return fallbackValue;

    throw new Error('IllegalArgumentException: `varName` not found in stack');
  }

  /**
     * Returns the number of frames in this stack.
     * @returns {int}
     */
  size() {
    return this._frames.length;
  }

  /**
     * Returns the top frame (aka current frame).
     * @returns {ScriptVarStackFrame}
     */
  topFrame() {
    return this._getFrames()[0];
  }

  /**
     * Returns the bottom frame (aka global context).
     * This could be the current frame.
     * @returns {ScriptVarStackFrame}
     */
  bottomFrame() {
    const frames = this._getFrames();
    return frames[frames.length - 1];
  }

  /**
     * Returns the number of variables currently set in this stack.
     * @returns {int}
     */
  numVars() {
    // Ah... the silliness we do to get JS code recognized by an IDE.
    let total = 0;
    for (const frame of this._frames) {
      total += frame.numVars();
    }
    return total;
  }

  /**
     * @returns {ScriptVarStackFrame[]} Non-empty list of frames associated with a Stack.
     * @throws IllegalStateException If var stack is empty.
     * @private
     */
  _getFrames() {
    if (this._frames.length === 0) {
      throw new Error('IllegalStateException: stack is empty');
    }
    return this._frames;
  }
}

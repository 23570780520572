<template>
  <div class="copy-scheduler">
    <div
      v-if="overlay"
      id="mds-overlay"
      class="mds-overlay"
    />
    <mds-loader
      v-show="showloader"
      class="div-centered"
      size="large"
    />
    <mds-row>
      <mds-col :cols="12">
        <form
          class="mds-form mds-form--medium-layout custom_form"
          method=""
          action=""
        >
          <div class="mds-form__fieldset">
            <mds-row>
              <mds-col>
                <mds-row>
                  <mds-col :cols="4">
                    <div @click="nullifyCTNSch(false)">
                      <mds-radio-button
                        v-model="schCopyNewNExistingRadio"
                        :checked="schCopyNewNExistingRadio == '1'"
                        :size="size"
                        name="schCopyNewNExistingRadio"
                        value="1"
                      >
                        {{ $t('labels.schedulerManagement.copyToNewSch') }}
                      </mds-radio-button>
                    </div>
                  </mds-col>
                </mds-row>
                <mds-row
                  class="pading-top_1rem"
                >
                  <mds-col :cols="3">
                    <label
                      class="mds-form__label pading-top_1rem padding-left_15rem"
                      for="datasource"
                    >{{ $t('labels.schedulerManagement.schName') }}
                    </label>
                  </mds-col>
                  <mds-col class="pading-top_05rem">
                    <input
                      id="schName"
                      v-model="schName"
                      class="mds-form__input"
                      type="text"
                      maxlength="100"
                      placeholder="Schedule Name"
                      :disabled="schCopyNewNExistingRadio !== '1'"
                      @focusin="schPatternError = scheduleExists = schNameError = false"
                      @focusout="checkScheduleNameErrors($event)"
                    >
                    <span
                      v-if="schPatternError"
                      id="schName"
                      class="mds-form__field-error"
                      role="alert"
                    >
                      <span class="mds-form__field-error-text">
                        {{ $t('labels.schedulerManagement.schedulePatternError') }}
                      </span>
                    </span>
                    <span
                      v-if="scheduleExists"
                      id="schName"
                      class="mds-form__field-error"
                      role="alert"
                    >
                      <span class="mds-form__field-error-text">
                        {{ $t('labels.schedulerManagement.scheduleDuplicateError') }}
                      </span>
                    </span>
                    <span
                      v-if="schNameError"
                      id="schName"
                      class="mds-form__field-error"
                      role="alert"
                    >
                      <span class="mds-form__field-error-text">
                        {{ $t('labels.schedulerManagement.schValidNameError') }}
                      </span>
                    </span>
                  </mds-col>
                </mds-row><mds-row
                  class="pading-top_1rem"
                >
                  <mds-col :cols="3">
                    <label
                      class="mds-form__label pading-top_1rem padding-left_15rem"
                      for="datasource"
                    >{{ $t('labels.schedulerManagement.scLabelDescription') }}
                    </label>
                  </mds-col>
                  <mds-col class="pading-top_05rem">
                    <textarea
                      id="schDescription"
                      v-model="schDescription"
                      maxlength="255"
                      class="mds-form__textarea custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
                      :disabled="schCopyNewNExistingRadio !== '1'"
                      @focusout="trimInputData('schDescription')"
                    />
                  </mds-col>
                </mds-row>
                <mds-row
                  class="pading-top_1rem pading-bottom_1rem"
                  :class="{disablefunctionality: disableCollFun}"
                >
                  <mds-col :cols="5">
                    <mds-checkbox
                      v-model="chkCopyColAcc"
                      value="accept"
                      :checked="chkCopyColAcc === true"
                      :disabled="schCopyNewNExistingRadio !== '1'"
                      @click.native.stop="()=>{}"
                      @change="setValOfchkCopyColAcc()"
                    >
                      {{ $t('labels.schedulerManagement.cpyToCollAcc') }}
                    </mds-checkbox>
                  </mds-col>
                </mds-row>
                <mds-row v-if="chkCopyColAcc">
                  <mds-col :cols="1" />
                  <mds-col :cols="10">
                    <mds-combo-box
                      v-model="CopyColAccUserName"
                      class="upward-dropdown-user"
                      label=""
                      :data-set="dataSetUserList"
                      :disabled="schCopyNewNExistingRadio !== '1'"
                    />
                    <div class="pading-top_05rem">
                      <mds-row>
                        <mds-col :cols="3">
                          <mds-checkbox
                            v-model="chkForceCopyColAcc"
                            value="accept"
                            :checked="chkForceCopyColAcc === true"
                            :disabled="schCopyNewNExistingRadio !== '1'"
                            @click.native.stop="()=>{}"
                            @change="chkForceCopyColAcc = !chkForceCopyColAcc"
                          >
                            Force copy
                          </mds-checkbox>
                        </mds-col>
                      </mds-row>
                    </div>
                  </mds-col>
                </mds-row>
                <hr class="horizontal-bar">
                <mds-row>
                  <mds-col
                    :cols="4"
                  >
                    <div @click="nullifyCTNSch(true)">
                      <mds-radio-button
                        v-model="schCopyNewNExistingRadio"
                        :checked="schCopyNewNExistingRadio == '0'"
                        name="schCopyNewNExistingRadio"
                        value="0"
                      >
                        {{ $t('labels.schedulerManagement.cpyToExtSch') }}
                      </mds-radio-button>
                    </div>
                  </mds-col>
                </mds-row>
                <mds-row class="pading-top_1rem pading-bottom_1rem">
                  <mds-col :cols="1" />
                  <mds-col :cols="10">
                    <mds-combo-box
                      v-model="overridingSchName"
                      class="upward-dropdown"
                      label=""
                      :data-set="dataSetSchList"
                      :disabled="schCopyNewNExistingRadio !== '0'"
                    />
                  </mds-col>
                  <mds-col />
                </mds-row>
                <mds-row />
              </mds-col>
            </mds-row>
          </div>
        </form>
        <mds-row>
          <mds-col class="pading-top_1rem">
            <mds-row>
              <mds-col
                :push="8"
                class="copy-sch-buttons"
              >
                <button
                  class="mds-button mds-button--primary font-size-small copy-sch-btn"
                  type="button"
                  :disabled="(
                    (schCopyNewNExistingRadio !== '0') &&
                    ((schName === '') ||
                      (schPatternError === true) ||
                      (scheduleExists === true) ||
                      (schNameError === true))) ||
                    ((schCopyNewNExistingRadio !== '1') &&
                      (overridingSchName === '')) ||
                    ((chkCopyColAcc) && (CopyColAccUserName === ''))"
                  @click="submitTheSelection($event)"
                >
                  Copy
                </button>
              </mds-col>
            </mds-row>
          </mds-col>
        </mds-row>
      </mds-col>
    </mds-row>
    <scheduler-notification
      ref="notificationComponent"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { MdsRow, MdsCol } from '@mds/layout-grid';
import MdsRadioButton from '@mds/radio-button';
import MdsComboBox from '@mds/combo-box';
import Cookies from 'js-cookie';
import SchedulerNotification from './SchedulerNotification.vue';

export default {
  name: 'CopySchedule',
  components: {
    MdsRow,
    MdsCol,
    MdsRadioButton,
    MdsComboBox,
    SchedulerNotification,
  },
  props: {
    scheduler:
    {
      type: Object,
      default() {
        return {};
      },
    },
    schNamesArray: {
      type: Array,
      default() {
        return [];
      },
    },
    size: {
      type: String,
      default() {
        return '';
      },
    },
    width: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      overlay: false,
      showloader: false,
      showPageError: false,
      pageError: '',
      internalServerErrMsg: 'Internal Server Error with status code 500',
      schNameError: false,
      schPatternError: false,
      schCopyNewNExistingRadio: '1',
      scheduleExists: false,
      schedulePatternError: false,
      dataSetSchList: [],
      dataSetUserList: [{
        text: 'Loading..',
        value: 'Loading',
      }],
      schName: '',
      schDescription: '',
      chkCopyColAcc: false,
      CopyColAccUserName: '',
      chkForceCopyColAcc: false,
      companyGroupsOrig: [],
      companyGroups: [],
      disableCollFun: false,
      overridingSchName: '',
      userCompany: '',
      schObjForcopyToNew: {},
      schObjForcopyToExt: {},
    };
  },
  computed: {
    ...mapGetters({
      schedulerList: 'schedulerModule/getSchedulerList',
    }),
  },
  created() {
    if (this.schNamesArray) {
      for (let i = 0; i < this.schNamesArray.length; i++) {
        this.dataSetSchList.push({
          text: this.schNamesArray[i],
          value: this.schNamesArray[i],
        });
      }
    }
    if (this.scheduler) {
      if (Cookies.get('userInfoVue') !== undefined) {
        this.userCompany = JSON.parse(Cookies.get('userInfoVue')).company;
      }
      this.loadSchedulerDetails();
    }
  },
  methods: {
    ...mapActions('schedulerModule', ['getSchedulerList', 'copySchToExistingSch', 'copySchToNewSch', 'getAllUsersForCompany']),

    loadSchedulerDetails() {
      if (this.scheduler) {
        this.schName = `Copy_of_${ this.scheduler.scheduleName}`;
        this.schDescription = this.scheduler.description ? this.scheduler.description : '';
      }
    },
    checkScheduleNameErrors(event) {
      this.schPatternError = false;
      this.scheduleExists = false;
      this.schName = this.schName.trim();
      if (this.schName && this.schName.length > 0) {
        const allowedSPattern = /^[a-zA-Z0-9_]+$/;
        const allowedSLength = 100;
        const matchedString = this.schName.match(allowedSPattern);
        if (!(matchedString && matchedString[0].length === this.schName.length)
        || this.schName.length > allowedSLength
        || this.schName.length === 0) {
          this.schPatternError = true;
        } else if (this.schedulerList.length > 0) {
          this.schedulerList.forEach((item, index) => {
            if (this.schName.toLowerCase() === item.scheduleName.toLowerCase()) {
              this.scheduleExists = true;
            }
          });
        }
      }
    },
    nullifyCTNSch(value) {
      this.disableCollFun = value;
    },
    trimInputData(trimmedVariable) {
      this[trimmedVariable] = this[trimmedVariable].trim();
    },
    checkedCopyColleagues() {
      this.chkCopyColAcc = !this.chkCopyColAcc;
    },
    setValOfchkCopyColAcc() {
      this.chkCopyColAcc = !this.chkCopyColAcc;
      this.schPatternError = false;
      this.scheduleExists = false;
      if (this.dataSetUserList.length === 1) {
        this.getAllUsersForCompany(this.userCompany).then((response) => {
          const users = response.data.userList;
          this.dataSetUserList = [];
          for (let i = 0; i < users.length; i++) {
            this.dataSetUserList.push({
              text: users[i],
              value: users[i],
            });
          }
        });
      }
    },
    workOnResponse(response) {
      if (response !== undefined && response.status !== 200) {
        this.errorText = response.Message || response.data;
        this.$refs.notificationComponent.triggerError(this.errorText);
        this.overlay = false;
        this.showloader = false;
        this.$emit('copyScheduleNameError', this.errorText);
      } else if (response) {
        this.$refs.notificationComponent.triggerSuccess(response.data);
        this.overlay = false;
        this.showloader = false;
        this.$emit('copyScheduleNameSucess', response.data.replace(/%40/g, '@'));
      } else {
        this.errorText = response.Message || response.data;
        this.$refs.notificationComponent.triggerError(this.errorText);
        this.showPageError = true;
        this.overlay = false;
        this.showloader = false;
        this.$emit('copyScheduleNameError', this.errorText);
      }
    },
    workOnErrorResponse(error) {
      const parser = new DOMParser();
      const xml = parser.parseFromString(error.response.data, 'text/xml');
      const error1 = xml.getElementsByTagName('errorMessage')[0].innerHTML;
      this.$emit('copyScheduleNameError', error1);
    },
    getSchObjForCopySchToNew() {
      this.schObjForcopyToNew = {
        fromSchName: this.scheduler.scheduleName,
        toSchName: '',
        newSchDesc: '',
        toAccount: '',
        forceCopy: '',
        copyToColleaguesAcc: '',
      };

      if (this.schName === '') {
        this.schNameError = true;
        return;
      }
      this.schObjForcopyToNew.toSchName = `${this.schName }`;
      this.schObjForcopyToNew.newSchDesc = this.schDescription ? `${this.schDescription }` : '';
      this.schObjForcopyToNew.copyToColleaguesAcc = this.chkCopyColAcc ? `${this.chkCopyColAcc }` : 'false';

      if (this.chkCopyColAcc) {
        this.schObjForcopyToNew.toAccount = `${this.CopyColAccUserName[0] }`;
        this.schObjForcopyToNew.forceCopy = `${this.chkForceCopyColAcc }`;
      }
    },
    getSchObjForCopySchToExt() {
      this.schObjForcopyToExt = {
        fromSchName: this.scheduler.scheduleName,
        toSchName: this.overridingSchName[0],
      };
    },
    submitTheSelection(e) {
      e.preventDefault();
      if (this.schCopyNewNExistingRadio === '1') {
        this.getSchObjForCopySchToNew();
        this.copySchToNewSch(this.schObjForcopyToNew).then((response) => {
          if (response) {
            this.workOnResponse(response);
          }
        }).catch((error) => {
          this.workOnErrorResponse(error);
        });
      } else if (this.schCopyNewNExistingRadio === '0') {
        if (this.overridingSchName !== '') {
          this.getSchObjForCopySchToExt();
          if (this.schObjForcopyToExt.fromSchName !== '' && this.schObjForcopyToExt.toSchName !== '') {
            this.copySchToExistingSch(this.schObjForcopyToExt).then((response) => {
              this.workOnResponse(response);
            }).catch((error) => {
              this.workOnErrorResponse(error);
            });
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.mds-form__label {
  font-weight: 450;
}
.padding-top-bottom {
  padding: 2rem 1rem 2rem 1rem;
}
.pading_1rem {
  padding: 1rem;
}
.pading-top_1rem {
  padding-top: 1rem;
}
.pading-top_05rem {
  padding-top: 0.5rem;
}
.pading-bottom_1rem {
  padding-bottom: 1rem;
}
.pading-bottom_05rem {
  padding-bottom: 0.5rem;
}
.horizontal-bar {
  border-top: 0.10px solid #dadada;
}
.padding-right_05rem {
  padding-right: 0.5rem;
}
.padding-right_2rem {
  padding-right: 2rem;
}
.padding-left_15rem {
  padding-left: 1.5rem;
}
.mds-combo-box__result-list___Mcd-ui {
  max-height: 250px !important;
}
.disablefunctionality {
  pointer-events: none !important;
  opacity: 0.4 !important;
  cursor: not-allowed !important;
}
.copy-sch-buttons {
  display: flex;
  justify-content: end;
}
.copy-sch-btn {
  margin-right: 1rem;
}
</style>

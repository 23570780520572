
import { requireNonEmptyString, firstString } from './strings.es6';
import { requireNonNegativeInteger} from "./numbers.es6";
import Objects from './objects.es6';

/* *****************************************************
 * Private variables
 * ***************************************************** */

const _regexAbs = new RegExp(
    '^(http|https|ftp|sftp|file)://([^/:]+)(?::(\\d+))?([^?#]*)(\\?[^#]*)?(#.*)?$',
    'i'
);

/* *****************************************************
 * Private methods
 * ***************************************************** */

/** @returns {string} URL-encoded representation of `s`. */
const _escape = function (s) {
    return window.encodeURIComponent(s);
};

/** @returns {string} URL-decoded representation of `s`. */
const _unescape = function (s) {
    return window.decodeURIComponent(s);
};

/**
 * Parses query-string parameters as they appear in URLs.
 * The string argument is assumed to be URL-encoded; the
 * returned object contains URL-decoded names and values.
 *
 * If the same parameter name is found multiple time, the value
 * associated with that parameter is appended to, using a comma (,)
 * as the delimiter.
 *
 * @param {string} str - Query-string parameters, URL-encoded.
 * @returns {Object.<string, string>}
 * @private
 */
const _parseQryString = function (str) {

    const params = {};

    _.each(str.split('&'), function (entry) {

        const equal = entry.indexOf('=');
        let name, value;

        if (equal < 0) {
            name = entry;
            value = "";
        } else {
            name = entry.substring(0, equal);
            value = entry.substring(equal + 1);
        }

        params[_unescape(name)] = _unescape(value);
    });

    return params;
};

/**
 * @namespace
 */
const Url = Object.freeze(/** @lends {Url} */ {

    /**
     * Returns `depth` number of path-items within the current browser URL.
     * For example: <ul>
     *     <li> rootPath(0) => "/" </li>
     *     <li> rootPath(1) => "/cdb/" </li>
     * </ul>
     * @param {int} depth - Number of path entries to be included.
     * @returns {string}
     */
    rootPath: function (depth) {

        requireNonNegativeInteger(depth, "depth");

        const path = window.location.pathname;
        let idx = -1;
        for (let cnt = 0; cnt <= depth; cnt++) {
            idx = path.indexOf('/', idx + 1);
            if (idx < 0)
                idx = path.length - 1;
        }

        return path.substring(0, idx + 1);
    },

    /**
     * Parses a query parameter from the current browser URL.
     * If `name` is found as a query-string parameter, its value is returned.
     * If `name` is not found, `defaultValue` is returned, or `null` if defaultValue
     * is not provided.
     * @param {string} name Case-sensitive query-string parameter..
     * @param {*} [defaultValue=null]
     * @returns {?string}
     */
    queryStringParam: function (name, defaultValue) {

        requireNonEmptyString(name, "name");

        const regex = new RegExp('[\\?&]' + name + '=([^&]*)');
        const match = regex.exec(window.location.search);
        if (match !== null) {
            return match[1];
        } else if (arguments.length > 1) {
            return defaultValue;
        } else {
            return null;
        }
    },

    /**
     * Parses query-string parameters from the given URL, after '?'.
     * If the given URL does not contain '?', this method returns an empty object.
     * @param {string} url - URL in which query-parameters are found after '?'.
     * @returns {Object.<string, string>}
     */
    parseQueryStringInUrl: function (url) {
        requireNonEmptyString(url, "url");
        let questionMark = url.indexOf('?');
        if (questionMark >= 0) {
            return _parseQryString(url.substring(questionMark + 1));
        } else {
            return {};
        }
    },

    /**
     * Parses query-string parameters as they appear in URLs or body/payload of
     * "application/x-www-form-urlencoded" requests.
     *
     * @param {string} queryString - Query-string parameters, without '?' (URL-encoded).
     * @returns {Object.<string, string>}
     */
    parseQueryString: function (queryString) {
        return _parseQryString(requireNonEmptyString(queryString, "queryString"));
    },

    /**
     * Converts a plain object into a query-string.
     * @param {Object.<string, (string|number|boolean)>} obj
     * @returns {string} URL-encoded string of key-value pairs, sorted by key names.
     */
    stringifyQueryString: function (obj) {
        if (!Objects.isAllPrimitives(obj)) {
            throw new TypeError('obj: Object.<string, (string|number|boolean)>');
        }
        return Objects.properties(obj, true).map((name) => _escape(name) + '=' + _escape(obj[name])).join('&');
    },

    /**
     * Returns the equivalent of the JavaScript <code>location</code> object.
     * @see http://www.w3schools.com/jsref/obj_location.asp
     */
    info: function (url) {

        requireNonEmptyString(url, "url");

        const m = _regexAbs.exec(url);
        if (m === null)
            return null;

        else {

            const o = {
                hash:     firstString(m[6], ''),
                host:     null,
                hostname: firstString(m[2], ''),
                href:     url,
                pathname: firstString(m[4], ''),
                port:     firstString(m[3], ''),
                protocol: firstString(m[1], ''),
                search:   firstString(m[5], '')
            };

            o.host = o.hostname;
            if (o.port !== '')
                o.host += ':' + o.port;

            return o;
        }
    }

});

export default Url;


import { isInteger } from '../../numbers.es6';
import HttpCodes from '../../httpcodes.es6';
import Functions, { requireFunction } from '../../functions.es6';
import Arrays from '../../arrays.es6';
import Console from '../../console.es6';
import { isServerError} from '../../servererror.es6'
import { ApiHandler } from '../../apihandler.es6';

/**
 * Super-user code, -1 = not_initialized.
 * @type {int}
 * @private
 */
let _code = -1;

/** @type {Function.<boolean>[]} */
let _callbacks = [];

/**
 * Logs the super-user code
 * @param {int} suCode
 * @private
 */
function _logSuperUserCode (suCode) {

    switch (suCode) {
        case HttpCodes.OK:
            Console.info("Workflow support-user acknowledged.");
            break;

        case HttpCodes.FORBIDDEN:
            // This is how MP says a user is not a workflow super-user.
            Console.info("Not a workflow support-user.");
            break;

        case HttpCodes.UNAUTHORIZED:
            Console.warn("User has no access to MP; no workflow for you!");
            break;

        case HttpCodes.NOT_FOUND:
            Console.info("MP doesn't recognize workflow support-users yet.");
            break;

        default:
            // Unexpected error, show it to user (it's already been
            // logged to console).
            Console.error("Unexpected workflow support-user code: {}", suCode);
    }
}

function _isSupportUser () {
    return (_code === HttpCodes.OK);
}

/**
 * Asynchronous call to whether current user has Support privileges around MP workflows.
 * Only one server call is made, ever; the response is cached permanently.  All callbacks are executed in order
 * they are passed to this caller.
 * @param {Function.<boolean>} callback Callback that receives a boolean argument: whether current user has
 *        Support privileges.
 */
export function isSupportUser (callback) {

    requireFunction(callback, 'callback');

    if (_code >= 0) {
        Functions.delay(function () {
            callback(_isSupportUser());
        });
    } else {

        let isFirstCall = (_callbacks.length === 0);
        _callbacks.push(callback);

        if (isFirstCall) {
            let apiHandler = new ApiHandler(1);

            apiHandler.call('lim.MpApi.WorkflowSupportAccessTest', function (payload) {

                if (payload === "OK") {
                    _code = HttpCodes.OK;
                } else if (isInteger(payload)) {
                    // HTTP status code, could be normal.  In all cases, current user
                    // is not a super-user.
                    _code = payload;
                } else if (isServerError(payload)) {
                    // HTTP status code from Marketplace, could be normal.  In all cases,
                    // current user is not a super-user.
                    _code = payload.code();
                } else {
                    _code = HttpCodes.INTERNAL_SERVER_ERROR;
                }

                _logSuperUserCode(_code);

                // Forward the super-user code to all listeners.
                _.each(Arrays.removeAll(_callbacks), function (callback) {
                    callback(_isSupportUser());
                });

                // done with the ApiHandler object.
                apiHandler.destroy();
            });
        }
    }
}



function _isBool (b) {
    return (typeof b === "boolean");
}

/** @namespace */
const Booleans = Object.freeze(/** @lends {Booleans} */ {

    /**
     * @param {(boolean|*)} bool Argument to validate.
     * @returns {boolean} Whether `bool` is a boolean value.
     * @method
     */
    is: _isBool,

    /**
     * Validates `arg` to be a Boolean value, throws otherwise.
     * @param {boolean} arg Argument to validate.
     * @param {string} argName Name given to `arg`, for when an error must be thrown.
     * @returns {boolean} Always returns `arg`.
     * @throws {TypeError} If `arg` is not a boolean.
     */
    requireBoolean: function (arg, argName) {

        if (!_isBool(arg))
            throw new TypeError(argName, ": Boolean");

        return arg;
    }
});

export default Booleans;
export let
    isBoolean = Booleans.is,
    requireBoolean = Booleans.requireBoolean;

<template>
  <div class="wf-edit-action">
    <mds-notification-container v-if="showError">
      <!-- Tinted -->
      <mds-notification
        key="error-tinted"
        variation="error"
        title="Error"
        tinted
        :dismiss-delay="3000"
        @mds-notification-dismissed="hideError"
      >
        {{ errorMessage }}
      </mds-notification>
    </mds-notification-container>
    <mds-loader
      v-if="showLoader"
      size="small"
      aria-label="Small Loader"
    />
    <mds-form
      v-if="!showErrorMsg"
      :class="isEditable === 'false' || isEditable === false ? 'disabled' : ''"
    >
      <mds-input
        v-model="userEmail"
        label="To: "
        @focus="usernameError = false"
      />
      <span
        v-if="usernameError"
        id="bubbleName"
        class="mds-form__field-error"
        role="alert"
        style="margin-bottom: 1%;"
      >
        <span
          class="mds-form__field-error-text"
        >
          Please enter valid email ID.
        </span>
      </span>
      <mds-textarea
        v-model="message"
        label="Message: "
      />
      <span
        v-if="messageError"
        id="bubbleName"
        class="mds-form__field-error"
        role="alert"
      >
        <span
          class="mds-form__field-error-text"
          style="margin-bottom: 10px;"
        >
          Message cannot be empty.
        </span>
      </span>
    </mds-form>
    <div v-if="isEditable">
      <hr v-if="!showErrorMsg">
      <mds-row v-if="!showErrorMsg">
        <mds-col />
        <mds-col style="display: flex; justify-content: right;">
          <mds-button
            variation="primary"
            @click.prevent="saveForm()"
          >
            Ok
          </mds-button>
        </mds-col>
      </mds-row>
    </div>
    <div v-if="showErrorMsg">
      <mds-empty-state
        title="Nothing to edit"
        message="Please connect proper bubble to this bubble."
        size="large"
        style="margin: auto;"
      />
    </div>
  </div>
</template>
<script>
import MdsForm from '@mds/form';
import MdsInput from '@mds/input';
import { mapGetters, mapActions } from 'vuex';
import { MdsButton } from '@mds/button';
import MdsTextarea from '@mds/textarea';
import { MdsRow, MdsCol } from '@mds/layout-grid';
import MdsEmptyState from '@mds/empty-state';
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';
import { _editNotificationCB } from '../../../scripts/utils/Manager';

export default {
  name: 'WorkflowNotificationEditAction',
  components: {
    MdsForm,
    MdsRow,
    MdsCol,
    MdsButton,
    MdsInput,
    MdsTextarea,
    MdsEmptyState,
    MdsNotification,
    MdsNotificationContainer,
  },
  props: {
    node: {
      type: Object,
      default: null,
    },
    workflowData: {
      type: Object,
      default: null,
    },
    nodeVarName: {
      type: String,
      default: null,
    },
    formulas: {
      type: Array,
      default: null,
    },
    parameterSetData: {
      type: Object,
      default: null,
    },
    workflow: {
      type: Object,
      required: false,
      default: null,
    },
    isNewNode: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showLoader: false,
      message: '',
      userEmail: '',
      usernameError: false,
      messageError: false,
      showErrorMsg: false,
      errorMessage: false,
      showError: false,
    };
  },
  computed: {
    ...mapGetters('workflowModule', [
      'getParamStatus',
      'getWorkflowFormulas',
      'getEditableWorkflowDetails',
      'getEditableWorkflowUI',
      'getEditableParamSet',
      'getEditableFormulae',
      'getEditableNewFormulae',
      'getEditableNodes',
      'getEditableLinks',
      'getEditableTargets',
      'getCreateNewWorkflowDetails',
      'getCreateNewWorkflowUI',
      'getCreateNewParamSet',
      'getCreateNewFormulae',
      'getallWorkflowsForName',
      'getCurrentWorkflowManager',
    ]),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('feedModule', [
      'getUserDatasources',
      'getUserFeeds',
      'getFeedDetailsByName',
      'getFeedRoots',
    ]),
    ...mapActions('workflowModule', [
      'getUserWorkflows',
      'getWorkflowStatus',
      'getFormulasForWorkflow',
      'setUserWorkflow',
      'getParameterSet',
      'setCurrentEditableWorkflow',
      'setNewlyCreateWorkflow',
      'setCurrentEditableWorkflow',
      'setCurrentWorkflowManager',
      'setCurrentEditParamSet',
      'setCurrentEditWorkflowUI',
    ]),
    init() {
      if (this.node.title === 'notification'
      && this.node.data.targetInfo.dependencies.length > 0) {
        if (this.node.data) {
          this.userEmail = this.node.data.to ? this.node.data.to : '';
          this.message = this.node.data.msg ? this.node.data.msg.split('\n\nMessage:') : [''];
          this.message = this.message.length > 1 ? this.message[0] : this.node.data.msg;
        }
      }
    },
    saveForm() {
      this.manager = this.getCurrentWorkflowManager;
      // eslint-disable-next-line max-len
      const re = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/;
      if (this.userEmail === '' || !(new RegExp(re).test(this.userEmail))) {
        this.usernameError = true;
        return;
      }

      if (this.message === '') {
        this.messageError = true;
        return;
      }

      // eslint-disable-next-line vue/no-mutating-props
      this.node.data.to = this.userEmail;
      // eslint-disable-next-line vue/no-mutating-props
      this.node.data.targetInfo.tasks[0].props.recipients = this.userEmail;
      const restPartOfOrignalMessage = this.node.data.msg.split('\n\nMessage:')[1];
      // eslint-disable-next-line vue/no-mutating-props
      this.node.data.msg = `${this.message }\n\nMessage:${ restPartOfOrignalMessage}`;
      // eslint-disable-next-line vue/no-mutating-props
      this.node.data.targetInfo.tasks[0].props.body = `${this.message }\n\nMessage:${ restPartOfOrignalMessage}`;
      const dataObj = {
        mgr: this.manager,
        bubble: this.manager._priv.bubbles[this.node.id],
        recipients: this.userEmail,
        body: this.message,
      };
      if (!_editNotificationCB(dataObj, this)) {
        return;
      }
      this.$emit('notificationEdit', this.node);
    },
  },
};
</script>

<style>
.wf-edit-action {
  height: 97% !important;
  padding: 1%;
}
.wf-edit-action form {
  height: 100% !important;
}
.disabled {
  pointer-events: none;
  cursor: no-drop;
  opacity: 0.5;
  display: block !important;
}
</style>

const mutations = {
  setRouterChange(state, payload) {
    state.routerLocation = payload;
  },
  setUserInfo(state, payload) {
    if (typeof payload === 'string') {
      payload = JSON.parse(payload);
    }
    if (payload) {
      state.inactive = payload.inactive;
      state.userName = payload.userName;
      state.uuid = payload.uuid;
      state.company = payload.company;
    }
  },
  setTheme(state, payload) {
    state.darkMode = payload;
  },
  updateGlobalSettings(state, payload) {
    const keyName = state.globalSettings.get(payload.keyId).name;
    state.globalSettings.get(payload.keyId).subKeys.forEach((element) => {
      if (element.subKeyId === payload.subKeyId) {
        element.enabled = payload.action === state.notificationsKeys.add;
        if (keyName === state.notificationsKeys.keyName) {
          const index = state.notifSettings.indexOf(element.subKeyName);
          if (payload.action === state.notificationsKeys.add && index < 0) {
            state.notifSettings.push(element.subKeyName);
          } else if (payload.action === state.notificationsKeys.remove && index >= 0) {
            state.notifSettings = state.notifSettings.filter(val => val !== element.subKeyName);
          }
        }
        if (payload.cronExpression != null) {
          state.dataAlertsCron = payload.cronExpression;
        }
      }
    });
  },
  setGlobalSettings(state, payload) {
    const globalSettingsMap = new Map();
    payload.forEach((element) => {
      const gsKey = element.keyId;
      let subKeyStatus = false;
      const {
        subKeyName, subKeyId, keyName, value,
      } = element;
      if (element.userId) {
        subKeyStatus = true;
      }
      if (keyName === 'Email Preferences' && subKeyName === 'Data Alerts' && value != null) {
        state.dataAlertsCron = value;
      }
      if (globalSettingsMap.has(gsKey)) {
        globalSettingsMap.get(gsKey).subKeys.push({
          subKeyId,
          subKeyName,
          enabled: subKeyStatus,
        });
      } else {
        globalSettingsMap.set(gsKey, {
          name: keyName,
          subKeys: [{
            subKeyId,
            subKeyName,
            enabled: subKeyStatus,
          }],
        });
      }
    });
    state.globalSettings = globalSettingsMap;
    state.notifSettings = [];
    payload.forEach((element) => {
      if (element.keyName === state.notificationsKeys.keyName && element.userId) {
        state.notifSettings.push(element.subKeyName);
      }
    });
  },
  SET_USER_NOTIFS_COUNT(state) {
    state.newNotifsCount = state.userNotifs.length;
  },
  SET_USER_NOTIFS(state, payload) {
    state.userNotifs = payload;
    state.newNotifsCount = state.userNotifs.length;
  },
  MARK_NOTIFS_READ(state) {
    state.userNotifs = [];
    state.newNotifsCount = 0;
  },
};

export default mutations;

<template>
  <div>
    <Feeds />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { FEATURES } from '@/utils/constants.js';
import Feeds from '../CuratedFeeds/NavigationHome';
import NotEntitledUser from '../Errors/NotEntitledUser';

export default {
  name: 'NavigationHome',
  components: {
    Feeds,
  },
};
</script>

function findMaxOrMin(values = [], type = 'max') {
  let maxMinValue = type === 'max' ? -Infinity : Infinity;
  values.forEach((value) => {
    maxMinValue = (type === 'max' ? Math.max : Math.min)(maxMinValue, value);
  });
  return maxMinValue;
}

// function number, precision) {
//   if (!number) return number;
//   const precisedNum = number.toString().match(new RegExp(`^-?\\d+(?:\\.\\d{0,${precision}})?`))[0];
//   return precisedNum;
// }

function countPositivesOrNegatives(values = [], type = '+') {
  let count = 0;
  values.forEach((value) => {
    if (type === '+') {
      if (value > 0) count++;
    } else if (type === '-') {
      if (value < 0) count++;
    }
  });
  return count;
}

function transformData(data) {
  const keys = Object.keys(data[0]);
  const transformedData = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    const values = data.map(item => item[key]);
    transformedData.push([key, ...values]);
  }

  return transformedData;
}

export function generateSummaryDataForPreviewResults(valuesColumn) {
  const summaries = [];

  Object.keys(valuesColumn).forEach((idx) => {
    const valueColumn = valuesColumn[idx].map(value => +value).filter(value => value);

    const cnt = valueColumn.length;
    const cntPos = countPositivesOrNegatives(valueColumn, '+');
    const cntNeg = countPositivesOrNegatives(valueColumn, '-');

    const sumSquare = valueColumn.reduce((prev, curr) => prev + (curr ** 2), 0);
    const sum = valueColumn.reduce((prev, curr) => prev + curr, 0);
    const sumPos = valueColumn.reduce((prev, curr) => (curr > 0 ? prev + curr : prev + 0), 0);
    const sumNeg = valueColumn.reduce((prev, curr) => (curr < 0 ? prev + curr : prev + 0), 0);
    const maximum = findMaxOrMin(valueColumn, 'max');
    const minimum = findMaxOrMin(valueColumn, 'min');

    const avg = sum / cnt;
    const variance = (sumSquare - sum * avg) / cnt;
    const stdDev = Math.sqrt(variance);

    const summaryObject = {
      Avg: avg.toFixed(5),
      AvgPos: (cntPos <= 0 ? 0 : sumPos / cntPos).toFixed(5),
      AvgNeg: (cntPos <= 0 ? 0 : sumNeg / cntNeg).toFixed(5),
      PctPos: (cntPos <= 0 ? 0 : (100 * cntPos) / cnt).toFixed(2),
      PctNeg: (cntPos <= 0 ? 0 : (100 * cntNeg) / cnt).toFixed(2),
      Max: maximum,
      Min: minimum,
      StdDev: stdDev.toFixed(5),
      ZStat: (avg / stdDev).toFixed(5),
      Variance: ((sumSquare - sum * avg) / cnt).toFixed(5),
    };

    summaries.push(summaryObject);
  });

  return transformData(summaries);
}

export function generatePreviewResultsData(response) {
  const previewResultsDataObject = {};
  const responseLines = response.split('\n');
  const dateColumn = [];
  const valuesColumn = {};
  responseLines.forEach((line) => {
    const [date, ...valuesRow] = line.split(',');
    dateColumn.push(date);
    for (let i = 0; i < valuesRow.length; i++) {
      if (Array.isArray(valuesColumn[i])) {
        valuesColumn[i].push(valuesRow[i].replace('\r', ''));
      } else {
        valuesColumn[i] = [valuesRow[i].replace('\r', '')];
      }
    }
  });

  previewResultsDataObject.dateColumn = dateColumn;
  previewResultsDataObject.valuesColumn = valuesColumn;

  return previewResultsDataObject;
}

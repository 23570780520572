<template>
  <div class="guide">
    <div class="iframe-container">
      <iframe
        id="contentIFrame"
        ref="contentIFrame"
        :src="guideUrl"
        allowfullscreen
      />
    </div>
  </div>
</template>
<script>
import LayoutDefault from '../../Layouts/LayoutDefault.vue';
import EventBus from '../../../main';

export default {
  name: 'WFDocs',
  computed: {
    guideUrl() {
      return `https://${process.env.VUE_APP_STATIC_RESOURCES_S3_BUCKET_CF_URL}/products/workflows/documentations/js-api-docs/morn.html`;
    },
  },
  created() {
    EventBus.$emit('layoutChange', LayoutDefault);
  },
};
</script>

<style scoped>
@import '../../../assets/styles/components/guides/guides.scss';
</style>

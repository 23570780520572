import { EnumBase, EnumItem } from './enums.es6';

/**
 * WorkflowVarDataType: UNKNOWN, STRING, INTEGER, FLOAT, BOOLEAN, DATE, JSON, TIME_ZONE, PRODUCT, TIME_SERIES.
 * @enum EnumItem
 */
const WorkflowVarDataType = EnumBase.finalize(/** @lends WorkflowVarDataType */ {

  UNKNOWN: new EnumItem('UNKNOWN', 'unknown'),
  STRING: new EnumItem('STRING', 'string'),
  INTEGER: new EnumItem('INTEGER', 'integer'),
  FLOAT: new EnumItem('FLOAT', 'float'),
  BOOLEAN: new EnumItem('BOOLEAN', 'boolean'),
  DATE: new EnumItem('DATE', 'Date'),
  JSON: new EnumItem('JSON', 'JSON'),

  TIME_ZONE: new EnumItem('TIME_ZONE', 'TimeZone'),
  PRODUCT: new EnumItem('PRODUCT', 'Product'),
  TIME_SERIES: new EnumItem('TIME_SERIES', 'TimeSeries'),

}, 'WorkflowVarDataType');

export default WorkflowVarDataType;

const getters = {
  allSources: state => state.sources.sort((a, b) => a.localeCompare(b)),
  allFeeds: state => state.feeds,
  allKeys: state => state.keys,
  allCols: state => state.cols,
  allItems: state => state.totalItems,
  allMetaData: state => state.metadata,
  allDataPreview: state => state.dataPreview,
  allKeySearchRes: state => state.keySearchResults,
  basketLength: state => state.basket.length,
  allBasket: state => state.basket,
};
export default getters;

<template>
  <div
    v-if="tableLoadingStatus==='success'"
    :key="selectedColumnType"
    class="container"
  >
    <mds-table
      class="table__padding-top"
      size="small"
    >
      <mds-thead>
        <mds-th
          v-for="(header, index) in headers"
          :key="index"
          class="dates__header"
        >
          {{ header.text }}
        </mds-th>
      </mds-thead>
      <mds-tbody>
        <mds-tr
          v-for="(row, index) in rows"
          :key="index"
        >
          <mds-td
            v-for="(header, i) in headers"
            :key="i"
            :row-header="i === 0 ? true : false"
            :class="getCssType(i,row)"
          >
            {{ row[header.fieldName] }}
          </mds-td>
        </mds-tr>
      </mds-tbody>
    </mds-table>
  </div>
  <div
    v-else-if="tableLoadingStatus==='loading'"
    :key="chartLoadingStatus"
    class="container"
  >
    <mds-loader
      size="large"
      class="loader"
    />
  </div>
  <div v-else>
    No data found
  </div>
</template>

<script>
import {
  MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr,
} from '@mds/data-table';
import axios from 'axios';
import moment from 'moment';
import MdsLoader from '@mds/loader';
import EventBus from '@/main';

const mapper = {
  0: 'morningstar',
  1: 'ercot',
  2: 'actual',
};
function initialState() {
  return {
    atcResults: { type: Object, default: { morningstar: [], ercot: [], actual: [] } },
    peakResults: { type: Object, default: { morningstar: [], ercot: [], actual: [] } },
    offPeakResults: { type: Object, default: { morningstar: [], ercot: [], actual: [] } },
    maxColumns: 0,
    consolidatedDate: {},
    firstGridView: false,
    tableLoadingStatus: 'loading',
    headers: [
      {
        text: 'Data Type',
        fieldName: 'day',
        style: {
          width: 200,
        },
      },
    ],
    rows: [],
  };
}
export default {
  name: 'DailyAveragesTable',
  components: {
    MdsLoader, MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr,
  },
  props: {
    futureDate: { type: String, default: '' },
    activeTabId: { type: String, default: '' },
    twoDaysAgoDate: { type: String, default: '' },
    eightyFiveMinutesAgoDate: { type: String, default: '' },
    selectedColumnType: { type: String, default: '' },
    table: { type: Array, default: () => [] },
    morningstarData: { type: Array, default: () => [] },
    chartLoadingStatus: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return initialState();
  },
  watch: {
    selectedColumnType(newValue, oldValue) {
      // clear old results
      this.resetPage();
      this.getTablesData();
    },
  },
  mounted() {
    EventBus.$on('readyChart', () => {
      if (parseInt(document.getElementsByClassName('grid__bars')[0].getAttribute('x'), 10) > 50) {
        this.firstGridView = !this.firstGridView;
      }
    });
    this.resetPage();
    this.getTablesData();
  },
  destroyed() {
    // clear old results
    this.resetPage();
  },
  methods: {
    getCssType(index, header) {
      if (index === 0) {
        if (
          header.day.toUpperCase().includes('morningstar') || header.day.toUpperCase().includes('HCSM')
          || header.day.toUpperCase().includes('HRSM') || header.day.toUpperCase().includes('BITO')
          || header.day.toUpperCase().includes('CIBO')
        ) {
          return 'left-side__headers__red';
        }
        if (header.day.toLowerCase().includes('actual')) {
          return 'left-side__headers__blue';
        }
        if (header.day.toLowerCase().includes('ercot')) {
          return 'left-side__headers__green';
        }
      }
      if (index !== 0 && this.firstGridView) {
        if (header.day.toLowerCase().includes('actual')) {
          return 'grid__bars black-border-bottom';
        }
        return 'grid__bars';
      }
      if (index !== 0 && !this.firstGridView) {
        if (header.day.toLowerCase().includes('actual')) {
          return 'grid__bars__secondary black-border-bottom';
        }
        return 'grid__bars__secondary';
      }
      return '';
    },
    consolidateDates(d1, d2, d3) {
      const alldates = [...d1 || [], ...d2 || [], ...d3 || []];
      const result = alldates.reduce((accumulator, current) => {
        accumulator[current.date.format('MMM D')] = current.date;
        return accumulator;
      }, {});
      return Object.keys(result);
    },
    processResults(results) {
      if (results) {
        const processed = results.map(item => ({
          date: moment(item.date),
          value: parseFloat(item.values[0]['value']).toFixed(2),
        }));
        return processed;
      }
      return results;
    },
    async getTablesData() {
      const one = '/api/getPfDailyAverages';
      const two = '/api/getPfDailyAveragesPeak';
      const three = '/api/getPfDailyAveragesOffPeak';


      // laying out all the requests at once so Axios all runs them in concurrent way
      const requestOne = axios.post(one, {
        feedName: this.morningstarData[0].feedName,
        keys: this.morningstarData[0].keys,
        column: this.morningstarData[0].column,
        type: this.activeTabId,
      });
      const requestTwo = axios.post(two, {
        feedName: this.morningstarData[0].feedName,
        keys: this.morningstarData[0].keys,
        column: this.morningstarData[0].column,
        type: this.activeTabId,
      });
      const requestThree = axios.post(three, {
        feedName: this.morningstarData[0].feedName,
        keys: this.morningstarData[0].keys,
        column: this.morningstarData[0].column,
        type: this.activeTabId,
      });
      const requestFour = axios.post(one, {
        feedName: this.morningstarData[1].feedName,
        keys: this.morningstarData[1].keys,
        column: this.morningstarData[1].column,
        type: this.activeTabId,
      });
      const requestFive = axios.post(two, {
        feedName: this.morningstarData[1].feedName,
        keys: this.morningstarData[1].keys,
        column: this.morningstarData[1].column,
        type: this.activeTabId,
      });
      const requestSix = axios.post(three, {
        feedName: this.morningstarData[1].feedName,
        keys: this.morningstarData[1].keys,
        column: this.morningstarData[1].column,
        type: this.activeTabId,
      });
      const requestSeven = axios.post(one, {
        feedName: this.morningstarData[2].feedName,
        keys: this.morningstarData[2].keys,
        column: this.morningstarData[2].column,
        type: this.activeTabId,
      });
      const requestEighth = axios.post(two, {
        feedName: this.morningstarData[2].feedName,
        keys: this.morningstarData[2].keys,
        column: this.morningstarData[2].column,
        type: this.activeTabId,
      });
      const requestNinth = axios.post(three, {
        feedName: this.morningstarData[2].feedName,
        keys: this.morningstarData[2].keys,
        column: this.morningstarData[2].column,
        sourceType: this.activeTabId,
      });

      // run concurrent requests
      axios.all([
        requestOne,
        requestTwo,
        requestThree,
        requestFour,
        requestFive,
        requestSix,
        requestSeven,
        requestEighth,
        requestNinth,
      ]).then(axios.spread(async (...responses) => {
        // get all responses and map them
        const responseOneRawData = responses[0].data;
        const responseTwoRawData = responses[1].data;
        const responseThreeRawData = responses[2].data;
        const responseFourRawData = responses[3].data;
        const responseFiveRawData = responses[4].data;
        const responseSixRawData = responses[5].data;
        const responseSevenRawData = responses[6].data;
        const responseEigthRawData = responses[7].data;
        const responseNineRawData = responses[8].data;
        if (responseOneRawData && responseTwoRawData && responseThreeRawData && responseFourRawData
              && responseFiveRawData && responseSixRawData && responseSevenRawData && responseEigthRawData && responseNineRawData
        ) {
          const responseOneData = this.processResults(responseOneRawData);
          const responseTwoData = this.processResults(responseTwoRawData);
          const responseThreeData = this.processResults(responseThreeRawData);
          const responseFourData = this.processResults(responseFourRawData);
          const responseFiveData = this.processResults(responseFiveRawData);
          const responseSixData = this.processResults(responseSixRawData);
          const responseSevenData = this.processResults(responseSevenRawData);
          const responseEightData = this.processResults(responseEigthRawData);
          const responseNineData = this.processResults(responseNineRawData);

          const group1 = Math.max(responseOneData.length, Math.max(responseTwoData.length, responseThreeData.length)) || 0;
          const group2 = Math.max(responseFourData.length, Math.max(responseFiveData.length, responseSixData.length)) || 0;
          const group3 = Math.max(responseSevenData.length, Math.max(responseEightData.length, responseNineData.length)) || 0;
          const dataMax = Math.max(group1, Math.max(group2, group3)) || 0;

          this.$nextTick(() => {
            this.$set(this.atcResults, 'morningstar', responseOneData);
            this.$set(this.peakResults, 'morningstar', responseTwoData);
            this.$set(this.offPeakResults, 'morningstar', responseThreeData);
            this.$set(this.atcResults, 'ercot', responseFourData);
            this.$set(this.peakResults, 'ercot', responseFiveData);
            this.$set(this.offPeakResults, 'ercot', responseSixData);
            this.$set(this.atcResults, 'actual', responseSevenData);
            this.$set(this.peakResults, 'actual', responseEightData);
            this.$set(this.offPeakResults, 'actual', responseNineData);
            this.$set(this.atcResults, 'maxlength', dataMax);
            this.$set(this.peakResults, 'maxlength', dataMax);
            this.$set(this.offPeakResults, 'maxlength', dataMax);
            // consolidate all dates in one
            this.$set(this.atcResults, 'allAtcDates',
              this.consolidateDates(this.atcResults['morningstar'],
                this.atcResults['ercot'], this.atcResults['actual']));
            this.$set(this.peakResults, 'allPeakDates',
              this.consolidateDates(this.peakResults['morningstar'],
                this.peakResults['ercot'], this.peakResults['actual']));
            this.$set(this.offPeakResults, 'allOffPeakDates',
              this.consolidateDates(this.offPeakResults['morningstar'],
                this.offPeakResults['ercot'], this.offPeakResults['actual']));
            this.$set(this.consolidatedDate, 'allDates', this.atcResults.allAtcDates,
              this.peakResults.allPeakDates, this.offPeakResults.allOffPeakDates);
            this.buildHeaders();
            this.tableLoadingStatus = 'success';
          });
        }
      })).catch((errors) => {
        console.log(errors);
        this.tableLoadingStatus = 'error';
      });
    },
    resetPage() {
      Object.assign(this.$data, initialState());
    },
    buildHeaders() {
      ['atc', 'peak', 'offPeak'].forEach((collection) => {
        ['morningstar', 'ercot', 'actual'].forEach((item) => {
          const dto = {};
          dto.day = `${collection.toLocaleUpperCase() } | ${ item.toUpperCase()
                       === 'MORNINGSTAR' ? this.selectedColumnType.toUpperCase().substring(0, 4) : item.toUpperCase()}`;
          if (this[`${collection}Results`] && this[`${collection}Results`][item]) {
            this[`${collection}Results`][item].forEach((data) => {
              dto[moment(data.date).format('MMM_D')] = data.value;
            });
            this.rows.push(dto);
          }
        });
      });

      // horizontal headers ( Dates )
      if (this.consolidatedDate && this.consolidatedDate.allDates) {
        this.consolidatedDate.allDates.forEach((item) => {
          this.headers.push({
            text: item,
            fieldName: item.replace(' ', '_'),
            align: 'right',
          });
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>

@media screen and (min-width: 1400px) {
  .container {
    width : 88% ;
    height: 100%;
    margin-right: auto;
    //margin-left: 10px;
  }
}

@media screen and (min-width: 1900px) {
  .container {
    width : 88% ;
    height: 100%;
    margin-right: auto;
    margin-left: 18px;
  }
}
.grid__bars:nth-child(odd) {
  background-color: #eeeeee;
  border-right: black 1px solid;
  border-left: black 1px solid;
}

.grid__bars__secondary:nth-child(even) {
  background-color: #eeeeee;
  border-right: black 1px solid;
  border-left: black 1px solid;
}

.grid__bars__secondary {
  text-align: center;
  font-size: 13px !important;
}
.grid__bars {
  text-align: center;
  font-size: 13px !important;
}

.black-border-bottom {
  border-bottom: 1px solid black;
}

.left-side__headers__green{
  font-size: 12px !important;
  border-right: 2px solid #1f55a5;
  text-align: center;
}
.left-side__headers__blue{
  font-size: 12px !important;
  border-right: 2px solid #f5c400;
  text-align: center;
  border-bottom: 1px solid black;
}
.left-side__headers__red{
  font-size: 12px !important;
  border-right: 2px solid #ff0000;
  text-align: center;
}

@media screen and (min-width: 1400px) {
  .dates__header:first-child {
    width: 115px;
  }
}
@media screen and (min-width: 1900px) {
  .dates__header:first-child {
    width: 100px;
  }
}
@media screen and (min-width: 1400px) {
  .dates__header:nth-child(2) {
    width: 75px;
  }
}
@media screen and (min-width: 1900px) {
  .dates__header:nth-child(2) {
    width: 60px;
  }
}
.dates__header {
 text-align: center;
  width: 90px;
  //width: 80px !important;
}
</style>

<template>
  <div>
    <div
      id="mds-overlay"
      :class="{'mds-overlay':saveGroupsPopOver || loader || showNotification}"
    />
    <template v-if="pageStatus == 'SUCCESS'">
      <group-pop-over
        :company-groups="companyGroups"
        :company-groups-orig="companyGroupsOrig"
      />
      <div
        id="mds-modal"
        class="mds-modal  mds-modal--600px mds-modal--width-600px"
        :class="{'mds-modal--open  mds-modal--active':showNotification}"
      >
        <group-update-response
          :group-save-success="groupSaveSuccess"
          :error-list="errorList"
        />
      </div>
      <div class="mds-header border-bottom-solid">
        <h1 class="mds-header__title component-title">
          {{ $t('labels.group.groupList') }}
        </h1>
        <div class="mds-header__actions">
          <add-group-pop />
          <quick-links />
        </div>
      </div>
      <div style="margin-top:30px;">
        <template v-if="loader">
          <div v-show="loader==true">
            <mds-loader />
          </div>
        </template>
        <template v-else>
          <div
            v-for="(group, index) in companyGroups"
            :key="'group' + index"
            style="margin-top:15px;"
            class="mds-accordion"
          >
            <input
              :id="'group' + index"
              :ref="'group' + index"
              type="checkbox"
              :checked="group.checked"
              aria-controls="groupAccordian"
              class="mds-accordion__trigger"
              @click="getUsersList(group.groupName,index)"
            >
            <label
              :for="'group' + index"
              class="mds-accordion__label"
            >
              <div class="mds-header  mds-header--level-4">
                <h2 class="mds-header__title ent-header"> {{ group.groupName }}</h2>
              </div>
            </label>
            <section v-show="group.checked">
              <div v-show="companyGroups[index].loader==true">
                <mds-loader />
              </div>
              <template v-if="companyGroups[index].userList && companyGroups[index].userList.length > 0">
                <mds-data-table
                  size="large"
                  row-hover
                  :row-data="getUserObjects(companyGroups[index].userList)"
                  :header-configs="[{
                                      fieldName: 'name',
                                      text: '',
                                      width: '100%',
                                    },
                                    {
                                      fieldName: '',
                                      text: '',
                                      width: '0%',
                                      align: 'right'
                                    }]"
                >
                  <template #header>
                    <span class="group-hide-header" />
                  </template>
                  <template #body-cell="cellData">
                    <template v-if="cellData.colIndex === 1">
                      <mds-button
                        variation="icon-only"
                        icon="remove"
                        @click="removeUserGroup(companyGroups[index],cellData.rowData.id)"
                      >
                        {{ $t('labels.group.removeUserButton') }}
                      </mds-button>
                    </template>
                  </template>
                </mds-data-table>
              </template>
              <template v-else-if="!group.loader">
                <label>{{ $t('labels.group.noUserWarning') }}</label>
              </template>
              <group-action
                :group="group"
                :index="index"
                :company-users="companyUsers"
              />
            </section>
            <hr>
          </div>
        </template>
      </div>
      <div class="mds-button__container">
        <mds-button
          icon="caret-circle-left"
          variation="secondary"
          class="group-back-button"
          type="button"
          @click="goBack"
        >
          {{ $t('labels.group.groupBackButton') }}
        </mds-button>
        <mds-button
          icon="save"
          variation="primary"
          class="group-save-button"
          type="button"
          @click="saveGroupsPop()"
        >
          {{ $t('labels.group.saveGroupButton') }}
        </mds-button>
      </div>
    </template>
    <template v-else-if="pageStatus == 'ERROR'">
      <div class="details-error">
        <mds-notification
          visible
          title="ERROR"
          status="error"
          dismissible="false"
        >
          {{ errorMessage }}
        </mds-notification>
      </div>
    </template>
    <template v-else-if="pageStatus == 'LOADING'">
      <mds-loader />
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { MdsDataTable } from '@mds/data-table-v3';
import LayoutDefault from '../Layouts/LayoutDefault.vue';
import EventBus from '../../main';
import GroupPopOver from './GroupPopOver';
import GroupUpdateResponse from './GroupUpdateResponse';
import AddGroupPop from './AddGroupPop';
import QuickLinks from './QuickLinks';
import GroupAction from './GroupAction';

export default {
  name: 'Group',
  components: {
    GroupPopOver, GroupUpdateResponse, AddGroupPop, QuickLinks, GroupAction, MdsDataTable,
  },
  data() {
    return {
      loader: true,
      groupChanges: new Map(),
      saveGroupsPopOver: false,
      companyGroupsOrig: [],
      groupSaveSuccess: false,
      showNotification: false,
      errorList: [],
      changeUserList: [],
      curResponseCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      errorMessage: 'groupModule/getErrorMessage',
      pageStatus: 'groupModule/getPageStatus',
      companyGroups: 'groupModule/getCompanyGroups',
      companyUsers: 'groupModule/getCompanyUsers',
    }),
  },
  created() {
    this.setCompanyGroups();
    EventBus.$emit('layoutChange', LayoutDefault);
  },
  mounted() {
    EventBus.$on('addGroup', this.addGroup);
  },
  methods: {
    ...mapActions('groupModule', ['getCompanyGroups', 'getGroupUsersList', 'saveGroupChanges']),
    saveGroupsPop() {
      EventBus.$emit('saveGroupPop', GroupPopOver);
    },
    goBack() {
      this.$router.push({
        path: '/feeds',
      });
      this.$store.commit('feedModule/SET_PAGE_STATUS', 'LOADING');
    },
    setCompanyGroups() {
      this.getCompanyGroups().then((response) => {
        this.loader = true;
        this.companyGroupsOrig = [];
        this.companyGroups.forEach(((group) => {
          const userList = group.userList.slice();
          this.companyGroupsOrig.push({
            groupName: group.groupName,
            userList,
            loaded: group.loaded,
            loader: group.loader,
            checked: group.checked,
          });
        }));
        this.loader = false;
      });
    },
    getUsersList(group, index) {
      const groupObj = this.companyGroups[index];
      groupObj.checked = !groupObj.checked;
      if (!groupObj.loaded) {
        this.companyGroups[index].loader = true;
        this.getGroupUsersList(group).then((response) => {
          this.companyGroups[index].loader = false;
          this.companyGroupsOrig[
            this.getGroupIndex(this.companyGroupsOrig, groupObj.groupName)]
            .userList = this.companyGroups[index].userList == null ? []
              : this.companyGroups[index].userList.slice();
        });
      }
    },
    removeUserGroup(group, index) {
      group.userList.splice(index, 1);
    },
    addGroup(groupName) {
      if (this.getGroupIndex(this.companyGroups, groupName) < 0) {
        this.companyGroups.unshift({
          groupName,
          userList: [],
          feedNames: null,
          loaded: true,
          loader: false,
          checked: true,
        });
      }
    },
    getGroupIndex(groupList, groupName) {
      for (let index = 0; index < groupList.length; index += 1) {
        if (groupList[index].groupName.trim().toLowerCase() === groupName.trim().toLowerCase()) {
          return index;
        }
      }
      return -1;
    },
    deleteGroup(index) {
      this.companyGroups[index].loaded = false;
      this.companyGroups[index].checked = false;
      this.companyGroups.splice(index, 1);
    },
    closeNotification() {
      this.showNotification = false;
      this.$router.go(0);
    },
    saveGroups(groupChanges) {
      this.groupSaveSuccess = true;
      this.saveGroupsPopOver = false;
      this.loader = true;
      let entGroup = {};
      const totalReqCount = groupChanges.get('ADD').length
                                + groupChanges.get('UPDATE').length
                                    + groupChanges.get('DELETE').length;
      if (totalReqCount === 0) {
        this.loader = false;
      }
      this.curResponseCount = 0;
      const actionArr = ['ADD', 'UPDATE', 'DELETE'];
      for (let actionCount = 0; actionCount < actionArr.length; actionCount += 1) {
        const actionName = actionArr[actionCount];
        let { params } = {};
        for (let i = 0; i < groupChanges.get(actionName).length; i += 1) {
          const { groupName } = groupChanges.get(actionName)[i];
          let groupIndex;
          let groupObj = {};
          if (actionName === 'ADD' || actionName === 'UPDATE') {
            groupIndex = this.getGroupIndex(this.companyGroups, groupName);
            groupObj = this.companyGroups[groupIndex];
          } else if (actionName === 'DELETE') {
            groupObj = groupChanges.get(actionName)[i];
          }
          if (groupObj.feedNames === 'undefined') {
            entGroup = {
              name: groupObj.groupName,
              readUsers: groupObj.userList,
            };
          } else {
            entGroup = {
              name: groupObj.groupName,
              readUsers: groupObj.userList,
              feedNames: groupObj.feedNames,
            };
          }

          params = {
            action: actionName,
            value: entGroup,
          };
          this.saveGroupChangesApi(groupObj, groupName, params, totalReqCount);
        }
      }
    },
    saveGroupChangesApi(groupObj, groupName, params, totalReqCount) {
      this.saveGroupChanges(params).then((response) => {
        if (response.status >= 400) {
          this.groupSaveSuccess = false;
          this.errorList.push({
            groupName: groupObj && groupName != null ? groupName : groupObj.groupName,
            message: response.status + (response.data.errorMessage == null ? response.statusText : response.data.errorMessage),
          });
        } else if (response.data) {
          this.errorList.push({
            groupName: groupObj && groupName != null ? groupName : groupObj.groupName,
            message: response.data,
          });
        }
        this.curResponseCount += 1;
        if (this.curResponseCount === totalReqCount) {
          this.loader = false;
          this.showNotification = true;
        }
      });
    },
    getUserObjects(userList) {
      const userObject = [];
      userList.forEach((o, i) => userObject.push({ name: o, id: i++ }));
      return userObject;
    },
  },
};
</script>

<style scoped>

.group-name-header{
  font-weight:normal
}
.group-save-button{
    float: right !important;
    margin-top : 20px;
}
.group-back-button{

    margin-top : 20px;
}
.row-message-error{
  background-color:#ebcccc;
}

.group-hide-header{
  display: block;
  visibility: hidden;
}

</style>

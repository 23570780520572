import axios from 'axios';
import templateString from '../../../utils/stringUtils';
import getAPIConfigs from './config.js';

const config = getAPIConfigs();

const actions = {
  getSchedulerList({ commit }) {
    return axios
      .get(templateString(config.GET_SCHEDULER_LIST_API))
      .then((response) => {
        if (response.data) {
          if (!Array.isArray(response.data)) {
            response.data = [response.data];
          }
        }
        commit('SET_SCHEDULER_LIST', response.data);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
  deleteScheduler({ commit }, scheduleName) {
    return axios.delete(templateString(config.SCHEDULER_DELETE_BY_NAME_API), {
      params: {
        scheduleName,
      },
    }).then(
      response => response,
    ).catch((error) => {
      throw error;
    });
  },
  toggleEnable({ commit }, payload) {
    return axios.post(templateString(config.SCHEDULER_ENABLE_DISABLE_API), {
      params: {
        schName: payload.schName,
        enabled: payload.enabled,
      },
    }).then(
      response => response,
    ).catch((error) => {
      throw error;
    });
  },
  getPubListForScheduler({ commit }) {
    return axios
      .get(templateString(config.GET_PUB_LIST_API))
      .then((response) => {
        commit('SET_PUB_LIST', response.data);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
  createSchedule({ commit }, payload) {
    const requestPayload = {
      scheduleName: payload.scheduleName,
      enabled: payload.enabled,
      machineId: payload.machineId,
      description: payload.description,
      listNames: payload.listNames,
      fileSuffix: payload.fileSuffix,
      fileDateFormat: payload.fileDateFormat,
      fileType: payload.fileType,
      fileDirectory: payload.fileDirectory,
      lastValue: payload.lastValue,
      cron: payload.cron,
      timezone: payload.timezone,
      newData: payload.newData,
      numberDaysBack: payload.numberDaysBack,
      includeHistory: payload.includeHistory,
      disableToPubDate: payload.disableToPubDate,
      numberOfPubDaysBack: payload.numberOfPubDaysBack,
      isMsgBusCheck: payload.isMsgBusCheck,
      messageBusType: payload.messageBusType,
      busName: payload.busName,
    };
    return axios.post(templateString(config.SCHEDULER_CREATE_API), requestPayload).then(
      response => response,
    ).catch((error) => {
      throw error;
    });
  },
  updateSchedule({ commit }, payload) {
    const requestPayload = {
      scheduleName: payload.scheduleName,
      enabled: payload.enabled,
      machineId: payload.machineId,
      description: payload.description,
      listNames: payload.listNames,
      fileSuffix: payload.fileSuffix,
      fileDateFormat: payload.fileDateFormat,
      fileType: payload.fileType,
      fileDirectory: payload.fileDirectory,
      lastValue: payload.lastValue,
      cron: payload.cron,
      timezone: payload.timezone,
      newData: payload.newData,
      numberDaysBack: payload.numberDaysBack,
      includeHistory: payload.includeHistory,
      disableToPubDate: payload.disableToPubDate,
      numberOfPubDaysBack: payload.numberOfPubDaysBack,
      isMsgBusCheck: payload.isMsgBusCheck,
      messageBusType: payload.messageBusType,
      busName: payload.busName,
    };
    return axios.put(templateString(config.SCHEDULER_UPDATE_API), requestPayload).then(
      response => response,
    ).catch((error) => {
      throw error;
    });
  },
  updateScheduleName({ commit }, payload) {
    const requestPayload = {
      oldSchName: payload.scheduleNameInitial,
      newSchName: payload.scheduleName,
      newSchDesc: payload.description,
    };
    return axios.post(templateString(config.SCHEDULER_UPDATE_BY_NAME_API), requestPayload).then(
      response => response,
    ).catch((error) => {
      throw error;
    });
  },
  copySchToNewSch({ commit }, schedule) {
    return axios.post(templateString(config.SCHEDULER_COPY_TO_NEW_SCH_API), {
      params: {
        fromSchName: schedule.fromSchName ? schedule.fromSchName : '',
        toSchName: schedule.toSchName ? schedule.toSchName : '',
        newSchDesc: schedule.newSchDesc ? schedule.newSchDesc : '',
        toAccount: schedule.toAccount ? schedule.toAccount : '',
        forceCopy: schedule.forceCopy ? schedule.forceCopy : false,
        copyToColleaguesAcc: schedule.copyToColleaguesAcc ? schedule.copyToColleaguesAcc : false,
      },
    }).then(
      response => response,
    ).catch((error) => {
      throw error;
    });
  },
  copySchToExistingSch({ commit }, schedule) {
    return axios.post(templateString(config.SCHEDULER_COPY_TO_EXISTING_API), {
      params: {
        fromSchName: schedule.fromSchName ? schedule.fromSchName : '',
        toSchName: schedule.toSchName ? schedule.toSchName : '',
      },
    }).then(
      response => response,
    ).catch((error) => {
      throw error;
    });
  },
  getuserpublistfiletype({ commit }, publistArray) {
    return axios.get(templateString(config.FETCH_USER_PUBLIST_FILE_TYPE_API), {
      params: {
        data: publistArray,
      },
    }).then(
      response => response,
    ).catch((error) => {
      throw error;
    });
  },
  getAllUsersForCompany({ commit }, companyNameVal) {
    return axios.get(templateString(config.FETCH_ACTIVE_USER_FOR_COMPANY_API), {
      params: {
        companyName: companyNameVal,
      },
    }).then(
      response => response,
    ).catch((error) => {
      throw error;
    });
  },
};


export default actions;

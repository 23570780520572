const state = {
  auditLogs: {},
  dataAlertLogs: {},
  dataAlertFeeds: [],
  pageStatus: 'LOADING',
  timeFrame: [
    {
      text: 'Last Edit',
      value: 'last_edit',
    },
    {
      text: 'Last Week',
      value: 'last_week',
    },
    {
      text: 'Last Month',
      value: 'last_month',
    },
    {
      text: 'Last Three Months',
      value: 'last_three_months',
    },
    {
      text: 'Last Year',
      value: 'last_year',
    },
  ],
  logTypes: [
    {
      text: 'Bloomberg',
      value: 'bloomberg',
    },
    {
      text: 'Feeds',
      value: 'feeds',
    },
  ],
};
export default state;

import MpKeyValue from "../keyvalue/keyvalue.es6"
import Arrays from "../../arrays.es6";
import {indexBy, reduce} from "underscore";
import {requireNonEmptyString} from "../../strings.es6";

export default class MpKeySet {

    /**
     * A set of MpKeyValue (aka key-values)
     * @param {MpKeyValue[]} keyValues At least one MpKeyValue object.
     * @constructor
     */
    constructor (keyValues) {

        if (   !Arrays.isArrayOf(keyValues, MpKeyValue)
            || keyValues.length === 0 ) {
            throw new TypeError("keyValues: Array-of-MpKeyValue, non-empty");
        }

        let index = indexBy(keyValues, keyValue => keyValue.name());

        if (keyValues.length !== _.keys(index).length) {
            throw new Error("UnsupportedOperationException: duplicate key name");
        }

        let clone = Arrays.slice(keyValues);
        clone.sort(MpKeyValue.compare);

        this._list  = Object.freeze(clone);
        this._index = Object.freeze(index);

        Object.freeze(this);
    }

    /**
     * Returns a list of MpKeyValue objects, sorted by key name.
     * The returned array is immutable; callers must clone the array
     * before modifying it.
     * @returns {MpKeyValue[]}
     */
    list () { return this._list; }

    /**
     * Returns the MpKeyValue object for the given `name`.
     * @param {string} keyName Key name.
     * @returns {MpKeyValue}
     */
    keyValue (keyName) {
        requireNonEmptyString(keyName, "keyName");
        let index = this._index;
        if (!index.hasOwnProperty(keyName)) {
            throw new Error("IllegalArgumentException: `keyName` not found (" + keyName + ")");
        }
        return index[keyName];
    }

    /**
     * @param {(MpKeySet|*)} that
     * @returns {boolean} Whether `that` represents the same list of key-values.
     */
    equals (that) {
        return (   (that instanceof MpKeySet)
                && Arrays.areEqual(this._list, that._list, MpKeyValue.areEqual) );
    }

    /**
     * Returns a JSON object representing this key-set, in which key names
     * become properties of the object.
     * @returns {Object} A mutable object, caller can modify.
     */
    toJson () {
        return reduce(this._list, function (memo, keyValue) {
            memo[keyValue.name()] = keyValue.value();
            return memo;
        }, {});
    }

    /**
     * Returns a string representation of this key-set:
     * a JSON like string, in which the keys are sorted
     * by name.
     *
     * @returns {string} A JSON string, can be parsed with `JSON.parse()`.
     */
    toString () {
        let rv = [];
        for (let keyValue of this._list) {
            rv.push(  JSON.stringify(keyValue.name())
                    + ':'
                    + JSON.stringify(keyValue.value()) );
        }
        return '{' + rv.join(',') + '}';
    }
}

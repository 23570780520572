<template>
  <div>
    <div
      v-if="loader"
      id="mds-overlay"
      class="mds-overlay"
    >
      <mds-loader class="loader-vertically-center" />
    </div>
    <div class="mds-header border-bottom-solid">
      <h1 class="mds-header__title component-title">
        {{ $t('labels.bbLoader.newYellowKey') }}
      </h1>
      <div class="mds-header__actions">
        <router-link
          :to="{ path: '/bb/yellowKey/list' }"
          class="mds-list-group__link hide-actions"
          replace
        >
          <button
            class="mds-button mds-button--flat"
            type="button"
          >
            <svg
              class="mds-icon mds-button__icon mds-button__icon--left"
              aria-hidden="true"
            >
              <use xlink:href="#list" />
            </svg>
            <span class="mds-button__text mcd-page__content-hide--600">
              {{
                $t('labels.bbLoader.yellowKeyList')
              }}
            </span>
          </button>
        </router-link>
      </div>
    </div>
    <form
      class="mds-form mds-form--medium-layout custom_form"
      method
      action
    >
      <fieldset class="mds-form__fieldset">
        <legend class="mds-form__legend" />
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="yellowKeyName"
          >
            {{ $t('labels.bbLoader.yellowKeyName') }}
            <abbr
              class="mds-form__label-required-indicator"
            >*</abbr>
          </label>
          <input
            id="yellowKeyName"
            v-model="yellowKeyName"
            class="mds-form__input custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
            type="text"
            maxlength="45"
          >
          <span
            v-if="!isValidYellowKeyName"
            id="ykNameError"
            class="mds-form__field-error"
            role="alert"
          >
            <span class="mds-form__field-error-text">{{ $t('labels.common.propNameCheck') }}</span>
          </span>
        </div>
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="yellowKeyDesc"
          >
            {{ $t('labels.bbLoader.yellowKeyDesc') }}
          </label>
          <textarea
            id="yellowKeyDesc"
            v-model="yellowKeyDesc"
            class="mds-form__textarea custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
          />
        </div>
        <div class="mds-form__field-group">
          <div class="mds-header border-bottom-solid">
            <mds-form>
              <mds-fieldset horizontal>
                <mds-fieldset variation="checkbox-group">
                  <label
                    class="mds-form__label"
                    for="yellowKeyFields"
                  >
                    {{ $t('labels.bbLoader.ykValueFields') }}
                    <abbr class="mds-form__label-required-indicator">*</abbr>
                  </label>
                  <mds-checkbox
                    v-for="(key, index) in yellowKeyFieldsList "
                    :key="'field' + index"
                    name="checkbox-name"
                    class="checkbox-inline"
                    :value="key"
                    @change="setYellowKeyFields(key,$event)"
                  >
                    {{ key }}
                  </mds-checkbox>
                </mds-fieldset>
                <mds-fieldset>
                  <p style="padding-left:40px" />
                </mds-fieldset>
                <mds-fieldset variation="checkbox-group">
                  <label
                    class="mds-form__label"
                    for="yellowKeyMetas"
                  >
                    {{ $t('labels.bbLoader.yellowKeyMetas') }}
                  </label>
                  <mds-checkbox
                    v-for="(key, index) in yellowKeyMetaList "
                    :key="'meta' + index"
                    name="checkbox-name"
                    class="checkbox-inline"
                    :value="key"
                    @change="setYellowKeyMetas(key,$event)"
                  >
                    {{ key }}
                  </mds-checkbox>
                </mds-fieldset>
              </mds-fieldset>
            </mds-form>
          </div>
        </div>
      </fieldset>
      <div class="demo-button-container">
        <button
          id="btnBack"
          class="mds-button val_pad mds-button--primary font-size-small new-feed-save-button"
          type="button"
          @click="goBack"
        >
          {{ $t('labels.group.groupBackButton') }}
        </button>
        <button
          id="btnSave"
          class="mds-button mds-button--primary font-size-small new-feed-save-button"
          type="button"
          @click="formSubmit"
        >
          {{ $t('labels.bbLoader.saveYellowKey') }}
        </button>
        <span
          v-if="formError"
          id="formError"
          class="mds-form__field-error"
          role="alert"
        >
          <span class="mds-form__field-error-text">{{ $t('labels.common.invalidForm') }}</span>
        </span>
        <span
          v-if="errorText != ''"
          id="pageError"
          class="mds-form__field-error"
          role="alert"
        >
          <span class="mds-form__field-error-text">{{ errorText }}</span>
        </span>
      </div>
    </form>
  </div>
</template>
<script>
import MdsForm from '@mds/form';
import MdsFieldset from '@mds/fieldset';
import MdsCheckbox from '@mds/checkbox';
import { mapActions, mapState } from 'vuex';
import { checkSpecialChars } from '../../utils/stringUtils';

export default {
  name: 'BBNewYellowKey',
  components: {
    MdsForm,
    MdsFieldset,
    MdsCheckbox,
  },
  data() {
    return {
      loader: false,
      yellowKeyName: '',
      yellowKeyDesc: '',
      yellowKeyFields: [],
      yellowKeyMetas: [],
      errorText: '',
      formError: false,
      yellowKeyFieldsList: [],
      yellowKeyMetaList: [],
    };
  },
  computed: {
    ...mapState('bloombergModule', ['yellowKeyMappings']),
    isValidYellowKeyName() {
      return !this.yellowKeyName || this.yellowKeyName === '' || checkSpecialChars(this.yellowKeyName);
    },
  },
  created() {
    this.setYellowKeyFieldsList('default');
    this.loader = false;
  },
  methods: {
    ...mapActions('bloombergModule', ['addYellowKey', 'getYellowKeyMappings']),
    validateForm() {
      if (
        !(
          this.yellowKeyName
          && this.isValidYellowKeyName
          && this.yellowKeyFields
          && this.validateFields()
        )
        || this.yellowKeyName.length <= 0
        || this.yellowKeyFields.length <= 0
      ) {
        this.formError = true;
        return false;
      }

      this.formError = false;
      return true;
    },
    formSubmit() {
      this.errorText = '';
      if (!this.validateForm()) {
        return;
      }

      this.loader = true;

      const payload = {
        name: this.yellowKeyName,
        desc: this.yellowKeyDesc,
        fields: this.getFieldsStr(),
      };
      this.addYellowKey(payload)
        .then((response) => {
          if (response.StatusCode === 200) {
            this.goToYellowKeyDetails(this.yellowKeyName);
          }
        })
        .catch((error) => {
          this.loader = false;
          this.errorText = error.response.data.Message;
        });
    },
    goToYellowKeyDetails(ykName) {
      this.$router.push({
        path: `/bb/yellowKey/${ykName}`,
        params: {
          keyName: ykName,
        },
      });
      this.loader = false;
    },
    goBack() {
      this.$router.push({
        path: '/bb/yellowKey/list',
      });
      this.loader = false;
    },
    setYellowKeyFieldsList(yellowKey) {
      if (typeof this.yellowKeyMappings === 'undefined' || this.yellowKeyMappings.length <= 0) {
        this.getYellowKeyMappings(yellowKey).then((resp) => {
          this.setYellowKeyMappings(yellowKey);
        })
          .catch((error) => {
            this.currentStatus = 'ERROR';
            this.pageError = error.response.data.Message ? error.response.data.Message : '';
          });
      } else {
        this.setYellowKeyMappings(yellowKey);
      }
    },
    setYellowKeyMappings(yellowKey) {
      this.yellowKeyFields = [];
      this.yellowKeyMetas = [];
      this.yellowKeyMetaList = [];
      this.yellowKeyFieldsList = [];
      for (let i = 0; i < this.yellowKeyMappings.length; i++) {
        if (this.yellowKeyMappings[i].name === yellowKey) {
          this.yellowKeyFieldsList = this.yellowKeyMappings[i].fields;
          this.yellowKeyMetaList = this.yellowKeyMappings[i].metas;
          break;
        }
      }
    },
    getFieldsStr() {
      const fieldsArr = [];
      this.yellowKeyFields.forEach((field) => {
        fieldsArr.push(`${field }:0`);
      });
      this.yellowKeyMetas.forEach((field) => {
        fieldsArr.push(`${field }:1`);
      });
      return fieldsArr.join('||');
    },
    validateFields() {
      let retVal = true;
      this.yellowKeyFields.forEach((field) => {
        if (this.yellowKeyMetas.indexOf(field) > -1) {
          retVal = false;
        }
      });
      if (this.yellowKeyFields.length === 0) {
        retVal = false;
      }
      return retVal;
    },
    setYellowKeyMetas(meta, checked) {
      this.addValueToList(this.yellowKeyMetas, meta, checked);
    },
    setYellowKeyFields(field, checked) {
      this.addValueToList(this.yellowKeyFields, field, checked);
    },
    addValueToList(list, field, checked) {
      const fieldIndex = list.indexOf(field);
      if (checked && fieldIndex < 0) {
        list.push(field);
      } else if (!checked && fieldIndex > -1) {
        list.splice(fieldIndex, 1);
      }
    },
  },

};
</script>
<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/new_feed';
</style>

<template>
  <div>
    <div
      v-if="loader"
      id="mds-overlay"
      class="mds-overlay"
    >
      <mds-loader class="loader-vertically-center" />
    </div>
    <template v-if="pageStatus == 'SUCCESS'">
      <div class="mds-header border-bottom-solid">
        <h1 class="mds-header__title component-title">
          {{ $t('labels.bbLoader.yellowKeyList') }}
        </h1>
        <div class="mds-header__actions">
          <div
            class="mds-search-field"
            role="search"
          >
            <input
              id="searchField"
              v-model="searchFilter"
              type="text"
              class="mds-search-field__input"
              aria-label="Search"
              :placeholder="$t('labels.bbLoader.searchYellowKey')"
              @keyup="filterYellowKey($event.target.value)"
            >
            <svg
              class="mds-icon mds-search-field__search-icon"
              aria-hidden="true"
            >
              <use xlink:href="#search--s" />
            </svg>
            <button
              class="mds-button mds-search-field__clear-button mds-button--small mds-button--icon-only"
              type="button"
              aria-label="Clear search field"
              @click="clearSearchBar()"
            >
              <svg
                class="mds-icon mds-button__icon mds-button__icon--right"
                aria-hidden="true"
              >
                <use xlink:href="#remove--s" />
              </svg>
            </button>
            <span class="mds-search-field__input-focus-outline" />
          </div>

          <router-link
            :to="{path: 'new'}"
            class="mds-list-group__link hide-actions"
            replace
          >
            <button
              class="mds-button mds-button--flat"
              type="button"
            >
              <svg
                class="mds-icon mds-button__icon mds-button__icon--left"
                aria-hidden="true"
              >
                <use xlink:href="#plus" />
              </svg>
              <span
                class="mds-button__text mcd-page__content-hide--600"
              >{{ $t('labels.bbLoader.newYellowKey') }}</span>
            </button>
          </router-link>
        </div>
      </div>
      <div style="margin-top:30px;">
        <mds-button-group class="hide-actions margin-top-20">
          <mds-button
            icon="list--s"
            variation="secondary"
            type="radio"
            name="with-icons-mdswc"
            value="Config List"
            @click="goToConfigList"
          >
            {{ $t('labels.bbLoader.configList') }}
          </mds-button>
          <mds-button
            icon="list--s"
            variation="secondary"
            type="radio"
            name="with-icons-mdswc"
            value="Account List"
            @click="goToAccountList"
          >
            {{ $t('labels.bbLoader.accountList') }}
          </mds-button>
        </mds-button-group>
      </div>
      <div style="margin-top:30px;">
        <table class="mds-data-table mds-data-table--large">
          <caption class="mds-data-table__caption">
            {{ $t('labels.bbLoader.listTableCaption') }}
          </caption>
          <thead class="mds-data-table__header">
            <tr class="mds-data-table__row">
              <th
                class="mds-data-table__header-cell"
                scope="col"
              >
                <div
                  class="mds-data-table__header-cell-inner mds-data-table__header-cell-inner--selected-v"
                  :class=" currentSort == 'name' ?
                    {'mds-data-table__header-cell-inner--selected-descending': (currentSortDir === 'desc'),
                     'mds-data-table__header-cell-inner--selected-ascending': (currentSortDir === 'asc')}:''"
                  @click="sort('name')"
                >
                  {{ $t('labels.bbLoader.yellowKeyName') }}
                </div>
              </th>
            </tr>
          </thead>

          <tbody v-if="yellowKeyList.length === 0">
            <tr>
              <span
                id="error-id"
                class="mds-form__field-error mds-form__field-error--large"
                role="alert"
              >
                <span class="mds-form__field-error-text">{{ $t("labels.bbLoader.noYellowKey") }}</span>
              </span>
            </tr>
          </tbody>

          <tbody
            v-else
            class="mds-data-table__body"
          >
            <tr
              v-for="(key, index) in activeYellowKeyList"
              :key="index"
              class="mds-data-table__row cursor-pointer"
              @click="goToYellowKeyDetails(key.name);"
            >
              <td
                class="mds-data-table__cell"
                v-html="key.name"
              />
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else-if="pageStatus == 'ERROR'">
      <div class="reports-error">
        <mds-notification
          visible
          title="ERROR"
          status="error"
          dismissible="false"
        >
          {{ errorMessage || $t('labels.bbLoader.yellowKeyListError') }}
        </mds-notification>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'BBYellowKeyList',
  data() {
    return {
      loader: false,
      pageStatus: 'SUCCESS',
      errorMessage: '',
      currentSort: 'name',
      currentSortDir: 'asc',
      searchFilter: '',
      yellowKeyList: [],
      activeYellowKeyList: [],
    };
  },
  created() {
    this.loader = true;
    this.getYellowKeyList()
      .then((response) => {
        if (response && response.data) {
          this.yellowKeyList = response.data;
          this.activeYellowKeyList = this.yellowKeyList;
        } else {
          this.pageStatus = 'ERROR';
        }
        this.loader = false;
      })
      .catch((error) => {
        this.pageStatus = 'ERROR';
        this.errorMessage = error;
        this.loader = false;
      });
  },
  methods: {
    ...mapActions('bloombergModule', ['getYellowKeyList']),
    sort(sortType) {
      if (sortType === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = sortType;
      this.activeYellowKeyList = this.activeYellowKeyList.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') {
          modifier = -1;
        }
        if (a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) {
          return -1 * modifier;
        }
        if (a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) {
          return 1 * modifier;
        }
        return 0;
      });
    },
    goToAccountList(name) {
      this.$router.push({
        path: '/bb/account/list',
        params: {
          name,
        },
      });
    },
    goToConfigList(name) {
      this.$router.push({
        path: '/bb',
        params: {
          name,
        },
      });
    },
    goToYellowKeyDetails(name) {
      this.$router.push({
        path: `/bb/yellowKey/${name}`,
        params: {
          name,
        },
      });
    },

    filterYellowKey(txt) {
      this.searchFilter = txt;
      this.activeYellowKeyList = this.yellowKeyList.filter(item => item.name.toLowerCase().includes(txt.toLowerCase()));
    },
    clearSearchBar() {
      this.searchFilter = '';
      this.filterYellowKey('');
    },
  },
};
</script>

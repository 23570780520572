const config = {
  GET_AUDIT_LOG: 'api/auditlog/$[TIME_FRAME]',
  GET_DATA_ALERT_LOG: 'api/auditlog/dataalert',
  GET_DATAALERT_FEEDS_API: 'api/auditlog/dataalert/getFeeds/$[COMPANY_NAME]/$[USER_NAME]',
  SAVE_DATAALERT_FEEDS_API: 'api/auditlog/dataalert/saveFeeds/$[COMPANY_NAME]/$[USER_NAME]',
  DELETE_DATAALERT_FEEDS_API: 'api/auditlog/dataalert/deleteFeeds/$[COMPANY_NAME]/$[USER_NAME]',
};

export default function getAPIConfigs() {
  return config;
}

const { format } = require('logform');

const SERVICE_NAME = 'mcd-ui';
const { morningstar, tag } = require('./formats');
const { consoleLogger } = require('./loggers');

const formatter = (formats = []) => format.combine.apply(format, [
  ...formats,
  // tag({ cloudwatchToSplunk: true }),
  morningstar({ serviceName: SERVICE_NAME, env: process.env.ENV, trace: true }),
]);

let logLevel = 'debug';

if (process && process.env) {
  logLevel = process.env.LOG_LEVEL || logLevel;
}

module.exports = formats => consoleLogger(formatter(formats), { logLevel });

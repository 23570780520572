
import $ from 'jquery';
import Text from './lang/responseparser_en-us.es6';
import DataMatrix, { DataType } from '../../datamatrix';
import ServerError from '../../servererror.es6';
import Dates from '../../dates/dates.es6';
import Strings, { isString, requireString } from '../../strings.es6';
import Numbers from '../../numbers.es6';
import Console from '../../console.es6';
import { isVoid } from '../../objects.es6';

/**
 * @typedef Object JsFormulaParsedData.Column
 * @property {string} dataType "string", "number" or "date".
 */

/**
 * @typedef Object JsFormulaParsedData
 * @property {JsFormulaParsedData.Column[]} columns Column headers.
 * @property {Array[]} data The payload.
 */

/**
 * @typedef Object JsFormulaResponseParserColumnDataType
 * @property {string} name
 * @property {Function} converter
 * @property {*} defaultValue Default value for this data-type.
 */

/**
 * @param {*} o
 * @returns {string} String representation of `o`.
 * @private
 */
function _toStr(o) {
  if (isVoid(o)) {
    return '';
  } if (isString(o)) {
    return o;
  } if (o instanceof Object) {
    return JSON.stringify(o);
  }
  return o.toString();
}

/**
 * @enum {JsFormulaResponseParserColumnDataType}
 */
const DATA_TYPES = {
  DATE: _newRespParserColDataType('date', Dates.isoStringToUTCDate, null),
  NUMBER: _newRespParserColDataType('number', Numbers.parseFloat, Number.NaN),
  STRING: _newRespParserColDataType('string', _toStr, ''),
};

/**
 * @param {string} name
 * @param {Function} converter
 * @param {*} defaultValue
 * @returns {JsFormulaResponseParserColumnDataType}
 * @private
 */
function _newRespParserColDataType(name, converter, defaultValue) {
  return { name, converter, defaultValue };
}

/**
 * @param {string} val Value to evaluate.
 * @returns {JsFormulaResponseParserColumnDataType} Data-type of `val`.
 * @private
 */
function _getDataTypeFromString(val) {
  if (Numbers.isNumberString(val)) {
    return DATA_TYPES.NUMBER;
  } if (Dates.isIsoDate(val)) {
    return DATA_TYPES.DATE;
  }
  return DATA_TYPES.STRING;
}

/**
 * @param {*} o Object to analyze.
 * @returns {JsFormulaResponseParserColumnDataType} Data-type of `o`.
 * @private
 */
function _getDataTypeFromRaw(o) {
  const type = typeof o;
  if (type === 'number') {
    return DATA_TYPES.NUMBER;
  } if (type === 'string' && Dates.isIsoDate(o)) {
    // JSON doesn't support date objects, only strings, and there's no standard for that either.
    return DATA_TYPES.DATE;
  }
  return DATA_TYPES.STRING;
}

/**
 * Figures out data-type(s) per column.  It's possible a column has multiple data-types; callers beware!
 * @param {Array[]} array2d
 * @param {Function.<JsFormulaResponseParserColumnDataType, *>} dataTypeGetter
 * @returns {Array.<JsFormulaResponseParserColumnDataType[]>} List of possible data-type(s), per column.
 * @private
 */
function _getDataTypes(array2d, dataTypeGetter) {
  const dataTypes = [];
  const numRows = Math.min(array2d.length, 200); // scan 200 rows max; it's good-enough sample.
  let dataType;

  for (let i = 0; i < numRows; i++) {
    const row = array2d[i];
    const numCols = row.length;
    for (let j = 0; j < numCols; j++) {
      if (dataTypes.length <= j) {
        // initialize data-type object for this column
        dataTypes.push({});
      }
      dataType = dataTypeGetter(row[j]);
      dataTypes[j][dataType.name] = dataType;
    }
  }
  return dataTypes.map(Object.values);
}

/**
 * Safely parses JSON, returning `fallbackValue` if a parse failure occurs.
 * @param {string} s
 * @param {?(Object|Array)} fallbackValue
 * @returns {?(Object|Array)} Parsed `s`, or `fallbackValue` if a parsing failure occurs.
 * @private
 */
function _safeJsonParse(s, fallbackValue) {
  try {
    return JSON.parse(s);
  } catch (e) {
    return null;
  }
}

/**
 * Converts a 1-, 2- or n- dimensional array into table-able payload.  If a multi-dimensional array, anything beyond
 * the 2nd level is converted to string, using bare-bone *toString* functionality.
 * @param {Array} array 1-, 2- or n- dimensional array.
 * @returns {JsFormulaParsedData} Table-able content.
 * @private
 */
function _tableableJsonArray(array) {
  return _tableable2dArray(
    array.map((row) => {
      if (Array.isArray(row)) {
        return row;
      }
      return [row];
    }),
    _getDataTypeFromRaw,
  );
}

/**
 * Assumes a CSV payload, parses it, scans it for data-types and ensures all rows have equal lengths
 * (pads rows of necessary).
 *
 * If payload cannot be parsed as CSV, a one-row, one-cell "table" is created.
 *
 * @param {string} payload
 * @returns {JsFormulaParsedData} Table-able content.
 * @private
 */
function _tableableCsv(payload) {
  // Convert to 2D array
  let csv = $.csv.toArrays(payload);
  if (csv.length === 0) {
    csv = [[payload]];
  }
  return _tableable2dArray(csv, _getDataTypeFromString);
}

/**
 * Converts 2D array to
 * @param {Array[]} csv
 * @param {Function.<JsFormulaResponseParserColumnDataType, *>} dataTypeGetter
 * @returns {JsFormulaParsedData} Table-able content.
 * @private
 */
function _tableable2dArray(csv, dataTypeGetter) {
  // Nobody knows what these JS formulas can return; be lenient!

  /** @type {JsFormulaParsedData.Column[]} */
  const cols = [];
  const len = csv.length;

  _getDataTypes(csv, dataTypeGetter)
    .forEach((dataTypes, j) => {
      const dataType = ((dataTypes.length === 1) ? dataTypes[0] : DATA_TYPES.STRING);
      cols.push({
        dataType: dataType.name,
      });
      // Convert all values within this column to their string representations, pad the row as needed.
      for (let i = 0; i < len; i++) {
        const row = csv[i];
        if (j < row.length) {
          row[j] = dataType.converter(row[j]);
        } else {
          row.push(dataType.defaultValue);
        }
      }
    });

  return {
    columns: cols,
    data: csv,
  };
}

/**
 * Parses string payload
 * @param {string} payload
 * @returns {(JsFormulaParsedData|ServerError)}
 */
export function parseJsEngineResponse(payload) {
  requireString(payload, 'payload');
  if (Strings.isEmpty(payload)) {
    // No data.
    return {
      columns: [],
      data: [],
    };
  } if (payload.charAt(0) === '{') {
    // A JSON payload could indicate an error, but not necessarily.  It could be an error in the JS formula
    // or some problem with a back-end server; it could also be a legitimate JSON payload returned by the JS
    // formula (who knows what code people submit!?)
    //
    // We try to parse it as an error.  If parsing fails, we'll parse it as CSV/text downstream.
    const json = _safeJsonParse(payload, {});
    if (ServerError.isMpErrorResponse(json)) {
      return ServerError.fromMpErrorResponse(json);
    }
  } else if (payload.charAt(0) === '[') {
    // Looks like a JSON array.  Let's try to parse it, handling possible 2D array.
    // If successful, we ensure that non-primitive values within the array is converted to string.
    // If unsuccessful, we'll parse it as CSV/text downstream.
    const array = _safeJsonParse(payload, null);
    if (Array.isArray(array)) {
      return _tableableJsonArray(array);
    }
  }

  // No other format recognized? Assume CSV!
  try {
    return _tableableCsv(payload);
  } catch (e) {
    // CSV failed. Log the error, return a single row, single (string) cell table.
    Console.log('Failed to parse CSV: [{}]', e);
    return {
      columns: [{ dataType: DATA_TYPES.STRING.name }],
      data: [[payload]],
    };
  }
}

/**
 * Converts the raw results from the JS-engine into a DataMatrix object.
 * @param {(JsFormulaParsedData|ServerError)} payload JS-engine response; may contain an embedded
 *        error custom-built by the remote server, but `payload` will never represent an HTTP error (int).
 * @return {DataMatrix}
 */
export function convertJsEngineResponseToDataMatrix(payload) {
  if (payload instanceof ServerError) {
    return DataMatrix.newError(payload.toString());
  } if (isVoid(payload)
               || !Array.isArray(payload.columns)
               || !Array.isArray(payload.data)) {
    return DataMatrix.newError(Text.invalidExecPayload);
  } if (payload.data.length === 0) {
    return DataMatrix.newError(Text.noData);
  }
  const dataArray = new DataMatrix();
  const cols = payload.columns;
  if (cols.length > 0) {
    // Look for legacy forward-curve format
    if (cols.length === 4
                && cols[0].dataType === 'date'
                && cols[1].dataType === 'number'
                && cols[2].dataType === 'string'
                && cols[3].dataType === 'number') {
      dataArray
        .setHeader(0, Text.date, DataType.DATE)
        .setHeader(1, Text.derivedVal, DataType.NUMBER)
        .setHeader(2, Text.descr, DataType.STRING)
        .setHeader(3, Text.origVal, DataType.NUMBER);
    } else {
      let colCnt = 0;
      let text = null;
      for (let i = 0; i < cols.length; i++) {
        // noinspection JSValidateTypes
        /** @type {EnumItem} */
        const dataType = DataType.valueOf(cols[i].dataType);

        if (dataType === DataType.DATE) {
          text = Text.date;
        } else if (dataType === DataType.STRING) {
          text = Text.traceColumn.replace('[col_index]', (colCnt).toString(10));
        } else {
          text = (++colCnt).toString(10);
        }
        dataArray.setHeader(i, text, dataType);
      }
    }
  }
  dataArray.setData(payload.data);
  return dataArray;
}

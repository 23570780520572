<template>
  <div class="power-forecast-chart-container" />
</template>

<script>
import ComponentResizeMixin from '../../../mixins/componentResize';
import EventBus from '../../../main';

const d3 = require('d3');
// eslint-disable-next-line import/no-extraneous-dependencies
const TimeSeriesLineChart = require('mbc-time-series-line-chart');
// eslint-disable-next-line import/no-extraneous-dependencies
const Core = require('mbc-core');

export function generatePfChart(el, chartData, config) {
  if (!d3 || !Core || !TimeSeriesLineChart) {
    return null;
  }

  const container = d3.select ? d3.select(el) : null;

  if (container === null) {
    return null;
  }

  el.innerHTML = ''; // eslint-disable-line no-param-reassign

  const defaultConfig = {
    showToolTip: true,
    y2: false,
    showLegendValues: false,
  };

  const mergedConfig = { ...defaultConfig, ...config };

  const PowerForecastChart = TimeSeriesLineChart.extend({

    drawAreas() {
      const data = this.get('data')._data;

      const mergedData = data.reduce((acc, item) => {
        if (item.name.includes('Min') || item.name.includes('Max')) {
          item.series.forEach((a, index) => {
            if (acc[index] && acc[index].date.toString() === a.date.toString()) {
              acc[index]['FiveYearMin'] = a.value;
            } else {
              acc.push({
                date: a.date,
                FiveYearMax: a.value,
              });
            }
          });
        }
        return acc;
      }, []);

      const vlineData = data.reduce((acc, item, index) => {
        if (index === 2) { // use index as names changes for feeds
          item.series.forEach((a) => {
            acc.push({
              date: a.date,
              value: a.value,
            });
          });
        }
        return acc;
      }, []);

      const canvas = this.get('canvas');
      const scales = this.get('scales');
      const xScale = scales.scale('x');
      const yScale = scales.scale('y1');
      const layer = this.get('canvas').layer('layer-area');
      const verticalLineLayer = this.get('canvas').layer('layer-vertical-line');
      const color = '#5e5e5e';

      const areaSvg = d3.svg.area()
        .x(d => xScale(d.date))
        .y0(d => yScale(d.FiveYearMin))
        .y1(d => yScale(d.FiveYearMax));

      const areas = layer.selectAll('.area').data([1]);

      areas
        .enter()
        .append('path')
        .classed('area', true)
        .attrs({
          d: areaSvg(mergedData),
          stroke: 'none',
          fill: color,
          'fill-opacity': 0.25,
          'shape-rendering': 'auto',
        });


      verticalLineLayer.append('line')
        .attr('class', 'zero')
        .attr('stroke', 'black')
        .attr('stroke-dasharray', '2,2')
        .attr('x1', xScale(vlineData[vlineData.length - 1].date))
        .attr('y1', 0)
        .attr('x2', xScale(vlineData[vlineData.length - 1].date))
        .attr('y2', canvas.height());
    },

    afterDraw() {
      this.__super__.afterDraw.call(this);
      this.drawAreas();
    },

    /**
     * @method  adjustYDomain
     * @desc    Calculate and return a buffer for the y domain
     *
     * @example
     *      var adjustedY1Domain = this.adjustYDomain(dataset)
     * @param   {Object} dataset
     *
     * @return  {Array} extent
     */
    adjustYDomain(dataset, symmetrical) {
      // add buffer spacing between datapoints and axix
      const extent = d3.extent(dataset);
      const range = extent[1] - extent[0];
      const minMagnitude = this.getMagnitude(extent[0]);
      const maxMagnitude = this.getMagnitude(extent[1]);
      const setLowerBoundZero = extent[0] >= 0 && (minMagnitude < maxMagnitude) && (range > Math.abs(extent[0]));
      // overrided this as it rendomly add zero to lower bound to few charts
      // var lowerBound = setLowerBoundZero ? 0 : Math.floor(extent[0] - (range * 0.05));
      let lowerBound = Math.floor(extent[0] - (range * 0.05));
      lowerBound = extent[0] < 0 && (minMagnitude < maxMagnitude) ? extent[0] : lowerBound;
      const adjusted = [lowerBound, Math.ceil(extent[1] + (range * 0.05))];
      if (symmetrical) {
        return this.makeSymmetrical(adjusted);
      }

      return adjusted;
    },

    updateTickWidths() {
      // [CUSTOM]  override MBC infinite loop bug with extending afterDraw
      const axes = this.get('axes');
      if (this.get('ticksUpdated')) {
        this.set('ticksUpdated', false);
        return;
      }

      let maxWidth = 0;
      const ticks = [];
      const y1TickWidth = this.get('y1TickWidth');

      if (y1TickWidth) {
        this.set('yTickSize', y1TickWidth);
      } else {
        d3.selectAll('.mbc-axis.mbc-axis-y1 .tick text').each(function pushText() {
          const elem = d3.select(this);
          ticks.push(elem.text());
        });
        maxWidth = Core.Utils.svg.getGreaterSVGTextWidth(
          this.get('canvas').get('svg'), ticks,
        );
        this.set('yTickSize', maxWidth + this.get('yTickMargin'));
      }

      this.set('ticksUpdated', true);
      this.update({ noTicksRecalc: true });
    },
  });

  const pfChart = new PowerForecastChart(mergedConfig);

  container.datum(chartData).call(pfChart.init());

  return pfChart;
}

export default {
  name: 'PowerForcaseChart',
  mixins: [ComponentResizeMixin],
  props: {
    chartData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    config: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    calculatedConfig() {
      // Resolution < 1600px would only display the 1st and last labels on x-axis
      return {
        ...this.config,
      };
    },
  },
  watch: {
    chartData: {
      handler(newValue, oldValue) {
        if (!this.$_pfChart && this.dataIsReady()) {
          this.renderChart();
        }
        const formattedData = this.formatData(this.chartData);
        if (this.$_pfChart) {
          this.$_pfChart.data(formattedData).update();
        }
      },
      deep: true,
    },
    config() {
      this.renderChart();
    },
    elementWidth() {
      if (!this.$_pfChart && this.dataIsReady()) {
        this.renderChart();
      }

      if (this.$_pfChart) {
        this.$_pfChart.update();
      }
    },
  },
  async mounted() {
    if (this.dataIsReady()) {
      const formattedData = this.formatData(this.chartData);
      this.$_pfChart = generatePfChart(
        this.$el,
        formattedData,
        this.calculatedConfig,
      );
    }
    this.$nextTick(() => {
      EventBus.$emit('readyChart', true);
    });
  },
  methods: {
    renderChart() {
      const { $el, calculatedConfig, chartData } = this;
      const formattedData = this.formatData(chartData);
      this.$_pfChart = generatePfChart(
        $el,
        formattedData,
        calculatedConfig,
      );
    },
    dataIsReady() {
      return (
        Array.isArray(this.chartData.y1)
        && this.chartData.y1.length > 0
      );
    },
    formatData(data) {
      return Object.keys(data).reduce((acc, dataKey) => {
        acc[dataKey] = data[dataKey].map(yData => ({
          ...yData,
          date: new Date(yData.date),
        }));

        return acc;
      }, {});
    },
  },
};
</script>

<style lang="scss">
@import '~mbc/dist/mbc-line.css';
@import '~@mds/constants';
@import '~@mds/typography';
@import '~@mds/link';

.power-forecast-chart-container {
  height: 500px;
  width: 100%;
  .line  {
    stroke-width: 3;
    opacity: 0.9;
    transition: 0.2s ease-in-out all;
  }
  .line:nth-child(4){
    opacity: 0.1;
  }
  .line:nth-child(5){
    opacity: 0.1;
  }
  .grid__bars {
    &:nth-child(odd) {
      fill: #eeeeee !important;
    }
    &rect:nth-child(odd) {
      opacity: 0;
    }
  }
  text {
    font-size: 15px;
  }
}

</style>

/* eslint-disable no-use-before-define */

import Numbers, { requirePositiveInteger } from './numbers.es6';
import Arrays from './arrays.es6';

import Strings, { requireString, requireNonEmptyString } from './strings.es6';
import { requireFunction } from './functions.es6';
import Dates, { requireDate } from './dates/dates.es6';

const EOL = '\n';
const INVALID_CHARS_REGEX = new RegExp('[\n\r\t,"]');


/**
 * Validates an array
 * @param array
 * @param requiredLength
 * @returns {Array} Always returns `array`
 * @throws {TypeError} If `array` is not an Array.
 * @throws {Error} If the length of the array is invalid.
 * @private
 */
function _validArray(array, requiredLength) {
  if (!Array.isArray(array)) { throw new TypeError('array: Array'); }

  const len = array.length;

  if (arguments.length > 1) {
    if (len !== requirePositiveInteger(requiredLength, 'requiredLength')) {
      throw new Error(`array: length=${ len }, expected ${ requiredLength}`);
    }
  } else if (len === 0) {
    throw new Error('array is empty');
  }

  return array;
}

function _checkBadChar(s, argName) {
  const badChar = _firstBadCharUnchecked(s);
  if (badChar >= 0) { throw new Error(`${argName }: bad character at index ${ badChar } (${ s })`); }

  return s;
}

function _validArrayString(array, requiredLength) {
  _validArray(...arguments);

  Arrays.requireValid(array, (item) => {
    requireNonEmptyString(item, 'item');
    _checkBadChar(item, 'item');
    return true;
  }, 'array');
}

function _validArrayNumOrString(array, requiredLength) {
  _validArray(...arguments);

  const bad = Arrays.indexOfInvalid(array, _isNumOrString);
  if (bad >= 0) {
    throw new Error(`array[${ bad }] must be Number or String (without invalid characters)`);
  }

  return array;
}

function _isNumOrString(item) {
  const typeOf = typeof item;

  return (typeOf === 'number' // Allow for NaN, +Infinity, -Infinity
            || (typeOf === 'string'
                && _firstBadCharUnchecked(item) < 0));
}

function _firstBadCharUnchecked(s) {
  const m = INVALID_CHARS_REGEX.exec(s);
  return ((m !== null) ? m.index : -1);
}

function _cleanNumbers(values) {
  return values.map(v => Numbers.toString(v));
}

/**
 * Validates `s` to be no longer than `maxLen`.
 * @param {string} s
 * @param {int} maxLen
 * @param {string} argName
 * @returns {string} Always returns `s`.
 * @throws {Error} If `s.length > maxLen'.
 * @private
 */
function _checkMaxLen(s, maxLen, argName) {
  if (s.length > maxLen) {
    throw new Error(`${argName }: exceeds maximum length [${ maxLen }]: [${ s }]`);
  }
  return s;
}

export default class CommonDataFormat {
  constructor(keyNames, colNames, dateFormatter) {
    _validArrayString(keyNames);
    _validArrayString(colNames);
    requireFunction(dateFormatter, 'dateFormatter');

    this._keys = keyNames.slice(0); // shallow copy
    this._cols = colNames.slice(0); // shallow copy

    this._dateF = dateFormatter;
    this._rows = [];
    this._name = null;
    this._eol = EOL;
  }

  /**
     * Gets or sets the name.
     * @param {string} [name] Name to give the instance.
     * @returns {(?string|CommonDataFormat)} May return null if the name hasn't been set.
     */
  name(name) {
    if (arguments.length < 1) {
      return this._name;
    }

    requireString(name, 'name');

    if (name.length < 5
                || !Strings.endsWith(name, '.cdf')) throw new Error('name: must be a String ending with ".cdf"');

    this._name = name;
    return this;
  }

  /**
     * Gets or sets the character(s) to be used as line-break:
     * '\n', '\r', "\r\n", "\n\r", or any other non-empty String.
     * @param {string} [lineBreak='\n']
     * @returns {(string|CommonDataFormat)}
     */
  lineBreak(lineBreak) {
    if (arguments.length < 1) {
      return this._eol;
    }
    this._eol = requireNonEmptyString(lineBreak, 'lineBreak');
    return this;
  }


  _addRow(keys, date, values) {
    const row = [];

    Arrays.addAll(row, keys); // Keys
    row.push(this._dateF(date)); // Date
    Arrays.addAll(row, _cleanNumbers(values)); // Values

    this._rows.push(row.join(','));
  }


  addRow(date, keys, values) {
    requireDate(date, 'date');
    _validArrayString(keys, this._keys.length);
    _validArrayNumOrString(values, this._cols.length);

    this._addRow(keys, date, values.slice(0));

    return this;
  }

  addTimeSeries(keys, timeSeries) {
    _validArrayString(keys, this._keys.length);

    if (!Arrays.isArrayOf(timeSeries, Array)
            || timeSeries.length === 0) { throw new TypeError('timeSeries: Array[]'); }

    const numCols = this._cols.length + 1;

    for (let i = 0, len = timeSeries.length; i < len; i++) {
      const row = timeSeries[i];

      if (row.length !== numCols) { throw new Error(`timeSeries[${ i }]: mismatch number of values`); }

      requireDate(row[0], 'row[0]');

      for (let j = 1; j < numCols; j++) {
        if (!_isNumOrString(row[j])) { throw new Error(`timeSeries[${ i }][${ j }]: Number or String`); }
      }

      this._addRow(keys, row[0], row.slice(1));
    }

    return this;
  }

  /**
     * Returns the payload to be sent to Marketplace, as one String
     * object.
     * @returns {string} Payload to be sent to Marketplace, as one string.
     */
  getPayload() {
    const lb = this._eol;
    const tsFormat = 'yyyy-MM-ddTHH:mm:ss.SSSZ';
    const tsFormatter = Dates.getFormatter(tsFormat);

    return `# Produced by Markets JavaScript client${ lb
    }# Created on ${ tsFormatter(new Date()) }${lb

    }Def: ${ this._keys.join('*,') }*,`
                       + `Date,${
                         this._cols.join(',') }${lb

                       }${this._rows.join(lb)}`;
  }

  /**
     * @param {string} key
     * @returns {int} Index position of the first invalid character found in `key`.
     */
  static indexOfFirstInvalidCharInKey(key) {
    return _firstBadCharUnchecked(requireString(key, 'key'));
  }

  /**
     * Cleans the given key of any invalid characters.
     * @param {string} key Key to be cleaned.
     * @param {string} [replaceWith=""] No longer than one char.
     * @returns {string} Cleansed `key`.
     */
  static cleanKey(key, replaceWith) {
    requireString(key, 'key');

    const replacement = (
      (arguments.length > 1)
        ? _checkMaxLen(requireString(replaceWith, 'replaceWith'), 1, 'replaceWith')
        : ''
    );

    let clean = key;
    let idx = _firstBadCharUnchecked(clean);

    while (idx >= 0) {
      clean = clean.substring(0, idx)
                  + replacement
                  + clean.substring(idx + 1);
      idx = _firstBadCharUnchecked(clean);
    }

    if (replacement.length > 0) {
      const regex = new RegExp(`\\${ replacement }{2,}`, 'g');
      clean = clean.replace(regex, replacement);
    }

    return clean;
  }
}

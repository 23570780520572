<template>
  <div class="notifications-slidein">
    <div class="notifications-header">
      <h1 class="mds-header__title component-title">
        {{ $t('labels.notification.name') }}
      </h1>
      <mds-button
        id="notif-settings-pop"
        icon="gear"
        variation="flat"
        size="touch"
        @click="notifSettingsShow=!notifSettingsShow"
      />
      <mds-button
        class="close-notification"
        icon="remove"
        variation="flat"
        size="touch"
        @click="hideNotifs"
      />
      <mds-popover
        triggered-by="notif-settings-pop"
        :visible="notifSettingsShow"
        :position="['bottom-left' , 'bottom-center']"
        class="notif-settings-popover"
        width="200px"
        fixed
      >
        <div>
          <mds-list-group>
            <div class="notif-popover-heading">
              {{ $t('navigation.settings') }}
            </div>
            <mds-list-group-item
              class="dark-top-border"
              @mds-list-item-clicked="showGlobalSettings"
            >
              {{ $t('navigation.notificationSettings') }}
            </mds-list-group-item>
            <mds-list-group-item
              @mds-list-item-clicked="markRead"
            >
              {{ $t('labels.notification.markRead') }}
            </mds-list-group-item>
          </mds-list-group>
        </div>
      </mds-popover>
    </div>
    <div class="notifications-body">
      <mds-layout-grid>
        <mds-row>
          <template v-if="userNotifs.length ===0">
            <div class="no-notifs">
              {{ $t('labels.notification.noNewNotifs') }}
            </div>
          </template>
          <template
            v-for="(auditlog,index) in userNotifs"
            v-else
          >
            <ul
              v-if="auditlog.log_type==='DataAlert_Log'"
              :key="'log' + index"
              class="notifications-row"
            >
              <li>{{ $t('labels.notification.logType') }}: {{ auditlog.log_type }}</li>
              <li>{{ $t('labels.notification.date') }}: {{ auditlog.created_time }}</li>
              <li>{{ $t('labels.notification.resolutionDate') }}: {{ auditlog.resolutionDate }}</li>
              <li>{{ $t('labels.notification.feed') }}: {{ auditlog.feed }}</li>
              <li>{{ $t('labels.notification.by') }}: {{ auditlog.login }}</li>
              <li>{{ $t('labels.notification.status') }}: {{ auditlog.status }}</li>
              <li>{{ $t('labels.notification.message') }}: {{ auditlog.message }}</li>
              <hr>
            </ul>
            <ul
              v-else
              :key="'log' + index"
              class="notifications-row"
            >
              <li>{{ $t('labels.notification.logType') }}: {{ auditlog.log_type }}</li>
              <li>{{ $t('labels.notification.date') }}: {{ auditlog.created_time }}</li>
              <li>{{ $t('labels.notification.by') }}: {{ auditlog.login }}</li>
              <li>{{ $t('labels.notification.action') }}: {{ auditlog.action }}</li>
              <li>{{ $t('labels.notification.message') }}: {{ auditlog.message }}</li>
              <li v-if="auditlog.log_type==='Feed_Log'">
                {{ $t('labels.notification.impacted') }}: {{ auditlog.impacted_user }}
              </li>
              <hr>
            </ul>
          </template>
        </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>
<script>
import { MdsButton } from '@mds/button';
import { MdsLayoutGrid, MdsRow } from '@mds/layout-grid';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group-v4';
import MdsPopover from '@mds/popover';
import EventBus from '../main';

export default {
  name: 'Notifications',
  components: {
    MdsLayoutGrid, MdsRow, MdsPopover, MdsButton, MdsListGroup, MdsListGroupItem,
  },
  props: {
    userNotifs: { type: Array, default: null },
  },
  data() {
    return {
      notifSettingsShow: false,
    };
  },
  methods: {
    markRead() {
      this.notifSettingsShow = false;
      EventBus.$emit('markNotifsRead', true);
    },
    hideNotifs() {
      EventBus.$emit('hideNotifs');
    },
    showGlobalSettings() {
      this.notifSettingsShow = false;
      EventBus.$emit('showGlobalSettings');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/notifications';
</style>

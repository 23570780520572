<template>
  <div>
    <mds-loader
      v-if="showMainLoader"
      aria-label="Default Loader"
    />
    <div
      v-else
      style="margin-top: 2%;"
    >
      <mds-empty-state
        v-if="rows.length === 0"
        title="Nothing to show"
        message=""
        size="large"
        style="margin: auto;"
      />
      <mds-table
        v-else
        row-number
        zebra-stripes
      >
        <mds-thead>
          <mds-th style="width: 50px;">
            No.
          </mds-th>
          <mds-th
            v-for="(header, index) in headers"
            :key="index"
            :style="header.style"
          >
            {{ header.text }}
          </mds-th>
        </mds-thead>
        <mds-tbody>
          <mds-tr
            v-for="(row, index) in rows"
            :key="index"
            :row-number="index+1"
          >
            <mds-td
              v-for="(header, i) in headers"
              :key="i"
            >
              <div v-if="header.text === 'Formulas'">
                <mds-list-group v-if="getFormulaList(row).length > 0">
                  <mds-list-group-item
                    v-for="(formula) in getFormulaList(row)"
                    :key="formula"
                  >
                    <mds-button
                      variation="flat"
                      type="button"
                      @click="showFormula(formula)"
                    >
                      {{ formula }}
                    </mds-button>
                  </mds-list-group-item>
                </mds-list-group>
                <div v-else>
                  -
                </div>
              </div>
              <div v-else-if="header.text === 'Parameter Set'">
                <mds-table>
                  <caption
                    class="underline"
                    style="position: relative"
                  >
                    Psg Version: {{ row.psgVersion }}
                    <mds-button
                      variation="flat"
                      type="button"
                      icon="expand"
                      class="anchored-button"
                      @click="showPsgDetails(index)"
                    >
                      Details
                    </mds-button>
                  </caption>
                  <mds-thead :hidden-header="true">
                    <mds-th :left-aligned="true">
                      Input Sets
                    </mds-th>
                  </mds-thead>
                  <mds-tbody>
                    <mds-tr>
                      <mds-td :left-aligned="true">
                        <mds-list-group
                          v-if="row.parameterSets.length > 0"
                        >
                          <mds-list-group-item
                            v-for="(paramset, p) in row.parameterSets"
                            :key="p+10001"
                          >
                            <mds-layout-grid v-if="p === 0">
                              <mds-row>
                                <mds-col>
                                  {{ p+1 }}. {{ paramset.name }}
                                </mds-col>
                                <mds-col :cols="1" />
                              </mds-row>
                            </mds-layout-grid>
                            <div v-else>
                              {{ p+1 }}. {{ paramset.name }}
                            </div>
                          </mds-list-group-item>
                        </mds-list-group>
                        <div v-else>
                          CURRENT INPUT SET
                        </div>
                      </mds-td>
                    </mds-tr>
                  </mds-tbody>
                </mds-table>
              </div>
              <div v-else>
                {{ row[header.fieldName] }}
              </div>
            </mds-td>
          </mds-tr>
        </mds-tbody>
      </mds-table>
      <mds-dialog
        v-if="toggle1200px"
        v-model="toggle1200px"
        action-required
        title="Input Set Update Details"
        width="500px"
      >
        <ul
          class="ul"
          style="padding: 5px 5px 5px 10px;"
        >
          <li
            v-for="(comment) in currentPsgComment.split('\n').filter(comment => comment.length > 0)"
            :key="comment"
          >
            {{ comment }}
          </li>
        </ul>
        <code-diff
          language="json"
          :old-string="prevPsgData"
          :new-string="currentPsgData"
          :filename="currentPsgVersion > 0 ? `Psg Version: ${currentPsgVersion - 1}` : ''"
          :new-filename="`Psg Version: ${currentPsgVersion}`"
          :output-format="currentPsgVersion > 0 ? 'side-by-side' : 'line-by-line'"
          max-height="400px"
        />
      </mds-dialog>
      <mds-dialog
        v-if="showFormulaDialog"
        v-model="showFormulaDialog"
        action-required
        :title="`Formula: ${formulaId}`"
        width="500px"
      >
        <mds-loader
          v-if="showFormulaLoader"
          aria-label="Default Loader"
        />
        <div v-else>
          <code-diff
            id="formula-diff"
            language="javascript"
            :old-string="prevFormula"
            :new-string="currentFormula"
            :filename="prevFormulaId.length > 0 ? prevFormulaId : ''"
            :new-filename="currentFormulaId"
            :output-format="prevFormulaId.length > 0 ? 'side-by-side' : 'line-by-line'"
            max-height="400px"
          />
        </div>
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="showFormulaDialog=!showFormulaDialog"
            >
              Cancel
            </mds-button>
            <mds-button
              id="formula-copy-button"
              type="button"
              variation="primary"
              icon="open-new"
              @click="copyFormulaToClipboard"
            >
              Copy
            </mds-button>
            <mds-tooltip
              v-model="formulaCopiedTooltip"
              triggered-by="formula-copy-button"
              :position="['top-center']"
            >
              Copied!
            </mds-tooltip>
          </mds-button-container>
        </template>
      </mds-dialog>
    </div>
  </div>
</template>

<script>
import MdsEmptyState from '@mds/empty-state';
import { mapGetters } from 'vuex';
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import MdsTooltip from '@mds/tooltip';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import MdsDialog from '@mds/dialog';
import MdsLoader from '@mds/loader';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import axios from 'axios';


export default {
  name: 'WorkflowHistory',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsEmptyState,
    MdsTooltip,
    MdsButton,
    MdsListGroup,
    MdsListGroupItem,
    MdsDialog,
    MdsButtonContainer,
    MdsLoader,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
  },
  filters: {
    pretty(value) {
      if (value) {
        return JSON.stringify(value, null, 2);
      }
      return '';
    },
  },
  props: {
    parameterSetData: {
      type: Object,
      default: null,
    },
    formulas: {
      type: Array,
      default: null,
    },
    workflowConfigData: {
      type: Object,
      default: null,
    },
    operation: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Insert Time',
          fieldName: 'insertTime',
          style: {
            width: '200px',
          },
        },
        {
          text: 'Workflow Name',
          fieldName: 'workflowName',
        },
        {
          text: 'Parameter Set',
          fieldName: 'parameterSets',
        },
        {
          text: 'Formulas',
          fieldName: 'formula_id',
        },
        {
          text: 'Comment',
          fieldName: 'comment',
        },
        {
          text: 'Created By',
          fieldName: 'createdBy',
        },
      ],
      rows: [],
      errorMessage: 'Needs a maximum duration. <br /><br /> No Publish, no Notification: this workflow does nothing.',
      manager: {},
      coordMap: {},
      showCopied: false,
      showFormulaDialog: false,
      formulaId: '',
      showFormulaLoader: false,
      currentFormula: '',
      showFormulaError: false,
      formulaCopiedTooltip: false,
      showMainLoader: true,
      toggle1200px: false,
      psgVersionData: {},
      currentPsgData: {},
      prevPsgData: {},
      currentPsgComment: '',
      currentPsgVersion: 0,
      formulaVersion: new Map(),
      currentFormulaId: '',
      prevFormulaId: '',
      prevFormula: '',
      formulaCache: new Map(),
    };
  },
  computed: {
    ...mapGetters('workflowModule', ['getCurrentWorkflowManager', 'getEditableWorkflowDetails']),
    formatedParameterSetData() {
      return JSON.stringify(this.parameterSetData, null, 4);
    },
  },
  mounted() {
    this.fetchWorkflowChanges();
    this.rows.forEach((row) => {
      this.$set(row, 'showCopied', false);
    });
  },
  methods: {
    changed(event) {
      event.preventDefault();
    },
    showPsgDetails(index) {
      this.currentPsgComment = this.rows[index].paramsetComment || '';
      this.currentPsgVersion = this.rows[index].psgVersion;
      this.currentPsgData = JSON.stringify(this.psgVersionData[this.currentPsgVersion], null, 1);
      if (this.currentPsgVersion > 0) {
        this.prevPsgData = JSON.stringify(this.psgVersionData[this.currentPsgVersion - 1], null, 1);
      } else {
        this.prevPsgData = '';
      }
      this.toggle1200px = true;
    },
    async fetchWorkflowChanges() {
      const resp = await axios.get(`api/workflows/${this.workflowConfigData.id}/changes`);
      if (resp.status === 200) {
        this.rows = resp.data.reverse();
        this.rows.forEach((row) => {
          this.psgVersionData[row.psgVersion] = row.parameterSets;
          const formulaBubbles = row.ui.bubbles.filter(bubble => bubble.type === 'formula');
          formulaBubbles.forEach((bubble) => {
            const { id } = bubble;
            if (this.formulaVersion.has(id)) {
              this.formulaVersion.get(id).add(bubble.props.formula_id);
            } else {
              const formulaSet = new Set();
              formulaSet.add(bubble.props.formula_id);
              this.formulaVersion.set(id, formulaSet);
            }
          });
        });
      }
      this.showMainLoader = false;
    },
    getFormulaList(data) {
      if (data && data.ui && data.ui.bubbles) {
        return data.ui.bubbles.map(bubble => (bubble.type === 'formula' ? bubble.props.formula_id : ''))
          .filter(id => id && id.length > 0);
      }
      return [];
    },
    toggleRow(index) {
      const status = this.rows[index].expanded ? !this.rows[index].expanded : true;
      this.$set(this.rows[index], 'expanded', status);
    },
    copyToClipboard(text, row) {
      navigator.clipboard.writeText(JSON.stringify(text));
      this.$set(row, 'showCopied', true);
      setTimeout(() => {
        row.showCopied = false;
      }, 3000);
    },
    copyFormulaToClipboard() {
      navigator.clipboard.writeText(this.currentFormula);
      this.formulaCopiedTooltip = true;
      setTimeout(() => {
        this.formulaCopiedTooltip = false;
      }, 2000);
    },
    fetchFormulaForId() {
      this.showFormulaLoader = true;
      const formulaFetchPromises = [];
      if (this.formulaCache.has(this.currentFormulaId)) {
        formulaFetchPromises.push(Promise.resolve({ data: { formula: this.formulaCache.get(this.currentFormulaId) } }));
      } else {
        formulaFetchPromises.push(axios.get(`api/workflows/formulas/id/${this.currentFormulaId}`));
      }

      if (this.prevFormulaId.length > 0) {
        if (this.formulaCache.has(this.prevFormulaId)) {
          formulaFetchPromises.push(Promise.resolve({ data: { formula: this.formulaCache.get(this.prevFormulaId) } }));
        } else {
          formulaFetchPromises.push(axios.get(`api/workflows/formulas/id/${this.prevFormulaId}`));
        }
      }
      Promise.all(formulaFetchPromises)
        .then((resp) => {
          this.currentFormula = resp[0].data.formula;
          this.formulaCache.set(this.currentFormulaId, this.currentFormula);
          if (resp[1]) {
            this.prevFormula = resp[1].data.formula;
            this.formulaCache.set(this.prevFormulaId, this.prevFormula);
          }
        })
        .catch(() => {
          this.showFormulaError = true;
        })
        .finally(() => {
          this.showFormulaLoader = false;
        });
    },
    showFormula(formulaId) {
      this.formulaId = formulaId;
      this.showFormulaDialog = true;
      let formulaList = [];
      this.formulaVersion.forEach((bubble) => {
        if (bubble.has(formulaId)) {
          formulaList = Array.from(bubble);
        }
      });
      const formulaIndex = formulaList.indexOf(formulaId);
      if (formulaIndex > 0) {
        this.currentFormulaId = formulaId;
        this.prevFormulaId = formulaList[formulaIndex - 1];
      } else {
        this.currentFormulaId = formulaId;
        this.prevFormulaId = '';
      }
      this.currentFormula = '';
      this.prevFormula = '';
      this.fetchFormulaForId();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@mds/typography';
  @import '@mds/constants';
  @import '@mds/typography';

  .ul {
      @include mds-unordered-list(medium);
  }

  h3 {
      @include mds-level-5-heading();

      color: $mds-text-color-primary;
  }

  h6 {
    @include mds-level-6-heading();
  }

  thead {
    border: 0px !important
  }

  .underline{
    border-bottom: 1px solid;
    width: 100%;
    display: block;
  }

  .anchored-button {
    position: absolute;
    right: 0;
    bottom: 0px;
  }
.blob-code-marker {
    position: relative !important;
  }

.blob-code-marker:before {
    position: relative !important;
  }

</style>

<template>
  <div class="create-new-scheduler">
    <div
      v-if="overlay"
      id="mds-overlay"
      class="mds-overlay"
    />
    <mds-loader
      v-show="showloader"
      class="div-centered"
      size="large"
    />
    <mds-row>
      <mds-col :cols="12">
        <form
          class="mds-form mds-form--medium-layout custom_form pading_1rem "
          method=""
          action=""
        >
          <div class="mds-form__fieldset">
            <mds-row>
              <mds-col
                :cols="2"
                class="pading-top_05rem"
              >
                <label
                  class="mds-form__label pading-top_1rem"
                  for="datasource"
                >
                  {{ $t('labels.schedulerManagement.scLabelName') }}
                  <abbr class="mds-form__label-required-indicator">*</abbr>
                </label>
              </mds-col>
              <mds-col
                class="pading-top_1rem"
              >
                <mds-row>
                  <mds-col
                    :cols="6"
                    class="pading-top_05rem"
                  >
                    <input
                      id="schName"
                      v-model="schName"
                      class="mds-form__input"
                      type="text"
                      maxlength="100"
                      placeholder="Schedule Name"
                      :disabled="btnName === 'Edit'"
                      @focusin="schPatternError = scheduleExists = schNameError = false"
                      @focusout="checkScheduleNameErrors($event)"
                    >
                    <span
                      v-if="schPatternError"
                      id="schName"
                      class="mds-form__field-error"
                      role="alert"
                    >
                      <span class="mds-form__field-error-text">
                        {{ $t('labels.schedulerManagement.schedulePatternError') }}
                      </span>
                    </span>
                    <span
                      v-if="scheduleExists"
                      id="schName"
                      class="mds-form__field-error"
                      role="alert"
                    >
                      <span class="mds-form__field-error-text">
                        {{ $t('labels.schedulerManagement.scheduleDuplicateError') }}
                      </span>
                    </span>
                    <span
                      v-if="schNameError"
                      id="schName"
                      class="mds-form__field-error"
                      role="alert"
                    >
                      <span class="mds-form__field-error-text">
                        {{ $t('labels.schedulerManagement.schValidNameError') }}
                      </span>
                    </span>
                  </mds-col>
                  <mds-col
                    class="pading-top_05rem"
                    :cols="1"
                  >
                    <mds-switch
                      id="schEnabaleDisable"
                      v-model="schEnabaleDisable"
                      value="logic-mode"
                      :checked="schEnabaleDisable"
                    >
                      {{ $t('labels.schedulerManagement.schLabelEnableDisable') }}
                    </mds-switch>
                  </mds-col>
                </mds-row>
                <hr class="horizontal-bar">
              </mds-col>
            </mds-row>
            <mds-row>
              <mds-col
                :cols="2"
              >
                <label
                  class="mds-form__label pading-top_05rem"
                  for="datasource"
                >{{ $t('labels.schedulerManagement.scLabelDescription') }}
                </label>
              </mds-col>
              <mds-col :cols="5">
                <textarea
                  id="schDescription"
                  v-model="schDescription"
                  maxlength="255"
                  class="mds-form__textarea custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
                  @focusout="trimInputData('schDescription')"
                />
              </mds-col>
            </mds-row>
            <hr class="horizontal-bar">
            <mds-row>
              <mds-col :cols="2">
                <label
                  class="mds-form__label pading-top_1rem"
                  for="datasource"
                >
                  {{ $t('labels.schedulerManagement.scLabelMachineID') }}
                  <abbr class="mds-form__label-required-indicator">*</abbr>
                </label>
              </mds-col>
              <mds-col
                class="pading-top_05rem"
              >
                <input
                  id="schMachineID"
                  v-model="schMachineID"
                  class="mds-form__input"
                  type="text"
                  maxlength="255"
                  placeholder="Machine ID"
                  @focusin="schMachineIdError = false"
                >
                <span
                  v-if="schMachineIdError"
                  id="schMachineIdError"
                  class="mds-form__field-error"
                  role="alert"
                >
                  <span class="mds-form__field-error-text">
                    {{ $t('labels.schedulerManagement.scLabelMachineIDError') }}
                  </span>
                </span>
              </mds-col>
              <mds-col
                class="pading-top_1rem"
              />
            </mds-row>
            <hr class="horizontal-bar">
            <mds-row>
              <mds-col :cols="2">
                <label
                  class="mds-form__label pading-top_1rem"
                  for="datasource"
                >
                  {{ $t('labels.schedulerManagement.scLabelPublist') }}
                  <abbr class="mds-form__label-required-indicator">*</abbr>
                </label>
              </mds-col>
              <mds-col
                class="pading-top_05rem"
              >
                <div
                  @focusin="removePubListError()"
                >
                  <mds-combo-box
                    id="schPubList"
                    v-model="schPubList"
                    placeholder="Select Option(s)"
                    multiple
                    :data-set="dataSetPubList"
                    label=""
                  />
                </div>

                <span
                  v-if="schPubListError"
                  id="schPubListError"
                  class="mds-form__field-error"
                  role="alert"
                >
                  <span class="mds-form__field-error-text">
                    {{ $t('labels.schedulerManagement.scLabelPublistError') }}
                  </span>
                </span>
              </mds-col>
              <mds-col
                class="pading-top_1rem"
              />
            </mds-row>
            <hr class="horizontal-bar">
            <mds-row>
              <mds-col :cols="2">
                <label
                  class="mds-form__label pading-top_1rem"
                  for="datasource"
                >
                  {{ $t('labels.schedulerManagement.scLabelSaveFile') }}
                  <abbr class="mds-form__label-required-indicator">*</abbr>
                </label>
              </mds-col>
              <mds-col
                class="pading-top_05rem"
              >
                <mds-row>
                  <mds-col :cols="6">
                    <mds-combo-box
                      id="schFileDateFormat"
                      v-model="schFileDateFormat"
                      placeholder="File date format"
                      :data-set="dataSetFileDate"
                      label=""
                    />
                  </mds-col>
                  <mds-col
                    :cols="4"
                  >
                    <div
                      @focusin="removeExtentionError()"
                    >
                      <mds-combo-box
                        id="schFileExtensionFormat"
                        v-model="schFileExtensionFormat"
                        placeholder="File extension"
                        :data-set="dataSetFileExtension"
                        label=""
                      />
                    </div>
                  </mds-col>
                  <mds-col
                    v-if="schFileExtensionFormat[0] === 'custom'"
                    :cols="3"
                  >
                    <input
                      id="schCustomFileExtension"
                      v-model="schCustomFileExtension"
                      class="mds-form__input"
                      type="text"
                      maxlength="55"
                      placeholder="Custom file type"
                      @focusout="checkValidFileType()"
                    >
                  </mds-col>
                </mds-row>
                <!-- <div @mouseleave="validatePublistFileType()"> -->
                <mds-row class="pading-top_05rem">
                  <mds-col>
                    <span>
                      <!-- eslint-disable-next-line max-len -->
                      {{ $t('labels.schedulerManagement.scLabelSaveFileExample') }} {{ schName }}_{{ schFileDateFormat[0] }}.{{ schFileExtensionFormat[0] != 'custom' ? schFileExtensionFormat[0] : schCustomFileExtension }}
                    </span>
                  </mds-col>
                </mds-row>
                <mds-row>
                  <mds-col>
                    <span
                      v-if="listNameErrorFlag"
                      id="listNameError"
                      class="mds-form__field-error"
                      role="alert"
                    >
                      <span class="mds-form__field-error-text">
                        {{ listNameError }}
                      </span>
                    </span>
                  </mds-col>
                </mds-row>
                <!-- </div> -->
                <span
                  v-if="schFileTypeError"
                  id="schFileTypeError"
                  class="mds-form__field-error"
                  role="alert"
                >
                  <span class="mds-form__field-error-text">
                    {{ customFileFormatErrorStr }}
                  </span>
                </span>
              </mds-col>
              <mds-col />
            </mds-row>
            <hr class="horizontal-bar">
            <!-- <div @mouseleave="validatePublistFileType()"> -->
            <div>
              <mds-row>
                <mds-col :cols="2">
                  <label
                    class="mds-form__label pading-top_1rem"
                    for="datasource"
                  >
                    {{ $t('labels.schedulerManagement.scLabelFilePath') }}
                  </label>
                </mds-col>
                <mds-col
                  class="pading-top_05rem"
                >
                  <input
                    id="schDirFilePath"
                    v-model="schDirFilePath"
                    class="mds-form__input"
                    type="text"
                    maxlength="255"
                    placeholder="File Path"
                  >
                </mds-col>
                <mds-col
                  class="pading-top_1rem"
                />
              </mds-row>
            </div>
            <hr class="horizontal-bar">
            <mds-row>
              <mds-col :cols="2">
                <label
                  class="mds-form__label pading-top_1rem"
                  for="datasource"
                >
                  {{ $t('labels.schedulerManagement.scLabelCheckData') }}
                </label>
              </mds-col>
              <mds-col>
                <mds-row>
                  <mds-col
                    :cols="5"
                    class="pading-top_05rem"
                  >
                    <mds-row>
                      <mds-col :cols="7">
                        <mds-radio-button
                          v-model="schCKDRadio"
                          :checked="schCKDRadio === 'true' || schCKDRadio === true"
                          name="schCKDRadio"
                          value="true"
                          label="Only Check for New Data"
                        />
                      </mds-col>
                      <mds-col :cols="4">
                        <mds-radio-button
                          v-model="schCKDRadio"
                          :checked="schCKDRadio === 'false' || schCKDRadio !== false"
                          name="schCKDRadio"
                          value="false"
                          label="Check back"
                        />
                      </mds-col>
                    </mds-row>
                  </mds-col>
                  <mds-col :col="2" />
                  <mds-col :cols="6">
                    <mds-row>
                      <mds-col :cols="4">
                        <mds-combo-box
                          id="schDaysForData"
                          v-model="schDaysForData"
                          :data-set="dataSetDaysForData"
                          :disabled="schCKDRadio !== 'false'"
                          label=""
                        />
                      </mds-col>
                      <mds-col
                        :cols="8"
                      >
                        {{ $t('labels.schedulerManagement.scLabelDaysData') }}
                      </mds-col>
                    </mds-row>
                  </mds-col>
                </mds-row>
                <hr class="horizontal-bar">
                <mds-row>
                  <mds-col
                    :cols="3"
                    class="pading-top_05rem"
                  >
                    <div>
                      <mds-checkbox
                        id="schFromPubDate"
                        v-model="schFromPubDate"
                        value="accept"
                        :checked="schFromPubDate === true || schFromPubDate === 'true'"
                        @click.native.stop="()=>{}"
                        @change="checkschFromPubDate"
                      >
                        {{ $t('labels.schedulerManagement.scLabelFPubDate') }}
                      </mds-checkbox>
                    </div>
                    <div>
                      <mds-checkbox
                        id="schIncHistoryData"
                        v-model="schIncHistoryData"
                        value="accept"
                        :checked="schIncHistoryData === true"
                        @click.native.stop="()=>{}"
                        @change="schIncHistoryData = !schIncHistoryData"
                      >
                        {{ $t('labels.schedulerManagement.scLabelIncHistory') }}
                      </mds-checkbox>
                    </div>
                    <span
                      v-if="(schIncHistoryData === true) || (schIncHistoryData === 'true')"
                      id="schIncHistoryData"
                      class="mds-form__field-error"
                      role="alert"
                    >
                      <span class="mds-form__field-error-text">
                        {{ $t('labels.schedulerManagement.includeHistoryDataError') }}
                      </span>
                    </span>
                  </mds-col>
                  <mds-col :cols="2">
                    <mds-combo-box
                      id="schDays"
                      v-model="schDays"
                      placeholder="0"
                      label="Days"
                      :data-set="dataSetSchDays"
                      :disabled="(schFromPubDate === 'false') || (schFromPubDate === false)"
                    />
                  </mds-col>
                  <mds-col class="pading-top_05rem">
                    <div>
                      <mds-checkbox
                        id="schRmtoPubDateTime"
                        v-model="schRmtoPubDateTime"
                        value="accept"
                        :checked="schRmtoPubDateTime === true"
                        @click.native.stop="()=>{}"
                        @change="schRmtoPubDateTime = !schRmtoPubDateTime"
                      >
                        {{ $t('labels.schedulerManagement.scLabelRmtoPubDateTime') }}
                      </mds-checkbox>
                    </div>
                  </mds-col>
                </mds-row>
              </mds-col>
            </mds-row>
            <hr class="horizontal-bar">
            <mds-row>
              <mds-col :cols="2">
                <label
                  class="mds-form__label pading-top_1rem"
                  for="datasource"
                >
                  {{ $t('labels.schedulerManagement.scLabelLastValue') }}
                </label>
              </mds-col>
              <mds-col
                class="pading-top_05rem"
              >
                <mds-checkbox
                  id="schLastValueOnly"
                  v-model="schLastValueOnly"
                  value="accept"
                  :checked="schLastValueOnly === true"
                  @click.native.stop="()=>{}"
                  @change="schLastValueOnly = !schLastValueOnly"
                />
              </mds-col>
              <mds-col
                class="pading-top_1rem"
              />
            </mds-row>
            <hr class="horizontal-bar">
            <mds-row>
              <mds-col :cols="2">
                <label
                  class="mds-form__label pading-top_1rem"
                  for="Frequency"
                >
                  {{ $t('labels.schedulerManagement.scLabelFreq') }}
                  <abbr class="mds-form__label-required-indicator">*</abbr>
                </label>
              </mds-col>
              <mds-col
                class="pading-top_05rem"
              >
                <mds-button
                  id="schFrqSundayId"
                  ref="schFrqSundayId"
                  v-model="schFrq.weekDays[0]"
                  class="padding-right_05rem"
                  :variation="schFrq.weekDays[0] === true ? 'primary' : 'secondry'"
                  @click="changeButtonType($event, 'schFrqSundayId', 0)"
                >
                  {{ $t('labels.schedulerManagement.scLabelSunday') }}
                </mds-button>
                <mds-button
                  id="schFrqMondayId"
                  ref="schFrqMondayId"
                  v-model="schFrq.weekDays[1]"
                  class="padding-right_05rem"
                  :variation="schFrq.weekDays[1] === true ? 'primary' : 'secondry'"
                  @click="changeButtonType($event, 'schFrqMondayId', 1)"
                >
                  {{ $t('labels.schedulerManagement.scLabelMonday') }}
                </mds-button>
                <mds-button
                  id="schFrqTuesdayId"
                  ref="schFrqTuesdayId"
                  v-model="schFrq.weekDays[2]"
                  class="padding-right_05rem"
                  :variation="schFrq.weekDays[2] === true ? 'primary' : 'secondry'"
                  @click="changeButtonType($event, 'schFrqTuesdayId', 2)"
                >
                  {{ $t('labels.schedulerManagement.scLabelTuesday') }}
                </mds-button>
                <mds-button
                  id="schFrqWedsdayId"
                  ref="schFrqWedsdayId"
                  v-model="schFrq.weekDays[3]"
                  class="padding-right_05rem"
                  :variation="schFrq.weekDays[3] === true ? 'primary' : 'secondry'"
                  @click="changeButtonType($event, 'schFrqWedsdayId', 3)"
                >
                  {{ $t('labels.schedulerManagement.scLabelWednesday') }}
                </mds-button>
                <mds-button
                  id="schFrqThrusdayId"
                  ref="schFrqThrusdayId"
                  v-model="schFrq.weekDays[4]"
                  class="padding-right_05rem"
                  :variation="schFrq.weekDays[4] === true ? 'primary' : 'secondry'"
                  @click="changeButtonType($event, 'schFrqThrusdayId', 4)"
                >
                  {{ $t('labels.schedulerManagement.scLabelThrusday') }}
                </mds-button>
                <mds-button
                  id="schFrqFridayId"
                  ref="schFrqFridayId"
                  v-model="schFrq.weekDays[5]"
                  class="padding-right_05rem"
                  :variation="schFrq.weekDays[5] === true ? 'primary' : 'secondry'"
                  @click="changeButtonType($event, 'schFrqFridayId', 5)"
                >
                  {{ $t('labels.schedulerManagement.scLabelFriday') }}
                </mds-button>
                <mds-button
                  id="schFrqSaturdayId"
                  ref="schFrqSaturdayId"
                  v-model="schFrq.weekDays[6]"
                  class="padding-right_05rem"
                  :variation="schFrq.weekDays[6] === true ? 'primary' : 'secondry'"
                  @click="changeButtonType($event, 'schFrqSaturdayId', 6)"
                >
                  {{ $t('labels.schedulerManagement.scLabelSaturday') }}
                </mds-button>

                <mds-button
                  id="schFrqEverydayId"
                  ref="schFrqEverydayId"
                  v-model="schFrq.everyDay"
                  class="padding-right_05rem"
                  :variation="schFrq.everyDay === true ? 'primary' : 'secondry'"
                  @click="changeButtonType($event, 'schFrqEverydayId', 7)"
                >
                  {{ $t('labels.schedulerManagement.scLabelEveryday') }}
                </mds-button>
              </mds-col>
            </mds-row>
            <mds-row>
              <mds-col :cols="2" />
              <mds-col>
                <span
                  v-if="schFrequencyError"
                  id="schFrequencyError"
                  class="mds-form__field-error"
                  role="alert"
                >
                  <span class="mds-form__field-error-text">
                    {{ $t('labels.schedulerManagement.scLabelPlzFreq') }}
                  </span>
                </span>
              </mds-col>
            </mds-row>
            <hr class="horizontal-bar">
            <mds-row>
              <mds-col :cols="2">
                <label
                  class="mds-form__label pading-top_1rem"
                  for="datasource"
                >
                  {{ $t('labels.schedulerManagement.scLabelTime') }}
                  <abbr class="mds-form__label-required-indicator">*</abbr>
                </label>
              </mds-col>
              <mds-col
                class="pading-top_05rem"
              >
                <div @click="removeCornError('1', schTimeRangeHours)">
                  <mds-row>
                    <mds-col
                      :cols="2"
                    >
                      <mds-radio-button
                        v-model="schTRIRadio"
                        :checked="schTRIRadio === '1'"
                        name="schTRIRadio"
                        value="1"
                        label="Range"
                      />
                    </mds-col>
                    <mds-col :cols="3">
                      <div @focusout="removeCornError('1', schTimeRangeHours)">
                        <mds-combo-box
                          id="schTimeRangeHours"
                          v-model="schTimeRangeHours"
                          class="upward-dropdown"
                          placeholder="Hours"
                          multiple
                          :disabled="schTRIRadio !== '1'"
                          :data-set="dataSetTimeRangeHours"
                          label=""
                        />
                      </div>
                    </mds-col>
                    <mds-col :cols="3">
                      <div>
                        <mds-checkbox
                          id="schMinuteOnHour"
                          ref="schMinuteOnHour"
                          v-model="schMinuteOnHour"
                          value="accept"
                          :checked="schMinuteOnHour === true"
                          :disabled=" schTRIRadio !== '1'"
                          @change="schMinuteOnHour = !schMinuteOnHour"
                        >
                          {{ $t('labels.schedulerManagement.scLabelMinOnHours') }}
                        </mds-checkbox>
                      </div>
                    </mds-col>
                    <mds-col :cols="2">
                      <mds-combo-box
                        id="schTimeRangeMins"
                        v-model="schTimeRangeMins"
                        class="upward-dropdown"
                        placeholder="Minutes"
                        :disabled="schMinuteOnHour !== true"
                        :data-set="dataSetTimeRangeMins"
                        label=""
                      />
                    </mds-col>
                  </mds-row>
                </div>
                <hr class="horizontal-bar">
                <div @click="removeCornError('0', schTimeRangeHours)">
                  <mds-row>
                    <mds-col
                      :cols="2"
                      class="pading-top_05rem"
                    >
                      <mds-radio-button
                        v-model="schTRIRadio"
                        :checked="schTRIRadio === '0'"
                        name="schTRIRadio"
                        value="0"
                        label="Increments"
                      />
                    </mds-col>
                    <mds-col :cols="2">
                      <mds-combo-box
                        id="schTimeIncChkEvery"
                        v-model="schTimeIncChkEvery"
                        class="upward-dropdown"
                        :data-set="dataSetimeIncChkEvery"
                        placeholder="Check every"
                        :disabled=" schTRIRadio !== '0'"
                        label="Check every"
                      />
                    </mds-col>
                    <mds-col :cols="2">
                      <mds-combo-box
                        id="schTimeIncTI"
                        v-model="schTimeIncTI"
                        class="upward-dropdown"
                        :data-set="dataSetTimeIncTI"
                        placeholder="Time Increment"
                        :disabled=" schTRIRadio !== '0'"
                        label="Time Increment"
                      />
                    </mds-col>
                    <mds-col :cols="6">
                      <mds-row>
                        <mds-col>
                          <mds-range-slider
                            id="schStartStop"
                            v-model="schStartStop"
                            range
                            lower-label="Start at (Hrs)"
                            upper-label="End at (Hrs)"
                            :min="0"
                            :max="23"
                            :step="1"
                            :decimal-points="0"
                            :disabled=" schTRIRadio !== '0'"
                          />
                        </mds-col>
                      </mds-row>
                    </mds-col>
                  </mds-row>
                </div>
              </mds-col>
            </mds-row>
            <mds-row>
              <mds-col :cols="2" />
              <mds-col>
                <span
                  v-if="cronError"
                  id="cronError"
                  class="mds-form__field-error"
                  role="alert"
                >
                  <span class="mds-form__field-error-text">
                    {{ $t('labels.schedulerManagement.scLabelCornError') }}
                  </span>
                </span>
              </mds-col>
            </mds-row>
            <hr class="horizontal-bar">
            <mds-row>
              <mds-col :cols="2">
                <label
                  class="mds-form__label pading-top_1rem"
                  for="datasource"
                >
                  {{ $t('labels.schedulerManagement.scLabelTimeZone') }}
                  <abbr class="mds-form__label-required-indicator">*</abbr>
                </label>
              </mds-col>
              <mds-col
                :cols="3"
                class="pading-top_05rem"
              >
                <mds-combo-box
                  id="schTimeZone"
                  v-model="schTimeZone"
                  class="upward-dropdown sch-up-top0"
                  :data-set="dataSetTimeZone"
                  label=""
                />
              </mds-col>
              <mds-col
                class="pading-top_1rem"
              />
            </mds-row>
            <hr class="horizontal-bar">
            <div @mouseover="isMsgBusIntegrationCorrect()">
              <mds-row>
                <mds-col :cols="2">
                  <label
                    class="mds-form__label pading-top_15rem"
                    for="datasource"
                  >
                    {{ $t('labels.schedulerManagement.scMsgBusInt') }}
                  </label>
                </mds-col>
                <mds-col
                  :cols="1"
                  class="sch-msg-chk"
                >
                  <mds-checkbox
                    id="schMsgBusCheck"
                    ref="schMsgBusCheck"
                    v-model="schMsgBusCheck"
                    value="accept"
                    :checked="schMsgBusCheck === true"
                    @change="schMsgBusCheck = !schMsgBusCheck"
                  />
                </mds-col>
                <mds-col
                  :cols="3"
                >
                  <mds-combo-box
                    id="schMsgBusType"
                    v-model="schMsgBusType"
                    class="upward-dropdown sch-up-top"
                    :data-set="dataSetMsgBusType"
                    label="Message Bus Type"
                    :disabled="schMsgBusCheck === false"
                  />
                </mds-col>
                <mds-col
                  :cols="3"
                >
                  <span><strong>{{ $t('labels.schedulerManagement.scMsgBusName') }}</strong></span>
                  <input
                    v-model="schMsgBusName"
                    type="text"
                    maxlength="100"
                    placeholder="Bus Name"
                    class="mds-form__input"
                    :disabled="schMsgBusCheck === false"
                    @keydown="isMsgBusIntegrationCorrect($event)"
                    @focusin="isMsgBusIntegrationCorrect($event)"
                    @focusout="isMsgBusIntegrationCorrect($event)"
                  >
                </mds-col>
                <mds-col
                  class="pading-top_1rem"
                />
              </mds-row>
              <mds-row>
                <mds-col :cols="2" />
                <mds-col>
                  <span
                    v-if="isMsgBusIntegrationCorrectVal === false"
                    id="schMsgBusCheck"
                    class="mds-form__field-error"
                    role="alert"
                  >
                    <span class="mds-form__field-error-text">
                      {{ $t('labels.schedulerManagement.scMsgBusError') }}
                    </span>
                  </span>
                </mds-col>
              </mds-row>
            </div>
            <hr class="horizontal-bar">
          </div>
          <mds-row>
            <mds-col class="pading-top_1rem">
              <mds-row>
                <mds-col :push="11">
                  <button
                    id="new-feed-save-button"
                    class="mds-button mds-button--primary font-size-small new-feed-save-button"
                    type="button"
                    :disabled="evalSubmitDisabled"
                    @click="createScheduler($event)"
                  >
                    {{ $t('labels.schedulerManagement.scLabelSave') }}
                  </button>
                </mds-col>
              </mds-row>
            </mds-col>
          </mds-row>
        </form>
      </mds-col>
    </mds-row>
    <scheduler-notification
      ref="notificationComponent"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { MdsRow, MdsCol } from '@mds/layout-grid';
import MdsComboBox from '@mds/combo-box';
import MdsSwitch from '@mds/switch';
import MdsRangeSlider from '@mds/range-slider';
import MdsRadioButton from '@mds/radio-button';
import { toNumber } from 'lodash';
import LayoutDefault from '../Layouts/LayoutDefault.vue';
import SchedulerNotification from './SchedulerNotification.vue';
import EventBus from '../../main';
import * as utilsConstants from '../../utils/constants';

export default {
  name: 'CreateNewScheduler',
  components: {
    MdsRow,
    MdsCol,
    MdsSwitch,
    MdsComboBox,
    MdsRadioButton,
    MdsRangeSlider,
    SchedulerNotification,
  },
  props: {
    btnName: {
      type: String,
      default() {
        return '';
      },
    },
    scheduler:
            {
              type: Object,
              default() {
                return {};
              },
            },
    publistFromSch: {
      type: Array,
      default() {
        return [];
      },
    },
    size: {
      type: String,
      default() {
        return '';
      },
    },
    width: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      overlay: false,
      showloader: false,
      showPageError: false,
      pageError: '',
      internalServerErrMsg: 'Internal Server Error with status code 500',
      schNameError: false,
      schPatternError: false,
      schPubListError: false,
      schFrequencyError: false,
      schFileTypeError: false,
      customFileFormatErrorStr: '',
      schMachineIdError: false,
      cronError: false,
      scheduleExists: false,
      schedulePatternError: false,
      schName: '',
      schEnabaleDisable: true,
      schDescription: '',
      schMachineID: '1',
      schPubList: [],
      schDirFilePath: '',
      schCKDRadio: 'false',
      schDaysForData: ['0'],
      schFromPubDate: false,
      schIncHistoryData: false,
      schRmtoPubDateTime: false,
      schLastValueOnly: false,
      schTRIRadio: '',
      schCorn: '',
      schFrq: {
        weekDays: [false, false, false, false, false, false, false],
        everyDay: false,
      },
      listNameErrorFlag: false,
      listNameError: '',
      schMinuteOnHour: false,
      schFileDateFormat: ['yyyyMMddHHmmss'],
      schFileExtensionFormat: ['csv'],
      schCustomFileExtension: 'csv',
      schTimeRangeHours: [0],
      schTimeRangeMins: ['1'],
      schDays: [],
      schTimeIncChkEvery: ['1'],
      schTimeIncTI: ['Minutes'],
      schStartStop: { min: 0, max: 23 },
      schTimeZone: ['America/Chicago'],
      schMsgBusCheck: false,
      schMsgBusName: '',
      schMsgBusType: [''],
      schMsgBusNameError: false,
      isMsgBusIntegrationCorrectVal: '',
      dataSetPubList: [],
      dataSetimeIncChkEvery: [],
      dataSetTimeZone: [],
      dataSetFileDate: [
        {
          text: 'yyyyMMddHHmmss',
          value: 'yyyyMMddHHmmss',
        },
        {
          text: 'MMddyyyy',
          value: 'MMddyyyy',
        },
        {
          text: 'yyyy_MM_dd',
          value: 'yyyy_MM_dd',
        },
        {
          text: 'yyyyMMdd_HHmmss',
          value: 'yyyyMMdd_HHmmss',
        },
        {
          text: 'yyyy-MM-dd',
          value: 'yyyy-MM-dd',
        },
        {
          text: 'yyyyMMdd',
          value: 'yyyyMMdd',
        },
        {
          text: 'yyyy-MMMM-dd',
          value: 'yyyy-MMMM-dd',
        },
        {
          text: 'MM-dd-yyyy',
          value: 'MM-dd-yyyy',
        },
        {
          text: 'dd-MMMM-yy',
          value: 'dd-MMMM-yy',
        },
        {
          text: 'dd-MMM-yy',
          value: 'dd-MMM-yy',
        },
        {
          text: 'MMM-dd-yyyy',
          value: 'MMM-dd-yyyy',
        },
        {
          text: 'MMM dd yyyy',
          value: 'MMM dd yyyy',
        },
      ],
      dataSetFileExtension: [
        {
          text: 'xlsx',
          value: 'xlsx',
        },
        {
          text: 'csv',
          value: 'csv',
        },
        {
          text: 'csvnoheader',
          value: 'csvnoheader',
        },
        {
          text: 'txt',
          value: 'txt',
        },
        {
          text: 'json',
          value: 'json',
        },
        {
          text: 'odt',
          value: 'odt',
        },
        {
          text: 'ods',
          value: 'ods',
        },
        {
          text: 'xml',
          value: 'xml',
        },
        {
          text: 'xls',
          value: 'xls',
        },
        {
          text: 'doc',
          value: 'doc',
        },
        {
          text: 'docx',
          value: 'docx',
        },
        {
          text: 'html',
          value: 'html',
        },
        {
          text: 'htm',
          value: 'htm',
        },
        {
          text: 'pdf',
          value: 'pdf',
        },
      ],
      dataSetDaysForData: [],
      dataSetSchDays: [
      ],
      dataSetTimeRangeHours: [
      ],
      dataSetTimeRangeMins: [
      ],
      dataSetTimeIncTI: [
        {
          text: 'Minutes',
          value: 'Minutes',
        },
        {
          text: 'Hours',
          value: 'Hours',
        },
      ],
      dataSetMsgBusType: [
        {
          text: '',
          value: '',
        },
        {
          text: 'JmsQueue',
          value: 'JmsQueue',
        },
        {
          text: 'JmsTopic',
          value: 'JmsTopic',
        },
      ],
      count: 0,
      evalCorn: '',
      separatedCorn: [],
    };
  },
  computed: {
    ...mapGetters({
      pubList: 'schedulerModule/getPubListForScheduler',
      schedulerList: 'schedulerModule/getSchedulerList',
    }),
    evalSubmitDisabled() {
      return ((this.schName === '')
        || (this.schMachineID === '')
        || (this.schPubList.length === 0)
        || (this.schTRIRadio === '')
        || (this.schFrequencyError === true)
        || (this.schPatternError === true)
        || (this.scheduleExists === true)
        || (this.schNameError === true)
        || (this.cronError === true)
        || (this.schFileTypeError === true)
        || (this.isMsgBusIntegrationCorrectVal === false));
    },
  },
  created() {
    const aryIannaTimeZones = utilsConstants.FEATURES.TIMEZONES;
    EventBus.$emit('layoutChange', LayoutDefault);
    this.getDataSetimeIncChkEvery();
    this.getDataSetforDayHours();
    this.getDataSetDaysForData();
    this.getDataSetforSchDays();
    this.getDataSetTimeRangeMins();

    aryIannaTimeZones.forEach((timeZone) => {
      this.dataSetTimeZone.push({
        text: `${timeZone}`,
        value: `${timeZone}`,
      });
    });
    if (this.publistFromSch) {
      this.dataSetPubList = this.publistFromSch;
    }
    if (this.scheduler) {
      this.loadSchedulerDetails();
    }
  },
  methods: {
    ...mapActions('schedulerModule', ['getSchedulerList', 'createSchedule', 'updateSchedule', 'getuserpublistfiletype']),
    getDataSetimeIncChkEvery() {
      for (let i = 0; i <= 59; i++) {
        this.dataSetimeIncChkEvery.push({
          text: `${i }`,
          value: `${i }`,
        });
      }
    },
    getDataSetDaysForData() {
      for (let i = 0; i <= 65; i++) {
        this.dataSetDaysForData.push({
          text: `${i }`,
          value: `${i }`,
        });
      }
    },
    getDataSetforSchDays() {
      for (let i = 0; i <= 365; i++) {
        this.dataSetSchDays.push({
          text: `${i }`,
          value: `${i }`,
        });
      }
    },
    getDataSetTimeRangeMins() {
      for (let i = 0; i <= 59; i++) {
        this.dataSetTimeRangeMins.push({
          text: `${i }`,
          value: `${i }`,
        });
      }
    },
    getDataSetforDayHours() {
      let ampm = 'AM';
      let textVal = 0;
      for (let i = 0; i <= 23; i++) {
        textVal = i;
        if (i > 11) {
          ampm = 'PM';
        }
        if (i === 0 || i === 12) {
          textVal = `12:00 ${ ampm}`;
        } else if (((i % 12) === 10) || ((i % 12) === 11)) {
          textVal = `${ i % 12 }:00 ${ ampm}`;
        } else {
          textVal = `0${ i % 12 }:00 ${ ampm}`;
        }
        this.dataSetTimeRangeHours.push({
          text: textVal,
          value: i,
        });
      }
    },
    checkMsgBusNameErrors(event) {

    },
    isMsgBusIntegrationCorrect() {
      this.isMsgBusIntegrationCorrectVal = false;
      if (this.schMsgBusCheck) {
        if (this.schMsgBusType[0] === '' || this.schMsgBusName === '') {
          this.isMsgBusIntegrationCorrectVal = false;
        } else {
          this.isMsgBusIntegrationCorrectVal = true;
        }
      } else {
        this.isMsgBusIntegrationCorrectVal = true;
      }
    },
    checkScheduleNameErrors(event) {
      this.schPatternError = false;
      this.scheduleExists = false;
      this.schName = this.schName.trim();
      if (this.schName && this.schName.length > 0) {
        const allowedSPattern = /^[a-zA-Z0-9_]+$/;
        const allowedSLength = 100;
        const matchedString = this.schName.match(allowedSPattern);
        if (!(matchedString && matchedString[0].length === this.schName.length)
        || this.schName.length > allowedSLength
        || this.schName.length === 0) {
          this.schPatternError = true;
        } else if (this.schedulerList.length > 0) {
          let schedulerListToFilter = this.schedulerList;
          if (this.btnName === 'Edit') {
            const IntialSchedulerName = this.scheduler.scheduleName.toString().toLowerCase();
            // eslint-disable-next-line max-len
            schedulerListToFilter = schedulerListToFilter.filter(obj => (obj.scheduleName.toString().toLowerCase() !== IntialSchedulerName));
          }
          schedulerListToFilter.forEach((item, index) => {
            if (this.schName.toString().toLowerCase() === item.scheduleName.toString().toLowerCase()) {
              this.scheduleExists = true;
            }
          });
        }
      }
    },
    checkValidFileType() {
      const validFileTypeList = [
        'csv', 'txt', 'xlsx', 'csvnoheader', 'xml', 'xls', 'json', 'htm', 'html', 'doc', 'docx', 'pdf', 'odt', 'ods',
      ];

      if ((this.schFileExtensionFormat[0] === 'custom')) {
        if (this.schCustomFileExtension.toString().toLowerCase() === '') {
          this.schFileTypeError = true;
          this.customFileFormatErrorStr = 'Please enter supportable file type..!';
          return false;
        }
        if (!validFileTypeList.includes(this.schCustomFileExtension.toString().toLowerCase())) {
          this.schFileTypeError = true;
          this.customFileFormatErrorStr = 'This file type entered is not supportable..!';
          return false;
        }
      }
      this.schFileTypeError = false;
      return true;
    },
    // validatePublistFileType() {
    //   console.log(this.schPubList.toString(), this.schFileExtensionFormat[0]);

    //   if (
    //     (this.schFileExtensionFormat[0] === 'xls' || this.schFileExtensionFormat[0] === 'xlsx')
    //     && (this.schPubList.length > 0)
    //   ) {
    //     this.getuserpublistfiletype(this.schPubList.toString()).then((response) => {
    //       console.log(response);
    //       let pubListNames = '';
    //       let notCsv = false;
    //       response.data.forEach((result) => {
    //         if (result.val !== 'csv' && result.val !== 'csvnoheader') {
    //           notCsv = true;
    //           if (pubListNames !== '') { pubListNames += ', '; }
    //           pubListNames += result.key;
    //         }
    //       });

    //       if (notCsv) {
    //         this.schFileTypeError = true;
    //          this.customFileFormatErrorStr =
    //            `Lists "${ pubListNames }" file format is not compatible.
    //             Please change output style to CSV or CSV - no header in the Publisher List Output tab,
    //              or choose the file type as 'txt' in Scheduler`;
    //       }
    //     });
    //   }
    // },
    removeExtentionError() {
      if (this.schFileTypeError === true) {
        this.schFileExtensionFormat = ['csv'];
        this.schCustomFileExtension = '';
        this.schFileTypeError = false;
        this.customFileFormatErrorStr = '';
      }
    },
    trimInputData(trimmedVariable) {
      this[trimmedVariable] = this[trimmedVariable].trim();
    },
    resetScheduleFlags() {
      this.scheduleExists = false;
      this.schedulePatternError = false;
    },
    checkschFromPubDate() {
      this.schFromPubDate = !this.schFromPubDate;
      if (!this.schFromPubDate) {
        this.schDays = [];
      }
    },
    changeButtonType(event, btnId, schFrqInd) {
      event.preventDefault();
      this.schFrequencyError = false;
      this.cronError = false;
      const refId = [
        'schFrqSundayId',
        'schFrqMondayId',
        'schFrqTuesdayId',
        'schFrqWedsdayId',
        'schFrqThrusdayId',
        'schFrqFridayId',
        'schFrqSaturdayId',
      ];
      if (schFrqInd === 7) {
        this.schFrq.everyDay = !this.schFrq.everyDay;
        if (this.schFrq.everyDay) {
          this.schFrq.weekDays = [true, true, true, true, true, true, true];
          this.count = 7;
          refId.forEach((item) => { this.chnageButtonsClass(item, true); });
          this.chnageButtonsClass('schFrqEverydayId', true);
        } else {
          this.schFrq.weekDays = [false, false, false, false, false, false, false];
          this.count = 0;
          refId.forEach((item) => { this.chnageButtonsClass(item, false); });
          this.chnageButtonsClass('schFrqEverydayId', false);
        }
      } else {
        this.schFrq.weekDays[schFrqInd] = !this.schFrq.weekDays[schFrqInd];
        if (this.schFrq.weekDays[schFrqInd]) {
          this.count++;
          this.chnageButtonsClass(btnId, true);
          if (this.count === 7) {
            this.schFrq.everyDay = true;
            this.chnageButtonsClass('schFrqEverydayId', true);
          }
        } else {
          if (this.schFrq.everyDay) {
            this.schFrq.everyDay = !this.schFrq.everyDay;
          }
          this.count--;
          this.chnageButtonsClass(btnId, false);
          this.chnageButtonsClass('schFrqEverydayId', false);
        }
      }
    },
    chnageButtonsClass(btnId, flag) {
      if (flag && this.$refs[btnId].children[0]) {
        this.$refs[btnId].children[0].classList.remove('mds-button--secondry');
        this.$refs[btnId].children[0].classList.add('mds-button--primary');
      } else if (this.$refs[btnId].children[0]) {
        this.$refs[btnId].children[0].classList.remove('mds-button--primary');
        this.$refs[btnId].children[0].classList.add('mds-button--secondry');
      }
    },
    loadSchedulerDetails() {
      const fileType = ['htm', 'xlsx', 'csv', 'docx', 'txt', 'pdf',
        'xml', 'csvnoheader', 'json', 'doc', 'html', 'xls', 'odt', 'ods'];
      if (this.scheduler && this.btnName !== 'Create') {
        this.schName = this.scheduler.scheduleName;
        this.schDescription = this.scheduler.description;
        this.schEnabaleDisable = this.scheduler.enabled !== 'false';
        this.schMachineID = this.scheduler.machineId;
        if (this.scheduler.listNames && this.scheduler.listNames.length > 0) {
          this.scheduler.listNames.forEach((listName) => {
            this.schPubList.push(listName);
          });
        }
        this.schFileDateFormat = [this.scheduler.fileDateFormat];
        if (this.fileType === undefined) {
          this.schFileExtensionFormat = ['csv'];
          this.schCustomFileExtension = '';
        } else if (fileType.includes(this.scheduler.fileType.toLowerCase())) {
          this.schFileExtensionFormat = [this.scheduler.fileType];
        } else {
          this.schFileExtensionFormat = ['custom'];
          this.schCustomFileExtension = this.scheduler.fileType;
        }
        this.schDirFilePath = this.scheduler.fileDirectory;
        this.schTimeZone = [this.scheduler.timezone];
        this.schLastValueOnly = this.scheduler.lastValue;
        if (this.scheduler.newData) {
          this.schCKDRadio = 'true';
          this.schDaysForData = [`${this.scheduler.numberDaysBack}`];
        } else {
          this.schCKDRadio = 'false';
          this.schDaysForData = [`${this.scheduler.numberDaysBack}`];
        }
        this.schIncHistoryData = (this.scheduler.includeHistory === 'true') || (this.scheduler.includeHistory === true);
        this.schLastValueOnly = (this.scheduler.lastValue === 'true') || (this.scheduler.lastValue === true);
        this.schMsgBusCheck = (this.scheduler.msgBusCheck === 'true') || (this.scheduler.msgBusCheck === true);
        this.schMsgBusName = this.scheduler.busName ? this.scheduler.busName : '';
        this.schMsgBusType = [this.scheduler.messageBusType ? this.scheduler.messageBusType : ''];
        // eslint-disable-next-line max-len
        if (this.scheduler.numberOfPubDaysBack && Number(this.scheduler.numberOfPubDaysBack) >= 0 && Number(this.scheduler.numberOfPubDaysBack) <= 365) {
          this.schFromPubDate = true;
          this.schDays = [`${this.scheduler.numberOfPubDaysBack}`];
        }
        this.schRmtoPubDateTime = (this.scheduler.disableToPubDate === 'true');
        this.evaluateCronExpression();
      }
    },
    removeCornError(schTRIRadioVal, schTimeRangeHoursVal) {
      this.schTRIRadio = schTRIRadioVal;
      this.schTimeRangeHours = schTimeRangeHoursVal;
      this.cronError = false;
      this.schFrequencyError = false;
      const daysfreq = this.createSchFreqExpression();
      if (!daysfreq) {
        this.schFrequencyError = true;
      }

      if ((((this.schTRIRadio === '1') || (this.schTRIRadio === 1))
      && (this.schTimeRangeHours.length > 0))
      && (document.getElementById('schMinuteOnHour'))) {
        document.getElementById('schMinuteOnHour').disabled = false;
        this.$refs['schMinuteOnHour'].children[0].getElementsByTagName('input')[0].removeAttribute('disabled');
      } else if (document.getElementById('schMinuteOnHour')) {
        document.getElementById('schMinuteOnHour').disabled = true;
        document.getElementById('schMinuteOnHour').removeAttribute('checked');
        this.$refs['schMinuteOnHour'].children[0].getElementsByTagName('input')[0].setAttributeNode(
          document.createAttribute('disabled'),
        );
        this.schMinuteOnHour = false;
      }
    },
    removePubListError() {
      this.schPubListError = false;
    },
    createSchFreqExpression() {
      let strToReturn = '';
      if (this.schFrq.everyDay) {
        return '*';
      }
      for (let i = 0; i < this.schFrq.weekDays.length; i++) {
        if (this.schFrq.weekDays[i]) {
          strToReturn = strToReturn === '' ? strToReturn = (i + 1) : (strToReturn = `${i + 1 },${ strToReturn}`);
        }
      }
      if (strToReturn) {
        return `${strToReturn }`;
      }
      return null;
    },
    // eslint-disable-next-line consistent-return
    createCornExpression() {
      let cornExpression = '';
      const daysfreq = this.createSchFreqExpression();
      if (this.schTRIRadio === '0' && daysfreq !== null && (this.schStartStop.min >= 0 || this.schStartStop.max <= 23)) {
        // eslint-disable-next-line max-len
        cornExpression = `0 0${ (this.schTimeIncTI[0] === 'Minutes') ? (`/${ this.schTimeIncChkEvery[0]} `) : ' ' }${this.schStartStop.min }-${ this.schStartStop.max }${(this.schTimeIncTI[0] === 'Hours') ? (`/${ this.schTimeIncChkEvery[0]}`) : '' }${(daysfreq === '*') ? ' * * ?' : ` ? * ${ daysfreq}`}`;
        return cornExpression;
      }

      if (this.schTRIRadio === '1' && daysfreq && (this.schTimeRangeHours.length > 0)) {
        this.schTimeRangeHours = [...new Set(this.schTimeRangeHours)];
        // eslint-disable-next-line max-len
        cornExpression = `0 ${ this.schTimeRangeMins[0] } ${ this.schTimeRangeHours.sort().toString() } ${ daysfreq === '*' ? '* * ?' : (`? * ${ daysfreq}`)}`;
        return cornExpression;
      }
      this.cronError = true;
      return '';
    },
    resetAllValues() {
      this.schName = '';
      this.schNameError = false;
      this.schPatternError = false;
      this.schPubListError = false;
      this.schFrequencyError = false;
      this.schFileTypeError = false;
      this.customFileFormatErrorStr = '';
      this.schMachineIdError = false;
      this.cronError = false;
      this.scheduleExists = false;
      this.schedulePatternError = false;
      this.schEnabaleDisable = true;
      this.schDescription = '';
      this.schMachineID = '1';
      this.schPubList = [];
      this.schDirFilePath = '';
      this.schCKDRadio = 'true';
      this.schDaysForData = '0';
      this.schFromPubDate = false;
      this.schIncHistoryData = false;
      this.schRmtoPubDateTime = false;
      this.schLastValueOnly = '';
      this.schTRIRadio = '';
      this.schCorn = '';
      this.schFrq = {
        weekDays: [false, false, false, false, false, false, false],
        everyDay: false,
      };
      this.schMinuteOnHour = false;
      this.schFileDateFormat = ['yyyyMMddHHmmss'];
      this.schFileExtensionFormat = ['csv'];
      this.schCustomFileExtension = '';
      this.schTimeRangeHours = [0];
      this.schTimeRangeMins = ['0'];
      this.schDays = [];
      this.schTimeIncChkEvery = ['1'];
      this.schTimeIncTI = ['Minutes'];
      this.schStartStop = { min: 0, max: 23 };
      this.schTimeZone = ['America/Chicago'];
      this.schMsgBusCheck = false;
      this.schMsgBusName = '';
      this.schMsgBusType = [''];
      this.schMsgBusNameError = false;
      this.isMsgBusIntegrationCorrectVal = '';
      this.listNameErrorFlag = false;
      this.listNameError = '';
    },
    createScheduler(event) {
      event.preventDefault();
      const schedule = {};
      this.schCorn = this.createCornExpression();
      if (this.schName === '') {
        schedule.scheduleName = '';
        this.schNameError = true;
        return;
      }
      schedule.scheduleName = `${this.schName }`;
      schedule.enabled = `${this.schEnabaleDisable }`;
      schedule.description = `${this.schDescription }`;

      if (this.schMachineID === '') {
        this.schMachineIdError = true;
        return;
      }
      schedule.machineId = `${this.schMachineID }`;

      if (this.schPubList.length === 0) {
        this.schPubListError = true;
        schedule.listNames = '';
        return;
      }
      schedule.listNames = `${this.schPubList }`;
      schedule.fileDateFormat = `${this.schFileDateFormat }`;
      if (this.schFileExtensionFormat[0] === '') {
        this.schFileTypeError = true;
        this.customFileFormatErrorStr = 'File type selected is invalid..!';
        return;
      }
      if (this.schFileExtensionFormat[0] === 'custom') {
        schedule.fileSuffix = `${this.schCustomFileExtension }`;
        schedule.fileType = `${this.schCustomFileExtension }`;
      } else {
        schedule.fileSuffix = `${this.schFileExtensionFormat[0] }`;
        schedule.fileType = `${this.schFileExtensionFormat[0] }`;
      }
      schedule.fileDirectory = `${this.schDirFilePath }`;

      if (this.schCorn === '' || this.schFrequencyError) {
        this.schFrequencyError = true;
        this.cronError = true;
        // eslint-disable-next-line no-alert
        alert('Please set valid corn expression by selection frequency and time.');
        return;
      }
      schedule.cron = `${this.schCorn}`;
      schedule.lastValue = `${this.schLastValueOnly}`;
      schedule.timezone = `${this.schTimeZone }`;
      schedule.newData = `${this.schCKDRadio }`;
      schedule.numberDaysBack = `${this.schDaysForData[0]}`;
      schedule.includeHistory = `${this.schIncHistoryData}`;
      schedule.disableToPubDate = `${this.schRmtoPubDateTime}`;
      schedule.isMsgBusCheck = `${this.schMsgBusCheck}`;
      if ((this.schMsgBusCheck === true || this.msgBusCheck === 'true') && this.isMsgBusIntegrationCorrectVal) {
        schedule.busName = `${this.schMsgBusName}`;
        schedule.messageBusType = `${this.schMsgBusType}`;
      }
      if ((Boolean(this.schFromPubDate) === true)) {
        [schedule.numberOfPubDaysBack] = this.schDays;
      } else {
        this.schDays = [];
      }
      if (this.btnName === 'Create') {
        this.overlay = true;
        this.showloader = true;
        this.createSchedule(schedule).then((response) => {
          this.workOnResponse(response);
        }).catch((error) => {
          this.workOnError(error);
        });
      } else if (this.btnName === 'Edit') {
        this.overlay = true;
        this.showloader = true;
        this.updateSchedule(schedule).then((response) => {
          this.workOnResponse(response);
        }).catch((error) => {
          this.workOnError(error);
        });
      }
    },
    workOnError(error) {
      this.listNameError = error.response.Message || error.response.data;
      this.listNameErrorFlag = true;
      this.overlay = false;
      this.showloader = false;
    },
    workOnResponse(response) {
      if (response !== undefined && response.status !== 200) {
        this.errorText = response.Message || response.data;
        this.$refs.notificationComponent.triggerError(this.errorText);
        this.overlay = false;
        this.showloader = false;
        this.$emit('createScheduleError', this.errorText);
      } else if (response) {
        this.$refs.notificationComponent.triggerSuccess(response.data);
        this.overlay = false;
        this.showloader = false;
        this.$emit('createScheduleSucess', response.data);
      } else {
        this.errorText = response.Message || response.data;
        this.$refs.notificationComponent.triggerError(this.errorText);
        this.showPageError = true;
        this.overlay = false;
        this.showloader = false;
        this.$emit('createScheduleError', this.errorText);
      }
      this.resetAllValues();
    },
    evalCornForRange() {
      this.schTRIRadio = '1';
      this.schTimeRangeHours = [];
      this.separatedCorn[2].split(',').forEach((item) => {
        this.schTimeRangeHours.push(toNumber(item));
      });
      this.schTimeRangeHours = this.schTimeRangeHours.sort();
      if (this.separatedCorn[1] !== '') {
        this.schMinuteOnHour = true;
        this.schTimeRangeMins = [`${this.separatedCorn[1] }`];
      }
      if (this.separatedCorn[3] === '*') {
        this.schFrq.everyDay = true;
        this.schFrq.weekDays = [true, true, true, true, true, true, true];
      } else if (this.separatedCorn[3] === '?') {
        const days = this.separatedCorn[5].split(',');
        for (let i = 0; i < days.length; i++) {
          this.schFrq.weekDays[Number(days[i]) - 1] = true;
        }
      }
    },
    evalCornForIncrement() {
      this.schTRIRadio = '0';

      if (this.separatedCorn[1].includes('/') && this.separatedCorn[1].split('/')[1]) {
        this.schTimeIncChkEvery = [`${this.separatedCorn[1].split('/')[1] }`];
        this.schTimeIncTI = ['Minutes'];
      } else {
        this.schTimeIncChkEvery = `${this.separatedCorn[1] }`;
      }

      if (this.separatedCorn[2].includes('/') && this.separatedCorn[2].split('/')[1]) {
        this.schTimeIncChkEvery = [`${this.separatedCorn[2].split('/')[1] }`];
        this.schTimeIncTI = ['Hours'];
        const startStopHrs = this.separatedCorn[2].split('/')[0].split('-');
        this.schStartStop.max = `${startStopHrs[1]}`;
        this.schStartStop.min = `${startStopHrs[0]}`;
      } else {
        const startStopHrs = this.separatedCorn[2].split('-');
        if (startStopHrs.length > 1) {
          this.schStartStop.max = `${startStopHrs[1]}`;
          this.schStartStop.min = `${startStopHrs[0]}`;
        } else {
          [this.schStartStop.max] = startStopHrs;
          [this.schStartStop.min] = startStopHrs;
        }
      }
      if (this.separatedCorn[3] === '*') {
        this.schFrq.everyDay = true;
        this.schFrq.weekDays = [true, true, true, true, true, true, true];
      } else if (this.separatedCorn[3] === '?') {
        const days = this.separatedCorn[5].split(',');
        for (let i = 0; i < days.length; i++) {
          this.schFrq.weekDays[Number(days[i]) - 1] = true;
        }
      }
    },
    evaluateCronExpression() {
      if (this.scheduler.cron) {
        this.evalCorn = this.scheduler.cron;
        this.separatedCorn = this.evalCorn.split(' ');
        if (this.evalCorn.includes('/')) {
          this.evalCornForIncrement();
        } else {
          this.evalCornForRange();
        }
      }
    },
  },
};
</script>

<style scoped>
.mds-form__label {
  font-weight: 450;
}
.padding-top-bottom {
  padding: 2rem 1rem 2rem 1rem;
}
.pading_1rem {
  padding: 1rem;
}
.pading-top_15rem {
  padding-top: 1.5rem;
}
.pading-top_1rem {
  padding-top: 1rem;
}
.pading-top_05rem {
  padding-top: 0.5rem;
}
.horizontal-bar {
  border-top: 0.10px solid #dadada;
}
.padding-right_05rem {
  padding-right: 0.5rem;
}
.padding-right_2rem {
  padding-right: 2rem;
}
.sch-msg-chk {
  padding: 1.5rem 0rem 1rem 1rem;
  max-width: 3.33333% !important;
}
.sch-up-top {
  top: 5px !important;
}
.sch-up-top0 {
  top: 0px !important;
}
</style>

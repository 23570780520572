import _ from 'underscore';
import jstz from 'jstz';
import Strings, { requireNonEmptyString } from './strings.es6';
import Arrays from './arrays.es6';
import Objects from './objects.es6';

/** @type {string[]} */
const _ids = Object.freeze(_.values(jstz.olson.timezones).sort(Strings.compare));

/**
 * Finds the default time-zone, but make sure to ignore the
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl ECMAScript Internationalization API}.
 * Intl reports time-zones that are not in the Olson DB, which causes a problem when trying
 * to match it to a list of time-zones we're showing users; the list comes from the Olson DB.
 * @param {*} [undef] Pass nothing for a proper undefined value.
 * @returns {string} Name of the default time zone, found in Olson DB.
 * @private
 */
function _getJstzDefault(undef) {
  const oldIntl = window.Intl;
  window.Intl = undef;
  try {
    return jstz.determine().name();
  } finally {
    window.Intl = oldIntl;
  }
}

/**
 * @param {(string|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is a recognized time-zone ID.
 */
export function isTimeZoneId(arg) {
  return (Arrays.indexSearch(arg, _ids) >= 0);
}

/**
 * Validates the given time-zone ID to be recognized, throws otherwise.
 * @param {string} arg Argument to validate.
 * @param {string} argName Name given to `arg`, for when error must be thrown.
 * @returns {string} Always returns `arg`
 * @throws {TypeError} If `arg` is not a string or is empty.
 * @throws {Error} If `arg` is not a recognized time-zone ID.
 */
export function requireTimeZoneId(arg, argName) {
  if (!isTimeZoneId(requireNonEmptyString(arg, argName))) {
    throw new Error(`${argName}: unrecognized time-zone ID: ${arg}`);
  }
  return arg;
}

/** @namespace */
const TimeZone = Object.freeze(/** @lends {TimeZone} */ {

  /**
     * @returns {string[]} Immutable list of time-zone IDs, sorted alphabetically.
     */
  getIDs() {
    return _ids;
  },

  /**
     * @returns {string} Default time-zone ID based on current user's configuration.
     */
  getDefault() {
    const tz = Objects.getRuntimeCfg('timezone', null);
    return ((Strings.isNonEmpty(tz))
      ? tz
      : _getJstzDefault()
    );
  },

  /**
     * @param {string} id
     * @returns {boolean} Whether `id` is a recognized time-zone ID.
     */
  isValidID(id) {
    return isTimeZoneId(Strings.requireNonEmpty(id, 'id'));
  },

  /**
     * Validates the given time-zone ID to be recognized, throws otherwise.
     * @param {string} tzId A valid time-zone ID.
     * @param {string} argName Name given to `tzId`, for when error must be thrown.
     * @returns {string} Always returns `tzId`
     * @throws {TypeError} If `tzId` is not a string or is empty.
     * @throws {Error} If `tzId` is not a recognized time-zone ID.
     */
  requireValidID(tzId, argName) {
    return requireTimeZoneId(tzId, argName);
  },
});

export default TimeZone;

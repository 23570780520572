<template>
  <div>
    <div
      v-if="loader"
      id="mds-overlay"
      class="mds-overlay"
    />
    <div class="mds-header border-bottom-solid">
      <h1 class="mds-header__title component-title">
        {{ $t('labels.feed.createFeed') }}
      </h1>
    </div>
    <form
      class="mds-form mds-form--medium-layout custom_form"
      method=""
      action=""
    >
      <fieldset
        class="mds-form__fieldset"
      >
        <legend class="mds-form__legend" />
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="datasource"
          >
            {{ $t('labels.feed.dataSource') }}
            <abbr class="mds-form__label-required-indicator">*</abbr>
          </label>
          <div class="mds-form__select">
            <select
              id="datasource"
              v-model="dataSource"
              class="mds-form__select-input custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
              @change="getSourceName($event)"
            >
              <option
                v-for="(datasource, index) in userDatasource"
                :key="index"
                class="mds-form__select-option"
                :data-provider="datasource.provider"
                :value="datasource.name"
              >
                {{ datasource.name }}
              </option>
            </select>
            <div class="mds-form__select-visual-wrap" />
            <span class="mds-form__select-open-indicator">
              <svg
                class="mds-icon mds-form__select-open-icon"
                aria-hidden="true"
              >
                <use xlink:href="#caret-down--s" />
              </svg>
            </span>
          </div>
        </div>
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="feedName"
          >
            {{ $t('labels.feed.feedName') }}
            <abbr class="mds-form__label-required-indicator">*</abbr>
          </label>
          <input
            id="feedName"
            v-model="feedName"
            class="mds-form__input custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
            type="text"
            maxlength="55"
            @keyup="checkSpecial($event)"
          >
          <span
            v-if="feedSourceCheck"
            id="feedName"
            class="mds-form__field-error"
            role="alert"
          >
            <span class="mds-form__field-error-text">
              {{ $t('labels.feed.feedNameError',{dsource: sourceName}) }}
            </span>
          </span>
          <span
            v-if="specialCharCheck"
            id="feedName"
            class="mds-form__field-error"
            role="alert"
          >
            <span class="mds-form__field-error-text">
              {{ $t('labels.feed.specialCharError') }}
            </span>
          </span>
          <span
            v-if="feedLengthCheck"
            id="feedName"
            class="mds-form__field-error"
            role="alert"
          >
            <span class="mds-form__field-error-text">
              {{ $t('labels.feed.feedLenthError',{dsource: sourceName}) }}
            </span>
          </span>
        </div>
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="description"
          >
            {{ $t('labels.common.description') }}
            <abbr class="mds-form__label-required-indicator">*</abbr>
          </label>
          <textarea
            id="description"
            v-model="description"
            class="mds-form__textarea custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
          />
        </div>
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="timeUnits"
          >
            {{ $t('labels.feed.timeUnits') }}
            <abbr class="mds-form__label-required-indicator">*</abbr>
          </label>
          <div class="mds-form__select">
            <select
              id="timeUnits"
              v-model="timeUnits"
              class="mds-form__select-input  custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
              @change="clearTimezone"
            >
              <option
                v-for="(unit, index) in units"
                :key="index"
                class="mds-form__select-option"
                :value="unit"
              >
                {{ unit }}
              </option>
            </select>
            <div class="mds-form__select-visual-wrap" />
            <span class="mds-form__select-open-indicator">
              <svg
                class="mds-icon mds-form__select-open-icon"
                aria-hidden="true"
              >
                <use xlink:href="#caret-down--s" />
              </svg>
            </span>
          </div>
        </div>
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="timezone"
          >
            {{ $t('labels.feed.timezone') }}
            <abbr
              v-if=" timeUnits !== 'DAY' "
              class="mds-form__label-required-indicator"
            >*</abbr>
          </label>
          <div class="mds-form__select">
            <select
              id="timezone"
              v-model="timeZone"
              class="mds-form__select-input custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
            >
              <option
                v-for="(timezone, index) in timezones"
                :key="index"
                class="mds-form__select-option"
                :value="timezone"
              >
                {{ timezone }}
              </option>
            </select>
            <div class="mds-form__select-visual-wrap" />
            <span class="mds-form__select-open-indicator">
              <svg
                class="mds-icon mds-form__select-open-icon"
                aria-hidden="true"
              >
                <use xlink:href="#caret-down--s" />
              </svg>
            </span>
          </div>
        </div>
        <div class="mds-header mds-header--secondary mds-header--border-bottom">
          <div class="mds-form__field-group">
            <label
              class="mds-form__checkbox"
              for="futuresFeed"
            >
              <input
                id="futuresFeed"
                v-model="futureCheck"
                type="checkbox"
                class="mds-form__checkbox-input"
                name="checkbox-name"
                value="accept"
                @change="addFutureFields"
              >
              <span class="mds-form__checkbox-visible-wrap">
                <span class="mds-form__checkbox-visual">
                  <svg
                    class="mds-icon mds-form__checkbox-checked-icon"
                    aria-hidden="true"
                  >
                    <use xlink:href="#check--s" />
                  </svg>
                  <svg
                    class="mds-icon mds-form__checkbox-indeterminate-icon"
                    aria-hidden="true"
                  >
                    <use xlink:href="#minus--s" />
                  </svg>
                </span>
                <span class="mds-form__checkbox-text">
                  {{ $t('labels.feed.futureFeed') }}
                </span>
              </span>
            </label>
          </div>
        </div>
        <div class="mds-header mds-header--secondary mds-header--border-bottom">
          <div class="mds-form__field-group">
            <mds-layout-grid>
              <mds-row>
                <mds-col
                  :cols="12"
                  :cols-at-l="4"
                >
                  <h3 class="mds-header__title group-save-button">
                    {{ $t('labels.feed.keys') }}
                    <abbr class="mds-form__label-required-indicator">*</abbr>
                  </h3>
                  <ul class="mds-list-group">
                    <li
                      v-for="(keyInput, index) in keyInputs"
                      :key="'key_' + index"
                      class="mds-list-group__item component-title"
                    >
                      <a class="mds-list-group__link ">
                        <div>
                          <input
                            id="key"
                            v-model="keyInput.key"
                            class="mds-form__input custom_key_width custom_key_width-at-1200"
                            type="text"
                            name="0[name]"
                            placeholder="Key Name"
                            value=""
                            :disabled="keyInput.readOnly"
                          >
                          <a
                            v-if="keyInput.key==='Root'||keyInput.key==='DeliveryStart'||keyInput.key==='DeliveryEnd'?false:true"
                            class="custom-key-value"
                            @click="deleteKeyRow(index)"
                          ><span class="ic-remove-sm">x</span></a>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <a
                    class="custom-key-value"
                    @click="addKeyRow()"
                  >
                    <span class="ic-add-sm" />+addKey</a>
                </mds-col>
                <mds-col
                  :cols="12"
                  :cols-at-l="8"
                >
                  <h3 class="mds-header__title group-save-button">
                    {{ $t('labels.feed.columns') }}
                    <abbr class="mds-form__label-required-indicator">*</abbr>
                  </h3>
                  <ul class="mds-list-group">
                    <li
                      v-for="(valueInput, index) in valueInputs"
                      :key="'value_' + index"
                      class="mds-list-group__item component-title"
                    >
                      <a class="mds-list-group__link ">
                        <div>
                          <input
                            id="valueName"
                            v-model="valueInput.value"
                            class="mds-form__input val_pad custom_key_width custom_key_width-at-1200 custom_value_width-at-540"
                            type="text"
                            name="0[name]"
                            placeholder="Column Name"
                            value=""
                          >
                          <div class="mds-form__select">
                            <select
                              id="dataTypes"
                              v-model="valueInput.format"
                              class="mds-form__select-input custom_key_width custom_key_width-at-1200 custom_value_width-at-540"
                            >
                              <option
                                v-for="(dataType) in dataTypes"
                                :key="dataType"
                                class="mds-form__select-option"
                                :value="dataType"
                              >
                                {{ dataType }}
                              </option>
                            </select>
                            <div class="mds-form__select-visual-wrap" />
                            <span class="mds-form__select-open-indicator">
                              <svg
                                class="mds-icon mds-form__select-open-icon"
                                aria-hidden="true"
                              >
                                <use xlink:href="#caret-down--s" />
                              </svg>
                            </span>
                          </div>
                          <a
                            class="custom-key-value"
                            @click="deleteValueRow(index)"
                          ><span class="ic-remove-sm">x</span></a>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <a
                    class="custom-key-value"
                    @click="addValueRow()"
                  >
                    <span class="ic-add-sm" />+addColumn</a>
                </mds-col>
              </mds-row>
            </mds-layout-grid>
          </div>
        </div>
      </fieldset>
      <div class="demo-button-container">
        <button
          id="new-feed-back-button"
          class="mds-button val_pad mds-button--primary font-size-small new-feed-save-button"
          type="button"
          @click="goBack"
        >
          {{ $t('labels.group.groupBackButton') }}
        </button>
        <button
          id="new-feed-save-button"
          class="mds-button mds-button--primary font-size-small new-feed-save-button"
          type="button"
          :disabled="feedSourceCheck || specialCharCheck || feedLengthCheck"
          @click="formSubmit"
        >
          {{ $t('labels.feed.saveFeed') }}
        </button>
      </div>
    </form>
    <div
      id="mds-notification"
      class="mds-notification mds-notification--top-center mds-notification--below-masthead mds-notification--active custom-top"
    >
      <mds-notification-group>
        <mds-notification
          ref="successDialog"
          :list="JSON.stringify(successText)"
          role="alertdialog"
          status="success"
          size="small"
          dismiss-delay="45000"
          dismissible="true"
          removable="false"
          class="z-index-9999"
        >
          {{ $t('labels.feed.successDialogMsg') }}
        </mds-notification>
        <mds-notification
          ref="errorDialog"
          :list="JSON.stringify(errorText)"
          role="alertdialog"
          status="error"
          size="small"
          dismiss-delay="35000"
          dismissible="true"
          removable="false"
          class="z-index-9999"
        >
          {{ $t('labels.feed.errorDialogMsg') }}
        </mds-notification>
      </mds-notification-group>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import moment from 'moment-timezone';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { getUserName } from '../../utils/authService';

export default {
  name: 'NewFeed',
  components: { MdsLayoutGrid, MdsRow, MdsCol },
  data() {
    return {
      userName: '',
      loader: '',
      successText: [],
      errorText: [],
      userDatasource: [],
      futureCheck: false,
      feedName: '',
      timezones: [],
      keyInputs: [{
        key: '',
      }],
      valueInputs: [{
        value: '',
        format: '',
      }],
      description: '',
      dataSource: '',
      timeUnits: '',
      timeZone: '',
      units: [],
      sourceName: '',
      checkFeedName: '',
      feedSourceCheck: false,
      specialCharCheck: false,
      feedLengthCheck: false,
      dataTypes: [],
      cloneFeed: '',
      provider: '',
    };
  },
  computed: {
    ...mapGetters({
      feedDetails: 'feedModule/getFeedDetails',
      userSource: 'feedModule/getUserDatasource',
    }),
  },
  created() {
    this.loader = true;
    this.getDatasource().then(() => {
      this.userName = getUserName();
      this.userDatasource = this.userSource;
      this.units = this.$store.state.feedModule.units;
      this.dataTypes = this.$store.state.feedModule.dataTypes;
      this.timezones = moment.tz.names();
      this.checkForDefaultValues();
      this.checkIfCloneFeed();
      this.loader = false;
    });
  },
  methods: {
    ...mapActions('feedModule', ['getDatasource', 'createNewFeed', 'postSaveReadEntitlements', 'saveWriteEntitlements']),
    checkIfCloneFeed() {
      if (this.$route.name === 'CloneFeed') {
        this.cloneFeed = this.feedDetails.name;
        this.feedName = `${this.feedDetails.name}_Clone`;
        this.dataSource = this.feedDetails.dataSource;
        this.description = this.feedDetails.description;
        this.timeUnits = this.feedDetails.timeUnits;
        this.provider = this.feedDetails.provider;
        this.timeZone = this.feedDetails.timezone;

        this.keyInputs = [];
        this.valueInputs = [];
        this.feedDetails.fields.forEach(((field) => {
          if (field.type.toLowerCase() === 'k') {
            if (field.fieldName === 'Root' || field.fieldName === 'DeliveryStart' || field.fieldName === 'DeliveryEnd') {
              this.keyInputs.push({ key: field.fieldName, readOnly: true });
              this.futureCheck = true;
            } else {
              this.keyInputs.push({ key: field.fieldName });
            }
          } else {
            this.valueInputs.push({ value: field.fieldName, format: field.fieldDataType });
          }
        }));
      }
    },
    addKeyRow(index) {
      this.keyInputs.push({ key: '' });
    },
    deleteKeyRow(index) {
      this.keyInputs.splice(index, 1);
    },
    addValueRow(index) {
      this.valueInputs.push({ value: '', format: '' });
    },
    deleteValueRow(index) {
      this.valueInputs.splice(index, 1);
    },
    getSourceName(event) {
      this.feedName = `${event.target.value}_`.replace(/\s/g, '');
      this.sourceName = `${event.target.value}_`.replace(/\s/g, '');
      this.provider = event.target.selectedOptions[0].dataset.provider;
    },
    clearTimezone() {
      if (this.timeUnits === 'DAY') {
        this.timeZone = 'America/Chicago';
        this.timezones = moment.tz.names();
      } else {
        this.timezones = moment.tz.names();
      }
    },
    checkSpecial(event) {
      this.checkFeedName = `${event.target.value}`;
      const regex = /[^\w\\s]/ig;

      if (!(this.checkFeedName).startsWith(this.sourceName)) {
        this.feedSourceCheck = true;
        this.feedLengthCheck = false;
      } else if ((this.checkFeedName).length <= (this.sourceName).length) {
        this.feedLengthCheck = true;
        this.specialCharCheck = false;
        this.feedSourceCheck = false;
      } else if ((this.checkFeedName).match(regex)) {
        this.specialCharCheck = true;
        this.feedLengthCheck = false;
        this.feedSourceCheck = false;
      } else {
        this.feedSourceCheck = false;
        this.specialCharCheck = false;
        this.feedLengthCheck = false;
      }
    },
    addFutureFields() {
      if (this.futureCheck) {
        this.keyInputs.forEach((input) => {
          if (input.key === '') {
            this.keyInputs.splice(this.keyInputs.findIndex(item => item.key === ''), 1);
          }
        });
        // eslint-disable-next-line max-len
        this.keyInputs.push({ key: 'Root', readOnly: true }, { key: 'DeliveryStart', readOnly: true }, { key: 'DeliveryEnd', readOnly: true });
      } else {
        this.keyInputs.splice(this.keyInputs.findIndex(item => item.key === 'Root'), 1);
        this.keyInputs.splice(this.keyInputs.findIndex(item => item.key === 'DeliveryStart'), 1);
        this.keyInputs.splice(this.keyInputs.findIndex(item => item.key === 'DeliveryEnd'), 1);
        if (this.keyInputs.length === 0) {
          this.keyInputs.push({ key: '' });
        }
      }
    },
    async formSubmit() {
      this.loader = true;
      const users = [];
      const fields = [];
      this.successText = [];
      this.errorText = [];
      users.push(this.userName);

      if (this.keyInputs.length === 0 || this.valueInputs.length === 0) {
        this.errorText.push('Please add key and value.');
        this.$refs.errorDialog.open();
        this.loader = false;
        return false;
      }

      const duplicateKey = this.keyInputs.filter(key1 => this.keyInputs.filter(key2 => key2.key === key1.key).length > 1);
      if (duplicateKey.length) {
        this.errorText.push('Duplicate keys not allowed.');
        this.$refs.errorDialog.open();
        this.loader = false;
        return false;
      }

      const duplicateValue = this.valueInputs.filter(val1 => this.valueInputs.filter(val2 => val2.value === val1.value).length
      > 1);
      if (duplicateValue.length) {
        this.errorText.push('Duplicate values not allowed.');
        this.$refs.errorDialog.open();
        this.loader = false;
        return false;
      }

      if (this.dataSource === ''
      || this.feedName === ''
      || this.description === ''
      || this.timeZone === ''
      || this.timeUnits === ''
      || this.keyInputs[0].key === ''
      || this.valueInputs[0].value === ''
      || this.valueInputs[0].format === '') {
        this.errorText.push('Please fill all fields.');
        this.$refs.errorDialog.open();
        this.loader = false;
        return false;
      }

      this.keyInputs.forEach(((kInput) => {
        fields.push({
          fieldName: kInput.key,
          type: 'K',
          fieldDataType: 'String(255)',
        });
      }));

      this.valueInputs.forEach(((vInput) => {
        fields.push({
          fieldName: vInput.value,
          type: 'V',
          fieldDataType: vInput.format,
        });
      }));

      this.hitCreateNewFeedApi(fields, users);

      return true;
    },
    async hitCreateNewFeedApi(fields, users) {
      await this.createNewFeed({
        name: this.feedName,
        description: this.description,
        dataSource: this.dataSource,
        provider: this.provider,
        multiValue: false,
        continuousBase: false,
        partialUpdates: true,
        privateCF: true,
        privateFeed: true,
        timeUnits: this.timeUnits,
        timeZone: this.timeZone,
        fields,
        cloneFeedName: this.cloneFeed,
      })
        .then((response) => {
          if (response.status === 201) {
            this.$refs.successDialog.open();
            this.addReadEnt().then((responses) => {
              if (responses[0].status === 200) {
                this.successText.push('Entitlements saved.');
              } else {
                this.$refs.errorDialog.open();
                this.errorText.push('Failed to save entitlements.');
              }
            }).then(() => {
              this.addWriteEnt(users).then((res) => {
                if (res[0].status === 200) {
                  this.successText.push('Entitlements saved.');
                } else {
                  this.$refs.errorDialog.open();
                  this.errorText.push('Failed to save entitlements.');
                }
              }).then(() => {
                this.goToFeedDetails();
              });
            });
          } else if (response.status === 409) {
            this.errorText.push('Feed already exists.');
            this.$refs.errorDialog.open();
            this.loader = false;
          } else if (response.status === 429) {
            this.errorText.push('User has exceeded their feed quota limit, can not create feed.'
            + ' Please contact Morningstar support.');
            this.$refs.errorDialog.open();
            this.loader = false;
          } else {
            this.errorText.push(
              response.data.errorMessage == null || response.data.errorMessage === ''
                ? response.statusText
                : response.data.errorMessage,
            );
            this.$refs.errorDialog.open();
            this.loader = false;
          }
        });
    },
    async addReadEnt() {
      const promises = [];
      const promise = await new Promise((resolve) => {
        resolve(this.postSaveReadEntitlements({
          feedName: this.feedName,
          users: [{ login: this.userName }],
        }));
      });
      promises.push(promise);
      return Promise.all(promises);
    },
    async addWriteEnt(users) {
      const promises = [];
      const promise = await new Promise((resolve) => {
        resolve(this.saveWriteEntitlements({ feedName: this.feedName, delete: 'no', users }));
      });
      promises.push(promise);
      return Promise.all(promises);
    },
    goToFeedDetails() {
      this.$router.push({
        path: `/feeds/${this.feedName}`,
        params: {
          feedName: this.feedName,
        },
      });
      this.loader = false;
    },
    goBack() {
      if (this.$route.params && this.$route.params.redirectedFrom === 'workflowMigrate') {
        this.$router.push({
          path: '/workflow',
        });
      } else if (this.cloneFeed === '') {
        this.$router.push({
          path: '/feeds',
        });
      } else {
        this.$router.push({
          path: `/feeds/${this.cloneFeed}`,
          params: {
            feedName: this.cloneFeed,
          },
        });
      }
      this.loader = false;
    },
    checkForDefaultValues() {
      if (this.$route.params && this.$route.params.redirectedFrom === 'workflowMigrate') {
        this.feedName = this.$route.params.feedName || '';
        this.dataSource = this.$route.params.feedName ? this.$route.params.feedName.split('_')[0] : '';
        this.provider = this.$route.params.provider || '';
        this.timeUnits = this.$route.params.timeUnits || '';
        this.keyInputs = this.$route.params.keyInputs || [{ key: '' }];
        this.valueInputs = this.$route.params.valueInputs || [{ value: '', format: '' }];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/new_feed';
</style>

/* ****************************************
 * Private methods
 * **************************************** */

/**
 * @param {(function|*)} f
 * @returns {boolean} Whether `f` is a function.
 */
function isFunc(f) {
  return (typeof f === 'function');
}

function _inContext(context, fn) {
  /** @type {function} */
  const f = ((typeof fn === 'string') ? context[fn] : fn);
  if (!isFunc(f)) { throw new TypeError('fn: Function or String (pointer to a function of `context`)'); }

  return f.bind(context);
}


/** @namespace */
const Functions = Object.freeze(/** @lends {Functions} */ {

  is: isFunc,

  /**
     * Validates the given argument to be a function.
     * If valid, this method returns the given argument; otherwise it throws.
     * @param {Function} arg - Argument to be validated.
     * @param {string} argName - Name given to the argument.
     * @returns {Function} Always returns `arg`.
     * @throws {TypeError} If `arg` is not a function.
     */
  requireFunction(arg, argName) {
    if (typeof arg !== 'function') {
      throw new TypeError(`${argName }: Function`);
    }
    return arg;
  },

  /**
     * Validates the given argument to be a function or `null`.
     * If valid, this method returns the given argument; otherwise it throws.
     * @param {?Function} arg - Argument to be validated.
     * @param {string} argName - Name given to the argument.
     * @returns {?Function} Always returns `arg`.
     * @throws {TypeError} If `arg` is not a function and not null.
     */
  requireFunctionOrNull(arg, argName) {
    if (arg !== null) {
      Functions.requireFunction(arg, argName);
    }
    return arg;
  },

  findClass(fnPath) {
    if (typeof fnPath !== 'string') throw 'IllegalArgumentException: fnPath must be a String.';

    fnPath += '.';

    let obj = window;
    let startIdx = 0;
    let endIdx;
    while (obj && (endIdx = fnPath.indexOf('.', startIdx)) >= 0) {
      const name = fnPath.substring(startIdx, endIdx);
      startIdx = endIdx + 1;
      const pointer = obj[name];
      if (typeof pointer === 'object'
                || typeof pointer === 'function') obj = pointer;
      else obj = null;
    }
    return obj;
  },

  /**
     * Sets the context of a function.
     * @param {Object} context - Context to which the function will be bound.
     * @param {(string|function)} fn - If a string, must be a property of
     *        `context` representing a function.
     * @returns {function}
     * @method
     */
  inContext: _inContext,

  /**
     * Sets the context of multiple functions in one operation.
     * This is a convenience function to avoid calling
     * `Functions.inContext()` repeatedly.
     * @param {Object} context - Context to which the functions will be bound.
     * @param {...string} fns - Function names. Must represent functions of <code>context</code>.
     * @returns void
     */
  setInContext(context, fns) {
    if (typeof context === 'undefined'
            || context === null) throw 'IllegalArgumentException: context is null or undefined.';

    for (let i = 1; i < arguments.length; i++) {
      const fnName = arguments[i];
      if (typeof fnName !== 'string'
                || typeof context[fnName] !== 'function') throw `IllegalArgumentException: "${ fnName }" not a function of the specified context object.`;

      context[fnName] = _inContext(context, fnName);
    }
  },

  /**
     * Executes a function after a delays.  Useful for async heavy schemes,
     * where callers don't expect their callback to execute until after
     * their call has completed.
     *
     * @param {function} fn - Function to executed after a delay.
     * @param {?Object} [context] - Value of `this` within the execution of `fn`.
     * @param {number} [msDelay=10] - Delay, in milliseconds.
     * @returns {number}
     */
  delay(fn, context, msDelay) {
    if (typeof fn !== 'function') throw 'IllegalArgumentException: fn must be a Function.';

    if (typeof msDelay === 'undefined') msDelay = 10; // Enough to send at bottom of execution stack.
    else if (typeof msDelay !== 'number'
                 || msDelay < 0) throw 'IllegalArgumentException: msDelay must be a non-negative millisecond value (Integer).';

    if (typeof context !== 'undefined'
            && context !== null) fn = _inContext(context, fn);

    return window.setTimeout(fn, msDelay);
  },

});

/* ****************************************
 * Public object
 * **************************************** */
export default Functions;
export const
  { requireFunction } = Functions;
const { requireFunctionOrNull } = Functions;
const isFunction = isFunc;

const state = {
  company: '',
  routerLocation: '',
  inactive: false,
  darkMode: false,
  userName: '',
  uuid: '',
  lastReadTime: '',
  newNotifsCount: 0,
  userNotifs: [],
  notifSettings: [],
  globalSettings: new Map(),
  notificationsKeys: {
    keyName: 'Notifications',
    dataAlerts: 'Data Alerts',
    feedAlerts: 'Feed Alerts',
    add: 'ADD',
    remove: 'REMOVE',
  },
  emailPrefsKeys: {
    keyName: 'Email Preferences',
    newDataVendor: 'New Data Vendors',
  },
  marketsKeys: {
    keyName: 'Markets',
  },
  regionsKeys: {
    keyName: 'Regions',
  },
  dataAlertsCron: '',
};
export default state;

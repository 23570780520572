/**
 * remove null values from component config
 * @param {object} config 
 * @returns {array}
 */
function removeNullValues(config) {
  if (Array.isArray(config) || typeof config !== 'object') {
    return config;
  }

  if (!config) {
    return undefined;
  }

  return Object.keys(config).reduce((acc, next) => {
    const validProp = removeNullValues(config[next]);
    if (typeof validProp !== 'undefined') {
      acc[next] = validProp;
    }

    return acc;
  }, {});
}

const methods = {
  /**
   * get merged MWC component configuration
   * @returns {object} component configuration
   */
  getComponentConfig() {
    if (!this.mwcId) return null;

    let result;
    const configuration = window.mwc ? window.mwc.configuration : null;
    const parsedConfig =
      typeof this.config === 'string'
        ? JSON.parse(this.config)
        : this.config ||
          (configuration.injectedConfig &&
            configuration.injectedConfig[this.mwcId]);

    if (configuration) {
      result = configuration.getConfig(
        this.mwcId,
        removeNullValues(this.defaultConfig),
        parsedConfig
      );
    }

    return result;
  },

  /**
   * Number formatting for internationalization
   * @param  {string} args label to translate 
   * @returns {string}
   */
  $t(...args) {
    return this.$mwc.labeller.getLabel(...args);
  },

  /**
   * Number formatting for internationalization
   * @param {number} x number to format
   * @param {string} format ex: 'number'
   * @returns {string}
   */
  $nf(x, format) {
    return this.$mwc.formatter.number(x, format || 'number');
  },

  /**
   * Currency formatting for internationalization
   * @param {number} x number to format
   * @param {string} format ex: 'currency'
   */
  $cf(x, format) {
    return this.$mwc.formatter.currency(x, format || 'currency');
  },
  
  /**
   * Date formatting for internationalization
   * @param {number} x number to format
   * @param {string} format ex: 'date'
   */
  $df(x, format) {
    return this.$mwc.formatter.number(x, format || 'date');
  },
};

// We need access to "this" which doesn't work with instance funcs...
// this also hides functionality from non-mwc components
export default vm => {
  Object.keys(methods).forEach(method => {
    vm.$mwc[method] = methods[method].bind(vm); // eslint-disable-line
  });
};

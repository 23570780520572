<template>
  <div>
    <mds-loader
      v-show="showLoader"
      size="large"
    />
    <mds-layout-grid v-if="!showLoader">
      <div
        id="mds-notification"
        class="mds-notification mds-notification--top-center mds-notification--below-masthead
          mds-notification--active custom-top"
      >
        <mds-notification
          v-if="showErrorDialog"
          variation="error"
          title="Error fetching Reports"
          :dismiss-delay="dialogDelay"
          size="small"
          dismissible="true"
          class="z-index-9999"
          @mds-notification-dismissed="removeNotification()"
        >
          {{ errorMessage }}
        </mds-notification>
      </div>
      <div v-if="reportFetched">
        <mds-row align-horizontal="center">
          <mds-col :cols="12">
            <component-title
              class="mds-header__title component-title"
            >
              Workflow Report
            </component-title>
          </mds-col>
        </mds-row>
        <mds-row align-horizontal="center">
          <mds-col :cols="12">
            <div class="header3">
              Prepared for {{ company }}
            </div>
          </mds-col>
        </mds-row>
        <mds-row align-horizontal="center">
          <mds-col :cols="12">
            <div class="header3">
              Prepared on {{ currentTime() }}
            </div>
          </mds-col>
        </mds-row>
        <mds-row>
          <mds-col :cols="12">
            <div class="border" />
          </mds-col>
        </mds-row>
        <mds-row>
          <mds-col :cols="12">
            <mds-table
              v-for="workflow in workflows"
              :key="workflow.id"
              row-hover
              size="small"
              style="margin-top: 25px; margin-left: 10px; margin-right: 10px;"
            >
              <caption>
                <b>{{ workflow.name }}</b> ({{ workflow.id }}) <br>
                Last Modified: {{ formatTime(workflow.lastModified) }}
              </caption>
              <mds-tbody>
                <mds-layout-grid>
                  <mds-row>
                    <mds-col :cols="8">
                      <mds-layout-grid>
                        <mds-row>
                          <mds-col :cols="12">
                            <mds-table row-hover>
                              <mds-thead :nested-headers="1">
                                <template #mds-thead-group-1>
                                  <mds-th colspan="7">
                                    Permissions
                                  </mds-th>
                                </template>
                                <mds-th> User </mds-th>
                                <mds-th />
                                <mds-th />
                                <mds-th> View </mds-th>
                                <mds-th> Edit </mds-th>
                                <mds-th> Approve </mds-th>
                                <mds-th> Start/Stop </mds-th>
                              </mds-thead>
                              <mds-tbody>
                                <mds-tr>
                                  <mds-td :colspan="3">
                                    {{ workflow.owner }}
                                  </mds-td>
                                  <mds-td :colspan="4">
                                    (Owner)
                                  </mds-td>
                                </mds-tr>
                                <mds-tr
                                  v-for="permission in getWorkflowPerms(workflow)"
                                  :key="permission.userName"
                                >
                                  <mds-td :colspan="3">
                                    {{ permission.userName }}
                                  </mds-td>
                                  <mds-td>
                                    {{ (permission.perms.includes('view') ? 'X' : '') }}
                                  </mds-td>
                                  <mds-td>
                                    {{ (permission.perms.includes('edit') ? 'X' : '') }}
                                  </mds-td>
                                  <mds-td>
                                    {{ (permission.perms.includes('approval') ? 'X' : '') }}
                                  </mds-td>
                                  <mds-td>
                                    {{ (permission.perms.includes('start_stop') ? 'X' : '') }}
                                  </mds-td>
                                </mds-tr>
                              </mds-tbody>
                            </mds-table>
                          </mds-col>
                        </mds-row>
                        <mds-row style="margin-top: 20px;">
                          <mds-col :cols="12">
                            <mds-table row-hover>
                              <mds-thead :nested-headers="1">
                                <template #mds-thead-group-1>
                                  <mds-th colspan="6">
                                    Formulas
                                  </mds-th>
                                </template>
                                <mds-th> Name </mds-th>
                                <mds-th />
                                <mds-th> Last Modified </mds-th>
                                <mds-th> Who </mds-th>
                                <mds-th />
                                <mds-th />
                              </mds-thead>
                              <mds-tbody>
                                <!--eslint-disable-next-line vue/valid-v-for-->
                                <mds-tr
                                  v-for="formula in getWorkflowFormulas(workflow)"
                                >
                                  <mds-td :colspan="2">
                                    {{ (formula.name ? formula.name : 'N/A') }}
                                  </mds-td>
                                  <mds-td> {{ formatTime(formula.lastModified) }} </mds-td>
                                  <mds-td :colspan="3">
                                    {{ formula.owner }}
                                  </mds-td>
                                </mds-tr>
                                <mds-tr total-row>
                                  <mds-td> Total </mds-td>
                                  <mds-td :colspan="2" />
                                  <mds-td>
                                    {{ (getWorkflowFormulas(workflow).length) }}
                                  </mds-td>
                                  <mds-td :colspan="2" />
                                </mds-tr>
                              </mds-tbody>
                            </mds-table>
                          </mds-col>
                        </mds-row>
                      </mds-layout-grid>
                    </mds-col>
                    <mds-col :cols="4">
                      <mds-layout-grid>
                        <mds-row>
                          <mds-col :cols="12">
                            <mds-table row-hover>
                              <mds-thead :nested-headers="1">
                                <template #mds-thead-group-1>
                                  <mds-th colspan="2">
                                    Variable-sets by ({{ workflow.parameterSetGroup.owner }},
                                    on {{ formatTime(workflow.parameterSetGroup.lastModified) }})
                                  </mds-th>
                                </template>
                                <mds-th> Name </mds-th>
                                <mds-th />
                              </mds-thead>
                              <mds-tbody>
                                <mds-tr
                                  v-for="param in getWorkflowParams(workflow.parameterSetGroup)"
                                  :key="param.name"
                                >
                                  <mds-td :colspan="2">
                                    {{ (param.description ? param.name + ' (' + param.description + ')' : param.name) }}
                                  </mds-td>
                                </mds-tr>
                                <mds-tr total-row>
                                  <mds-td> Total </mds-td>
                                  <mds-td>
                                    {{ (getWorkflowParams(workflow.parameterSetGroup).length) }}
                                  </mds-td>
                                </mds-tr>
                              </mds-tbody>
                            </mds-table>
                          </mds-col>
                        </mds-row>
                      </mds-layout-grid>
                    </mds-col>
                  </mds-row>
                </mds-layout-grid>
              </mds-tbody>
              <mds-row>
                <mds-col :cols="12">
                  <div class="border" />
                </mds-col>
              </mds-row>
            </mds-table>
          </mds-col>
        </mds-row>
        <mds-row style="padding-top: 25px; padding-left: 10px;">
          <mds-col :cols="12">
            <mds-table>
              <mds-thead>
                <mds-th />
                <mds-th />
                <mds-th />
                <mds-th />
              </mds-thead>
              <mds-tbody>
                <mds-tr total-row>
                  <mds-td> Workflow Count </mds-td>
                  <mds-td>
                    {{ workflowCount }}
                  </mds-td>
                  <mds-td />
                  <mds-td />
                </mds-tr>
                <mds-tr total-row>
                  <mds-td> Variable-set Count </mds-td>
                  <mds-td>
                    {{ variableSetCount }}
                  </mds-td>
                  <mds-td />
                  <mds-td />
                </mds-tr>
              </mds-tbody>
            </mds-table>
          </mds-col>
        </mds-row>
        <mds-row>
          <mds-col :cols="12">
            <div class="border" />
          </mds-col>
        </mds-row>
      </div>
    </mds-layout-grid>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import { MdsNotification } from '@mds/notification';
import { mapActions } from 'vuex';
import moment from 'moment';
import { getUserRoles } from '../../utils/authService';

export default {
  name: 'AuditReport',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsNotification,
  },
  data() {
    return {
      company: '',
      workflows: [],
      workflowCount: 0,
      variableSetCount: 0,
      showLoader: true,
      showErrorDialog: false,
      errorMessage: '',
      reportFetched: false,
      dialogDelay: 10000,
    };
  },
  created() {
    this.company = this.$route.params.company;
    this.fetchWorkflowReports().then((resp) => {
      this.showLoader = false;
      if (resp.status === 200) {
        if (resp.data.workFlows && Array.isArray(resp.data.workFlows)) {
          this.workflows = resp.data.workFlows;
        } else if (resp.data.workFlows) {
          this.workflows.push(resp.data.workFlows);
        }
        if (resp.data.variableSetCount) {
          this.variableSetCount = resp.data.variableSetCount;
        }
        if (resp.data.workflowCount) {
          this.workflowCount = resp.data.workflowCount;
        }
        this.reportFetched = true;
      } else {
        this.showErrorDialog = true;
        this.errorMessage = resp.message;
      }
    });
  },
  methods: {
    ...mapActions('reportsModule', ['getWorkflowReports', 'getWorkflowReportsForCompAdmins']),
    getWorkflowPerms(workflow) {
      let perms = [];
      if (workflow.permissions && Array.isArray(workflow.permissions)) {
        perms = workflow.permissions;
      } else if (workflow.permissions) {
        perms.push(workflow.permissions);
      }
      return perms;
    },
    fetchWorkflowReports() {
      const roles = getUserRoles();
      if (roles.includes('ROLE_LDS_SUPPORT_ADMIN')) {
        return this.getWorkflowReports(this.company);
      }
      return this.getWorkflowReportsForCompAdmins();
    },
    getWorkflowFormulas(workflow) {
      let formula = [];
      if (workflow.formulas && Array.isArray(workflow.formulas)) {
        formula = workflow.formulas;
      } else if (workflow.formulas) {
        formula.push(workflow.formulas);
      }
      return formula;
    },
    getWorkflowParams(paramObj) {
      let params = [];
      if (paramObj.paramSets && Array.isArray(paramObj.paramSets)) {
        params = paramObj.paramSets;
      } else if (paramObj.paramSets) {
        params.push(paramObj.paramSets);
      }
      return params;
    },
    formatTime(timestamp) {
      if (timestamp.length > 10) {
        timestamp = timestamp.slice(0, 10);
      }
      return moment.unix(timestamp).tz('America/Chicago').format('MM/DD/YYYY');
    },
    currentTime() {
      return moment().tz('America/Chicago').format('MMM DD, YYYY');
    },
    removeNotification() {
      this.showErrorDialog = false;
    },
  },

};
</script>

<style lang="scss" scoped>
@import '@mds/typography';
@import '@mds/constants';

.component-title {
  @include mds-level-1-heading($bold: false);
  color: #162b3d;
  text-align: center;
  margin: auto;
  padding: 10px;
  width: 100%;
}

.header3 {
  @include mds-level-3-heading($bold: false);
  color: #162b3d;
  text-align: center;
  margin: auto;
  padding: 10px;
  width: 100%;
}

.header4 {
  @include mds-level-4-heading();
  color: #162b3d;
  text-align: center;
  margin: auto;
  padding: 10px;
  width: 100%;
}

.border {
  border-bottom: solid 1px #e5e5e5;
}

</style>

<template>
  <div>
    <div>
      <div class="custom-header-ent border-bottom-solid break-word">
        {{ $t('labels.dataAlert.sources') }}
      </div>
    </div>
    <div
      class="mds-search-field "
      role="search"
    >
      <input
        id="feedEntitlementSearchField"
        v-model="searchFilter"
        type="text"
        class="mds-search-field__input"
        aria-label="Search"
        :placeholder="$t('labels.dataAlert.sourceSearch')"
      >
      <svg
        class="mds-icon mds-search-field__search-icon"
        aria-hidden="true"
      >
        <use xlink:href="#search--s" />
      </svg>
      <span class="mds-search-field__input-focus-outline" />
    </div>
    <div class="over-flow break-word">
      <ul class="mds-list-group">
        <li
          v-for="(source, sourceIndex) in activeDatasourceList"
          :key="sourceIndex"
          class="mds-list-group__item"
        >
          <a
            class="mds-list-group__link"
            @click="getSourceName(source)"
          >
            <span class="mds-list-group__item-text custom-width-ent">
              {{ source.text }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import EventBus from '../../main';

export default {
  name: 'DataSource',
  props: {
    datasourceList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchFilter: '',
    };
  },
  computed: {
    activeDatasourceList() {
      return this.searchFilter === ''
        ? this.datasourceList
        : this.datasourceList.filter(
          item => item.text.toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1,
        );
    },
  },
  methods: {
    getSourceName(name) {
      EventBus.$emit('showFeeds', name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';

  .custom-header-ent {
    @include mds-level-3-heading($bold: false);
    font-weight: 300;
    margin-bottom: 10px;
    margin-top: 20px;
    color: #162b3d;
  }
</style>


import { EnumBase, EnumItem } from './enums.es6';
import Arrays from './arrays.es6';

/**
 * Link types, linking bubbles together: SUCCESS, FAILURE, QA_FAILURE.
 * @enum {EnumItem}
 */
const LinkType = EnumBase.finalize(/** @lends {LinkType} */ {

  /** Successful completion. */
  SUCCESS: new EnumItem('SUCCESS', 'ok'),

  /** Any kind of failure, including QA check failures. */
  FAILURE: new EnumItem('FAILURE', 'fail'),

  /** QA check failures. */
  QA_FAILURE: new EnumItem('QA_FAILURE', 'qa_fail'),

}, 'LinkType');

/**
 * Validates an argument to be an array (or array-like object) of LinkType.
 * If valid, this method returns that argument. Otherwise it throws.
 * @param {LinkType[]} arg Argument to be validated.
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {LinkType[]} Always returns `arg`.
 * @throws {TypeError} If `arg` is not an array-like object or contains anything other than
 *         LinkType objects.
 */
export function requireLinkTypes(arg, argName) {
  const bad = Arrays.indexOfInvalid(arg, LinkType.isEnumOf);
  if (bad >= 0) {
    throw new TypeError(`${argName}[${bad}]: LinkType`);
  }
  return arg;
}

export default LinkType;


const FEED_CREATE = 'FEED_CREATE';
const FEED_ENTITLEMENTS_EDIT = 'FEED_ENTITLEMENTS_EDIT';

/**
 * @namespace
 * @alias MpUserFeatureNames
 */
const MpUserFeatureNames = Object.freeze( /** @lends {MpUserFeatureNames} */ {
    FEED_CREATE,
    FEED_ENTITLEMENTS_EDIT
});

export default MpUserFeatureNames;

<template>
  <div>
    <div class="breadcrumb-module-container">
      <breadcrumb
        class="breadcrumb-module-container"
        :from-path-name="fromPathName"
        :to-path-name="toPathName"
      />
    </div>
    <div
      class="mds-module-container"
      aria-labelledby="header-id-1"
    >
      <section>
        <slot />
      </section>
    </div>
  </div>
</template>

<script>
import Breadcrumb from './Breadcrumb';

export default {
  name: 'SubpageLayout',
  components: { Breadcrumb },
  props: {
    fromPathName: { type: String, default: '' },
    toPathName: { type: String, default: '' },
  },
};
</script>

<style scoped>

</style>

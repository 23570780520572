import Strings, { isString, requireNonEmptyString } from '../../strings.es6';
import Objects from '../../objects.es6';
import Arrays from '../../arrays.es6';
import Type from './type.es6';


/**
 * @typedef Object MpField.ServerObject
 * @property {string} fieldName Field name.
 * @property {string} type Field type.
 * @property {string} fieldDataType Field data-type (storage DB).
 */


/**
 * A Marketplace field.  Instances are immutable.
 */
export default class MpField {
  // static Type = Type;

  /**
     *
     * @param {string} name Field name.
     * @param {string} dataType Field data-type (storage DB).
     * @param {Type} type
     */
  constructor(name, dataType, type) {
    this._name = requireNonEmptyString(name, 'name');
    this._dt = requireNonEmptyString(dataType, 'dataType');
    this._type = Type.valueOf(type);

    Object.freeze(this);
  }

  /** @returns {string} Field name. */
  name() { return this._name; }

  /** @returns {string} Field data-type (storage DB). */
  dataType() { return this._dt; }

  /** @returns {Type} Field type: key, value, metadata. */
  type() { return this._type; }

  /**
     * Returns whether *that* is a MpField object that represents
     * the same field.
     * @param that {*}
     * @returns {boolean} Returns true if *that* instance represents
     *                    the same field as this instance.
     */
  equals(that) {
    return (
      isMpField(that)
            && this._type === that._type
            && Strings.equalsIgnoreCase(this._name, that._name)
            && Strings.equalsIgnoreCase(this._dt, that._dt)
    );
  }

  /**
     * Returns whether `f1` and `f2` are two instances of MpField
     * that represent the same field.  Returns *false* if either
     * object is not an instance of MpField.
     *
     * @param f1 {*}
     * @param f2 {*}
     * @returns {boolean}
     */
  static areEqual(f1, f2) {
    return (isMpField(f1) && f1.equals(f2));
  }


  /**
     * Returns whether `that` is can handle data contained in this field.  For that field to support this field,
     * it must:
     *
     * 1. match this field's name exactly (case-insensitive); </li
     * 2. match this field's type;
     * 3. have a data-type that handles all values supported by this field's data-type.
     *
     * For example, a field defined with data-type "Number" (ie INTEGER) can migrate to another field
     * defined with data-type "Number(20,8)" (ie DOUBLE), because DOUBLE supports all INTEGERs.
     *
     * @param {MpField} that Field to compare for compatibility with `this` field.
     * @returns {boolean} Whether `that` is compatible with this field.
     */
  canMigrateTo(that) {
    return (
      isMpField(that)
            && this._type === that._type
            && Strings.equalsIgnoreCase(this._name, that._name)
            && (Strings.equalsIgnoreCase(this._dt, that._dt)
                || (this._dt.toLowerCase() === 'number'
                    && that._dt.match(new RegExp('^Number *\\([0-9, ]*\\)$', 'i'))
                )
            )
    );
  }

  /**
     * Returns a list of MpField objects created from the given server-provided array.
     * The returned list does not contain fields for which the type is unrecognized;
     * only fields with recognized types are returned.
     *
     * @param {Array.<MpField.ServerObject>} fields
     * @returns {MpField[]} A list of MpField objects, sorted by name.
     */
  static fromServerArray(fields) {
    Arrays.requireValid(
      fields,
      /** @param {MpField.ServerObject} f */
      f => (
        Objects.is(f)
                && isString(f.fieldName)
                && isString(f.type)
                && isString(f.fieldDataType)
      ),
      'fields',
    );

    const rv = fields.map(field => new MpField(
      field.fieldName,
      field.fieldDataType,
      Type.valueOf(field.type.toLowerCase(), Type.UNKNOWN),
    ));

    rv.sort(MpField.compare);

    return rv;
  }

  /**
     * Compares two MpField objects for the purpose of sorting them by name.
     * This method compares field names; it recognizes numeric sequence found at the
     * end of field names and compares those accordingly (as numeric values).
     *
     * Objects that are not MpField are pushed at the end of the sort-order.
     *
     * @param {(MpField|*)} f1
     * @param {(MpField|*)} f2
     * @returns {number}
     * @method
     */
  static compare() {
    return Objects.newNullableComparator(MpField, (f1, f2) => Strings.compareSequenced(f1._name, f2._name));
  }
}

/**
 * @param {(MpField|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is an instance of MpField.
 */
export function isMpField(arg) {
  return (arg instanceof MpField);
}

export const MpFieldType = Type;

<template>
  <div class="bb-config-new">
    <div
      v-if="loader"
      id="mds-overlay"
      class="mds-overlay"
    >
      <mds-loader class="loader-vertically-center" />
    </div>
    <div class="mds-header border-bottom-solid">
      <h1 class="mds-header__title component-title">
        {{ isEditRoute ? $t('labels.bbLoader.editConfig') : $t('labels.bbLoader.newConfig') }}
      </h1>
    </div>
    <form
      class="mds-form mds-form--medium-layout custom_form"
      method
      action
    >
      <fieldset class="mds-form__fieldset">
        <legend class="mds-form__legend" />
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="configName"
          >
            {{ $t('labels.bbLoader.configName') }}
            <abbr class="mds-form__label-required-indicator">*</abbr>
          </label>
          <input
            id="configName"
            v-model="config.name"
            class="mds-form__input custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
            type="text"
            maxlength="100"
            :disabled="isEditRoute"
          >
          <span
            v-if="!isValidConfigName"
            id="configNameError"
            class="mds-form__field-error"
            role="alert"
          >
            <span class="mds-form__field-error-text">{{ $t('labels.common.propNameCheck') }}</span>
          </span>
        </div>
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="yellowkey"
          >
            {{ $t('labels.bbLoader.yellowKey') }}
            <abbr class="mds-form__label-required-indicator">*</abbr>
          </label>
          <div class="mds-form__select">
            <select
              id="yellowkey"
              v-model="config.yellowkey"
              :disabled="isEditRoute"
              class="mds-form__select-input custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
              @change="showYellowKeyDetails($event.target.value)"
            >
              <option
                v-for="(key, index) in yellowKeyList"
                :key="index"
                class="mds-form__select-option"
                :value="key.name"
              >
                {{ key.name }}
              </option>
            </select>
            <div class="mds-form__select-visual-wrap" />
            <span class="mds-form__select-open-indicator">
              <svg
                class="mds-icon mds-form__select-open-icon"
                aria-hidden="true"
              >
                <use xlink:href="#caret-down--s" />
              </svg>
            </span>
          </div>
          <span id="yk-trigger">
            <mds-icon name="info-circle--s" />
          </span>
          <mds-popover
            :title="$t('labels.bbLoader.yellowKey')"
            triggered-by="yk-trigger"
            position="left-bottom"
          >
            <div>
              <div>
                <strong>{{ $t('labels.common.name') + ': ' }}</strong>
                <span>{{ yellowKeyDetails.name }}</span>
              </div>
              <div>
                <strong>{{ $t('labels.common.value') + ': ' }}</strong>
                <span>{{ yellowKeyDetails.value }}</span>
              </div>
              <div>
                <strong>{{ $t('labels.bbLoader.ykValueFields') + ': ' }}</strong>
                <ul>
                  <li
                    v-for="field in yellowKeyDetails.valueFields"
                    :key="field"
                  >
                    {{ field }}
                  </li>
                </ul>
              </div>
              <div>
                <strong>{{ $t('labels.bbLoader.ykMetaFields') + ': ' }}</strong>
                <ul>
                  <li
                    v-for="field in yellowKeyDetails.metaFields"
                    :key="field"
                  >
                    {{ field }}
                  </li>
                </ul>
              </div>
            </div>
          </mds-popover>
        </div>

        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="account"
          >{{
            $t('labels.bbLoader.accountName')
          }}</label>
          <div class="mds-form__select">
            <select
              id="bbAccount"
              v-model="config.account_name"
              class="mds-form__select-input custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
            >
              <option
                v-for="(key, index) in accountList"
                :key="index"
                class="mds-form__select-option"
                :value="key.name"
              >
                {{ key.name }}
              </option>
            </select>
            <div class="mds-form__select-visual-wrap" />
            <span class="mds-form__select-open-indicator">
              <svg
                class="mds-icon mds-form__select-open-icon"
                aria-hidden="true"
              >
                <use xlink:href="#caret-down--s" />
              </svg>
            </span>
          </div>
        </div>
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="feedName"
          >
            {{ $t('labels.feed.feedName') }}
            <abbr class="mds-form__label-required-indicator">*</abbr>
          </label>
          <div class="mds-form__select">
            <select
              id="feedName"
              v-model="config.feed_name"
              :disabled="isEditRoute"
              class="mds-form__select-input custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
            >
              <option
                v-for="(feed, index) in feedList"
                :key="index"
                class="mds-form__select-option"
                :value="feed"
              >
                {{ feed }}
              </option>
            </select>
            <div class="mds-form__select-visual-wrap" />
            <span class="mds-form__select-open-indicator">
              <svg
                class="mds-icon mds-form__select-open-icon"
                aria-hidden="true"
              >
                <use xlink:href="#caret-down--s" />
              </svg>
            </span>
          </div>
        </div>

        <div
          v-if="isEditRoute"
          class="mds-form__field-group"
        >
          <label
            class="mds-form__label"
            for="configStatus"
          >
            {{ $t('labels.common.status') }}
            <abbr class="mds-form__label-required-indicator">*</abbr>
          </label>
          <div class="mds-form__select">
            <select
              id="configStatus"
              v-model="config.status"
              class="mds-form__select-input custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
              :disabled="config.status == 'UNDER_REVIEW' && !isAdminUser"
            >
              <option
                class="mds-form__select-option"
                value="ACTIVE"
              >
                {{ $t('labels.common.active') }}
              </option>
              <option
                class="mds-form__select-option"
                value="INACTIVE"
              >
                {{ $t('labels.common.inActive') }}
              </option>
              <option
                v-if="config.status == 'UNDER_REVIEW' || isAdminUser"
                class="mds-form__select-option"
                value="UNDER_REVIEW"
              >
                {{ $t('labels.common.underReview') }}
              </option>
            </select>
            <div class="mds-form__select-visual-wrap" />
            <span class="mds-form__select-open-indicator">
              <svg
                class="mds-icon mds-form__select-open-icon"
                aria-hidden="true"
              >
                <use xlink:href="#caret-down--s" />
              </svg>
            </span>
          </div>
        </div>

        <div class="mds-header mds-header--secondary mds-header--border-bottom">
          <div class="mds-form__field-group">
            <label
              class="mds-form__label"
              for="description"
            >{{
              $t('labels.common.description')
            }}</label>
            <textarea
              id="description"
              v-model="config.desc"
              class="mds-form__textarea custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
            />
          </div>
        </div>
        <div class="mds-header mds-header--secondary mds-header--border-bottom">
          <div class="mds-form__field-group">
            <mds-layout-grid>
              <mds-row>
                <mds-col
                  :cols="12"
                  class="padding_bottom_custom"
                >
                  <h3 class="mds-header__title group-save-button">
                    {{ $t('labels.bbLoader.instruments') }}
                  </h3>
                  <ul class="mds-list-group instrument-list">
                    <li
                      v-for="(instrument, index) in instrumentList"
                      :key="'instrumentRow_' + index"
                      class="mds-list-group__item component-title"
                    >
                      <div>
                        <input
                          v-model="instrument.name"
                          class="mds-form__input val_pad custom_key_width custom_key_width-at-1200 custom_value_width-at-540"
                          type="text"
                          name="0[name]"
                          :placeholder="$t('labels.bbLoader.instrumentName')"
                          value
                        >
                        <input
                          v-model="instrument.curve"
                          class="mds-form__input val_pad custom_key_width custom_key_width-at-1200 custom_value_width-at-540"
                          type="text"
                          name="0[name]"
                          :placeholder="$t('labels.bbLoader.curveName')"
                          value
                        >
                        <input
                          v-model="instrument.tenor"
                          class="mds-form__input val_pad custom_key_width custom_key_width-at-1200 custom_value_width-at-540"
                          type="text"
                          name="0[name]"
                          :placeholder="$t('labels.bbLoader.tenor')"
                          value
                        >
                        <div class="mds-form__select">
                          <select
                            v-model="instrument.yellowkey"
                            class="mds-form__select-input"
                          >
                            <option
                              v-for="(ykType, idx) in yellowKeyTypes"
                              :key="'ykType_' + idx"
                              class="mds-form__select-option"
                              :value="ykType"
                            >
                              {{ ykType }}
                            </option>
                          </select>
                          <div class="mds-form__select-visual-wrap" />
                          <span class="mds-form__select-open-indicator">
                            <svg
                              class="mds-icon mds-form__select-open-icon"
                              aria-hidden="true"
                            >
                              <use xlink:href="#caret-down--s" />
                            </svg>
                          </span>
                        </div>
                        <a
                          class="custom-key-value delete-instrument"
                          @click="deleteInstrumentRow(index)"
                        >
                          <span class="ic-remove-sm">x</span>
                        </a>
                      </div>
                      <div
                        v-if="instrument.error != ''"
                        class="validation-error"
                      >
                        {{ instrument.error }}
                      </div>
                    </li>
                  </ul>
                  <a
                    id="anchorAddInstrument"
                    class="custom-key-value"
                    @click="addInstrumentRow()"
                  >
                    <span class="ic-add-sm" />
                    {{ $t('labels.bbLoader.addinstrument') }}
                  </a>
                  <a
                    id="anchorAddInstruments"
                    class="custom-key-value"
                    @click="toggleModal = !toggleModal"
                  >
                    <span class="ic-add-sm" />
                    {{ $t('labels.bbLoader.uploadinstruments') }}
                  </a>
                </mds-col>
              </mds-row>
            </mds-layout-grid>
          </div>
        </div>
      </fieldset>
      <div class="demo-button-container">
        <button
          id="btnBack"
          class="mds-button val_pad mds-button--primary font-size-small new-feed-save-button"
          type="button"
          @click="goBack"
        >
          {{ $t('labels.group.groupBackButton') }}
        </button>
        <button
          id="btnSave"
          class="mds-button mds-button--primary font-size-small new-feed-save-button"
          type="button"
          @click="formSubmit"
        >
          {{ $t('labels.bbLoader.saveConfig') }}
        </button>
        <span
          v-if="formError"
          id="formError"
          class="mds-form__field-error"
          role="alert"
        >
          <span class="mds-form__field-error-text">{{ $t('labels.common.invalidForm') }}</span>
        </span>
        <span
          v-if="errorMessage != '' || errorText != ''"
          id="formError"
          class="mds-form__field-error"
          role="alert"
        >
          <span class="mds-form__field-error-text">{{ errorText || errorMessage }}</span>
        </span>
      </div>
    </form>
    <div id="csvImportModal">
      <mds-modal
        v-model="toggleModal"
        :title="$t('labels.bbLoader.uploadinstruments')"
        class="custom-z-index"
      >
        <div>
          <instrument-import
            @cancel="toggleModal = !toggleModal"
            @save="addMultipleInstruments"
          />
        </div>
      </mds-modal>
    </div>
    <div
      id="mds-notification"
      class="mds-notification mds-notification--top-center mds-notification--below-masthead mds-notification--active custom-top"
    >
      <mds-notification-group>
        <mds-notification
          ref="successDialog"
          role="alertdialog"
          status="success"
          size="small"
          dismiss-delay="45000"
          dismissible="true"
          removable="false"
          class="z-index-9999"
        >
          {{ $t('labels.feed.successDialogMsg') }}
        </mds-notification>
        <mds-notification
          ref="errorDialog"
          :list="JSON.stringify(errorText)"
          role="alertdialog"
          status="error"
          size="small"
          dismiss-delay="35000"
          dismissible="true"
          removable="false"
          class="z-index-9999"
        >
          {{ $t('labels.feed.errorDialogMsg') }}
        </mds-notification>
      </mds-notification-group>
    </div>
  </div>
</template>
<script>
import MdsModal from '@mds/modal';
import { mapGetters, mapActions, mapState } from 'vuex';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import InstrumentImport from './InstrumentImport';

import { checkSpecialChars, checkAlphaNumeric, checkAlphaNumericWithExceptions } from '../../utils/stringUtils';
import { getUserRoles } from '../../utils/authService';

export default {
  name: 'BBNewConfig',
  components: {
    MdsModal, InstrumentImport, MdsLayoutGrid, MdsRow, MdsCol,
  },
  data() {
    return {
      loader: true,
      config: {
        name: '',
        yellowkey: '',
        account_name: '',
        feed_name: '',
        status: 'ACTIVE',
        desc: '',
        instruments: [{ name: '', curve: '', tenor: '' }],
      },
      instrumentList: [],
      yellowKeyTypes: [],
      formError: false,
      ykDetailsToggle: true,
      ykDetails: {},
      errorText: '',
      toggleModal: false,
    };
  },
  computed: {
    ...mapState('bloombergModule', ['errorMessage', 'yellowKeyMappings']),
    ...mapGetters('bloombergModule', ['yellowKeyList', 'accountList', 'configDetails']),
    ...mapGetters({
      userFeeds: 'feedModule/getFeedList',
    }),
    isEditRoute() {
      return this.$route.name === 'BBEditConfig';
    },
    isAdminUser() {
      return (
        getUserRoles().includes('ROLE_LDS_ADMIN') || getUserRoles().includes('ROLE_LDS_SUPPORT')
      );
    },
    feedList() {
      return this.userFeeds
        ? this.userFeeds
          .filter(feed => feed.name.toLowerCase().includes('bloomberg'))
          .map(feed => feed.name)
        : [];
    },
    isValidConfigName() {
      return !this.config.name || this.config.name === '' || checkSpecialChars(this.config.name);
    },
    isValidFeedName() {
      return checkSpecialChars(this.config.feed_name) || this.config.feed_name === '';
    },
    yellowKeyDetails() {
      return this.ykDetails;
    },
  },
  created() {
    this.errorText = '';
    this.formError = false;
    const actionArray = [];
    if (typeof this.yellowKeyMappings === 'undefined' || this.yellowKeyMappings.length <= 0) {
      actionArray.push(this.getYellowKeyMappings('default'));
    }
    if (typeof this.yellowKeyList === 'undefined' || this.yellowKeyList.length <= 0) {
      actionArray.push(this.getYellowKeyList());
    }
    if (typeof this.accountList === 'undefined' || this.accountList.length <= 0) {
      actionArray.push(this.getAccountList());
    }

    if (typeof this.userFeeds === 'undefined' || this.userFeeds.length <= 0) {
      actionArray.push(this.getUserFeeds());
    }

    Promise.all(actionArray).finally(() => {
      if (this.isEditRoute) {
        this.initForm();
      } else {
        this.loader = false;
        this.instrumentList.push({
          name: '',
          curve: '',
          tenor: '',
          yellowkey: '',
          error: '',
        });
      }
      this.yellowKeyTypes = this.yellowKeyMappings[0].types;
    });
  },
  methods: {
    ...mapActions('bloombergModule', [
      'createNewConfig',
      'getYellowKeyList',
      'getYellowKeyDetails',
      'getAccountList',
      'getConfigDetails',
      'editConfig',
      'getYellowKeyMappings',
    ]),
    ...mapActions('feedModule', ['getUserFeeds']),
    showYellowKeyDetails(name) {
      this.getYellowKeyDetails(name).then((resp) => {
        this.ykDetails = resp;
      });
    },
    addInstrumentRow(index) {
      this.instrumentList.push({
        name: '',
        curve: '',
        tenor: '',
        yellowkey: '',
      });
    },
    addMultipleInstruments(instruments) {
      instruments.map((item) => {
        if (!this.instrumentList.some(obj => obj.name === item.name)) {
          this.instrumentList.push(item);
        }
        return item;
      });
      this.toggleModal = !this.toggleModal;
    },
    deleteInstrumentRow(index) {
      this.instrumentList.splice(index, 1);
    },
    initForm() {
      const { configName } = this.$route.params;

      if (configName.toLowerCase() === this.configDetails.name.toLowerCase()) {
        this.config = this.configDetails;
        this.instrumentList.push(...this.config.instruments);

        this.loader = false;
        this.showYellowKeyDetails(this.config.yellowkey);
      } else {
        this.getConfigDetails(configName)
          .then((resp) => {
            this.config = resp;
            this.loader = false;
            this.currentStatus = 'SUCCESS';
            this.showYellowKeyDetails(this.config.yellowkey);

            this.instrumentList.push(...this.config.instruments);
          })
          .catch((error) => {
            this.loader = false;
            this.currentStatus = 'ERROR';
            this.errorText = error.response.data.Message || '';
          });
      }
    },
    validateForm() {
      if (
        !(this.isValidConfigName && this.isValidFeedName)
        || !this.validateInstrumentMap()
        || this.config.name.length <= 0
        || this.config.yellowkey.length <= 0
        || this.config.feed_name.length <= 0
      ) {
        this.formError = true;
        return false;
      }

      this.formError = false;
      return true;
    },
    validateInstrumentMap() {
      const tempList = [];
      this.instrumentList.map((item) => {
        const flagA = item.name.trim() === ''
          || item.curve.trim() === ''
          || item.yellowkey.trim() === ''
          || !this.yellowKeyTypes.includes(item.yellowkey.trim());

        const flagB = !(checkAlphaNumericWithExceptions(item.name) && checkAlphaNumeric(item.curve));
        const flagC = item.tenor.trim() !== '' && !checkAlphaNumeric(item.tenor.trim());

        if (flagA || flagB || flagC) {
          item.error = 'Instrument name, curve & yellowkey are required and should only contain alphanumeric values.';
        } else {
          item.error = '';
        }

        tempList.push(item);
        return item;
      });
      this.instrumentList = tempList;

      if (this.instrumentList.some(obj => obj.error !== '')) {
        return false;
      }
      return true;
    },
    formSubmit() {
      if (!this.validateForm()) {
        return;
      }

      this.loader = true;
      this.errorText = '';
      this.formError = false;

      if (this.isEditRoute) {
        this.config.instrumentsAdded = this.instrumentList;

        this.editConfig(this.config)
          .then((response) => {
            if (response.StatusCode === 200) {
              this.goToConfigDetails(this.config.name);
            } else {
              this.loader = false;
            }
          })
          .catch((error) => {
            this.loader = false;
            this.errorText = error.response.data.Message || '';
          });

        this.loader = false;
      } else {
        this.config.instrumentsAdded = this.instrumentList;

        this.createNewConfig(this.config)
          .then((response) => {
            if (response.StatusCode === 200) {
              this.goToConfigDetails(this.config.name);
            } else {
              this.loader = false;
            }
          })
          .catch((error) => {
            this.loader = false;
            this.errorText = error.response.data.Message || '';
          });
      }
    },
    goToConfigDetails(cName) {
      this.$router.push({
        path: `/bb/config/${cName}`,
        params: {
          configName: cName,
        },
      });
      this.loader = false;
    },
    goBack() {
      this.$router.push({
        path: '/bb',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/new_feed';

.custom_key_width {
  width: 200px;
}

.validation-error {
  color: red;
  font-size: medium;
}

@media (max-width: 1200px) {
  .custom_key_width-at-1200 {
    width: 160px;
  }
}

@media (max-width: 540px) {
  .custom_value_width-at-540 {
    width: 100px;
  }
}
.custom-top{
  top:50px;
}
.custom-z-index{
  z-index: 810;
}
</style>

<template>
  <div
    id="mds-modal-wrapper"
    class="mds-modal__wrapper"
  >
    <section
      role="dialog"
      class="mds-modal__container"
      aria-hidden="false"
    >
      <div class="mds-modal__inner">
        <header class="mds-modal__header">
          <h1
            id="save-group-header"
            class="mds-modal__title"
          >
            {{ $t('labels.group.saveGroupHeader') }}
          </h1>
        </header>
        <div class="mds-modal__content">
          <template v-if="groupSaveSuccess">
            <p> {{ $t('labels.group.saveGroupSuccess') }}</p>
          </template>
          <template v-else>
            <p>{{ $t('labels.group.saveGroupError') }}</p>
          </template>
          <table class="mds-data-table mds-data-table--large">
            <tbody class="mds-data-table__body">
              <tr
                v-for="(error,index) in errorList"
                :key="'error' + index"
                class="mds-data-table__row row-message-error"
              >
                <td
                  class="mds-data-table__cell"
                  style="width:3%"
                >
                  {{ error.groupName }}
                </td>
                <td class="mds-data-table__cell">
                  {{ error.message }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="mds-modal__actions"
          style="padding-top:10px;margin-right:10px"
        >
          <mds-button
            id="close-notif-btn"
            type="button"
            @click="closeNotification()"
          >
            {{ $t('labels.group.closeButton') }}
          </mds-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'GroupUpdateResponse',
  props: {
    groupSaveSuccess: { type: Boolean, default: true },
    errorList: { type: Array, default: null },
  },
  methods: {
    closeNotification() {
      this.$parent.closeNotification();
    },
  },
};
</script>>


<style scoped>
.save-group-row.ADD{
  background-color:#d0e9c6;
}
.save-group-row.REMOVE{
  background-color:#ebcccc;
}
.row-message-error{
  background-color:#ebcccc;
}
</style>

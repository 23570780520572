/* eslint-disable */
exports.pythonScriptBasket = params => `try:
    import io
    import requests
    from requests.auth import HTTPBasicAuth
    import json
    import pandas as pd
    import time
except ModuleNotFoundError:
    print("ModuleNotFoundError: You do not have all of the required python packages installed. Please install the required packages by uncommenting and executing the first code block above.")


username = ""
password = ""

if username == "":
    print('Please set username and password')
    exit()

from_date = "${params.startDate}"
to_date = "${params.endDate}"
time_zone = "${params.timeZone}"

symbols_list = ${JSON.stringify(params.symbolsList)}

df = pd.DataFrame

exception = False
for symbol_dict in symbols_list:
    try:
        description_url = 'https://mp.morningstarcommodity.com/lds/feeds/{}/ts?{}&cols={}&fromDateTime={}&toDateTime={}&timezone={}'.format(symbol_dict['feed'],symbol_dict['keys'],symbol_dict['columns'],from_date,to_date,time_zone)
        r = requests.get(description_url, auth=HTTPBasicAuth(username, password))
        data = r.text
        temp = pd.read_csv(io.StringIO(data))
        if df.empty:
            df = temp
        else:
            df=pd.merge(df,temp, on='Date', how='outer')
        if r.headers['X-RateLimit-Remaining'] == 1:
            time.sleep(60)
    except:
              exception = True


if exception:
    print("Contract " + symbol_dict +" for the following settlement dates cannot be found:")
else:
    print(df) `;

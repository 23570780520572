import { isVoid } from './objects.es6';
import { isInteger, isIntegerString, requireInteger } from './numbers.es6';
import Strings, { isString, requireString, isNonEmptyString } from './strings.es6';
import Arrays from './arrays.es6';

/* *************************************************************
 * Interfaces
 * ************************************************************* */

/**
 * @typedef Object ServerError.MpPayload
 * @property {(int|string)} status HTTP status code.
 * @property {string} msg Error message
 * @property {string} url LDS URL that responded.
 * @property {string} method HTTP method, or other named method
 */

/**
 * Parse MP server errors returned in HTML format,
 * and returns an Array-of-String.  If not HTML, or
 * HTML is unrecognized format, this method returns
 * an empty array.
 *
 * @param {string} msg
 * @return {string[]}
 */
function _parseServerErrorsHtml(msg) {
  const errors = [];
  const regexBodyContent = new RegExp('<body>(.*?)</body>', 'g');
  let match = regexBodyContent.exec(msg);

  while (match !== null) {
    // Only keep what's inside <body> and </body>.
    let bodyContent = match[1];

    // Replace <p> with "\n\n".
    bodyContent = bodyContent.replace(new RegExp('<\\s*p\\s*>', 'g'), '\n\n');

    // Replace any permutation of <br> (<br/>, <br />, </br>) with "\n".
    bodyContent = bodyContent.replace(new RegExp('</?\\s*br\\s*/?>', 'g'), '\n');

    // Ignore all </p>
    bodyContent = bodyContent.replace(new RegExp('</\\s*p\\s*>', 'g'), '');

    // Remove all remaining HTML elements
    bodyContent = bodyContent.replace(new RegExp('<[^>]*>', 'g'), ' ');

    errors.push(bodyContent);
    match = regexBodyContent.exec(msg);
  }

  return errors;
}

/**
 * Parse MP server errors returned in JSON format,
 * and returns an Array-of-String.  If not JSON, or
 * JSON is unrecognized format, this method returns
 * an array that contains the raw `msg`.
 *
 * @param {string} msg
 * @return {string[]}
 */
function _parseServerErrorsJson(msg) {
  /** @type {string[]} */
  const errors = [];

  /** @type {?{errorMessage: string, hasOwnProperty: function}} */
  let json = null;

  try {
    json = JSON.parse(msg);
  } catch (ignored) {
  }

  if (json === null
        || !Object.hasOwnProperty.call(json, 'errorMessage')) {
    // In case `msg` is not actual JSON or unexpected JSON,
    // return raw error message.
    errors.push(msg);
  } else {
    let msgText = json.errorMessage;
    const idx = msgText.indexOf(' : ');
    if (idx >= 0) {
      msgText = msgText.substring(0, idx);
    }
    errors.push(msgText);
  }
  return errors;
}

/**
 * Parse MP server errors returned in XML format,
 * and returns an Array-of-String.  If not XML, or
 * XML is unrecognized format, this method returns
 * and empty array.
 *
 * @param {string} msg
 * @return {string[]}
 */
function _parseServerErrorsXml(msg) {
  // Error messages can contain '\n' and '\r' characters
  // which RegExp can't handle.  We work around this
  // limitation by doing the work manually.

  const errors = [];
  const tagName = 'errorMessage';
  const startTag = `<${ tagName }>`;
  const endTag = `</${ tagName }>`;
  const startTagLen = startTag.length;
  const endTagLen = endTag.length;

  let endIdx = msg.indexOf(endTag);
  while (endIdx >= 0) {
    const startIdx = msg.lastIndexOf(startTag, endIdx);
    if (startIdx < 0) {
      // end tag without starting tag? stop parsing now.
      return errors;
    }
    errors.push(Strings.xmlDecode(msg.substring(startIdx + startTagLen, endIdx)).trim());
    endIdx = msg.indexOf(endTag, endIdx + endTagLen);
  }
  return errors;
}


export default class ServerError {
  /**
     * @param {int} code
     * @param {string[]} errors
     * @param {string} [url]
     * @param {string} [method]
     */
  constructor(code, errors, url, method) {
    requireInteger(code, 'code');

    if (!Arrays.isArrayOf(errors, 'string')
            || errors.length === 0) { throw new TypeError('errors: String[], non-empty'); }

    if (!isNonEmptyString(url)) url = 'N/A';
    if (!isNonEmptyString(method)) method = 'N/A';

    this._code = code;
    this._errors = Object.freeze(errors);
    this._url = url;
    this._method = method;

    Object.freeze(this);
  }

  /**
     * Creates a ServerError object based on MP error.
     * @param payload {ServerError.MpPayload}
     * @returns {ServerError}
     */
  static fromMpErrorResponse(payload) {
    const { msg } = payload;
    let errors;

    if (/^\s*<html>/i.test(msg)) {
      errors = _parseServerErrorsHtml(msg);
    } else if (/^\s*{.*}\s*$/.test(msg)) {
      errors = _parseServerErrorsJson(msg);
    } else {
      errors = _parseServerErrorsXml(msg);
    }

    // If errors are not formatted as expected, then add raw payload.
    if (errors.length === 0) {
      errors.push(msg);
    }

    return new ServerError(
      parseInt(payload.status, 10),
      errors,
      payload.url,
      payload.method,
    );
  }

  /**
     * @param {(ServerError.MpPayload|*)} arg Argument to validate.
     * @returns {boolean} Whether `arg` has the structure of an error produced from LDS/Marketplace.
     * @see ServerError.MpPayload
     */
  static isMpErrorResponse(arg) {
    return (!isVoid(arg)
                && isString(arg.msg)
                && isString(arg.method)
                && isString(arg.url)
                && (isInteger(arg.status) || isIntegerString(arg.status)));
  }

  /**
     * Returns whether an argument represents an error from Marketplace server.
     * Errors from MP server are reported as `ServerError` or
     * HTTP status codes (integer).
     * @param {*} arg - Argument to validate.
     * @returns {boolean} Whether `arg` is an HTTP error or MP error.
     */
  static isError(arg) {
    return (isInteger(arg)
                || arg instanceof ServerError);
  }

  /**
     * Returns the text to display to users when an HTTP error
     * or MP server error occurs.
     * @param {(int|ServerError)} error
     * @returns {string}
     * @throws TypeError if `error` is not an Integer or ServerError object.
     */
  static displayText(error) {
    if (isInteger(error)) {
      return `Communication error: ${ error}`;
    } if (error instanceof ServerError) {
      return error.displayText();
    }
    throw new TypeError('error: Integer or ServerError');
  }

  /** @returns {int} Error code, likely an HTTP status code. */
  code() { return this._code; }

  /** @returns {string[]} List of errors, at least one, safe to modify. */
  errors() { return this._errors.slice(0); }

  /** @returns {string} Request URL associated with the error(s). */
  url() { return this._url; }

  /** @returns {string} HTTP method used on the URL associated with the error(s). */
  method() { return this._method; }

  /**
     * @param {string} [delim="\n"] Delimiter used between errors.
     * @returns {string} Error(s) as a single string.
     */
  toString(delim) {
    return this._errors.join(
      (arguments.length < 1)
        ? '\n'
        : requireString(delim, 'delim'),
    );
  }

  /** @returns {string} Full error message. */
  displayText() {
    return [
      'Marketplace error:',
      `\nCode: ${ this._code.toString()}`,
      `URL: ${ this._url}`,
      '\nDetails:',
      this._errors.join('\n\n'),
    ].join('\n');
  }
}

/**
 * @param {(ServerError|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is an instance of ServerError.
 */
export function isServerError(arg) {
  return (arg instanceof ServerError);
}

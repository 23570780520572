<template>
  <div class="mcd-page-shell__content mds-page-shell__content">
    <mds-loader
      v-show="!authenticated"
      size="large"
    />
    <mds-layout-grid v-if="authenticated">
      <div class="border-bottom-solid">
        <mds-row>
          <mds-col :cols="8">
            <h1 class="mds-header__title component-title">
              <small>{{ $t('labels.auditLog.name') }}</small>
            </h1>
          </mds-col>
          <mds-col :cols="4">
            <mds-search-field
              v-model="searchFilter"
              placeholder="Search"
              label="Search"
              @keyup.prevent="filterKeywords($event)"
              @mds-search-field-input-cleared="clearSearchBar"
            />
          </mds-col>
        </mds-row>
      </div>
      <div class="custom-padding">
        <mds-row>
          <mds-col :cols="5">
            <mds-row>
              <mds-col
                :cols-at-l="3"
                :cols-at-m="4"
                :cols="12"
              >
                <label class="mds-form__label custom-margin">
                  {{ $t('labels.auditLog.logType') }}
                </label>
              </mds-col>
              <mds-col
                :cols-at-l="4"
                :cols-at-m="5"
                :pull="3"
                :cols="12"
              >
                <mds-combo-box
                  ref="logTypeComboRef"
                  :selected-items="selectedLogType"
                  label=""
                  placeholder="Select Log Type"
                  :data-set="logTypes"
                  @input="filterLogs"
                />
              </mds-col>
            </mds-row>
          </mds-col>
          <mds-col
            :cols="6"
            :pull="1"
          >
            <mds-row>
              <mds-col
                :cols-at-l="3"
                :cols-at-m="4"
                :cols="12"
              >
                <label class="mds-form__label custom-margin">
                  {{ $t('labels.auditLog.timeFrame') }}
                </label>
              </mds-col>
              <mds-col
                :cols-at-l="4"
                :cols-at-m="5"
                :pull="3"
                :cols="12"
              >
                <mds-combo-box
                  ref="timeFrameComboRef"
                  :selected-items="selectedTimeFrame"
                  label=""
                  placeholder="Select Time Frame"
                  :data-set="timeFrames"
                  @input="filterTime"
                />
              </mds-col>
            </mds-row>
          </mds-col>
        </mds-row>
        <div class="padding-top">
          <mds-row>
            <mds-col>
              <mds-loader v-show="loader" />
              <mds-data-table
                v-if="!loader"
                ref="logTable"
                name="test"
                size="medium"
                :fixed-first-col="respond"
                row-hover
                :header-configs="headers"
                :row-data="rowLogs"
                class="break-word"
                :pagination-config="paginationOpts"
                @mds-data-table-page-change="paginateTable"
                @mds-data-table-sort-change="onSort"
              />
              <p v-if="!loader && rowLogs.length === 0">
                No records found.
              </p>
              <template v-if="pagination">
                <mds-pagination
                  show-items-info
                  show-items-select
                  :total-items="paginationOpts.totalItems"
                  :page-sizes="paginationOpts.pageSizes"
                  :page="paginationOpts.page"
                  :page-size="paginationOpts.pageSize"
                  @mds-pagination-page-changed="paginateTable"
                />
              </template>
            </mds-col>
          </mds-row>
        </div>
      </div>
    </mds-layout-grid>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsLoader from '@mds/loader';
import MdsComboBox from '@mds/combo-box';
import { MdsDataTable } from '@mds/data-table-v3';
import { mapGetters, mapActions } from 'vuex';
import MdsSearchField from '@mds/search-field';
import MdsPagination from '@mds/pagination';
import LayoutDefault from '../Layouts/LayoutDefault.vue';
import { getUserRoles, getUserCompany } from '../../utils/authService';

export default {
  name: 'AuditLog',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsLoader,
    MdsComboBox,
    MdsDataTable,
    MdsSearchField,
    MdsPagination,
  },
  data() {
    return {
      selectedLogType: [],
      selectedTimeFrame: [],
      respond: false,
      headers: [],
      logData: [],
      paginationOpts: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        page: 1,
        totalItems: this.totalRecords,
        showItemsInfo: true,
        showItemsSelect: true,
      },
      loader: false,
      searchFilter: '',
      keyCodes: [9, 13, 16, 17, 18, 27, 45, 122, 144, 20, 27],
      rowLogs: [],
      pagination: false,
      authenticated: false,
      bbHeaders: [
        {
          text: 'Time',
          fieldName: 'created_time',
          sortable: true,
        },
        {
          text: 'Login',
          fieldName: 'login',
          sortable: true,
        },
        {
          text: 'Module',
          fieldName: 'module',
          sortable: true,
        },
        {
          text: 'Action',
          fieldName: 'action',
          sortable: true,
        },
        {
          text: 'Key Name',
          fieldName: 'key_name',
          sortable: true,
        },
        {
          text: 'Old Value',
          fieldName: 'old_value',
          sortable: true,
        },
        {
          text: 'Details',
          fieldName: 'message',
          sortable: true,
        },
      ],
      feedHeaders: [
        {
          text: 'Time',
          fieldName: 'created_time',
          sortable: true,
        },
        {
          text: 'Login',
          fieldName: 'login',
          sortable: true,
        },
        {
          text: 'Action',
          fieldName: 'action',
          sortable: true,
        },
        {
          text: 'Feed Name',
          fieldName: 'ref_id',
          sortable: true,
        },
        {
          text: 'Details',
          fieldName: 'message',
          sortable: true,
        },
      ],
      logTypes: [
        {
          text: 'Bloomberg',
          value: 'bloomberg',
        },
        {
          text: 'Feeds',
          value: 'feeds',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAuditLogs: 'auditLogModule/getAuditLogs',
      pageStatus: 'auditLogModule/getPageStatus',
      totalRecords: 'auditLogModule/getTotalRecords',
      timeFrames: 'auditLogModule/getTimeFrames',
    }),
    auditLogs: {
      get() {
        return this.getAuditLogs;
      },
      set() {
        return this.getAuditLogs;
      },
    },
  },
  created() {
    this.getBBLicense().then((response) => {
      this.authenticated = true;
      if (response.status !== 200) {
        if (this.logTypes.length === 2) {
          this.logTypes.shift();
          this.headers = this.feedHeaders;
        }
        this.selectedLogType.push('feeds');
        this.init();
      } else {
        this.selectedLogType.push('bloomberg');
        this.headers = this.bbHeaders;
        this.init();
      }
    });
  },

  methods: {
    ...mapActions('auditLogModule', ['getLogs', 'getBBLicense']),
    filterLogs(event) {
      if (!this.loader) {
        this.pagination = false;
        this.getLogs({
          timeFrame: 'last_edit',
          pageNo: 1,
          noOfRecords: 10,
          logType: event[0],
          company: getUserCompany(),
          roles: getUserRoles(),
        }).then((response) => {
          if (response.data) {
            this.headers = event[0] === 'feeds' ? this.feedHeaders : this.bbHeaders;
            this.rowLogs = this.auditLogs;
            this.selectedTimeFrame = ['last_edit'];
            this.selectedLogType = event;
            if (
              this.auditLogs !== undefined
            && this.auditLogs.length > 0
            && this.selectedTimeFrame[0] !== 'last_edit'
            ) {
              this.pagination = true;
            }
            this.onSort({ sortField: 'created_time', sortOrder: -1 });
            this.modifyPagination({
              page: 1,
              pageSize: 10,
              totalItems: this.totalRecords,
            });
          }
          this.loader = false;
        });
      }
    },
    filterTime(event) {
      if (!this.loader) {
        this.pagination = false;
        this.getLogs({
          timeFrame: event[0],
          pageNo: 1,
          noOfRecords: 10,
          logType: this.selectedLogType[0],
          company: getUserCompany(),
          roles: getUserRoles(),
        }).then((response) => {
          if (response.data) {
            this.rowLogs = this.auditLogs;
            this.selectedTimeFrame = event;
            if (
              this.auditLogs !== undefined
            && this.auditLogs.length > 0
            && this.selectedTimeFrame[0] !== 'last_edit'
            ) {
              this.pagination = true;
            }
            this.onSort({ sortField: 'created_time', sortOrder: -1 });
            this.modifyPagination({
              page: 1,
              pageSize: 10,
              totalItems: this.totalRecords,
            });
          }
          this.loader = false;
        });
      }
    },
    onSort(arg) {
      const order = arg.sortOrder;
      if (arg.sortField === 'created_time') {
        this.auditLogs = this.auditLogs.sort((a, b) => (order === -1
          ? new Date(b[`${arg.sortField}`]).getTime() - new Date(a[`${arg.sortField}`]).getTime()
          : new Date(a[`${arg.sortField}`]).getTime() - new Date(b[`${arg.sortField}`]).getTime()));
      } else {
        this.showRows = this.auditLogs.sort(
          (a, b) => (a[`${arg.sortField}`] >= b[`${arg.sortField}`] ? order : -order),
          undefined,
          {
            sensitivity: 'base',
          },
        );
      }
    },
    paginateTable(arg) {
      this.loader = true;
      this.pagination = false;
      this.getLogs({
        timeFrame: this.selectedTimeFrame[0],
        pageNo: arg.page,
        noOfRecords: arg.pageSize,
        logType: this.selectedLogType[0],
        company: getUserCompany(),
        roles: getUserRoles(),
      }).then((response) => {
        if (response.data) {
          this.rowLogs = this.auditLogs;
          if (
            this.auditLogs !== undefined
          && this.auditLogs.length > 0
          && this.selectedTimeFrame[0] !== 'last_edit'
          ) {
            this.pagination = true;
          }
          this.onSort({ sortField: 'created_time', sortOrder: -1 });
          this.modifyPagination({
            page: arg.page,
            pageSize: arg.pageSize,
            totalItems: this.totalRecords,
          });
        }
        this.loader = false;
      });
    },
    modifyPagination(arg) {
      this.paginationOpts['page'] = arg.page;
      this.paginationOpts['pageSize'] = arg.pageSize;
      this.paginationOpts['totalItems'] = arg.totalItems;
    },
    filterKeywords(event) {
      if (this.searchFilter === '') {
        this.pagination = true;
      } else {
        this.pagination = false;
      }
      const keyCode = event.keyCode || event.which;
      // Don't validate the input if below arrow,tab capslock,shift and ctrl keys were pressed
      if (this.keyCodes.includes(keyCode) || (keyCode >= 35 && keyCode <= 40)) {
        return false;
      }
      this.searchKeyword();
      return true;
    },
    clearSearchBar() {
      this.searchFilter = '';
      this.pagination = true;
      this.searchKeyword();
    },
    init() {
      this.loader = true;
      this.pagination = false;
      this.$emit('update:layout', LayoutDefault);
      this.selectedTimeFrame.push('last_edit');
      this.getLogs({
        timeFrame: this.selectedTimeFrame[0],
        pageNo: 1,
        noOfRecords: 10,
        logType: this.selectedLogType[0],
        company: getUserCompany(),
        roles: getUserRoles(),
      }).then((response) => {
        if (response.data) {
          this.rowLogs = this.auditLogs;
          if (
            this.auditLogs !== undefined
          && this.auditLogs.length > 0
          && this.selectedTimeFrame[0] !== 'last_edit'
          ) {
            this.pagination = true;
          }
          this.onSort({ sortField: 'created_time', sortOrder: -1 });
          this.modifyPagination({
            page: 1,
            pageSize: 10,
            totalItems: this.totalRecords,
          });
        }
        this.loader = false;
      });
    },
    searchKeyword() {
      this.rowLogs = this.auditLogs.filter(obj => Object.keys(obj).some(
        key => key !== 'id'
            && key !== 'ref_id'
            && key !== 'sudo_user'
            && obj[key] !== null
            && obj[key].toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1,
      ));
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/audit_log';
</style>

<template>
  <div class="cmd-home">
    <banner />
    <template v-if="!loaded">
      <mds-loader
        v-show="!loaded"
        size="large"
      />
    </template>
    <template v-else-if="!error">
      <div
        class="home-content"
        role="complementary"
      >
        <div class="carousel-container">
          <div class="carousel-grid">
            <carousel-slide :slides="slides" />
            <carousel-tiles :tiles="tiles" />
          </div>
        </div>

        <div
          class="product-section"
        >
          <products :products="products" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="home-content-error">
        <p aria-label="main">
          {{ $t('labels.home.labels.landingPageError') }}
        </p>
      </div>
    </template>
    <page-footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import MdsLoader from '@mds/loader';
import Banner from './Banner.vue';
import CarouselSlide from './CarouselSlide.vue';
import Products from './Products.vue';
import PageFooter from '../PageFooter';
import CarouselTiles from './CarouselTiles.vue';

export default {
  name: 'Home',
  components: {
    Banner, CarouselSlide, CarouselTiles, Products, PageFooter, MdsLoader,
  },
  data() {
    return {
      slides: [],
      tiles: [],
      products: [],
      error: false,
      loaded: false,
    };
  },
  async created() {
    await axios.get(`${process.env.VUE_APP_LANDING_PAGE_RESOURCE}/home.json`)
      .then((response) => {
        const configs = response.data;
        this.slides = configs.slides;
        this.tiles = configs.tiles;
        this.products = configs.products;
        this.loaded = true;
      })
      .catch((error) => {
        this.loaded = true;
        this.error = true;
      });
  },


};
</script>

<template>
  <div>
    <div class="home-gradient">
      <h1 class="product-section-head">
        {{ $t('labels.home.labels.productHeading') }}
      </h1>
    </div>
    <div
      v-for="(product,index) in products"
      :key="index"
    >
      <div
        v-if="index%2==0"
        :id="product.name"
        class="product-gird"
      >
        <div class="product-content">
          <h2 class="product-title">
            {{ product.title }}
          </h2>
          <h2 class="product-subheading">
            {{ product.subheading }}
          </h2>
          <ul>
            <li
              v-for="(bullet,index1) in product.bullets"
              :key="index1"
              class="bullet"
            >
              <span class="bullet-head">{{ bullet[0] }}</span> - <span>{{ bullet[1] }}</span>
            </li>
          </ul>
          <mds-button
            class="learn-more"
            variation="primary"
            @click="learnMore(product.url)"
          >
            {{ product.learnMore }}
          </mds-button>
        </div>
        <div
          class="product-image"
          :style="'background: url(' + getImgUrl(product.image) + ');'"
        />
      </div>
      <div
        v-else
        :id="product.name"
        class="product-gird"
      >
        <div
          class="product-image"
          :style="'background: url(' + getImgUrl(product.image) + ');'"
        />
        <div class="product-content">
          <h2 class="product-title">
            {{ product.title }}
          </h2>
          <h2 class="product-subheading">
            {{ product.subheading }}
          </h2>
          <ul>
            <li
              v-for="(bullet,index1) in product.bullets"
              :key="index1"
              class="bullet"
            >
              <span class="bullet-head">{{ bullet[0] }}</span> - {{ bullet[1] }}
            </li>
          </ul>
          <mds-button
            class="learn-more"
            variation="primary"
            @click="learnMore(product.url)"
          >
            {{ product.learnMore }}
          </mds-button>
        </div>
      </div>
      <hr>
    </div>
  </div>
</template>

<script>
import { MdsButton } from '@mds/button';

export default {
  name: 'HomeProducts',
  components: {
    MdsButton,
  },
  props: {
    products: { type: Array, default: () => [] },
  },
  methods: {
    getImgUrl(pic) {
      return `${process.env.VUE_APP_LANDING_PAGE_RESOURCE}/images/carousel/${pic}`;
    },
    learnMore(url) {
      window.open(url, '_blank');
    },
  },
};
</script>
<style lang='scss'>
@import '~@mds/constants';
@import '~@mds/fonts';
@import '~@mds/typography';

.product-section-head {
  background-color: $mds-background-color-light-gray;
}
</style>

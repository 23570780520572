/* eslint-disable no-use-before-define */

import { requireNonEmptyString } from '../../strings.es6';
import { requireParameterSetGroup } from '../../wf.js';
import { requireFunction } from '../../functions.es6';
import { isVoid } from '../../objects.es6';
import Arrays from '../../arrays.es6';
import Events from '../../events.es6';
import Dates from '../../dates/dates.es6';
import { LimitlessApiHandler } from '../../apihandler.es6';
import ServerError from '../../servererror.es6';

const ERROR = 'error';
const SAVED_PARAMETER_SET_GROUP = 'saved-parameter-set-group';
const SAVED_FORMULA = 'saved-formula';
const SAVED_SCHEDULE = 'saved-schedule';
const SAVED_WORKFLOW = 'saved-workflow';

export const EventNames = Object.freeze(/** @lends {EventNames} */ {
  ERROR,
  SAVED_PARAMETER_SET_GROUP,
  SAVED_FORMULA,
  SAVED_SCHEDULE,
  SAVED_WORKFLOW,
});

/** @type {?LimitlessApiHandler} */
let _apiHandler = null;

/**
 * @returns {LimitlessApiHandler} Singleton API handler, limited to two concurrent requests.
 * @private
 */
function _getAH() {
  if (isVoid(_apiHandler)) {
    _apiHandler = new LimitlessApiHandler(2);
  }
  return _apiHandler;
}

/**
 * @param {(ServerError|int|*)} resp
 * @param {WorkflowSaveOperation} saveOper
 * @returns {boolean} Whether the given response was a server error and has been handled (true) or a
 *          valid response (false).
 * @private
 */
function _isHandledError(resp, saveOper) {
  if (!ServerError.isError(resp)) {
    return false;
  }
  saveOper.events.send(ERROR, ServerError.displayText(resp));
  saveOper._complete(false);
  return true;
}

/**
 * Submit formulas to Marketplace.
 * This method only submits the formulas that have been modified.
 * @param {WorkflowSaveOperation} saveOper
 * @private
 */
function _saveFormulas(saveOper) {
  if (saveOper._formulas.length === 0) {
    // No formulas? Skip to next step.
    _savePsg(saveOper);
    return;
  }

  const cleanName = saveOper._name.replace(new RegExp('\\W+', 'g'), '_');
  const prefix = `wfgen_${ cleanName }_${
    Dates.getFormatter('yyyyMMddTHHmmss', false)(new Date()) }_`;
  let seq = 0;
  let numRcvd = 0;

  saveOper._formulas.forEach((formula) => {
    _getAH().call(
      'lim.MpApi.CreateFormula',
      /** @param {(int|ServerError|SavedFormulaResponse)} resp */
      (resp) => {
        numRcvd++;
        if (!_isHandledError(resp, saveOper)) {
          formula._uuid = resp.uuid;
          saveOper.events.send(SAVED_FORMULA, formula);
          if (numRcvd === seq // last one
                        && saveOper._formulas.every(f => !isVoid(f._uuid))) { // all saved successfully
            // Move to next step.
            _savePsg(saveOper);
          }
        }
      },
      prefix + (++seq),
      'JS',
      formula._code,
    );
  });
}

/**
 * Submits the managers `psgBuffer` to Marketplace.
 * @param {WorkflowSaveOperation} saveOper
 * @private
 */
function _savePsg(saveOper) {
  const psg = saveOper._psg;
  if (isVoid(psg) || psg.isPersisted()) {
    // No ParameterSetGroup to save? Skip to next step.
    _saveConfig(saveOper, psg);
  } else {
    _getAH().call(
      'lim.MpApi.SetParameterSetGroup',
      /** @param {(int|ServerError|ParameterSetGroup)} resp */
      (resp) => {
        if (!_isHandledError(resp, saveOper)) {
          saveOper.events.send(SAVED_PARAMETER_SET_GROUP, resp);
          _saveConfig(saveOper, resp);
        }
      },
      psg,
    );
  }
}

/**
 * Submits the workflow config file to Marketplace.
 * @param {WorkflowSaveOperation} saveOper
 * @param {?ParameterSetGroup} psg ParameterSetGroup for this workflow, null if one isn't being saved as part of
 *         this operation.
 * @private
 */
async function _saveConfig(saveOper, psg, vue) {
  const wfCfgBuilder = saveOper._wfCfgBuilderFactory();
  const cronExpr = saveOper._cronExpr;
  if (!isVoid(psg) && psg.isPersisted()) {
    wfCfgBuilder.overwriteParameterSetGroupId(psg.id());
  }
  if (!isVoid(cronExpr)) {
    wfCfgBuilder.overwriteCronExpression(cronExpr);
  }

  if (wfCfgBuilder.isNewConfig()) {
    return vue.saveNewWorkflow(wfCfgBuilder.toJson());
  }
  return vue.saveWorkflowConfig(wfCfgBuilder.toJson());
}

/**
 * Sets the schedule for this combination of config and parameter-set-group.
 * @param {WorkflowSaveOperation} saveOper
 * @param {WorkflowConfig} wfConfig
 * @param {ParameterSetGroup} psg
 * @private
 */
function _saveSchedule(saveOper, wfConfig, psg) {
  if (isVoid(saveOper._cronExpr)) {
    _finalizeSaveOperation(saveOper);
  } else {
    _getAH().call(
      'lim.MpApi.CreateJobSchedule',
      /** @param {(int|ServerError|ScheduledJob)} resp */
      (resp) => {
        if (!_isHandledError(resp, saveOper)) {
          saveOper.events.send(SAVED_SCHEDULE, resp);
          _finalizeSaveOperation(saveOper);
        }
      },
      wfConfig,
      saveOper._cronExpr,
      psg,
      wfConfig.timezone(),
    );
  }
}

/**
 * @param {WorkflowSaveOperation} saveOper
 * @private
 */
function _finalizeSaveOperation(saveOper) {
  saveOper._complete(true);
}

/**
 * Wrapper around a formula to be saved, used within WorkflowSaveOperation.
 */
export class WorkflowSaveFormulaContext {
  /**
     * @param {string} code Formula code.
     * @param {*} context Context associated with this formula, anything callers want to like to it.
     */
  constructor(code, context) {
    this._code = requireNonEmptyString(code, 'code');
    this._ctx = context;

    /** @type {?string} */
    this._uuid = null;
  }

  /** @returns {*} Context given to constructor, as is. */
  getContext() {
    return this._ctx;
  }

  /** @returns {?string} UUID given to this formula once save operation succeeds, null if unsaved. */
  getUuid() {
    return this._uuid;
  }
}

/**
 * WorkflowSaveOperation is an implementation that enables saving a Marketplace workflow, including
 * parameter-set-group, formulas, schedule.
 *
 * The process of saving a workflow is complicated.  This implementation allows callers to specify what
 * needs to be saved, then execute the save and receiving notifications as each item is being saved.
 *
 * The operation consists of saving the following items, in order:
 *
 * 1. formulas - only modified formulas must be specified, an empty list is acceptable and is the default;
 * 2. parameter-set-group - required only when changes must be saved or when a new schedule is being set;
 * 3. workflow configuration - required, saved via WorkflowConfigBuilder factory method provided to constructor;
 * 4. schedule - optional, only provide when saving a new workflow or changing the existing schedule.
 *
 * Callers create a new instance, call setter methods for what must be saved, then call {@link execute}.
 * Callers can also bind event listeners, to monitor the operation's progress.  When the operation reaches a stopping
 * point - whether it completed successfully or encountered an error - the *complete* callback is executed
 * (if provided to {@link execute}.
 *
 * Save operations can only be executed once; calling {@link execute} twice results in an error.
 */
export default class WorkflowSaveOperation {
  /**
     * @param {string} name New workflow name
     * @param {function():WorkflowConfigBuilder} wfConfigBuilderFactory
     */
  constructor(name, wfConfigBuilderFactory) {
    this._name = requireNonEmptyString(name, 'name');

    // Validate aggressively!  Saving a workflow is complicated; we need to know now if a bad factory method
    // is provided.
    this._wfCfgBuilderFactory = requireFunction(wfConfigBuilderFactory, 'wfConfigBuilderFactory');

    /** @type {?ParameterSetGroup} */
    this._psg = null;

    /** @type {WorkflowSaveFormulaContext[]} */
    this._formulas = [];

    /** @type {?string} */
    this._cronExpr = null;

    /** @type {?function(boolean)} */
    this._complete = null;

    this.events = new Events(...Object.values(EventNames));
  }

    static EventNames = EventNames;

    /**
     * Sets a ParameterSetGroup to be associated with the workflow.
     * ParameterSetGroup objects are assumed to be immutable, even persisted ones.
     * If `psg` has already been persisted, it will not be saved again; that would be unnecessary.
     * But its ID will still be set in the WorkflowConfigBuilder's `ui` property.
     * @param {ParameterSetGroup} psg
     * @returns {WorkflowSaveOperation} `this`
     */
    setParameterSetGroup(psg) {
      this._psg = requireParameterSetGroup(psg, 'psg');
      return this;
    }

    /**
     * Sets formulas to be saved as part of this workflow.
     * @param {WorkflowSaveFormulaContext[]} formulas
     * @returns {WorkflowSaveOperation} `this`
     */
    setFormulas(formulas) {
      Arrays.addAll(
        this._formulas,
        Arrays.requireArrayOf(
          formulas,
          WorkflowSaveFormulaContext,
          'formulas',
          'WorkflowSaveFormulaContext',
        ),
      );
      return this;
    }

    /**
     * Sets the schedule for the workflow.  Callers of this method must also call
     * {@link setParameterSetGroup}, which is required for the schedule to save.
     * @param {string} cronExpression Cron expression.
     * @returns {WorkflowSaveOperation} `this`
     */
    setSchedule(cronExpression) {
      this._cronExpr = requireNonEmptyString(cronExpression, 'cronExpression');
      return this;
    }

    /**
     * Launches the save operation.
     * @param {function(boolean)} [complete] Callback executed when the operation completes.
     *        The sole argument is a boolean to indicate whether the operation was successful (true)
     *        or not (false).  All events are dispatched prior to this callback.
     * @throws {Error} If `execute` has already been called, or if a schedule cron-expression was provided
     *         without a parameter-set-group.
     */
    execute(complete) {
      if (!isVoid(this._complete)) {
        throw new Error('Save operation already started, cannot execute twice.');
      }
      if (!isVoid(this._cronExpr) && isVoid(this._psg)) {
        throw new Error('ParameterSetGroup is required when saving schedule cron-expression.');
      }
      this._complete = (
        (arguments.length > 0)
          ? requireFunction(complete, 'complete')
          : () => {}
      );
      _saveFormulas(this);
    }
}

export { _saveConfig };

<template>
  <div class="workspace-tabs__add-modal-form standard-widget-container">
    <mds-modal
      ref="addModal"
      title="Add Workspace Information"
      class="tabs__add--modal"
      @mds-modal-dismissed="clearForm"
    >
      <span slot="mds-modal-actions">
        <mds-button
          :on-dark="darkMode"
          type="button"
          class="modal-actions__cancel-btn"
          variation="secondary"
          @click="closeModal"
        >{{ $t('dashboard.common.cancel') }}</mds-button>
        <mds-button
          :on-dark="darkMode"
          class="modal-actions__save-btn"
          type="submit"
          variation="primary"
          :disabled="!isValidForm"
          @click.prevent="handleAddWorkspace"
        >{{ $t('dashboard.common.save') }}</mds-button>
      </span>
      <mds-form
        class="mds-form mds-form--medium-layout"
        novalidate
        name="loginForm"
        @submit.prevent="handleAddWorkspace"
      >
        <mds-input
          v-model="$v.name.$model"
          :error="$v.name.$error"
          :error-text="errors"
          :on-dark="darkMode"
          label="Name:"
          @input="watchModelChange"
        />
        <mds-button-group
          full-width
          :on-dark="darkMode"
          :content="btnGroups"
          @mds-button-group-item-active="handleToggle"
        />
        <mds-layout-grid
          class="modal__btn-groups__canvas"
        >
          <mds-row>
            <mds-col
              :cols="12"
              :align-vertical="'center'"
            >
              <mds-field-set
                v-if="isStandardView"
                :size="size"
                :on-dark="darkMode"
                variation="radio-group"
                legend="Templates: "
              >
                <mds-radio-button
                  v-model="selectedTemplate"
                  :on-dark="darkMode"
                  checked
                  :size="size"
                  name="widgetType"
                  value="oil"
                  label="Oil Template"
                />
                <mds-radio-button
                  v-model="selectedTemplate"
                  :on-dark="darkMode"
                  name="widgetType"
                  :size="size"
                  value="gas"
                  label="Gas Template"
                />
                <mds-radio-button
                  v-model="selectedTemplate"
                  :on-dark="darkMode"
                  name="widgetType"
                  :size="size"
                  value="power"
                  label="Power Template"
                />
              </mds-field-set>
              <mds-field-set
                v-if="isCustomView"
                :size="size"
                :on-dark="darkMode"
                variation="radio-group"
                legend="Layout type: "
              >
                <mds-radio-button
                  v-model="selectedLayout"
                  :checked="(true)"
                  :model-value="selectedLayout"
                  :size="size"
                  name="layoutType"
                  label="One Row"
                  value="oneCard"
                  :on-dark="darkMode"
                />
                <mds-radio-button
                  v-model="selectedLayout"
                  :model-value="selectedLayout"
                  name="layoutType"
                  :size="size"
                  label="Two Rows"
                  value="twoCards"
                  :on-dark="darkMode"
                />
                <mds-radio-button
                  v-model="selectedLayout"
                  :model-value="selectedLayout"
                  name="layoutType"
                  :size="size"
                  label="Three Rows"
                  value="threeCards"
                  :on-dark="darkMode"
                />
                <mds-radio-button
                  v-model="selectedLayout"
                  :model-value="selectedLayout"
                  name="layoutType"
                  :size="size"
                  label="Four Rows"
                  value="fourCards"
                  :on-dark="darkMode"
                />
              </mds-field-set>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </mds-form>
    </mds-modal>
  </div>
</template>
<script>
import MdsForm from '@mds/form';
import MdsInput from '@mds/input';
import { MdsButton } from '@mds/button';
import MdsRadioButton from '@mds/radio-button';
import MdsButtonGroup from '@mds/button-group';
import MdsFieldSet from '@mds/fieldset';
import { MdsLayoutGrid, MdsCol, MdsRow } from '@mds/layout-grid';
import MdsModal from '@mds/modal';
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import EventBus from '../../../main';
import { specialCharacterValidator } from '../mstarPasswordStrategy';
import { McdValidationMixin } from '../../../mixins/mcdValidationMixin';
import { ThemeMixin } from '../../../mixins/themeMixin';

export default {
  name: 'AddWorkspaceModal',

  components: {
    MdsForm,
    MdsButton,
    MdsInput,
    MdsRadioButton,
    MdsLayoutGrid,
    MdsCol,
    MdsModal,
    MdsRow,
    MdsFieldSet,
    MdsButtonGroup,

  },
  mixins: [validationMixin, McdValidationMixin, ThemeMixin],
  validations: {
    name: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(20),
      specialCharacterValidator,
    },
  },
  props: {
    size: {
      type: String,
      default: 'medium',
    },
  },
  data() {
    return {
      name: '',
      errors: [],
      btnGroups: [
        {
          id: 'standard',
          text: 'Our Templates',
          icon: 'group',
          active: true,
        },
        {
          id: 'custom',
          text: 'Create Your Own',
          icon: 'object-plus',
          active: false,
        },
      ],
      selectedGroup: 'standard',
      selectedTemplate: 'oil', // standard template
      selectedLayout: 'oneCard', // custom
    };
  },
  computed: {
    isStandardView() {
      return (this.btnGroups[0].active);
    },
    isCustomView() {
      return (this.btnGroups[1].active);
    },
    isValidForm() {
      return (!this.$v.name.$error && this.$v.name.$dirty);
    },
  },
  methods: {
    ...mapActions('workspaceModule', ['addWorkspace']),
    handleAddWorkspace() {
      const id = `workspace_${this.$v.name.$model}`;
      if (this.selectedGroup === 'standard') {
        this.addWorkspace({
          id,
          title: this.$v.name.$model,
          group: this.selectedGroup,
          template: this.selectedTemplate,
          data: [],
        });
      } else {
        this.addWorkspace({
          id,
          title: this.$v.name.$model,
          group: this.selectedGroup,
          widgetsLayout: this.selectedLayout,
          filledWidgetCount: 0,
          widgets: {},
        });
        this.name = '';
      }
      EventBus.$emit('selectTabFromModal', id);
      this.closeModal();
    },
    closeModal() {
      this.$refs.addModal.hide();
    },
    clearForm() {
      this.name = '';
      this.$v.$reset();
      this.btnGroups[0].active = true;
      this.btnGroups[1].active = false;
      this.selectedGroup = 'standard';
      this.selectedLayout = 'oneCard';
      this.selectedTemplate = 'oil';
    },
    handleToggle(e) {
      if (e.target.id === 'standard') {
        this.btnGroups[0].active = true;
        this.btnGroups[1].active = false;
      } else {
        this.btnGroups[0].active = false;
        this.btnGroups[1].active = true;
      }
      this.selectedGroup = e.target.id; // important
    },
  },
};
</script>


<style lang="scss" module>
  @import "src/assets/styles/theme/mds-overrides/add-workspace-modal";
</style>

<template>
  <div class="sign-in-container__controls">
    <div class="sign-in-container__checkbox-buttons">
      <mds-checkbox
        class="checkbox-keep-logged"
        name="checkbox-keep-logged"
        value="accept"
        additional-class="sign-in-container__controls-checkbox__keep-logged"
        @change="handleKeepLogged"
      >
        {{ $mwc.$t(`rememberMeText`) }}
      </mds-checkbox>
      <a
        :href="resetPasswordUrl"
        aria-label="Forgot your password ?"
        target="_blank"
        class="mds-link link-forgot-password"
      >{{ $mwc.$t(`forgotPwdText`) }}</a>
    </div>
    <div class="sign-in-container__controls-button">
      <mds-button
        :class="{
          'text-float-left': $mwc.labeller.languageId === 'ar',
          'text-float-right': $mwc.labeller.languageId !== 'ar'
        }"
        class="login-controls__mds-login-btn"
        variation="primary"
        :disabled="disableLoginBtn"
        type="submit"
        text="Login"
        tabindex="0"
      >
        {{ $mwc.$t(`loginText`) }}
      </mds-button>
    </div>
  </div>
</template>

<script>
import {
  MdsButton,
} from '@mds/button';

export default {
  name: 'LoginControls',
  components: {
    MdsButton,
  },
  inject: ['mwcId', 'eventBus'],
  props: {
    disableLoginBtn: { type: Boolean, default: true },
    userName: { type: String, default: '' },
    password: { type: String, default: '' },
    resetPasswordUrl: { type: String, default: '' },
  },
  data() {
    return {
      isValid: false,
      isRememberMeChecked: false,
    };
  },
  created() {
    this.$mwcRegister(this);
  },
  methods: {
    handleKeepLogged() {
      this.isRememberMeChecked = !this.isRememberMeChecked;
    },
  },
};
</script>

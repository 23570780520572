
import { requireNonEmptyString } from './strings.es6';
import { requireFunction } from "./functions.es6";
import Arrays from './arrays.es6';

const ACTIVATE = '_activate';
const DEACTIVATE = '_deactivate';

/** @type {Object.<string, string>} */
const _cleanedNames = {};

const _regexLegacyNames = new RegExp("^on[A-Z]");

/**
 * @param {string} eventName
 * @returns {string} Clean, consistent event name.
 * @private
 */
function _getCleanName (eventName) {

    requireNonEmptyString(eventName, "eventName");

    if (!_cleanedNames.hasOwnProperty(eventName))
        _cleanedNames[eventName] = _clean(eventName);

    return _cleanedNames[eventName];
}

/**
 * @param {string} eventName
 * @returns {string}
 * @private
 */
function _clean (eventName) {
    if (_regexLegacyNames.test(eventName))
        return eventName.charAt(2).toLowerCase()
             + eventName.substring(3);
    else
        return eventName;
}

/**
 * Declares the named event within `store`.
 * @param {Object.<string, function[]>} store
 * @param {(string[]|Arguments)} eventNames
 * @returns {Object.<string, function[]>} Always returns `store`.
 * @private
 */
function _declareEvents (store, eventNames) {

    for (let i = 0, len = eventNames.length; i < len; i++) {
        let clean = _getCleanName(eventNames[i]);
        if (!store.hasOwnProperty(clean)) {
            store[clean] = [];
        }
    }

    return store;
}

/**
 * Validates that `arg` is an Events instance.
 * @param {Events} arg
 * @param {string} argName
 * @returns {Events} Always returns `arg`.
 * @throws {TypeError} If `arg` is not an Events object.
 */
export function requireEvents (arg, argName) {
    if (!(arg instanceof Events)) {
        throw new TypeError(argName + ": Events");
    }
    return arg;
}

export default class Events {

    /**
     * @param {...string} eventNames Names of events supported by this instance.
     */
    constructor (eventNames) {
        this._events = _declareEvents(
            {},
            [...arguments, ACTIVATE, DEACTIVATE]
        );
    }

    /* **********************************************
     * METHODS
     * ********************************************** */
    
    /**
     * Defines a list of events.
     * @param {...string} eventNames
     * @returns {Events}
     */
    declare (eventNames) {
        _declareEvents(this._events, arguments);
        return this;
    }
    
    /**
     * @param {string} eventName
     * @returns {boolean} Whether `eventName` has been declared.
     */
    isSupported (eventName) {
        return this._events.hasOwnProperty(_getCleanName(eventName));
    }
    
    /**
     * Binds a listener to an event.
     * @param {string} eventName Name of a declared event.
     * @param {function} fn Callback function to execute when event is triggered.
     * @returns {Events} A pointer to this instance, allowing call chaining.
     */
    bind (eventName, fn) {
        let clean = _getCleanName(eventName);
        if (!this.isSupported(clean)) {
            throw new Error("undeclared event name: " + eventName);
        }
        requireFunction(fn, "fn");
        this._events[clean].push(fn);
        if (this.numListeners(clean) === 1) {
            this._sendActivation(ACTIVATE, clean);
        }
        return this;
    }

    /**
     * Binds a listener to an event. Alias to {@link bind}.
     * @param {string} eventName Name of a declared event.
     * @param {function} fn Callback function to execute when event is triggered.
     * @returns {Events} A pointer to this instance, allowing call chaining.
     */
    on (eventName, fn) {
        return this.bind(...arguments);
    }
    
    /**
     * Removes the bind between a listener and an event.
     * @param {string} eventName Name of a declared event.
     * @param {function} fn Callback function to remove from bound list of callbacks.
     * @returns {Events} A pointer to this instance, allowing call chaining.
     */
    unbind (eventName, fn) {
        let clean = _getCleanName(eventName);
        requireFunction(fn, "fn");
        let fns = this._events[clean];
        if (fns instanceof Array) {
            let len = fns.length;
            for (let i = len - 1; i >= 0; i--) {
                if (fns[i] === fn) {
                    fns.splice(i, 1);
                }
            }
            if (   fns.length !== len
                && fns.length === 0  ) {
                this._sendActivation(DEACTIVATE, clean);
            }
        }
        return this;
    }

    /**
     * Removes the bind between a listener and an event.  Alias for {@link unbind}.
     * @param {string} eventName Name of a declared event.
     * @param {function} fn Callback function to remove from bound list of callbacks.
     * @returns {Events} A pointer to this instance, allowing call chaining.
     */
    off (eventName, fn) {
        return this.unbind(...arguments);
    }

    /**
     * @param {string} eventName
     * @returns {int} Number of listeners for currently subscribed to `eventName`.
     */
    numListeners (eventName) {
        
        let clean = _getCleanName(eventName),
            list  = this._events[clean];
        
        if (list instanceof Array)
            return list.length;
        else
            return 0;
    }
    
    /**
     * @param {string} eventName
     * @returns {boolean} Whether `eventName` has any listeners.
     */
    hasListeners (eventName) {
        return (this.numListeners(eventName) > 0);
    }
    
    /**
     * Sends an event to all listeners.
     * 
     * This method returns <code>false</code> if any of the
     * listeners returned <code>false</code>.  If there are
     * no listeners to this event, or if none of them
     * returned <code>false</code>, this method returns <code>undefined</code>
     * (for backward compatibility).
     * Typically, a listener will return <code>false</code> to indicate
     * that a browser event should not continue to bubble up the DOM; callers
     * can look for a return value equals to <code>false</code> to
     * recognize those cases.
     * 
     * @param {string} eventName
     * @param {...*} valuesToSend Values to send to listeners.
     * @returns {(boolean|*)} `false` or `undefined`
     */
    send (eventName, valuesToSend) {
        
        let clean = _getCleanName(eventName),
            fns   = this._events[clean];
        
        if (typeof fns === 'undefined')
            throw new Error("Undeclared event name: " + eventName);
        
        if (fns.length > 0) {
            let args = Arrays.slice(arguments, 1);

            let hasFoundFalse = false;
            for ( let i = 0;
                      i < fns.length && hasFoundFalse === false;
                      i++ ) {
                if (fns[i].apply(this, args) === false)
                    hasFoundFalse = true;
            }
            if (hasFoundFalse === true)
                return false;
        }

        // Do not return "true", for backward compatibility.
    }

    /**
     * Binds a listener to ACTIVATE event, this listener
     * will be noticed when any public event starts to have listeners.
     *
     * @param {function} fn
     * @returns {Events} A pointer to this instance, allowing call chaining.
     */   
    monitorActivation (fn) {
        return this.bind(ACTIVATE, fn);
    }

    /**
     * Binds a listener to DEACTIVATE event, this listener
     * will be noticed when any public event have no listeners.
     *
     * @param {function} fn
     * @returns {Events} A pointer to this instance, allowing call chaining.
     */
    monitorDeactivation (fn) {
        return this.bind(DEACTIVATE, fn);
    }
    
    _sendActivation (activationName, cleanName) {
        if (   cleanName !== ACTIVATE
            && cleanName !== DEACTIVATE )
            this.send(activationName, cleanName);
    }
}

const oneDigit = value => /[0-9]/g.test(value);
const upCaseLetter = value => /[A-Z]/g.test(value);
const lowerCaseLetter = value => /[a-z]/g.test(value);
const specialCharacterValidator = value => !/[^a-zA-Z0-9 ]/.test(value);
const repeatCharacter = value => /(.)\1{3}/g.test(value);
const consecutiveCharacter = value => /[a-z]{4,}|[A-Z]{4,}|\d{4,}/g.test(value);


export {
  oneDigit,
  upCaseLetter,
  lowerCaseLetter,
  specialCharacterValidator,
  repeatCharacter,
  consecutiveCharacter,
};

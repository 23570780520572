
import { EnumBase, EnumItem } from './enums.es6';
import Numbers, { requireNonNegativeInteger } from './numbers.es6';
import Strings, { isString, requireString, requireNonEmptyString } from './strings.es6';
import Dates from './dates/dates.es6';
import Arrays from './arrays.es6';

/**
 * @param {?(Date|*)} val Value to validate.
 * @returns {boolean} Whether the given argument is an instance of `Date` or `null`.
 * @private
 */
function _isValidDate(val) {
  // `null` is supported/accepted
  return ((val instanceof Date) || val === null);
}

/**
 * Returns whether the given argument is a number (finite or not).
 * @param {(number|*)} val Value to validate.
 * @returns {boolean} Whether the given argument is a number; Infinity and NaN are allowed.
 * @private
 */
function _isValidNum(val) {
  // NaN, +Infinity, -Infinity are all supported/accepted.
  return (typeof val === 'number');
}

/**
 * @param {?(string|*)} val Value to validate.
 * @returns {boolean} Whether the given argument is a string or `null`.
 * @private
 */
function _isValidString(val) {
  // `null` is supported/accepted
  return (isString(val) || val === null);
}

/**
 * DataMatrix data-types: DATE, NUMBER, STRING.
 * @enum {EnumItem}
 */
export const DataType = EnumBase.finalize({
  DATE: new EnumItem('DATE', 'DATE'),
  NUMBER: new EnumItem('NUMBER', 'NUMBER'),
  STRING: new EnumItem('STRING', 'STRING'),
}, 'DataMatrix.DataType');


/**
 * Matrix column header.
 */
class ColumnHeader {
  /**
     * @param {string} text Column text shown to user.
     * @param {EnumItem} dataType {@link DataType}
     */
  constructor(text, dataType) {
    let validator = null;
    let compare = null;

    switch (dataType) {
      case DataType.DATE:
        validator = _isValidDate;
        compare = Dates.compare;
        break;

      case DataType.NUMBER:
        validator = _isValidNum;
        compare = Numbers.compare;
        break;

      case DataType.STRING:
        validator = _isValidString;
        compare = Strings.compare;
        break;

      default:
        throw new Error(`Unsupported dataType: ${ dataType.toString()}`);
    }

    this._txt = text;
    this._dt = dataType;
    this._validator = validator;
    this._compare = compare;

    Object.freeze(this);
  }

  text() {
    return this._txt;
  }

  dataType() {
    return this._dt;
  }

  validator() {
    return this._validator;
  }

  compare() {
    return this._compare;
  }
}


/**
 * @param {(DataMatrix|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is a DataMatrix object.
 */
export function isDataMatrix(arg) {
  return (arg instanceof DataMatrix);
}

/**
 * Validates `arg` to be a DataMatrix object, throws otherwise.
 * @param {DataMatrix} arg Argument to validate.
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {DataMatrix} Always returns `arg`.
 * @throws {TypeError} If `arg` is invalid.
 */
export function requireDataMatrix(arg, argName) {
  if (!isDataMatrix(arg)) {
    throw new TypeError(`${argName }: DataMatrix`);
  }
  return arg;
}


/**
 * DataMatrix is a wrapper around a 2D array of data in which each row is a list of columns of equal length.
 * DataMatrix contains metadata about each column, and an optional label to help differentiate multiple matrices.
 */
class DataMatrix {
  constructor() {
    this._hdrs = [];
    this._data = null;
    this._label = '';
    this._errs = [];
  }

    static DataType = DataType;

    static is = isDataMatrix;

    static require = requireDataMatrix;

    /**
     * @param {string} errMsg Error message.
     * @returns {DataMatrix} A matrix that only contains the one given error message.
     */
    static newError(errMsg) {
      return new DataMatrix()
        .addError(requireNonEmptyString(errMsg, 'errMsg'))
        .setData([]); // Set empty data payload, effectively freezing the instance.
    }

    /**
     * Gets and sets the label associated with a data-matrix.
     * @param {string} [label] Label to give this data-matrix.
     * @return {(string|DataMatrix)} Current label, or `this`.
     */
    label(label) {
      if (arguments.length < 1) {
        return this._label;
      }
      this._label = requireString(label, 'label');
      return this;
    }

    /**
     * @param {int} idx 0-base index position of the column being sought.
     * @returns {ColumnHeader} Header metadata object for the column at `idx`.
     */
    header(idx) {
      return this._hdrs[Arrays.validIndex(idx, this._hdrs, 'idx')];
    }

    /** @returns {ColumnHeader[]} Array of headers (shallow copy). */
    headers() {
      return this._hdrs.slice(0);
    }

    /** @returns {boolean} Whether any errors have been assigned to this matrix. */
    hasErrors() {
      return (this.numErrors() > 0);
    }

    /** @returns {int} Number of errors assigned to this matrix. */
    numErrors() {
      return this._errs.length;
    }

    /** @returns {string[]} Errors associated to this matrix; mutable, modification-safe. */
    errors() {
      return this._errs.slice(0);
    }

    /**
     * Assigns an error to this matrix.
     * @param {string} msg Error message.
     * @returns {DataMatrix} `this`
     */
    addError(msg) {
      this._errs.push(requireNonEmptyString(msg, 'msg'));
      return this;
    }

    /**
     * Sets a given header.  Headers can be set in any order,
     * but all headers must be set before calling `setData()`.
     *
     * @param {int} idx 0-based index of the column header.
     * @param {string} label Label or text to use for this column, shown to user.
     * @param {EnumItem} dataType {@link DataType}
     * @return {DataMatrix}
     * @throws {Error} If {@link setData} has already been called.
     */
    setHeader(idx, label, dataType) {
      this._checkUnset();
      requireNonNegativeInteger(idx, 'idx');
      requireString(label, 'label');
      DataType.requireEnumOf(dataType, 'dataType');
      this._hdrs[idx] = new ColumnHeader(label, dataType);
      return this;
    }

    /**
     * @param {EnumItem} dataType {@link DataType}
     * @returns {int} Number of columns of the given data-type.
     */
    numOfDataType(dataType) {
      DataType.requireEnumOf(dataType, 'dataType');
      return this._hdrs.filter(h => (h._dt === dataType)).length;
    }

    /**
     * Sets the data, which must match the declared headers (which implies that all calls to
     * {@link setHeader} must be made prior to calling this method.
     *
     * Once this method has been called, neither {@link setHeader} nor {@link setData} can be called again.
     *
     * @param {Array[]} data Matrix of data.
     * @return {DataMatrix} `this`
     * @throws {Error} If {@link setData} has already been called.
     */
    setData(data) {
      this._checkUnset();
      this._data = this._validateData(data).slice(0); // shallow copy
      return this;
    }

    /**
     * @returns {Array[]} Matrix of data (shallow copy).
     * @throws {Error} If no data was previously set.
     */
    getData() {
      if (this._data === null) {
        throw new Error('No data previously set');
      }
      return this._data.slice(0);
    }

    /**
     * @return {DataMatrix} A clone of this instance with same label and headers, but no data (i.e. unset).
     */
    clone() {
      const clone = new DataMatrix();
      clone._label = this._label;
      clone._hdrs = this._hdrs.slice(0);
      return clone;
    }

    /**
     * @returns {boolean} Whether data has been set. Once set, headers cannot be changed and new data cannot be reset.
     */
    isSet() {
      return (this._data !== null);
    }

    /**
     * Checks that the instance is still unset; that no data has been loaded.
     * @throws {Error} If data exists.
     * @private
     */
    _checkUnset() {
      if (this.isSet()) {
        throw new Error('UnsupportedOperationException: setData() has already been called');
      }
    }

    /**
     * Validates a matrix to be a 2D array, in which all sub-arrays' length match that of the headers.
     * @param {Array[]} data Argument to validate.
     * @returns {Array[]} Always returns `data`.
     * @throws {TypeError} If `data` is not an array or contains anything but arrays.
     * @throws {Error} If any sub-array's length doesn't match the number of declared headers.
     * @private
     */
    _validateData(data) {
      Arrays.requireArray(data, 'data');
      const hdrs = this._hdrs;
      const size = hdrs.length;

      // Validate that all rows contain Arrays of matching size.
      data.forEach((row, i) => {
        if (!Array.isArray(row)) {
          throw new TypeError(`data[${ i }]: Array`);
        }
        if (row.length !== size) {
          throw new Error(`data[${ i }]: size mismatch headers.`);
        }
      });
      return data;
    }
}

export default DataMatrix;

const mutations = {
  SET_COMPANY_LIST: (state, list) => {
    state.companyList = list;
  },
  SET_CURRENT_MP_USER: (state, username) => {
    state.currentMpUser = username;
  },
  SET_WORKFLOW_REPORTS: (state, reports) => {
    state.SET_WORKFLOW_REPORTS = reports;
  },
};

export default mutations;

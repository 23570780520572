
export const PARAM_PREFIX_SINGLE_VAR = 'udef.var.';
export const PARAM_PREFIX_DATASET = 'udef.ds.';
export const DATASET_NAME = 'name';
export const DATASET_SUFFIX_FEED = 'feed';
export const DATASET_SUFFIX_KEY_ROOTS = 'key';
export const DATASET_SUFFIX_COLS = 'cols';
export const DATASET_SUFFIX_PROVIDER = 'prov';
export const DATASET_SUFFIX_SOURCE = 'source';
export const DATASET_SUFFIXES = [
  DATASET_SUFFIX_FEED,
  DATASET_SUFFIX_KEY_ROOTS,
  DATASET_SUFFIX_COLS,
  DATASET_SUFFIX_PROVIDER,
  DATASET_SUFFIX_SOURCE,
];
export const BUBBLE_NAME = 'bubbleName';
export const BUBBLE_DESCR = 'bubbleDescr';
export const PARAM_SET_NAME = 'priv.name';
export const PARAM_SET_DESCR = 'priv.descr';
export const PARAM_SET_UUID = 'priv.uuid';

// export default {
//   PARAM_PREFIX_SINGLE_VAR: 'udef.var.',
//   PARAM_PREFIX_DATASET: 'udef.ds.',
//   DATASET_NAME: 'name',
//   DATASET_SUFFIX_FEED: 'feed',
//   DATASET_SUFFIX_KEY_ROOTS: 'key',
//   DATASET_SUFFIX_COLS: 'cols',
//   DATASET_SUFFIX_PROVIDER: 'prov',
//   DATASET_SUFFIX_SOURCE: 'source',
//   DATASET_SUFFIXES: [
//     DATASET_SUFFIX_FEED,
//     DATASET_SUFFIX_KEY_ROOTS,
//     DATASET_SUFFIX_COLS,
//     DATASET_SUFFIX_PROVIDER,
//     DATASET_SUFFIX_SOURCE,
//   ],
//   BUBBLE_NAME: 'bubbleName',
//   BUBBLE_DESCR: 'bubbleDescr',
//   PARAM_SET_NAME: 'priv.name',
//   PARAM_SET_DESCR: 'priv.descr',
//   PARAM_SET_UUID: 'priv.uuid',
// };

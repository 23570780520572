
import { requirePositiveInteger, isNonNegativeInteger } from './numbers.es6';
import { requireFunction } from './functions.es6';

/**
 * Wrapper of `window.setTimeout`, for convenience.
 */
class Timer {
  /**
     * @param {int} delayMillis - Must be positive.
     * @param {function} callback
     */
  constructor(delayMillis, callback) {
    this._delay = requirePositiveInteger(delayMillis, 'delayMillis');
    this._cb = requireFunction(callback, 'callback');
    this._id = null;

    this._tick = this._tick.bind(this);
  }


  /**
     * This method activates the timer, unless it was already active.
     * @param {int} [delay] Delay to use, if different than the default specified during
     *              construction.
     * @returns {boolean} Whether the timer was actually set during
     *                    the execution of this method.
     */
  set(delay) {
    if (this._id !== null) {
      return false;
    }
    const d = (isNonNegativeInteger(delay) ? delay : this._delay);
    this._id = window.setTimeout(this._tick, d);
    return true;
  }

  /**
     * Resets the timer.  If the timer is not currently active,
     * this method is the same as <code>set()</code>.
     * @param {int} [delay] - The delay to use, if different than the default specified during
     *        construction.
     */
  reset(delay) {
    this.cancel();
    this.set(delay);
  }

  /**
     * Cancels the timer if it was previously set.
     * This method does nothing if the timer was
     * not previously activated (no-op).
     * @returns {boolean} Whether the timer was cancelled
     *                    during the execution of this method.
     */
  cancel() {
    if (this._id === null) {
      return false;
    }
    window.clearTimeout(this._id);
    this._id = null;
    return true;
  }

  /** @returns {boolean} Whether this timer is currently active, or set. */
  isSet() {
    return (this._id !== null);
  }

  /** @returns {int} Delay used during construction (milliseconds). */
  delay() {
    return this._delay;
  }

  /** @type {function} */
  _tick() {
    this.cancel();
    this._cb();
  }

  /** Forces a *tick* and cancels any pending tick if any. */
  tick() {
    this._tick();
  }
}

export default Timer;

<template>
  <mds-table
    class="daily__table table__padding-top"
    size="small"
  >
    <mds-thead>
      <mds-th
        v-for="(header, index) in headers"
        :key="index"
        class="cell__font"
      >
        <!-- indexes used to modify a specific column -->
        {{ index === 2 ? activeTabId : '' }}
        {{ index === 1 ? selectedMorningstarType : '' }}
        {{ header.text }}
      </mds-th>
    </mds-thead>
    <mds-tbody>
      <mds-tr
        v-for="(row,index2) in rows"
        :key="index2"
      >
        <mds-td
          v-for="(header, i) in headers"
          :key="i"
          class="cell__font"
        >
          {{ row[header.fieldName] }}
        </mds-td>
      </mds-tr>
    </mds-tbody>
  </mds-table>
</template>
<script>

import {
  MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr,
} from '@mds/data-table';
import moment from 'moment';
import axios from 'axios';

const mapper = {
  0: 'morningstar',
  1: 'forecast',
  2: 'actual',
};

function initialState() {
  return {
    selectedWidgetIndex: 0, // first item in the widgets array
    headers: [
      {
        text: 'Hour',
        fieldName: 'hour',
      },
      {
        text: '',
        fieldName: 'morningstar',
      },
      {
        text: '',
        fieldName: 'forecast',
        style: {
          width: 200,
        },
      },
      {
        text: 'Actual',
        fieldName: 'actual',
      },
    ],
    rows: [],
    results: [],
  };
}
export default {
  name: 'DailyTable',
  components: {
    MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr,
  },
  props: {
    activeTabId: { type: String, default: '' },
    selectedMorningstarType: { type: String, default: '' },
    selectedColumnType: { type: String, default: '' },
    apiData: { type: Array, default: () => [] },
  },

  data() {
    return initialState();
  },
  computed: {
    computedRows() {
      return this.rows;
    },
  },
  watch: {
    selectedColumnType(newValue, oldValue) {
      // clear old results
      this.resetPage();
      this.fetchTableData();
    },
  },
  mounted() {
    this.fetchTableData();
  },
  methods: {
    resetPage() {
      Object.assign(this.$data, initialState());
    },
    fetchTableData() {
      try {
        const localUtcDateOffset = moment().utc().utcOffset();
        const localDateOffset = moment().utcOffset();
        const diffInHours = (localUtcDateOffset - localDateOffset) / 60;
        const yesterdayDate = moment().subtract(1, 'd').startOf('day').add(23 + diffInHours + 1, 'h')
          .format('YYYY-MM-DDTHH:mm:ss');
        const todayDate = moment().startOf('day').add(24 + diffInHours + 1, 'h').format('YYYY-MM-DD');
        let timezone;
        if (this.activeTabId === 'PJM') {
          timezone = 'america/new_york';
        } else {
          timezone = 'america/chicago';
        }
        const urlsArr = [
          `/api/getTimeSeries/?feedName=${this.apiData[0].feedName}&timezone=${timezone}&rollupStrategy=hour&fromDateTime=${yesterdayDate}&toDateTime=${todayDate}&cols=${this.selectedColumnType}&${this.spread(this.apiData[0].keys)}&insertTimes=true&version=v2`, // eslint-disable-line max-len
          `/api/getTimeSeries/?feedName=${this.apiData[1].feedName}&timezone=${timezone}&rollupStrategy=hour&fromDateTime=${yesterdayDate}&toDateTime=${todayDate}&cols=${this.apiData[1].column}&${this.spread(this.apiData[1].keys)}&insertTimes=true&version=v2`, // eslint-disable-line max-len
          `/api/getTimeSeries/?feedName=${this.apiData[2].feedName}&timezone=${timezone}&rollupStrategy=hour&fromDateTime=${yesterdayDate}&toDateTime=${todayDate}&cols=${this.apiData[2].column}&${this.spread(this.apiData[2].keys)}&version=v2`, // eslint-disable-line max-len
        ];
        // laying out all the requests at once so Axios all runs them in concurrent way
        const requestOne = axios.get(urlsArr[0]);
        const requestTwo = axios.get(urlsArr[1]);
        const requestThree = axios.get(urlsArr[2]);
        axios.all([
          requestOne,
          requestTwo,
          requestThree,
        ]).then(axios.spread(async (...responses) => {
          const firstResults = responses[0].data;
          const secondResults = responses[1].data;
          const thirdResults = responses[2].data;
          for (let i = 0; i <= 23; i++) {
            const firstHour = moment(firstResults[i].date).hour();
            const secondHour = moment(secondResults[i].date).hour();
            const thirdHour = thirdResults[i] ? moment(thirdResults[i].date).hour() : 0;
            // just checking if hours are equals
            const hour = (firstHour === secondHour && secondHour === thirdHour) || (firstHour === secondHour ? firstHour : 0);
            let dtoObj;
            if (hour > 24) {
              dtoObj = {
                hour: hour - 24,
                length: 0,
              };
            } else if (hour === 0) {
              dtoObj = {
                hour: 1,
                length: 0,
              };
            } else {
              dtoObj = {
                hour: hour + 1,
                length: 0,
              };
            }
            dtoObj.morningstar = firstResults[i] ? parseFloat(firstResults[i].value).toFixed(0) : '';
            dtoObj.length += 1;
            dtoObj.forecast = secondResults[i] ? parseFloat(secondResults[i].value).toFixed(0) : '';
            dtoObj.length += 1;
            dtoObj.actual = thirdResults[i] ? parseFloat(thirdResults[i].value).toFixed(0) : '';
            dtoObj.length += 1;
            if (dtoObj.length > 0) {
              this.rows.push(dtoObj);
            }
          }
        }));
      } catch (e) {
        console.log(e);
      }
    },
    spread(itemKeys) {
      const entires = Object.entries(itemKeys);
      let str = '';
      entires.forEach((item, index) => {
        if (index > 0) {
          str = `${str }&`;
        }
        str = `${str + item[0] }=${ item[1]}`;
      });
      return str;
    },
  },
};
</script>
<style lang="scss" scoped>

.table__padding-top {
  margin-top: 20px;
}
.daily__table > thead > tr > th:nth-child(3) {
  width: 60px;
}
.cell__font{
  font-size: 12px;
}
.daily__table > thead > tr > th:nth-child(2) {
  width: 60px;
}
</style>


const MpProductSelText = {

    datasrc: "Source:",
    feed: "Feed:",
    product: "Product:",
    columns: "Columns:",
    roots: "Root(s):",
    key: "Key:",
    timeSeries: "Time-series (history)",
    fwdCurve: "Forward curve (futures contracts)",
    select: "Choose...",
    selectOne: "*** Choose one ***",
    loading: "loading...",
    search: "Search:",
    searchCols: "Column Selection",
    searchRoots: "Root Selection",
    combinedRootString: "[name] -- [descr]",

    optionLoading: "[name] - loading...",
    optionNotFound: "[name] - NOT AVAILABLE",

    SearchRootCreate: {
        tooltip: "Create a new root",
        newRootDescr: "[new]",
    },

    Error: {
        http: "Communication error: [code]",
        mp: "Received error from Marketplace:\n\nCode: [code]\nURL: [url]\n\nDetails:\n[details]"
    }
};

export default MpProductSelText;

<template>
  <div
    class="feeds__edit--container"
  >
    <mds-modal
      ref="editFeedModal"
      :title="feedName ? 'Edit '+ feedName +' Information' : 'Edit Feed Information'"
      class="feeds__edit--modal"
      @mds-modal-dismissed="clearForm"
      @mds-modal-opened="fetchModalData"
    >
      <span slot="mds-modal-actions">
        <mds-button
          type="button"
          class="modal-actions__cancel-btn"
          variation="secondary"
          @click="closeModal"
        >Cancel</mds-button>
        <mds-button
          class="modal-actions__save-btn"
          type="submit"
          variation="primary"
          :loading="isLoading"
          @click="handleSave"
        >
          Save
        </mds-button>
      </span>
      <div>
        <div
          id="mds-notification"
        >
          <mds-notification
            v-if="error"
            variation="error"
            title="Error"
            :dismiss-delay="2000"
            size="small"
            dismissible="true"
            class="z-index-9999"
            @mds-notification-dismissed="removeNotification()"
          >
            {{ error }}
          </mds-notification>
        </div>
        <mds-loader v-if="isLoadingForm" />
        <mds-form
          v-else
          class="feeds__edit--form"
        >
          <div class="edit-feed-form__group">
            <div class="form-item">
              <mds-combo-box
                :key="selectedMarketModel ? selectedMarketModel.length: 0"
                v-model="selectedMarketModel"
                required
                required-text="please select market"
                :microcopy-below="excludedMarketModel && excludedMarketModel.length ?
                  `Not Included:'${excludedMarketModel.join(';')}'` : '' "
                label="Markets"
                multiple
                :selected-items="selectedMarketModel ? selectedMarketModel : []"
                :data-set="marketsOptions"
                @input="handleMarketsComboChange"
              />
            </div>
            <div class="form-item">
              <mds-combo-box
                :key="selectedFrequencyModel ? selectedFrequencyModel.length: 0"
                v-model="selectedFrequencyModel"
                required
                required-text="please select Frequency"
                label="Frequency"
                :microcopy-below="excludedFrequencyModel && excludedFrequencyModel.length ?
                  `Not Included:'${excludedFrequencyModel.join(';')}'` : '' "
                :selected-items="selectedFrequencyModel"
                :data-set="frequencyOptions"
                @input="handleFrequencyComboChange"
              />
            </div>
          </div>
          <div class="edit-feed-form__group">
            <div class="form-item">
              <mds-combo-box
                :key="selectedCountriesModel ? selectedCountriesModel.length: 0"
                v-model="selectedCountriesModel"
                required
                required-text="please select Countries"
                :microcopy-below="excludedCountryModel && excludedCountryModel.length ?
                  `Not Included:'${excludedCountryModel.join(';')}'` : '' "
                label="Countries"
                multiple
                :selected-items="selectedCountriesModel"
                :data-set="countryOptions"
                @input="handleCountriesInputChange"
              />
            </div>
            <div class="form-item">
              <mds-combo-box
                :key="selectedContinentModel ? selectedContinentModel.length: 0"
                v-model="selectedContinentModel"
                required
                required-text="please select Countries"
                :microcopy-below="excludedContinentModel && excludedContinentModel.length ?
                  `Not Included: '${excludedContinentModel.join(';')}'` : ''"
                label="Continents"
                multiple
                :selected-items="selectedContinentModel"
                :data-set="continentOptions"
                @input="handleContinentComboChange"
              />
            </div>
          </div>
          <div class="edit-feed-form__group">
            <div class="form-item">
              <mds-combo-box
                :key="selectedDataTypeModel ? selectedDataTypeModel.length: 0"
                v-model="selectedDataTypeModel"
                required
                required-text="please select DataType"
                label="Data Type"
                :microcopy-below="excludedDataTypeModel && excludedDataTypeModel.length ?
                  `Not Included: '${excludedDataTypeModel.join(';')}'` : ''"
                :selected-items="selectedDataTypeModel"
                :data-set="dataTypeOptions"
                @input="handleDataTypeComboChange"
              />
            </div>
            <div class="form-item">
              <mds-fieldset legend="Feed's Type">
                <mds-checkbox
                  :key="isFutureModel"
                  required
                  required-text="please select Feed Type"
                  label="Feed's Type"
                  name="checkbox-name"
                  :checked="isFutureModel"
                  @change="isFutureModel = !isFutureModel"
                >
                  Future Feeds
                </mds-checkbox>
              </mds-fieldset>
            </div>
          </div>
          <div class="edit-feed-form__group">
            <div class="form-item">
              <mds-combo-box
                :key="selectedLicensingTypeModel ? selectedLicensingTypeModel.length: 0"
                v-model="selectedLicensingTypeModel"
                required
                required-text="please select Licensing Type"
                label="Licensing Type"
                :microcopy-below="excludedLicensingTypeModel && excludedLicensingTypeModel.length ?
                  `Not Included: '${excludedLicensingTypeModel.join(';')}'` : ''"
                :selected-items="selectedLicensingTypeModel"
                :data-set="licensingTypeOptions"
                @input="handleLicensingTypeComboChange"
              />
            </div>
            <div class="form-item">
              <mds-input
                v-model="urlModel"
                label="Source URL"
              />
            </div>
          </div>
          <div class="edit-feed-form__group">
            <div class="form-item">
              <mds-input
                v-model="keywordsModel"
                label="Keywords"
              />
            </div>
            <div class="form-item">
              <mds-fieldset>
                <mds-checkbox
                  :key="isRevenueShareModel"
                  required
                  required-text="please select Feed Type"
                  name="checkbox-name"
                  :checked="isRevenueShareModel"
                  @change="isRevenueShareModel = !isRevenueShareModel"
                >
                  Revenue Share
                </mds-checkbox>
              </mds-fieldset>
            </div>
          </div>
          <div class="edit-feed-form__group">
            <div class="form-item">
              <mds-combo-box
                :key="selectedTimezoneModel ? selectedTimezoneModel.length: 0"
                v-model="selectedTimezoneModel"
                required
                required-text="please select Licensing Type"
                label="Time Zone"
                :microcopy-below="excludedTimezoneModel && excludedTimezoneModel.length ?
                  `Not Included: '${excludedTimezoneModel.join(';')}'` : ''"
                :selected-items="selectedTimezoneModel"
                :data-set="timezoneOptions"
                @input="handleTimezoneComboChange"
              />
            </div>
          </div>
          <mds-textarea
            v-model="feedDescriptionModel"
            class="edit-feed-form__description"
            label="Feed's Description"
          />
          <mds-textarea
            v-model="sourceDescriptionModel"
            class="edit-feed-form__description"
            label="Source Description"
          />
        </mds-form>
      </div>
    </mds-modal>
  </div>
</template>
<script>

import MdsModal from '@mds/modal';
import MdsComboBox from '@mds/combo-box';
import MdsForm from '@mds/form';
import MdsCheckbox from '@mds/checkbox';
import MdsFieldset from '@mds/fieldset';
import MdsTextarea from '@mds/textarea';
import { MdsButton } from '@mds/button';
import MdsInput from '@mds/input';
import axios from 'axios';
import { MdsNotification } from '@mds/notification';
import {
  continentOptions,
  continentsShorted,
  countriesShorted,
  countryOptions,
  dataTypeOptions,
  frequencyOptions,
  marketsOptions,
  dataTypeShorted,
  frequencyShorted,
  marketShorted,
  licensingTypeOptions,
  licenceTypeShorted,
  timezoneShorted,
  timezoneOptions,
} from './staticData';

export default {
  name: 'EditFeedModal',
  components: {
    MdsForm, MdsModal, MdsComboBox, MdsCheckbox, MdsFieldset, MdsTextarea, MdsButton, MdsNotification, MdsInput,
  },
  props: {
    feedName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      /* set by FeedPage */
      selectedFeedNameModel: '',
      selectedCountriesModel: [],
      selectedContinentModel: [],
      selectedMarketModel: [],
      selectedFrequencyModel: [],
      selectedDataTypeModel: [],
      selectedLicensingTypeModel: [],
      selectedTimezoneModel: [],
      excludedContinentModel: [],
      excludedMarketModel: [],
      excludedCountryModel: [],
      excludedDataTypeModel: [],
      excludedLicensingTypeModel: [],
      excludedTimezoneModel: [],
      excludedFrequencyModel: [],
      feedDescriptionModel: '',
      keywordsModel: '',
      urlModel: '',
      sourceDescriptionModel: '',
      isFutureModel: false,
      isRevenueShareModel: false,
      feedOldData: {},
      frequencyOptions,
      marketsOptions,
      continentOptions,
      dataTypeOptions,
      countryOptions,
      licensingTypeOptions,
      licenceTypeShorted,
      timezoneShorted,
      timezoneOptions,
      isLoading: false,
      isLoadingForm: false,
      error: '',
    };
  },
  methods: {
    removeNotification() {
      this.error = false;
    },
    fetchModalData() {
      this.isLoadingForm = true;
      if (this.selectedFeedNameModel) {
        axios.get(`/api/feeds/getMetaDetails/${this.selectedFeedNameModel}`)
          .then((response) => {
            this.isLoadingForm = false;
            this.feedOldData = response.data;
            const res = response.data;
            /* Countries */
            const countriesRes = res.country.split(';');
            const modifiedCountries = countriesRes.map(item => item.trim());
            this.excludedCountryModel = modifiedCountries.filter(item => !countriesShorted.includes(item));
            this.selectedCountriesModel = modifiedCountries.filter(item => countriesShorted.includes(item));

            /* Continent */
            const continentsRes = res.continent.split(';');
            const modifiedContinent = continentsRes.map(item => item.trim());
            this.excludedContinentModel = modifiedContinent.filter(item => !continentsShorted.includes(item));
            this.selectedContinentModel = modifiedContinent.filter(item => continentsShorted.includes(item));

            /* Market */
            const marketRes = res.market.split(';');
            const modifiedMarket = marketRes.map(item => item.trim());
            this.excludedMarketModel = modifiedMarket.filter(item => !marketShorted.includes(item));
            this.selectedMarketModel = modifiedMarket.filter(item => marketShorted.includes(item));

            /* Frequency */
            const frequencyRes = res.frequency.split(';');
            const modifiedFrequency = frequencyRes.map(item => item.trim());
            this.excludedFrequencyModel = modifiedFrequency.filter(item => !frequencyShorted.includes(item));
            this.selectedFrequencyModel = modifiedFrequency.filter(item => frequencyShorted.includes(item)); /* Frequency */

            // /* License Type */
            const licenseTypeRes = res.licensingType.split(';');
            const modifiedLicenseType = licenseTypeRes.map(item => item.trim());
            this.excludedLicensingTypeModel = modifiedLicenseType.filter(item => !licenceTypeShorted.includes(item));
            this.selectedLicensingTypeModel = modifiedLicenseType.filter(item => licenceTypeShorted.includes(item));

            // /* timezones */
            const timezoneRes = res.timeZone.split(';');
            const modifiedTimezone = timezoneRes.map(item => item.trim());
            this.excludedTimezoneModel = modifiedTimezone.filter(item => !timezoneShorted.includes(item));
            this.selectedTimezoneModel = modifiedTimezone.filter(item => timezoneShorted.includes(item));

            /* DataType */
            const dataTypeRes = res.dataType.split(';');
            const modifiedDataType = dataTypeRes.map(item => item.trim());
            this.excludedDataTypeModel = modifiedDataType.filter(item => !dataTypeShorted.includes(item));
            this.selectedDataTypeModel = modifiedDataType.filter(item => dataTypeShorted.includes(item));

            this.feedDescriptionModel = res.feedDescription;
            this.sourceDescriptionModel = res.sourceDescription;
            this.urlModel = res.sourceUrl;
            this.keywordsModel = res.keywords;
            this.isFutureModel = res.futuresFeed;
            this.isRevenueShareModel = res.revenueShare;
          })
          .catch((error) => {
            this.isLoadingForm = false;
            this.error = error;
          });
      } else {
        this.error = "Feed's Name can not be empty.";
      }
    },
    clearForm() {
      this.selectedCountriesModel = [];
      this.selectedContinentModel = [];
      this.selectedMarketModel = [];
      this.selectedFrequencyModel = [];
      this.selectedLicensingTypeModel = [];
      this.selectedTimezoneModel = [];
      this.selectedDataTypeModel = [];
      this.excludedContinentModel = [];
      this.excludedMarketModel = [];
      this.excludedCountryModel = [];
      this.excludedDataTypeModel = [];
      this.excludedFrequencyModel = [];
      this.excludedLicensingTypeModel = [];
      this.excludedTimezoneModel = [];
      this.feedDescriptionModel = '';
      this.sourceDescriptionModel = '';
      this.keywordsModel = '';
      this.urlModel = '';
      this.isFutureModel = false;
      this.isRevenueShareModel = false;
      this.feedOldData = {};
      this.isLoading = false;
      this.isLoadingForm = false;
      this.error = false;
    },
    closeModal() {
      this.$refs.editFeedModal.hide();
    },
    handleMarketsComboChange(markets) {
      this.selectedMarketModel = markets;
    },
    handleFrequencyComboChange(frequency) {
      this.selectedFrequencyModel = frequency;
    },
    handleLicensingTypeComboChange(licensingType) {
      this.selectedLicensingTypeModel = licensingType;
    },
    handleTimezoneComboChange(timezone) {
      this.selectedTimezoneModel = timezone;
    },
    handleContinentComboChange(continents) {
      this.selectedContinentModel = continents;
    },
    handleDataTypeComboChange(dataType) {
      this.selectedDataTypeModel = dataType;
    },
    handleCountriesInputChange(countries) {
      this.selectedCountriesModel = countries;
      this.selectedContinentModel = [];
      countries.forEach((country) => {
        const data = this.countryOptions.filter(item => item.value === country);
        if (!this.selectedContinentModel.includes(data[0].continent)) {
          this.selectedContinentModel.push(data[0].continent);
        }
      });
    },
    async handleSave() {
      this.isLoading = true;
      this.feedOldData.country = this.selectedCountriesModel.join(';');
      this.feedOldData.continent = this.selectedContinentModel.join(';');
      if (this.selectedMarketModel.length) {
        this.feedOldData.market = this.selectedMarketModel.join(';');
      }
      if (this.selectedFrequencyModel.length) {
        this.feedOldData.frequency = this.selectedFrequencyModel.join(';');
      }
      if (this.selectedLicensingTypeModel.length) {
        this.feedOldData.licensingType = this.selectedLicensingTypeModel.join(';');
      }
      if (this.selectedTimezoneModel.length) {
        this.feedOldData.timeZone = this.selectedTimezoneModel.join(';');
      }
      if (this.selectedDataTypeModel.length) {
        this.feedOldData.dataType = this.selectedDataTypeModel.join(';');
      }
      if (this.feedDescriptionModel) {
        this.feedOldData.feedDescription = this.feedDescriptionModel;
      }
      if (this.urlModel) {
        this.feedOldData.sourceUrl = this.urlModel;
      }
      if (this.keywordsModel) {
        this.feedOldData.keywords = this.keywordsModel;
      }
      if (this.sourceDescriptionModel) {
        this.feedOldData.sourceDescription = this.sourceDescriptionModel;
      }
      this.feedOldData.futuresFeed = this.isFutureModel;
      this.feedOldData.revenueShare = this.isRevenueShareModel;
      await axios.post(`/api/feeds/postMetaDetails/${this.feedName}`, this.feedOldData)
        .then((response) => {
          if (response.data) {
            this.loading = 'SUCCESS';
            this.$emit('mcd-refresh-meta-data', { feedName: JSON.parse(response.config.data).feedName, response, refreshNo: 1 });
            this.closeModal();
          }
        })
        .catch((error) => {
          this.isLoadingForm = false;
          this.error = error;
        }).finally(() => {
          this.isLoading = false;
        });
    },
  },
};

</script>
<style lang='scss' scoped>
@import '@mds/constants';
@import '@mds/typography';
@import '@mds/link';

.feeds__edit--form{
  height: 500px
}
.form-item{
  width: 45%;
}
.edit-feed-form__group{
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.edit-feed-form__description{
  padding: 10px 0;
}

.modal-actions__cancel-btn{
  margin-right: 5px;
}
</style>

<style lang="scss" module>
:global(.mds-textarea___Mcd-ui) {
  height: 130px ;
}
</style>

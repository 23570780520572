/**
 * Foo takes any argument.
 * The return value is 'baz' in all cases.
 * @param {urlString} - String that needs to be interpolated.
 * @param {map} [optionalArg] - requires a object with the properties that need to be replaced.
 */
exports.formatLDSURL = function (urlString, map, fallback) {
  return process.env.VUE_APP_LDS_HOST + urlString.replace(/\$\[[^\]]+\]/g, match => match
    .slice(2, -1)
    .trim()
    .split('.')
    .reduce(
      (searchObject, key) => searchObject[key] || fallback || match,
      map,
    ));
};

exports.IsNullOrEmpty = function (str) {
  return (str == null ? true : (str.length <= 0));
};

exports.getUserName = function (base64Credentials) {
  const credentials = Buffer.from(base64Credentials, 'base64').toString('ascii');
  const USER_NAME = credentials.split(':')[0];
  return USER_NAME;
};
exports.fixedEncodeURIComponent = function (str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, c => `%${ c.charCodeAt(0).toString(16)}`);
};

exports.formatLDSWHAURL = function (urlString, map, fallback) {
  return process.env.VUE_APP_LDSWHA_HOST + urlString.replace(/\$\[[^\]]+\]/g, match => match
    .slice(2, -1)
    .trim()
    .split('.')
    .reduce(
      (searchObject, key) => searchObject[key] || fallback || match,
      map,
    ));
};

exports.formatACCESSURL = function (urlString, map, fallback) {
  return process.env.VUE_APP_ACCESS_HOST + urlString.replace(/\$\[[^\]]+\]/g, match => match
    .slice(2, -1)
    .trim()
    .split('.')
    .reduce(
      (searchObject, key) => searchObject[key] || fallback || match,
      map,
    ));
};

exports.formatSchedulerURL = function (urlString, map, fallback) {
  return process.env.SCHEDULER_URL + urlString.replace(/\$\[[^\]]+\]/g, match => match
    .slice(2, -1)
    .trim()
    .split('.')
    .reduce(
      (searchObject, key) => searchObject[key] || fallback || match,
      map,
    ));
};

exports.isValidRequestParam = function (str) {
  return !((str == undefined || str == null || str.length < 1 || str.startsWith('$[')));
};

<template>
  <div class="sign-in-container__logo">
    <img
      style="max-height: 50px;"
      aria-label="Logo"
      :src="localUrl"
    >
  </div>
</template>

<script>
export default {
  name: 'FormLogo',
  props: {
    logoUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    localUrl() {
      if (!this.logoUrl) { return require('../../assets/images/common/Mstar-logo-50px.svg'); }
      return this.logoUrl;
    },
  },
};
</script>

<template>
  <div class="slidein">
    <mds-section
      border="tertiary"
      border-position="bottom"
      title="Basket"
    >
      <mds-button
        class="close-btn"
        constiation="icon-only"
        icon="remove"
        flat-button-icon-size="medium"
        type="button"
        @click="toggleBasket"
      />
      <div class="basket-table">
        <mds-table
          size="medium"
          hover
        >
          <mds-thead>
            <mds-th
              v-for="(header, index) in headers"
              :key="index"
              :right-aligned="header.align === 'right'"
              :style="header.style"
            >
              <div
                v-if="header.fieldName === 'column' "
                class="required"
              >
                {{ header.text }}
              </div>
              <div v-else>
                {{ header.text }}
              </div>
            </mds-th>
          </mds-thead>
          <mds-tbody>
            <mds-tr
              v-for="(row) in cart"
              :key="row.Id"
            >
              <mds-td
                v-for="(header, i) in headers"
                :key="i"
                :right-aligned="header.align === 'right'"
                :style="header.style"
              >
                <div
                  v-if="header.fieldName === 'remove' "
                >
                  <mds-button
                    class="remove-row-btn"
                    constiation="icon-only"
                    icon="trash"
                    type="button"
                    @click="deleteKeyFromBasket(row)"
                  />
                </div>
                <div
                  v-if="header.fieldName === 'column' "
                >
                  <form>
                    <mds-combo-box
                      size="small"
                      multiple
                      label="Columns"
                      hidden-label
                      placeholder="Select an option"
                      :selected-items="row.selectedCols"
                      :data-set="row[header.fieldName]"
                      @input="handleUpdatedSelectedCols(row, $event)"
                    />
                  </form>
                </div>
                <div v-else>
                  {{ row[header.fieldName] }}
                </div>
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
      </div>
      <mds-layout-grid>
        <mds-row class="basket-margin-top">
          <mds-col
            :cols="12"
            :cols-at-m="12"
            :cols-at-l="9"
          >
            <mds-button-container>
              <mds-form
                style="margin-right: 3%;"
                size="small"
              >
                <mds-select
                  class="mds-select-options-basket"
                  label="Chart Options"
                  hidden-label
                  placeholder="Send To..."
                  :options="chartOptions"
                  @change="handleSelectedItem"
                />
              </mds-form>
              <mds-button
                class="display-none"
                constiation="secondary"
              >
                {{ $t('labels.common.formula') }}
              </mds-button>
              <mds-button
                class="display-none"
                constiation="secondary"
              >
                {{ $t('labels.common.query') }}
              </mds-button>
            </mds-button-container>
          </mds-col>
          <mds-col
            :cols="12"
            :cols-at-m="12"
            :cols-at-l="3"
          >
            <mds-button-container style="display: flex; justify-content: flex-end;">
              <mds-button
                class="display-none"
                constiation="secondary"
              >
                {{ $t('labels.group.submitButton') }}
              </mds-button>
              <mds-button
                constiation="secondary"
                @click="clearBasketButton"
              >
                {{ $t('labels.group.clearButton') }}
              </mds-button>
            </mds-button-container>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </mds-section>
    <mds-modal
      v-model="toggleModal"
      class="mds-modal-section-slidein"
      size="medium"
      :title="$t('labels.common.snippet')"
      action-required
    >
      <template #mds-modal-actions>
        <mds-button-container right-aligned>
          <mds-button
            id="mds-copy-btn"
            constiation="primary"
            @click="copy"
          >
            {{ $t('labels.group.copyButton') }}
          </mds-button>
          <mds-button
            constiation="secondary"
            @click="toggleModal=!toggleModal"
          >
            {{ $t('labels.group.closeButton') }}
          </mds-button>
        </mds-button-container>
      </template>
      <mds-layout-grid>
        <mds-row>
          <mds-col :cols="12">
            <mds-form>
              <mds-fieldset>
                <mds-row>
                  <mds-col :cols="6">
                    <mds-date-picker
                      :value="startDate"
                      :min-max-dates="minMaxDates"
                      :labels="{month: 'short'}"
                      :date-format="{month: 'short'}"
                      size="small"
                      label="Start Date"
                      placeholder="Please pick a start date"
                      :error="fromDatePickerError"
                      :error-text="[`The Start Date is greater than the End Date.`]"
                      @input="updateCurrentStartDate"
                    />
                  </mds-col>
                  <mds-col :cols="6">
                    <mds-alert
                      v-show="alert"
                      variation="success"
                      size="small"
                      :persistent="true"
                    >
                      Copied to clipboard!
                    </mds-alert>
                  </mds-col>
                </mds-row>
                <mds-row>
                  <mds-col :cols="6">
                    <mds-date-picker
                      v-model="endDate"
                      class="basket-margin-top"
                      :min-max-dates="minMaxDates"
                      :labels="{month: 'short'}"
                      :date-format="{month: 'short'}"
                      size="small"
                      label="End Date"
                      placeholder="Please pick an end date"
                      :error="toDatePickerError"
                      :error-text="[`The End Date is less than the Start Date.`]"
                      @input="updateCurrentEndDate"
                    />
                  </mds-col>
                </mds-row>
              </mds-fieldset>
              <mds-row>
                <mds-col :cols="6">
                  <mds-select
                    class="basket-margin-top"
                    size="small"
                    label="Time Zone"
                    placeholder="Select a time zone"
                    :options="timeZones"
                    @change="handleSelectedTimeZone"
                  />
                </mds-col>
              </mds-row>
            </mds-form>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
      <div class="script-section basket-margin-top">
        <span
          id="python-script-container"
          style="white-space: pre;"
        >
          {{ pythonScript }}
        </span>
      </div>
    </mds-modal>
  </div>
</template>

<script>
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { MdsCol, MdsLayoutGrid, MdsRow } from '@mds/layout-grid';
import MdsSection from '@mds/section';
import MdsModal from '@mds/modal';
import {
  MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr,
} from '@mds/data-table';
import MdsComboBox from '@mds/combo-box';
import { mapActions, mapGetters } from 'vuex';
import MdsSelect from '@mds/select';
import MdsForm from '@mds/form';
import MdsDatePicker from '@mds/date-picker';
import MdsFieldset from '@mds/fieldset';
import MdsAlert from '@mds/alert';
import timeZone from '@/utils/timeZone';
import moment from 'moment';

const { pythonScriptBasket } = require('@/utils/pythonScript');

const dateFormat = 'YYYY-MM-DD';

export default {
  name: 'Basket',
  components: {
    MdsComboBox,
    MdsTable,
    MdsTh,
    MdsThead,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsButton,
    MdsButtonContainer,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsSection,
    MdsSelect,
    MdsForm,
    MdsModal,
    MdsDatePicker,
    MdsFieldset,
    MdsAlert,
  },
  data() {
    return {
      alert: false,
      endDate: '',
      startDate: '',
      open: false,
      scriptObjCreated: {
        symbolsList: [],
        timeZone: 'America/Chicago',
        startDate: moment().subtract(1, 'month').format(dateFormat),
        endDate: moment().add(1, 'month').format(dateFormat),
      },
      minMaxDates: {
        min: new Date(2021, 12, 1),
        max: new Date(2025, 12, 31),
      },
      toggleModal: false,
      chartOptions: [
        { text: 'Python', value: 'python' },
      ],
      headers: [
        {
          text: 'Keys',
          fieldName: 'key',
          style: {
            width: '220px',
          },
        },
        {
          text: 'Feed Name',
          fieldName: 'feedName',
          style: {
            width: '220px',
          },
        },
        {
          text: 'Column',
          fieldName: 'column',
          style: {
            width: '185px',
            overflow: 'inherit',
          },
        },
        {
          text: 'Remove',
          fieldName: 'remove',
          style: {
            width: '70px',
          },
        },
      ],
      fromDatePickerError: false,
      toDatePickerError: false,
    };
  },
  computed: {
    ...mapGetters({
      cart: 'dashboardModule/allBasket',
      count: 'dashboardModule/basketLength',
    }),
    timeZones() {
      const options = [];
      timeZone.timeZones.forEach((element) => {
        options.push({
          text: element,
          value: element,
        });
      });
      return options;
    },
    destructColsForEachFeed() {
      const cartBuild = [];
      this.cart.forEach((obj) => {
        if (obj['selectedCols']) {
          obj['selectedCols'].forEach((col) => {
            const objForEachCol = {
              feed: obj.feedName,
              keys: obj.key.replace(/;/g, '&'),
              columns: col,
            };
            cartBuild.push(objForEachCol);
          });
        }
      });
      return cartBuild;
    },
    pythonScript() {
      this.setArrObj();
      return pythonScriptBasket(this.scriptObjCreated);
    },
  },
  watch: {
    alert(newVal) {
      if (newVal) {
        setTimeout(() => { this.alert = false; }, 1500);
      }
    },
    scriptObjCreated: {
      handler() {
        this.fromDatePickerError = this.scriptObjCreated['startDate'] > this.scriptObjCreated['endDate'];
        this.toDatePickerError = this.scriptObjCreated['endDate'] < this.scriptObjCreated['startDate'];
      },
      deep: true,
    },
  },
  created() {
    this.$eventBus.$on('open-basket-toggle', (data) => {
      this.open = data;
    });
  },
  methods: {
    ...mapActions('dashboardModule', ['clearBasketButton', 'deleteKeyFromBasket', 'updateSelectedColumns']),
    toggleBasket() {
      this.open = false;
      this.$eventBus.$emit('close-basket-toggle', this.open);
    },
    handleUpdatedSelectedCols(rowObj, event) {
      if (rowObj) {
        rowObj.selectedCols = event;
      }
      this.updateSelectedColumns(rowObj);
    },
    handleSelectedItem() {
      const selectedValue = event.target.value;
      if (selectedValue === 'python') {
        this.toggleModal = true;
      }
      event.target.value = '';
    },
    handleSelectedTimeZone() {
      this.scriptObjCreated['timeZone'] = event.target.value;
    },
    copy() {
      const copyText = document.getElementById('python-script-container');
      const textArea = document.createElement('textarea');
      textArea.value = copyText.textContent.trim();
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('Copy');
      textArea.remove();
      this.alert = !this.alert;
    },
    setArrObj() {
      this.scriptObjCreated['symbolsList'] = this.destructColsForEachFeed;
    },
    updateCurrentStartDate(value) {
      const momentObj = moment(value);
      this.scriptObjCreated['startDate'] = momentObj.format(dateFormat);
    },
    updateCurrentEndDate() {
      const momentObj = moment(this.endDate);
      this.scriptObjCreated['endDate'] = momentObj.format(dateFormat);
      this.endDate = '';
    },
  },
};
</script>
<style lang='scss' scoped>
@import '~@mds/constants';
@import '~@mds/fonts';
@import '~@mds/typography';


.display-none {
  display: none;
}

.mds-modal-section-slidein div.script-section {
  background-color: #f2f2f2;
  overflow-y: scroll;
  height: 40vh;
  margin-bottom: 20px;
  border: solid 1px #e5e5e5;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 16px;
}

.slidein {
  max-width: 50%;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  background: #ffffff;
  height: 100%;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  padding: 2em 3em;
}
.close-btn {
  border: none;
  font-weight: bold;
  font-size: 2em;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  padding: .5em;
}

.basket-table {
  margin-top: 3%;
  z-index: 0;
  border: solid 1px #ccc;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 2em 3em;
  position: relative;
  overflow: scroll;
  height: 580px;
}
.basket-margin-top {
  margin-top: 3%;
}
.required:after {
  content:" *";
  color: red;
}
</style>
<style lang="scss" module>
:global(.mds-select___Mcd-ui ){
  > select {
    background-color: #ffffff !important;
    border-radius: 24px !important;
    border: 1px solid #808080 !important;
    color: #5e5e5e !important;
  }
}
</style>

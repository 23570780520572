const config = {
  USER_ROLES_API: 'api/users/roles/$[USER_NAME]',
  API_USER_FEEDS: 'api/feeds/list/$[USER_NAME]',
  API_USER_DATA: 'api/users/$[USER_NAME]',
  FEED_DETAILS_API: 'api/feeds/details/$[FEED_NAME]',
  FEED_ENTILEMENT_API: 'api/feeds/entitlements',
  DELETE_FEED_API: 'api/feeds/delete',
  ACTIVE_USERS_API: 'api/feeds/active/users',
  GROUP_COMPANY_API: 'api/feeds/entilements/group/update',
  GROUP_FEED_ENT_API: '/lds/companies/$[COMPANY_NAME]/groupent?feedname=$[FEED_NAME]',
  LICENSE_UPDATE_API: 'api/feeds/entilements/read/update',
  LICENSE_SAVE_API: 'api/feeds/entilements/read/save',
  WRITERS_API: 'api/feeds/entilements/writers',
  GROUP_DETAILS_API: 'api/feeds/entilements/group/details',
  DELETE_LICENSE_API: 'api/feeds/delete/license',
  USER_DATASOURCE_API: '/api/feeds/datasources/$[USER_NAME]',
  NEW_FEED_API: '/api/feeds/createFeed',
  LDS_V2_SEARCH: '/api/search/$[KEYWORD]',
  FEED_KEY_VALUES: '/api/feed/$[FEED_NAME]/keys',
  FEED_LICENSE: '/api/feeds/license/$[FEED_NAME]/$[COMPANY_NAME]',
  CHECK_CORE_FEED_API: '/api/feeds/coreFeed/$[FEED_NAME]',
  CURVE_API: '/api/feed/$[FEED_NAME]/rootcontracts',
  MD_API: '/api/feed/$[FEED_NAME]/md',
  KEYS_API: '/api/feed/$[FEED_NAME]/$[CODE_NAME]',
  MD_ROOT_API: '/api/feed/$[FEED_NAME]/contractroots/$[ROOT]',
  GET_FEED_GROUP_API: '/api/feedgroup/getfeedgroup/$[COMPANY_NAME]',
  GET_GROUP_FEEDS_API: '/api/feedgroup/getfeeds/$[COMPANY_NAME]/$[GROUP_NAME]',
  GET_GROUP_USERS_API: '/api/feedgroup/getUsers/$[COMPANY_NAME]/$[GROUP_NAME]',
  API_FEED_GROUPS: '/api/feedgroup/companies/$[COMPANY_NAME]',
  API_DELETE_FEED_GROUP: '/api/feedgroup/companies/$[COMPANY_NAME]/$[GROUP_NAME]',
  API_USER_DATASOURCES: 'api/users/$[USER_NAME]/datasources',
  API_UPDATE_USER_SETTING: 'api/users/update/settings',
  GET_FEED_ROOTS: 'api/feeds/contractRoots/$[FEED_NAME]',
  // GET_FEED_KEYS: 'api/feeds/keys/$[FEED_NAME]/$[PAGE]',
};

export default function getAPIConfigs() {
  return config;
}

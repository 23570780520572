<template>
  <div
    :ref="`analyticsWorkspace`"
  >
    <mds-layout-grid
      :ref="`analyticsWorkspace`"
      class="layout-grid"
    >
      <div>
        <div class="controls__container">
          <mds-select
            class="select__padding"
            label="Region"
            @change="handleSelectedWidget()"
          >
            <option
              v-for="(item,index) in workspace.tabs[activeTabId].widgets"
              :key="index"
              :selected="index === selectedWidgetIndex"
              :value="item.id"
            >
              {{ item.widgetName }}
            </option>
          </mds-select>
          <div
            style="text-align: center"
          >
            <h1
              class="mds-header__title component-title"
            >
              {{ workspace.tabs[activeTabId].widgets[selectedWidgetIndex].title }}
            </h1>
          </div>
          <div class="para-item">
            <div style="float: left;">
              <p style="font-size: 12px;">
                {{ $t("labels.common.lastUpdateLabel") }}
              </p>
            </div>
            <div style="float: left; margin-left: 30px;">
              <div style="float: left;">
                {{ $t("labels.common.mstrLabel") }}
                <br>
                {{ vendorName }}:
              </div>
              <div style="float: left; margin-left: 30px">
                {{ lastUpdateMstr }}
                <br>
                {{ lastUpdateVendor }}
              </div>
            </div>
          </div>
        </div>
        <forecast-zone-chart-widget
          :id="workspace.tabs[activeTabId].widgets[selectedWidgetIndex].id"
          :key="workspace.tabs[activeTabId].widgets[selectedWidgetIndex].id"
          slot="firstCard"
          :title="workspace.tabs[activeTabId].widgets[selectedWidgetIndex].title"
          :api-data="workspace.tabs[activeTabId].widgets[selectedWidgetIndex].apiData"
          :active-tab-id="activeTabId"
          :selected-morningstar-col="selectedMorningstarType"
          @colEmitter="handleSubEmitter"
          @lastUpdateTimeEvent="handleLastUpdateTime"
        />
      </div>
    </mds-layout-grid>
  </div>
</template>
<script>
import { MdsLayoutGrid } from '@mds/layout-grid';
import MdsSelect from '@mds/select';
import { mapGetters } from 'vuex';
import splunkLogger from '@/utils/splunkLogger';
import ForecastZoneChartWidget from '../Widgets/ChartWidget/ForecastZones/ForecastZoneChartWidget';
import { FEATURES } from '../../../utils/constants';
import EventBus from '../../../main';

export default {
  name: 'AnalyticsWorkspace',
  components: {
    MdsLayoutGrid,
    ForecastZoneChartWidget,
    MdsSelect,
  },
  props: {
    workspace: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedWidgetIndex: 0, // first item in the widgets array
      defaultContent: [
        {
          id: 'PJM', text: this.workspace['tabs']['PJM'].tabName, active: true, href: '/#/dataAnalytics/pjm',
        },
        {
          id: 'ERCOT', text: this.workspace['tabs']['ERCOT'].tabName, active: false, href: '/#/dataAnalytics/ercot',
        },
      ],
      activeTabId: 'PJM',
      catagorizedSubs: [],
      timeout: '',
      selectedMorningstarType: 'HCSM_Value',
      lastUpdateMstr: '',
      lastUpdateVendor: '',
      vendorName: '',
    };
  },
  mounted() {
    splunkLogger([{
      pageViewed: this.activeTabId,
    }, { featureName: 'data_analytics' }]);
    const source = this.$route.path.split('/')[2];
    // manual trigger
    this.mdsTabsItemActive(source);
    EventBus.$on('handleDaTabs', (selectedSideItem) => {
      this.selectedWidgetIndex = 0;
      this.mdsTabsItemActive(selectedSideItem);
    });
  },
  created() {
    if ((this.isUserEntitled && !this.isUserEntitled([
      FEATURES.POWER_FORECAST_PJM,
    ]))) {
      this.activeTabId = 'ERCOT';
      this.mdsTabsItemActive(this.activeTabId.toLocaleLowerCase());
    } else if ((this.isUserEntitled && !this.isUserEntitled([
      FEATURES.POWER_FORECAST_ERCOT,
    ]))) {
      this.activeTabId = 'PJM';
      this.mdsTabsItemActive(this.activeTabId.toLocaleLowerCase());
    }
  },
  methods: {
    handleSubEmitter(data) {
      // reference original list to update whats selected
      const widget = this.workspace.tabs[this.activeTabId].widgets.find(item => item.title.replaceAll(' ', '_') === data.id);
      widget.apiData[0]['column'] = data.selectedColumn;
      this.selectedMorningstarType = data.selectedColumn; // update local data of morningstar col to whats selected
    },
    handleLastUpdateTime(data) {
      this.lastUpdateMstr = data.lastUpdateMstr;
      this.lastUpdateVendor = data.lastUpdateVendor;
      this.vendorName = data.vendorName;
    },
    mdsTabsItemActive(event) {
      this.defaultContent.forEach((item) => {
        // if the event that is trigered when clicking on either of 'pjm' or 'ercot' tab is an object, item will be active
        // and splunk will register the usage of this tab of data analytics
        if (typeof event === 'object' && item.id === event.currentTarget.id) {
          item.active = true;
          splunkLogger([{
            tabClicked: item.id,
          }, { featureName: 'data_analytics' }]);
          this.activeTabId = item.id;
          /* initialize the section */
          this.selectedWidgetIndex = 0;
          this.selectedMorningstarType = 'HCSM_Value';
          // else if the event was triggered manually so it is a string (either 'pjm' or 'ercot' tab)
        } else if (typeof event === 'string' && item.id.toLocaleLowerCase() === event) {
          item.active = true;
          this.activeTabId = item.id;
        } else {
          // last else if item is active set it to false to activate the clicked tab
          item.active = false;
        }
      });
    },
    handleSelectedWidget() {
      const selectedValue = event.target.value;
      let itemIndex;
      this.workspace.tabs[this.activeTabId].widgets.forEach((item, index) => {
        if (item.id === selectedValue) {
          itemIndex = index;
        }
      });
      this.selectedWidgetIndex = itemIndex;
      this.selectedMorningstarType = 'HCSM_Value';
    },
  },
};
</script>

<style lang="scss" scoped>

.select__padding{
  width: 300px;
}
.para-item{
  color:black;
  font-size: 12px;
  margin-top: 10px;
}
h1.component-title {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  line-height: 2.5;
  font-weight: 400;
  font-size: 25px;
}
.controls__container{
  display: flex;
  padding: 20px;
  justify-content: space-around ;
}
</style>

const mutations = {
  SET_SCHEDULER_LIST: (state, list) => {
    state.schedulerList = list;
  },
  SET_PUB_LIST: (state, list) => {
    state.pubList = list.sort();
  },
};

export default mutations;

<template>
  <div
    id="mds-modal"
    class="mds-modal mds-modal--600px mds-modal--width-600px"
    :class="{ 'mds-modal--open  mds-modal--active': saveEntitlementsPopOver }"
  >
    <div
      id="mds-modal-wrapper"
      class="mds-modal__wrapper"
    >
      <section
        role="dialog"
        class="mds-modal__container"
        aria-hidden="false"
      >
        <div class="mds-modal__inner">
          <header class="mds-modal__header">
            <h1 class="mds-modal__title">
              {{ $t('labels.feed.saveEntitlementsHeader') }}
            </h1>
          </header>
          <div class="mds-modal__content">
            <template
              v-if="readUserList.length > 0 || writeUserList.length > 0 || siteUserList.length > 0"
            >
              <p>{{ $t('labels.group.confirmMessage') }}</p>
            </template>
            <template v-else>
              <p>{{ $t('labels.group.noChanges') }}</p>
            </template>
            <div
              v-if="readUserList.length > 0"
              class="group-changes-block"
            >
              <table class="mds-data-table">
                <th id="empty" />
                <tbody class="mds-data-table__body">
                  <tr class="mds-data-table__row save-group-row update">
                    <td
                      class="mds-data-table__cell border-top"
                      style="width: 3%"
                    >
                      <mds-icon name="pencil--s" />
                    </td>
                    <td class="mds-data-table__cell border-top">
                      <strong>{{ $t('labels.feed.readPermission') }}</strong>
                    </td>
                  </tr>
                  <tr
                    v-for="(user, index) in readListComputed"
                    :key="user.name + '_read_' + index"
                    class="save-group-row"
                    :class="user.isRemoved ? 'REMOVE' : 'ADD'"
                  >
                    <td
                      class="mds-data-table__cell border-top"
                      style="width: 3%"
                    >
                      <template v-if="!user.isRemoved">
                        <mds-icon name="plus--s" />
                      </template>
                      <template v-if="user.isRemoved">
                        <mds-icon name="minus--s" />
                      </template>
                    </td>
                    <td class="save-group-sec-col border-top">
                      <template v-if="user.type == 'group'">
                        <strong> {{ user.name }} </strong>
                      </template>
                      <template v-else>
                        {{ user.name }}
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-if="writeUserList.length > 0"
              class="group-changes-block"
            >
              <table class="mds-data-table">
                <th id="empty2" />
                <tbody class="mds-data-table__body">
                  <tr class="mds-data-table__row save-group-row update">
                    <td
                      class="mds-data-table__cell"
                      style="width: 3%"
                    >
                      <mds-icon name="pencil--s" />
                    </td>
                    <td class="mds-data-table__cell border-top">
                      <strong> {{ $t('labels.feed.writePermission') }} </strong>
                    </td>
                  </tr>
                  <tr
                    v-for="(user, index) in writeListComputed"
                    :key="user.name + '_write_' + index"
                    class="save-group-row"
                    :class="user.isRemoved ? 'REMOVE' : 'ADD'"
                  >
                    <td
                      class="mds-data-table__cell border-top"
                      style="width: 3%"
                    >
                      <template v-if="!user.isRemoved">
                        <mds-icon name="plus--s" />
                      </template>
                      <template v-if="user.isRemoved">
                        <mds-icon name="minus--s" />
                      </template>
                    </td>
                    <td class="save-group-sec-col">
                      {{ user.name }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-if="siteUserList.length > 0"
              class="group-changes-block"
            >
              <table class="mds-data-table">
                <th id="empty3" />
                <tbody class="mds-data-table__body">
                  <tr class="mds-data-table__row save-group-row update">
                    <td
                      class="mds-data-table__cell"
                      style="width: 3%"
                    >
                      <mds-icon name="pencil--s" />
                    </td>
                    <td class="mds-data-table__cell border-top">
                      <strong> {{ $t('labels.feed.sitePermission') }} </strong>
                    </td>
                  </tr>
                  <tr
                    v-for="(site, index) in siteListComputed"
                    :key="site.name + '_write_' + index"
                    class="save-group-row"
                    :class="site.isRemoved ? 'REMOVE' : 'ADD'"
                  >
                    <td
                      class="mds-data-table__cell border-top"
                      style="width: 3%"
                    >
                      <template v-if="!site.isRemoved">
                        <mds-icon name="plus--s" />
                      </template>
                      <template v-if="site.isRemoved">
                        <mds-icon name="minus--s" />
                      </template>
                    </td>
                    <td class="save-group-sec-col">
                      {{ site.name }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mds-button__container save-group-actions">
            <mds-button
              v-show="
                readUserList.length > 0 || writeUserList.length > 0 || siteUserList.length > 0
              "
              id="save-btn"
              variation="primary"
              type="button"
              @click="saveEntitlements()"
            >
              {{ $t('labels.group.saveButton') }}
            </mds-button>
            <mds-button
              id="cancel-btn"
              variation="secondary"
              type="button"
              @click="cancelPopOver"
            >
              {{ $t('labels.group.cancelButton') }}
            </mds-button>
          </div>
        </div>
      </section>
    </div>

    <div
      id="mds-notification"
      class="mds-notification mds-notification--top-center mds-notification--below-masthead mds-notification--active custom-top"
    >
      <mds-notification-group>
        <mds-notification
          ref="successDialog"
          :list="JSON.stringify(successText)"
          role="alertdialog"
          status="success"
          size="small"
          dismiss-delay="60000"
          dismissible="true"
          removable="false"
          class="z-index-9999"
        >
          {{ $t('labels.feed.successDialogTex') }}
        </mds-notification>
        <mds-notification
          ref="errorDialog"
          :list="JSON.stringify(errorText)"
          role="alertdialog"
          status="error"
          size="small"
          dismiss-delay="60000"
          dismissible="true"
          removable="false"
          class="z-index-9999"
        >
          {{ $t('labels.feed.errorDialogTex') }}
        </mds-notification>
      </mds-notification-group>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EventBus from '../../main';

const SITE_ALL = 'Site License (all users)';
export default {
  name: 'EntitlementsPopOver',
  props: {
    feedEntitlements: {
      type: Array,
      default: () => {},
    },
    groupEntitlements: {
      type: Array,
      default: () => [],
    },
    siteEntitlements: {
      type: Array,
      default: () => [],
    },
    initialFeedEntitlements: {
      type: Array,
      default: () => {},
    },
    initialGroupEntitlements: {
      type: Array,
      default: () => {},
    },
    initialSiteEntitlements: {
      type: Array,
      default: () => {},
    },
    removedList: {
      type: Array,
      default: () => [],
    },
    feedName: {
      type: String,
      default: '',
    },
    feedGroupName: {
      type: String,
      default: '',
    },
    groupFeeds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      readUserList: [],
      writeUserList: [],
      siteUserList: [],
      users: [],
      saveEntitlementsPopOver: false,
      countResponse: 0,
      blockCounter: 0,
      successText: [],
      errorText: [],
      newUser: [],
      readPromiseCount: 0,
      siteLicense: 'Site License (all users)',
    };
  },
  computed: {
    readListComputed() {
      return this.readUserList;
    },
    writeListComputed() {
      return this.writeUserList;
    },
    siteListComputed() {
      return this.siteUserList;
    },
  },
  watch: {
    countResponse(newValue, oldValue) {
      if (this.blockCounter === this.countResponse) {
        EventBus.$emit('hideOverLay', false);
        EventBus.$emit('hideFeedEntitlements', false);
        EventBus.$emit('refreshPage');
      }
    },
  },
  created() {
    EventBus.$on('saveEntitlements', this.saveEntitlementsPop);
  },
  methods: {
    ...mapGetters({
      baseEntitlements: 'feedModule/getFeedEntitlements',
    }),
    ...mapActions('feedModule', [
      'saveReadEntitlements',
      'saveWriteEntitlements',
      'saveGroupEntitlements',
      'postSaveReadEntitlements',
      'deleteLicenseByName',
      'saveGroupChanges',
    ]),
    cancelPopOver() {
      this.saveEntitlementsPopOver = false;
      this.siteUserList = [];
    },
    isFeedGroup() {
      return this.feedGroupName !== '';
    },
    saveEntitlementsPop() {
      this.readPermissionUser();

      this.writePermissionUser();

      this.sitePermissionUser();
      this.saveEntitlementsPopOver = true;
    },

    readPermissionUser() {
      this.readUserList = Object.assign([], []);
      this.feedEntitlements
        .filter(siteEnt => siteEnt.numsubs !== '-1')
        .forEach((ent) => {
          if (
            ent.read === true
            && !this.initialFeedEntitlements
              .filter(test => test.numsubs !== '-1')
              .some(
                item => item.name === ent.name && item.license === ent.license && item.read === true,
              )
          ) {
            const tempEnt = Object.assign({}, ent);
            tempEnt.type = 'user';
            tempEnt.isRemoved = false;
            this.readUserList.push(tempEnt);
          }
        });

      this.initialFeedEntitlements
        .filter(ini => ini.numsubs !== '-1')
        .forEach((ent) => {
          if (
            ent.read === true
            && !this.feedEntitlements
              .filter(lic => lic.numsubs !== '-1')
              .some(
                item => item.name === ent.name && item.license === ent.license && item.read === true,
              )
          ) {
            const tempEnt = Object.assign({}, ent);
            tempEnt.type = 'user';
            tempEnt.isRemoved = true;
            this.readUserList.push(tempEnt);
          }
        });

      this.groupEntitlements.forEach((ent) => {
        if (!this.initialGroupEntitlements.some(item => item === ent)) {
          this.readUserList.push({ name: ent, type: 'group', isRemoved: false });
        }
      });

      this.initialGroupEntitlements.forEach((ent) => {
        if (!this.groupEntitlements.some(item => item === ent)) {
          this.readUserList.push({ name: ent, type: 'group', isRemoved: true });
        }
      });
    },
    writePermissionUser() {
      this.writeUserList = [];
      this.feedEntitlements.forEach((ent) => {
        if (
          ent.write === true
          && !this.initialFeedEntitlements.some(
            item => item.name === ent.name && item.license === ent.license && item.write === true,
          )
        ) {
          const tempEnt = Object.assign({}, ent);
          tempEnt.isRemoved = false;
          this.writeUserList.push(tempEnt);
        }
      });

      this.initialFeedEntitlements.forEach((ent) => {
        if (
          ent.write === true
          && !this.feedEntitlements.some(
            item => item.name === ent.name && item.license === ent.license && item.write === true,
          )
        ) {
          const tempEnt = Object.assign({}, ent);
          tempEnt.isRemoved = true;
          this.writeUserList.push(tempEnt);
        }
      });
    },

    sitePermissionUser() {
      if (
        this.initialSiteEntitlements.length === 0
        && this.siteEntitlements.length === 1
        && this.siteEntitlements[0] === SITE_ALL
      ) {
        if (this.siteUserList.length === 0) {
          this.siteUserList.push({
            name: SITE_ALL,
            type: 'site',
            isRemoved: false,
          });
        }
      } else if (this.initialSiteEntitlements.length === 1 && this.siteEntitlements.length === 0) {
        this.siteUserList.push({ name: SITE_ALL, type: 'site', isRemoved: true });
      } else {
        this.siteUserList = [];
      }
    },

    async saveEntitlements() {
      this.blockCounter = 0;
      this.countResponse = 0;
      this.successText.length = 0;
      this.errorText.length = 0;
      this.saveEntitlementsPopOver = false;
      EventBus.$emit('showLoader', true);

      if (!this.isFeedGroup()) {
        if (this.siteUserList.length === 1) {
          if (this.siteUserList[0].isRemoved === false) {
            await this.saveSiteEnts();
          } else if (this.siteUserList[0].isRemoved) {
            await this.deleteSiteEnts();
          }
        }

        this.updateReadEnts()
          .then((responses) => {
            let successFlag = false;
            const errorFlag = false;
            responses.forEach((response, index) => {
              this.countResponse++;
              if (response.status === 200) {
                if (response.config.method === 'put') {
                  const reqPayload = JSON.parse(response.config.data);
                  this.successText.push(`Read License '${reqPayload.name}' Permissions Updated.`);
                } else if (response.config.method === 'delete') {
                  this.successText.push(response.data);
                } else {
                  this.successText.push('Read License Permissions Updated.');
                }
                successFlag = true;
              } else {
                this.errorText.push(response.data);
              }
            });

            if (successFlag) {
              this.$refs.successDialog.open();
            }
            if (errorFlag) {
              this.$refs.errorDialog.open();
            }
          })
          .catch((error) => {
            if (error.response.status === 502) {
              this.successText.push('Read License update request sent.');
            } else {
              this.errorText.push(error.message);
            }
            this.countResponse += this.readPromiseCount;
          });

        await this.updateWriteEnts();

        await this.updateGroupEnts();
      } else {
        this.saveFeedGroupEntitlements();
      }
    },

    async saveFeedGroupEntitlements() {
      const fgUsers = [];
      const fgUsersToRemove = [];

      this.readUserList.forEach((user) => {
        if (!user.isRemoved) {
          fgUsers.push(user.name);
        } else {
          fgUsersToRemove.push(user.name);
        }
      });

      this.initialFeedEntitlements.forEach((user) => {
        if (!fgUsersToRemove.includes(user.name)) {
          fgUsers.push(user.name);
        }
      });

      const entGroup = {
        name: this.feedGroupName,
        feeds: this.groupFeeds,
        users: fgUsers,
      };

      const params = {
        action: 'put',
        value: entGroup,
      };

      let successFlag = false;
      await this.saveGroupChanges(params).then((res) => {
        if (res.status === 200) {
          this.successText.push('Read License Permissions Updated.');
          successFlag = true;
        } else {
          this.errorText.push(res.data);
        }
      });

      if (successFlag) {
        this.$refs.successDialog.open();
      } else {
        this.$refs.errorDialog.open();
      }

      EventBus.$emit('showLoader', false);
      EventBus.$emit('hideOverLay', false);
      EventBus.$emit('hideFeedEntitlements', false);
    },

    async saveSiteEnts() {
      await this.postSaveReadEntitlements({
        historyType: 'Regular History',
        feedName: this.feedName,
        numsubs: '-1',
        users: [],
      }).then((response) => {
        if (response.status === 200) {
          this.$refs.successDialog.open();
          this.successText.push('Site License Permission Granted.');
        } else {
          this.errorText.push(response.data);
          this.$refs.errorDialog.open();
        }
      });
    },

    async deleteSiteEnts() {
      const tempSiteLicense = this.initialSiteEntitlements[0].name;
      await this.deleteLicenseByName(tempSiteLicense).then((response) => {
        if (response.status === 200) {
          this.$refs.successDialog.open();
          this.successText.push('Site License Permission Deleted.');
        } else {
          this.errorText.push(response.data);
          this.$refs.errorDialog.open();
        }
      });
    },

    async updateReadEnts() {
      const baseEntitlements = this.baseEntitlements();

      const newUsers = Object.assign(
        [],
        this.readUserList.filter(user => user.license === null).map(user => ({ login: user.name })),
      );

      const promises = [];

      if (
        baseEntitlements.readers.filter(lic => lic.numsubs !== '-1').length <= 0
        && newUsers.length > 0
      ) {
        // adding new read liscense
        const promise = new Promise((resolve) => {
          resolve(
            this.postSaveReadEntitlements({
              historyType: 'Regular History',
              feedName: this.feedName,
              numsubs: '1000',
              users: newUsers,
            }),
          );
        });
        this.blockCounter++;
        promises.push(promise);
      } else {
        baseEntitlements.readers
          .filter(lic => lic.numsubs !== '-1')
          .forEach((entObj, index) => {
            let entModified = false;
            const tempLicense = entObj.name;
            let entUsers = Object.assign([], entObj.users);

            this.readUserList
              .filter(user => user.license === tempLicense)
              .forEach((element) => {
                if (element.isRemoved === true && element.type === 'user') {
                  entUsers = Object.assign(
                    [],
                    entUsers.filter(user => user.login !== element.name),
                  );
                  entModified = true;
                }
              });

            // add new users
            if (index === 0 && newUsers.length > 0) {
              entUsers.push(...newUsers);
              entModified = true;
            }

            if (entModified === true) {
              if (entUsers.length <= 0) {
                // delete read license with no users
                const promise = new Promise((resolve) => {
                  resolve(this.deleteLicenseByName(tempLicense));
                });
                this.blockCounter++;
                promises.push(promise);
              } else {
                // Update Read License
                entObj.users = entUsers;
                const promise = new Promise((resolve) => {
                  resolve(this.saveReadEntitlements(entObj));
                });
                this.blockCounter++;
                promises.push(promise);
              }
            }
          });
      }
      this.readPromiseCount = promises.length;
      return Promise.all(promises);
    },

    async updateWriteEnts() {
      const newWriteUsers = Object.assign(
        [],
        this.writeUserList
          .filter(user => user.isRemoved === false && user.write === true)
          .map(user => user.name),
      );

      const delWriteUsers = Object.assign(
        [],
        this.writeUserList
          .filter(user => user.isRemoved === true && user.write === true)
          .map(user => user.name),
      );

      if (delWriteUsers.length > 0) {
        this.blockCounter++;
        await this.saveWriteEntitlements({
          users: delWriteUsers,
          feedName: this.feedName,
          delete: 'yes',
        }).then((response) => {
          this.countResponse++;
          if (response.status === 200) {
            this.successText.push('Write Permission Removed.');
            this.$refs.successDialog.open();
          } else {
            this.errorText.push(response.data);
            this.$refs.errorDialog.open();
          }
        });
      }

      if (newWriteUsers.length > 0) {
        this.blockCounter++;
        await this.saveWriteEntitlements({
          users: newWriteUsers,
          feedName: this.feedName,
          delete: 'no',
        }).then((response) => {
          this.countResponse++;
          if (response.status === 200) {
            this.$refs.successDialog.open();
            this.successText.push('Write Permission Granted.');
          } else {
            this.errorText.push(response.data);
            this.$refs.errorDialog.open();
          }
        });
      }
    },

    async updateGroupEnts() {
      let groupList = this.readUserList
        .filter(user => user.type === 'group' && !user.isRemoved)
        .map(ent => ({ name: ent.name, delete: false }));

      const removedGroup = this.readUserList
        .filter(user => user.type === 'group' && user.isRemoved)
        .map(ent => ({ name: ent.name, delete: true }));

      groupList = groupList.concat(removedGroup);

      if (groupList.length > 0) {
        this.blockCounter++;
        this.saveGroupEntitlements({ groupList, feedName: this.feedName }).then((responses) => {
          this.countResponse++;
          responses.forEach((response, index) => {
            if (response.status === 200) {
              let textMessage = '';
              if (response.delete) {
                textMessage = `${response.name} Removed`;
              } else {
                textMessage = `${response.name} Entitled`;
              }
              this.successText.push(textMessage);
              this.$refs.successDialog.open();
            } else {
              this.errorText.push(response.data);
              this.$refs.errorDialog.open();
            }
          });
        });
      }
    },
  },
};
</script>
<style lang="scss" >
@import 'src/assets/styles/components/custom/popover';
</style>

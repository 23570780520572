import _ from 'underscore';
import Esprima from '../lib/esprima';
import { isNonEmptyString, requireString } from './strings.es6';
import { isObject } from './objects.es6';
import Arrays from './arrays.es6';
import DataType from './script/vardatatype.es6';
import ScriptVar from './script/scriptvar.es6';
import ScriptVarStack from './script/stack.es6';

const ESyntax = Esprima.Syntax;

/* **************************************************
 * Interfaces
 * ************************************************** */

/**
 * First draft at defining what properties are provided in AST-compliant node.
 * @typedef Object AstTreeNode
 * @property {string} type
 * @property {Object} declarations
 * @property {Object} [left]
 * @property {Object} [right]
 * @property {?number[]} range
 */

/**
 * Generic visitor interface.
 * @callback Visitor<T>
 * @param {T} visitedNode
 * @template T
 */

/**
 * Generic consumer interface.
 * @callback Consumer<T>
 * @param {T} consumableItem Consumable item.
 * @template T
 */

/** @type {RegExp} */
const REGEX_FUNCTION = new RegExp('^\\s*function(\\s+[\\w$]+)?\\s*\\(');

/**
 * Parses JS code to AST-compliant structure.
 * @param {string} code
 * @returns {Object}
 * @private
 */
function _jsParse(code) {
  // http://esprima.org
  return Esprima.parse(code, {
    range: true,
  });
}

/**
 * @param {?string} code - Original source code
 * @param {?AstTreeNode} node - AST node
 * @returns {string} JavaScript text (aka code) associated with an AST node, from the original source.
 * @private
 */
function _jsText(code, node) {
  if (code !== null
        && node !== null) {
    const r = node.range;
    return code.substring(r[0], r[1]);
  }
  return 'N/A';
}

/**
 * @param {string} code
 * @returns {boolean} Whether the given code matches "function *(" or "function some_name *(.
 * @private
 */
function _isFunctionDeclaration(code) {
  return REGEX_FUNCTION.test(code);
}

/**
 * Returns whether a given AST node object represents an assignment.
 * @param {Object} node
 * @returns {boolean}
 * @private
 */
function _isSimpleAssign(node) {
  return ((node instanceof Object)
            && node.type === ESyntax.AssignmentExpression
            && node.operator === '=');
}

/**
 * Adds an assign node to `varTracker` (aka list of variables)
 * @param {Consumer<ScriptVar>} varTracker - Variable tracker
 * @param {?string} code - Original source code
 * @param {{ type: string, left: Object, right: Object, range: number[] }} astAssign
 *        Node, `type === "AssignmentExpression"`
 * @private
 */
function _addAssign(varTracker, code, astAssign) {
  const { left } = astAssign;
  if (left.type === ESyntax.Identifier && typeof left.name === 'string') {
    const initCode = _jsText(code, astAssign.right);
    if (!_isFunctionDeclaration(initCode)) {
      varTracker(ScriptVar.fromCode(left.name, initCode));
    }
  }
}

/**
 * Checks for multi-var statements, and adds all variables to `vars`.
 * Multi-variable statements look like this (with or without "var "):
 *
 * `var name1 = name2 = name3 = "value";`
 * `name4 = name5 = name6 = "value2";`
 *
 * @param {Consumer<ScriptVar>} varTracker - Variable tracker.
 * @param {?string} code - Original source code.
 * @param {?Object} astNode
 * @private
 */
function _addMultiVar(varTracker, code, astNode) {
  let node = astNode;
  while (_isSimpleAssign(node)) {
    _addAssign(varTracker, code, node);
    node = node.right;
  }
}

/**
 * Scans an AST node looking for declared variables at that level using "var" keyword,
 * or variables declared at the global level (without "var").
 * @param {Object} astCurrentLevel - ESTree-compliant node object.
 * @param {?string} code - Original source code.
 * @returns {Object.<string, ScriptVar>} An object in which properties are variable names
 *                                    and values are the code to the right of the
 *                                    last assignment for that variable.
 * @private
 */
function _getDeclaredVarsAtLevel(astCurrentLevel, code) {
  const vars = {};

  /** @param {ScriptVar} scriptVar */
  const varTracker = (scriptVar) => {
    vars[scriptVar.name()] = scriptVar;
  };

  const declarationConsumer = WorkflowVars.visitorOfDeclaredVar(varTracker, code);
  const simpleAssignConsumer = WorkflowVars.visitorOfGlobalVarAssignments(varTracker, code);

  const enterCallbacks = {
    VariableDeclaration(node) {
      const depth = this.path().length;
      if (depth <= 1) {
        declarationConsumer(node);
      }
      // noinspection JSConstructorReturnsPrimitive
      return (depth < 1); // only interested in top-level assignments
    },
    AssignmentExpression(node) {
      const depth = this.path().length;
      if (depth <= 2) {
        simpleAssignConsumer(node);
      }
      // noinspection JSConstructorReturnsPrimitive
      return (depth < 2); // only interested in top-level assignments
    },
  };
  _walkAst(astCurrentLevel, enterCallbacks, {});
  return vars;
}

/**
 * @param {(Function|*)} callback Argument to validate.
 * @returns {boolean} Whether `callback` is a function.
 * @private
 */
function _isValidAstCallback(callback) {
  return (
    typeof callback === 'function'
  // && callback.length === 1  // Takes one and only one argument.
  );
}

/**
 * Returns whether `callbacks` is a valid argument for `walkAst()` method.
 * @param {*} callbacks
 * @returns {boolean} True if valid, false otherwise.
 * @private
 */
function _isValidAstCallbacks(callbacks) {
  return (_isValidAstCallback(callbacks)
        || (isObject(callbacks)
            && Arrays.isValid(_.keys(callbacks), isNonEmptyString)
            && Arrays.isValid(_.values(callbacks), _isValidAstCallback)));
}


/* ************************************************
 * Private methods
 * ************************************************ */

// TODO
function _walkCall(walker, callback, node, goDeeper) {
  if (callback.call(walker, node) === false) return false;
  return goDeeper;
}

// TODO
function _walkExecCallback(walker, callbacks, node, goDeeper) {
  let rv = goDeeper;

  if (typeof callbacks === 'function') {
    if (_walkCall(walker, callbacks, node, goDeeper) === false) { rv = false; }
  } else if (typeof callbacks[node.type] === 'function') {
    if (_walkCall(walker, callbacks[node.type], node, goDeeper) === false) { rv = false; }
  }

  return rv;
}

/**
 * Walks one node and possibly its sub-nodes.
 * @param {{path: function, stop: function, isStopped: function}} walker
 * @param {Object[]} path
 * @param {Object} node
 * @param {(Object.<string, Function>|Function)} enterCallbacks
 * @param {(Object.<string, Function>|Function)} leaveCallbacks
 * @private
 */
function _walkAstRecurs(walker, path, node, enterCallbacks, leaveCallbacks) {
  const isNode = (typeof node.type === 'string');
  let goDeeper = true;

  if (isNode) {
    goDeeper = _walkExecCallback(walker, enterCallbacks, node, goDeeper);
    path.unshift(node); // Insert at beginning.
  }

  if (goDeeper && !walker.isStopped()) {
    for (const prop in node) {
      if (node.hasOwnProperty(prop)
                && node[prop] instanceof Object
                && prop !== 'range' // optimization, ignore "range" path
                && prop !== 'loc' // optimization, ignore "loc" path
                && !walker.isStopped()) {
        _walkAstRecurs(walker, path, node[prop], enterCallbacks, leaveCallbacks);
      }
    }
  }
  if (isNode) {
    path.shift(); // Remove from beginning
    if (!walker.isStopped()) {
      _walkExecCallback(walker, leaveCallbacks, node, goDeeper);
    }
  }
}


/**
 * Walks an Abstract Syntax Tree node.
 * @param {Object} ast - Top node to walk.
 * @param {(Object.<string, Function>|Function)} enterCallbacks - Callbacks to execute upon
 *     entering a node.
 * @param {(Object.<string, Function>|Function)} [leaveCallbacks] - Callbacks to execute upon
 *     leaving a node, moving to next sibling or up back to parent node.
 * @private
 */
function _walkAst(ast, enterCallbacks, leaveCallbacks) {
  const path = [];
  let isStopped = false;

  const walker = {
    path() {
      return Arrays.slice(path);
    },

    stop() {
      isStopped = true;
    },

    isStopped() {
      return isStopped;
    },
  };

  _walkAstRecurs(walker, path, ast, enterCallbacks, leaveCallbacks);
}

/* ************************************************
 * Public object
 * ************************************************ */

const WorkflowVars = Object.freeze(/** @lends WorkflowVars */ {

  DataType,
  ScriptVar,
  Stack: ScriptVarStack,

  /**
     * Parse a JavaScript formula (or snipet) into an ESTree-compliant Abstract Syntax Tree (AST).
     * @param {string} code
     * @returns {Object} ESTree compliant object
     * @throws Error - If `code` contains a syntax error.
     * @see https://github.com/estree/estree ESTree specifications
     * @see https://developer.mozilla.org/en-US/docs/Mozilla/Projects/SpiderMonkey/Parser_API The
     *     original SpiderMonkeyAST project
     */
  jsToAst(code) {
    return _jsParse(requireString(code, 'code'));
  },


  /**
     * Walks an ESTree-compliant Abstract Syntax Tree (tree or node).
     *
     * Callbacks can be provided as one function (which will execute for all nodes)
     * or an object of callbacks, indexed by node type (which only executes for the specified
     * node type.)
     *
     * Callbacks receive one argument: the current node.
     *
     * Callbacks that returns *false* to tell the walker not to go any deeper into the tree.
     *
     * Callbacks have access to the following methods:
     * > `this.stop()` to immediately stop the operation;
     * > `this.path()` to retrieve an array of nodes that lead to the current node.
     *    This path is sorted "parent first", meaning the immediate parent of the current
     *    node is at `this.path()[0]`, the parent's parent is at `this.path()[1]`, etc.
     *
     * @param {Object} ast - Top node to walk.
     * @param {(Object.<string, Function>|Function)} enterCallbacks - Callbacks to execute upon
     *     entering a node.
     * @param {(Object.<string, Function>|Function)} [leaveCallbacks] - Callbacks to execute upon
     *     leaving a node, moving to next sibling or up back to parent node.
     * @throws {TypeError} If `ast` is not recognized as an ESTree-compliant object, or if either
     *         `enterCallbacks` or `leaveCallbacks` is invalid.
     */
  walkAst(ast, enterCallbacks, leaveCallbacks) {
    if (!isObject(ast)
            || !isNonEmptyString(ast.type)) throw new TypeError('ast: Object, ESTree-compliant node');

    if (!_isValidAstCallbacks(enterCallbacks)) throw new TypeError('enterCallbacks: Function or Object.<string, Function>');

    if (arguments.length < 3) leaveCallbacks = {};
    else if (!_isValidAstCallbacks(leaveCallbacks)) throw new TypeError('leaveCallbacks: Function or Object.<string, Function>');

    _walkAst(ast, enterCallbacks, leaveCallbacks);
  },


  /**
     * @param {Consumer<ScriptVar>} scriptVarConsumer Consumer of declared variables in the form of ScriptVar objects.
     * @param {?string} code Raw source code, if available.
     * @returns {Visitor<AstTreeNode>} Visitor of AST nodes that pertain to variable declarations.
     */
  visitorOfDeclaredVar(scriptVarConsumer, code) {
    return function (node) {
      for (const declaration of node.declarations) {
        const { name } = declaration.id;
        const initCode = _jsText(code, declaration.init);
        if (!_isFunctionDeclaration(initCode)) {
          scriptVarConsumer(ScriptVar.fromCode(name, initCode));
        }
        _addMultiVar(scriptVarConsumer, code, declaration.init);
      }
    };
  },

  /**
     * @param {Consumer<ScriptVar>} scriptVarConsumer Consumer of declared variables in the form of ScriptVar objects.
     * @param {?string} code Raw source code, if available.
     * @returns {Visitor<AstTreeNode>} Visitor of AST nodes that assign values to global, undeclared variables.
     */
  visitorOfGlobalVarAssignments(scriptVarConsumer, code) {
    return function (node) {
      if (_isSimpleAssign(node)) {
        _addAssign(scriptVarConsumer, code, node);
        _addMultiVar(scriptVarConsumer, code, node.right);
      }
    };
  },

  /**
     * Scans the given JavaScript code node, looking for variables created at that level;
     * not higher, not lower, just this level.
     * @param {AstTreeNode} astNode
     * @param {?string} code Raw code from which `astNode` was extracted.
     * @returns {Object.<string, ScriptVar>} An object in which properties are variable names and values are
     *          the code to the right of the last assignment for that variable.
     */
  getVariablesAtLevel(astNode, code) {
    return _getDeclaredVarsAtLevel(astNode, code);
  },

  /**
     * Scans the given JavaScript code looking for variables created at the top level.
     * @param {string} jsCode
     * @returns {Object.<string, ScriptVar>} An object in which properties are variable names and values are
     *          the code to the right of the last assignment for that variable.
     * @throws {Error} - If formula code contains a syntax error.
     */
  getTopLevelVariables(jsCode) {
    return _getDeclaredVarsAtLevel(
      _jsParse(requireString(jsCode, 'jsCode')),
      jsCode,
    );
  },
});

export default WorkflowVars;

<template>
  <div class="product-details">
    <div class="breadcrumb-module-container">
      <breadcrumb
        class="breadcrumb-module-container"
        :from-path-name="'products'"
        :to-path-name="productDetails.name"
      />
    </div>
    <div class="mds-doc mds-doc-tabs-template">
      <div class="mcd-doc__content-wrap">
        <div class="mds-doc__content">
          <div class="mds-doc__content-inner">
            <div class="mds-doc-tabs__content-wrap">
              <div
                class="mds-doc-tabs__content-pane mds-doc-tabs__content-pane--variations mds-doc-tabs__content-pane--visible"
              >
                <div class="mds-doc-tabs__content-pane-content">
                  <header
                    v-if="!productDetails.useNewProductPage"
                    id="product_header"
                    class="mds-doc__template-intro"
                  >
                    <div class="mds-doc__template-intro--inner">
                      <div class="mds-doc__template-intro--inner-centered">
                        <div class="mds-doc-long-form-text">
                          <h1>{{ productDetails.name }}</h1>
                          <ul
                            v-if="productDetails.url || productDetails.props"
                            class="mds-doc-component-status-header"
                          >
                            <li
                              v-for="(prop, index) in productDetails.props"
                              :key="index"
                            >
                              {{ prop.name }}
                              <p>
                                <code>{{ prop.value }}</code>
                              </p>
                            </li>
                            <li v-if="productDetails.url">
                              <template v-if="productDetails.urlType === 'INTERNAL'">
                                <router-link
                                  :to="{ path: productDetails.url}"
                                  target="_blank"
                                  class="mds-link"
                                  replace
                                >
                                  <span
                                    class="mds-list-group__item-text mds-list-group__item--active"
                                  >{{
                                    productDetails.urlText ? 'Request for Demo' : 'Visit Site'
                                  }}</span>
                                </router-link>
                              </template>
                              <a
                                v-else
                                :href="productDetails.url"
                                target="_blank"
                                class="mds-link"
                              >
                                <span
                                  class="mds-list-group__item-text mds-list-group__item--active"
                                >{{
                                  productDetails.urlText ? 'Request for Demo' : 'Visit Site'
                                }}</span>
                              </a>
                            </li>
                          </ul>
                          <p v-html="productDetails.info" />
                        </div>
                      </div>
                    </div>
                  </header>
                  <div
                    v-else
                    id="product_header"
                  >
                    <img
                      :src="getImgUrl(productDetails.mainImage)"
                      class="mcd-product-main-img banner"
                    >
                    <div
                      class="mds-doc-long-form-text"
                    >
                      <p v-html="productDetails.info" />
                    </div>
                  </div>
                  <div
                    v-if="!productDetails.useNewProductPage"
                    class="mcd-product-contnet"
                  >
                    <div
                      v-if="productDetails.mainImage"
                      class="mcd-product-main-img"
                    >
                      <img
                        class="mds-doc-image-with-caption__image"
                        :src="getImgUrl(productDetails.mainImage)"
                      >
                    </div>

                    <div
                      v-if="productDetails.description != ''"
                      class="mcd-product-description"
                      v-html="productDetails.description"
                    />
                    <div
                      v-if="productDetails.imageTwo"
                      class="mcd-product-main-img"
                    >
                      <img
                        class="mds-doc-image-with-caption__image"
                        :src="getImgUrl(productDetails.imageTwo)"
                      >
                    </div>
                    <div
                      v-if="productDetails.additionalDescription !== ''"
                      class="mcd-product-description"
                      v-html="productDetails.additionalDescription"
                    />
                    <div
                      v-if="productDetails.imageThree"
                      class="mcd-product-main-img"
                    >
                      <img
                        class="mds-doc-image-with-caption__image"
                        :src="getImgUrl(productDetails.imageThree)"
                      >
                    </div>
                    <div
                      v-if="hasResources"
                      class="mcd-product-resources"
                    >
                      <div
                        v-for="(resource, index) in getResources"
                        :id="'resource_' + index"
                        :key="resource.id"
                        class="mcd-product-resource-item"
                      >
                        <div
                          v-if="isValidResource(resource)"
                          class="mds-header"
                          :class="index > 0 ? 'mds-header--tertiary' : 'mds-header--secondary'"
                        >
                          <h2
                            class="mds-header__title"
                            v-html="resource.name"
                          />
                        </div>
                        <ul
                          v-if="resource.links.length > 0"
                          class="mcd-doc-list"
                        >
                          <li
                            v-for="(link, count) in resource.links"
                            :key="count"
                          >
                            <p>
                              <a
                                :href="link.url"
                                :target="link.target ? link.target:'_blank'"
                                class="mds-link mds-link--no-underline"
                                v-html="link.name"
                              />
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                  >
                    <mds-layout-grid>
                      <mds-row>
                        <mds-col
                          :cols="6"
                          class="middle-img"
                        >
                          <img
                            v-if="productDetails.imageTwo"
                            :src="getImgUrl(productDetails.imageTwo)"
                          >
                        </mds-col>
                        <mds-col
                          :cols="6"
                          class="middle-img"
                        >
                          <img
                            v-if="productDetails.imageThree"
                            :src="getImgUrl(productDetails.imageThree)"
                          >
                        </mds-col>
                      </mds-row>
                    </mds-layout-grid>
                    <div
                      v-if="productDetails.description != ''"
                      class="mcd-product-description"
                      v-html="productDetails.description"
                    />
                    <div
                      v-if="hasResources"
                      class="mcd-product-resources"
                    >
                      <div
                        v-for="(resource, index) in getResources"
                        :id="'resource_' + index"
                        :key="resource.id"
                        class="mcd-product-resource-item"
                      >
                        <div
                          v-if="isValidResource(resource)"
                          class="mds-header"
                          :class="index > 0 ? 'mds-header--tertiary' : 'mds-header--secondary'"
                        >
                          <h2
                            class="mds-header__title"
                            v-html="resource.name"
                          />
                        </div>
                        <ul
                          v-if="resource.links.length > 0"
                          class="mcd-doc-list"
                        >
                          <li
                            v-for="(link, count) in resource.links"
                            :key="count"
                          >
                            <p>
                              <a
                                :href="link.url"
                                :target="link.target ? link.target:'_blank'"
                                class="mds-link mds-link--no-underline"
                                v-html="link.name"
                              />
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      v-if="hasAdditionalResources"
                    >
                      <mds-layout-grid>
                        <mds-row>
                          <mds-col
                            v-for="resource in additionalResources"
                            :key="resource.name"
                            :cols="getColSize"
                          >
                            <div
                              class="header-5"
                            >
                              {{ resource.name }}
                            </div>
                            <ul
                              v-for="link in resource.links"
                              :key="link.name"
                              class="ul-medium"
                            >
                              <li>
                                <a
                                  :href="link.url"
                                  :target="link.target ? link.target:'_blank'"
                                  class="mds-link mds-link--no-underline"
                                  v-html="link.name"
                                />
                              </li>
                            </ul>
                          </mds-col>
                        </mds-row>
                      </mds-layout-grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import Breadcrumb from '../Layouts/Subpage/Breadcrumb';
import filterObj from '../../utils/objectUtils';

const VueScrollTo = require('vue-scrollto');

export default {
  name: 'ProductDetails',
  components: {
    Breadcrumb, MdsLayoutGrid, MdsRow, MdsCol,
  },
  props: {
    productData: { type: Object, default: null },
    allProducts: { type: Object, default: null },
  },
  data() {
    return {
      productDetails: this.productData,
      resources: [],
      additionalResources: [],
    };
  },
  computed: {
    getResources() {
      return this.resources;
    },
    hasResources() {
      return this.resources.length > 0;
    },
    hasAdditionalResources() {
      return this.additionalResources.length > 0;
    },
    getColSize() {
      return (12 / this.additionalResources.length);
    },
  },
  watch: {
    '$route.params': {
      handler(params) {
        this.updateProductDetails(params.productId);
        this.prepareResourceLinks();
        this.prepareAdditionalResourceLinks();
      },
      immediate: true,
    },
  },
  mounted() {
    this.scrollToAnchor();
  },
  updated() {
    this.scrollToAnchor();
  },
  methods: {
    scrollToAnchor() {
      this.$nextTick(() => {
        if (this.$route.hash) {
          VueScrollTo.scrollTo('#resource_0');
        } else {
          VueScrollTo.scrollTo('#product_header');
        }
      });
    },
    getImgUrl(pic) {
      return require(`../../assets/images/screenshots/${pic}`);
    },
    getConfigByURL(url) {
      return axios.get(url).then(res => res.data);
    },
    updateProductDetails(productId) {
      this.productDetails = this.allProducts[productId];
    },
    isValidResource(resource) {
      return resource.name !== '' && resource.links.length > 0;
    },
    prepareResourceLinks() {
      this.resources = [];
      this.productDetails.resources.map((resource) => {
        if (resource.linksUrl) {
          const tempResource = resource;
          this.getConfigByURL(`${resource.linksUrl}?timestamp=${new Date().getTime()}`).then(
            (response) => {
              tempResource.links = response;
              this.getCfUrls(tempResource.links);
            },
          );
        } else {
          this.resources.push(resource);
        }
        return true;
      });
    },
    prepareAdditionalResourceLinks() {
      this.additionalResources = [];
      if ('additionResources' in this.productDetails) {
        this.additionalResources = this.productDetails.additionResources;
      }
    },
    getSignedUrls(links) {
      axios.post('api/products/download', links).then((response) => {
        this.resources.push({ links: response.data, name: 'Download' });
      })
        .catch((error) => {
          console.log('Failed to get signed URL\'s, error');
        });
    },
    getCfUrls(links) {
      const cfLinks = [];
      links.forEach((objLink) => {
        objLink.url = `https://${process.env.VUE_APP_STATIC_RESOURCES_S3_BUCKET_CF_URL}/${objLink.url}`;
        cfLinks.push(objLink);
      });
      this.resources.push({ links: cfLinks, name: 'Download' });
    },
    DashboardOrDataCatalogLink(productName) {
      if (productName === 'Dashboard') {
        return '/dashboard';
      }
      return '/catalog';
    },
    getNavProducts() {
      return filterObj(this.allProducts, item => item.hideInNavigation === true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/product_page';
@import '@mds/typography';

.banner {
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 20px;
}

.middle-img {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
}

.header-5 {
  @include mds-level-5-heading($bold: true);
  margin-bottom: 5px;
}

.ul-medium {
  @include mds-unordered-list(medium);
}
</style>

const state = {
  configList: [],
  configDetails: {
    name: '',
    yellowKey: '',
    account: '',
    feedName: '',
    status: 'ACTIVE',
    description: '',
    instruments: [{
      name: '', curve: '', tenor: '', yellowkey: '',
    }],
  },
  accountList: [],
  yellowKeyList: [],
  pageStatus: 'LOADING',
  errorMessage: '',
  yellowKeyMappings: [],
};
export default state;

<template>
  <div class="cmd-banner">
    <template v-if="screenWidth > 550">
      <mds-page-shell
        ref="banner"
        :logo="require('../../assets/images/common/Mstar-logo-50px.svg')"
        :logo-responsive="require('../../assets/images/common/Mstar-logo-50px.svg')"
        logo-href="#"
        logo-alt="Back to home page"
      >
        <template #mds-page-shell-masthead-right>
          <mds-button-container>
            <mds-button
              id="client-login"
              variation="secondary"
              type="button"
              @click="toggleLoginModal"
            >
              {{ $t('labels.home.labels.signIn') }}
            </mds-button>

            <mds-button
              variation="secondary"
              href="https://www.zema.global/contact-us"
            >
              {{ $t('labels.home.labels.getStarted') }}
            </mds-button>
            <mds-button
              id="popover-help"
              variation="secondary"
              type="button"
              @click.stop="(showHelp=!showHelp)"
            >
              Help
            </mds-button>
          </mds-button-container>
        </template>
      </mds-page-shell>
    </template>
    <template v-else>
      <mds-page-shell
        ref="banner"
        :logo="require('../../assets/images/common/Mstar-logo-50px.svg')"
        :logo-responsive="require('../../assets/images/common/Mstar-logo-50px.svg')"
        logo-href="#"
        logo-alt="Back to home page"
      >
        <template #mds-page-shell-masthead-right>
          <mds-button-container>
            <mds-button
              id="client-login"
              variation="icon-only"
              icon="person"
              type="button"
              @click="toggleLoginModal"
            >
              {{ $t('labels.home.labels.signIn') }}
            </mds-button>
            <mds-button
              variation="icon-only"
              icon="person-plus"
              type="button"
              href="https://www.zema.global/contact-us"
            >
              {{ $t('labels.home.labels.getStarted') }}
            </mds-button>
            <mds-button
              id="popover-help"
              variation="icon-only"
              icon="question-circle"
              type="button"
              @click.stop="(showHelp=!showHelp)"
            />
          </mds-button-container>
        </template>
      </mds-page-shell>
    </template>
    <mds-popover
      triggered-by="popover-help"
      :visible="showHelp"
      :position="['bottom-left']"
      class="settings-popover"
      width="200px"
      fixed
    >
      <mds-list-group>
        <div class="popover-heading">
          {{ $t('navigation.help') }}
        </div>
        <mds-list-group-item
          @mds-list-item-clicked="(showWhatsNew=!showWhatsNew) && (showHelp=!showHelp)"
        >
          <mds-button
            variation="flat"
          >
            {{ $t('navigation.whatsNewText') }}
          </mds-button>
        </mds-list-group-item>
        <mds-list-group-item>
          <mds-button
            id="footer__visit-us"
            :href="corporateSite"
            variation="flat"
          >
            {{ $t('navigation.visitUsText') }}
          </mds-button>
        </mds-list-group-item>
        <mds-list-group-item>
          <mds-button
            id="footer__contact-us"
            href="mailto:marketplace-support@ze.com"
            variation="flat"
          >
            {{ $t('navigation.contactUsText') }}
          </mds-button>
        </mds-list-group-item>
      </mds-list-group>
    </mds-popover>
    <transition name="slide">
      <template v-if="showWhatsNew">
        <whats-new />
      </template>
    </transition>
    <mds-modal
      v-model="toggle"
      title="User Login"
      class="modal-login"
      aria-label="Default Modal"
    >
      <login-page />
    </mds-modal>
  </div>
</template>

<script>
import MdsPageShell from '@mds/page-shell';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsModal from '@mds/modal';
import LoginPage from '@/components/Login/LoginPage.vue';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group-v4';
import MdsPopover from '@mds/popover';
import WhatsNew from '@/components/Common/WhatsNew.vue';
import { CORPORATE_SITE } from '@/utils/constants.js';
import EventBus from '../../main';

export default {
  name: 'Banner',
  components: {
    MdsPageShell,
    MdsButton,
    MdsButtonContainer,
    MdsModal,
    LoginPage,
    MdsListGroup,
    MdsListGroupItem,
    MdsPopover,
    WhatsNew,
  },
  data() {
    return {
      toggle: false,
      screenWidth: window.innerWidth,
      showHelp: false,
      showWhatsNew: false,
      corporateSite: CORPORATE_SITE,
    };
  },
  created() {
    window.addEventListener('resize', this.handleScreenWidth);
    EventBus.$on('hideWhatsNew', this.hideWhatsNew);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleScreenWidth);
  },
  methods: {
    handleScreenWidth(e) {
      this.screenWidth = window.innerWidth;
    },
    toggleLoginModal() {
      this.toggle = !this.toggle;
      EventBus.$emit('toggleLoginModal');
    },
    hideWhatsNew() {
      this.showWhatsNew = false;
    },
  },
};
</script>

const MpFormulaStepText = {

  Date: {
    relativeToRunDate: 'today, minus <INPUT> days',
    skipWeekends: 'Skip weekends',
    fixed: 'Fixed date: <INPUT>',
    dateFormat: 'M/d/yyyy',

    Validation: {
      badDate: 'Unrecognized date value: [value]',
    },
  },

  Integer: {

    Validation: {
      badVal: 'Invalid value, must be an integer.',
      badValWithMin: 'Invalid value, must be an integer no less than [min].',
    },
  },

  UserMessage: {
    enterCustomMessage: 'Custom Message:',
    tooltip: 'Optional text to append to the error message\n(for when this validation fails.)',
  },

  Type: {

    ForwardCurve: {

      product: 'Product:',
      delivery: 'Delivery:',
      curveType: 'Method:',
      curveDate: 'Curve date:',

      arbitrage: 'Plain/Arbitrage',
      arbitrageFree: 'Arbitrage-free',
      arbitrageFreeBasic: 'Basic',
      showNans: 'Include NaNs',

      DeliveryType: {
        '30-minute': '30-Minute',
        hour: 'Hourly',
        day: 'Daily',
        week: 'Weekly',
        month: 'Monthly',
        quarter: 'Quarterly',
        season: 'Seasonal',
        year: 'Annual/Yearly',
      },

      Validation: {
        noProduct: 'A product must be selected.',
      },
    },

    TimeSeries: {
      product: 'Product:',
      howFarBack: 'How far back:',
      isIntraday: 'Intraday data',
      timeZone: 'Time zone:',
      timeZoneSelectOne: '(for intraday data only)',

      TimeUnit: {
        h: 'hours',
        d: 'days',
        D: 'business days',
        M: 'months',
        y: 'years',
      },

      Validation: {
        noProduct: 'A product must be selected.',
      },
    },

    BasicMath: {
      math: 'Computation:',

      Validation: {
        isEmpty: 'A computation formula must be provided.',
        unexpectedError: "An unexpected error occured while validating your formula.  Check your browser's logs for details.",
      },
    },

    FreeText: {
      text: 'Code:',

      Validation: {
        isEmpty: 'Free text JS code must be provided.',
      },
    },
    Extrapolation: {
      selectTimeSeries: 'Curve:',
      howTo: 'Method:',
      stopAt: 'Stop at:',

      Method: {
        FOLLOW_DATASET: 'Use other curve as model',
        REPEAT_LAST_CYCLE: 'Repeat last cycle',
      },

      Validate: {
        noVar: 'A curve must be selected.',
      },

      FollowDataset: {
        otherTimeSeries: 'Model:',
        extendTo: 'Extend:',
        datasetLength: 'To length of model curve',
        fixedLength: 'Until curve length reaches <INPUT>',
        fixedDate: 'To: <INPUT>',

        Validation: {
          noOther: 'A Model Curve must be selected.',
          mustDiffer: 'Curve and Model Curve must be different.',
        },
      },

      RepeatLastCycle: {
        numRepeat: 'Cycle size:',
        numRepeatSuffix: 'data-points',
        delivery: 'Delivery:',
        stopDate: 'Up to:',

        Calendar: {
          YEAR: 'annual',
          QUARTER: 'quarter',
          MONTH: 'month',
          DAY: 'day',
          WEEKDAY: 'business day',
          HOUR: 'hour',
        },
      },
    },

    Merge: {
      howTo: '',
      union: 'Union - all dates',
      intersection: 'Intersection - only dates found in all curves',
      complement: 'Complement - preserve first curve',
      overwrite: 'Overwrite - overwrite first curve',
      widthWise: 'Align side-by-side, add columns:',
      lengthWise: 'Extend, add dates:',
      base: 'Into:',
      next: 'Merge:',
      oneMore: '+',
      oneLess: 'x',
      subsequent: 'And:',

      Validation: {
        noCurve: 'There are no curves to merge.',
      },
    },

    Fill: {
      selectTimeSeries: 'Curve:',
      fillForward: 'Forward',
      fillBackward: 'Backward',

      Validate: {
        noVar: 'A curve must be selected.',
      },

    },

    MissingData: {
      selectTimeSeries: 'Curve:',
      minCount: 'Contains:',
      minCountText: 'At least <INPUT> numeric values.',

      Validation: {
        noVar: 'A curve must be selected.',
      },
    },

    Spike: {
      selectTimeSeries: 'Curve:',
      type: 'Comparison:',
      sideBySide: 'Side-by-side (two curves)',
      linearity: 'Linearity (one curve)',
      compareWith: 'Compare with:',
      maxChange: 'Spike Value:',

      Validation: {
        noVar: 'A curve must be selected.',
        badMaxChg: 'Invalid value "[value]" must be a positive number (percentage are allowed).',
        noCompareWith: 'A data-set must be selected for comparison.',
        sameCompareWith: 'Curve and Compare With must be different.',
      },
    },

    InRange: {
      selectTimeSeries: 'Curve:',
      low: 'Lowest:',
      high: 'Highest:',

      Validation: {
        noVar: 'A curve must be selected.',
        badLow: 'Invalid value, Lowest must be a number.',
        badHigh: 'Invalid value, Highest must be a number.',
        lowIsHigher: 'Lowest value must be smaller than Highest.',
      },
    },
  },
};

export default MpFormulaStepText;

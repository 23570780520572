import Strings, { requireString, requireNonEmptyString } from '../strings.es6';
import Objects from '../objects.es6';

/**
 * A Marketplace root, for futures contracts.
 */
class MpRoot {
  /**
     * @param name {string} Root name.
     * @param descr {string} Root description.
     */
  constructor(name, descr) {
    this._name = requireNonEmptyString(name, 'name');
    this._descr = requireString(descr, 'descr');
    Object.freeze(this);
  }

  /** @returns {string} Root name. */
  name() { return this._name; }

  /** @returns {string} Root description. */
  description() { return this._descr; }

  /**
     * Returns whether *that* is a MpRoot object that represents
     * the same root.
     *
     * This comparison does not account for feed;
     * two roots from different feeds are considered equal if they have the same name.
     *
     * @param that {*}
     * @returns {boolean} True if `that` is a root with the same (case-sensitive) name.
     */
  equals(that) {
    return ((that instanceof MpRoot)
                && this._name === that._name);
  }

  /**
     * Returns whether `r1` and `r2` are two instances of MpRoot
     * that represent the same root.  Returns *false* if either
     * object is not an instance of MpRoot.
     *
     * This comparison does not account for feed;
     * two roots from different feeds are considered equal if they have the same name.
     *
     * @param {(MpRoot|*)} r1
     * @param {(MpRoot|*)} r2
     * @returns {boolean} Whether `r1` and `r2` are two roots of the same name.
     */
  static areEqual(r1, r2) {
    return ((r1 instanceof MpRoot)
                && r1.equals(r2));
  }

  /**
     * Factory method to construct a new instance from a given name.
     * @param {string} name - Name given to this root, must not be empty.
     * @param {string} [descr=""] - Description for the root.
     * @returns {MpRoot}
     */
  static fromName(name, descr) {
    return new MpRoot(
      requireNonEmptyString(name, 'name'),
      ((arguments.length > 1) ? requireString(descr, 'descr') : ''),
    );
  }
}

_.extend(MpRoot, /** @lends MpRoot */ {

  /**
     * Compares two MpRoot objects for the purpose of sorting them by name.
     *
     * Objects that are not MpRoot will be pushed at the end of the sort-order.
     *
     * @param r1 {*}
     * @param r2 {*}
     * @returns {number}
     *
     * @method
     */
  compare: Objects.newNullableComparator(
    MpRoot,
    (r1, r2) => Strings.compareSequenced(r1._name, r2._name),
  ),
});

export default MpRoot;

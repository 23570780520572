const config = {
  GET_SCHEDULER_LIST_API: 'api/schedule/schedulerList',
  SCHEDULER_DELETE_BY_NAME_API: 'api/schedule/deleteScheduleByName',
  SCHEDULER_ENABLE_DISABLE_API: 'api/schedule/enableScheduleByName',
  GET_PUB_LIST_API: 'api/schedule/publist',
  SCHEDULER_CREATE_API: 'api/schedule/createNew',
  SCHEDULER_UPDATE_API: 'api/schedule/updateScheduleByName',
  SCHEDULER_UPDATE_BY_NAME_API: 'api/schedule/modifyScheduleName',
  SCHEDULER_COPY_TO_NEW_SCH_API: 'api/schedule/copyToNewSch',
  SCHEDULER_COPY_TO_EXISTING_API: 'api/schedule/copyToExistingSch',
  FETCH_USER_PUBLIST_FILE_TYPE_API: 'api/schedule/userpublistfiletype',
  FETCH_ACTIVE_USER_FOR_COMPANY_API: 'api/schedule/fetchActiveUsers',
};

export default function getAPIConfigs() {
  return config;
}

<template>
  <div class="sub-page-content">
    <component
      :is="dynamicComponent"
      :product-data="productData"
      :all-products="allProducts"
    />
  </div>
</template>

<script>
import Breadcrumb from '../components/Layouts/Subpage/Breadcrumb';
import ProductDetails from '../components/Subpages/ProductPage';

export default {
  name: 'ContentContainer',
  components: {
    Breadcrumb,
    ProductDetails,
  },
  props: {
    productId: { type: String, default: '' },
  },
  data() {
    return {
      productData: {},
      allProducts: {},
      dynamicComponent: '',
    };
  },
  computed: {
    fromPath() {
      return this.$route.path.split('/')[1];
    },
  },
  created() {
    const vm = this;
    if (this.fromPath === 'all') { // work around since all content container is only used in products page
      const allNestedData = this.$t('allHomeData');
      const allFlatData = {};
      Object.keys(allNestedData).forEach((key) => {
        Object.assign(allFlatData, allNestedData[key]);
      });
      this.productData = allFlatData[`${this.productId}`];
      this.allProducts = allFlatData;
      if (!this.productData) {
        this.$router.push('/all');
      }
    } else {
      this.productData = this.$t(`allHomeData.${this.fromPath}.${this.productId}`);
      this.allProducts = this.$t(`allHomeData.${this.fromPath}`);
    }
    if (this.productData.componentType) {
      vm.$data.dynamicComponent = this.productData.componentType;
    }
  },
};
</script>

<template>
  <div>
    <div class="custom-header-ent border-bottom-solid break-word">
      {{ $t('labels.dataAlert.savedFeeds') }}
    </div>
    <mds-layout-grid class="entitlement-container">
      <mds-row
        v-for="(entitlement, index) in feedEntitlements"
        :key="entitlement.text + '_' + index"
        class="custom-border"
      >
        <mds-col
          :cols="8"
          :cols-at-m="11"
        >
          {{ entitlement.text }}
        </mds-col>
        <mds-col
          :cols="4"
          :cols-at-m="1"
        >
          <a class="ic-add">
            <mds-icon
              name="remove--s"
              title="favorite"
              class="checkbox-inline"
              @click="removeEntitlements(entitlement)"
            />
          </a>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
    <div>
      <span class="pull-left margin-top-10">
        <button
          id="cancelConfigurationsButton"
          class="mds-button mds-button--primary test font-size-small"
          type="button"
          @click="hideEntitlements"
        >
          {{ $t('labels.group.groupBackButton') }}
        </button>
      </span>
      <span class="pull-right margin-top-10">
        <button
          id="saveConfigurationsButton"
          class="mds-button mds-button--primary test font-size-small"
          type="button"
          @click="showEntitlementsPopUp"
        >
          {{ $t('labels.dataAlert.saveConfiguration') }}
        </button>
      </span>
    </div>
    <entitlements-pop-over
      :feed-entitlements="feedEntitlements"
      :initial-feed-entitlements="initialFeedEntitlements"
      :removed-list="removedList"
    />
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import EventBus from '../../main';
import EntitlementsPopOver from './EntitlementsPopOver.vue';

export default {
  name: 'EntitledFeed',
  components: {
    EntitlementsPopOver, MdsLayoutGrid, MdsRow, MdsCol,
  },
  props: {
    feedEntitlements: {
      type: Array,
      default: () => [],
    },
    initialFeedEntitlements: {
      type: Array,
      default: () => [],
    },
    removedList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    hideEntitlements() {
      EventBus.$emit('hideOverLay', false);
      EventBus.$emit('hideFeedEntitlements', false);
    },
    showEntitlementsPopUp() {
      EventBus.$emit('saveEntitlements', EntitlementsPopOver);
    },
    removeEntitlements(entitlement) {
      EventBus.$emit('removeEntitlements', entitlement);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';

  .custom-header-ent {
    @include mds-level-3-heading($bold: true);
    font-weight: 300;
    margin-bottom: 10px;
    margin-top: 20px;
    color: #162b3d;
  }
</style>

<template>
  <div>
    <div>
      <mcd-card-section
        item-type="allHomeData"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import McdCardSection from '../components/McdCard/McdCardSection';

export default {
  name: 'All',
  components: { McdCardSection },
  computed: {
    ...mapState({
      isAuthenticated: state => state.loginModule.isAuthenticated,
    }),
  },
};
</script>

<style scoped>

</style>

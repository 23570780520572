<template>
  <div>
    <div
      v-if="dialogType !== '' || overlay"
      id="overlayDiv"
      class="overlay"
    />
    <template v-if="currentStatus == 'SUCCESS'">
      <div class="mds-header border-bottom-solid-details">
        <h1 class="mds-header__title component-title">
          <small>{{ config.name }}</small>
        </h1>
        <div class="mds-header__actions">
          <mds-button
            v-if="userAdminRoles.length > 0"
            variation="flat"
            icon="trash"
            @click="displayDialogBox('deleteBox')"
          >
            {{ $t('labels.common.delete') }}
          </mds-button>
          <router-link
            :to="{ path: editRoute }"
            class="mds-list-group__link"
            replace
          >
            <mds-button
              variation="flat"
              icon="pencil"
            >
              {{ $t('labels.bbLoader.editConfig') }}
            </mds-button>
          </router-link>
          <router-link
            :to="{ path: '/bb' }"
            class="mds-list-group__link hide-actions"
            replace
          >
            <button
              class="mds-button mds-button--flat"
              type="button"
            >
              <svg
                class="mds-icon mds-button__icon mds-button__icon--left"
                aria-hidden="true"
              >
                <use xlink:href="#list" />
              </svg>
              <span class="mds-button__text mcd-page__content-hide--600">
                {{
                  $t('labels.bbLoader.configList')
                }}
              </span>
            </button>
          </router-link>
        </div>
      </div>

      <div class="feed-description">
        <h2 class="custom-header alignment font-size-larger">
          {{ $t('labels.common.status') }}
        </h2>
        <p class="para-margin-top">
          {{ config.status }}
        </p>
      </div>

      <div class="feed-description">
        <h2 class="custom-header alignment font-size-larger">
          {{ $t('labels.common.description') }}
        </h2>
        <p class="para-margin-top">
          {{ config.desc || $t('labels.common.na') }}
        </p>
      </div>

      <div class="feed-description">
        <h2 class="custom-header alignment font-size-larger">
          {{ $t('labels.bbLoader.accountName') }}
        </h2>
        <p
          class="para-margin-top"
        >
          {{ config.account_name == null ? $t('labels.bbLoader.accountNotLinked') : config.account_name }}
        </p>
      </div>

      <div class="feed-description">
        <h2 class="custom-header alignment font-size-larger">
          {{ $t('labels.bbLoader.yellowKey') }}
        </h2>
        <p class="para-margin-top">
          {{ config.yellowkey }}
        </p>
      </div>

      <div class="feed-description">
        <h2 class="custom-header alignment font-size-larger">
          {{ $t('labels.feed.feedName') }}
        </h2>
        <p class="para-margin-top">
          {{ config.feed_name }}
        </p>
      </div>

      <div>
        <div class="mds-accordion">
          <input
            id="instrumentsAccordion"
            type="checkbox"
            role="button"
            aria-controls="intAccordionData"
            class="mds-accordion__trigger"
          >
          <label
            for="instrumentsAccordion"
            class="mds-accordion__label"
          >
            <div class="mds-header mds-header--secondary mds-header--level-3">
              <h2 class="mds-header__title ent-header">{{ $t('labels.bbLoader.instruments') }}</h2>
            </div>
          </label>
          <section
            id="intAccordionData"
            class="mds-accordion__content"
          >
            <template v-if="config.instruments && config.instruments.length > 0">
              <table class="mds-data-table">
                <thead class="mds-data-table__header">
                  <tr class="mds-data-table__row">
                    <th
                      class="mds-data-table__header-cell"
                      width="30%"
                      scope="col"
                    >
                      <div
                        class="mds-data-table__header-cell-inner"
                      >
                        {{ $t('labels.bbLoader.instrumentName') }}
                      </div>
                    </th>
                    <th
                      class="mds-data-table__header-cell"
                      width="30%"
                      scope="col"
                    >
                      <div
                        class="mds-data-table__header-cell-inner mds-data-table__header-cell-inner--right"
                      >
                        {{ $t('labels.bbLoader.curveName') }}
                      </div>
                    </th>
                    <th
                      class="mds-data-table__header-cell"
                      width="20%"
                      scope="col"
                    >
                      <div
                        class="mds-data-table__header-cell-inner mds-data-table__header-cell-inner--right"
                      >
                        {{ $t('labels.bbLoader.tenor') }}
                      </div>
                    </th>
                    <th
                      class="mds-data-table__header-cell"
                      width="20%"
                      scope="col"
                    >
                      <div
                        class="mds-data-table__header-cell-inner mds-data-table__header-cell-inner--right"
                      >
                        {{ $t('labels.bbLoader.yellowKey') }}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody class="mds-data-table__body">
                  <tr
                    v-for="instrument in config.instruments"
                    :key="instrument.name"
                    class="mds-data-table__row"
                  >
                    <td
                      class="mds-data-table__cell entitlement-body"
                      scope="row"
                    >
                      {{ instrument.name }}
                    </td>
                    <td
                      class="mds-data-table__cell mds-data-table__cell--right"
                    >
                      {{ instrument.curve }}
                    </td>
                    <td
                      class="mds-data-table__cell mds-data-table__cell--right"
                    >
                      {{ instrument.tenor }}
                    </td>
                    <td
                      class="mds-data-table__cell mds-data-table__cell--right"
                    >
                      {{ instrument.yellowkey }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>

            <template v-else-if="config.instruments && config.instruments.length == 0">
              <mds-field-error
                id="error-id"
                size="large"
                visible
              >
                {{ $t('labels.bbLoader.noInstruments') }}
              </mds-field-error>
            </template>
          </section>
        </div>

        <div class="mds-accordion">
          <input
            id="configInfoAccordion"
            type="checkbox"
            role="button"
            aria-controls="configInfoAccordionData"
            class="mds-accordion__trigger"
          >
          <label
            for="configInfoAccordion"
            class="mds-accordion__label"
          >
            <div class="mds-header mds-header--secondary mds-header--level-3">
              <h2 class="mds-header__title ent-header">{{ $t('labels.common.moreInformation') }}</h2>
            </div>
          </label>
          <section
            id="feedInfoAccordionData"
            class="mds-accordion__content"
          >
            <table class="mds-data-table">
              <th id="empty" />
              <tbody class="mds-data-table__body">
                <template v-for="(value, key, index) in config">
                  <tr
                    v-if="!excludedInfoItems.includes(key)"
                    :key="index"
                    class="mds-data-table__row"
                  >
                    <td
                      class="mds-data-table__cell"
                      scope="row"
                    >
                      {{ getKeyLabel(key) }}
                    </td>

                    <td
                      class="mds-data-table__cell custom-text-align"
                    >
                      {{ value == false ? 'No' : value == true ? 'Yes' : value }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </template>
    <template v-else-if="currentStatus == 'ERROR'">
      <div class="details-error">
        <mds-notification
          visible
          title="ERROR"
          status="error"
          dismissible="false"
        >
          {{ errorText !== '' ? errorText : errorMessage }}
        </mds-notification>
      </div>
    </template>
    <template v-else-if="currentStatus == 'LOADING'">
      <mds-loader />
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'BBConfigDetails',
  data() {
    return {
      userAdminRoles: [],
      configName: '',
      config: {},
      instrumentList: [],
      formError: false,
      errorText: '',
      dialogType: '',
      overlay: false,
      excludedInfoItems: ['name', 'desc', 'account_name', 'yellowkey', 'feed_name', 'instruments'],
      currentStatus: 'LOADING',
    };
  },

  computed: {
    ...mapGetters({
      pageStatus: 'bloombergModule/getPageStatus',
      configDetails: 'bloombergModule/configDetails',
      errorMessage: 'bloombergModule/getErrorMessage',
    }),
    editRoute() {
      return `${this.$route.fullPath }/edit`;
    },
  },
  created() {
    this.configName = this.$route.params.configName;
    this.getConfigDetails(this.configName)
      .then(() => {
        this.config = this.configDetails;
        this.overlay = false;
        this.currentStatus = 'SUCCESS';
      })
      .catch((error) => {
        this.currentStatus = 'ERROR';
        this.errorText = error.response.data.Message || '';
      });
  },
  methods: {
    ...mapActions('bloombergModule', ['getConfigDetails']),

    getKeyLabel(key) {
      return this.$t(`labels.common.${key}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/feed_details';
</style>

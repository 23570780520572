<template>
  <div class="mcd-page-shell__content mds-page-shell__content">
    <template v-if="showPageError">
      <div class="reports-error">
        <mds-notification
          visible
          title="ERROR"
          status="error"
          dismissible="false"
        >
          {{ pageError !== ''? pageError : $t('labels.bbLoader.configListError') }}
        </mds-notification>
      </div>
    </template>
    <template v-else>
      <div class="mcd-page-tabs__content-wrap">
        <div class="mcd-page-tabs__content-pane mcd-page-tabs__content-pane--visible">
          <div class="mcd-page-tabs__content-pane-content">
            <router-view />
          </div>
          <div
            v-show="overlay"
            id="overlayDiv"
            class="overlay"
          />
          <div class="mcd-page-tabs__content-pane-navigation">
            <div
              class="mds-doc-page-nav mds-doc-toc-template__page-nav mds-doc__page-nav--fixed"
              style="margin-top: 0px;"
            >
              <div class="mds-doc-page-nav__list-groups-wrap">
                <ul
                  class="mds-list-group mds-list-group--medium mds-list-group--with-icon mds-doc-page-nav--list-group-expanded"
                >
                  <li class="mds-list-group__item mds-list-group__item--header">
                    <span>{{ $t('labels.common.actions') }}</span>
                  </li>
                  <li
                    v-for="(action, index) in bbActions"
                    :key="index"
                    class="mds-list-group__item"
                  >
                    <router-link
                      :to="{ path: action.path }"
                      class="mds-list-group__link"
                      replace
                    >
                      <svg
                        class="mds-icon mds-list-group__left-icon"
                        aria-hidden="true"
                      >
                        <use :xlink:href="'#' + action.icon" />
                      </svg>
                      <span class="mds-list-group__item-text">{{ $t(action.name) }}</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { FEATURES } from '@/utils/constants.js';
import LayoutDefault from '../Layouts/LayoutDefault.vue';
import EventBus from '../../main';

export default {
  name: 'BloomberLoader',
  data() {
    return {
      showFeedEnt: false,
      overlay: false,
      showPageError: false,
      pageError: '',
    };
  },
  computed: {
    bbActions() {
      const actions = [];
      const actionConfigList = {
        name: 'labels.bbLoader.configList',
        icon: 'list',
        path: '/bb',
      };
      const actionNewYellowKey = {
        name: 'labels.bbLoader.addYellowKey',
        icon: 'plus',
        path: '/bb/yellowkey/new',
      };
      const actionNewAccount = {
        name: 'labels.bbLoader.addAccount',
        icon: 'plus',
        path: '/bb/account/new',
      };
      const actionNewConfig = {
        name: 'labels.bbLoader.addConfig',
        icon: 'plus',
        path: '/bb/config/new',
      };
      const actionYellowKeyList = {
        name: 'labels.bbLoader.yellowKeyList',
        icon: 'list',
        path: '/bb/yellowKey/List',
      };
      const actionAccountList = {
        name: 'labels.bbLoader.accountList',
        icon: 'list',
        path: '/bb/account/List',
      };

      if (this.$route.name !== 'BBAccountList') {
        actions.push(actionAccountList);
      }
      if (this.$route.name !== 'BBConfigList') {
        actions.push(actionConfigList);
      }

      if (this.$route.name !== 'BBYellowKeyList') {
        actions.push(actionYellowKeyList);
      }

      if (this.$route.name !== 'BBNewAccount') {
        actions.push(actionNewAccount);
      }
      if (this.$route.name !== 'BBNewConfig') {
        actions.push(actionNewConfig);
      }
      if (this.$route.name !== 'BBNewYellowKey') {
        actions.push(actionNewYellowKey);
      }

      if (this.$route.name === 'ConfigDetails') {
        actions.push(actionConfigList);
        actions.push({
          name: 'labels.feed.editEntitlements',
          icon: 'document',
          path: '/feeds/new',
        });
        actions.push({
          name: 'labels.feed.editGroups',
          icon: 'person-group',
          path: '/feeds/groups',
        });
        actions.push({ name: 'labels.feed.cloneFeed', icon: 'open-new', path: '/feeds/clone' });
      }

      return actions;
    },
  },
  created() {
    if ((this.isUserEntitled && !this.isUserEntitled([FEATURES.BLOOMBERG_LOADER]))) {
      this.showPageError = true;
      this.pageError = this.$t('labels.common.featureNotEntitled');
    }

    this.$emit('update:layout', LayoutDefault);
    EventBus.$on('hideOverLay', (value) => {
      this.overlay = value;
    });
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/feed';
</style>

import { isString, requireString, requireNonEmptyString } from '../strings.es6';
import VarDataType from './vardatatype.es6';

/**
 * A available declared within a given (workflow) script.
 */
export default class ScriptVar {
  /**
     * @param {string} name - Variable name within the script.
     * @param {EnumItem} dataType - Variable data-type.
     * @param {string} descr - Description of the variable.
     * @param {?string} [code] - Code for setting the variable (can be null).
     * @constructor
     */
  constructor(name, dataType, descr, code) {
    requireNonEmptyString(name, 'name');
    VarDataType.requireEnumOf(dataType, 'dataType');
    requireNonEmptyString(descr, 'descr');

    if (arguments.length < 4) {
      code = null;
    } else if (code !== null && !isString(code)) {
      throw new TypeError('code: String or null');
    }

    this._name = name;
    this._dt = dataType;
    this._descr = descr;
    this._code = code;
    Object.freeze(this);
  }

  /** @returns {string} Variable name. */
  name() { return this._name; }

  /** @returns {WorkflowVarDataType} Data-type of the variable's value. */
  dataType() { return this._dt; }

  /** @returns {string} Description associated with the variable. */
  description() { return this._descr; }

  /** @returns {?string} Code (snippet) of the variable's assignment. */
  code() { return this._code; }

  /**
     * @param {string} name - Variable name
     * @param {string} code - Code to the right of the '=' sign where the variable is assigned.
     * @returns {ScriptVar} New ScriptVar with name and assignment-code; data-type=UNKNOWN, description="N/A".
     */
  static fromCode(name, code) {
    return new ScriptVar(
      requireNonEmptyString(name, 'name'),
      VarDataType.UNKNOWN,
      'N/A',
      requireString(code, 'code'),
    );
  }
}

/**
 * @param {(ScriptVar|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is a ScriptVar object.
 */
export function isScriptVar(arg) {
  return (arg instanceof ScriptVar);
}

/**
 * Validates that `arg` is a ScriptVar object.
 * @param {ScriptVar} arg Argument to validate.
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {ScriptVar} Always returns `arg`.
 * @throws {TypeError} If `arg` is not a ScriptVar object.
 */
export function requireScriptVar(arg, argName) {
  if (!isScriptVar(arg)) {
    throw new TypeError(`${argName }: ScriptVar`);
  }
  return arg;
}

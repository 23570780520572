<template>
  <div :ref="`${title.trim()}`">
    <mds-smart-row-wide>
      <DailyTable
        slot="firstCard"
        class="table-container"
        :active-tab-id="activeTabId"
        :selected-morningstar-type="selectedMorningstarCol.substring(0,4)"
        :selected-column-type="selectedDataType"
        :api-data="apiData"
      />
      <div
        slot="secondCard"
        class="chart-container"
      >
        <mds-form
          size="small"
          class="chart-widget-form-spacer"
        >
          <mds-button-group
            :on-dark="darkMode"
            class="intervals-btn-group flex-item"
            full-width
            :content="btnGroups"
            :size="'small'"
            @mds-button-group-item-active="handleIntervalToggle"
          />
          <data-analytics-chart-section
            class="flex-item"
            :chart-data="TSData"
            :dark-mode="darkMode"
            :loading-status="loadingStatus"
          />
        </mds-form>
        <daily-averages-table
          :selected-column-type="selectedDataType"
          :future-date="FutureDate"
          :two-days-ago-date="twoDaysAgoDate"
          :eighty-five-minutes-ago-date="eightyFiveMinutesAgoDate"
          :morningstar-data="apiData"
          :chart-loading-status="loadingStatus"
          :active-tab-id="activeTabId"
        />
      </div>
    </mds-smart-row-wide>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import MdsForm from '@mds/form';
import MdsButtonGroup from '@mds/button-group';
import { mapGetters } from 'vuex';
import DataAnalyticsChartSection from '@/components/Dashboard/Widgets/ChartWidget/DataAnalyticsChartSection';
import splunkLogger from '@/utils/splunkLogger';
import DailyAveragesTable from '@/components/Dashboard/Workspaces/DailyAveragesTable';
import DailyTable from '@/components/Dashboard/Workspaces/DailyTable';
import MdsSmartRowWide from '@/components/Dashboard/MdsSmartRowWide';
import { ThemeMixin } from '../../../../../mixins/themeMixin';
import { getUserName } from '../../../../../utils/authService';
import EventBus from '../../../../../main';


export default {
  name: 'ForecastZoneChartWidget',
  components: {
    DailyAveragesTable,
    DataAnalyticsChartSection,
    MdsForm,
    MdsButtonGroup,
    DailyTable,
    MdsSmartRowWide,
  },
  mixins: [ThemeMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    activeTabId: {
      type: String,
      default: '',
    },
    selectedMorningstarCol: {
      type: String,
      default: '',
    },
    apiData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      timeSeriesArray: null,
      loadingStatus: '',
      selectedGraphType: 'line',
      btnGroups: [
        {
          id: 'HCSM_Value',
          text: 'Base Case with CART',
          active: true,
        },
        {
          id: 'HRSM_Value',
          text: 'Base Case',
          active: false,
        },
        {
          id: 'CIBOT',
          text: 'Lower Case',
          active: false,
        },
        {
          id: 'CITOP',
          text: 'Upper Case',
          active: false,
        },
      ],
      selectedDataType: 'HCSM_Value',
      lastUpdateMstr: '',
      lastUpdateVendor: '',
      vendorName: '',
      selectedSymbols: [],
      intervalID: '',
      hover: false,
      FutureDate: '',
      twoDaysAgoDate: '',
      eightyFiveMinutesAgoDate: '',
    };
  },
  computed: {
    TSData() {
      return this.timeSeriesArray;
    },
  },
  watch: {
    selectedDataType: {
      handler(val, oldVal) {
        this.selectedDataType = val;
        this.fetchChartData();
      },
      nextTick: true, // refresh in the next repaint
    },
  },
  mounted() {
    this.fetchChartData();
    // EventBus.$on(`update-${this.id}`, this.handleNewData);
    EventBus.$on(`update-${this.id}`, (value) => {
      this.handleNewData(value);
    });
  },
  unmounted() {
    clearTimeout(this.intervalID);
  },
  methods: {
    handleNewData(newArray) {
      this.timeSeriesArray.y1 = [...this.timeSeriesArray.y1, ...newArray];
    },
    handleIntervalToggle(e) {
      // clearTimeout(this.intervalID);
      this.btnGroups.forEach((item) => {
        if (item.id === e.target.id) {
          item.active = true;
          splunkLogger([{
            widgetName: this.title,
          }, { itemClicked: item.id }, { featureName: 'data_analytics' }]);
          this.selectedDataType = item.id;
        } else {
          item.active = false;
        }
        this.$emit('colEmitter', {
          id: this.title.replaceAll(' ', '_'),
          username: getUserName(),
          selectedColumn: this.selectedDataType,
        });
      });
    },
    fetchChartData() {
      try {
        this._getTimeSeries();
      } catch (e) {
        this.loadingStatus = 'Error';
      }
    },
    _getTimeSeries(isRefresh = false) {
      if (isRefresh) {
        this.loadingStatus = 'Refreshing';
      } else {
        this.loadingStatus = 'Loading';
      }

      this.FutureDate = moment().add(6, 'day').format('YYYY-MM-DD');
      this.twoDaysAgoDate = moment().subtract(2, 'day').format('YYYY-MM-DD');
      this.eightyFiveMinutesAgoDate = moment().utc().subtract(1, 'hour').subtract(25, 'minutes')
        .format();

      let timezone;
      if (this.activeTabId === 'PJM') {
        timezone = 'america/new_york';
      } else {
        timezone = 'america/chicago';
      }
      const urlsArr = [
        `/api/getTimeSeries/?feedName=${this.apiData[0].feedName}&timezone=${timezone}&rollupStrategy=hour&fromDateTime=${this.eightyFiveMinutesAgoDate}&toDateTime=${this.FutureDate}&cols=${this.selectedDataType}&${this.spread(this.apiData[0].keys)}&insertTimes=true&version=v2`, // eslint-disable-line max-len
        `/api/getTimeSeries/?feedName=${this.apiData[1].feedName}&timezone=${timezone}&rollupStrategy=hour&fromDateTime=${this.eightyFiveMinutesAgoDate}&toDateTime=${this.FutureDate}&cols=${this.apiData[1].column}&${this.spread(this.apiData[1].keys)}&insertTimes=true&version=v2`, // eslint-disable-line max-len
        `/api/getTimeSeries/?feedName=${this.apiData[2].feedName}&timezone=${timezone}&rollupStrategy=hour&fromDateTime=${this.twoDaysAgoDate}&toDateTime=${this.FutureDate}&cols=${this.apiData[2].column}&${this.spread(this.apiData[2].keys)}&version=v2`, // eslint-disable-line max-len
        `/api/getTimeSeries/?feedName=${this.apiData[3].feedName}&timezone=${timezone}&rollupStrategy=hour&fromDateTime=${this.twoDaysAgoDate}&toDateTime=${this.FutureDate}&cols=${this.apiData[3].column}&${this.spread(this.apiData[3].keys)}&version=v2`, // eslint-disable-line max-len
        `/api/getTimeSeries/?feedName=${this.apiData[4].feedName}&timezone=${timezone}&rollupStrategy=hour&fromDateTime=${this.twoDaysAgoDate}&toDateTime=${this.FutureDate}&cols=${this.apiData[4].column}&${this.spread(this.apiData[4].keys)}&version=v2`, // eslint-disable-line max-len
      ];

      axios.all(urlsArr.map(l => axios.get(l)))
        .then(axios.spread((...res) => {
          // extracting last update insertTime of morningstar api res and formatting the datetime
          this.lastUpdateMstr = moment(res[0].data[res[0].data.length - 1]['lastInsertTime']).format('YYYY-MM-DD HH:mm:ss');
          // extracting last update insertTime of vendor api res and formatting the datetime
          this.lastUpdateVendor = moment(res[1].data[res[1].data.length - 1]['lastInsertTime']).format('YYYY-MM-DD HH:mm:ss');
          this.vendorName = res[1].data[0]['name'];
          this.$emit('lastUpdateTimeEvent',
            {
              lastUpdateMstr: this.lastUpdateMstr,
              lastUpdateVendor: this.lastUpdateVendor,
              vendorName: this.vendorName,
            });
          // all requests are now complete
          // merge both objs to be in one for the chart
          const merged = res.reduce((acc, item) => {
            // acc = { ...acc, ...item.data };
            acc.push(...item.data);
            return acc;
          }, []);

          this.timeSeriesArray = { y1: [...merged] };

          this.loadingStatus = 'Success';
          // if its not the first window we use Refresh
          this.intervalID = setTimeout(this._getTimeSeries.bind(this, true), 300000);
        }))
        .catch((error) => {
          console.log(error);
        });
    },
    spread(itemKeys) {
      const entires = Object.entries(itemKeys);
      let str = '';
      entires.forEach((item, index) => {
        if (index > 0) {
          str = `${str }&`;
        }
        str = `${str + item[0] }=${ item[1]}`;
      });
      return str;
    },
  },
};
</script>

<style lang="scss">
.flex-item{
  flex:1;
  margin:0 25px 25px 0;
  padding: 40px 0 0 40px;
}
.chart-widget-form-spacer{
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  justify-content: space-around;
}
.chart-controls-container{
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px;
  margin: 0 10px;
}

</style>

<template>
  <div class="wrapper-column--key">
    <mds-section
      class="section-wrapper"
      title="Keys"
      border="tertiary"
      border-position="bottom"
    >
      <template
        v-if="check === false"
        #mds-section-actions
      >
        <mds-pagination
          v-if="totalItems"
          :total-items="totalItems"
          :page-size="pageSize"
          :page-sizes="[50]"
          :page="page"
          :icon-only="true"
          size="small"
          @mds-pagination-page-changed="onPaginationPageChanged"
        />
      </template>
      <mds-search-field
        v-model="searchKeyInput"
        placeholder="Search for Keys"
        label="Search"
        class="clickable-search-field"
        @keyup.enter.native="search"
        @keyup.native="clearStateToKeys(searchKeyInput)"
        @mds-search-field-input-cleared="clearSearchBar"
      />
    </mds-section>
    <div class="list-content-wrapper">
      <mds-table
        class="list-content"
        size="medium"
        multiselection
        row-hover
      >
        <mds-thead>
          <mds-th
            v-for="(header, index) in headers"
            :key="index"
          >
            {{ header.text }}
          </mds-th>
        </mds-thead>
        <mds-tbody>
          <mds-tr
            v-for="(key, index) in searchResultsOrKeys"
            :key="key.Id"
            :checked="key.checked"
            class="clickable"
            :class="key.Id"
            @change.native="handleRowSelectEvent(index, key, $event)"
            @click.native="changeClass($event)"
          >
            <mds-td
              v-for="(header, i) in headers"
              :key="i"
              @click.native="onClick(key)"
            >
              {{ key[header.fieldName] }}
            </mds-td>
          </mds-tr>
        </mds-tbody>
      </mds-table>
    </div>
  </div>
</template>
<script>
import MdsSearchField from '@mds/search-field';
import MdsSection from '@mds/section';
import {
  MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr,
} from '@mds/data-table';
import MdsPagination from '@mds/pagination';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';


export default {
  name: 'FeedKey',
  components: {
    MdsPagination,
    MdsSection,
    MdsSearchField,
    MdsTable,
    MdsTh,
    MdsThead,
    MdsTbody,
    MdsTr,
    MdsTd,
  },
  data() {
    return {
      feedName: '',
      page: 1,
      pageSize: 50,
      searchKeyInput: '',
      check: false,
      loading: false,
      previousNode: '',
    };
  },
  computed: {
    ...mapGetters({
      basket: 'dashboardModule/allBasket',
      keys: 'dashboardModule/allKeys',
      totalItems: 'dashboardModule/allItems',
      columns: 'dashboardModule/allCols',
      keySearch: 'dashboardModule/allKeySearchRes',
    }),
    // creates headers and map them
    headers() {
      let uniqueKeys = [];
      // headers and keys
      if (uniqueKeys.length === 0 && this.check !== true) {
        uniqueKeys = Object.keys(this.keys.reduce((result, obj) => Object.assign(result, obj), {}));
        // headers and key search result
      } else if (uniqueKeys.length === 0 && this.check === true && this.loading === false) {
        uniqueKeys = Object.keys(this.keySearch.reduce((result, obj) => Object.assign(result, obj), {}));
      }
      uniqueKeys = uniqueKeys.filter(item => item !== 'checked' && item !== 'feedName'
        && item !== 'Id' && item !== 'column' && item !== 'selectedCols' && item !== 'key');
      const arr = [];
      uniqueKeys.forEach((key) => {
        let colDef = {};
        if (key === 'Description') {
          colDef = {
            text: 'Description',
            fieldName: key,
          };
        } else {
          colDef = {
            text: key,
            fieldName: key,
          };
        }
        arr.push(colDef);
      });
      return arr;
    },
    searchResultsOrKeys() {
      if (this.check) {
        this.compareCheckedWithBasket(this.keySearch);
        return this.keySearch;
      }
      this.compareCheckedWithBasket(this.keys);
      return this.keys;
    },
  },
  created() {
    this.$eventBus.$on('searchClear', (data) => {
      this.searchKeyInput = data;
    });
    this.$eventBus.$on('searchClearFeed', (data) => {
      this.searchKeyInput = data;
    });
    this.$eventBus.$on('initialKeysRes', (data) => {
      this.check = data;
    });
  },
  mounted() {
    this.$eventBus.$on('feedNameSelected', (data) => {
      this.feedName = data;
    });
    this.$eventBus.$on('defaultPage', (data) => {
      this.page = data;
    });
  },
  methods: {
    ...mapActions('dashboardModule',
      ['getKeyData', 'getMetadataKeys', 'getDataPreviewKeys', 'getSearchKeyResult',
        'addKeyToBasket', 'deleteKeyFromBasket', 'checkItemToFalse']),
    onPaginationPageChanged(paginationConfig) {
      this.page = paginationConfig.page;
      this.getKeyData({ feedName: this.feedName, pageNo: this.page });
    },
    onClick(selectedKey) {
      // constructing the api query param
      this.feedName = selectedKey.feedName;
      let keyValue = '';
      if (Object.keys(selectedKey).length > 0) {
        Object.entries(selectedKey).forEach(([key, value]) => {
          if (key !== 'Description' && key !== 'checked' && key !== 'feedName'
            && key !== 'Id' && key !== 'column' && key !== 'selectedCols' && key !== 'key') {
            keyValue += `${key}=${value}&`;
          }
        });
        keyValue = keyValue.slice(0, -1);
        this.$eventBus.$emit('keyValueDataPreview', keyValue);
        this.getMetadataKeys({ feedName: this.feedName, keys: keyValue });
        const timeSeries = moment().subtract(5, 'day').format('YYYY-MM-DD');
        let constructParam = '';
        constructParam += `${keyValue}&cols=${this.columns[0]}&fromDateTime=${timeSeries}`;
        this.getDataPreviewKeys({ feedName: this.feedName, timeseries: constructParam });
      }
    },
    search() {
      this.check = true;
      const payload = { feedName: this.feedName ? this.feedName : this.$route.query.feedName, key: this.searchKeyInput };
      this.loading = true;
      this.$store
        .dispatch('dashboardModule/getSearchKeyResult', payload)
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false));
    },
    clearSearchBar() {
      this.check = false;
    },
    clearStateToKeys(input) {
      if (this.keys.length > 0 && input === '') {
        this.check = false;
      }
    },
    handleRowSelectEvent(rowIndex, item, ev) {
      const isChecked = ev.target.checked;
      if (isChecked) {
        const newCols = this.columnsOptions(this.columns);
        item.checked = true;
        item['key'] = this.keyValues(item);
        item['column'] = newCols;
        item['selectedCols'] = [newCols[0]['value']];
        this.addKeyToBasket(item);
      } else {
        this.checkItemToFalse(item);
        this.deleteKeyFromBasket(item);
      }
    },
    keyValues(keyObj) {
      let temp = '';
      Object.entries(keyObj).forEach(([key, value]) => {
        if (key !== 'Description' && key !== 'feedName' && key !== 'checked' && key !== 'Id'
          && key !== 'column' && key !== 'selectedCols' && key !== 'key') {
          temp += `${key}=${value};`;
        }
      });
      return temp.substring(0, temp.length - 1);
    },
    columnsOptions(colsList) {
      const options = [];
      colsList.forEach((element) => {
        options.push({
          text: element,
          value: element,
        });
      });
      return options;
    },
    compareCheckedWithBasket(keysList) {
      keysList.forEach((keyList) => {
        keyList.checked = false;
      });
      if (this.basket.length > 0) {
        keysList.forEach(keyList => this.basket.forEach((basketItem) => {
          if (keyList.Id === basketItem.Id) {
            keyList.checked = true;
          }
        }));
      }
    },
    changeClass(ev) {
      const className = 'selected-row';
      if (this.previousNode !== '') {
        this.previousNode.classList.toggle(className);
        ev.target.parentElement.classList.toggle(className);
      } else {
        ev.target.parentElement.classList.toggle(className);
      }
      this.previousNode = ev.target.parentElement;
    },
  },
};
</script>
<style lang='scss'>
@import '~@mds/constants';
@import '~@mds/fonts';
@import '~@mds/typography';

.wrapper-column--key {
  box-sizing: border-box;
  height: calc(90vh);
  background-color: white;
  width: 350px;
  flex-grow: 2;
  border: 7px solid whitesmoke;
  display: flex;
  flex-direction: column;
}
.clickable:hover {
  cursor: pointer;
  color: $mds-text-color-interactive-primary;
}
.list-content th label {
  visibility: hidden;
}
.selected-row {
  background-color: #f2f2f2 !important;
}
</style>

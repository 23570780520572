/* eslint-disable prefer-spread */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-restricted-syntax */
import { EnumItem } from './enums.es6';
import { isInteger, requirePositiveInteger } from './numbers.es6';
import { requireString, requireNonEmptyString } from './strings.es6';
import { LimitlessApiHandler } from './apihandler.es6';
import { requireNonVoid } from './objects.es6';
import Functions, { requireFunction } from './functions.es6';
import Dates from './dates/dates.es6';
import Arrays from './arrays.es6';


/**
 * @typedef {Object} PayloadRecord
 * @property {int} timeSet - When was this payload set (epoch millis).
 * @property {*} payload - The response payload, null if not yet received.
 * @property {function[]} callbacks - A list of callers waiting for a non-expired payload.
 */

/**
 * @typedef {Object} CacheRecord
 * @property {int} maxExpire - Maximum expiration, for when it's okay to clean up.
 * @property {Object.<string, PayloadRecord>} payloads - A collection of cached payloads.
 */

/**
 * @typedef {Object} ConstructorToUuid
 * @property {Function} ctor - Constructor
 * @property {string} meth - Method name for retrieving the unique ID of an object.
 */

/* ********************************************************************
 * Private variables
 * ******************************************************************** */

let _canConstructSimple = false;

let _apiHandler = null;

/**
 * Stores the cached payloads, per API name.
 * @type {Object.<string, CacheRecord>}
 * @private
 */
const _cacheById = {};

/**
 * Stores UID method names per constructor function.
 * @type {ConstructorToUuid[]}
 * @private
 */
const _uidByCtor = [];

/**
 * Simple cache sequence.
 * @type {int}
 * @private
 */
let _simpleCacheSeq = 0;

/* ********************************************************************
 * Private methods
 * ******************************************************************** */

/**
 * @throws {Error} If we access to SimpleCache constructor is not allowed.
 * @private
 */
function _checkSimpleCacheCtorAccess() {
  if (!_canConstructSimple) {
    throw new Error('IllegalAccess: private constructor, access denied');
  }
  _canConstructSimple = false;
}

/** @returns {LimitlessApiHandler} Singleton API handler. */
function _getApiHandler() {
  if (_apiHandler === null) {
    // `3` => arbitrary number of concurrent requests
    _apiHandler = new LimitlessApiHandler(3);
  }
  return _apiHandler;
}

/**
 * Creates a string key for the given list of arguments.
 * @param args {Object[]}
 * @return {string}
 * @private
 */
function _makeKey(args) {
  let key = '';
  for (let i = 0, len = args.length; i < len; i++) {
    const arg = args[i];
    const typeOf = typeof arg;
    let argStr = '';

    if (arg === null) {
      argStr = '?';
    } else if (typeOf === 'string') {
      argStr = arg;
    } else if (isInteger(arg)) {
      argStr = arg.toString(10);
    } else if (typeOf === 'boolean') {
      argStr = ((arg === true) ? 'true' : 'false');
    } else if (arg instanceof Date) {
      argStr = arg.getTime().toString(10);
    } else if (typeof arg.toSmartCacheId === 'function') {
      argStr = arg.toSmartCacheId();
    } else if (arg instanceof EnumItem) {
      argStr = arg.valueOf();
      if (isInteger(argStr)) {
        argStr = argStr.toString(10);
      } else if (typeof argStr !== 'string') {
        throw new Error(`Unsupported data-type for enum valueOf(): ${ typeof argStr}`);
      }
    } else {
      let uuidMeth = null;
      for (let j = 0;
        j < _uidByCtor.length && uuidMeth === null;
        j++) {
        if (arg instanceof _uidByCtor[i].ctor) {
          uuidMeth = _uidByCtor[i].meth;
        }
      }

      if (uuidMeth === null) {
        throw new Error(`cannot create key from arguments[${ i }]`);
      }

      argStr = arg[uuidMeth]();
    }
    key += `<<${ argStr }>>`;
  }
  return key;
}

/**
 * Returns the record associated with an ID, creates one if none exists.
 * @param {string} cacheId - Cache ID
 * @param {int} expire - Expiration interval for this cache instance (SmartCache or SimpleCache).
 * @param {string} key - Stringified arguments.
 * @returns {PayloadRecord}
 * @private
 */
function _getCachedRecord(cacheId, expire, key) {
  /** @type {CacheRecord} */
  let cache;

  if (Object.hasOwnProperty.call(_cacheById, cacheId)) {
    cache = _cacheById[cacheId];
  } else {
    cache = {
      maxExpire: 0,
      payloads: {},
    };
    _cacheById[cacheId] = cache;
  }

  if (expire > cache.maxExpire) {
    cache.maxExpire = expire; // Used during clean-up.
  }

  const { payloads } = cache;
  let rec;

  if (Object.hasOwnProperty.call(payloads, key)) {
    rec = payloads[key];
  } else {
    rec = {
      timeSet: 0,
      payload: null,
      callbacks: [],
    };
    payloads[key] = rec;
  }

  return rec;
}


/**
 * Clear old entries from the cache, to release memory.
 * @private
 */
function _cleanUp() {
  const time = Dates.now();

  for (const cacheId of Object.keys(_cacheById)) {
    const cache = _cacheById[cacheId];
    const expire = cache.maxExpire;
    const { payloads } = cache;

    for (const argKey of Object.keys(payloads)) {
      const rec = payloads[argKey];
      if (rec.timeSet + expire < time) {
        rec.payload = null;
      }
    }
  }
}

/**
 * Returns whether a PayloadRecord contains an unexpired payload.
 * @param {PayloadRecord} payloadRec
 * @param {int} expire
 * @returns {boolean}
 * @private
 */
function _isUnexpiredPayload(payloadRec, expire) {
  return (payloadRec.payload !== null
            && payloadRec.timeSet + expire >= Dates.now());
}


/**
 * Primary callback function, caches the response payload
 * and forwards the call to the original caller(s).
 * @param apiResponse {Object}
 * @private
 */
function _cb(apiResponse) {
  // Clarify where we are.
  const rec = this;

  rec.timeSet = Dates.now();

  // Only cache response payload if no errors occurred.
  if (apiResponse !== null
        && !isInteger(apiResponse)) {
    rec.payload = apiResponse;
  }

  // Forward response payload to all callbacks in-waiting.
  for (const callback of Arrays.removeAll(rec.callbacks)) {
    callback(apiResponse);
  }
}


/* ******************************************************************
 * Class: SimpleCache
 * ****************************************************************** */

/**
 * Use SimpleCache to store cached value and have them expire automatically.
 */
export class SimpleCache {
  /**
     * @param {string} id - Simple cache ID.
     * @param {int} expire - How long does a cached entry remain valid, in milliseconds.
     * @private
     */
  constructor(id, expire) {
    _checkSimpleCacheCtorAccess();
    this._id = id;
    this._expire = expire;
    Object.freeze(this);
  }

  /**
     * Returns the current, unexpired value associated with the given keys.
     * @param {...Object} cacheKeys - One or more keys.
     * @returns {?Object} An unexpired value, or null.
     */
  get(cacheKeys) {
    const key = _makeKey(arguments);
    const expire = this._expire;
    const rec = _getCachedRecord(this._id, expire, key);

    if (_isUnexpiredPayload(rec, expire)) {
      return rec.payload;
    }
    return null;
  }

  /**
     * Returns whether this instance contains an unexpired value
     * for the given keys.
     * @param {...Object} cacheKeys - One or more keys.
     * @returns {boolean}
     */
  contains(cacheKeys) {
    return (this.get.apply(this, arguments) !== null);
  }

  /**
     * Sets a value in this cache.
     *
     * It is callers' responsibility to make sure `value` is immutable.
     *
     * @param {Object} value - Value to cache (until it expires)
     * @param {...Object} cacheKeys - One or more keys.
     * @returns {?Object} Value currently store in this cache,
     *                    expire or not.  Possibly null.
     */
  set(value, cacheKeys) {
    requireNonVoid(value, 'value');

    const args = Arrays.slice(arguments, 1);
    const key = _makeKey(args);
    const expire = this._expire;
    const rec = _getCachedRecord(this._id, expire, key);
    const prevPayload = rec.payload;

    rec.timeSet = Dates.now();
    rec.payload = value;

    return prevPayload;
  }

  /**
     * Creates a new instance of SimpleCache.
     * @param {int} expire - How long does a cached entry remain valid, in milliseconds.
     * @returns {SimpleCache}
     */
  static create(expire) {
    requirePositiveInteger(expire, 'expire');

    _canConstructSimple = true;
    return new SimpleCache(
      `autogen_simple_cache_${ (++_simpleCacheSeq).toString(10)}`,
      expire,
    );
  }
}

/**
 * Use SmartCache to prevent multiple trips to the server.  Construct
 * a SmartCache instance with an API name and expire time (how long should API payloads
 * remain valid.)  Then call `smartCache.get()` with a callback and the API arguments
 * and let the SmartCache instance handle the caching (and expiration) for you.
 */
export default class SmartCache {
  /**
     * @param {string} apiName - Name of the API.
     * @param {int} expire - How long is a cached entry persisted, in milliseconds.
     */
  constructor(apiName, expire) {
    this._apiName = requireNonEmptyString(apiName, 'apiName');
    this._expire = requirePositiveInteger(expire, 'expire');
    Object.freeze(this);
  }

  /**
     * Gets a value, fetches it to the server if needed.
     * @param {Function} callback - Where the response is returned.
     * @param {...Object} apiArgs - The API arguments, for when
     *                a server request must be made.
     * @returns {boolean} Whether the value was found in the cache (true)
     *                    or if a server request was initiated (false).
     */
  get(callback, apiArgs) {
    requireFunction(callback, 'callback');

    const args = Arrays.slice(arguments, 1);
    const key = _makeKey(args);
    const expire = this._expire;
    const rec = _getCachedRecord(this._apiName, expire, key);
    const { payload } = rec;

    if (_isUnexpiredPayload(rec, expire)) {
      Functions.delay(() => {
        callback(payload);
      });
      return true;
    }
    rec.callbacks.push(callback);
    if (rec.callbacks.length === 1) { // First caller
      const ah = _getApiHandler();
      const callArgs = [
        this._apiName,
        _cb.bind(rec),
      ];

      Arrays.addAll(callArgs, args);
      ah.call(...callArgs);
    }
    return false;
  }

  /**
     * Returns the underlying SimpleCache instance, for when caller needs to
     * set values manually.
     * @returns {SimpleCache}
     */
  simpleCache() {
    _canConstructSimple = true;
    return new SimpleCache(this._apiName, this._expire);
  }

  /**
     * Adds a UID scheme based on constructor (function).
     * @param {function} ctor Constructor
     * @param {string} idMethod The name of the instance method to use as unique ID.
     */
  static addIdScheme(ctor, idMethod) {
    requireFunction(ctor, 'ctor');
    requireString(idMethod, 'idMethod');

    const idx = Arrays.indexOf(ctor, _uidByCtor, 'ctor');
    if (idx < 0) {
      _uidByCtor.push({
        ctor,
        meth: idMethod,
      });
    } else {
      const spec = _uidByCtor[idx];
      if (spec.meth !== idMethod) {
        throw new Error(`Inconsistent UID method: ${idMethod}() vs. ${spec.meth}()`);
      }
    }
  }
}

// Schedule auto-cleanup, every 2 minutes
window.setInterval(_cleanUp, 2 * 60000);

<template>
  <mds-layout-grid class="sign-in-wrap">
    <span
      style="display:none"
      v-html="
        require(`!svg-inline-loader!../../../node_modules/morningstar-design-system/dist/mds.svg`)"
    />
    <mds-row>
      <mds-col>
        <login-form
          :class="{ 'is-rtl-language-type': $mwc.labeller.languageId === 'ar'}"
          :is-email="settings.useEmail"
          :logo-url="settings.logoUrl"
          :error-text="hasError"
          :reset-password-url="settings.resetPasswordUrl"
          :is-morningstar-policy="settings.isMorningstarPolicy"
          @hideErrorAlert="hideError()"
        />
      </mds-col>
    </mds-row>
  </mds-layout-grid>
</template>
<script>

// MWC helpers
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import translations from './translations';
import LoginForm from './LoginForm.vue';
import EventBus from '../../main';
// CMD components

export default {
  name: 'LoginApp',
  components: {
    LoginForm, MdsLayoutGrid, MdsRow, MdsCol,
  },
  provide() {
    return [
      'mwcId',
      'eventBus',
    ].reduce((provider, obj) => {
      Object.defineProperty(provider, obj, {
        enumerable: true,
        get: () => this[obj] || this[`$_${obj}`],
      });

      return provider;
    }, {});
  },
  props: {
    mwcId: {
      type: String,
      required: true,
    },
    config: {
      type: [String, Object],
      default: () => ({}),
    },
  },
  data() {
    return {
      injectedConfigTemp: this.config,
      injectedConfiguration: {},
      settings: {},
      defaultConfig: {
        settings: {
          intlNamespace: this.mwcId,
          useEmail: true,
          logoUrl: require('../../assets/images/common/Mstar-logo-50px.svg'),
          languageId: 'en',
          isMorningstarPolicy: true,
          resetPasswordUrl: '',
        },
        labels: translations,
      },
    };
  },
  computed: {
    ...mapState({
      hasError: state => state.loginModule.loginError,
    }),
  },
  watch: {
    config: {
      handler(newConfig) {
        this.injectedConfigTemp = newConfig;
      },
      deep: true,
    },
  },
  beforeCreate() {
    this.eventBus = new Vue({});
  },
  beforeUpdate() {
    const componentConfig = typeof this.injectedConfigTemp === 'string'
      ? JSON.parse(`${this.injectedConfigTemp}`)
      : this.injectedConfigTemp;
    this.settings = componentConfig.settings;
  },
  created() {
    /* eslint-disable no-undef */
    this.eventBus.$on('signInRequested', (value) => {
      this.$emit('signInRequested', value);
    });
    EventBus.$on('toggleLoginModal', () => {
      this.$store.state.loginModule.loginError = '';
    });
    this.eventBus.$on('toggleRememberMe', () => {
      this.$emit('toggleRememberMe');
    });
    this.eventBus.$on('showPassword', () => {
      this.$emit('showPassword');
    });
    this.$mwcRegister(this);
  },
  beforeMount() {
    const componentConfig = typeof this.injectedConfigTemp === 'string'
      ? JSON.parse(this.injectedConfigTemp)
      : this.injectedConfigTemp;

    if (
      window.mwc
                && window.mwc.configuration
                && window.mwc.configuration.getConfig
    ) {
      this.injectedConfiguration = window.mwc.configuration.getConfig(
        this.mwcId,
        this.defaultConfig,
        componentConfig,
      );
    } else {
      this.injectedConfiguration = { ...this.defaultConfig, ...componentConfig };
    }

    this.settings = this.injectedConfiguration.settings;
    this.value = this.settings.startingValue;
  },
  methods: {
    ...mapActions('loginModule', ['removeError']),
    hideError() {
      this.removeError();
    },
  },
};
</script>

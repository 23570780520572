<template>
  <div class="wrapper-column--feed">
    <mds-section
      class="section-wrapper"
      title="Feeds"
      border="tertiary"
      border-position="bottom"
    >
      <mds-search-field
        v-model="searchFeedsInput"
        placeholder="Search for Feeds"
        label="Search"
      />
    </mds-section>
    <div class="list-content-wrapper">
      <mds-list-group
        variation="navigation"
        class="list-content"
      >
        <mds-list-group-item
          v-for="(feed, index) in filteredFeedsList"
          id="list-content-mds-list-group-item--feeds"
          :key="index"
          :text="feed"
          :active="activeFeedId === index"
          @mds-list-item-clicked="feedSelected(feed)"
        />
      </mds-list-group>
    </div>
  </div>
</template>
<script>
import MdsSearchField from '@mds/search-field';
import MdsSection from '@mds/section';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group-v4';
import { mapActions, mapGetters } from 'vuex';


export default {
  name: 'FeedList',
  components: {
    MdsSearchField,
    MdsSection,
    MdsListGroup,
    MdsListGroupItem,
  },
  data() {
    return {
      activeItemId: '',
      activeFeedName: '',
      searchFeedsInput: '',
      defaultPagePaginate: 1,
      clearSearchFromFeed: '',
      initialsKeyResult: false,
    };
  },
  computed: {
    ...mapGetters({
      feeds: 'dashboardModule/allFeeds',
    }),
    filteredFeedsList() {
      this.$eventBus.$emit('initialKeysRes', this.initialsKeyResult);
      return this.feeds.filter(item => item.toLowerCase().includes(this.searchFeedsInput.toLowerCase()));
    },
    activeFeedId() {
      return this.filteredFeedsList.indexOf(this.activeFeedName);
    },
  },
  created() {
    this.$eventBus.$on('searchClear', (data) => {
      this.searchFeedsInput = data;
    });
  },
  mounted() {
    const { feedName } = this.$route.query;
    if (feedName) {
      this.feedSelected(feedName);
    }
  },
  methods: {
    ...mapActions('dashboardModule', ['getKeyData', 'getColData', 'clearSectionsFeeds']),
    feedSelected(selectedFeedName) {
      this.activeFeedName = selectedFeedName;
      this.getKeyData({ feedName: this.activeFeedName, pageNo: this.defaultPagePaginate });
      this.$eventBus.$emit('feedNameSelected', this.activeFeedName);
      this.$eventBus.$emit('defaultPage', this.defaultPagePaginate);
      this.$emit('hide-sources');
      this.getColData(this.activeFeedName);
    },
  },
};
</script>
<style lang='scss'>
@import '~@mds/constants';
@import '~@mds/fonts';
@import '~@mds/typography';

.wrapper-column--feed {
  box-sizing: border-box;
  height: calc(90vh);
  background-color: white;
  width: 320px;
  flex-grow: 0.5;
  border: 7px solid whitesmoke;
  display: flex;
  flex-direction: column;
}

</style>

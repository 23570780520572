<template>
  <div
    id="app"
    ref="appRef"
    :class="{ 'dynamic-theme': (isDarkMode)}"
  >
    <span
      style="display:none"
      v-html="require(`!svg-inline-loader!../node_modules/morningstar-design-system/dist/mds.svg`)"
    />
    <component :is="$route.meta.layout || 'div'">
      <router-view />
    </component>
    <IdleTimeout
      :events="['mousemove','keydown', 'mousedown', 'touchstart','scroll','resize','load','keypress','onclick']"
      :inactive-user-time="computeTimePerLocation"
      :ignore-routes="['home']"
      :timeout="30"
    />
  </div>
</template>

<script>
import IdleTimeout from '@/components/Common/IdleTimeout.vue';

export default {
  name: 'App',
  components: {
    IdleTimeout,
  },
  data() {
    return {
      layout: 'div',
    };
  },
  computed: {
    isDarkMode() {
      // TODO: [THEMEA]temporary after applying themeing globally - in dashboard for now
      if (this.$route.path.includes('dashboard')) {
        const htmlElement = document.documentElement;
        const themeType = localStorage.getItem('theme');
        htmlElement.setAttribute('theme', themeType);
        const appEl = document.getElementById('app');
        appEl.classList.add('dynamic-theme');
        return true;
      }
      const htmlElement = document.documentElement;
      htmlElement.removeAttribute('theme');
      const appEl = document.getElementById('app');
      appEl.classList.remove('dynamic-theme');
      return false;
    },
    computeTimePerLocation() {
      if (this.$route.path.includes('dashboard') || this.$route.path.includes('dataAnalytics')) {
        return 86400000; // 1 day
      }

      return 60 * 60 * 1000; // 1 hour
    },
  },
  mounted() {
    console.log(process.env.VUE_APP_TITLE);
    this.$logger.info(process.env.VUE_APP_TITLE);
    const app = this;
    if (
      '-ms-scroll-limit' in document.documentElement.style
      && '-ms-ime-align' in document.documentElement.style
    ) {
      window.addEventListener(
        'hashchange',
        () => {
          const currentPath = window.location.hash.slice(1);
          if (app.$route.path !== currentPath) {
            app.$router.replace(currentPath);
          }
        },
        false,
      );
    }
  },
};
</script>

<style lang="scss">
@import './assets/styles/global.scss';
</style>

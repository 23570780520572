const mutations = {
  ADD_REPORTS: (state, reports) => {
    state.reports = reports;
  },
  SET_REPORTS_STATUS: (state, status) => {
    state.reportsStatus = status;
  },
};

export default mutations;


import Strings, { firstString } from "./strings.es6";
import Url from "./url.es6";
import Ajax from "./ajax.es6";
import HttpCodes from "./httpcodes.es6";

const REGEX_LOGIN_PAGE_BODY = new RegExp(
    '<form[^>]*?\\saction=[\'"]?j_spring_security_check[\'"]?[\\s>]',
    'i'
);

/**
 * @param {Ajax.Response} response
 * @returns {boolean} Whether the response body contains the login page.
 * @private
 */
function _isLoginPage (response) {
    return REGEX_LOGIN_PAGE_BODY.test(response.payloadAsString());
}

/** Redirects current page to `expired.htm`. */
function _disconnect () {
    window.location.assign(
        Url.rootPath(1) + 'expired.htm?g=' + window.encodeURIComponent(window.location.href)
    );
}


function _timeDZ (request) {

    if (_isUnauthenticated(request)) {
        _disconnect();
    } else {
        // We could be failing right now if we get status codes 400, 503 or 0.
        // In those cases, try again in 5 seconds.
        let sc = request.response().statusCode();
        if (   (   sc === HttpCodes.BAD_REQUEST
                || sc === HttpCodes.SERVICE_UNAVAILABLE
                || sc === HttpCodes.NO_NETWORK )
            && !Runtime.shutdown.isExecuting() )
            window.setTimeout(_authenticate, 5000);
    }
}

/**
 * Returns <code>true</code> if the server is responding, but the session is not authenticated.
 * Otherwise returns <code>false</code>.
 * @param request (Ajax.Request)
 * @returns {boolean}
 */
function _isUnauthenticated (request) {

    if (request.isXOrigin())
        return false;

    else {
        let resp       = request.response(),
            httpStatus = resp.statusCode();

        return (   (   httpStatus >= 200
                    && httpStatus <= 299
                    && _isLoginPage(resp) === true )
                || (   httpStatus === 302
                    && firstString(resp.header("Location"), "").indexOf("/login") >= 0 ) );
    }
}

/**
 * Verifies whether the current session is still valid (a.k.a. user is authenticated).
 * If it isn't, we force the user to the login page.
 */
function _authenticate () {
    /* Make a dummy call to time API.  We only care about
     * whether the response that came back is valid. */
    new Ajax.Request().url(Url.rootPath(1) + "func/time")
                          .callback(_timeDZ)
                          .execute();
}


/** @namespace */
const Session = Object.freeze( /** @lends {Session} */ {

    /**
     * Returns <code>true</code> if the server is responding, but the session is not authenticated.
     * Otherwise returns <code>false</code>.
     * @param request (Ajax.Request)
     * @returns {boolean}
     * @method
     */
    isUnauthenticated: _isUnauthenticated,

    /**
     * Returns whether the given request is a internal communication test request.
     * @param {Ajax.Request} request
     * @returns {boolean}
     */
    isTestRequest: function (request) {
        return Strings.endsWith(request.url(), "/func/time");
    },
    

    /**
     * Verifies whether the current session is still valid (a.k.a. user is authenticated).
     * If it isn't, we force the user through the login page.
     */
    authenticate: _authenticate,

});

export default Session;

<template>
  <div
    id="mds-notification"
    class="mds-notification mds-notification--top-center mds-notification--below-masthead mds-notification--active custom-top"
  >
    <mds-notification-group>
      <mds-notification
        ref="errorDialog"
        key="error-default"
        :list="''"
        status="error"
        size="small"
        dismiss-delay="5000"
        dismissible="true"
        removable="false"
        class="z-index-9999"
      >
        {{ errorText }}
      </mds-notification>

      <mds-notification
        ref="successDialog"
        key="success-default"
        :list="''"
        status="success"
        size="small"
        dismiss-delay="5000"
        dismissible="true"
        removable="false"
        class="z-index-9999"
      >
        {{ successText }}
      </mds-notification>
    </mds-notification-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LayoutDefault from '../Layouts/LayoutDefault.vue';
import EventBus from '../../main';

export default {
  name: 'SchedulerNotification',
  components: {
  },
  filters: {},
  props: {
    notificationText: {
      type: Array,
      default: () => [],
    },
    notificationType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      errorText: [],
      successText: [],
    };
  },
  computed: {
    ...mapGetters({
    }),
  },
  created() {
    EventBus.$emit('layoutChange', LayoutDefault);
  },
  methods: {
    ...mapActions('schedulerModule', []),

    triggerError(message) {
      this.errorText = message;
      this.$refs.errorDialog.open();
    },
    triggerSuccess(messgae) {
      this.successText = messgae;
      this.$refs.successDialog.open();
    },
  },
};
</script>

<template>
  <div>
    <section class="page-header">
      <div v-if="feedsData.length">
        <p>
          <mds-layout-grid>
            <mds-row>
              <mds-col :cols="10">
                <div
                  class="caret"
                >
                  <button
                    id="from-link"
                    @click="backToCatalog"
                  >
                    <a
                      href="#/catalog"
                      aria-current="page"
                      class="router-link-exact-active router-link-active"
                    >
                      <span class="caret-item">  {{ $t('labels.datacatalog.catalog') }}</span>
                    </a>
                  </button> /
                  <span class="caret-item">
                    {{ feedsData[1]['source'].toUpperCase() }}  {{ $t('labels.datacatalog.feedsCap') }}
                  </span>
                </div>
              </mds-col>
              <mds-col
                class="mds-bk-cat"
                style="margin-top: 1rem; display: flex; justify-content: end; height: 100%;"
              >
                <mds-button
                  variation="primary"
                  @click="backToCatalog"
                >
                  {{ $t('labels.datacatalog.backToCatalog') }}
                </mds-button>
              </mds-col>
            </mds-row>
          </mds-layout-grid>
        </p>
        <span class="feed-header">
          {{ feedsData[1]['source'] }}  {{ $t('labels.datacatalog.feeds') }}
        </span>
        <mds-layout-grid>
          <mds-row>
            <mds-col cols="2">
              <img
                class="img-init"
                alt="Go to Source"
                aria-label="Source Provider Image"
                :src="getImgUrl(feedsData[1]['source'].toLowerCase())"
                style="object-fit: contain;"
              >
            </mds-col>
            <mds-col cols="10">
              <p class="feed-description">
                {{ feedsData[1]['sourceDescription'] }}
              </p>
            </mds-col>
          </mds-row>
          <mds-row>
            <mds-col :cols="2">
              <template
                v-if="feedsData[1]['sourceUrl'] != 'null'
                  && feedsData[1]['sourceUrl'] != '' && feedsData[1]['sourceUrl'] != null"
              >
                <mds-button
                  variation="flat"
                  class="my-link"
                  :href="feedsData[1]['sourceUrl']"
                  target="_blank"
                  style="align-items: left;display: inline-block; justify-content: left; color: ;"
                  @click="logVendorSiteVisit(feedsData[1]['source'])"
                >
                  {{ $t('labels.common.visitVendorSite') }}
                </mds-button>
              </template>
            </mds-col>
          </mds-row>
          <mds-row>
            <mds-col>
              <p class="border-bottom-grey" />
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </div>
    </section>
    <mds-section
      container
    >
      <mds-table>
        <mds-thead>
          <mds-th
            v-for="(header, index) in headers"
            :key="index"
          >
            {{ header.text }}
          </mds-th>
        </mds-thead>
        <mds-tbody>
          <mds-tr
            v-for="(row,index) in feedsData[0]"
            :key="index"
          >
            <mds-td
              v-for="(header,i) in headers"
              :key="i"
            >
              <div
                v-if="header.fieldName === 'actions'"
                class="controls__actions"
              >
                <mds-button
                  v-if="isUserEntitledToFeedName(row['feedName'])"
                  variation="icon-only"
                  icon="eye"
                  @click="emitNavPageClicked(row['feedName'],feedsData[1].source)"
                />
                <mds-button
                  v-if="adminUser"
                  variation="icon-only"
                  icon="pencil"
                  class="actions__bttn-edit"
                  @click="handleEditFeed(row['feedName'])"
                />
              </div>
              <div v-else>
                {{ row[header.fieldName] }}
              </div>
            </mds-td>
          </mds-tr>
        </mds-tbody>
      </mds-table>
    </mds-section>
    <edit-feed-modal
      ref="editFeedComponent"
      :feed-name="activeFeedName"
      @mcd-refresh-meta-data="handleRefreshSignal"
    />
  </div>
</template>

<script>
import MdsSection from '@mds/section';
import {
  MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr,
} from '@mds/data-table';
import { MdsButton } from '@mds/button';
import axios from 'axios';
import splunkLogger from '@/utils/splunkLogger';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import EditFeedModal from './EditFeedModal';
import { getUserName, getUserRoles } from '../../utils/authService';

export default {
  name: 'FeedPage',
  components: {
    EditFeedModal,
    MdsSection,
    MdsTable,
    MdsTbody,
    MdsTd,
    MdsTh,
    MdsThead,
    MdsTr,
    MdsButton,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
  },
  props: {
    feedsData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeFeedName: '',
      headers: [
        {
          fieldName: 'feedName',
          text: 'Feed Name',
        },
        {
          fieldName: 'feedDescription',
          text: 'Description',
        },
        {
          fieldName: 'actions',
          text: 'Actions',
        },
      ],
      adminUser: false,
      entitledFeedList: [],
    };
  },
  watch: {
    feedsData: {
      deep: true,
      handler(newVal) {
      },
    },
  },
  mounted() {
    if (getUserRoles().includes('ROLE_LDS_SUPPORT_ADMIN')
      || getUserRoles().includes('ROLE_LDS_DATAOPS_ADMIN')
      || getUserRoles().includes('ROLE_LDS_DATADEV_ADMIN')
    ) {
      this.adminUser = true;
    }
    if (this.feedsData.length > 0 && this.feedsData[1]['source']) {
      axios.get(`/api/providers/LIM/datasources/${this.feedsData[1]['source']}`)
        .then((response) => {
          this.entitledFeedList = response.data;
        });
    }
  },
  methods: {
    getImgUrl(name) {
      return `https://${process.env.VUE_APP_STATIC_RESOURCES_S3_BUCKET_CF_URL}/images/${name}.PNG`;
    },
    logVendorSiteVisit(source) {
      splunkLogger([{
        user: getUserName(),
      }, { vendorSiteVisited: source, featurePage: 'catalog' },
      ]);
    },
    handleEditFeed(feedName) {
      if (this.$refs && this.$refs.editFeedComponent) {
        this.$refs.editFeedComponent.selectedFeedNameModel = feedName;
        this.activeFeedName = feedName;
        this.$refs.editFeedComponent.$refs.editFeedModal.show();
      }
    },
    isUserEntitledToFeedName(feedName) {
      let isEntitled = false;
      if (feedName && this.entitledFeedList.length > 0) {
        isEntitled = this.entitledFeedList.includes(feedName);
      }
      return isEntitled;
    },
    closeModal() {
      this.$refs.editFeedModal.hide();
    },
    handleRefreshSignal(obj) {
      this.$emit('mcd-refresh-meta-data', obj);
    },
    emitNavPageClicked(feedName, SourceName) {
      splunkLogger([{
        user: getUserName(),
      }, { feedClicked: feedName, featurePage: 'catalog' },
      ]);
      this.$router.push({
        path: '/navigation',
        query: { feedName, sourceName: SourceName },
        replace: true,
      });
    },
    backToCatalog() {
      this.$emit('reloadMainCatalogPage');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@mds/constants';
@import '~@mds/typography';
@import '~@mds/link';

.my-link {
    @include mds-link($underline: false);
};

.controls__actions{
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
}
.actions__bttn-edit{
  padding-left: 20px;
  line-height: 50px;
}
.feed-header{
  @include mds-level-1-heading()

}
.feed-description{
  @include mds-level-4-heading()
}
.border-bottom-grey{
  border-bottom: 2px solid #ababab;
}
</style>


import _ from 'underscore';

import { requireBoolean } from './booleans.es6';
import { requireNonNegativeInteger, requirePositiveInteger, requireInteger } from './numbers.es6';
import Strings, { requireString, requireNonEmptyString, requireStringOrNull } from './strings.es6';
import Dates from './dates/dates.es6';
import Objects, { requireObject } from './objects.es6';
import Arrays from './arrays.es6';
import Url from './url.es6';
import Functions from './functions.es6';
import Console from './console.es6';
import Timer from './timer.es6';
import HttpCodes from './httpcodes.es6';
import Session from './session.es6';


/**
 * @typedef Object Ajax.CallMock
 * @property {Ajax.RequestMock} request
 * @property {Ajax.ResponseMock} response
 */

/**
 * A path to a specific value within a JSON object, or a simple key-name.
 * If an XPath, the string represents a dot-delimited ('.') list of object properties,
 * in which each entry can be a property name (string) or array index (int).
 *
 * There is currently no way to escape the '.' delimiter within an XPath, but
 * escaping can easily be added later if needed.
 *
 * @typedef string Ajax.XPath
 */

/* ****************************************
 * Private variables
 * **************************************** */

const APP_URL_ENCODED = 'application/x-www-form-urlencoded';
const APP_JSON = 'application/json';
const GET = 'GET';

const CONTENT_TYPE_HTML = new RegExp('^[^/]+/html(?:;|$)', 'i');
const CONTENT_TYPE_XML = new RegExp('^[^/]+/xml(?:;|$)', 'i');
const CONTENT_TYPE_JSON = new RegExp('^[^/]+/json(?:;|$)', 'i');

/**
 * Mocked requests, indexed by "[HTTP_METHOD] [URL]".
 * @type {Object.<string, Ajax.CallMock[]>}
 */
const _mocked = {};

/* ****************************************
 * Private methods
 * **************************************** */

/**
 * Returns whether the given content type represents an XML payload.
 * @param contentType
 * @returns {boolean}
 * @private
 */
function _isXml(contentType) {
  return CONTENT_TYPE_XML.test(contentType);
}
/**
 * Validates the HTTP method.
 * @param {string} type - GET, PUT, POST, DELETE or HEAD.
 * @returns {string} Validated HTTP method.
 * @private
 */
function _validMethodType(type) {
  if (type !== 'GET'
        && type !== 'PUT'
        && type !== 'POST'
        && type !== 'HEAD'
        && type !== 'DELETE') { throw new Error(`IllegalArgumentException: \`methodType\` must be one of [GET, POST, HEAD, DELETE] (methodType="${ type }")`); }

  return type;
}
function _validateContentType(type) {
  if (type.indexOf(';') >= 0) { throw new Error(`IllegalArgumentException: \`type\` cannot contain ';' (type="${ type }")`); }
}
/**
 * @param {string} method
 * @param {string} url
 * @returns {string} Key to use in `_mocked` object.
 * @private
 */
function _getMockKey(method, url) {
  return `${method } ${ url}`;
}
/**
 * Getter and setter method for an Client or Request
 * instance.
 * @param {(Ajax.Client|Ajax.Request)} instance
 * @param {Arguments} args - Arguments given to calling method.
 * @returns {(int|Ajax.Client|Ajax.Request)} Timeout value or `instance`.
 */
function _getsetTimeout(instance, args) {
  if (args.length < 1) {
    return instance._timeout;
  }
  instance._timeout = requireNonNegativeInteger(args[0], 'timeout');
  return instance;
}

/**
 * Returns a new XOrigin native object, based on feature detection.
 * @type {function}
 * @private
 */
const _newXhrCors = (function () {
  if ('withCredentials' in new XMLHttpRequest()) {
    // All browsers... let IE 9 or lower fail.
    return function () {
      return new XMLHttpRequest();
    };
  }
  return function () {
    throw new Error('UnsupportedOperationException: browser does not support CORS protocol.');
  };
}());

/**
 * Creates the appropriate XMLHttpRequest object (or XDomainRequest)
 * depending on the request and browser support.
 * @param {Ajax.Request} request
 * @returns {(XMLHttpRequest)}
 */
function _getXhr(request) {
  if (!request.isXOrigin()) return new XMLHttpRequest();


  const xhr = _newXhrCors();
  xhr.withCredentials = true;

  return xhr;
}
/**
 * Callback for when request times out.
 * @this {Ajax.Request}
 */
function _timeoutTick() {
  Console.log('Request timed out [{}]', this._url);

  this._abort();

  const resp = new Response(this);
  resp._status = 504;
  resp._payload.text = '504 - Gateway timeout (local client)';

  this._resp = resp;

  this._markReceived();

  if (this._callback !== null) { this._callback(this); }
}
/**
 * Activates the timer, if a timeout period is set.
 * @param {Ajax.Request} request
 */
function _setTimeout(request) {
  let timeout = request._timeout;
  const c = request._client;

  if (timeout <= 0
        && c !== null) { timeout = c.timeout(); }

  if (timeout > 0) { request._timer = window.setTimeout(_timeoutTick.bind(request), timeout); }
}
/**
 *
 * @param {Object.<string, string>} headers
 * @returns {Object.<string, Object>}
 * @private
 */
function _indexedHeaders(headers) {
  const o = {};

  for (const name of Object.keys(headers)) {
    o[name.toLowerCase()] = {
      name,
      value: o[name],
    };
  }

  return o;
}
/**
 *
 * @param {string} rawHeaders
 * @returns {Object.<string, string>} An object that contains all HTTP headers
 *          and their values.
 * @private
 */
function _parseHeaders(rawHeaders) {
  const o = {};

  if (typeof rawHeaders === 'string') {
    const eol = new RegExp('\r\n', 'g');
    const lines = Strings.split(rawHeaders, eol);

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const colon = line.indexOf(':');

      if (colon < 0) { continue; }

      const name = line.substring(0, colon).trim();
      o[name] = line.substring(colon + 1).trim();
    }
  }

  return o;
}
/**
 * @param {Ajax.Response} resp
 * @param {XMLHttpRequest} xhr
 * @returns {Ajax.Response}
 * @private
 */
function _loadXhr(resp, xhr) {
  const contentType = xhr.getResponseHeader('content-type');

  _loadXhrContent(
    resp,
    xhr.status,
    contentType,
    _parseHeaders(xhr.getAllResponseHeaders()),
    xhr.responseText,
  );

  if (_isXml(contentType)) { resp._payload.xml = xhr.responseXML; }

  return resp;
}
/**
 *
 * @param {Ajax.Response} resp
 * @param {int} statusCode
 * @param {string} contentType
 * @param {Object.<string, string>} headers
 * @param {string} responseText
 * @returns {Ajax.Response} `resp`
 * @private
 */
function _loadXhrContent(resp, statusCode, contentType, headers, responseText) {
  resp._status = statusCode;
  resp._headers = _indexedHeaders(headers);
  resp._contentType = contentType;

  resp._payload.text = responseText;

  return resp;
}
/**
 * @param {Object} mocked
 * @param {Object} request
 * @returns {boolean} Whether the request (payload) satisfies the mocked payload.
 * @private
 */
function _areEqualPayloadObjects(mocked, request) {
  return Objects.areEqual(
    mocked,
    request,
    {
      include: Objects.properties(mocked),
    },
  );
}
/**
 * @param {string} mocked
 * @param {string} request
 * @param {string} contentType
 * @returns {boolean} Whether the mocked payload matches the request payload.
 * @private
 */
function _areEqualPayloads(mocked, request, contentType) {
  switch (contentType) {
    case APP_JSON:
      return _areEqualPayloadObjects(
        JSON.parse(mocked),
        JSON.parse(request),
      );

    case APP_URL_ENCODED:
      return _areEqualPayloadObjects(
        Url.parseQueryString(mocked),
        Url.parseQueryString(request),
      );

    default:
      throw new Error(`Mocking payloads with Content-type [${
        contentType
      }] is not supported`);
  }
}
/**
 * @param {Ajax.RequestMock} reqMock
 * @param {Ajax.Request} req
 * @returns {boolean} Whether the request's payload matches the request mock's.
 * @private
 */
function _isPayloadMatch(reqMock, req) {
  // If mock request has no payload, they we match all payloads.
  // Otherwise, payload has to match that of the actual request.
  if (reqMock._payload === null) return true;

  if (reqMock._contentType !== req._contentType) return false;

  return _areEqualPayloads(reqMock._payload, req._payload, req._contentType);
}
/**
 * Retrieves a mocked response associated with an HTTP request, or null if none found.
 * @param {Ajax.Request} req
 * @returns {?Ajax.ResponseMock} Mocked response, may be null.
 * @private
 */
function _getMockedResponse(req) {
  const method = req._type.toUpperCase();
  const url = Strings.upTo(req._url, '?');
  const list = _mocked[_getMockKey(method, url)];

  if (list instanceof Array) {
    const qryStr = Object.freeze(Url.parseQueryStringInUrl(req._url));

    let i = 0;
    const len = list.length;
    for (; i < len; i++) {
      /** @type {Ajax.CallMock} */
      const mock = list[i];

      const reqMock = mock.request;

      if (reqMock._method === method
                && reqMock._url === url
                && Objects.areEqual(reqMock._qryStr, qryStr)
                && _isPayloadMatch(reqMock, req)) {
        return mock.response;
      }
    }
  }

  return null;
}
/**
 * Returns a hash of values from the request, keyed by their names within the response.
 * @param {Object.<Ajax.XPath, Ajax.XPath>} xPathMap -
 *         Object.&lt;path_in_request, path_in_response&gt;
 * @param {Ajax.Request} req - Ajax request.
 * @returns {Object.<Ajax.XPath, *>} Object.&lt;path_in_response, value_from_request&gt;
 * @private
 */
function _getValuesFromRequest(xPathMap, req) {
  const srcNames = _.keys(xPathMap);
  let src;

  switch (req.contentType()) {
    case APP_JSON:
      src = {};
      const json = JSON.parse(req.payload());
      _.each(srcNames, (keyName) => {
        src[keyName] = _getValueAtXPath(_parseXPath(keyName), json);
      });
      break;

    case APP_URL_ENCODED:
      src = _getUrlEncodedRequestParams(req);
      break;

    default:
      throw new Error(`Unsupported content type, cannot copy values from [${
        req.contentType() }] request`);
  }

  const rv = {};
  _.each(xPathMap, (respRef, reqRef) => {
    if (!_.has(src, reqRef)) throw new Error(`Could not find [${ reqRef }] in Ajax request.`);

    rv[respRef] = src[reqRef];
  });

  return rv;
}

/**
 * @param {Ajax.Request} req
 * @returns {Object.<string, string>} Hash of parameters found in url-encoded request
 *          query-string and body/payload.
 * @private
 */
function _getUrlEncodedRequestParams(req) {
  const params = Url.parseQueryStringInUrl(req.url());
  const payload = req.payload();

  if (Strings.isNonEmpty(payload)) { _.extend(params, Url.parseQueryString(payload)); }

  return params;
}

/**
 * @param {string[]} xpath - Already-parsed xpath.
 * @param {Object} json
 * @returns {*} V - object, int, string, etc. - at the specified xpath within `json`.
 * @private
 */
function _getValueAtXPath(xpath, json) {
  let o = json;

  _.each(xpath, (entry) => {
    // Type coercion works wonders here, for both objects AND arrays:
    // it works even when array indices are specified as string!

    if (!_.has(o, entry)) throw new Error(`Invalid XPath: ${ xpath.join('.')}`);

    o = o[entry];
  });

  return o;
}

/**
 * Parses `xpath` into a list of entries.
 * @param xpath
 * @returns {Array|*}
 * @private
 */
function _parseXPath(xpath) {
  return xpath.split('.');
}

/**
 * Injects values in the payload, according to the specified paths.
 * @param {Object.<Ajax.XPath, *>} valuesToInject - Values to inject, indexed by xpath
 *        within the payload.
 * @param {string} payload - Payload to enhanced.
 * @param {string} contentType - Content-type of `payload`.
 * @returns {string} New `payload` with values injected into it.
 * @private
 */
function _injectInPayload(valuesToInject, payload, contentType) {
  switch (contentType) {
    case APP_JSON:
      return JSON.stringify(
        _injectIntoJsonObject(
          valuesToInject,
          JSON.parse(payload),
        ),
      );

    case APP_URL_ENCODED:
      return Url.stringifyQueryString(
        _.extend(
          Url.parseQueryString(payload),
          valuesToInject,
        ),
      );

    default:
      throw new Error(`Unsupported content type, cannot inject values into [${
        contentType }] response`);
  }
}

/**
 * Injects some values into the JSON object.
 * @param {Object.<Ajax.XPath, *>} valuesToInject - Values to inject, indexed by xpath
 *        within the JSON object.
 * @param {Object} json
 * @returns {Object} `json`
 * @private
 */
function _injectIntoJsonObject(valuesToInject, json) {
  _.each(valuesToInject, (value, xpath) => {
    const path = _parseXPath(xpath);
    const lastIdx = path.length - 1;
    const target = _getValueAtXPath(path.slice(0, lastIdx), json);

    target[path[lastIdx]] = value;
  });

  return json;
}

/**
 *
 * @param {Ajax.Request} req
 * @returns {boolean} Whether this request had a (already handled) mocked response.
 * @private
 */
function _isHandledMock(req) {
  const mockResp = _getMockedResponse(req);
  if (mockResp === null) return false;


  req._resp = _loadXhrContent(
    new Response(req),
    mockResp._httpCode,
    mockResp._contentType,
    {},
    mockResp.payloadWithInjections(req),
  );
  req._markReceived();

  if (req._callback !== null) {
    Functions.delay(() => {
      req._callback(req);
    });
  }

  return true;
}
/* *************************************************
 * CLASS Ajax.Client (public)
 * ************************************************* */

/**
 * Creates a Client instance.  Client instances are useful
 * when we need to pace the number of concurrent connections
 * to the server.  It provides functionality to auto-cancel
 * requests when new requests are made, or to enqueue the newer
 * requests until a pending request returns.
 *
 * @constructor
 * @name Ajax.Client
 */
export class Client {
  constructor() {
    this._maxConn = 1;
    this._autoAbort = false;
    this._actives = [];
    this._queue = [];
    this._timeout = 0; // No timeout is the default

    this._sendNextTimer = new Timer(10, this._sendNextNow.bind(this));
  }

  /**
     * Getter and setter method for the maximum number of
     * concurrent connections to the server.  Defaults to <em>1</em>.
     *
     * This value can only be set while the instance is inactive
     * (no active requests).
     *
     * @param {int} [maxConnection]
     * @returns {(int|Ajax.Client)}
     * @this {Ajax.Client}
     */
  maxConnections(maxConnection) {
    if (arguments.length < 1) {
      return this._maxConn;
    } if (this.size() > 0) {
      throw new Error('IllegalStateException: cannot set maxConnections() while requests are active.');
    } else {
      this._maxConn = requirePositiveInteger(maxConnection, ' maxConnection');
      return this;
    }
  }

  /**
     * Getter and setter method for whether this client
     * automatically aborts requests when the maximum number
     * of connections is exceeded.  Defaults to <em>false</em>.
     *
     * @param {boolean} [autoAbort]
     * @returns {(boolean|Ajax.Client)}
     * @this {Ajax.Client}
     */
  autoAbort(autoAbort) {
    if (typeof autoAbort === 'undefined') {
      return this._autoAbort;
    } if (this.size() > 0) {
      throw new Error('IllegalStateException: cannot call autoAbort() while requests are active.');
    } else {
      this._autoAbort = requireBoolean(autoAbort, 'autoAbort');
      return this;
    }
  }

  /**
     * Getter and setter method for the timeout value of this
     * Client instance.
     * @param {int} [timeout] - The client's timeout value, in milliseconds.
     * @returns {(int|Ajax.Client)}
     * @this {Ajax.Client}
     */
  timeout(timeout) {
    return _getsetTimeout(this, arguments);
  }

  /**
     * Returns the total number of requests (active and pending).
     * @returns {int}
     */
  size() {
    return (this._actives.length
                + this._queue.length);
  }

  /**
     * Executes a request.  Excessive requests
     * will be handled by this instance of <code>Ajax.Client</code>.
     * @param request {Ajax.Request}
     */
  execute(request) {
    if (!(request instanceof Request)) { throw new TypeError('request: Ajax.Request'); }

    if (request.isComplete()
            || request.isPending()) { throw new Error('IllegalStateException: cannot execute twice.'); }

    request._client = this;
    request.execute();
  }

  /**
     * Returns the list of requests being executed or in the queue.
     * @returns {Ajax.Request[]}
     */
  requests() {
    const a = [];
    Arrays.addAll(a, this._actives);
    Arrays.addAll(a, this._queue);
    return a;
  }

  _isDestroyed() {
    return (typeof this._autoAbort === 'undefined');
  }

  /**
     * Destroys the instance, after cancelling all pending requests.
     */
  destroy() {
    this.abortAll();
    Objects.destroy(this);
  }

  /**
     * Cancels all pending requests immediately.
     */
  abortAll() {
    /* First, we cancel all pending requests,
         * to prevent new requests from being executed. */
    for (const req of Arrays.removeAll(this._queue)) {
      req._isPending = false;
    }

    // Then, we cancel all active requests.
    for (const active of Arrays.removeAll(this._actives)) {
      active.abort();
    }
  }


  /**
     * This method returns a XMLHttpRequest object available to make a
     * new request.  If we reached the maximum number of concurrent requests,
     * one of two things happens:
     * <ol>
     *  <li> if auto-abort is false, the new request is enqueued until
     *       a XMLHttpRequest object becomes available.  In this case,
     *       the method returns <code>null</code> to indicate that the request
     *       cannot proceed. </li>
     *  <li> if auto-abort is true, the oldest, pending request is cancelled,
     *       its XMLHttpRequest object is reset and re-used for the newest
     *       request. </li>
     * </ol>
     * @param request {Ajax.Request}
     * @returns {?XMLHttpRequest} A XMLHttpRequest object, or <code>null</code> if
     *         <code>request</code> has been enqueued.
     */

  _getXhr(request) {
    const actives = this._actives;

    if (actives.length >= this._maxConn) {
      /* If we reached the maximum number of concurrent
             * connections allowed. */

      if (!this._autoAbort) {
        /* Enqueue the request and it will be processed
                 * as soon as bandwidth becomes available. */
        this._queue.push(request);
        return null;
      }

      /* Abort the oldest, active Request to clear some bandwidth
             * before proceeding. */

      Console.log('New request is cancelling previous request...');

      actives[0].abort();
    }

    actives.push(request);
    return _getXhr(request);
  }

  _markComplete(request) {
    return (Arrays.remove(request, this._actives) >= 0);
  }

  /**
     * Marks the given Request as received
     * and initiates next pending request.
     * @param request {Ajax.Request}
     * @returns {Boolean} Whether another request was initiated.
     */
  _markReceived(request) {
    this._markComplete(request);
    this._sendNextNow();
  }

  /**
     * Returns whether the cancelled request was waiting
     * for server response (true) or enqueued (false).
     * @param request {Ajax.Request}
     * @returns {Boolean}
     */
  _cancel(request) {
    if (this._markComplete(request)) {
      return true;
    }
    Arrays.remove(request, this._queue);
    return false;
  }

  /**
     * Sends the next Request currently in the queue.
     * @returns {Boolean} Whether a request was sent.
     */
  _sendNextNow() {
    const queue = this._queue;
    if (queue.length > 0) {
      const nextRequest = queue.splice(0, 1)[0];

      nextRequest._execute(this._getXhr(nextRequest));
      return true;
    }
    return false;
  }

  _sendNext() {
    // Delay for a bit in case we're aborting multiple requests at once.
    this._sendNextTimer.reset();
  }
}


/* ******************************************************
 * CLASS Ajax.Request (public)
 * ****************************************************** */
/**
 * AJAX request constructor.
 *
 * @constructor
 * @name Ajax.Request
 */
export class Request {
  constructor() {
    this._client = null;
    this._xhr = null;
    this._data = null;
    this._resp = null;
    this._timer = null;
    this._isPending = false;
    this._isCancelled = false;
    this._reqTime = null;
    this._launchTime = null;
    this._respTime = null;

    // cloneable values
    this._url = null;
    this._type = 'GET';
    this._async = true;
    this._contentType = APP_URL_ENCODED;
    this._charset = document.charset;
    this._headers = {
      index: {},
      list: [],
    };
    this._payload = null;
    this._callback = null;
    this._timeout = 0; // No timeout by default.
  }

  _getsetString(prop, args, fnValidate) {
    if (args.length < 1) {
      return this[prop];
    }

    requireNonEmptyString(args[0], 'stringValue');
    if (Functions.is(fnValidate)) {
      fnValidate(args[0]);
    }

    this[prop] = args[0];
    return this;
  }

  _getsetBool(prop, args) {
    if (args.length < 1) {
      return this[prop];
    }
    this[prop] = requireBoolean(args[0], 'boolValue');
    return this;
  }

  /**
     * Getter and setter method for request URL.
     * @param {string} [url] Non-empty.
     * @returns {?(string|Ajax.Request)}
     */
  url(url) {
    return this._getsetString('_url', arguments);
  }

  /**
     * Getter and setter method for asynchronous mode.
     * Defaults to <em>true</em>.
     * @param {boolean} [async]
     * @returns {(boolean|Ajax.Request)}
     */
  async(async) {
    return this._getsetBool('_async', arguments);
  }

  /**
     * Get and set the content-type header.  Defaults to
     * "application/x-www-form-urlencoded".
     *
     * @param {string} [type] Non-empty.
     * @returns {(string|Ajax.Request)}
     */
  contentType(type) {
    return this._getsetString('_contentType', arguments, _validateContentType);
  }

  /**
     * Gets or sets the request character-set.
     * @param {string} [charset]
     * @returns {(string|Ajax.Request)}
     */
  charset(charset) {
    return this._getsetString('_charset', arguments);
  }

  /**
     * Getter and setter method for the method type.  Defaults to "GET".
     * @param {string} [type] Non-empty, optional.  Acceptable values are
     *  <ul>
     *   <li> GET </li>
     *   <li> POST </li>
     *   <li> HEAD </li>
     *   <li> DELETE </li>
     *  </ul>
     * @returns {(string|Ajax.Request)}
     */
  methodType(type) {
    return this._getsetString('_type', arguments, _validMethodType);
  }

  /**
     * Getter or setter method for the request payload.
     *
     * @param {string} [payload]
     * @returns {?(string|Ajax.Request)} May return `null`.
     */
  payload(payload) {
    return this._getsetString('_payload', arguments);
  }

  /**
     * Sets the callback function to be executed once
     * the response has arrived.
     *
     * The callback function receives one argument: this
     * <code>Request</code> instance.  The callback can
     * access the response via <code>request.response()</code>.
     *
     * @param {function} callback
     * @returns {Ajax.Request}
     */
  callback(callback) {
    if (typeof callback !== 'function') { throw new TypeError('callback: Function'); }

    this._callback = callback;
    return this;
  }

  /**
     * Getter and setter method for the timeout value of this
     * Request instance.
     * @param {int} [timeout] - The request timeout value, in milliseconds.
     * @returns {(int|Ajax.Request)}
     */
  timeout(timeout) {
    return _getsetTimeout(this, arguments);
  }


  /**
     * Getter or setter method for HTTP headers.
     * This method preserves the order of the headers
     * as they are set.
     *
     * This method returns <em>null</em> when attempting to
     * retrieve a header that has not been set.
     *
     * @param {string} name - Non-empty
     * @param {string} [value] - Non-empty
     * @returns {(string|Ajax.Request)}
     */
  header(name, value) {
    requireNonEmptyString(name, 'name');

    const hdrs = this._headers;
    const key = name.toLowerCase();

    if (typeof value === 'undefined') {
      if (key === 'content-type') return this.contentType();
      if (hdrs.index.hasOwnProperty(key)) return hdrs.index[key].value;
      return null;
    }

    requireNonEmptyString(value, 'value');

    if (key === 'content-type') {
      return this.contentType(value);
    }

    // Preserve the header set-order.

    if (hdrs.index.hasOwnProperty(key)) hdrs.index[key].value = value;

    else {
      hdrs.list.push(key);
      hdrs.index[key] = {
        name,
        value,
      };
    }

    return this;
  }

  /**
     * Getter or setter method to deal with data related
     * to this request.
     * @param {string} name - Non-empty
     * @param {*} [value]
     * @returns {*}
     */
  data(name, value) {
    requireNonEmptyString(name, 'name');

    let d = this._data;

    if (typeof value === 'undefined') {
      // getter

      if (d !== null
                && d.hasOwnProperty(name)) return d[name];
      return Objects.undef();
    }


    // setter

    if (d === null) {
      d = {};
      this._data = d;
    }

    d[name] = value;
    return this;
  }

  /**
     * Returns whether this request pertains to a domain or sub-domain
     * that differs from the domain in which the current page belongs.
     * @returns {boolean}
     */
  isXOrigin() {
    const info = Url.info(this._url);
    const loc = window.location; // global object

    return (info !== null
                && (info.protocol !== loc.protocol
                    || info.host !== loc.host));
  }

  /**
     * Returns whether a request is pending.
     * That is, a request that has been sent and
     * for which a response has not been received yet.
     * @returns {boolean}
     */
  isPending() {
    return this._isPending;
  }

  /**
     * Returns whether a request is complete.
     * That is, a request that has been sent and
     * for which a response has been received.
     * @returns {boolean}
     */
  isComplete() {
    return (this._resp !== null);
  }

  /**
     * Returns whether the request was cancelled
     * by the caller.
     * @returns {boolean}
     */
  isCancelled() {
    return this._isCancelled;
  }

  /**
     * Returns the response received from the server.
     * This method throws an exception if the request has
     * not been executed or if the server has not responded yet.
     * @returns {Ajax.Response}
     */
  response() {
    if (this._resp === null) { throw new Error('IllegalStateException: request is still pending.'); }

    return this._resp;
  }

  /**
     * Clones the request.  The returned request
     * is ready to be executed.
     * @returns {Ajax.Request}
     */
  clone() {
    const that = new Request();
    const hdrs = this._headers;

    that._client = this._client;
    that._url = this._url;
    that._type = this._type;
    that._contentType = this._contentType;
    that._charset = this._charset;
    that._payload = this._payload;
    that._callback = this._callback;

    // Even preserve the header set-order.
    for (let i = 0; i < hdrs.list.length; i++) {
      const key = hdrs.list[i];
      const hdr = hdrs.index[key];
      that.header(hdr.name, hdr.value);
    }

    return that;
  }

  /**
     * Executes the request.  Excessive requests
     * will be handled by the web-browser.
     */
  execute() {
    if (this._isPending === true
            || this._resp !== null) { throw new Error('IllegalStateException: cannot execute twice.'); }

    if (!Strings.isNonEmpty(this._url)) { throw new Error('IllegalStateException: URL is not set.'); }

    this._isPending = true;
    this._reqTime = Dates.currentTimeMillis();

    const xhr = this._getXhr();
    if (xhr !== null) { this._execute(xhr); }

    /* If xhr is null, we've reached the client's maximum
         * number of concurrent connections.
         * The Request instance has already been enqueued
         * by the client; it will be automatically sent
         * as soon as a bandwidth becomes available. */
  }

  _getXhr() {
    const c = this._client;
    if (c === null) {
      // We're not limited, just provide a requester now
      return _getXhr(this);
    }
    if (c._isDestroyed()) throw new Error('IllegalStateException: Client has been destroyed.');

    else return c._getXhr(this);
  }

  /**
     *
     * @param {XMLHttpRequest} xhr
     * @private
     */
  _execute(xhr) {
    if (_isHandledMock(this)) { return; }

    this._xhr = xhr;
    this._launchTime = Dates.currentTimeMillis();

    const async = this._async;
    if (async) { xhr.onreadystatechange = this._dz.bind(this); }

    xhr.open(this._type, this._url, async);

    if (this._payload !== null) {
      const contentType = `${this._contentType
      }; charset=${ this._charset}`;
      xhr.setRequestHeader('Content-Type', contentType);
    }

    const hdrs = this._headers;
    const numHdrs = hdrs.list.length;
    for (let i = 0; i < numHdrs; i++) {
      const key = hdrs.list[i];
      const hdr = hdrs.index[key];

      xhr.setRequestHeader(hdr.name, hdr.value);
    }


    _setTimeout(this);

    xhr.send(this._payload);

    if (!async) {
      this._resp = _loadXhr(new Response(this), xhr);

      this._markReceived();

      if (this._callback !== null) { this._callback(this); }
    }
  }

  /**
     * Returns the duration of the request, in milliseconds,
     * from the time the request was executed to the time
     * the server responded, including time waiting in queue
     * if applicable.
     *
     * This method returns zero (0) if the response has not been
     * received.
     * @returns {int} Duration of the request, in milliseconds.
     */
  duration() {
    const r = this._respTime;
    if (r !== null) return r - this._reqTime;
    return 0;
  }

  /**
     * Returns the length of time, in milliseconds,
     * that the request was waiting in the queue, if applicable.
     *
     * This method returns zero (0) if the request has not been
     * sent to the server yet.
     * @returns {int} Time that the request was waiting in queue, in milliseconds.
     */
  durationInQueue() {
    const l = this._launchTime;
    if (l !== null) return l - this._reqTime;
    return 0;
  }

  /**
     * Returns the duration of the request, in milliseconds,
     * that the request was waiting on the server for a response -
     * excluding the time the request might have waited in the queue.
     *
     * This method returns zero (0) if the response has not been
     * received.
     * @returns {int} Duration of the request, in milliseconds.
     */
  durationOnServer() {
    const r = this._respTime;
    const l = this._launchTime;
    if (r !== null
            && l !== null) return r - l;
    return 0;
  }

  /**
     * Aborts a request.
     */
  abort() {
    Console.log('Aborting request [{}]', this._url);

    this._isCancelled = true;
    this._abort();
  }

  _abort() {
    this._clearTimer();
    this._isPending = false;

    const xhr = this._releaseXhr();
    if (xhr !== null) { xhr.abort(); }

    const c = this._client;
    if (c !== null) {
      if (c._cancel(this)) { c._sendNext(); }
    }
  }

  /** @this {Ajax.Request} */
  _dz() {
    const xhr = this._xhr;

    if (xhr.readyState === 4) {
      this._respTime = Dates.currentTimeMillis();
      this._resp = _loadXhr(new Response(this), xhr);

      this._markReceived();

      /* Make sure the response object is constructed BEFORE
             * checking if the user is still authenticated; otherwise the
             * isUnauthenticated() and isTestRequest() functions won't
             * be able to retrieve the info they need to return accurate results. */
      if (Session.isUnauthenticated(this)
                && !Session.isTestRequest(this)
                && (!Objects.hasRuntimeObj('shutdown')
                    || !Runtime.shutdown.isExecuting())) {
        Session.authenticate();
      }

      if (this._callback !== null) { this._callback(this); }
    }
  }

  _releaseXhr() {
    const xhr = this._xhr;

    if (xhr !== null) {
      this._xhr = null; // release
      xhr.onreadystatechange = null;
    }

    return xhr;
  }

  _markReceived() {
    this._isPending = false;
    this._releaseXhr();

    this._clearTimer();

    const c = this._client;
    if (c !== null) { c._markReceived(this); }
  }

  _clearTimer() {
    const timer = this._timer;
    if (timer !== null) {
      this._timer = null;
      window.clearTimeout(timer);
    }
  }
}


/* ******************************************************
 * Class: Ajax.Response (private)
 * ****************************************************** */

/**
 * @constructor
 * @name Ajax.Response
 */
class Response {
  /**
     * @param {Ajax.Request} request
     */
  constructor(request) {
    this._req = request;
    /** @type {?int} */
    this._status = null;
    this._headers = null;
    /** @type {?string} */
    this._contentType = null;

    this._payload = {
      text: null,
      xml: null,
    };
  }

  isHtml() {
    return CONTENT_TYPE_HTML.test(this._contentType);
  }

  isXml() {
    return _isXml(this._contentType);
  }

  isJson() {
    return CONTENT_TYPE_JSON.test(this._contentType);
  }


  /**
     * Returns the request associated with the response.
     * @returns {Ajax.Request}
     */
  request() {
    return this._req;
  }

  /**
     * Returns the HTTP status code returned by the server.
     * @returns {int} HTTP status code.
     */
  statusCode() {
    return this._status;
  }

  /**
     * @returns {boolean} Whether status code is between 200 and 300 (exclusive).
     */
  isOk() {
    return (this._status >= 200
                && this._status < 300);
  }


  /**
     * Returns the String value associated with the HTTP header
     * <em>name</em>.  This method returns <em>null</em> if
     * <em>name</em> does not represent an HTTP header within
     * this response.
     *
     * @param name {String} Non-empty, case-insensitive.
     * @returns {?string}
     */
  header(name) {
    requireNonEmptyString(name, 'name');

    const key = name.trim().toLowerCase();
    const hdrs = this._headers;

    if (hdrs !== null
            && hdrs.hasOwnProperty(key)) return hdrs[key].value;
    return null;
  }

  /**
     * Returns the content-type of the response.
     * @returns {string}
     */
  contentType() {
    return this._contentType;
  }

  /**
     * Returns the raw/unparsed payload of this response.
     * @returns {string}
     */
  payloadAsString() {
    return this._payload.text;
  }

  /**
     * Returns an XML document created from the response payload.
     * This method returns <em>null</em> if the response is not
     * a XML response.
     * @returns {XMLDocument}
     */
  payloadAsXml() {
    return this._payload.xml;
  }

  /**
     * Returns a JSON object created from the response payload.
     * This method returns <em>null</em> if the response is not
     * a JSON response or if the parser fails to convert to JSON.
     * @returns {?Object}
     */
  payloadAsJson() {
    if (!this.isJson()) { return null; }

    try {
      return JSON.parse(this._payload.text);
    } catch (err) {
      // ignore it - default to null
      return null;
    }
  }
}

/**
 * A mocked response.
 * @constructor
 * @name Ajax.ResponseMock
 *
 * @param {int} httpCode - HTTP status code.
 * @param {string} payload - Response body, may be blank.
 * @param {string} [contentType="application/json"] - Response content type.
 * @param {Object.<Ajax.XPath, Ajax.XPath>} [copyFromRequest] - Values to copy
 *        from the original request into the mocked response.  Use this to mock RPC requests,
 *        to copy request IDs generated on-the-fly into the response.  For url-encoded
 *        payloads, the keys and values are flat strings to be copied in the response payload
 *        hash. For JSON payloads, the object contains {@link Ajax.XPath} values;
 *        we treat these strings as arrays where the key is a path
 *        from the request, the value is a path into the mocked response.
 */
function ResponseMock(httpCode, payload, contentType, copyFromRequest) {
  this._httpCode = requireInteger(httpCode, 'httpCode');
  this._payload = requireString(payload, 'payload');

  let cType = APP_JSON;
  let propsToCopy = null;
  const numArgs = arguments.length;

  if (numArgs > 2) {
    cType = requireNonEmptyString(contentType, 'contentType');

    if (numArgs > 3) {
      propsToCopy = Object.freeze(requireObject(copyFromRequest, 'copyFromRequest'));
    }
  }

  this._contentType = cType;

  /** @type {?Object.<Ajax.XPath, Ajax.XPath>} */
  this._xPathMap = propsToCopy;

  Object.freeze(this);
}

ResponseMock.prototype = /** @lends {Ajax.ResponseMock.prototype} */ {
  constructor: ResponseMock,

  /** @returns {int} HTTP status code. */
  statusCode() { return this._httpCode; },

  /** @returns {string} Content-type header. */
  contentType() { return this._contentType; },

  /** @returns {string} Response body. */
  payload() { return this._payload; },

  /**
     * @param {Ajax.Request} req - HTTP request, source of the values to copy into
     *        the response payload.
     * @returns {string} Response body/payload in which values from the request have
     *          been injected, if applicable.
     */
  payloadWithInjections(req) {
    const xPathMap = this._xPathMap;
    const payload = this._payload;

    if (xPathMap === null) return payload; // Nothing to inject, return immutable payload as-is.


    return _injectInPayload(
      _getValuesFromRequest(xPathMap, req),
      payload,
      this._contentType,
    );
  },
};

Object.freeze(ResponseMock);
Object.freeze(ResponseMock.prototype);

/**
 * A mocked request.
 *
 * @constructor
 * @name Ajax.RequestMock
 *
 * @param {string} method - HTTP method (GET, POST, PUT, DELETE, etc.)
 * @param {string} url - URL.
 * @param {?string} payload - Request body.
 * @param {string} [contentType="application/x-www-form-urlencoded"] - Content-type.
 */
function RequestMock(method, url, payload, contentType) {
  requireNonEmptyString(method, 'method');
  requireNonEmptyString(url, 'url');

  this._method = _validMethodType(method.toUpperCase());
  this._url = Strings.upTo(url, '?');
  this._qryStr = Object.freeze(Url.parseQueryStringInUrl(url));
  this._payload = requireStringOrNull(payload, 'payload');

  if (arguments.length > 3) { this._contentType = requireNonEmptyString(contentType, 'contentType'); } else { this._contentType = APP_URL_ENCODED; }

  Object.freeze(this);
}

RequestMock.prototype = /** @lends {Ajax.RequestMock.prototype} */ {
  constructor: RequestMock,

  /** @returns {string} URL. */
  url() { return this._url; },

  /** @returns {string} HTTP method type. */
  method() { return this._method; },

  /** @returns {Object.<string, string>} Query-string, immutable. */
  queryString() { return this._qryStr; },

  /** @returns {?string} Request body. */
  payload() { return this._payload; },

  /** @returns {string} Value of Content-type header. */
  contentType() { return this._contentType; },
};

Object.freeze(RequestMock);
Object.freeze(RequestMock.prototype);

/** @namespace */
const Ajax = Object.freeze(/** @lends {Ajax} */ {
  Client,
  Request,

  RequestMock,
  ResponseMock,

  // For backward compatibility
  HttpCode: HttpCodes,

  /**
     * Adds a mocked server call.
     * @param {Ajax.RequestMock} req
     * @param {Ajax.ResponseMock} resp
     */
  addMock(req, resp) {
    if (!(req instanceof RequestMock)) throw new TypeError('req: Ajax.RequestMock');

    if (!(resp instanceof ResponseMock)) throw new TypeError('resp: Ajax.ResponseMock');

    const key = _getMockKey(req._method, req._url);

    if (!_mocked.hasOwnProperty(key)) _mocked[key] = [];

    _mocked[key].push({
      request: req,
      response: resp,
    });

    return Ajax;
  },

  getMockExamples() {
    return [
      '',
      '// Example 1',
      'Ajax.addMock(',
      '        new Ajax.RequestMock(',
      '                "GET",',
      '                "/cdb/func/mp/remote-rsc?remRscPath=/lds/workflows/support/test",',
      '                null',
      '        ),',
      '        new Ajax.ResponseMock(',
      '                200,',
      '                "OK"',
      '        )',
      ');',
      '',
      '',
      '// Example 2',
      'Ajax.addMock(',
      '        new Ajax.RequestMock(',
      '                "POST",',
      '                "/cdb/func/mp/remote-rsc?remRscPath=lds/formulas/run",',
      '                JSON.stringify({',
      '                    "type": "JS",',
      '                    "formula": "typeof forward_curve",',
      '                    "propMap": {',
      '                        "formula.run_date": "2016-12-12",',
      '                        "formula.script_name": "Untitled1.js"',
      '                    }',
      '                })',
      '        ),',
      '        new Ajax.ResponseMock(',
      '                200,',
      '                "function"',
      '        )',
      ');',
      '',
    ].join('\n');
  },
});

export default Ajax;


// https://en.wikipedia.org/wiki/List_of_HTTP_status_codes

/** @namespace */
const HttpCodes = Object.freeze(/** @lends {HttpCodes} */ {

  /** 0 */ NO_NETWORK: 0,
  /** 200 */ OK: 200,
  /** 201 */ CREATED: 201,
  /** 202 */ ACCEPTED: 202,
  /** 204 */ NO_CONTENT: 204,
  /** 205 */ RESET_CONTENT: 205,
  /** 400 */ BAD_REQUEST: 400,
  /** 401 */ UNAUTHORIZED: 401,
  /** 403 */ FORBIDDEN: 403,
  /** 404 */ NOT_FOUND: 404,
  /** 405 */ METHOD_NOT_ALLOWED: 405,
  /** 406 */ NOT_ACCEPTABLE: 406,
  /** 415 */ UNSUPPORTED_MEDIA_TYPE: 415,
  /** 500 */ INTERNAL_SERVER_ERROR: 500,
  /** 501 */ NOT_IMPLEMENTED: 501,
  /** 502 */ BAD_GATEWAY: 502,
  /** 503 */ SERVICE_UNAVAILABLE: 503,
  /** 504 */ GATEWAY_TIMEOUT: 504,
});

export default HttpCodes;

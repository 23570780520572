<template>
  <div
    class="cmd-modal mds-modal  mds-modal--600px mds-modal--width-600px"
    :class="{'mds-modal--open  mds-modal--active':toggleMetadata}"
  >
    <div
      id="mds-modal-wrapper"
      class="mds-modal__wrapper"
    >
      <section
        role="dialog"
        class="mds-modal__container"
        aria-hidden="false"
      >
        <div class="mds-modal__inner">
          <header class="mds-modal__header">
            <h1 class="mds-modal__title">
              {{ popUpTitle }}
            </h1>
            <mds-button
              variation="icon-only"
              icon="remove"
              @click="hideModal"
            />
          </header>
          <div class="mds-modal__content">
            <template v-if="metadata.length">
              <ul>
                <li
                  v-for="(value, name) in metadata[0]"
                  :key="name"
                >
                  <strong> {{ name }}</strong> : {{ value }}
                </li>
              </ul>
            </template>
            <template v-else>
              {{ $t('labels.feed.noData') }}
            </template>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EventBus from '../../../main';

export default {
  name: 'Metadata',
  data() {
    return {
      toggleMetadata: false,
      popUpTitle: '',
    };
  },
  computed: {
    ...mapGetters({
      getMetadata: 'feedModule/getMetadata',
    }),
    metadata: {
      get() {
        if (this.getMetadata.length !== 0 && this.getMetadata[0]) {
          const metadataObj = {};
          Object.entries(this.getMetadata[0]).forEach(([key, value]) => {
            if (key.includes('mcd')) {
              const newKey = key.substring(key.indexOf('_') + 1);
              metadataObj[newKey] = value;
            } else {
              metadataObj[key] = value;
            }
          });
          // eslint-disable-next-line
          this.getMetadata[0] = metadataObj;
        }
        return this.getMetadata;
      },
      set() {
        if (this.metadata.length !== 0) {
          this.metadata.length = 0;
        }
        return this.metadata;
      },
    },
  },
  created() {
    EventBus.$on('showMetadataDialog', (metadata) => {
      this.showMetadata(metadata.symbol, metadata.feed, metadata.curveType);
    });
  },

  methods: {
    ...mapActions('feedModule', ['getMetadataForKeys', 'getMetadataForRoot']),
    hideModal() {
      this.toggleMetadata = false;
    },
    showMetadata(symbol, feed, curveType) {
      this.metadata = [];
      let keys = '';
      keys = decodeURIComponent(symbol);
      // keys = symbol.replace(/,/g, '&');
      if (curveType === 'timeSeries') {
        this.getTimeseriesMd(feed, keys);
      } else {
        this.getCurveMd(feed, keys);
      }
    },
    getTimeseriesMd(feed, keys) {
      this.getMetadataForKeys({ feedName: feed, keys }).then((response) => {
        this.popUpTitle = keys;
        this.toggleMetadata = true;
      });
    },
    getCurveMd(feed, keys) {
      keys = keys.substring(keys.indexOf('=') + 1);
      this.getMetadataForRoot({ feedName: feed, keys }).then((response) => {
        this.popUpTitle = keys;
        this.toggleMetadata = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cmd-modal .mds-modal  .mds-modal--600px .mds-modal--width-600px  {
  max-width: 600px;
}
</style>

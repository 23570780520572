import axios from 'axios';
import { getUserCompany, getUserName } from '../../../utils/authService';
import templateString from '../../../utils/stringUtils';
import getAPIConfigs from './config.js';

const config = getAPIConfigs();

let replacement;

const actions = {
  getLogs({ commit }, payload) {
    const { timeFrame } = payload;
    const { pageNo } = payload;
    const { noOfRecords } = payload;
    const { logType } = payload;
    const { company } = payload;
    const roles = payload.roles.toString();
    replacement = {
      TIME_FRAME: timeFrame,
    };
    let auditLogData = '';
    auditLogData = axios({
      method: 'get',
      url: templateString(config.GET_AUDIT_LOG, replacement),
      params: {
        pageNo,
        noOfRecords,
        logType,
        company,
        roles,
      },
    });

    return auditLogData.then((response) => {
      if (response.status === 204) {
        response.data = { logs: [], totalRecords: undefined };
      }
      commit('SET_LOGS', response.data);
      commit('SET_PAGE_STATUS', 'SUCCESS');
      return response;
    }).catch((errors) => {
      commit('SET_PAGE_STATUS', 'ERROR');
      return errors;
    });
  },

  getBBLicense({ commit }, payload) {
    const USER_NAME = getUserName();
    return axios.get(`api/auditlog/bloomberg/license/${USER_NAME}`).then(response => response).catch((errors) => {
      commit('SET_PAGE_STATUS', 'ERROR');
      return errors;
    });
  },

  getDataAlertLogs({ commit }, payload) {
    return axios
      .post(templateString(config.GET_DATA_ALERT_LOG), payload).then((response) => {
        if (response.status === 204) {
          response.data = { logs: [], totalRecords: undefined };
        }
        commit('SET_DATA_ALERT_LOGS', response.data);
        commit('SET_PAGE_STATUS', 'SUCCESS');
        return response;
      }).catch((errors) => {
        commit('SET_PAGE_STATUS', 'ERROR');
        return errors;
      });
  },

  getDataAlertFeeds({ commit }) {
    const COMPANY_NAME = getUserCompany();
    const USER_NAME = getUserName();
    replacement = {
      COMPANY_NAME,
      USER_NAME,
    };
    return axios
      .get(templateString(config.GET_DATAALERT_FEEDS_API, replacement))
      .then((response) => {
        commit('SET_DA_FEEDS', response.data);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },

  saveFeedsForUser({ commit }, payload) {
    const COMPANY_NAME = getUserCompany();
    const USER_NAME = getUserName();
    replacement = {
      COMPANY_NAME,
      USER_NAME,
    };
    return axios
      .post(templateString(config.SAVE_DATAALERT_FEEDS_API, replacement), payload)
      .then(response => response)
      .catch((error) => {
        throw error;
      });
  },

  deleteFeedsForUser({ commit }, payload) {
    const COMPANY_NAME = getUserCompany();
    const USER_NAME = getUserName();
    replacement = {
      COMPANY_NAME,
      USER_NAME,
    };
    return axios
      .put(templateString(config.DELETE_DATAALERT_FEEDS_API, replacement), payload)
      .then(response => response)
      .catch((error) => {
        throw error;
      });
  },

};


export default actions;

<template>
  <div class="vue-csv-uploader">
    <div class="form">
      <div class="vue-csv-uploader-part-one">
        <div class="form-group csv-import-file">
          <input
            ref="csv"
            type="file"
            name="csv"
            @change.prevent="validFileMimeType"
          >
          <div
            v-if="showErrorMessage"
            class="invalid-file"
          >
            {{ $t('labels.bbLoader.invalidFile') }}
          </div>
        </div>
        <div class="form-group csv-import-checkbox">
          <mds-checkbox
            :checked="hasHeaders"
            @change.prevent="hasHeaders = !hasHeaders"
          >
            {{ $t('labels.bbLoader.hasHeaders') }}
          </mds-checkbox>
        </div>
        <div class="form-group">
          <mds-button
            v-show="disabledNextButton"
            size="small"
            variation="secondary"
            disabled
          >
            {{ $t('labels.common.upload') }}
          </mds-button>
          <mds-button
            v-show="!disabledNextButton"
            size="small"
            @click.prevent="load"
          >
            {{ $t('labels.common.upload') }}
          </mds-button>
        </div>
      </div>
      <div>
        <div
          v-if="showInstrumentMappings"
          class="vue-csv-mapping"
        >
          <mds-layout-grid>
            <mds-row>
              <mds-col v-if="instruments.length > 0">
                <p>
                  <span style="font-weight:bold;">{{
                    $t('labels.bbLoader.validInstruments')
                  }}</span>
                  <span> [{{ $t('labels.common.count') + ': ' + instruments.length }}]</span>
                </p>
                <ul>
                  <li
                    v-for="(obj, index) in instruments"
                    :key="'inst' + index"
                  >
                    {{ obj.name + ' - ' + obj.curve + ' - ' + obj.tenor + ' - ' + obj.yellowkey }}
                  </li>
                </ul>
              </mds-col>
              <mds-col v-if="ignoredInstruments.length > 0">
                <p>
                  <span style="font-weight:bold;">{{
                    $t('labels.bbLoader.invalidInstruments')
                  }}</span>
                  <span> [{{ $t('labels.common.count') + ': ' + ignoredInstruments.length }}]</span>
                </p>
                <ul>
                  <li
                    v-for="(obj, index) in ignoredInstruments"
                    :key="'inst' + index"
                  >
                    {{ obj }}
                  </li>
                </ul>
              </mds-col>
            </mds-row>
          </mds-layout-grid>
        </div>
      </div>
    </div>

    <div class="form-group right-aligned">
      <mds-button
        variation="secondary"
        @click="cancel"
      >
        {{ $t('labels.common.cancel') }}
      </mds-button>
      <mds-button
        variation="primary"
        @click="submit"
      >
        {{ $t('labels.common.save') }}
      </mds-button>
    </div>
  </div>
</template>
<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { checkAlphaNumeric, checkAlphaNumericWithExceptions } from '../../utils/stringUtils';

export default {
  name: 'InstrumentImport',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
  },
  props: {
    delimiter: {
      type: String,
      default: ',',
    },
  },
  data: () => ({
    hasHeaders: true,
    isValidFileMimeType: false,
    fileSelected: false,
    instruments: [],
    ignoredInstruments: [],
    fileMimeTypes: ['text/csv', 'text/x-csv', 'application/vnd.ms-excel', 'text/plain'],
    showInstrumentMappings: true,
    fileError: false,
  }),
  computed: {
    showErrorMessage() {
      return (this.fileSelected && !this.isValidFileMimeType) || this.fileError;
    },
    disabledNextButton() {
      return !this.isValidFileMimeType;
    },
  },
  methods: {
    submit() {
      this.$emit('save', this.instruments);
    },
    cancel() {
      this.$emit('cancel', []);
    },
    validFileMimeType() {
      const file = this.$refs.csv.files[0];
      this.showInstrumentMappings = false;
      if (file) {
        this.fileSelected = true;
        this.isValidFileMimeType = this.validateMimeType(file.type);
      } else {
        this.isValidFileMimeType = false;
      }
    },
    validateMimeType(type) {
      return this.fileMimeTypes.indexOf(type) > -1;
    },

    parseCsvToJson(csvTxt, delimiter) {
      this.instruments = [];
      this.ignoredInstruments = [];
      csvTxt.split('\n').map((ln) => {
        if (ln) {
          const vals = ln.trim().split(delimiter);

          const flagA = vals[0] === '' || vals[1] === '' || vals[3] === '';
          const flagB = !(checkAlphaNumericWithExceptions(vals[0]) && checkAlphaNumeric(vals[1]));
          const flagC = vals[2] !== '' && !checkAlphaNumeric(vals[2]);

          if (flagA || flagB || flagC) {
            this.ignoredInstruments.push(ln);
          } else {
            this.instruments.push({
              name: vals[0].trim(),
              curve: vals[1].trim(),
              tenor: vals[2].trim(),
              yellowkey: vals[3].trim(),
            });
          }
        }
        return '';
      });
      if (this.hasHeaders) {
        this.instruments.splice(0, 1);
      }
      this.showInstrumentMappings = true;
    },

    load() {
      const _this = this;
      const file = this.$refs.csv.files[0];
      if (file) {
        const reader = new FileReader();

        reader.readAsText(file, 'UTF-8');
        reader.onload = (evt) => {
          _this.parseCsvToJson(evt.target.result, _this.delimiter);
        };
        reader.onerror = () => {
          _this.fileError = true;
        };
      }
    },
  },
};
</script>
<style scoped>
.form-group {
  margin-top: 10px;
}
.invalid-file {
  margin-top: 10px;
  color: red;
}

.right-aligned {
  float: right;
}

.right-aligned mds-button + mds-button {
  margin-left: 10px;
}
</style>

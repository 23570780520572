const mutations = {
  ADD_SITE_MENU_ITEMS: (state, menuItems) => {
    state.siteMenuItems.push(...menuItems);
  },
  DEL_SITE_MENU_ITEM: (state, itemID) => {
    state.siteMenuItems = state.siteMenuItems.filter(item => item.id !== itemID);
  },
};

export default mutations;

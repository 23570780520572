<template>
  <div class="entitlements-slidein">
    <div>
      <div class="border-bottom-solid">
        <div class="mds-header__title component-title">
          <small>{{ $t('labels.dataAlert.configuration') }}</small>
        </div>
      </div>
      <template v-if="loader">
        <overlay />
      </template>
      <div
        v-show="loader == true"
        class="div-centered"
      >
        <mds-loader />
      </div>
      <mds-layout-grid>
        <mds-row id="config-row">
          <mds-col :cols="3">
            <DataSource :datasource-list="activeDatasource" />
          </mds-col>
          <mds-col :cols="4">
            <FeedSource
              :key="sourceChange"
              :feed-list="feedOptions"
            />
          </mds-col>
          <mds-col :cols="5">
            <entitled-feed
              :feed-entitlements="entitledFeeds"
              :initial-feed-entitlements="initialFeedEntitlements"
              :removed-list="removedList"
            />
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import FeedSource from './FeedSource.vue';
import EventBus from '../../main';
import Overlay from './Overlay.vue';
import DataSource from './DataSource';
import EntitledFeed from './EntitledFeed.vue';

export default {
  name: 'DataAlertEntilements',
  components: {
    Overlay, FeedSource, DataSource, EntitledFeed, MdsLayoutGrid, MdsRow, MdsCol,
  },
  data() {
    return {
      loader: '',
      removedList: [],
      initialFeedEntitlements: [],
      userFeeds: [],
      sourceChange: false,
      feedOptions: [],
      activeDatasource: [],
      addedFeeds: [],
      removedFeeds: [],
    };
  },
  computed: {
    ...mapGetters({
      userDatasource: 'feedModule/getUserDatasources',
      feedList: 'feedModule/getUserFeedList',
      dataAlertFeeds: 'auditLogModule/getDataAlertFeeds',
    }),
    entitledFeeds() {
      return this.getEntitledFeeds();
    },
  },
  created() {
    this.loader = true;
    EventBus.$emit('hideOverLay', true);
    this.$store.state.auditLogModule.dataAlertFeeds = [];
    this.initialFeedEntitlements = [];
    this.getUserDatasources().then(() => {
      this.activeDatasource = this.userDatasource.sort((a, b) => a.value.localeCompare(b.value));
      this.getDataAlertFeeds().then((response) => {
        if (response.status === 200) {
          this.initialFeedEntitlements = Object.assign([], this.getEntitledFeeds());
        }
        this.loader = false;
      });
    });

    EventBus.$on('showLoader', (value) => {
      this.loader = value;
    });

    EventBus.$on('showFeeds', (value) => {
      this.getSourceName(value);
    });

    EventBus.$on('addEntitlements', (value) => {
      this.addEntitlements(value);
    });

    EventBus.$on('removeEntitlements', (value) => {
      this.removeEntitlements(value);
    });
  },
  methods: {
    ...mapActions('feedModule', ['getUserDatasources']),
    ...mapActions('auditLogModule', ['getDataAlertFeeds']),
    getSourceName(event) {
      const editedFeeds = this.addedFeeds.filter(item => !this.removedFeeds.includes(item));
      const finalUserFeeds = this.userFeeds.filter(element => !this.removedFeeds.includes(element));
      const feedNames = [...finalUserFeeds, ...editedFeeds];
      const sourceName = event.value;
      this.feedOptions = this.feedList
        .filter(element => element.datasource === sourceName)
        .flatMap(element => element.feeds)
        .filter(feed => !feedNames.includes(feed.name))
        .map(element => ({
          text: element.name,
          value: element.name,
        }))
        .sort((a, b) => a.text.localeCompare(b.text), undefined, {
          sensitivity: 'base',
        });
      this.sourceChange = true;
    },
    getEntitledFeeds() {
      const users = [];
      this.dataAlertFeeds.forEach((feed) => {
        if (feed != null) {
          this.userFeeds.push(feed);
          users.push({
            text: feed,
            value: feed,
          });
        }
      });
      return users.sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
    },
    addEntitlements(name) {
      this.entitledFeeds.push({
        text: name.text,
        value: name.value,
      });
      this.addedFeeds.push(name.text);
      this.feedOptions = this.feedOptions
        .filter(feed => feed.text !== name.text)
        .sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }));
      this.entitledFeeds.sort((a, b) => a.text.localeCompare(b.text), undefined, {
        sensitivity: 'base',
      });
      this.removedList = this.removedList.filter(feed => feed.text !== name.text);
    },
    removeEntitlements(ent) {
      this.entitledFeeds.splice(
        this.entitledFeeds.findIndex(feed => feed.text === ent.text),
        1,
      );

      if (this.entitledFeeds.filter(u => u.text === ent.text).length <= 0) {
        this.feedOptions.push({
          text: ent.text,
          value: ent.value,
        });
        this.feedOptions.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }));
        this.removedFeeds.push(ent.text);
      }

      this.removedList.push({
        text: ent.text,
        value: ent.value,
      });
      this.entitledFeeds.sort((a, b) => a.text.localeCompare(b.text), undefined, {
        sensitivity: 'base',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';
@import 'src/assets/styles/components/custom/feed_entitlements';

  .component-title {
    @include mds-level-1-heading($bold: false);
    margin-top: 5px;
    color: #162b3d;
  }
</style>
<style scoped>

/* eslint-disable no-use-before-define */
import _ from 'underscore';
import Strings, {
  isString,
  isNonEmptyString,
  requireString,
  requireNonEmptyString,
  firstString,
} from './strings.es6';
import { requireNumber } from './numbers.es6';
import Objects, { isVoid, requireObject } from './objects.es6';
import Arrays from './arrays.es6';
import Serializable from './serializable.es6';

/**
 * @typedef Object Symbol.Column
 * @property {string} cName Column name.
 * @property {string} cDesc Column description.
 * @property {string} baseCurrency Base currency from origin exchange.
 * @property {string} baseUnit Base unit from origin exchange
 * @property {Object.<string, Object.<string, number>>} conversions
 * @property {string} [DailyDataFrom] Lowest daily data date (yyyy-MM-ddTHH:mm:ss).
 * @property {string} [DailyDataTo] Highest daily data date (yyyy-MM-ddTHH:mm:ss).
 * @property {string} [MinDataFrom] Lowest intraday data date (yyyy-MM-ddTHH:mm:ss).
 * @property {string} [MinDataTo] Highest intraday data date (yyyy-MM-ddTHH:mm:ss).
 * @property {MimApi.RtdMapInfo} [tenforeData] RTD mapping info.
 * @property {MimApi.MpMapInfo} [mpData] Marketplace mapping info.
 */

/**
 * @typedef Object Symbol.Deconstructed
 * @property {string} source
 * @property {string} fullName
 * @property {string} displayName
 * @property {string} description
 * @property {string} type
 * @property {string} column
 * @property {string} tradingSession
 * @property {?Symbol.Column[]} columns
 * @property {Object.<string, Object.<string, number>>} currencyTable
 * @property {Object.<string, Object.<string, number>>} uomTable
 */

const REGEX_RTD_INSTRUMENT = new RegExp('^\\d+\\.\\d+\\.(.+)$');
const REGEX_RTD_FIELD = new RegExp('^X_(TFFIELD|CDB)_(\\w+)$', 'i');
const REGEX_CDB_COLUMN = new RegExp('^Z_CDB_(\\w+)$', 'i');

/** @type {Object.<string, string>} */
const X_CDB_FIELDS = _.indexBy([
  'SETTLEMENT',
  'SETTLEMENT_DATETIME',
  'ACTIVE_SETTLEMENT',
  'PREV_SETTLEMENT',
  'BID_PRICE',
  'ASK_PRICE',
]);

/** @type {Object.<string, string>} */
const Z_CDB_FIELDS = _.indexBy([
  'CHG',
  'CHG_PERCENT',
]);

/**
 * @param {(Symbol|*)} sym
 * @returns {boolean} Whether `sym` is an instance of Symbol.
 */
export function isSymbol(sym) {
  return (sym instanceof Symbol);
}

/**
 * Validates an argument to be an instance of Symbol
 * @param {Symbol} sym Argument to validate.
 * @param {string} argName Name given to `sym` for when error must be thrown.
 * @returns {Symbol} Always returns `sym`.
 * @throws {TypeError} If `sym` is not a Symbol.
 */
export function requireSymbol(sym, argName) {
  if (!isSymbol(sym)) {
    throw new TypeError(`${argName }: Symbol`);
  }
  return sym;
}

/**
 *
 * @param {string} uom Unit of measure.
 * @param {number} factor Non-zero conversion factor.
 * @param {Array.<Object.<string, Object.<string, number>>>} tables
 * @returns {{map: Object.<string, number>, chain: string[]}}
 * @private
 */
function _availConvSimple(uom, factor, tables) {
  // Barfing while trying to convert using UOM or Currency isn't worth the pain.
  // Instead, return empty conversion mappings.
  //
  // requireString(uom, "uom");

  if (requireNumber(factor, 'factor') === 0) {
    throw new Error('factor must be non-zero number');
  }

  Arrays.requireNonEmpty(tables, 'tables');

  const straight = {};
  const reversed = [];

  for (const table of tables) {
    if (table !== null) {
      _.each(table, (convInfo, fromUOM) => {
        for (const toUOM of Object.keys(convInfo)) {
          if (uom === fromUOM) { straight[toUOM] = factor * convInfo[toUOM]; } else if (uom === toUOM) {
            straight[fromUOM] = factor * (1 / convInfo[toUOM]);
            reversed.push(fromUOM);
          }
        }
      });
    }
  }

  return {
    map: straight,
    chain: reversed,
  };
}

/**
 * @param {string} tfField Recognized RTD field name.
 * @returns {?string} Underlying RTD field (D4, D2, etc.), or null if it can't.
 * @throws {Error} If `tfField` is not a recognized RTD field name.
 * @private
 */
function _rawTFField(tfField) {
  const match = REGEX_RTD_FIELD.exec(tfField);
  if (match === null) { throw `IllegalArgumentException: tfField is not recognized (${ tfField }).`; }

  const m1 = match[1].toUpperCase();
  const m2 = match[2].toUpperCase();
  let raw = null;

  if (m1 === 'CDB') {
    if (m2 === 'SETTLEMENT') { raw = 'D34'; }
  } else if (m1 === 'TFFIELD') { raw = m2; }

  return raw;
}

/**
 * TODO typedef for `col`
 * @param {???} col
 * @param {boolean} [entitledOnly]
 * @returns {boolean} Whether `col` has a valid mapping to RTD stream.
 * @private
 */
function _isTFMapped(col, entitledOnly) {
  return (col.tenforeData
            && typeof col.tenforeData.field === 'string'
            && typeof col.tenforeData.rate === 'string'
            && (entitledOnly !== true
                || col.tenforeData.rate !== 'NO_ENTITLEMENT'));
}

function _buildVirtualCol(colName, colDescr, tfData, tfField) {
  const col = {
    cName: colName,
    cDesc: colDescr,

    frequencies: {},
    conversions: {},

    tenforeData: null,
  };

  if (tfData !== null) {
    col.tenforeData = {
      symbol: tfData.symbol,
      field: tfField,
      rate: tfData.rate,
    };
  }

  return col;
}

/**
 *
 * @param {string} uom Unit of measure.
 * @param {Array.<Object.<string, Object.<string, number>>>} tables
 * @returns {Object.<string, number>}
 * @private
 */
function _availConv(uom, tables) {
  const info = _availConvSimple(uom, 1, tables);
  const { map } = info;

  for (let i = 0; i < info.chain.length; i++) {
    const chainUOM = info.chain[i];
    const chainInfo = _availConvSimple(chainUOM, map[chainUOM], tables);
    const chainMap = chainInfo.map;

    for (const uom2 in chainMap) {
      if (chainMap.hasOwnProperty(uom2)
                && !map.hasOwnProperty(uom2)) {
        map[uom2] = chainMap[uom2];
      }
    }
  }

  return map;
}

/**
 * @param {string} fromUOM
 * @param {string} toUOM
 * @param {Array.<Object.<string, Object.<string, number>>>} tables
 * @returns {?number} Conversion factor, or null.
 * @private
 */
function _convFactor(fromUOM, toUOM, tables) {
  if (fromUOM === toUOM) { return 1; }

  const map = _availConv(fromUOM, tables);

  requireString(toUOM, 'toUOM');

  return (map.hasOwnProperty(toUOM) ? map[toUOM] : null);
}

/**
 *
 * @param {string} uom
 * @param {Array.<Object.<string, Object.<string, number>>>} tables
 * @returns {string[]} List of possible units/currencies to which conversion is supported.
 * @private
 */
function _getConvNames(uom, tables) {
  const uoms = Object.keys(_availConv(uom, tables));
  Arrays.remove(uom, uoms);
  return uoms;
}


/**
 * A MIM symbol (aka CDS symbol), with mapping info to other systems such as MP, RTD.
 */
class Symbol {
  constructor(sym) {
    /* The following fields need to be initialized
         * to prevent previously serialized Symbols from failing
         * to instantiate.
         * We need to be careful in how we code new fields in the future, so that
         * deserialization doesn't break stuff. */

    /** @type {?MimApi.RtdMapInfo} */
    this.tenforeData = null;

    /** @type {string} */
    this._tradingSess = null;
    /** @type {?MimApi.MpMapInfo} */
    this._mpData = null;

    /** @type {?Symbol.Column[]} */
    this._columns = null;

    /** @type {?Object.<string, Symbol.Column>} */
    this._colsByName = null;

    /** @type {?Object.<string, Object.<string, number>>} */
    this._uomConv = null;

    /** @type {?Object.<string, Object.<string, number>>} */
    this._currencyConv = null;

    /** @type {?Object.<string, Object.<string, number>>} */
    this._colConv = null;

    /** @type {?string} */
    this._mimUOM = null;

    /** @type {?string} */
    this._mimCurrency = null;

    /** @type {?string} */
    this._rtUOM = null;

    /** @type {?string} */
    this._rtCurrency = null;

    /** @type {Object.<string, *>} */
    this._data = {};

    /**
         * Source system: MIM, MSS, RT.
         * @type {string}
         */
    this.source = '';

    /**
         * Full name, including system path (w/o source).
         * @type {string}
         */
    this.fullName = '';

    /**
         * Short symbol name, mostly used to display to users.
         * @type {string}
         */
    this.displayName = '';

    /**
         * Symbol description.
         * @type {string}
         */
    this.description = '';

    /**
         * Symbol type: NORMAL, FUTURES, FUTURES_CONTINUOUS, FUTURES_CONTRACT, UNKNOWN.
         * @type {string}
         */
    this.type = '';

    /**
         * Column name.
         * @type {string}
         */
    this.column = '';

    /** @type {MimApi.Frequencies} */
    this.frequencies = {};

    if (arguments.length > 0) {
      requireObject(sym, 'sym');
      if (!isString(sym.fullName)
                && !isString(sym.displayName)) {
        throw new Error('Either `sym.fullName` or `sym.displayName` is required (string)');
      }

      this.source = requireNonEmptyString(sym.source, 'sym.source');
      this.fullName = firstString(sym.fullName, sym.displayName);
      this.displayName = firstString(sym.displayName, sym.fullName);
      this.description = firstString(sym.description, '');
      this.type = firstString(sym.type, '');
      this.column = firstString(sym.column, '');

      if (Objects.is(sym.frequencies)) {
        this.frequencies = sym.frequencies;
      }
      if (sym.columns) {
        this._columns = sym.columns;
      }
      if (_.has(sym, 'tenforeData')) {
        this.tenforeData = sym.tenforeData;
      }
      if (_.has(sym, 'mpData')) {
        this._mpData = sym.mpData;
      }
      if (_.has(sym, 'uomConversions')) {
        this._uomConv = sym.uomConversions;
      }
      if (_.has(sym, 'currencyConversions')) {
        this._currencyConv = sym.currencyConversions;
      }
      if (isString(sym.tradingSession)) {
        this._tradingSess = sym.tradingSession;
      }

      this._finalize();
    }
  }

  /**
     * Returns whether `that` represents the same
     * symbol as `this`.  Note that while
     * instances of Symbol can be equal, they could
     * also be in different states.  For example, one
     * Symbol instance could have more frequencies
     * (if a time-store was added to the newest instance when
     * compared to an older instance), or the date range
     * on a particular time-store could have changed,
     * or the mapped Tenfore symbol might have changed, etc.
     * To compare the state of two Symbol instances,
     * use `Objects.areEqual(sym1, sym2, true)`.
     * @param {Symbol} that
     * @returns {boolean} Whether `that` represents the same symbol as this instance.
     */
  equals(that) {
    return (isSymbol(that) && this._uniqueId === that._uniqueId);
  }

  /** @returns {string} Unique identifier. */
  uniqueId() {
    return this._uniqueId;
  }

  /**
     * Deprecated. Use {@link Symbol.name name()} instead.
     * @returns {string}
     * @deprecated
     */
  chartName() {
    return this._name;
  }

  /** @returns {string} Symbol name, short. */
  name() {
    return this._name;
  }

  /** @returns {string} Name of trading session. */
  tradingSession() {
    return this._tradingSess;
  }

  /**
     * Gets (and optionally sets) a named value.
     * @param {string} name Name of the value to get or set.
     * @param {*} value
     * @returns {*} Latest value associated with `name`.
     */
  data(name, value) {
    requireNonEmptyString(name, 'name');

    if (arguments.length > 1) { this._data[name] = value; } else if (this._data.hasOwnProperty(name)) { value = this._data[name]; } else { value = null; }

    return value;
  }

  /** @returns {int} Number of columns available for this symbol, 0 if not fully initialized. */
  numColumns() {
    if (this._columns === null) return 0;
    return this._columns.length;
  }

  /**
     * @param {boolean} [listedOnly=false] Whether to only return documented columns
     *        (or include the current method if it's missing).
     * @returns {string[]} List of column names.
     */
  availableColumns(listedOnly) {
    const names = (this._columns !== null ? this._columns.map(c => c.cName) : []);

    if (listedOnly !== true) {
      Arrays.addIfMissing(this.column, names);
    }

    return names;
  }

  /**
     * @returns {string[]} List of selected column name(s).
     */
  splitColumns() {
    const names = [];

    if (this.column === 'OHLC') {
      names.push('Open', 'High', 'Low', 'Close');
    } else {
      names.push(this.column);
    }

    return names;
  }

  /**
     * @returns {string[]} List of selected symbol's name and column name paired together.
     */
  splitNameWithColumns() {
    const colnames = [];
    let symName = this._name;

    const symColNames = [];

    if (this.column === 'OHLC') {
      symName = this.displayName;
      colnames.push('Open', 'High', 'Low', 'Close');

      for (let i = 0; i < colnames.length; i++) {
        symColNames.push(`${symName } ${ colnames[i]}`);
      }
    } else {
      symColNames.push(symName);
    }

    return symColNames;
  }


  _firstTFMap() {
    let firstMap = null;
    const cols = this._columns;

    if (cols !== null) {
      for (let i = 0;
        i < cols.length && firstMap === null;
        i++) {
        const col = cols[i];
        if (_isTFMapped(col, false)) { firstMap = col.tenforeData; }
      }
    } else if (_isTFMapped(this, false)) { firstMap = this.tenforeData; }

    return firstMap;
  }


  _getTFMap(colName) {
    const key = colName.toUpperCase();
    const cols = this._colsByName;
    let map = null; // default to null

    if (cols !== null) {
      if (cols.hasOwnProperty(key)) {
        const col = cols[key];
        if (_isTFMapped(col, false)) { map = col.tenforeData; }
      }
    } else if (Strings.equalsIgnoreCase(this.column, colName)
                 && _isTFMapped(this, false)) { map = this.tenforeData; }

    return map;
  }

  /** @returns {boolean} Whether this symbol's column is a virtual column. */
  isLocalColumn() {
    return Symbol.isLocalColumn(this.column);
  }

  /** @returns {boolean} Whether this symbol's column is an RTD column. */
  isTenforeColumn() {
    return Symbol.isTenforeColumn(this.column);
  }

  /** @returns {boolean} Whether this symbol's column is a native MIM (or *fake*) column. */
  isNative() {
    return (!this.isTenforeColumn()
                && !this.isLocalColumn());
  }

  /** @returns {boolean} Whether MP mapping exists for this symbol. */
  hasMarketplaceData() {
    const mpd = this._mpData;
    return (!isVoid(mpd)
                && isNonEmptyString(mpd.feed));
  }

  /**
     * @returns {string} Time-unit pertaining to Marketplace.
     * @throws {ReferenceError} If this symbol doesn't have a MP mapping.
     */
  getMPTimeUnits() {
    return Objects.clone(this._mpData.time_units);
  }

  /**
     * @returns {MimApi.MpDataRange} Data range pertaining to Marketplace.
     * @throws {ReferenceError} If this symbol doesn't have a MP mapping.
     */
  getMPDataRange() {
    return Objects.clone(this._mpData.data_range);
  }

  /**
     * @param {string} upperColName Column name, upper-cased.
     * @returns {boolean} Whether the given column name is declared by this symbol.
     * @private
     */
  _hasNativeCol(upperColName) {
    return this._colsByName.hasOwnProperty(upperColName);
  }

  /**
     * @param {string} columnName Column name.
     * @returns {boolean} Whether the named column is declared or supported by this symbol.
     */
  containsColumn(columnName) {
    requireNonEmptyString(columnName, 'columnName');
    this._setColsByName();

    const colName = columnName.toUpperCase();
    return (this._hasNativeCol(colName)
                || (Symbol.isTenforeColumn(colName)
                    && this._firstTFMap() !== null)
                || (Symbol.isLocalColumn(colName)
                    && this._hasNativeCol('CLOSE')));
  }


  /**
     * @param {string} columnName Column name.
     * @returns {?Symbol.Column} The named column object, null if `columnName` is not declared
     *          or supported.
     */
  columnByName(columnName) {
    requireNonEmptyString(columnName, 'columnName');
    this._setColsByName();

    const colName = columnName.toUpperCase();
    const colsByName = this._colsByName;

    if (colsByName.hasOwnProperty(colName)) {
      return colsByName[colName];
    } if (Symbol.isTenforeColumn(colName)) {
      const map = this._firstTFMap();
      if (map !== null) {
        return _buildVirtualCol(
          colName,
          `Tenfore hard-coded field ${ colName}`,
          map,
          _rawTFField(colName),
        );
      }
    } else if (Symbol.isLocalColumn(colName)) {
      if (this._hasNativeCol('CLOSE')) {
        return _buildVirtualCol(
          colName,
          `Virtual column ${ colName}`,
          this._getTFMap('Close'),
          null,
        );
      }
    }

    return null;
  }

  /** Lazy-initialization method for `_colsByName` property. */
  _setColsByName() {
    if (this._colsByName !== null) { return; }

    if (this._columns === null) { throw new Error('`_columns` is null'); }

    this._colsByName = _.indexBy(this._columns, col => col.cName.toUpperCase());
  }

  /**
     * Safely returns the Column object associated with the symbol's column.
     * @returns {?Symbol.Column} Column object for the symbol's named column.
     * @private
     */
  _safeCol() {
    if (this._columns === null) return null;
    return this.columnByName(this.column);
  }

  /** @returns {?string} Default currency (dollars, euros, etc.) used of the symbol's data. */
  baseCurrency() {
    if (this._rtCurrency !== null) return this._rtCurrency;
    return this._mimCurrency;
  }

  /** @returns {?string} Default unit of measure used for the symbol's data. */
  baseUOM() {
    if (this._rtUOM !== null) return this._rtUOM;
    return this._mimUOM;
  }

  /**
     * @param {string} toUOM Unit of measure to convert to.
     * @param {string} [fromUOM=baseUOM()] Unit of measure to convert from.
     * @returns {?number} Conversion factor, null if not supported.
     */
  uomConversion(toUOM, fromUOM) {
    return _convFactor(
      ((arguments.length > 1) ? fromUOM : this.baseUOM()),
      toUOM,
      [this._uomConv, this._colConv],
    );
  }

  /**
     * @param {string} toCurrency Currency to convert to.
     * @param {string} fromCurrency Currency to convert from.
     * @returns {?number} Conversion factor, null if not supported.
     */
  currencyConversion(toCurrency, fromCurrency) {
    return _convFactor(
      ((arguments.length > 1) ? fromCurrency : this.baseCurrency()),
      toCurrency,
      [this._currencyConv, this._colConv],
    );
  }

  /**
     * @param {string} uom Unit of measure to convert from.
     * @returns {string[]} List of unit of measures to which converting is supported from `uom`.
     */
  availableUOM(uom) {
    return _getConvNames(
      (!isVoid(uom) ? uom : this.baseUOM()),
      [this._uomConv, this._colConv],
    );
  }

  /**
     * @param {string} currency Currency to convert from.
     * @returns {string[]} List of currencies to which converting is supported from `currency`.
     */
  availableCurrency(currency) {
    return _getConvNames(
      (!isVoid(currency) ? currency : this.baseCurrency()),
      [this._currencyConv, this._colConv],
    );
  }

  /**
     * @param {string} dataSource "MIM", "MSS" or "RT".
     * @returns {number} Conversion factor, looking for UOM first, then Currency.
     */
  dataConversion(dataSource) {
    requireString(dataSource, 'dataSource');

    if (dataSource === 'MIM') {
      let factor = 1;

      if (this._rtUOM !== null
                && this._mimUOM !== null
                && this._rtUOM !== this._mimUOM) {
        const tmpFactor = this.uomConversion(this._rtUOM,
          this._mimUOM);
        if (tmpFactor !== null) factor *= tmpFactor;
      }

      if (this._rtCurrency !== null
                && this._mimCurrency !== null
                && this._rtCurrency !== this._mimCurrency) {
        const tmpFactor = this.currencyConversion(this._rtCurrency,
          this._mimCurrency);
        if (tmpFactor !== null) factor *= tmpFactor;
      }

      return factor;
    }
    if (dataSource === 'MSS'
                 || dataSource === 'RT') return 1;
    throw new Error(`unrecognized dataSource: ${ dataSource}`);
  }


  /**
     * @param {string} columnName
     * @returns {Symbol} New Symbol with the specified column name.
     */
  symbol(columnName) {
    requireNonEmptyString(columnName, 'columnName');

    if (columnName.toUpperCase() === this.column.toUpperCase()) { return this; }

    if (this._columns === null
            && (this.column !== 'OHLC'
                || columnName !== 'Close')) { throw new Error('Cannot call symbol() method on an instance where `_columns` is null.'); }

    let col = null;
    // Tricking it for simulated/fake column "OHLC".
    if (columnName === 'Close'
            && this.column === 'OHLC') {
      col = {
        cName: columnName,
        frequencies: this.frequencies,
        tenforeData: this.tenforeData,
        mpData: this._mpData,
      };
    } else {
      col = this.columnByName(columnName);
      if (col === null) {
        throw new Error(`Unrecognized column name for symbol [${ this.displayName }]: ${ columnName}`);
      }
    }

    return new Symbol({
      source: this.source,
      fullName: this.fullName,
      displayName: this.displayName,
      description: this.description,
      type: this.type,
      column: col.cName,
      frequencies: col.frequencies,
      tenforeData: col.tenforeData,
      mpData: col.mpData,
      columns: this._columns,
      uomConversions: this._uomConv,
      currencyConversions: this._currencyConv,
      tradingSession: this._tradingSess,
    });
  }

  /** @returns {Object} Serializes this instance. */
  serialize() {
    return Serializable.serialize(this, 'lim.Symbol');
  }

  /** Finalizes the instance after construction. */
  _finalize() {
    this._uniqueId = Symbol.toUniqueId(this.source, this.fullName, this.column);
    this._name = `${this.displayName } ${ this.column}`;

    const col = this._safeCol();

    if (col !== null) {
      this._finalizeCopy('frequencies', col);
      this._finalizeCopy('tenforeData', col);
      this._finalizeCopy('mpData', col, '_mpData');

      this._colConv = col.conversions;

      if (typeof col.baseUnit === 'string'
                && col.baseUnit !== 'UNS') // UNSpecified
      { this._mimUOM = col.baseUnit; }

      if (typeof col.baseCurrency === 'string'
                && col.baseCurrency !== 'UNS') // UNSpecified
      { this._mimCurrency = col.baseCurrency; }


      if (col.tenforeData) {
        if (typeof col.tenforeData.units === 'string'
                    && col.tenforeData.units !== 'UNS') // UNSpecified
        { this._rtUOM = col.tenforeData.units; }

        if (typeof col.tenforeData.currency === 'string'
                    && col.tenforeData.currency !== 'UNS') // UNSpecified
        { this._rtCurrency = col.tenforeData.currency; }
      }
    }

    // Make sure to only call this when everything else is set.
    this._setRtEntitlements();
  }

  /**
     * Sets real-time entitlements.  Only call this method
     * after everything else is finalized.
     */
  _setRtEntitlements() {
    if (typeof this.tenforeData === 'undefined'
            || this.tenforeData === null
            || !Objects.hasRuntimeObj('exchangeEntitlements')) { return; }

    const exchCtrl = Runtime.exchangeEntitlements;
    if (exchCtrl.isAvailable(this)
            && exchCtrl.isEntitled(this)) {
      this.tenforeData.rate = ((exchCtrl.isRealtime(this))
        ? 'REAL_TIME'
        : 'DELAYED');
    } else { this.tenforeData.rate = 'NO_ENTITLEMENT'; }
  }

  /**
     * Copies a property from the given column object into this symbol instance.
     * @param {string} propName Property name to copy (from column).
     * @param {Symbol.Column} col Column object used as the source of the property value.
     * @param {string} [saveAs=`propName`] Property name used in the symbol instance, if
     *        different that `propName`.
     * @private
     */
  _finalizeCopy(propName, col, saveAs) {
    const symProp = ((arguments.length > 2) ? saveAs : propName);

    if (isVoid(this[symProp])
            && !isVoid(col[propName])) {
      this[symProp] = col[propName];
    }
  }

  /**
     * Deserialization method for RTD mapping data.
     * @param {{_: Object, info: {fullName: string, column: string}}} serializedSymbol
     */
  tenfore(serializedSymbol) {
    if (!isVoid(serializedSymbol)) {
      if (isVoid(serializedSymbol._)
                || isVoid(serializedSymbol.info)
                || !isString(serializedSymbol.info.fullName)
                || !isString(serializedSymbol.info.column)) { throw new Error('serializedSymbol: unsupported format'); }

      this.tenforeData = {
        symbol: serializedSymbol.info.fullName,
        field: serializedSymbol.info.column,
        rate: 'UNKNOWN',
      };
    }
  }

  /**
     * Returns all meta data necessary to reconstruct this instance.
     * This is a new take on the serialize() method; the difference
     * is that deconstruct/reconstruct handle ALL meta data, as
     * opposed to serialize() which drops some of the meta data
     * in an attempt to workaround a size limitation in the user_settings
     * table.
     * @returns {Symbol.Deconstructed}
     */
  deconstruct() {
    return {
      source: this.source,
      fullName: this.fullName,
      displayName: this.displayName,
      description: this.description,
      type: this.type,
      column: this.column,
      columns: Objects.clone(this._columns),

      currencyTable: Objects.clone(this._currencyConv),
      uomTable: Objects.clone(this._uomConv),

      tradingSession: this._tradingSess,
    };
  }

  /**
     * @param {string} symbolName Symbol name.
     * @returns {boolean} Whether `symbolName` matches the format of a Tenfore instrument.
     */
  static isTenfore(symbolName) {
    return REGEX_RTD_INSTRUMENT.test(requireNonEmptyString(symbolName, 'symbolName'));
  }

  /**
     *
     * @param {string} colName Column name.
     * @returns {boolean} Whether `colName` matches the format of a Tenfore field.
     */
  static isTenforeColumn(colName) {
    const m = REGEX_RTD_FIELD.exec(requireNonEmptyString(colName, 'colName'));
    if (m === null) { return false; }

    const type = m[1].toUpperCase();
    return (type === 'TFFIELD'
                || (type === 'CDB'
                    && X_CDB_FIELDS.hasOwnProperty(m[2].toUpperCase())));
  }

  /**
     * @param {string} colName Column name.
     * @returns {boolean} Whether `colName` matches the format of a virtual column.
     */
  static isLocalColumn(colName) {
    const m = REGEX_CDB_COLUMN.exec(requireNonEmptyString(colName, 'colName'));
    return (m !== null
                && Z_CDB_FIELDS.hasOwnProperty(m[1].toUpperCase()));
  }

  /**
     * @param {string} source
     * @param {string} fullName
     * @param {string} columnName
     * @returns {string} Unique ID that represent the 3 values.
     */
  static toUniqueId(source, fullName, columnName) {
    requireNonEmptyString(source, 'source');
    requireNonEmptyString(fullName, 'fullName');
    requireString(columnName, 'columnName'); // Allow "" - some symbols are folders w/o columns

    return `${columnName }@${ fullName }@${ source}`;
  }


  /**
     * Reconstructs an instance using an object previously returned by
     * deconstruct().  This function creates a new instance.
     *
     * This method returns "null" if it wasn't able to fully
     * construct an instance of Symbol.  This happens when
     * new instances require information that wasn't available during
     * deconstruction.
     *
     * @param {Symbol.Deconstructed} deconstructed
     * @return {?Symbol} New instance of Symbol, or null `deconstructed` is not recognized.
     */
  static reconstruct(deconstructed) {
    requireObject(deconstructed, 'deconstructed');

    if (!isString(deconstructed.source)
            || !isString(deconstructed.fullName)
            || !isString(deconstructed.displayName)
            || !isString(deconstructed.description)
            || !isString(deconstructed.type)
            || !isString(deconstructed.column)
            || !Array.isArray(deconstructed.columns)
            || typeof deconstructed.currencyTable !== 'object' // null is of type Object; that's ok.
            || typeof deconstructed.uomTable !== 'object' // null is of type Object; that's ok.
            || !isString(deconstructed.tradingSession)) {
      return null;
    }

    const sym = new Symbol();
    sym.source = deconstructed.source;
    sym.fullName = deconstructed.fullName;
    sym.displayName = deconstructed.displayName;
    sym.description = deconstructed.description;
    sym.type = deconstructed.type;
    sym.column = deconstructed.column;

    sym._columns = Objects.clone(deconstructed.columns);
    sym._currencyConv = Objects.clone(deconstructed.currencyTable);
    sym._uomConv = Objects.clone(deconstructed.uomTable);
    sym._tradingSess = deconstructed.tradingSession;

    sym._finalize();
    return sym;
  }

  /**
     * Serializable properties
     * @type {string[]}
     */

    static _serializeInfo = [
      'source',
      'fullName',
      'displayName',
      'description',
      'type',
      'column',
      'frequencies',
      '_uomConv',
      '_currencyConv',
      '_colConv',
      '_rtUOM',
      '_rtCurrency',
      '_mimUOM',
      '_mimCurrency',
      'tenfore',
      'tenforeData',
      '_mpData',
      '_tradingSess',
      // "_columns",  // Do not serialize _columns: there's too much data
      '_data',
      '_finalize',
    ];

    /**
     * @type {function}
     * @see isSymbol
     */
    static isSymbol = isSymbol;

    /**
     * @type {function}
     * @see requireSymbol
     */
    static requireSymbol = requireSymbol;
}

export default Symbol;

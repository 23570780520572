<template>
  <div class="grid-1of3 grid-1of1--tablet_xl">
    <div class="homepage-theme-tiles">
      <div
        v-for="tile in tiles"
        :key="tile.url"
        class="grid-1of1"
      >
        <template v-if="tile.url.includes('http')">
          <a
            class="theme-tile u-mb-md light-border"
            :href="tile.url"
            target="_blank"
          >
            <figure
              class="teaser__img"
            >
              <img
                :alt="tile.type"
                :src="getImgUrl(tile.backgroundURL)"
              >
            </figure>
            <div class="meta-data u-padding--xsmall">
              <h2>{{ tile.type }}</h2>
              <div class="teaser__byline">{{ tile.heading }}</div>
            </div>
          </a>
        </template>
        <template v-else>
          <a
            v-scroll-to="'#' +tile.url"
            class="theme-tile u-mb-md light-border"
            href="#"
          >
            <figure
              class="teaser__img"
            >
              <img
                :alt="tile.type"
                :src="getImgUrl(tile.backgroundURL)"
              >
            </figure>
            <div class="meta-data u-padding--xsmall">
              <h2>{{ tile.type }}</h2>
              <div class="teaser__byline">{{ tile.heading }}</div>
            </div>
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CarouselTiles',
  props: {
    tiles: { type: Array, default: () => [] },
  },
  methods: {
    getImgUrl(pic) {
      return `${process.env.VUE_APP_LANDING_PAGE_RESOURCE}/images/carousel/${pic}`;
    },
    goto(refName) {
      const element = this.$refs[refName];
      const top = element.offsetTop;

      window.scrollTo(0, top);
    },
  },
};
</script>

const formulaEditActionsConstants = {
  formulaNodeTypesForUI: {
    forward_curve: 'Forward Curve',
    time_series: 'History / Time Series',
    basic_math: 'Basic Math',
    extrapolation: 'Extrapolation',
    merge: 'Merge',
    fill: 'Fill',
    free_text: 'Free Text',
    missing_data_qa: 'Check Missing Data',
    in_range_qa: 'Check Data in Range',
    spike_qa: 'Check Data Spike',
  },
  forwardCurveUI: {
    deliveryTypeMap: {
      quarter: 'quarter',
      year: 'year',
      season: 'season',
      month: 'month',
      week: 'week',
      day: 'day',
      hour: 'hour',
      '30-minute': '30-minute',
    },
  },
  mergeUI: {
    functionDropdownData: [
      ['Union - all dates', 'union'],
      ['Intersection - only dates found in all curves', 'intersection'],
      ['Complement - preserve first curve', 'complement'],
      ['Overwrite - overwrite first curve', 'overwrite'],
    ],
  },
  extrapolationUI: {
    method: [
      ['Use other curve as model', 'follow_dataset'],
      ['Repeat last cycle', 'repeat_last_cycle'],
    ],
    extend: [
      ['To length of model curve', 'length_of_dataset'],
      ['Until curve length reaches fixed length', 'fixed_length'],
      ['To fixed_date', 'fixed_date'],
    ],
    cycle: {
      delivery: [
        ['hour', 'hour'],
        ['day', 'day'],
        ['month', 'month'],
        ['quarter', 'quarter'],
        ['annual', 'annual'],
      ],
    },
  },
  timeSeriesUI: {
    timeDataset: [
      { text: 'hours', value: 'hours' },
      { text: 'days', value: 'days' },
      { text: 'business days', value: 'business days' },
      { text: 'months', value: 'months' },
      { text: 'years', value: 'years' },
    ],
  },
  variablesAtThisPointDataSet: [
    {
      varName: '$TODAY',
      varType: 'Date',
      varDesc: "Today's date, no time",
    },
    {
      varName: '$TIME_ZONE',
      varType: 'TimeZone',
      varDesc: 'Workflow time-zone',
    },
    {
      varName: '$RUN_DATE',
      varType: 'Date',
      varDesc: 'Date-time for this run',
    },
    {
      varName: '$NOW',
      varType: 'Date',
      varDesc: 'Current time',
    },
  ],
  inputToAddDataset: [
    {
      text: 'forward curve',
      value: 'forward_curve',
    },
    {
      text: 'history/timeseries',
      value: 'time_series',
    },
    {
      text: 'basic computation',
      value: 'basic_math',
    },
    {
      text: 'extrapolation',
      value: 'extrapolation',
    },
    {
      text: 'merge',
      value: 'merge',
    },
    {
      text: 'fill',
      value: 'fill',
    },
    {
      text: 'free text',
      value: 'free_text',
    },
  ],
  formulaNodeTemplateObject: {
    forward_curve: {
      variableName: '',
      product: [],
      delivery: [],
      method: [],
      arbitrageFree: {
        basic: false,
      },
      includeNans: false,
      curveDate: [],
      fixedDate: '',
      skipWeekends: false,
      curveDaysBackValue: 0,
      curveFixedDate: '',
    },
    time_series: {
      variableName: '',
      product: [],
      howFarBack: '',
      time: [],
      timeZone: ['Asia/Kolkata'],
      intradayData: false,
    },
    basic_math: {
      variableName: '',
      computation: '',
    },
    extrapolation: {
      method: [],
      variableName: '',
      timeSeriesVar: [],
      lastMeth: null,
      methCtrls: [
        {
          other: [],
          extendTo: 'length_of_dataset',
          fixedLen: '1',
          fixedDate: '',
          toRemove: [],
        },
        {
          repeats: '1',
          delivery: [],
          stopDate: '',
          toRemove: [],
        },
      ],
    },
    merge: {
      additionalCurvesToMerge: [],
      variableName: '',
      func: '',
      intoCurve: [],
      mergeCurve: [],
    },
    fill: {
      variableName: '',
      curve: [],
      direction: [],
    },
    free_text: {
      variableName: '',
      code: '',
    },
  },
  formulaNodeTemplateValidationObject: {
    forward_curve: {
      variableName: {
        required: true,
        errorMessage: 'Variable name cannot be empty.',
      },
      product: {
        required: true,
        errorMessage: 'Product cannot be empty.',
      },
      delivery: {
        required: true,
        errorMessage: 'Delivery cannot be empty.',
      },
      method: {
        required: true,
        errorMessage: 'Method cannot be empty.',
      },
      arbitrageFree: {
        basic: false,
      },
      includeNans: false,
      curveDate: {
        required: true,
        errorMessage: 'Curve date cannot be empty.',
      },
      minusDays: 0,
      fixedDate: '',
      skipWeekends: false,
      curveDaysBackValue: 0,
      curveFixedDate: '',
    },
    time_series: {
      variableName: {
        required: true,
        errorMessage: 'Variable name cannot be empty.',
      },
      product: {
        required: true,
        errorMessage: 'Product cannot be empty.',
      },
      howFarBack: {
        required: false,
      },
      time: {
        required: true,
        errorMessage: 'Time cannot be empty.',
      },
    },
    merge: {
      variableName: {
        required: true,
        errorMessage: 'Variable name cannot be empty.',
      },
      func: {
        required: true,
        errorMessage: 'Function cannot be empty',
      },
      intoCurve: {
        required: true,
        errorMessage: 'Into Curve cannot be empty',
      },
      mergeCurve: {
        required: true,
        errorMessage: 'Merge curve cannot be empty',
      },
    },
    free_text: {
      variableName: {
        required: true,
        errorMessage: 'Variable name cannot be empty.',
      },
      code: {
        required: true,
        errorMessage: 'code name cannot be empty.',
      },
    },
    basic_math: {
      variableName: {
        required: true,
        errorMessage: 'Variable name cannot be empty.',
      },
      computation: {
        required: true,
        errorMessage: 'A computation formula must be provided.',
      },
    },
    extrapolation: {
      variableName: {
        required: true,
        errorMessage: 'Variable name cannot be empty.',
      },
    },
    fill: {
      variableName: {
        required: true,
        errorMessage: 'Variable name cannot be empty.',
      },
      curve: {
        required: true,
        errorMessage: 'Curve cannot be empty',
      },
      direction: {
        required: true,
        errorMessage: 'Direction cannot be empty',
      },
    },
  },
};

export default formulaEditActionsConstants;

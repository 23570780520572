<template>
  <div class="caret">
    <button
      id="from-link"
      @click="fromLinkClicked"
    >
      <router-link
        :to="fromLink"
      >
        <span class="caret-item"> {{ fromPathName.toUpperCase() }}</span>
      </router-link>
    </button>
    /
    <span class="caret-item"> {{ toPathName.toUpperCase() }}</span>
  </div>
</template>

<script>

export default {
  name: 'Breadcrumb',
  props: {
    toPathName: { type: String, default: '' },
    fromPathName: { type: String, default: '' },
  },
  computed: {
    fromLink() {
      return `/${this.$route.path.split('/')[1]}`;
    },
  },
  methods: {
    fromLinkClicked() {
      this.$eventBus.$emit('from-link-trigger', this.fromLink);
    },
  },
};
</script>

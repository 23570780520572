const mutations = {
  setSources: (state, sources) => {
    state.sources = sources;
  },
  setFeeds: (state, feeds) => {
    state.feeds = feeds;
  },
  setKeys: (state, keys) => {
    state.keys = keys;
  },
  setColumns: (state, columns) => {
    state.cols = columns;
  },
  setTotalItems: (state, totalItems) => {
    state.totalItems = totalItems;
  },
  setMetaData: (state, metadata) => {
    state.metadata = metadata;
  },
  setDataPreview: (state, dataPreview) => {
    state.dataPreview = dataPreview;
  },
  setSearchResult: (state, keySearchResults) => {
    state.keySearchResults = keySearchResults;
  },
  setClearSections: (state) => {
    state.feeds = [];
    state.keys = [];
    state.cols = [];
    state.metadata = [];
    state.dataPreview = [];
    state.keySearchResults = [];
  },
  setClearFeedSections: (state) => {
    state.keys = [];
    state.cols = [];
    state.metadata = [];
    state.dataPreview = [];
    state.keySearchResults = [];
  },
  pushKeyToBasket: (state, item) => {
    state.basket.push(item);
  },
  removeKeyFromBasket: (state, newBasket) => {
    state.basket = newBasket;
  },
  clearBasket: (state, resetBasket) => {
    state.basket = resetBasket;
  },
  changeToUnchecked: (state, itemIdx) => {
    state.basket[itemIdx].checked = false;
  },
  updateSelectedCols: (state, updatedItem) => {
    state.basket = [...state.basket.map(item => (item.Id !== updatedItem.Id ? item : { ...item, ...updatedItem }))];
  },
};
export default mutations;

<template>
  <div
    :id="id"
    class="mcd-time-series-line-chart"
  />
</template>

<script>
const d3 = require('d3');
// eslint-disable-next-line import/no-extraneous-dependencies
const TimeSeriesLineChart = require('mbc-time-series-line-chart');

export default {
  name: 'TimeSeriesLineChart',
  props: {
    dataSet: {
      type: Object,
      required: true,
      validator: (value) => {
        const groupIsArray = group => Array.isArray(value[group]);
        const groupHasLength = group => value[group].length > 0;
        const groupHasValidData = group => value[group].every(
          data => typeof data.name === 'string'
                && typeof data.value === 'number'
                && typeof Date.parse(data.date) === 'number'
                && typeof data.groupName === 'string',
        );

        const y1IsValid = !value.y1 || (groupIsArray('y1') && groupHasLength('y1') && groupHasValidData('y1'));
        const y2IsValid = !value.y2
            || (groupIsArray('y2')
              && (!groupHasLength('y2') || (groupHasLength('y2') && groupHasValidData('y2'))));

        return y1IsValid && y2IsValid;
      },
    },
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      updateChart: null,
    };
  },
  watch: {
    dataSet: {
      handler(newValue, oldValue) {
        if (typeof this.updateChart === 'function') {
          this.updateChart(this.formatData(newValue));
        }
      },
      deep: true,
    },
    options(newValue, oldValue) {
      // rerender when timeline Level changes
      if (newValue.timelineLevel !== oldValue.timelineLevel) {
        this.updateChart = this.initChart({
          dataSet: this.dataSet,
          id: this.id,
          options: this.options,
        });
      }
    },
  },
  mounted() {
    this.updateChart = this.initChart({
      dataSet: this.dataSet,
      id: this.id,
      options: this.options,
    });
  },
  methods: {
    formatData(data) {
      return Object.keys(data).reduce((acc, dataKey) => {
        acc[dataKey] = data[dataKey].map(yData => ({
          ...yData,
          date: new Date(yData.date),
        }));

        return acc;
      }, {});
    },
    initChart({ dataSet, id, options }) {
      const chart = new TimeSeriesLineChart(options);
      const chartContainer = d3.select(`#${id}`);

      // Dates in Vue need to be stored as timestamps (a JSON-style plain object)
      // https://github.com/vuejs/vue/issues/212
      // Here we convert the timestamps back to date objects (as that is what the chart expects)
      const formattedData = this.formatData(dataSet);

      // Initialize chart
      chartContainer.datum(formattedData).call(chart.init());

      // Resize chart on window resize
      d3.select(window).on(`resize.${id}`, () => {
        chart.update();
      });

      // Return a function to allow updates to the chart with new data
      return (updatedData) => {
        chart.data(updatedData);
      };
    },
  },
};
</script>

<style lang="scss">
  @import '~mbc/dist/mbc-time-series-line';
  .mbc-time-series-line-chart .grid__bars:nth-child(odd) {
    fill:  var(--app-background-color-dark) ;
  }

  .mbc-time-series-line-chart
  .mbc-chart-legend.mbc-chart-legend-right
  .mbc-legend .mbc-time-series_dataValue
  {
    fill: var(--dynamic-subtitle-color);
  }
  .mcd__chart{
    height: 400px;
  }
</style>

<style lang="scss" scoped>
  #mcd__chart{
    fill: var(--dynamic-subtitle-color);
  }

  .mcd-time-series-line-chart {
    /deep/ .mbc-chart-legend {
      overflow: visible;
    }
  }
</style>

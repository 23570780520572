
import Arrays from './arrays.es6';
import Strings from './strings.es6';
import { requireFunction } from './functions.es6';
import { EnumItem } from './enums.es6';

/* ***************************************************
 * Interfaces
 * *************************************************** */

/**
 * @callback Objects.Predicate
 * @param {*} arg
 * @returns {boolean} Whether `arg` passes the predicate.
 */

/**
 * @typedef Object Objects.AreEqualOptions
 * @property {boolean} [ignoreEquals=false] - Whether to ignore objects' `equals()` method.
 * @property {(string|string[])} [include=""] - Restricts the comparison to only included property names.
 * @property {(string|string[])} [exclude=""] - Restricts the comparison to exclude some property names.
 * @property {function} hasOwnProperty - Why I need to document this, I don't know.
 */

/**
 * @typedef Object Objects.AreEqualOptionsValidated
 * @property {boolean} ignoreEquals - Whether to ignore objects' `equals()` method.
 * @property {Objects.Predicate} include - Predicate for whether to include a property in the comparison.
 * @property {Objects.Predicate} exclude - Predicate for whether to exclude a property from the comparison.
 */

/* ***************************************************
 * Private methods
 * *************************************************** */

/** @returns {boolean} Always returns `false`. */
function _returnFalse() {
  return false;
}

/** @returns {boolean} Always returns `true`. */
function _returnTrue() {
  return true;
}

/**
 * Returns whether the given argument is void (null or undefined).
 * @param obj {*}
 * @returns {boolean}
 * @private
 */
function _isVoid(obj) {
  return (typeof obj === 'undefined'
            || obj === null);
}

/**
 * Returns whether `val` is of primitive type - string, number (finite) or boolean.
 * @param {*} val
 * @returns {boolean}
 * @private
 */
function _isPrimitive(val) {
  const typeOf = typeof val;
  return (typeOf === 'string'
            || (typeOf === 'number' && isFinite(val))
            || typeOf === 'boolean');
}

/**
 * @param {*} obj
 * @returns {boolean} Whether the given argument is a plain Object.
 * @private
 */
function _isObj(obj) {
  return (obj instanceof Object
            && obj.constructor === Object);
}

/**
 * @param {*} obj
 * @returns {boolean} Whether the given argument is an Object or sub-instance of Object.
 * @private
 */
function _isObjInst(obj) {
  return (obj instanceof Object);
}

/**
 * @param {*} obj
 * @returns {boolean} Whether the given argument is of type "object" and non-null.
 * @private
 */
function _isObjType(obj) {
  return (typeof obj === 'object'
            && obj !== null);
}

/**
 * @param {*} arg
 * @returns {boolean} Whether `arg` is undefined.
 * @private
 */
function _isUndef(arg) {
  return (typeof arg === 'undefined');
}

/**
 * @param {*} arg1
 * @param {*} arg2
 * @returns {boolean} Whether the two arguments are of the same type.
 * @private
 */
function _isSameType(arg1, arg2) {
  if (arg1 === arg2
        || (arg1 === null
            && arg2 === null)
        || (_isUndef(arg1)
            && _isUndef(arg2))) return true;

  return (arg1.constructor === arg2.constructor);
}

/**
 * Validates the given object to be a plain JavaScript Object.
 * If valid, this method returns that object. Otherwise it throws an exception.
 *
 * @param {Object} obj
 * @param {string} name - Name given to `obj`.
 * @returns {Object} `obj`
 * @private
 */
function _validObjPlain(obj, name) {
  if (!_isObj(obj)) { throw new TypeError(`${name }: Object, plain`); }

  return obj;
}

/**
 * Validates the given object to be an Object or sub-instance of Object.
 * If valid, this method returns that object. Otherwise it throws an exception.
 *
 * @param {Object} obj
 * @param {string} name - Name given to `obj`.
 * @returns {Object} `obj`
 * @private
 */
function _validObjInst(obj, name) {
  if (!_isObjInst(obj)) { throw new TypeError(`${name }: Object, or any sub-class`); }

  return obj;
}


/**
 * Validates the given argument is of type "object" and non-null.
 * If valid, this method returns that object. Otherwise it throws an exception.
 *
 * @param {Object} obj
 * @param {string} name - Name given to `obj`.
 * @returns {Object} `obj`
 * @private
 */
function _validObjType(obj, name) {
  if (!_isObjType(obj)) { throw new TypeError(`${name }: Object, non-null`); }

  return obj;
}

/**
 * Freezes a given object recursively.
 * @param obj {(Object|Array|string|number|boolean)}
 * @returns {(Object|Array|string|number|boolean)}
 * @private
 */
function _freezeDeep(obj) {
  if (_isObj(obj)
        || obj instanceof Array) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) { _freezeDeep(obj[prop]); }
    }

    Object.freeze(obj);
  } else if (typeof obj === 'function') { return obj; } else if (!_isPrimitive(obj)
             && obj !== null) { throw new TypeError('obj: Object, Array, String, Number (finite), Boolean or Function'); }

  return obj;
}

/**
 * Returns a list of property names (or numbers) that belong to `obj`.
 * @param obj {Object}
 * @param [isSorted=false] {boolean} Whether the returned array is sorted.
 *                       This option only works on regular object; objects with
 *                       string property names.
 * @returns {(string[]|number[])}
 * @private
 */
function _getProps(obj, isSorted) {
  /** @type {Array} */
  const rv = Object.keys(obj);

  if (isSorted === true) { rv.sort(); }

  return rv;
}

function _toString(obj) {
  if (typeof obj === 'undefined') return 'undefined';

  if (obj === null) return 'null';

  if (obj instanceof Date) return obj.toString();

  const isArray = (obj instanceof Array);
  let str = (isArray) ? '[' : '{';

  for (const name in obj) {
    if (obj.hasOwnProperty(name)) {
      if (str.length > 2) str += ', ';

      if (!isArray) str += `${name }:`;

      const val = obj[name];
      const to = typeof val;

      switch (to) {
        case 'function':
          str += '"[Function]"';
          break;

        case 'string':
          str += `"${ val }"`;
          break;

        case 'object':
          str += _toString(val);
          break;

        default:
          str += val;
      }
    }
  }

  str += (isArray) ? ']' : '}';
  return str;
}

/**
 * Copies the named properties from `object` into `target`.
 * @param {Object} target
 * @param {Object} object
 * @param {string[]} names
 * @param {boolean} goDeep
 * @returns {Object} Modified `target`.
 * @private
 */
function _copy(target, object, names, goDeep) {
  for (const name of names) {
    if (object.hasOwnProperty(name)) {
      const val = object[name];
      if (goDeep === false) { target[name] = val; } else { target[name] = Objects.clone(val); }
    }
  }
  return target;
}

/**
 * @param {string[]|string} array - List of properties, comma-separated string is supported.
 * @returns {Objects.Predicate} New predicate function that returns whether an item
 *          exists in the given array.
 * @throws TypeError - If `array` is neither a string[] nor string.
 */
function _newArrayIncludePredicate(array) {
  let list;
  if (typeof array === 'string') { list = array.split(','); } else if (Arrays.isArrayOf(array, 'string')) { list = array; } else { throw new TypeError('array: Array or string[]'); }

  /** @type {Object.<string, string>} */
  const index = {};
  list.forEach((item) => {
    if (typeof item === 'string') {
      index[item] = item;
    }
  });

  return function (item) {
    return index.hasOwnProperty(item);
  };
}

/**
 *
 * @param {Objects.AreEqualOptions} options
 * @returns {Objects.AreEqualOptionsValidated}
 * @private
 */
function _extendedOptionsAreEqual(options) {
  const validOptions = {
    ignoreEquals: false,
    include: _returnTrue,
    exclude: _returnFalse,
  };

  if (typeof options !== 'undefined') {
    if (typeof options === 'boolean') { validOptions.ignoreEquals = (options === true); } else if (_isObjType(options)) {
      if (typeof options.ignoreEquals !== 'boolean') { validOptions.ignoreEquals = true; } // Talk about confusing!!

      if (options.hasOwnProperty('include')) { validOptions.include = _newArrayIncludePredicate(options.include); }

      if (options.hasOwnProperty('exclude')) { validOptions.exclude = _newArrayIncludePredicate(options.exclude); }
    }
  }

  return validOptions;
}


/** @namespace */
const Objects = Object.freeze(/** @lends Objects */ {

  /** Empty, immutable object */
  EMPTY: Object.freeze({}),

  /**
     * Returns whether <code>obj</code> is <em>null</em>
     * or <em>undefined</em>.
     * @param obj {Object}
     * @returns {Boolean}
     * @method
     */
  isVoid: _isVoid,

  /**
     * Returns whether the given argument is a plain Object.
     * @param {*} obj
     * @returns {boolean}
     * @method
     */
  is: _isObj,

  /**
     * Validates the given argument to be not void (defined and not null).
     * If valid, this method returns that object; otherwise it throws an exception.
     * @param {*} arg Argument to validate.
     * @param {string} name Name given to the argument, for context when throwing.
     * @returns {*} Always returns `arg`.
     * @throws {ReferenceError} If `arg` is null or undefined.
     */
  requireNonVoid(arg, name) {
    if (_isVoid(arg)) throw new ReferenceError(name);

    return arg;
  },

  /**
     * Validates the given object to be a plain JavaScript Object.
     * If valid, this method returns that object. Otherwise it throws an exception.
     *
     * @param {Object} arg - Argument to be validated.
     * @param {string} [name="arg"] - Name given to the argument to be validated.
     * @returns {Object} `arg`
     */
  requirePlain(arg, name) {
    return _validObjPlain(arg, (arguments.length > 1) ? name : 'arg');
  },

  /**
     * Returns whether the given object only contains
     * primitive values.
     * @param obj {Object}
     * @returns {boolean}
     */
  isAllPrimitives(obj) {
    _validObjPlain(obj, 'obj');

    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)
                && !_isPrimitive(obj[prop])) return false;
    }

    return true;
  },

  /**
     * @return The `undefined` value.
     */
  undef() {
  },

  /**
     * Overwrites properties of `target` with properties found in `others`.
     * @param {Object} target
     * @param {...Object} others
     * @returns {Object} Modified `target`.
     */
  merge(target, others) {
    _validObjType(target, 'target');

    return Object.assign(...arguments);
  },

  /**
     * Overwrites properties of `target` with clones of values found in `source`.
     * @param {Object} target Object to modify, will get values from `source`.
     * @param {Object} source Object containing values which need to be copied to `target`.
     * @returns {Object} Modified `target`.
     * @throws {Error} If any of the values found in `source` have a constructor that isn't public
     *         or requires arguments.
     */
  mergeDeep(target, source) {
    return _copy(
      _validObjType(target, 'target'),
      _validObjType(source, 'source'),
      _getProps(target),
      true,
    );
  },

  /**
     * Creates a new object cloned from `target` with properties from `others` merged into it.
     * The main differences between `merge()` and this method are:
     *
     * <ul>
     *  <li> this method does not modify `target`; it clones it; </li>
     *  <li> this method validates that all values found in `others` are of the same type
     *       as values found in `target`. </li>
     * </ul>
     *
     * @param {Object} target - Basis of the clone, also serves as definition for acceptable
     *        property names and values.
     * @param {...Object} others - Objects with properties to be copied, in which
     *        each property must have a value that match that of `target`.
     * @returns {Object} Clone of `target` with all `others` merged into it.
     * @throws Error - If a property in `others` doesn't exist in `target`.
     * @throws TypeError - If a property of `others` contains a different type of value than
     *         what is found in `target`.
     */
  mergeTypeMatch(target, others) {
    _validObjType(target, 'target');
    const clone = Object.assign({}, target);
    for (let i = 1, numArgs = arguments.length; i < numArgs; i++) {
      const other = arguments[i];
      for (const prop in other) {
        if (other.hasOwnProperty(prop)) {
          if (!target.hasOwnProperty(prop)) {
            throw new Error(`arguments[${i}]["${prop}"] not in target`);
          }
          if (!_isSameType(other[prop], target[prop])) {
            throw new TypeError(`arguments[${i}]["${prop}"]: type mismatch target["${prop}"]`);
          }
          clone[prop] = other[prop];
        }
      }
    }
    return clone;
  },

  /**
     * Validates properties of `o` per the given definition.  All properties of `o` are deemed optional.
     * @param {Object} o Object containing properties to be validated, must not be null.
     * @param {Object.<string, function(*, string):*>} def Definition of properties of `o`, in which values are
     *        validators that throws an error when the given value is invalid.
     * @returns {Object} Always returns `o`.
     * @throws {TypeError} If `o` has invalid properties.
     */
  validateOptionalProperties(o, def) {
    _validObjPlain(o, 'o');
    _validObjPlain(def, 'def');
    for (const k of Object.keys(o)) {
      if (!def.hasOwnProperty(k)) {
        throw new TypeError(`o["${k}"]: no declaration found`);
      }
      def[k](o[k], k);
    }
    return o;
  },

  /**
     * Calls <code>Object.freeze(obj)</code>, unless <code>obj</code> is null or not
     * of type "object".
     * @param {(Object|Array)} obj
     * @return {(Object|Array)} Always returns `obj`.
     */
  freeze(obj) {
    if (_isObj(obj)) {
      Object.freeze(obj);
    }
    return obj;
  },

  /**
     * Freezes the given object at all levels.
     * @param {(Object|Array|string|number|boolean)} obj - Object to freeze.
     * @returns {(Object|Array|string|number|boolean)} - Same object, deep-frozen.
     * @throws TypeError - If this method encounters a
     *         value that is not a primitive, not an Array and not an
     *         Object.
     */
  freezeDeep(obj) {
    return _freezeDeep(obj);
  },


  /**
     * Copies values found in `object` into a newly created object.
     * @param {Object} object - Source of the values to be copied.
     * @param {(...string|string[]|boolean)} [names] - Property names to be copied, all if not provided.
     * @returns {Object} New instance of `object` with desired values copied to it.
     */
  copy(object, names) {
    /* For backward compatibility only.
         * There was a glitch in the initial implementation,
         * which returned an empty Object if "object" argument is
         * null.  This new implementation will not fail
         * if "object" is null, but it will return null instead.
         * of an new Object. */
    if (object === null) return null;

    _validObjInst(object, 'object');

    let goDeep = false;

    if (arguments.length === 1
            || typeof names === 'boolean') {
      goDeep = (names === true);
      names = _getProps(object);
    } else if (arguments.length > 2
                 || !(arguments[1] instanceof Array)) {
      names = [];
      for (let cnt = 1; cnt < arguments.length; cnt++) {
        const name = arguments[cnt];
        if (typeof name === 'string') names.push(name);
      }
    }

    return _copy(new object.constructor(), object, names, goDeep);
  },

  /**
     * Copies the properties of `object` into `target`.
     * @param {Object} target
     * @param {Object} object
     * @param {string[]} names
     * @param {boolean} [goDeep=false]
     * @returns {Object} Modified `target`.
     */
  copyProperties(target, object, names, goDeep) {
    _validObjInst(target, 'target');
    _validObjInst(object, 'object');

    if (!Arrays.isArrayOf(names, 'string')) throw new TypeError('names: string[]');

    return _copy(target, object, names, (goDeep === true));
  },

  /**
     * Returns a clone of whatever we give it.  If `value` is an object or array,
     * this method performs a deep copy.  Otherwise -- if a primitive, null or class
     * instance -- this method returns `value` as-is (no cloning possible).
     *
     * @param {(Object|Array|Date|string|number|boolean|*)} value
     * @returns {(Object|Array|Date|string|number|boolean|*)} A deeply cloned copy of `value`.
     */
  clone(value) {
    const typeOf = typeof value;
    if (value === null
            || typeOf === 'undefined'
            || typeOf === 'number'
            || typeOf === 'boolean'
            || typeOf === 'string'
            || typeOf === 'function') {
      return value; // primitive, cannot be cloned, copy as-is.
    }


    /*
         * IMPORTANT!!!
         * It's important that we keep using "constructor" here,
         * as opposed to using "instanceof".  That's because
         * we're looking for instances of the specific class,
         * not sub-classes.
         */

    if (value.constructor === Date) {
      return new Date(value.getTime());
    } if (value.constructor === Array) {
      const a = value.slice(0); // create shallow copy;
      for (let i = 0; i < a.length; i++) a[i] = Objects.clone(a[i]);
      return a;
    } if (value.constructor === Object) {
      return Objects.copy(value, true);
    } if (value.constructor === EnumItem) {
      return value; // copy EnumItem as is, they are singletons.
    }
    throw new Error(`Cannot clone: ${ value.constructor.toString()}`);
  },

  /**
     * Removes properties from an object.  This method
     * modifies <code>object</code>, so make a copy first!
     *
     * @param object {Object}
     * @param names {String...} or {Array} of {String}
     *
     * @return {Objects} A pointer to this utility class, enable call chaining.
     */
  remove(object, names) {
    if (!(object instanceof Object)) {
      throw new TypeError('object: expected Object');
    }
    if (arguments.length < 2) {
      throw new Error('names: required argument');
    }

    let namelist;
    if (Arrays.isArrayLike(names)) {
      namelist = names;
    } else {
      namelist = Arrays.slice(arguments, 1);
    }

    const isValid = Arrays.isValid(namelist, item => (typeof item === 'string'
                || typeof item === 'number'));
    if (!isValid) {
      throw new TypeError('names: property names must be string or number');
    }

    for (let cnt = 0; cnt < namelist.length; cnt++) {
      const name = namelist[cnt];
      if (object.hasOwnProperty(name)) delete object[name];
    }

    return this;
  },

  /**
     * Clears all references an object might have to other objects,
     * then deletes all properties.
     * @param {Object} obj
     */
  destroy(obj) {
    for (const name in obj) {
      if (obj.hasOwnProperty(name)) {
        obj[name] = null; // remove the reference
        delete obj[name]; // delete the property
      }
    }
  },

  addIfMissing(object, name, value) {
    _validObjPlain(object, 'object');
    Strings.requireNonEmpty(name, 'name');

    if (typeof value === 'undefined') throw new Error('value must be provided');

    if (!object.hasOwnProperty(name)) object[name] = value;

    return object;
  },

  /**
     * Returns an array of property names corresponding
     * to the object's own properties.
     * @param object {Object}
     * @param [isSorted=false] {boolean} Whether the returned array is sorted.
     *                       This option only works on regular object; objects with
     *                       string property names.
     * @returns {string[]}
     * @method
     */
  properties: _getProps,


  /**
     * Returns whether the specified object has any
     * property of its own.
     * @param object {Object}
     * @returns {Boolean}
     */
  hasProperties(object) {
    for (const name in object) {
      if (object.hasOwnProperty(name)) return true;
    }
    return false;
  },


  /**
     * Returns whether two values are identical.
     * This function considers two <code>null</code> values to be identical.
     * This function also considers two <code>undefined</code> values to be identical.
     * @param {*} obj1 - First object to compare.
     * @param {*} obj2 - Second object to compare.
     * @param {(boolean|Objects.AreEqualOptions)} [options=false] - Comparison options.
     *        A boolean value is interpreted as `{ ignoreEquals: false }`.
     *
     */
  areEqual(obj1, obj2, options) {
    if (arguments.length < 2
            || arguments.length > 3) throw 'IllegalArgumentException: must provide 2 or 3 arguments.';

    const typeOf1 = typeof obj1;
    const typeOf2 = typeof obj2;

    if (typeOf1 !== typeOf2) return false;
    if (typeOf1 === 'undefined') return true;

    if (obj1 === null
            || obj2 === null
            || typeOf1 === 'boolean'
            || typeOf1 === 'number'
            || typeOf1 === 'string'
            || typeOf1 === 'function') return (obj1 === obj2);
    if (obj1 === obj2) return true;

    if (obj1.constructor !== obj2.constructor) return false;

    if (obj1.constructor === Date) return (obj1.getTime() === obj2.getTime());

    if (obj1.constructor === Array) {
      if (obj1.length !== obj2.length) return false;

      return obj1.every((item, index) => Objects.areEqual(item, obj2[index]));
    }

    const validOptions = _extendedOptionsAreEqual(options);

    if (validOptions.ignoreEquals === false
            && typeof obj1.equals === 'function') return obj1.equals(obj2);

    const propNames1 = _getProps(obj1);
    const propNames2 = _getProps(obj2);
    if (propNames1.length !== propNames2.length
            && validOptions.include === _returnTrue
            && validOptions.exclude === _returnFalse) return false;

    let areEqual = true;
    for (let cnt = 0;
      cnt < propNames1.length && areEqual === true;
      cnt++) {
      const propName = propNames1[cnt];

      if (validOptions.include(propName)
                && !validOptions.exclude(propName)
                && !Objects.areEqual(obj1[propName], obj2[propName])) areEqual = false;
    }
    return areEqual;
  },

  /**
     * Creates a new comparator function that treats objects that aren't
     * instances of `ctor` equally, as *high* values.  The `comparator` will only
     * be called after both is arguments are validated as instances of `ctor`.
     *
     * @param {Function} ctor Constructor function.
     * @param {Function} comparator A method that expect two objects of type `ctor` only.
     * @returns {Function} A new comparator function.
     */
  newNullableComparator(ctor, comparator) {
    requireFunction(ctor, 'ctor');
    requireFunction(comparator, 'comparator');

    if (comparator.length !== 2) {
      throw new TypeError('comparator: function that accepts 2 arguments');
    }

    return function (o1, o2) {
      const is1 = (o1 instanceof ctor);
      const is2 = (o2 instanceof ctor);

      if (is1 && is2) return comparator(o1, o2);
      if (is1) return -1;
      if (is2) return 1;
      return 0;
    };
  },

  toString(obj) {
    // Support a no-argument version, for when browsers uses
    // `toString()` to create an exception message.
    if (arguments.length < 1) {
      return 'Objects';
    }
    return _toString(obj);
  },

  /**
     * Converts a user-entered JSON object (string) and forces quotes around property names
     * to ensure it can be processed by JSON parsers (i.e. `JSON.parse`).
     *
     * This method does a blunt replacement without checking that `jsonObject` represents
     * a JavaScript object; callers are responsible for validating `jsonObject`, either
     * before or after calling this method.
     *
     * @param {string} jsonObject - User-entered JSON object.
     * @returns {string} `jsonObject` in which property names are quoted.
     */
  withQuotedProperties(jsonObject) {
    const regex = new RegExp('([\\{,]\\s*)([A-Za-z_0-9\|$]+)(\\s*:)', 'g');

    return jsonObject.replace(regex, '$1"$2"$3');
  },

  /**
     * <p>
     *  This method overwrites an object property with a given
     *  <code>value</code>.  If <code>value</code> is not provided,
     *  the original value's data-type will be used as the
     *  overwriting value.
     * </p>
     *
     * <p>
     *  This method modifies <code>obj</code>, so make a copy first!
     * </p>
     *
     * @param obj {Object} The object to modify.
     * @param prop {String} The property name to obfuscate.
     * @param value {Object} Optional. The value used to obfuscate.  If
     *                       not specified, the original value's data-type
     *                       is used.
     * @returns {Objects} A pointer to this utility class,
     *          enabling call chaining.
     */
  obfuscate(obj, prop, value) {
    if (!_isVoid(obj)
            && obj.hasOwnProperty(prop)) {
      if (arguments.length < 3) {
        const val = obj[prop];
        let dataType = (typeof val);

        if (dataType === 'object') {
          if (val instanceof Date) dataType = 'date';

          else if (val instanceof Array) dataType = 'array';
        }

        value = `{${ dataType.substring(0, 1).toUpperCase()
        }${dataType.substring(1).toLowerCase()
        }}`;
      }

      obj[prop] = value;
    }

    return this;
  },

  /**
    * Returns an object which contains only a
    * pointer to a given object.
    */
  createPointer(obj) {
    _validObjInst(obj, 'obj');

    const F = function () {
    };
    F.prototype = obj;

    return new F();
  },

  /**
    * Inherit prototype from another class.
    *
    * @param {Function} subClass A class
    * @param {Function} superClass A class
    */
  inheritPrototype(subClass, superClass) {
    if (typeof subClass !== 'function'
            || typeof superClass !== 'function') throw 'IllegalArgumentException: subClass and superClass must be Function.';

    const p = Objects.createPointer(superClass.prototype);
    p.constructor = subClass;
    subClass.prototype = p;
  },

  /**
     * Add properties to a class's prototype.
     *
     * @param {Function} classObj A class
     * @param {Object} obj Holder of values, methods to attach to `classObj.prototype`.
     * @param {boolean} [notOverwrite=false] Whether to throw error when is going to add a
     *        property which already exists.
     */
  addToPrototype(classObj, obj, notOverwrite) {
    if (typeof classObj !== 'function') throw 'IllegalArgumentException: classObj must be a Class.';

    _validObjInst(obj, 'obj');
    _validObjInst(classObj.prototype, 'classObj.prototype');

    const proto = classObj.prototype;

    for (const p of Object.keys(obj)) {
      if (proto.hasOwnProperty(p)
                && notOverwrite === true) {
        throw new Error(`property "${ p }" already exists in prototype`);
      }

      proto[p] = obj[p];
    }
  },

  /**
     * @param {string} propName Name of configuration within `Runtime.cfg`.
     * @param {*} [defaultValue] Default value to return if Runtime isn't setup or `propName`
     *            isn't found.
     * @returns {*} The named configuration from `Runtime.cfg` if Runtime is available and
     *          the named property exists; `defaultValue` otherwise.
     * @throws {Error} If `Runtime.cfg` is not available or `propName` is not found, and
     *         `defaultValue` has not been provided.
     */
  getRuntimeCfg(propName, defaultValue) {
    Strings.requireNonEmpty(propName, 'propName');

    if (typeof Runtime !== 'undefined'
            && _isObj(Runtime.cfg)
            && Runtime.cfg.hasOwnProperty(propName)) {
      return Runtime.cfg[propName];
    } if (arguments.length > 1) {
      return defaultValue;
    }
    throw new Error(`Runtime config not found: ${ propName}`);
  },

  /**
     * Returns whether the Runtime object is available.  Optionally, returns whether
     * it has an object declared as `Runtime[propName]`.
     * @param {string} [propName]
     * @returns {boolean} Whether `Runtime` is available and optionally `Runtime[propName]`.
     */
  hasRuntimeObj(propName) {
    if (typeof Runtime === 'undefined') {
      return false;
    } if (arguments.length > 0) {
      return Runtime.hasOwnProperty(Strings.requireNonEmpty(propName, 'propName'));
    }
    return true;
  },
});

/* **************************************************
 * Public object
 * ************************************************** */

export default Objects;
export const requireObject = Objects.requirePlain;
export const { requireNonVoid } = Objects;
export const isObject = Objects.is;
export const { isVoid } = Objects;

import axios from 'axios';
import { getUserName } from '../../../utils/authService';
import templateString from '../../../utils/stringUtils';
import getAPIConfigs from './config.js';

const config = getAPIConfigs();
let replacement;
const actions = {
  getCompanyGroups({ commit }) {
    const USER_NAME = getUserName();
    replacement = {
      USER_NAME,
    };
    return axios.get(templateString(config.API_USER_DATA, replacement))
      .then((response) => {
        const COMPANY_NAME = response.data.company;
        commit('SET_COMPANY', COMPANY_NAME);
        replacement = {
          COMPANY_NAME,
        };
        return axios.get(templateString(config.API_COMPANY_GROUPS, replacement))
          .then((response1) => {
            const companyGroups = response1.data;
            commit('ADD_GROUPS', response1.data);
            return axios.get(templateString(config.API_COMPANY_USERS, replacement))
              .then((response2) => {
                commit('SET_USERS_LIST', response2.data);
                commit('SET_PAGE_STATUS', 'SUCCESS');
                return companyGroups;
              });
          });
      })
      .catch((error) => {
        console.log('Failure', error);
        commit('SET_PAGE_STATUS', 'ERROR');
      });
  },
  getGroupUsersList({ commit, state }, groupName) {
    replacement = {
      COMPANY_NAME: state.companyName,
      GROUP_NAME: groupName,
    };
    return axios.get(templateString(config.API_GROUP_USERS, replacement))
      .then((response) => {
        const groupUsers = {
          groupName,
          userList: response.data.readUsers,
          feedNames: response.data.feedNames,
        };
        commit('SET_GROUP_USERS', groupUsers);
        commit('SET_PAGE_STATUS', 'SUCCESS');
        return response;
      })
      .catch((errors) => {
        commit('SET_PAGE_STATUS', 'ERROR');
        commit('SET_ERROR_MESSAGE', errors);
      });
  },
  saveGroupChanges({ commit, state }, params) {
    replacement = {
      COMPANY_NAME: state.companyName,
    };
    let reqGroupUsersData = '';
    if (params.action !== 'DELETE') {
      reqGroupUsersData = axios({
        method: params.action === 'ADD' ? 'post' : 'put',
        url: templateString(config.API_COMPANY_GROUPS, replacement),
        data: params.value,
      });
    } else {
      const replacement1 = {
        COMPANY_NAME: state.companyName,
        GROUP_NAME: params.value.name,
      };
      reqGroupUsersData = axios({
        method: 'delete',
        url: templateString(config.API_GROUP_USERS, replacement1),
        data: params.value.name,
      });
    }
    return reqGroupUsersData.then((response) => {
      commit('SET_PAGE_STATUS', 'SUCCESS');
      return response;
    }).catch(error => error.response);
  },
};

export default actions;

<template>
  <div>
    <mds-loader
      v-show="showLoader"
      size="large"
    />
    <div
      v-if="!showLoader"
      class="global-settings-component"
    >
      <mds-layout-grid>
        <mds-row>
          <mds-col :cols="4">
            <mds-list-group spacious>
              <mds-list-group-item
                v-for="(entry) in globalSettingsMap"
                :key="entry[0]"
                :text="entry[1].name"
                :active="selectedKeyId==entry[0]"
                :aria-current="selectedKeyId==entry[0]"
                @mds-list-item-clicked="keySelected(entry[0])"
              />
            </mds-list-group>
          </mds-col>
          <mds-col :cols="8">
            <mds-table>
              <mds-thead>
                <mds-th />
                <mds-th />
              </mds-thead>
              <mds-tbody>
                <template
                  v-for="(element,index) in activeKeyList"
                >
                  <mds-tr
                    :key="index"
                    align-vertical="center"
                    :type="selectedKeyId && globalSettingsMap.get(selectedKeyId).name == 'Email Preferences'
                      && element.subKeyName == dataAlerts ? 'expandable' : null"
                    :expanded="showEmailDataAlerts"
                  >
                    <mds-td> {{ element.subKeyName }} </mds-td>
                    <mds-td>
                      <mds-switch
                        :value="'' + element.subKeyId"
                        hidden-label
                        :checked="selectedKeyId && globalSettingsMap.get(selectedKeyId).name == 'Email Preferences'
                          && element.subKeyName == dataAlerts ? showEmailDataAlerts :element.enabled"
                        @change.native.prevent="switchToggle($event,element.subKeyId,element.subKeyName)"
                      >
                        {{ element.subKeyName }}
                      </mds-switch>
                    </mds-td>
                  </mds-tr>
                  <mds-tr
                    v-if="selectedKeyId && globalSettingsMap.get(selectedKeyId).name == 'Email Preferences'
                      && element.subKeyName == dataAlerts && showEmailDataAlerts"
                    :key="index + '_container'"
                    type="container"
                  >
                    <mds-td colspan="2">
                      <mds-form>
                        <mds-fieldset
                          size="small"
                          variation="radio-group"
                          microcopy-above="Select Email Frequency"
                          style="margin-left: 10px"
                        >
                          <mds-radio-button
                            v-model="cron"
                            size="small"
                            name="frequency"
                            value="0 0 0/1 1/1 * ?"
                            checked
                          >
                            Hourly
                          </mds-radio-button>
                          <mds-radio-button
                            v-model="cron"
                            size="small"
                            name="frequency"
                            value="0 0 10 1/1 * ?"
                          >
                            Daily
                          </mds-radio-button>
                          <mds-radio-button
                            v-model="cron"
                            size="small"
                            name="frequency"
                            value="0 0 10 ? * MON"
                          >
                            Weekly
                          </mds-radio-button>
                        </mds-fieldset>
                        <mds-button-container>
                          <mds-button
                            variation="secondary"
                            size="small"
                            type="button"
                            :disabled="cron === ''"
                            @click="disableEmailDataAlerts"
                          >
                            Cancel
                          </mds-button>
                          <mds-button
                            variation="primary"
                            size="small"
                            type="button"
                            :disabled="cron === ''"
                            @click="submitDataAlertCron"
                          >
                            Submit
                          </mds-button>
                        </mds-button-container>
                      </mds-form>
                    </mds-td>
                  </mds-tr>
                </template>
              </mds-tbody>
            </mds-table>
          </mds-col>
        </mds-row>
        <mds-row>
          <mds-col :push="4">
            <template v-if="gsUpdateError">
              <p class="gs-update-error">
                {{ gsUpdateMessage }}
              </p>
            </template>
            <template v-else-if="gsUpdateSuccess">
              <p class="gs-update-success">
                {{ gsUpdateMessage }}
              </p>
            </template>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MdsSwitch from '@mds/switch';
import MdsRadioButton from '@mds/radio-button';
import MdsLoader from '@mds/loader';
import MdsFieldset from '@mds/fieldset';
import MdsForm from '@mds/form';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import {
  MdsTable, MdsThead, MdsTbody, MdsTh, MdsTr, MdsTd,
} from '@mds/data-table';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group-v4';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';

export default {
  name: 'GlobalSettings',
  components: {
    MdsListGroup,
    MdsListGroupItem,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsSwitch,
    MdsRadioButton,
    MdsFieldset,
    MdsLoader,
    MdsTable,
    MdsTbody,
    MdsThead,
    MdsTh,
    MdsTr,
    MdsTd,
    MdsForm,
    MdsButton,
    MdsButtonContainer,
  },
  data() {
    return {
      gsPopoverModal: true,
      globalSettingsMap: new Map(),
      selectedKeyId: null,
      gsUpdateError: false,
      gsUpdateSuccess: false,
      gsUpdateMessage: '',
      keyNameIdMap: new Map(),
      activeKeyList: [],
      showEmailDataAlerts: false,
      showLoader: true,
      cron: String,
      dataAlerts: 'Data Alerts',
    };
  },
  computed: {
    ...mapState({
      emailPrefsKeys: state => state.homeModule.emailPrefsKeys,
      marketsKeys: state => state.homeModule.marketsKeys,
      regionsKeys: state => state.homeModule.regionsKeys,
    }),
    ...mapGetters({
      globalSettings: 'homeModule/getGlobalSettings',
      cronGetter: 'homeModule/getDataAlertsCron',
    }),
  },
  created() {
    this.globalSettingsMap = this.globalSettings;
    if (this.globalSettingsMap) {
      this.globalSettingsMap.forEach((value, key) => {
        this.keyNameIdMap.set(value.name, key);
        if (value.name === 'Email Preferences' && Array.isArray(value.subKeys) && value.subKeys.length > 0) {
          value.subKeys.forEach((element) => {
            if (element.subKeyName === this.dataAlerts && element.enabled) {
              this.showEmailDataAlerts = true;
            }
          });
        }
      });
    }
    this.cron = this.cronGetter;
    this.showLoader = false;
  },
  methods: {
    ...mapActions('homeModule', ['setUserGlobalSettings']),
    keySelected(index) {
      this.invalidateNotifMessages();
      this.selectedKeyId = index;
      this.activeKeyList = this.globalSettingsMap.get(index).subKeys;
    },
    invalidateNotifMessages() {
      this.gsUpdateSuccess = false;
      this.gsUpdateError = false;
      this.gsUpdateMessage = '';
    },
    handleEmailPrefs(event, subKeyId, subKeyName, keyName) {
      const marketsKeyName = this.marketsKeys.keyName;
      const regionsKeyName = this.regionsKeys.keyName;
      const marketsKeyId = this.keyNameIdMap.get(marketsKeyName);
      const regionsKeyId = this.keyNameIdMap.get(regionsKeyName);
      const emailPrefsKeyId = this.keyNameIdMap.get(this.emailPrefsKeys.keyName);
      const marketsSubsCount = this.globalSettingsMap.get(marketsKeyId).subKeys.filter(element => element.enabled).length;
      const regionsSubsCount = this.globalSettingsMap.get(regionsKeyId).subKeys.filter(element => element.enabled).length;
      const newDWObject = this.globalSettingsMap.get(emailPrefsKeyId).subKeys
        .filter(element => element.subKeyName === this.emailPrefsKeys.newDataVendor)[0];
      const marketsRegionsKeyStatus = ((keyName === this.marketsKeys.keyName && marketsSubsCount === 1)
        || (keyName === this.regionsKeys.keyName && regionsSubsCount === 1));
      if (event && subKeyName === this.emailPrefsKeys.newDataVendor && (marketsSubsCount < 1 || regionsSubsCount < 1)) {
        this.gsUpdateError = true;
        this.gsUpdateMessage = `Please add at least one subscription in both ${ marketsKeyName } and ${ regionsKeyName}`;
        return false;
      }
      if (!event && newDWObject.enabled && marketsRegionsKeyStatus) {
        this.gsUpdateError = true;
        this.gsUpdateMessage = `Please disable
                                ${this.emailPrefsKeys.newDataVendor} before disabling all keys
                                in ${ marketsKeyName } and ${ regionsKeyName}`;
        return false;
      }
      return true;
    },
    setGlobalSettings(keyId, subKeyId, subKeyName, checked, isEmailDataAlert) {
      const payload = {
        keyId,
        subKeyId,
        action: checked ? 'ADD' : 'REMOVE',
        isEmailDataAlert,
      };
      if (isEmailDataAlert) {
        this.showLoader = true;
      }
      this.setUserGlobalSettings(payload).then(() => {
        this.gsUpdateSuccess = true;
        const eventStr = checked ? ' enabled' : ' disabled';
        this.gsUpdateMessage = `${subKeyName + eventStr } successfully`;
      }).catch((error) => {
        this.gsUpdateError = true;
        this.gsUpdateMessage = error.response.data ? error.response.data.message : error;
      }).finally(() => {
        this.showLoader = false;
      });
    },
    isEmailDataAlert(subKeyName) {
      const val = this.selectedKeyId && this.globalSettingsMap.get(this.selectedKeyId).name === 'Email Preferences'
        && subKeyName === this.dataAlerts;
      return val;
    },
    isEmailPrefs(keyName, checked, subKeyId, subKeyName) {
      return keyName === this.emailPrefsKeys.keyName && !this.handleEmailPrefs(checked, subKeyId, subKeyName, keyName);
    },
    async switchToggle(event, subKeyId, subKeyName) {
      const keyId = this.selectedKeyId;
      this.invalidateNotifMessages();
      const { checked } = event.target;
      const keyName = this.globalSettingsMap.get(keyId).name;
      let isEmailDataAlert = false;
      if (this.isEmailDataAlert(subKeyName)) {
        this.showEmailDataAlerts = event.target.checked;
        isEmailDataAlert = true;
        if (event.target.checked) {
          return;
        }
      } else if (this.isEmailPrefs(keyName, checked, subKeyId, subKeyName)) {
        this.globalSettingsMap.get(keyId).subKeys.forEach((element) => {
          if (element.subKeyId === subKeyId) {
            element.enabled = false;
          }
        });
        event.target.checked = false;
        return;
      }
      if (keyName === this.marketsKeys.keyName || keyName === this.regionsKeys.keyName) {
        if (!this.handleEmailPrefs(checked, subKeyId, subKeyName, keyName)) {
          this.globalSettingsMap.get(keyId).subKeys.forEach((element) => {
            if (element.subKeyId === subKeyId) {
              element.enabled = true;
            }
          });
          event.target.checked = true;
          return;
        }
      }
      this.setGlobalSettings(keyId, subKeyId, subKeyName, checked, isEmailDataAlert);
    },
    async submitDataAlertCron() {
      this.showLoader = true;
      const keyId = this.selectedKeyId;
      const { subKeyId } = this.globalSettingsMap.get(keyId).subKeys
        .filter(subKey => subKey.subKeyName === this.dataAlerts)[0];
      const payload = {
        keyId,
        subKeyId,
        action: 'ADD',
        isEmailDataAlert: true,
        cronExpression: this.cron,
      };
      this.setUserGlobalSettings(payload).then(() => {
        this.gsUpdateSuccess = true;
        const eventStr = ' enabled';
        this.gsUpdateMessage = `Data Alerts ${ eventStr } successfully`;
      }).catch((error) => {
        this.gsUpdateError = true;
        this.gsUpdateMessage = error.response.data ? error.response.data.message : error;
      }).finally(() => {
        this.showLoader = false;
      });
    },
    async disableEmailDataAlerts() {
      const dataAlertObj = this.globalSettingsMap.get(this.selectedKeyId).subKeys
        .filter(subKey => subKey.subKeyName === this.dataAlerts)[0];
      const { subKeyId, subKeyName } = dataAlertObj;
      await this.switchToggle({ target: { checked: false } }, subKeyId, subKeyName);
    },
  },
};
</script>
<style scoped>
.global-settings-component{
  height:calc(70vh);
  width:calc(50vw);
}
@media (max-width: 1024px) {
  .global-settings-component{
    width:calc(60vw);
  }
}
@media (max-width: 768px) {
  .global-settings-component{
    width:calc(70vw);
  }
}
@media (max-width: 560px) {
  .global-settings-component{
    width:calc(90vw);
  }
}
.gs-update-error{
  color:red;
}
.gs-update-success{
  color:green;
}

td.mds-td___Mcd-ui::before{
  display: none !important;
}
</style>

<template>
  <div>
    <mcd-card-section
      :key="$root.$i18n.locale"
      item-type="research"
    />
  </div>
</template>
<script>
import McdCardSection from '../components/McdCard/McdCardSection';

export default {
  name: 'Research',
  components: { McdCardSection },
};

</script>

// https://msstash.morningstar.com/projects/FP/repos/cwp-vue-components/browse/packages/cwp-dev-shared/mixins/component-resize.js

import debounce from 'lodash/debounce';
import ResizeObserver from 'resize-observer-polyfill/dist/ResizeObserver';
import { isValidElement } from '../helpers/dom';

export const BREAKPOINTS = [
  { name: 'xsmall', value: 320 },
  { name: 'small', value: 600 },
  { name: 'medium', value: 768 },
  { name: 'large', value: 1000 },
  { name: 'xlarge', value: 1200 },
];

export function findBreakpoint(width = window.innerWidth, breakpoints = BREAKPOINTS) {
  // null <= 320 is TRUE
  let index = typeof width === 'number' ? breakpoints.findIndex(b => width <= b.value) : -1;
  index = index >= 0 ? index : breakpoints.length - 1;
  return {
    index,
    ...breakpoints[index],
  };
}

export default {
  data: () => ({
    elementWidth: 0,
  }),
  created() {
    this.$_breakpoints = BREAKPOINTS;
  },
  mounted() {
    this.connectObserver();
  },
  beforeDestroy() {
    this.disconnectObserver();
  },
  computed: {
    breakpoint() {
      const breakpoint = findBreakpoint(this.elementWidth, this.$_breakpoints);
      return breakpoint.name;
    },
    breakpointClass() {
      if (this.breakpoint === 'xsmall') {
        return `${this.namespace || this.$options.name}--viewport-${this.breakpoint}`;
      }

      return `${this.namespace || this.$options.name}--${this.breakpoint}`;
    },
  },
  methods: {
    onResize(width) {
      this.elementWidth = width || this.$el.clientWidth;
    },
    connectObserver() {
      const _this = this;
      const { $el } = _this;

      if (!isValidElement($el)) {
        return;
      }

      _this.$_debouncedResize = debounce(_this.onResize, 100, true);
      _this.$_ro = new ResizeObserver((entries) => {
        const entry = entries.find(e => e.target === $el);
        const { paddingLeft, paddingRight } = window.getComputedStyle($el);
        const { width } = entry.contentRect;

        if (width) {
          _this.onResize(width
            + Number.parseFloat(paddingLeft)
            + Number.parseFloat(paddingRight));
        }
      });
      _this.$_ro.observe($el);
      _this.$_debouncedResize();
    },
    disconnectObserver() {
      try {
        this.$_ro.unobserve(this.$el);
        this.$_ro.disconnect();
      } catch (e) {
        // Unable to disconnect
      }
    },
  },
  watch: {
    $el(newValue, oldValue) {
      if (isValidElement(newValue) && !isValidElement(oldValue)) {
        this.connectObserver();
      } else if (isValidElement(oldValue) && !isValidElement(newValue)) {
        this.disconnectObserver();
      }
    },
  },
};

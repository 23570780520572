<template>
  <nav>
    <mds-list-group has-collapsible-child>
      <mds-list-group-item
        text="Markets"
        collapsible
        :expanded="marketCollapse"
        @click.native.stop="handleMarketCollapse"
      >
        <div slot="mds-list-item-children">
          <mds-loader
            v-if="$apolloData && $apolloData.queries.marketNav.loading"
          />
          <div v-else>
            <mds-list-group>
              <mds-list-group-item
                v-for="(market,key) in marketsHolder "
                :key="key"
                @click.native.stop="()=>{}"
              >
                <mds-checkbox
                  v-if="market === 'Lng' || market === 'Lpgs' || market === 'Ngls'"
                  name="checkbox-name"
                  :value="market"
                  :checked="selectedSideNav.market.includes(market.toLowerCase())"
                  @click.native.stop="()=>{}"
                  @change="handleMarketSelection"
                >
                  {{ checkAbbreviations(market) }}
                </mds-checkbox>
                <mds-checkbox
                  v-else
                  name="checkbox-name"
                  :value="market"
                  :checked="selectedSideNav.market.includes(market.toLowerCase())"
                  @click.native.stop="()=>{}"
                  @change="handleMarketSelection"
                >
                  {{ market }}
                </mds-checkbox>
              </mds-list-group-item>
            </mds-list-group>
          </div>
        </div>
      </mds-list-group-item>
      <mds-list-group-item
        text="Countries"
        collapsible
        :expanded="countryCollapse"
        @click.native.stop="handleCountryCollapse"
      >
        <div slot="mds-list-item-children">
          <mds-loader
            v-if="$apolloData && $apolloData.queries.countryNav.loading"
          />
          <div v-else>
            <mds-list-group has-collapsible-child>
              <mds-list-group-item
                v-if="countriesHolderAsia.length !== 0"
                collapsible
                :expanded="countryAsiaCollapse"
                @click.native.stop="handleCountriesAsiaCollapse"
              >
                Asia
                <div slot="mds-list-item-children">
                  <mds-list-group>
                    <mds-list-group-item
                      v-for="(country,key) in countriesHolderAsia "
                      :key="key"
                      @click.native.stop="()=>{}"
                    >
                      <mds-checkbox
                        :key="'field' + country"
                        name="checkbox-name"
                        class="countries__checkbox"
                        :value="country"
                        :checked="selectedSideNav.country.includes(country.toLowerCase())"
                        @change="handleCountrySelection"
                      >
                        {{ country }}
                      </mds-checkbox>
                    </mds-list-group-item>
                  </mds-list-group>
                </div>
              </mds-list-group-item>
              <mds-list-group-item
                v-if="countriesHolderAfrica.length !== 0"
                collapsible
                :expanded="countryAfricaCollapse"
                @click.native.stop="handleCountriesAfricaCollapse"
              >
                Africa
                <div slot="mds-list-item-children">
                  <mds-list-group>
                    <mds-list-group-item
                      v-for="(country,key) in countriesHolderAfrica "
                      :key="key"
                      @click.native.stop="()=>{}"
                    >
                      <mds-checkbox
                        :key="'field' + country"
                        name="checkbox-name"
                        class="countries__checkbox"
                        :value="country"
                        :checked="selectedSideNav.country.includes(country.toLowerCase())"
                        @change="handleCountrySelection"
                      >
                        {{ country }}
                      </mds-checkbox>
                    </mds-list-group-item>
                  </mds-list-group>
                </div>
              </mds-list-group-item>
              <mds-list-group-item
                v-if="countriesHolderNorthAmerica.length !== 0"
                collapsible
                :expanded="countryNorthAmericaCollapse"
                @click.native.stop="handleCountriesNorthAmericaCollapse"
              >
                North America
                <div slot="mds-list-item-children">
                  <mds-list-group>
                    <mds-list-group-item
                      v-for="(country,key) in countriesHolderNorthAmerica "
                      :key="key"
                      @click.native.stop="()=>{}"
                    >
                      <mds-checkbox
                        :key="'field' + country"
                        name="checkbox-name"
                        class="countries__checkbox"
                        :value="country"
                        :checked="selectedSideNav.country.includes(country.toLowerCase())"
                        @change="handleCountrySelection"
                      >
                        {{ country }}
                      </mds-checkbox>
                    </mds-list-group-item>
                  </mds-list-group>
                </div>
              </mds-list-group-item>
              <mds-list-group-item
                v-if="countriesHolderSouthAmerica.length !== 0"
                collapsible
                :expanded="countrySouthAmericaCollapse"
                @click.native.stop="handleCountriesSouthAmericaCollapse"
              >
                South America
                <div slot="mds-list-item-children">
                  <mds-list-group>
                    <mds-list-group-item
                      v-for="(country,key) in countriesHolderSouthAmerica "
                      :key="key"
                      @click.native.stop="()=>{}"
                    >
                      <mds-checkbox
                        :key="'field' + country"
                        name="checkbox-name"
                        class="countries__checkbox"
                        :value="country"
                        :checked="selectedSideNav.country.includes(country.toLowerCase())"
                        @change="handleCountrySelection"
                      >
                        {{ country }}
                      </mds-checkbox>
                    </mds-list-group-item>
                  </mds-list-group>
                </div>
              </mds-list-group-item>
              <mds-list-group-item
                v-if="countriesHolderEurope.length !== 0"
                collapsible
                :expanded="countryEuropeCollapse"
                @click.native.stop="handleCountriesEuropeCollapse"
              >
                Europe
                <div slot="mds-list-item-children">
                  <mds-list-group>
                    <mds-list-group-item
                      v-for="(country,key) in countriesHolderEurope "
                      :key="key"
                      @click.native.stop="()=>{}"
                    >
                      <mds-checkbox
                        :key="'field' + country"
                        name="checkbox-name"
                        class="countries__checkbox"
                        :value="country"
                        :checked="selectedSideNav.country.includes(country.toLowerCase())"
                        @change="handleCountrySelection"
                      >
                        {{ country }}
                      </mds-checkbox>
                    </mds-list-group-item>
                  </mds-list-group>
                </div>
              </mds-list-group-item>
              <mds-list-group-item
                v-if="countriesHolderOceania.length !== 0"
                collapsible
                :expanded="countryOceaniaCollapse"
                @click.native.stop="handleCountriesOceaniaCollapse"
              >
                Oceania
                <div slot="mds-list-item-children">
                  <mds-list-group>
                    <mds-list-group-item
                      v-for="(country,key) in countriesHolderOceania "
                      :key="key"
                      @click.native.stop="()=>{}"
                    >
                      <mds-checkbox
                        :key="'field' + country"
                        name="checkbox-name"
                        class="countries__checkbox"
                        :value="country"
                        :checked="selectedSideNav.country.includes(country.toLowerCase())"
                        @change="handleCountrySelection"
                      >
                        {{ country }}
                      </mds-checkbox>
                    </mds-list-group-item>
                  </mds-list-group>
                </div>
              </mds-list-group-item>
              <mds-list-group-item
                v-if="countriesHolderAntarctica.length !== 0 "
                collapsible
                :expanded="countryAntarcticaCollapse"
                @click.native.stop="handleCountriesAntarcticaCollapse"
              >
                Antarctica
                <div slot="mds-list-item-children">
                  <mds-list-group>
                    <mds-list-group-item
                      v-for="(country,key) in countriesHolderAntarctica "
                      :key="key"
                      @click.native.stop="()=>{}"
                    >
                      <mds-checkbox
                        :key="'field' + country"
                        name="checkbox-name"
                        class="countries__checkbox"
                        :value="country"
                        :checked="selectedSideNav.country.includes(country.toLowerCase())"
                        @change="handleCountrySelection"
                      >
                        {{ country }}
                      </mds-checkbox>
                    </mds-list-group-item>
                  </mds-list-group>
                </div>
              </mds-list-group-item>
              <mds-list-group-item
                v-if="countriesHolder.length !== 0"
                collapsible
                :expanded="countryOtherCollapse"
                @click.native.stop="handleCountriesOtherCollapse"
              >
                Other
                <div slot="mds-list-item-children">
                  <mds-list-group>
                    <mds-list-group-item
                      v-for="(country,key) in countriesHolder "
                      :key="key"
                      @click.native.stop="()=>{}"
                    >
                      <mds-checkbox
                        :key="'field' + country"
                        name="checkbox-name"
                        class="countries__checkbox"
                        :value="country"
                        :checked="selectedSideNav.country.includes(country.toLowerCase())"
                        @change="handleCountrySelection"
                      >
                        {{ country }}
                      </mds-checkbox>
                    </mds-list-group-item>
                  </mds-list-group>
                </div>
              </mds-list-group-item>
            </mds-list-group>
          </div>
        </div>
      </mds-list-group-item>
      <mds-list-group-item
        text="Licences"
        collapsible
        :expanded="licenseCollapse"
        @click.native.stop="handleLicencesCollapse"
      >
        <div slot="mds-list-item-children">
          <mds-loader
            v-if="$apolloData && $apolloData.queries.licensesNav.loading"
          />
          <div v-else>
            <mds-list-group>
              <mds-list-group-item
                v-for="(license,key) in licencesHolder "
                :key="key"
                :expanded="marketCollapse"
                @click.native.stop="handleMarketCollapse"
              >
                <mds-checkbox
                  :key="'field' + license"
                  name="checkbox-name"
                  :value="license"
                  :checked="selectedSideNav.licensingType.includes(license.toLowerCase())"
                  @click.native.stop="()=>{}"
                  @change="handleLicensesSelection"
                >
                  {{ license }}
                </mds-checkbox>
              </mds-list-group-item>
            </mds-list-group>
          </div>
        </div>
      </mds-list-group-item>
    </mds-list-group>
  </nav>
</template>
<script>

import MdsCheckbox from '@mds/checkbox';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import gql from 'graphql-tag';
import {
  countriesShortedOceania,
  countriesShortedAsia,
  countriesShortedAfrica,
  countriesShortedEurope,
  countriesShortedNorthAmerica,
  countriesShortedSouthAmerica,
  countriesShortedAntarctica,
} from '@/components/CuratedFeeds/staticData';
import { ThemeMixin } from '../../mixins/themeMixin';

const continentsShorted = ['Asia', 'Africa', 'North America', 'South America', 'Europe', 'Oceania', 'Antarctica', 'Other'];

const pickBy = require('lodash/pickBy');
/* eslint no-tabs: 0 */
export default {
  name: 'LeftNavigation',
  components: {
    MdsCheckbox, MdsListGroup, MdsListGroupItem,
  },
  filters: {
    titleize(value) {
      return value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase()).replace(' And ', ' and ');
    },
  },
  mixins: [ThemeMixin],
  props: {
    refreshSignal: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      countryNav: [],
      marketNav: [],
      licensesNav: [],
      countriesHolder: [],
      countriesHolderAsia: [],
      countriesHolderAfrica: [],
      countriesHolderEurope: [],
      countriesHolderNorthAmerica: [],
      countriesHolderSouthAmerica: [],
      countriesHolderOceania: [],
      countriesHolderAntarctica: [],
      marketCollapse: true,
      countryCollapse: true,
      licenseCollapse: true,
      countryOtherCollapse: false,
      countryAsiaCollapse: false,
      countryAfricaCollapse: false,
      countryEuropeCollapse: false,
      countryNorthAmericaCollapse: false,
      countrySouthAmericaCollapse: false,
      countryOceaniaCollapse: false,
      countryAntarcticaCollapse: false,
      marketsHolder: [],
      licencesHolder: [],
      selectedSideNav: {
        market: [],
        country: [],
        licensingType: [],
      },
    };
  },
  apollo: {
    countryNav: {
      query: gql`query {
		 			countryNav
    		}`,
    },
    marketNav: {
      query: gql`query {
		 			marketNav
    	}`,
    },
    licensesNav: {
      query: gql`query {
		 			licensesNav
    	}`,
    },
  },
  computed: {
    countries() {
      return pickBy(this.countryNav, (value, key) => !(value === undefined || value === ''));
    },
    markets() {
      return pickBy(this.marketNav, (value, key) => !(value === undefined || value === ''));
    },
    licences() {
      return pickBy(this.licensesNav, (value, key) => !(value === undefined || value === ''));
    },
  },
  watch: {
    countries: {
      deep: true,
      handler(countries) {
        if (Object.keys(countries).length > 0) {
          Object.keys(countries).forEach((key) => {
            if (countries[key].includes(';')) {
              const arr = countries[key].toLowerCase().trim().split(';').map(item => item.trim());
              arr.forEach((country) => {
                this.addToCountryList(country.trim());
              });
            } else {
              this.addToCountryList(countries[key].toLowerCase());
            }
          });
          this.countriesHolder = this.proccessList(this.countriesHolder);
          this.countriesHolderAsia = this.proccessList(this.countriesHolderAsia);
          this.countriesHolderAfrica = this.proccessList(this.countriesHolderAfrica);
          this.countriesHolderEurope = this.proccessList(this.countriesHolderEurope);
          this.countriesHolderOceania = this.proccessList(this.countriesHolderOceania);
          this.countriesHolderNorthAmerica = this.proccessList(this.countriesHolderNorthAmerica);
          this.countriesHolderSouthAmerica = this.proccessList(this.countriesHolderSouthAmerica);
          this.countriesHolderAntarctica = this.proccessList(this.countriesHolderAntarctica);
          return this.countriesHolder;
        }
        return [];
      },
    },
    markets: {
      deep: true,
      handler(markets) {
        if (Object.keys(markets).length > 0) {
          Object.keys(markets).forEach((key) => {
            if (markets[key].includes(';')) {
              const arr = markets[key].toLowerCase().split(';').map(item => item.trim());
              this.marketsHolder = [...this.marketsHolder, ...arr];
            } else {
              this.marketsHolder.push(markets[key].toLowerCase());
            }
          });
          this.marketsHolder = this.proccessList(this.marketsHolder);
          return this.marketsHolder;
        }
        return [];
      },
    },
    licences: {
      deep: true,
      handler(licenses) {
        if (Object.keys(licenses).length > 0) {
          Object.keys(licenses).forEach((key) => {
            if (licenses[key].includes(';')) {
              const arr = licenses[key].toLowerCase().split(';').map(item => item.trim());
              this.licencesHolder = [...this.licencesHolder, ...arr];
            } else {
              this.licencesHolder.push(licenses[key].toLowerCase());
            }
          });
          this.licencesHolder = this.proccessList(this.licencesHolder);
          return this.licencesHolder;
        }
        return [];
      },
    },
  },
  methods: {
    handleMarketSelection(value) {
      this.check('market', value, event.target.value.toLowerCase());
      this.$emit('mcd-left-menu-change', this.selectedSideNav);
    },
    handleCountrySelection(value) {
      this.check('country', value, event.target.value.toLowerCase());
      this.$emit('mcd-left-menu-change', this.selectedSideNav);
    },
    handleLicensesSelection(value) {
      this.check('licensingType', value, event.target.value.toLowerCase());
      this.$emit('mcd-left-menu-change', this.selectedSideNav);
    },
    check(parent, value, itemName) {
      const doExist = this.selectedSideNav[parent].includes(itemName);
      // delete it
      if (!value && doExist) {
        const index = this.selectedSideNav[parent].indexOf(itemName);
        if (index > -1) {
          this.selectedSideNav[parent].splice(index, 1);
        }
      } else if (value && !doExist) {
        this.selectedSideNav[parent].push(itemName);
      }
    },
    checkAbbreviations(market) {
      if (market === 'Lng') {
        return `${market.toUpperCase()}s`;
      }
      if (market === 'Lpgs') {
        return `${market.substring(0, 3).toUpperCase()}s`;
      }
      if (market === 'Ngls') {
        return `${market.substring(0, 3).toUpperCase()}s`;
      }
      return market.toUpperCase();
    },
    addToCountryList(country) {
      if (countriesShortedAsia.includes(this.$options.filters.titleize(country.trim()))) {
        this.countriesHolderAsia.push(this.$options.filters.titleize(country.trim()));
      } else if (countriesShortedAfrica.includes(this.$options.filters.titleize(country.trim()))) {
        this.countriesHolderAfrica.push(this.$options.filters.titleize(country.trim()));
      } else if (countriesShortedEurope.includes(this.$options.filters.titleize(country.trim()))) {
        this.countriesHolderEurope.push(this.$options.filters.titleize(country.trim()));
      } else if (countriesShortedAntarctica.includes(this.$options.filters.titleize(country.trim()))) {
        this.countriesHolderAntarctica.push(this.$options.filters.titleize(country.trim()));
      } else if (countriesShortedNorthAmerica.includes(this.$options.filters.titleize(country.trim()))) {
        this.countriesHolderNorthAmerica.push(this.$options.filters.titleize(country.trim()));
      } else if (countriesShortedOceania.includes(this.$options.filters.titleize(country.trim()))) {
        this.countriesHolderOceania.push(this.$options.filters.titleize(country.trim()));
      } else if (countriesShortedSouthAmerica.includes(this.$options.filters.titleize(country.trim()))) {
        this.countriesHolderSouthAmerica.push(this.$options.filters.titleize(country.trim()));
      } else {
        this.countriesHolder.push(this.$options.filters.titleize(country.trim()));
      }
    },
    proccessList(data) {
      const results = [];
      data.forEach((c) => {
        if (!results.includes(this.$options.filters.titleize(c.trim()))) {
          results.push(this.$options.filters.titleize(c.trim()));
        }
      });
      return results.sort();
    },
    handleMarketCollapse() {
      this.marketCollapse = !this.marketCollapse;
    },
    handleCountryCollapse() {
      this.countryCollapse = !this.countryCollapse;
    },
    handleLicencesCollapse() {
      this.licenseCollapse = !this.licenseCollapse;
    },
    handleCountriesAsiaCollapse() {
      this.countryAsiaCollapse = !this.countryAsiaCollapse;
    },
    handleCountriesAfricaCollapse() {
      this.countryAfricaCollapse = !this.countryAfricaCollapse;
    },
    handleCountriesEuropeCollapse() {
      this.countryEuropeCollapse = !this.countryEuropeCollapse;
    },
    handleCountriesNorthAmericaCollapse() {
      this.countryNorthAmericaCollapse = !this.countryNorthAmericaCollapse;
    },
    handleCountriesSouthAmericaCollapse() {
      this.countrySouthAmericaCollapse = !this.countrySouthAmericaCollapse;
    },
    handleCountriesOceaniaCollapse() {
      this.countryOceaniaCollapse = !this.countryOceaniaCollapse;
    },
    handleCountriesAntarcticaCollapse() {
      this.countryAntarcticaCollapse = !this.countryAntarcticaCollapse;
    },
    handleCountriesOtherCollapse() {
      this.countryOtherCollapse = !this.countryOtherCollapse;
    },
  },
};
</script>
<style lang="scss">
  @import '@mds/constants';
  @import '@mds/typography';
  @import '@mds/link';

  .side-bar__container {
    box-sizing: border-box;
    padding: $mds-space-2-x;
    flex-shrink: 0;
    height: calc(100vh);
    width: 300px;
    overflow-y: scroll;
    .mds-section:not(:last-child) {
      margin-bottom: $mds-space-4-x;
    }
  }

  .mds-list-group-item__text___Mcd-ui{
    width:100%;
    display: inline-block;
  }
  .mds-list-group-item__link-inner___Mcd-ui {
    padding-left: 0;
  }
</style>

<template>
  <div class="nested-columns-col-wrapper">
    <mds-section
      class="section-wrap"
      title="Columns"
      border="tertiary"
      border-position="bottom"
    />
    <div class="list-content-wrapper">
      <mds-list-group
        class="list-content"
        variation="navigation"
      >
        <mds-list-group-item
          v-for="(column, index) in columns"
          id="list-content-mds-list-group-item--columns"
          :key="index"
          :text="column"
          :active="activeItemId === index"
          @mds-list-item-clicked="columnSelected(column, index)"
        />
      </mds-list-group>
    </div>
  </div>
</template>
<script>
import MdsSection from '@mds/section';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group-v4';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'FeedColumn',
  components: {
    MdsSection,
    MdsListGroup,
    MdsListGroupItem,
  },
  data() {
    return {
      activeItemId: '',
      feedName: '',
      dataPreviewKeysValue: '',
    };
  },
  computed: {
    ...mapGetters({
      columns: 'dashboardModule/allCols',
    }),
  },
  created() {
    this.$eventBus.$on('searchClearFeed', (data) => {
      this.activeItemId = data;
    });
  },
  mounted() {
    this.$eventBus.$on('feedNameSelected', (data) => {
      this.feedName = data;
    });
    this.$eventBus.$on('keyValueDataPreview', (data) => {
      this.dataPreviewKeysValue = data;
    });
  },
  methods: {
    ...mapActions('dashboardModule',
      ['getDataPreviewKeys']),
    columnSelected(selectedColumnName, colId) {
      this.activeItemId = colId;
      const timeSeries = moment().subtract(5, 'day').format('YYYY-MM-DD');
      let constructParam = '';
      constructParam += `${this.dataPreviewKeysValue}&cols=${selectedColumnName}&fromDateTime=${timeSeries}`;
      this.getDataPreviewKeys({ feedName: this.feedName, timeseries: constructParam });
    },
  },
};
</script>
<style lang='scss'>
@import '~@mds/constants';
@import '~@mds/fonts';
@import '~@mds/typography';

.nested-columns-col-wrapper {
  box-sizing: border-box;
  background-color: white;
  width: 300px;
  height: calc(85vh / 3);
  flex-grow: 1;
  border: 7px solid whitesmoke;
  display: flex;
  flex-direction: column;
}
.section-wrap {
  padding-left: $mds-space-1-x;
}

</style>

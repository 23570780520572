const getters = {
  getSchedulerList: state => state.schedulerList.map(element => ({
    scheduleId: element.scheduleId,
    scheduleName: element.scheduleName,
    name: element.scheduleName,
    description: element.description,
    enabled: element.enabled,
    lastUpdatedTime: element.lastUpdatedTime,
    creationTime: element.creationTime,
    cron: element.cron,
    disableToPubDate: element.disableToPubDate,
    fileDateFormat: element.fileDateFormat,
    fileDirectory: element.fileDirectory,
    fileSuffix: element.fileSuffix,
    fileType: element.fileType,
    includeHistory: element.includeHistory,
    lastValue: element.lastValue,
    listNames: element.listNames,
    machineId: element.machineId,
    newData: element.newData,
    numberDaysBack: element.numberDaysBack,
    numberOfPubDaysBack: element.numberOfPubDaysBack,
    timezone: element.timezone,
    msgBusCheck: element.msgBusCheck,
    messageBusType: element.messageBusType,
    busName: element.busName,
  })),
  getPubListForScheduler: state => state.pubList.map(element => (element.text)),
};

export default getters;

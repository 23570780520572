<template>
  <div class="page-container">
    <div class="page">
      <div
        v-if="hideSourcesPanel"
        class="btn"
      >
        <mds-button
          style="transform: translate(10px, 25px) rotate(90deg);"
          variation="flat"
          size="small"
          @click="showSourcePanel"
        >
          SOURCES
        </mds-button>
      </div>
      <FeedSource v-if="!hideSourcesPanel" />
      <FeedList @hide-sources="hideSources" />
      <FeedKey />
      <div>
        <div class="nested-columns-col-basket">
          <mds-button
            :class="[count !== 0 ? 'button-basket-colored-background': 'button-basket']"
            variation="icon-only"
            icon="sector-consumer-defensive"
            flat-button-icon-size="medium"
            type="button"
            @click="toggleBasket"
          />
        </div>
        <FeedColumn />
        <div class="nested-columns-col">
          <mds-tabs
            id="mds-tab"
            alignment="center"
            responsive
            :content="tabsContent"
            @mds-tabs-item-active="setActiveItem"
          />
          <FeedMetadata v-if="tabsContent[1].active === true" />
          <FeedPreview v-if="tabsContent[0].active === true" />
        </div>
      </div>
      <Basket v-if="open" />
    </div>
  </div>
</template>

<script>
import { MdsTabs } from '@mds/tabs';
import { mapGetters } from 'vuex';
import FeedSource from './FeedSources';
import FeedList from './FeedList';
import FeedKey from './FeedKey';
import FeedColumn from './FeedColumn';
import FeedMetadata from './FeedMetadata';
import FeedPreview from './FeedPreview';
import Basket from './Basket';

export default {
  name: 'NavigationFeedHome',
  components: {
    Basket,
    FeedPreview,
    FeedMetadata,
    FeedColumn,
    FeedKey,
    FeedList,
    FeedSource,
    MdsTabs,
  },
  data() {
    return {
      open: false,
      hideSourcesPanel: false,
      tabsContent: [{
        text: 'Data Preview',
        id: 'data',
        active: true,
      },
      {
        text: 'Metadata',
        id: 'metadata',
        active: false,
      },
      ],
    };
  },
  computed: {
    ...mapGetters({
      count: 'dashboardModule/basketLength',
    }),
  },
  created() {
    this.$eventBus.$on('close-basket-toggle', (data) => {
      this.open = data;
    });
  },
  methods: {
    hideSources() {
      if (!this.hideSourcesPanel) {
        this.hideSourcesPanel = !this.hideSourcesPanel;
      }
    },
    showSourcePanel() {
      this.hideSourcesPanel = !this.hideSourcesPanel;
    },
    setActiveItem(event) {
      this.tabsContent.forEach((item) => {
        item.active = item.id === event.currentTarget.id;
      });
    },
    toggleBasket() {
      this.open = true;
      this.$eventBus.$emit('open-basket-toggle', this.open);
    },
  },
};
</script>

<style lang="scss">
  @import '~@mds/constants';
  @import '~@mds/fonts';
  @import '~@mds/typography';

.page-container {
  border: solid 1px;
  width: 100%;
  margin: auto;
  color: #65778D;
  -webkit-box-shadow: 0 0 2px 0 rgba(131,131,131,0.52);
  box-shadow: 0 0 5px 0 rgba(131,131,131,0.52);
}
.page {
  display: flex;
}
.nested-columns-col-basket {
  box-sizing: border-box;
  background-color: white;
  width: 300px;
  height: calc(5vh);
  flex-grow: 1;
  border: 7px solid whitesmoke;
  display: flex;
  flex-direction: column;
}
  .button-basket{
    margin-left: auto;
    margin-right: 10px;
  }
  .button-basket-colored-background{
    .mds-button--icon-only .mds-button__icon {
      fill:$mds-color-blue-44;
    }
    margin-left: auto;
    margin-right: 10px;
  }
  .btn {
    box-sizing: border-box;
    background-color: white;
    border: 7px solid whitesmoke;
  }
  .nested-columns-col {
    box-sizing: border-box;
    background-color: white;
    width: 300px;
    height: calc(85vh - (85vh/3));
    flex-grow: 1;
    border: 7px solid whitesmoke;
    display: flex;
    flex-direction: column;
  }

</style>

<template>
  <div class="wf-debug-action">
    <!-- eslint-disable-next-line vue/no-lone-template -->
    <template>
      <mds-loader
        v-if="showLoader"
        size="small"
        aria-label="Small Loader"
      />
      <mds-form>
        <div>
          <mds-row>
            <mds-col>
              <mds-row>
                <mds-col :cols="1">
                  <span><strong>UUID: </strong></span>
                </mds-col>
                <mds-col>
                  <span>{{ currentBubbleFormulaData.uuid ? currentBubbleFormulaData.uuid : 'NA' }}</span>
                </mds-col>
              </mds-row>

              <mds-row>
                <mds-col :cols="1">
                  <span><strong>Modified: </strong></span>
                </mds-col>
                <mds-col>
                  <span>{{ currentBubbleFormulaData.modified ? currentBubbleFormulaData.modified : 'no' }}</span>
                </mds-col>
              </mds-row>

              <mds-row>
                <mds-col :cols="1">
                  <span><strong>Owner: </strong></span>
                </mds-col>
                <mds-col>
                  <span>{{ currentBubbleFormulaData.userName ? currentBubbleFormulaData.userName : 'NA' }}</span>
                </mds-col>
              </mds-row>
            </mds-col>
          </mds-row>
        </div>
        <div
          class="formula-plane"
          style="position: relative;  height: 72%; overflow-x: hidden;"
        >
          <mds-row style="margin-top: .5rem;">
            <mds-col>
              <pre>
                <span style="font-size: medium;">
                  {{ currentBubbleFormulaData.formula ? currentBubbleFormulaData.formula : '' }}
                </span>
              </pre>
            </mds-col>
          </mds-row>
        </div>
      </mds-form>
    </template>
  </div>
</template>

<script>
import MdsForm from '@mds/form';
import { mapActions } from 'vuex';
import { MdsRow, MdsCol } from '@mds/layout-grid';

export default {
  name: 'WorkflowQADebugActions',
  components: {
    MdsForm,
    MdsRow,
    MdsCol,
  },
  props: {
    node: {
      type: Object,
      default: null,
    },
    workflowData: {
      type: Object,
      default: null,
    },
    nodeVarName: {
      type: String,
      default: null,
    },
    formulas: {
      type: Array,
      default: null,
    },
    parameterSetData: {
      type: Object,
      default: null,
    },
    workflow: {
      type: Object,
      required: false,
      default: null,
    },
    isNewNode: {
      type: Boolean,
      required: false,
      default: false,
    },
    nodeVarNameEdit: {
      type: String,
      default: null,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showLoader: false,
      userName: '',
      uuid: '',
      formula: '',
      modified: '',
      currentBubbleFormulaData: {
        modified: '',
      },
      currentNodeFormula: '',
      formula_id: '',
    };
  },
  computed: {

  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('feedModule', ['getUserDatasources', 'getUserFeeds', 'getFeedDetailsByName', 'getFeedRoots']),
    init() {
      if (this.node.title === 'formula' || this.node.title === 'qa') {
        this.formula_id = this.node.data.formula_id;
        for (let i = 0; i < this.formulas.length; i++) {
          const element = this.formulas[i];
          if (element.uuid === this.formula_id) {
            this.currentBubbleFormulaData = element;
            break;
          }
        }
        this.currentBubbleFormulaData.modified = this.currentBubbleFormulaData.version === 1 ? 'Yes' : 'No';
        this.currentNodeFormula = this.currentBubbleFormulaData.formula;
      } else if (this.node.title === 'publish' || this.node.title === 'save') {
        const { connectedFormulaNodeId } = this.node.data;
        for (let i = 0; i < this.workflow.ui.bubbles.length; i++) {
          const element = this.workflow.ui.bubbles[i];
          if (element.type === 'formula' && element.id === connectedFormulaNodeId) {
            this.formula_id = element.props.formula_id;
            for (let j = 0; j < this.formulas.length; j++) {
              const e = this.formulas[j];
              if (e.uuid === this.formula_id) {
                this.currentBubbleFormulaData = e;
                break;
              }
            }
            this.currentBubbleFormulaData.modified = this.currentBubbleFormulaData.version === 1 ? 'Yes' : 'No';
            this.currentNodeFormula = this.currentBubbleFormulaData.formula;
            break;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  @import '@mds/typography';
  @import '@mds/constants';

  #fullscreen h3 {
      @include mds-level-5-heading();

      color: $mds-text-color-primary;
  }

  #fullscreen .mds-select___Mcd-ui {
    margin-top: 2% !important;
  }
  .btn-row {
    padding-top: 1%;
  }
  .wf-debug-action {
    height: 97% !important;
    padding: 1%;
  }
  .wf-debug-action form {
    height: 100% !important;
    width: 98% !important;
    position: absolute;
  }
  .formula-plane {
    margin-top: 1rem;
    border: 1px solid #1e1e1e;
  }
</style>

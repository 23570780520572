const mutations = {
  SET_PAGE_STATUS: (state, status) => {
    state.pageStatus = status;
  },
  ADD_GROUPS: (state, companyGroups) => {
    state.companyGroups = [];
    companyGroups.forEach((element) => {
      const groupObj = state.companyGroups.filter(c => c.groupName === element);
      if (groupObj.length === 0) {
        state.companyGroups.push({
          groupName: element,
          userList: [],
          feedNames: [],
          loaded: false,
          loader: false,
          checked: false,
        });
      }
    });
  },
  SET_GROUP_USERS: (state, groupUsers) => {
    const groupObj = state.companyGroups.filter(c => c.groupName === groupUsers.groupName);
    if (groupObj.length > 0) {
      groupObj[0].userList = groupUsers.userList;
      groupObj[0].loaded = true;
      groupObj[0].feedNames = groupUsers.feedNames;
    }
  },
  SET_ERROR_STATUS: (state, status) => {
    state.errorStatus = status;
  },
  SET_ERROR_MESSAGE: (state, exception) => {
    state.errorMessage = exception;
  },
  SET_COMPANY: (state, companyName) => {
    state.companyName = companyName;
  },
  SET_USERS_LIST: (state, companyUsers) => {
    state.companyUsers = companyUsers;
  },
};

export default mutations;

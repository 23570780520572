const config = {
  API_USER_DATA: 'api/users/$[USER_NAME]',
  API_COMPANY_USERS: '/api/companies/$[COMPANY_NAME]/users',
  API_COMPANY_GROUPS: '/api/companies/$[COMPANY_NAME]/groupent',
  API_GROUP_USERS: '/api/companies/$[COMPANY_NAME]/groupent/$[GROUP_NAME]',
};


export default function getAPIConfigs() {
  return config;
}

<template>
  <div>
    {{ widgetData }}
    <mds-row
      v-if="isOneCard && (!isTwoCards)"
      class="layout-grid__middle-cards"
      :align-horizontal="'center'"
    >
      <mds-col :cols="12">
        <slot name="firstCard" />
      </mds-col>
    </mds-row>
    <mds-row
      v-if="isTwoCards && !(isThreeCards)"
      :align-horizontal="'center'"
    >
      <mds-col
        :cols-at-s="12"
        :cols-at-m="12"
        :cols-at-l="2"
      >
        <slot
          name="firstCard"
        />
      </mds-col>
      <mds-col
        :cols-at-s="12"
        :cols-at-m="12"
        :cols-at-l="10"
      >
        <slot
          name="secondCard"
        />
      </mds-col>
    </mds-row>
    <mds-row
      v-if="isThreeCards && !(isFourCards)"
      class="layout-grid__big-card"
      :align-horizontal="'center'"
    >
      <mds-col
        :cols-at-s="6"
        :cols-at-m="6"
        :cols-at-l="4"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="firstCard" />
        </div>
      </mds-col>
      <mds-col
        :cols-at-s="12"
        :cols-at-m="12"
        :cols-at-l="8"
      >
        <div
          class="mds-module-container widget-container-dynamic layout-grid__container-widget"
        >
          <slot name="secondCard" />
          <slot name="thirdCard" />
        </div>
      </mds-col>
    </mds-row>
    <mds-row
      v-if="isFourCards"
      class="layout-grid__small-card-height"
      :align-horizontal="'center'"
    >
      <mds-col
        :cols-at-s="12"
        :cols-at-m="12"
        :cols-at-l="6"
      >
        <slot name="firstCard" />
        <slot name="secondCard" />
      </mds-col>
      <mds-col
        :cols-at-s="12"
        :cols-at-m="12"
        :cols-at-l="6"
      >
        <slot name="thirdCard" />
        <slot name="fourthCard" />
      </mds-col>
    </mds-row>
  </div>
</template>
<script>
import { MdsCol, MdsRow } from '@mds/layout-grid';


export default {
  name: 'MdsSmartRowWide',
  components: {
    MdsCol,
    MdsRow,
  },
  props: {
    widgetData: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    hasOne() {
      return (this.$slots.firstCard && this.$slots.firstCard.length);
    },
    hasTwo() {
      return (this.$slots.secondCard && this.$slots.secondCard.length);
    },
    hasThree() {
      return (this.$slots.thirdCard && this.$slots.thirdCard.length);
    },
    hasFour() {
      return (this.$slots.fourthCard && this.$slots.fourthCard.length);
    },
    isOneCard() {
      return (this.hasOne && !(this.hasTwo && this.hasThree && this.hasFour));
    },
    isTwoCards() {
      return (this.hasOne && this.hasTwo) && !(this.hasThree && this.hasFour);
    },
    isThreeCards() {
      return (this.hasOne && this.hasTwo && this.hasThree) && !(this.hasFour);
    },
    isFourCards() {
      return (this.hasOne && this.hasTwo && this.hasThree && this.hasFour);
    },
  },
};
</script>

import axios from 'axios';
import { getUserName } from '../../../utils/authService';
import templateString from '../../../utils/stringUtils';
import getAPIConfigs from './config.js';

const config = getAPIConfigs();
let replacement;

function getDelimittedTextArray(delimittedString, instruments) {
  if (delimittedString.length > 0) {
    delimittedString += '||';
  }
  delimittedString += instruments.map(item => `${item.name}:${item.curve}:${item.tenor}:${item.yellowkey}`).join('||');
  return delimittedString;
}

const actions = {
  getAccountList({ commit }) {
    return axios.get(templateString(config.USER_ACCOUNT_LIST_API)).then((response) => {
      commit('ADD_ACCOUNTLIST', response.data);
      commit('SET_PAGE_STATUS', 'SUCCESS');
      return response;
    }).catch((error) => {
      commit('SET_PAGE_STATUS', 'ERROR');
      throw error;
    });
  },
  getAccountDetails({ commit }, accountName) {
    replacement = {
      ACCOUNT_NAME: accountName,
    };
    return axios.get(templateString(config.USER_ACCOUNT_DETAILS_API, replacement)).then((response) => {
      commit('SET_PAGE_STATUS', 'SUCCESS');
      return response;
    }).catch((error) => {
      commit('SET_PAGE_STATUS', 'ERROR');
      throw error;
    });
  },
  addUserAccount({ commit }, payload) {
    const USER_NAME = getUserName();
    payload.user = USER_NAME;

    return axios.post(config.USER_ACCOUNT_API, payload).then((response) => {
      commit('SET_PAGE_STATUS', 'SUCCESS');
      return response.data[0];
    }).catch((error) => {
      commit('SET_ERROR_MESSAGE', error);
      commit('SET_PAGE_STATUS', 'ERROR');
      throw error;
    });
  },

  getAllConfigs({ commit }) {
    const USER_NAME = getUserName();
    replacement = {
      USER_NAME,
    };

    return axios.get(`${templateString(config.USER_CONFIG_LIST_API, replacement)}?all=true`).then((response) => {
      commit('SET_PAGE_STATUS', 'SUCCESS');
      return response.data;
    }).catch((error) => {
      commit('SET_PAGE_STATUS', 'ERROR');
      throw error;
    });
  },

  getUserConfigs({ commit }) {
    const USER_NAME = getUserName();
    replacement = {
      USER_NAME,
    };

    return axios.get(templateString(config.USER_CONFIG_LIST_API, replacement)).then((response) => {
      commit('ADD_CONFIGS', response.data);
      commit('SET_PAGE_STATUS', 'SUCCESS');
    }).catch((error) => {
      commit('SET_PAGE_STATUS', 'ERROR');
      throw error;
    });
  },

  createNewConfig({ commit }, bbConfig) {
    const USER_NAME = getUserName();
    bbConfig.user = USER_NAME;
    bbConfig.instrumentArray = getDelimittedTextArray('', bbConfig.instrumentsAdded);

    return axios.post(config.USER_CONFIG_API, bbConfig).then((response) => {
      commit('SET_PAGE_STATUS', 'SUCCESS');
      return response.data[0];
    }).catch((error) => {
      commit('SET_ERROR_MESSAGE', error.response.data.Message);
      commit('SET_PAGE_STATUS', 'ERROR');
      throw error;
    });
  },

  editConfig({ commit }, bbConfig) {
    replacement = {
      CONFIG_NAME: bbConfig.name,
    };
    const USER_NAME = getUserName();
    bbConfig.user = USER_NAME;
    bbConfig.instrumentArray = getDelimittedTextArray('', bbConfig.instrumentsAdded);

    return axios.put(templateString(config.CONFIG_DETAILS_API, replacement), bbConfig).then((response) => {
      commit('SET_PAGE_STATUS', 'SUCCESS');
      return response.data[0];
    }).catch((error) => {
      commit('SET_ERROR_MESSAGE', error.response.data.Message);
      commit('SET_PAGE_STATUS', 'ERROR');
      throw error;
    });
  },

  getConfigDetails({ commit }, configName) {
    replacement = {
      CONFIG_NAME: configName,
    };
    return axios.get(templateString(config.CONFIG_DETAILS_API, replacement))
      .then((response) => {
        commit('SET_CONFIG_DETAILS', response.data);
        commit('SET_PAGE_STATUS', 'SUCCESS');
        return response.data;
      })
      .catch((error) => {
        commit('SET_ERROR_MESSAGE', 'Unable to get config details. Please try again after sometime.');
        commit('SET_PAGE_STATUS', 'ERROR');
        throw error;
      });
  },

  getYellowKeyList({ commit }) {
    return axios.get(templateString(config.USER_YELLOWKEY_LIST_API)).then((response) => {
      commit('ADD_YELLOWKEYLIST', response.data);
      commit('SET_PAGE_STATUS', 'SUCCESS');
      return response;
    }).catch((error) => {
      commit('SET_PAGE_STATUS', 'ERROR');
      throw error;
    });
  },

  getYellowKeyDetails({ commit }, keyName) {
    replacement = {
      KEY_NAME: keyName,
    };
    return axios.get(templateString(config.USER_YELLOWKEY_DETAILS_API, replacement)).then(
      response => response.data,
    ).catch((error) => {
      throw error;
    });
  },
  addYellowKey({ commit }, payload) {
    const USER_NAME = getUserName();
    payload.user = USER_NAME;
    return axios.post(config.USER_YELLOW_KEY_API, payload).then(response => response.data[0]).catch((error) => {
      throw error;
    });
  },
  deleteYellowKey({ commit }, keyName) {
    replacement = {
      KEY_NAME: keyName,
    };
    return axios.delete(templateString(config.USER_YELLOWKEY_DETAILS_API, replacement)).then(
      response => response,
    ).catch((error) => {
      throw error;
    });
  },
  deleteAccount({ commit }, accountName) {
    replacement = {
      ACCOUNT_NAME: accountName,
    };
    return axios.delete(templateString(config.USER_ACCOUNT_DETAILS_API, replacement)).then(
      response => response,
    ).catch((error) => {
      throw error;
    });
  },

  getYellowKeyMappings({ commit }, mappingName) {
    replacement = {
      MAPPING_NAME: mappingName,
    };
    return axios.get(templateString(config.USER_YELLOWKEY_MAPPINGS_API, replacement))
      .then((response) => {
        commit('SET_YELLOWKEY_MAPPINGS', response.data);
        return response.data;
      })
      .catch((error) => {
        commit('SET_ERROR_MESSAGE', 'Unable to fetch yellow key mappings. Please try again after sometime.');
        commit('SET_PAGE_STATUS', 'ERROR');
        throw error;
      });
  },
};


export default actions;

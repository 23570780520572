<template>
  <mds-modal
    v-model="toggle"
    title="Private Feed Access"
    :width="'600px'"
    @mds-modal-dismissed="closePopup"
  >
    <mds-input
      v-model="privateFeedName"
      label="Feed Name"
      placeholder="Enter Private Feed Name"
      :error="error"
      :error-text="errorText"
      class="custom-margin-form"
    />
    <mds-button
      variation="primary"
      icon="save"
      :loading="loading"
      @click="readEntitlement"
    >
      {{ $t('labels.group.saveButton') }}
    </mds-button>
  </mds-modal>
</template>
<script>
import { mapActions } from 'vuex';
import MdsModal from '@mds/modal';
import { MdsButton } from '@mds/button';
import MdsInput from '@mds/input';
import EventBus from '../../main';
import { getUserName, getUserCompany } from '../../utils/authService';

export default {
  name: 'PrivateFeedAccess',
  components: {
    MdsModal,
    MdsButton,
    MdsInput,
  },
  props: {
    toggleValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      privateFeedName: '',
      error: false,
      errorText: [],
      loading: false,
      toggle: this.toggleValue,
      notification: true,
      delay: 6000,
    };
  },
  created() {
    this.init();
    EventBus.$on('toggleChange', (value) => {
      this.toggle = value;
    });
    EventBus.$on('initPrivateModel', () => this.init());
    EventBus.$on('hidePrivateFeedModal', (value) => {
      this.toggle = value;
      this.loading = value;
    });
  },
  methods: {
    ...mapActions('feedModule', [
      'getLicenseForFeed',
      'saveReadEntitlements',
      'postSaveReadEntitlements',
    ]),
    init() {
      this.error = false;
      this.errorText = [];
      this.privateFeedName = '';
    },
    readEntitlement() {
      if (!this.privateFeedName) {
        this.errorOperations('Feed name cannot be blank');
        return false;
      }
      if (!this.loading) {
        this.loading = true;
        let siteLincense = false;
        let entitled = true;
        this.getLicenseForFeed({ feedName: this.privateFeedName, company: getUserCompany() }).then(
          (response) => {
            if (response.data.errorMessage) {
              if (response.data.errorMessage.startsWith('Feed not found')) {
                this.errorOperations('Feed not found');
              } else {
                this.errorOperations('Internal Server Error!!');
              }
            } else {
              this.errorText = [];
              this.error = false;
              if (response.data) {
                const responseSize = response.data.length;
                if (responseSize <= 0) {
                  this.postSaveReadEntitlements({
                    historyType: 'Regular History',
                    feedName: this.privateFeedName,
                    numsubs: '1000',
                    users: [{ login: getUserName() }],
                  }).then((res) => {
                    if (res.status !== 200) {
                      this.errorOperations(`Error code ${ res.status }: ${ res.data }`);
                      return false;
                    }
                    EventBus.$emit('readAcessSuccess', true);
                    this.loading = false;
                    this.closePopup();
                    return true;
                  });
                } else if (responseSize === 1) {
                  const element = response.data[0];
                  const elementUsers = element.users;
                  const userName = getUserName();
                  const userList = elementUsers.map(user => user.login);
                  if (userList.join().toLowerCase().includes(userName.toLowerCase())) {
                    this.errorOperations('You already have license for this feed');
                    return false;
                  }
                  elementUsers.push({ login: userName });
                  if (element.numsubs === '-1') {
                    siteLincense = true;
                    EventBus.$emit('readAcessSuccess', false);
                    this.loading = false;
                    return false;
                  }
                  if (!siteLincense) {
                    this.saveReadEntitlements(element).then((res) => {
                      if (res.status !== 200) {
                        this.errorOperations(`Error code ${ res.status }: ${ res.data }`);
                        return false;
                      }
                      EventBus.$emit('readAcessSuccess', true);
                      siteLincense = false;
                      this.loading = false;
                      this.closePopup();
                      return true;
                    });
                  }
                } else {
                  response.data.forEach((item, index) => {
                    if (item.numsubs === '-1') {
                      siteLincense = true;
                      EventBus.$emit('readAcessSuccess', false);
                      entitled = false;
                      return false;
                    }
                    if (!siteLincense && entitled) {
                      siteLincense = false;
                      entitled = false;
                      item.users.push({ login: getUserName() });
                      this.saveReadEntitlements(item).then((res) => {
                        if (res.status !== 200) {
                          this.errorOperations(`Error code ${ res.status }: ${ res.data }`);
                          return false;
                        }
                        EventBus.$emit('readAcessSuccess', true);
                        this.closePopup();
                        this.loading = false;
                        return true;
                      });
                    }
                    return true;
                  });
                }
              }
            }
            return true;
          },
        );
      }
      return true;
    },
    closePopup() {
      EventBus.$emit('closePrivateFeedModal', false);
      EventBus.$emit('hidePrivateFeedModal', false);
    },
    errorOperations(message) {
      this.errorText = [];
      this.errorText.push(message);
      this.error = true;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.custom-margin-form {
  margin-bottom: 15px;
}
.margin-notification {
  top: 62px;
}
.custom-color {
  color: green;
}
</style>

const getters = {
  getConfigList: state => state.configList,
  configDetails: state => state.configDetails,
  yellowKeyList: state => state.yellowKeyList,
  accountList: state => state.accountList,
  getPageStatus: state => state.pageStatus,
  getErrorMessage: state => state.errorMessage,
  getYellowKeyMappings: state => state.getYellowKeyMappings,
};

export default getters;

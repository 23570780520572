/* eslint-disable no-use-before-define */

import Numbers, { requireInteger } from './numbers.es6';
import Dates, { requireDate } from './dates/dates.es6';
import Arrays from './arrays.es6';

/**
 * @param {DateRange} dr1
 * @param {DateRange} dr2
 * @returns {number}
 * @private
 */
function _compare(dr1, dr2) {
  let rv = Numbers.compare(dr1._low, dr2._low);
  if (rv === 0) {
    rv = Numbers.compare(dr1._high, dr2._high);
  }
  return rv;
}

/**
 * Immutable date ranges.
 *
 * Instances of this implementation may accept or return Date objects; changing them have
 * no effect on the actual instance.
 */
class DateRange {
  /**
     * @param {int} low
     * @param {int} high
     */
  constructor(low, high) {
    /** @type {int} */ this._low = requireInteger(low, 'low');
    /** @type {int} */ this._high = requireInteger(high, 'high');
    if (this._low > this._high) {
      throw new Error('`low` must be lower than or or equal to `high`');
    }
    Object.freeze(this);
  }

    /**
     * @type {DateRange} Singleton instance for unknown date ranges.
     */
    static UNKNOWN = new DateRange(0, 0);

    /**
     * Factory method to create DateRange using Date objects.
     * @param {Date} low
     * @param {Date} high
     * @returns {DateRange} New DateRange instance.
     */
    static fromDates(low, high) {
      return new DateRange(
        requireDate(low, 'low').getTime(),
        requireDate(high, 'high').getTime(),
      );
    }

    /**
     * Factory method to create DateRange using possibly null Date objects, for legacy purposes.
     * This method takes two valid Date objects or two null values; passing only one Date
     * causes an Error.
     * @param {?Date} low May be null.
     * @param {?Date} high May be null.
     * @returns {?DateRange} New DateRange instance, null if both `low` and `high` are null.
     * @throws {Error} If only one date is passed.
     */
    static fromDatesOrNulls(low, high) {
      if (low === null && high === null) {
        return null;
      } if (Dates.isDate(low) && Dates.isDate(high)) {
        return new DateRange(
          requireDate(low, 'low').getTime(),
          requireDate(high, 'high').getTime(),
        );
      }
      throw new Error('`low` and `high` must both be Date objects or both be null');
    }

    /** @returns {string} For debugging purposes. */
    toString() {
      return `{low: ${ Dates.utcDateToString(this.lowDate(), 6)
      }, high: ${ Dates.utcDateToString(this.highDate(), 6) }}`;
    }

    /**
     * @param {(DateRange|*)} that
     * @returns {boolean} Whether `that` represents the exact same date range.
     */
    equals(that) {
      return (
        isDateRange(that)
            && this._low === that._low
            && this._high === that._high
      );
    }

    /**
     * @param {DateRange} that
     * @returns {number} Numeric value for the purpose of sorting consistently.
     */
    compareTo(that) {
      return _compare(this, requireDateRange(that, 'that'));
    }

    /**
     * Compares two DateRange objects for the purpose of sorting consistently.
     * @param {DateRange} dr1
     * @param {DateRange} dr2
     * @returns {number} Numeric value for the purpose of sorting consistently.
     */
    static compare(dr1, dr2) {
      return _compare(
        requireDateRange(dr1, 'dr1'),
        requireDateRange(dr2, 'dr2'),
      );
    }

    /** @returns {int} Low instant, as Epoch value. */
    lowInstant() {
      return this._low;
    }

    /** @returns {int} High instant, as Epoch value. */
    highInstant() {
      return this._high;
    }

    /** @returns {Date} Low date, newly created each time this method is called. */
    lowDate() {
      return new Date(this._low);
    }

    /** @returns {Date} High date, newly created each time this method is called. */
    highDate() {
      return new Date(this._high);
    }

    /** @returns {int} Epoch value in the middle this date range, rounded up to millisecond. */
    middleInstant() {
      return Math.ceil((this._low + this._high) / 2);
    }

    /** @returns {Date} Date in the middle of this range, may contain fraction of a day. */
    middleDate() {
      return new Date(this.middleInstant());
    }

    /** @returns {int} Size of the range, in milliseconds. */
    sizeMillis() {
      return this._high - this._low;
    }

    /**
     * @param {DateRange} range
     * @returns {boolean} Whether `range` is fully contained by this range, inclusively.
     */
    containsRange(range) {
      requireDateRange(range, 'range');
      return (range._low >= this._low && range._high <= this._high);
    }

    /**
     * @param {Date} date
     * @returns {boolean} Whether `date` is contained by this range, inclusively.
     * @throws {TypeError} If `date` is not a Date object.
     */
    containsDate(date) {
      return this._containsInstantUnchecked(requireDate(date, 'date').getTime());
    }

    /**
     *
     * @param {int} instant Instant, as Epoch value.
     * @returns {boolean} Whether `instant` is contained by this range, inclusively.
     * @throws {TypeError} If `instant` is not an integer.
     */
    containsInstant(instant) {
      return this._containsInstantUnchecked(requireInteger(instant, 'instant'));
    }

    /**
     * @param {int} instant
     * @returns {boolean} Whether `instant` is contained by this range, inclusively.
     * @private
     */
    _containsInstantUnchecked(instant) {
      return (instant >= this._low && instant <= this._high);
    }

    /**
     * @param {DateRange} range
     * @returns {boolean} Whether any portion of `range` overlaps with this date range.
     */
    hasOverlap(range) {
      requireDateRange(range, 'range');
      return (
        (range._low >= this._low && range._low <= this._high)
            || (range._high >= this._low && range._high <= this._high)
            || (this._low >= range._low && this._low <= range._high)
            || (this._high >= range._low && this._high <= range._high)
      );
    }

    /**
     * @param {DateRange} range
     * @returns {DateRange} This date range with its boundaries set no further than `range`.
     */
    limitTo(range) {
      requireDateRange(range, 'range');
      if (range.containsRange(this)) {
        return this;
      } if (!range.hasOverlap(this)) {
        throw new Error('This DateRange has no overlap with `range`');
      } else {
        return new DateRange(
          Math.max(this._low, range._low),
          Math.min(this._high, range._high),
        );
      }
    }

    /**
     * @param {DateRange} range
     * @returns {DateRange} This date range with its boundaries expanded to `range` if applicable.
     */
    expand(range) {
      requireDateRange(range, 'range');
      return new DateRange(
        Math.min(this._low, range._low),
        Math.max(this._high, range._high),
      );
    }

    /**
     * @param {DateRange[]} ranges List of DateRange objects.
     * @returns {DateRange} DateRange that represents the lowest and highest dates found in `ranges`.
     * @throws {TypeError} If `ranges` is not an array or contains non-DateRange objects.
     * @throws {Error} If `ranges` is empty.
     */
    static expanded(ranges) {
      Arrays.requireNonEmpty(ranges, 'ranges');
      Arrays.requireArrayOf(ranges, DateRange, 'ranges', 'DateRange');
      return new DateRange(
        Math.min(...ranges.map(r => r._low)),
        Math.max(...ranges.map(r => r._high)),
      );
    }

    static isDateRange = isDateRange;

    static requireDateRange = requireDateRange;
}

/**
 * @param {(DateRange|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is a DateRange instance.
 */
export function isDateRange(arg) {
  return (arg instanceof DateRange);
}

/**
 * Validates `arg` to be an instance of DateRange.
 * @param {DateRange} arg Argument to validate.
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {DateRange} Always returns `arg`.
 * @throws {TypeError} If `arg` is not a DateRange instance.
 */
export function requireDateRange(arg, argName) {
  if (!isDateRange(arg)) {
    throw new TypeError(`${argName }: DateRange`);
  }
  return arg;
}

export default DateRange;

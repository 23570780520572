<template>
  <div>
    <mcd-card-section
      :key="$root.$i18n.locale"
      item-type="dataManagement"
    />
  </div>
</template>
<script>
import McdCardSection from '../components/McdCard/McdCardSection';

export default {
  name: 'DataManagement',
  components: { McdCardSection },
};
</script>

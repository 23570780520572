/* eslint-disable no-use-before-define */

import _ from 'underscore';
import Enums, {
  EnumBase as Enum,
  EnumItem,
} from './enums.es6';
import Dates, { requireDate } from './dates/dates.es6';
import Arrays from './arrays.es6';
import Objects, { isVoid, requireObject } from './objects.es6';
import Numbers, { requireInteger, isInteger, requireNonNegativeInteger } from './numbers.es6';
import Strings, {
  isString, requireString, requireNonEmptyString, isNonEmptyString,
} from './strings.es6';
import HistoryJobStatus from './histjobstatus.es6';
import Task from './task.es6';
import UserActionTask from './user_action_task.es6';
import UserAction from './user_action.es6';
import { getUserName } from '../../../../utils/authService';

/* ************************************************
 * Local definitions
 * ************************************************ */

/**
 * @typedef {Object} RunError
 * @property {string} type - "formula" or "reactor"
 * @property {string} date - ISO-8601 format (yyyy-MM-ddTHH:mm:ss.SSSZ)
 * @property {(RunError.Formula|RunError.Reactor)} data - Properties depend on `type`, but both known
 *                             types provide a property for `err_msg` (string).
 */

/**
 * @typedef {Object} RunError.Formula
 * @property {string} uuid - Formula UUID.
 * @property {string} script_name - Name of the JavaScript script file.
 * @property {int} script_line - Line number at which `script_name` failed.
 * @property {string} err_type - High-level exception class name (wrapper).
 * @property {string} err_cause - Low-level exception class name (the cause).
 * @property {string} err_msg - Error message
 */

/**
 * @typedef {Object} RunError.Reactor
 * @property {string} err_msg - Error message
 */

/**
 * Validator returns whether the given string can be successfully converted
 * to a value compatible with the specific data-type.
 *
 * @callback VarType.Validator
 * @param {string} value - String value to validate against a data-type.
 * @returns {boolean}
 */

/* ************************************************
 * Private variables
 * ************************************************ */
const REGEX_TIME = new RegExp('^(?:[01]?[0-9]|2[0-3]):(?:[0-5][0-9])(?::(?:[0-5][0-9]))?$');

/**
 * Workflow parameter place-holder, such as "{{udef.ds.input.feed}}"
 * @type {RegExp}
 */
const REGEX_PARAM_PLACEHOLDER = new RegExp('{{[^\\s]+}}');

/**
 * Made up IDs for local Config objects (not posted to the server.)
 * @type {int}
 * @private
 */
let _localConfigSeq = 0;

let _canConstructDependency = true;

/**
 * Indicates whether a Workflow Report instance is being constructed by an
 * external program.  Report instances constructed internally may have relaxed
 * validation.
 * @type {boolean}
 * @private
 */
let _isExternalReport = true;

/* ************************************************
 * Private methods
 * ************************************************ */

/** @returns {boolean} Whether `s` is a string, maybe empty. */
function _isString(s) {
  return (typeof s === 'string');
}

/**
 * Returns whether an array is empty.
 * @returns {boolean} True if <code>arr</code> is empty; false otherwise.
 */
function _isEmptyArray(arr) {
  return (arr.length === 0);
}

function _optionalString(str, defaultValue) {
  if (typeof str === 'string') return str;
  if (arguments.length > 1) return defaultValue;
  return '';
}

function _validProp(obj, name, dataType, dataTypeDescr) {
  if (!Object.hasOwnProperty.call(obj, name)) { throw new Error(`NullPointerException: ${ name}`); }

  const val = obj[name];
  if (typeof dataType === 'string') {
    if (typeof val !== dataType) { throw new TypeError(`${name }: ${ dataType}`); }
  } else if (typeof dataType === 'function') {
    if (!dataType(val)) { throw new TypeError(`${name }: ${ dataTypeDescr}`); }
  } else { throw new Error(`IllegalArgumentException: dataType [${ typeof dataType }]`); }

  return val;
}

function _validObjPC(obj, name) {
  return Object.freeze(Objects.clone(requireObject(obj, name)));
}

/**
 * Validates the `arr` variable to make sure it's an
 * array of `ctor`.  If valid, this function returns
 * a protective copy of the `arr`.
 */
function _validArrayPC(arr, ctor, name, ctorName, isEmptyOk) {
  if (!Arrays.isArrayOf(arr, ctor)) { throw new TypeError(`${name }: Array-of-${ ctorName}`); }

  if (isEmptyOk === false
        && arr.length === 0) { throw new Error(`IllegalArgumentException: ${ name } is empty`); }

  return Arrays.slice(arr, 0);
}

/**
 * Validates that `arg` is a valid JSON string or workflow-parameter placeholder.
 * If valid, this method returns that argument (`arg`, as is).
 * Otherwise, it throws.
 * @param {string} arg
 * @param {string} argName
 * @param {function} ctor
 * @returns {string}
 * @private
 */
function _validJsonOrPH(arg, argName, ctor) {
  requireNonEmptyString(arg, argName);
  if (REGEX_PARAM_PLACEHOLDER.test(arg)) {
    return arg;
  }
  try {
    const o = JSON.parse(arg);
    if (isVoid(o)) {
      throw new Error(`IllegalArgumentException: ${ argName } resolved to null or undefined.`);
    }
    if (o.constructor !== ctor) {
      throw new Error(`IllegalArgumentException: ${ argName } is of the wrong type ("${ arg }")`);
    }
    return arg;
  } catch (ex) {
    throw new Error(`IllegalArgumentException: ${ argName } is not valid JSON ("${ arg }")`);
  }
}

/**
 * Returns a new comparator function that sorts object pairs based on the value returned
 * by their `name()` method, case-insensitive.  Ties are broken using their `id()` method.
 * The comparator function will push objects that don't match the given constructor at the
 * end of the sort order (i.e. nulls are high).
 *
 * @param {Function} ctor - Type of object to be sorted.
 * @returns {Function} New comparator function.
 * @private
 */
function _newNameComparator(ctor) {
  /**
     * @param {{name: function, id: function}} o1
     * @param {{name: function, id: function}} o2
     * @returns {number}
     */
  return (o1, o2) => {
    const is1 = (o1 instanceof ctor);
    const is2 = (o2 instanceof ctor);

    if (is1) {
      if (is2) {
        let rv = Strings.compareIgnoreCase(o1.name(), o2.name());
        if (rv === 0) rv = Numbers.compare(o1.id(), o2.id());
        return rv;
      }
      return -1;
    }
    if (is2) return 1;
    return 0;
  };
}

/**
 * Gets or sets the string property of an object/instance.
 */
function _getsetString(obj, propName, argName, args, isEmptyOk) {
  if (args.length < 1) {
    return obj[propName];
  } if (arguments.length > 4 && isEmptyOk === true) {
    obj[propName] = requireString(args[0], argName);
  } else {
    obj[propName] = requireNonEmptyString(args[0], argName);
  }
  return obj;
}

/* **************************************************************
 * Enum: VarType
 * ************************************************************** */

let _canConstructVarType = true;

/**
 * Variable data-types.
 *
 * @param {string} name - Name of data-type.
 * @param {string} jsEngineGetter - Name of getter method in LDS JS engine.
 * @param {VarType.Validator} varValidator - Function that validates
 *        string values for this data-type.
 *
 * @constructor
 * @alias WorkflowVarType
 * @private
 */
export function VarType(name, jsEngineGetter, varValidator) {
  if (!_canConstructVarType) { throw new Error('Private constructor, access denied'); }

  this._name = name;
  this._jsEng = jsEngineGetter;

  /** @type {VarType.Validator} */
  this.isValidValue = varValidator;

  Object.freeze(this);
}

VarType.prototype = /** @lends {VarType.prototype} */ {
  constructor: VarType,

  /** @returns {string} String representation of this data-type (aka its name). */
  toString() {
    return this._name;
  },

  /** @returns {string} Value of this data type (aka its name). */
  valueOf() {
    return this._name;
  },

  /**
     * @returns {string} Name of the getter method within LDS JavaScript engine.
     */
  jsEngineMethod() {
    return this._jsEng;
  },
};

Object.assign(VarType, /** @lends {VarType} */ {

  STRING: new VarType('STRING', 'Parameters.getString',

    /**
         * @param {string} value
         * @returns {boolean}
         */
    ((value) => {
      requireString(value, 'value');
      return true;
    })),

  FLOAT: new VarType('FLOAT', 'Parameters.getFloat',

    /**
         * @param {string} value
         * @returns {boolean}
         */
    (value => Numbers.isFloatString(requireString(value, 'value')))),

  DATE: new VarType('DATE', 'Parameters.getDate',

    /**
         * @param {string} value
         * @returns {boolean}
         */
    (value => Dates.isIsoDate(requireString(value, 'value')))),

  BOOLEAN: new VarType('BOOLEAN', 'Parameters.getBool',

    /**
         * @param {string} value
         * @returns {boolean}
         */
    ((value) => {
      requireString(value, 'value');
      return (value === 'true'
                    || value === 'false');
    })),

  JSON: new VarType('JSON', 'Parameters.getJson',

    /**
         * @param {string} value
         * @returns {boolean}
         */
    ((value) => {
      requireString(value, 'value');
      try {
        JSON.parse(value);
        return true;
      } catch (e) {
        return false;
      }
    })),
});

_canConstructVarType = false; // Lock down the constructor.

Enums.finalize(VarType, 'VarType');

Object.freeze(VarType);
Object.freeze(VarType.prototype);


/* ************************************************
 * Class ParameterSet
 * ************************************************ */

/**
 * Returns whether all parameter names exist in `paramset`.
 *
 * @param paramset {ParameterSet}
 * @param paramNames {string[]}
 * @returns {boolean}
 * @private
 */
function _containsAllParams(paramset, paramNames) {
  /* We don't use `_.every()` here to avoid excessive closure,
     * excessive function creation. We get better performance this way. */

  let hasAll = true;

  for (let i = 0, len = paramNames.length;
    i < len && hasAll === true;
    i++) {
    if (!paramset.contains(paramNames[i])) { hasAll = false; }
  }

  return hasAll;
}

/**
 * Validates the given parameter-set ID, and returns it
 * if valid.
 * @param {int} id
 * @param {int} min
 * @private
 */
function _validParamsetId(id, min) {
  if (!isInteger(id)
        || id < min) { throw new TypeError(`id: Integer, ${ min } or above`); }

  return id;
}


/* ***************************************************
 * Class: ParameterSet
 * *************************************************** */

/**
 * A set of parameters, associated with a name and (optional) description.
 */
export class ParameterSet {
  /**
     * @param {int} id - Use -1 if parameter-set has not been persisted yet.
     * @param {?string} uuid - Use `null` if parameter-set has not been persisted yet.
     * @param {string} name
     * @param {Object.<string, string>} params
     * @param {string} [description=""]
     */
  constructor(id, uuid, name, params, description, skipValidation) {
    if (!skipValidation) {
      _validParamsetId(id, -1);
      if (uuid !== null) {
        requireNonEmptyString(uuid, 'uuid');
      }
      requireNonEmptyString(name, 'name');
      requireObject(params, 'params');
    }

    if (arguments.length < 5) {
      description = '';
    } else if (!skipValidation) {
      requireString(description, 'description');
    }

    const clone = {};
    for (const paramName in params) {
      if (params.hasOwnProperty(paramName)) {
        const paramVal = params[paramName];
        if (!isString(paramVal)) {
          throw new TypeError(`params[${ paramName }]: String`);
        }
        clone[paramName] = paramVal;
      }
    }

    this._id = id;
    this._uuid = uuid;
    this._name = name;
    this._descr = description;
    this._params = Object.freeze(clone);

    Object.freeze(this);
  }

  /** @returns {int} ID given to the parameter-set. */
  id() { return this._id; }

  /**
     * Returns whether this parameter-set has been saved (aka persisted)
     * at least once.  This method returns *true* if the
     * parameter-set ID one recognized by the server.
     * @returns {boolean}
     */
  isPersisted() { return (this._id >= 0); }

  /**
     * @returns {?string} Parameter-set UUID which persists throughout workflow updates,
     *          `null` if parameter-set was never persisted.
     */
  uuid() { return this._uuid; }

  /** @returns {string} Name given to this parameter-set. */
  name() { return this._name; }

  /** @returns {string} Description associated with this parameter-set. */
  description() { return this._descr; }

  /** @returns {Object.<string, string>} Parameters within this set, as a Object instance. */
  parameters() { return this._params; }

  /** @returns {string[]} List of parameter names, sorted alphabetically. */
  parameterNames() {
    return Objects.properties(this._params, true);
  }

  /**
     * Returns whether the given parameter name exist in this set.
     * @param {string} paramName
     * @returns {boolean}
     */
  contains(paramName) {
    return this._params.hasOwnProperty(requireNonEmptyString(paramName, 'paramName'));
  }

  /**
     * @param {string} paramName
     * @param {?string} [defaultValue] The default value to return if `paramName` does not exist.
     * @returns {?string} Parameter value associated with `paramName`, or `defaultValue`.
     * @throws IllegalArgumentException - If `paramName` does not exist and `defaultValue` is not provided.
     */
  get(paramName, defaultValue) {
    if (this.contains(paramName)) {
      return this._params[paramName];
    } if (arguments.length > 1) {
      return defaultValue;
    }
    throw new Error(`IllegalArgumentException: \`paramName\` not found (${ paramName })`);
  }

  /**
     * Returns whether all given parameter names exist in this set.
     * @param paramNames {string[]}
     * @returns {boolean}
     */
  containsAll(paramNames) {
    if (!Arrays.isArrayOf(paramNames, 'string')) { throw new TypeError('paramNames: Array-of-String'); }

    return _containsAllParams(this, paramNames);
  }

  /**
     * @param {?ParameterSet} that
     * @returns {boolean} Whether `that` represents the same parameter-set as this instance.
     */
  equals(that) {
    return ((that instanceof ParameterSet)
                && this._id === that._id);
  }

    /**
     * Compares two parameter-set objects for the purpose of sorting them by name
     * (case-insensitive).
     * @param {ParameterSet} o1
     * @param {ParameterSet} o2
     * @returns {number}
     * @method
     */
    static compare = _newNameComparator(ParameterSet);

    /**
     * @param {ParameterSet} ps1
     * @param {ParameterSet} ps2
     * @returns {boolean} Whether `ps1` and `ps2` are two instances of ParameterSet
     *          that represent the same parameter-set.
     */
    static areEqual(ps1, ps2) {
      return ((ps1 instanceof ParameterSet)
                && ps1.equals(ps2));
    }
}


/* ************************************************
 * Class ParameterSetGroup
 * ************************************************ */

let _psgNone = null;

/**
 * A group of parameter-sets.
 */
export class ParameterSetGroup {
  /**
     * @param {int} id - The ID for this group.  Pass -1 if this group
     *           has not been saved yet.
     * @param {string} name
     * @param {ParameterSet[]} paramsets
     */
  constructor(id, name, paramsets) {
    requireInteger(id, 'id');
    if (id < -1) {
      throw new Error('IllegalArgumentException: id cannot be less than -1.');
    }

    this._id = id;
    this._name = requireNonEmptyString(name, 'name');

    const ps = _validArrayPC(paramsets,
      ParameterSet,
      'paramsets',
      'ParameterSet',
      true);

    this._paramsets = Object.freeze(ps);

    const psPersisted = _.filter(ps, paramset => paramset.isPersisted());

    this._paramsetById = Object.freeze(_.indexBy(psPersisted, paramset => paramset.id()));

    this._paramsetByUuid = Object.freeze(_.indexBy(psPersisted, paramset => paramset.uuid()));

    // Assert that (persisted) parameter-set IDs are unique within a group.
    if (_.keys(this._paramsetById).length !== psPersisted.length) { throw new Error('Duplicate parameter-set ID found in parameter-set-group.'); }

    // We can't assert the same about UUIDs, because `null` UUIDs are allowed.
    // Assert that (persisted) parameter-set UUIDs are unique within a group.
    // if (_.keys(this._paramsetByUuid).length !== psPersisted.length)
    //     throw new Error("Duplicate parameter-set UUID found in parameter-set-group.");

    Object.freeze(this);
  }

  /** @returns {int} ID of this ParameterSetGroup, -1 if never persisted. */
  id() { return this._id; }

  /** @returns {boolean} Whether this ParameterSetGroup was persisted on remote server. */
  isPersisted() {
    return (this._id >= 0);
  }

  /**
     * Returns the name of this parameter-set group.
     * @returns {*}
     */
  name() { return this._name; }

  /**
     * Returns the number of parameter-sets are contained
     * within this group.
     * @returns {int}
     */
  size() { return this._paramsets.length; }

  /**
     * Return the parameter-sets within this group.
     * The returned list is a copy, caller can modify at will.
     * @returns {ParameterSet[]}
     */
  parameterSets() { return this._paramsets.slice(0); }

  /**
     * @param {int} id
     * @returns {boolean} Whether this parameter-set-group contains the given parameter-set ID.
     */
  contains(id) {
    return this._paramsetById.hasOwnProperty(_validParamsetId(id, 0));
  }

  /**
     * @param {string} uuid
     * @returns {boolean} Whether this parameter-set-group contains the given
     *          parameter-set UUID.
     */
  containsUuid(uuid) {
    return this._paramsetByUuid.hasOwnProperty(requireNonEmptyString(uuid, 'uuid'));
  }

  /**
     * Returns the parameter-set associated with the given ID.
     * @param {(int|string)} id - ID or UUID.
     * @param [defaultValue] {Object} The value to return if parameter-set
     *                       ID (or UUID) doesn't exist in this parameter-set-group.
     * @returns {ParameterSet}
     * @throws IllegalArgumentException - If `id` doesn't exist and `defaultValue`
     *                                    is not provided.
     */
  parameterSet(id, defaultValue) {
    if (isInteger(id)) {
      if (this.contains(id)) return this._paramsetById[id];
    } else if (isString(id)) {
      if (this.containsUuid(id)) {
        return this._paramsetByUuid[id];
      }
    } else {
      throw new TypeError('id: Integer or String');
    }

    if (arguments.length > 1) {
      return defaultValue;
    }
    throw new Error(`IllegalArgumentException: id [${id}] not found.`);
  }

  /**
     * Returns a list of invalid parameter sets, based on required parameter names.
     * @param paramNames {string[]}
     * @returns {ParameterSet[]}
     */
  invalidSets(paramNames) {
    if (!Arrays.isArrayOf(paramNames, 'string')) { throw new TypeError('paramNames: Array-of-String'); }

    /* `_.reject()` returns all parameter-sets for which
         * the call to `_containsAllParams()` returns false. */
    return _.reject(this._paramsets, paramset => _containsAllParams(paramset, paramNames));
  }

  /**
     * Returns a list of parameter names, sorted alphabetically.
     * @param [isIntersection=false] {boolean} Whether the returned list
     *           is a superset (false) or a subset of names available
     *           in all parameter-sets (true).
     * @returns {string[]}
     */
  parameterNames(isIntersection) {
    if (arguments.length < 1) { isIntersection = false; } else if (typeof isIntersection !== 'boolean') { throw new TypeError('isIntersection: Boolean'); }

    const nameCnt = {};

    _.each(this._paramsets, (paramset) => {
      const names = paramset.parameterNames();
      for (let i = 0, len = names.length; i < len; i++) {
        const name = names[i];
        if (!nameCnt.hasOwnProperty(name)) nameCnt[name] = 0;

        nameCnt[name]++;
      }
    });

    let nameList;
    if (!isIntersection) { nameList = Objects.properties(nameCnt, true); } else {
      nameList = [];
      const matchCnt = this._paramsets.length;

      for (const name in nameCnt) {
        if (nameCnt.hasOwnProperty(name)
                    && nameCnt[name] === matchCnt) { nameList.push(name); }
      }

      nameList.sort(Strings.compare);
    }

    return nameList;
  }

  /**
     * Returns a (lazy-initialized) singleton ParameterSetGroup
     * instance that represent "no parameter-set-group".
     */
  static none() {
    if (_psgNone === null) {
      _psgNone = new ParameterSetGroup(-1, 'N/A', []);
    }
    return _psgNone;
  }

  /**
     * @param {string} workflowName Name of workflow owner of ParameterSetGroup (to be created).
     * @returns {string} New ParameterSetGroup name.
     */
  static newNameFromWorkflowName(workflowName) {
    requireNonEmptyString(workflowName, 'workflowName');
    const now = new Date();
    const df = Dates.getFormatter('yyyy-MM-ddTHH:mm:ss', false);
    // Keep it under 50 bytes
    return `${workflowName.substring(0, 25) }_${ df(now)}`;
  }
}

Object.freeze(ParameterSetGroup);
Object.freeze(ParameterSetGroup.prototype);

/**
 * @param {(ParameterSetGroup|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is a ParameterSetGroup instance.
 */
export function isParameterSetGroup(arg) {
  return (arg instanceof ParameterSetGroup);
}

/**
 * Validates the given argument to be a ParameterSetGroup instance, throws if invalid.
 * @param {ParameterSetGroup} arg Argument to validate.
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {ParameterSetGroup} Always returns `arg`.
 * @throws {TypeError} If `arg` is not a ParameterSetGroup instance.
 */
export function requireParameterSetGroup(arg, argName) {
  if (!isParameterSetGroup(arg)) {
    throw new TypeError(`${argName }: ParameterSetGroup`);
  }
  return arg;
}

/* ************************************************
 * Class ScheduledJob
 * ************************************************ */

/**
 * A scheduled job.
 *
 * @constructor
 * @name ScheduledJob
 *
 * @param {string} jobId
 * @param {string} cronExpression
 * @param {string} [lastTime]
 * @param {string} [nextTime]
 * @param {Object.<string, (string|int|boolean)>} props
 */
export function ScheduledJob(jobId, cronExpression, lastTime, nextTime, props) {
  this._jobId = requireNonEmptyString(jobId, 'jobId');
  this._cron = requireNonEmptyString(cronExpression, 'cronExpression');
  this._lastRun = _optionalString(lastTime, '');
  this._nextRun = _optionalString(nextTime, '');

  if (!Objects.isAllPrimitives(props)) { throw new TypeError('props: Object, primitive values only'); }

  this._props = Object.freeze(Objects.clone(props));

  Object.freeze(this);
}

ScheduledJob.prototype = /** @lends ScheduledJob.prototype */ {
  constructor: ScheduledJob,

  /**
     * Returns the job ID in the scheduler table.
     * @returns {string}
     */
  jobId() { return this._jobId; },

  /**
     * Returns the cron expression associated with this schedule.
     * @returns {string}
     */
  cronExpression() { return this._cron; },

  /**
     * Returns the last time this job has run.
     * (Date format is driven at the server.)
     * @returns {string}
     */
  lastRun() { return this._lastRun; },

  /**
     * Returns the next time this job is scheduled to run.
     * (Date format is driven at the server.)
     * @returns {string}
     */
  nextRun() { return this._nextRun; },

  /**
     * Returns the properties associated with this scheduled job.
     * @returns {Object} An immutable object containing the job properties.
     */
  properties() { return this._props; },

};

Object.freeze(ScheduledJob);
Object.freeze(ScheduledJob.prototype);

/* ************************************************
 * Enum Permission
 * ************************************************ */

/**
 * A permission, given to a user (or company) against a workflow: VIEW, EDIT, APPROVAL, START_STOP.
 *
 * @enum {EnumItem}
 * @readonly
 */
export const Permission = Enum.finalize({
  VIEW: new EnumItem('VIEW', 'view'),
  EDIT: new EnumItem('EDIT', 'edit'),
  APPROVAL: new EnumItem('APPROVAL', 'approval'),
  START_STOP: new EnumItem('START_STOP', 'start_stop'),
  FORMULA_EDIT: new EnumItem('FORMULA_EDIT', 'formula_edit'),
  INPUT_EDIT: new EnumItem('INPUT_EDIT', 'input_edit'),
}, 'Permission');

/* ************************************************
 * Class PermissionSet
 * ************************************************ */

/**
 * Represents a set of permissions given to a user (or company)
 * against a workflow.
 * @param perms {EnumItem[]}
 * @constructor
 * @name PermissionSet
 */
export function PermissionSet(perms) {
  // Can't use Arrays because we construct NONE during initialization.
  if (!(perms instanceof Array)) { throw new TypeError('perms: Array'); }

  const idx = {};

  for (let i = 0, len = perms.length; i < len; i++) {
    const perm = Permission.valueOf(perms[i]);
    idx[perm.valueOf()] = perm;
  }

  /** @type {Object.<string, EnumItem>} */
  this._idx = Object.freeze(idx);
  Object.freeze(this);
}

PermissionSet.prototype = /** @lends PermissionSet.prototype */ {
  constructor: PermissionSet,

  /**
     * Returns whether this set of permissions contains <code>perm</code>.
     * @param perm {(EnumItem|string|int)}
     * @returns {boolean}
     */
  contains(perm) {
    const p = Permission.valueOf(perm); // Validates the argument
    const v = p.valueOf();
    const i = this._idx;

    return (i.hasOwnProperty(v)
                && i[v] === p);
  },

  /**
     * Returns the set of permission as a list, in no particular order.
     * @returns {EnumItem[]}
     */
  list() {
    return _.values(this._idx);
  },

  /**
     * Returns the set of permissions as a list of string values,
     * in no particular order.
     * @returns {string[]}
     */
  listValues() {
    return _.map(this.list(), p => p.valueOf());
  },

  /**
     * Returns whether this instance represents an empty set.
     * @returns {boolean}
     */
  isEmpty() {
    return !Objects.hasProperties(this._idx);
  },
};

Object.assign(PermissionSet, /** @lends PermissionSet */ {
  /**
     * A static, singleton instance for "no permission".
     * @type {PermissionSet}
     */
  NONE: new PermissionSet([]),

  /**
     * A static, singleton instance for "all permissions".
     * @type {PermissionSet}
     */
  ALL: new PermissionSet(Permission.list()),
});

Object.freeze(PermissionSet);
Object.freeze(PermissionSet.prototype);


/* ************************************************
 * Class UserPermissions
 * ************************************************ */

/**
 * @param username {string}
 * @param permset {PermissionSet}
 * @constructor
 * @name UserPermissions
 */
export function UserPermissions(username, permset) {
  requireNonEmptyString(username, 'username');

  if (!(permset instanceof PermissionSet)) { throw new TypeError('permset: PermissionSet'); }

  this._un = username;
  this._ps = permset;

  Object.freeze(this);
}

UserPermissions.prototype = /** @lends UserPermissions.prototype */ {
  constructor: UserPermissions,

  /**
     * Returns the username (a.k.a. login ID).
     * @returns {string}
     */
  username() { return this._un; },

  /**
     * Returns the permission-set associated with this user.
     * @returns {PermissionSet}
     */
  permissionSet() { return this._ps; },
};

Object.assign(UserPermissions, /** @lends UserPermissions */ {
  /**
     * Comparative method, used to sort instances by username.
     * This method pushes items that aren't instances of
     * UserPermissions at the end of the array.
     *
     * @param up1 {UserPermissions}
     * @param up2 {UserPermissions}
     */
  compareUsername(up1, up2) {
    const is1 = (up1 instanceof UserPermissions);
    const is2 = (up2 instanceof UserPermissions);

    if (is1 && is2) return Strings.compareIgnoreCase(up1._un, up2._un);
    if (is1 && !is2) return -1;
    if (!is1 && is2) return 1;
    return 0;
  },
});

Object.freeze(UserPermissions);
Object.freeze(UserPermissions.prototype);

/* ************************************************
 * Class Permissions
 * ************************************************ */

/**
 * @param companyPermSet {PermissionSet}
 * @param users {UserPermission[]}
 * @constructor
 * @name Permissions
 */
export function Permissions(companyPermSet, users) {
  if (!(companyPermSet instanceof PermissionSet)) { throw new TypeError('companyPermSet: PermissionSet'); }

  if (!Arrays.isArrayOf(users, UserPermissions)) { throw new TypeError('users: Array-of-UserPermissions'); }

  const u = users.slice(0);
  u.sort(UserPermissions.compareUsername);

  this._co = companyPermSet;
  this._users = Object.freeze(u);

  Object.freeze(this);
}

Permissions.prototype = /** @lends Permissions.prototype */ {
  constructor: Permissions,

  /**
     * Returns the PermissionSet given to the entire company;
     * the same company to which the workflow owner belongs to.
     * @returns {PermissionSet}
     */
  companySet() { return this._co; },

  /**
     * Returns a (read-only) array of UserPermissions,
     * sorted by username.
     * @returns {UserPermissions[]}
     */
  users() { return this._users; },
};

Object.freeze(Permissions);
Object.freeze(Permissions.prototype);


/* ************************************************
 * Enum Timeout.Type
 * ************************************************ */

/**
 * A type of timeout: TIME_OF_DAY, DURATION.
 *
 * @enum {EnumItem}
 * @readonly
 */
export const TimeoutType = Enum.finalize(/** @lends {TimeoutType} */ {
  TIME_OF_DAY: new EnumItem('TIME_OF_DAY', 'time_of_day'),
  DURATION: new EnumItem('DURATION', 'duration'),
}, 'Timeout.Type');


/**
 * @class
 */
export class Timeout {
  /**
     * <ol>
     *  <li> type (string)
     *   <ol>
     *    <li> time_of_day <br />
     *         params -> time: "HH:mm" </li>
     *    <li> duration
     *         params -> ??? </li>
     *   </ol>
     *  </li>
     *  <li> params (depends on `type`) </li>
     *  <li> tasks (@see Task) </li>
     * </ol>
     * @param {EnumItem} type {@link TimeoutType}
     * @param {Object} params
     */
  constructor(type, params) {
    const t = TimeoutType.valueOf(type);
    const p = _validObjPC(params, 'params');

    switch (t) {
      case TimeoutType.TIME_OF_DAY:
        if (!REGEX_TIME.test(params.time)) { throw new Error('params.time is not recognizable time format.'); }
        break;

      case TimeoutType.DURATION:
        if (!REGEX_TIME.test(params.after)) { throw new Error('params.after is not recognizable time format.'); }
        break;
    }

    this._type = t;
    this._params = p;
  }

    static Type = TimeoutType;

    /** @returns {EnumItem} {@link TimeoutType} */
    type() { return this._type; }

    /**
     * Returns the parameters associated with this timeout.
     * @returns {Object} A clone of the parameters; caller can modify.
     */
    parameters() { return Objects.clone(this._params); }

    /**
     * @param {string} name
     * @returns {boolean} Whether this Timeout instance contains the named parameter.
     */
    contains(name) {
      requireNonEmptyString(name, 'name');
      return this._params.hasOwnProperty(name);
    }

    /**
     * Returns a parameter value.
     * @param {string} name
     * @param {*} [defaultVal]
     * @returns {*}
     */
    parameter(name, defaultVal) {
      if (this.contains(name)) {
        return this._params[name];
      } if (arguments.length > 1) {
        return defaultVal;
      }
      throw new Error(`parameter name not found: ${ name}`);
    }

    /** @returns {Object} JSON payload to be posted to Marketplace's workflow API. */
    toJson() {
      return {
        type: this._type.valueOf(),
        params: Objects.clone(this._params),
      };
    }

    /**
     * @param {Timeout} timeout
     * @returns {Object} JSON payload for the given `timeout` argument.
     */
    static toJson(timeout) {
      return requireTimeout(timeout, 'timeout').toJson();
    }

    /**
     * @param {?Timeout} timeout May be null.
     * @returns {?Object} JSON payload for the given `timeout` argument, may be null.
     */
    static toJsonOrNull(timeout) {
      if (timeout === null) {
        return null;
      }
      return Timeout.toJson(timeout);
    }
}

/**
 * @param {(Timeout|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is an instance of Timeout; TaskedTimeout returns false.
 */
export function isTimeout(arg) {
  return (arg instanceof Timeout && arg.constructor === Timeout);
}

/**
 * Validates `arg` to be a Timeout object.  TaskedTimeout are rejected.
 * @param {Timeout} arg Argument to validate.
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {Timeout} Always returns `arg`.
 * @throws {TypeError} If `arg` is not a Timeout object.
 */
export function requireTimeout(arg, argName) {
  if (!isTimeout(arg)) {
    throw new TypeError(`${argName }: Timeout`);
  }
  return arg;
}

/**
 * Validates `arg` to be a Timeout object or null.  TaskedTimeout are rejected.
 * @param {?Timeout} arg Argument to validate.
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {?Timeout} Always returns `arg`.
 * @throws {TypeError} If `arg` is anything but a Timeout object or null.
 */
export function requireTimeoutOrNull(arg, argName) {
  if (arg !== null && !isTimeout(arg)) {
    throw new TypeError(`${argName }: Timeout or null`);
  }
  return arg;
}

/**
 * @class
 */
export class TaskedTimeout extends Timeout {
  /**
     * <ol>
     *  <li> type (string)
     *   <ol>
     *    <li> time_of_day <br />
     *         params -> time: "HH:mm" </li>
     *    <li> duration
     *         params -> ??? </li>
     *   </ol>
     *  </li>
     *  <li> params (depends on `type`) </li>
     *  <li> tasks (@see Task) </li>
     * </ol>
     * @param {EnumItem} type {@link TimeoutType}
     * @param {Object} params
     * @param {Task[]} tasks
     */
  constructor(type, params, tasks) {
    super(type, params);
    this._tasks = Object.freeze(_validArrayPC(tasks, Task, 'tasks', 'Task', false));
  }

    static Type = TimeoutType;

    /** @returns {Task[]} Tasks to be launched if this timeout were to be triggered. */
    tasks() { return this._tasks; }

    /** @returns {Object} JSON payload to be posted to Marketplace's workflow API. */
    toJson() {
      return Object.assign(super.toJson(), {
        tasks: _.map(this._tasks, Task.toJson),
      });
    }

    /**
     * @param {TaskedTimeout} timeout
     * @returns {Object} JSON payload for the given `timeout` argument.
     */
    static toJson(timeout) {
      return requireTaskedTimeout(timeout, 'timeout').toJson();
    }
}

/**
 * @param {(TaskedTimeout|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is an instance of TaskedTimeout.
 */
export function isTaskedTimeout(arg) {
  return (arg instanceof TaskedTimeout);
}

/**
 * Validates `arg` to be a TaskedTimeout object.
 * @param {TaskedTimeout} arg Argument to validate.
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {TaskedTimeout} Always returns `arg`.
 * @throws {TypeError} If `arg` is not a TaskedTimeout object.
 */
export function requireTaskedTimeout(arg, argName) {
  if (!isTaskedTimeout(arg)) {
    throw new TypeError(`${argName }: TaskedTimeout`);
  }
  return arg;
}

/**
 * Validates `arg` to be a TaskedTimeout object or null.
 * @param {?TaskedTimeout} arg Argument to validate.
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {?TaskedTimeout} Always returns `arg`.
 * @throws {TypeError} If `arg` is anything but a TaskedTimeout object or null.
 */
export function requireTaskedTimeoutOrNull(arg, argName) {
  if (arg !== null && !isTaskedTimeout(arg)) {
    throw new TypeError(`${argName }: TaskedTimeout or null`);
  }
  return arg;
}


/* ************************************************
 * Class Dependency.Scope
 * ************************************************ */

/**
 * The scope of a depencency: LOCAL, GLOBAL.
 *
 * <ol>
 *  <li> LOCAL - Pertains to an event within the same
 *       workflow run (aka instance.) </li>
 *  <li> GLOBAL - Pertains to a global event, outside
 *       the scope of the current workflow run. </li>
 * </ol>
 *
 * @enum {EnumItem}
 * @readonly
 */
export const DependencyScope = Enum.finalize({
  LOCAL: new EnumItem('LOCAL', 'run_local'),
  GLOBAL: new EnumItem('GLOBAL', 'global'),
}, 'Dependency.Scope');


/**
 * Returns the given scope if one was given, or `defaultValue` otherwise.
 * @param {(Arguments|IArguments)} args
 * @param {int} argIdx
 * @param {EnumItem} defaultValue {@link Dependency.Scope}
 * @returns {EnumItem} Dependency scope ({@link Dependency.Scope}).
 * @throws Error If the given scope cannot be mapped to an enum item.
 * @private
 */
function _scopeOptional(args, argIdx, defaultValue) {
  if (argIdx < args.length
        && args[argIdx] !== null) {
    return DependencyScope.valueOf(args[argIdx]);
  }
  return defaultValue;
}

/* ************************************************
 * Class Dependency
 * ************************************************ */

/**
 * A dependency represents something that must be fulfilled.
 * Dependencies are used to define targets, where one or more
 * dependencies must be met before the target's tasks are launched.
 *
 * Abstract class, private constructor.
 * @constructor
 * @name Dependency
 */
export function Dependency() {
  if (!_canConstructDependency) { throw new Error('UnsupportedOperationException: access denied'); }
}

Dependency.prototype = /** @lends {Dependency.prototype} */ {
  constructor: Dependency,

  /**
     * Returns the scope of this dependency.
     * @returns {Dependency.Scope}
     */
  scope() {
    // This method assumes that instances of sub-classes
    // all have a `_scope` property.
    return this._scope;
  },

  toJson() {
    throw new Error('UnsupportedOperationException: sub-class must override.');
  },
};

Object.assign(Dependency, /** @lends Dependency */ {

  Scope: DependencyScope,

  /**
     * Returns the JSON payload for the given `dependency` argument.
     * @param dependency {Dependency}
     * @returns {Object}
     */
  toJson(dependency) {
    if (!(dependency instanceof Dependency)) throw new TypeError('dependency: Dependency');

    return dependency.toJson();
  },
});


/**
 * A topic dependency is a raw implementation of a dependency;
 * it relies heavily on Marketplace's messaging scheme.
 *
 * @constructor
 * @augments Dependency
 *
 * @param {string} topic
 * @param {Object.<string, string>} props
 * @param {?(string|Dependency.Scope)} [scope=LOCAL]
 */
export function TopicDependency(topic, props, scope) {
  this._topic = requireNonEmptyString(topic, 'topic');
  this._props = _validObjPC(props, 'props');
  this._scope = _scopeOptional(arguments, 2, DependencyScope.LOCAL);

  Object.freeze(this);
}

TopicDependency.prototype = Object.assign(new Dependency(), /** @lends TopicDependency.prototype */ {
  constructor: TopicDependency,

  /**
     * Returns the topic which this dependency listens to.
     * @returns {string}
     */
  topic() { return this._topic; },

  /**
     * Returns the properties (used as a filter) for the
     * messages coming through bus the topic for the topic
     * being listened to.
     * @returns {Object.<string, string>} A clone of the Properties; caller can modify.
     */
  properties() { return Objects.clone(this._props); },

  /**
     * Returns the JSON payload, to be posted to Marketplace's workflow API.
     * @returns {Object}
     */
  toJson() {
    return {
      topic: this._topic,
      props: Objects.clone(this._props),
      scope: this._scope.valueOf(),
    };
  },
});

Object.freeze(TopicDependency);
Object.freeze(TopicDependency.prototype);

/**
 * A key-arrival dependency is a dependency or specific data arrival,
 * per KeyArrivalWorker subscription framework.
 *
 * @constructor
 * @name KeyArrivalDependency
 * @augments Dependency
 *
 * @param {string} feed Feed name, or placeholder.
 * @param {?string} key Stringified key, or placeholder.
 * @param {?string} roots Stringified roots, or placeholder.
 * @param {string} columns Stringified columns, or placeholder.
 * @param {?Timeout} timeout Timeout for the dependency.
 * @param {?(string|EnumItem)} [scope=LOCAL] {@link Dependency.Scope}
 *
 * @throws IllegalStateException If `key` and `roots` are both provided,
 *         or both not provided.
 */
export function KeyArrivalDependency(feed, key, roots, columns, timeout, scope) {
  requireNonEmptyString(feed, 'feed');

  const hasKey = !isVoid(key);
  const hasRoots = !isVoid(roots);
  let _key = null;
  let _roots = null;

  if (hasKey && hasRoots) {
    throw new Error('IllegalStateException: both `key` and `roots` were provided.');
  } else if (!hasKey && !hasRoots) {
    throw new Error('IllegalStateException: neither `key` nor `roots` was provided.');
  } else if (hasKey) {
    _key = _validJsonOrPH(key, 'key', Object);
  } else { // if (hasRoots)
    _roots = _validJsonOrPH(roots, 'roots', Array);
  }

  _validJsonOrPH(columns, 'columns', Array);

  this._feed = feed;
  this._key = _key;
  this._roots = _roots;
  this._cols = columns;
  this._timeout = requireTimeoutOrNull(timeout, 'timeout');
  this._scope = _scopeOptional(arguments, 5, DependencyScope.LOCAL);

  Object.freeze(this);
}

KeyArrivalDependency.prototype = Object.assign(new Dependency(), /** @lends KeyArrivalDependency.prototype */ {
  constructor: KeyArrivalDependency,

  /**
     * Returns the JSON payload, to be posted to Marketplace's workflow API.
     * @returns {Object}
     */
  toJson() {
    const json = {
      type: 'kaw_sub',
      scope: this._scope.valueOf(),
      timeout: Timeout.toJsonOrNull(this._timeout),
      feed: this._feed,
      columns: this._cols,
    };

    if (this._key !== null) {
      json['keys'] = this._key;
    } else {
      json['roots'] = this._roots;
    }

    return json;
  },

  feed() { return this._feed; },
});

Object.freeze(KeyArrivalDependency);
Object.freeze(KeyArrivalDependency.prototype);


// Lock Dependency super-class.
_canConstructDependency = false;
Object.freeze(Dependency);
Object.freeze(Dependency.prototype);


/* ************************************************
 * Class TargetBuilder
 * ************************************************ */
/**
 * Target builder.  This mutable class is a convenient
 * way to build immutable instances of Target.
 *
 * @constructor
 * @alias Target.Builder
 * @see Target
 *
 * @param name {string} Non-empty.
 */
export function TargetBuilder(name) {
  this._name = requireNonEmptyString(name, 'name');
  this._depends = [];
  this._tasks = [];
  this._actions = [];
  this._timeout = null;
  this._isReq = true;
}

TargetBuilder.prototype = /** @lends Target.Builder.prototype */ {
  constructor: TargetBuilder,

  /**
     * Gets or sets the name for the target.
     * @param [name] {string}
     * @returns {(string|Target.Builder)}
     */
  name(name) {
    return _getsetString(this, '_name', 'name', arguments);
  },

  /**
     * Gets or sets the is-required flag.
     * @param {boolean} [isRequired]
     * @returns {(boolean|Target.Builder)}
     */
  isRequired(isRequired) {
    if (arguments.length < 1) return this._isReq;

    if (typeof isRequired !== 'boolean') throw new TypeError('isRequired: Boolean');

    else {
      this._isReq = isRequired;
      return this;
    }
  },

  /**
     * Gets or sets the timeout for the target.
     * @param {?TaskedTimeout} [timeout]
     * @returns {(TaskedTimeout|Target.Builder)}
     */
  timeout(timeout) {
    if (arguments.length < 1) {
      return this._timeout;
    }
    this._timeout = requireTaskedTimeoutOrNull(timeout, 'timeout');
    return this;
  },

  /**
     * Gets or sets the user-actions available for this target.
     * @param {UserAction[]} [userActions]
     * @returns {(UserAction[]|Target.Builder)}
     */
  userActions(userActions) {
    if (arguments.length < 1) {
      return Arrays.slice(this._actions);
    }
    this._actions = Arrays.slice(Arrays.requireArrayOf(userActions, UserAction, 'userActions', 'UserAction'));
    return this;
  },

  /**
     * Gets or sets the dependencies for the target.  In setter mode,
     * this method sets all dependencies at once!
     *
     * @param [dependencies] {Dependency[]}
     * @returns {(Dependency[]|Target.Builder)}
     */
  dependencies(dependencies) {
    if (arguments.length < 1) return this._depends.slice(0);


    this._depends = _validArrayPC(dependencies,
      Dependency,
      'dependencies',
      'Dependency');
    return this;
  },

  /**
     * Gets or sets the tasks for the target.  In setter mode,
     * this method sets all tasks at once!
     *
     * @param [tasks] {Task[]}
     * @returns {(Task[]|Target.Builder)}
     */
  tasks(tasks) {
    if (arguments.length < 1) return this._tasks.slice(0);


    this._tasks = _validArrayPC(tasks, Task, 'tasks', 'Task');
    return this;
  },

  /**
     * Return an immutable Target object using this builder's values.
     * @returns Target
     */
  build() {
    return new Target(this);
  },
};

Object.freeze(TargetBuilder);
Object.freeze(TargetBuilder.prototype);


/* ************************************************
 * Class Target - see declaration, above.
 * ************************************************ */

/**
 * Class Target.  In the context of data workflows, targets are used
 * to define steps within a given workflow.
 *
 * A typical target has one or more dependency(ies), and one or more
 * task(s).  Once all dependencies are met, its tasks are launched,
 * possibly causing other targets' dependencies to be met.
 *
 * Some targets have neither tasks nor dependencies.  Those
 * <em>timeout targets</em> are used to define a timeout
 * for the workflow itself.
 *
 * @constructor
 */
export function Target(builder) {
  if (!(builder instanceof TargetBuilder)) { throw new TypeError('builder: Target.Builder'); }

  const noDepends = _isEmptyArray(builder._depends);
  const noTasksOrActions = _isEmptyArray(builder._tasks) && _isEmptyArray(builder._actions);

  if (noDepends && !noTasksOrActions) { throw new Error('IllegalStateException: tasks/actions require at least one dependency'); }

  if (noDepends
        && noTasksOrActions
        && builder._timeout === null) { throw new Error('IllegalStateException: no task, no dependency, no timeout!'); }

  this._name = builder._name;
  this._depends = Object.freeze(builder._depends.slice(0));
  this._actions = Object.freeze(builder._actions.slice(0));
  this._tasks = Object.freeze(builder._tasks.slice(0));
  this._timeout = builder._timeout;
  this._isReq = builder._isReq;

  Object.freeze(this);
}

Target.prototype = /** @lends Target.prototype */ {
  constructor: Target,

  /**
     * Returns the name given to this target.
     * @returns {string}
     */
  name() { return this._name; },

  /**
     * Returns whether this target is required.
     * This affects the "% complete" value calculated by the server.
     * @returns {boolean}
     */
  isRequired() { return this._isReq; },

  /**
     * Returns the dependencies that pertains to this target, as
     * an immutable array.
     * @returns {Dependency[]} Immutable array.
     */
  dependencies() { return this._depends; },

  /** @returns {UserAction[]} Immutable array of user-actions associated with this target, may be empty. */
  userActions() { return this._actions; },

  /**
     * Returns the tasks that pertains to this target, as
     * an immutable array.
     * @returns {Task[]} Immutable array.
     */
  tasks() { return this._tasks; },

  /**
     * @returns {?TaskedTimeout} Timeout that pertains to this target, if any.
     */
  timeout() { return this._timeout; },

  /**
     * Returns the JSON payload, to be posted to Marketplace's workflow API.
     * @returns {Object}
     */
  toJson() {
    const obj = {
      name: this._name,
      required: this._isReq,
      dependencies: _.map(this._depends, Dependency.toJson),
      tasks: _.map(this._tasks, Task.toJson),
      timeout: ((this._timeout !== null) ? this._timeout.toJson() : null),
    };

    if (Array.isArray(this._actions) && this._actions.length > 0) {
      obj['user_actions'] = this._actions.map(UserAction.toJson);
    }

    return obj;
  },

  /**
     * Returns a new Target.Builder instance populated with this target's
     * values.
     * @returns {Target.Builder}
     */
  builder() {
    const builder = new TargetBuilder(this._name);

    return builder.dependencies(this._depends)
      .userActions(this._actions)
      .tasks(this._tasks)
      .timeout(this._timeout);
  },
};

Object.assign(Target, /** @lends Target */ {

  Builder: TargetBuilder,

  /**
     * Returns the JSON payload for the given `target` argument.
     * @param target {Target}
     * @returns {Object}
     */
  toJson(target) {
    if (!(target instanceof Target)) {
      throw new TypeError('target: Target');
    }
    return target.toJson();
  },
});

Object.freeze(Target);
Object.freeze(Target.prototype);

/**
 * Represents a Marketplace workflow.
 *
 * @constructor
 * @name MpDataWorkflow
 *
 * @param {int} id
 * @param {string} name Non-empty.
 * @param {string} owner Non-empty.
 * @param {int} created Epoch value.
 * @param {int} changed Epoch value.
 * @param {string} tz Time zone used for times specified within this workflow.
 * @param {string} company Owner's company name.
 * @param {string} description
 */
export function MpDataWorkflow(id, name, owner, created, changed, tz, company, description) {
  this._id = requireInteger(id, 'id');
  this._name = requireNonEmptyString(name, 'name');
  this._tz = requireNonEmptyString(tz, 'tz');
  this._owner = requireNonEmptyString(owner, 'owner');

  this._created = requireInteger(created, 'created');
  this._changed = requireInteger(changed, 'changed');
  this._company = requireString(company, 'company');
  this._description = requireString(description, 'description');

  Object.freeze(this);
}

MpDataWorkflow.prototype = /** @lends {MpDataWorkflow.prototype} */ {
  constructor: MpDataWorkflow,

  /**
     * Returns whether this workflow has been saved (aka persisted)
     * at least once.  This method returns *true* if the
     * config ID one recognized by the server.
     * @returns {boolean}
     */
  isPersisted() { return (this._id >= 0); },

  /** @returns {int} Workflow ID. */
  id() { return this._id; },

  /** @returns {string} Workflow name. */
  name() { return this._name; },

  /** @returns {string} Time-zone associated with this workflow. */
  timezone() { return this._tz; },

  /** @returns {string} Owner (user) ID of this workflow. */
  owner() { return this._owner; },

  /** @returns {string} Owner's company name. */
  company() { return this._company; },

  /** @returns {Date} Date when this workflow was created. */
  timeCreated() { return new Date(this._created); },

  /** @returns {Date} Date when this workflow was last modified. */
  lastModified() { return new Date(this._changed); },

  /** @returns {string} String ID used by SmartCache. */
  toSmartCacheId() { return this._id.toString(10); },

  /** @returns {string} Workflow description. */
  description() { return this._description; },
};

// We freeze the constructor just before we complete its namespace properties,
// near the bottom of the file.
// Object.freeze(MpDataWorkflow);
Object.freeze(MpDataWorkflow.prototype);


/**
 * Workflow config consists of a list of targets (aka steps)
 * a workflow must go through, within a given period.
 *
 * Use this builder class to create mutable config instances
 * which the "save" APIs accept.
 *
 * @see Config
 */
export class WorkflowConfigBuilder {
  /**
     * @param {string} name - Non-empty.
     * @param {Config} [config]
     */
  constructor(name, config) {
    requireNonEmptyString(name, 'name');

    if (arguments.length < 2) { config = null; } else if (config !== null
                 && !(config instanceof Config)) { throw new TypeError('config: Config'); }

    // Don't lose the Config object
    Object.defineProperty(this, '_config', {
      configurable: false,
      enumerable: true,
      writable: false,
      value: config,
    });

    this._name = name;
    this._tz = null;
    this._targets = [];
    this._ui = {};
    this._description = '';
    this._psgId = 0;
    this._psgVersion = 0;
    this._correctionDays = 0;
  }

  /**
     * Gets or set the name for this workflow/config.
     * @param [name] {string}
     * @returns {(string|WorkflowConfigBuilder)}
     */
  name(name) {
    return _getsetString(this, '_name', 'name', arguments);
  }

  /**
     * Gets or set the time zone for this workflow/config.
     * @param [tz] {string} The name of the (Java) time zone.
     * @returns {(string|WorkflowConfigBuilder)}
     */
  timezone(tz) {
    return _getsetString(this, '_tz', 'tz', arguments);
  }

  /**
     * Gets or set the targets for this workflow/config.
     * In setter mode, this method sets all targets at once!
     *
     * @param targets {Target[]}
     * @returns {(Target[]|WorkflowConfigBuilder)}
     */
  targets(targets) {
    if (arguments.length < 1) {
      return this._targets.slice(0);
    }
    this._targets = _validArrayPC(targets, Target, 'targets', 'Target', true);
    return this;
  }

  /**
     * Gets or sets the UI properties used by MpDataWorkflowGui.Manager.
     * @param {Object} [ui]
     * @returns {(Object|WorkflowConfigBuilder)}
     */
  ui(ui) {
    if (arguments.length < 1) {
      return Objects.clone(this._ui);
    }
    this._ui = Objects.clone(requireObject(ui, 'ui')); // protective copy.
    return this;
  }

  /**
     * Gets or set the description for this workflow/config.
     * @param [description] {string}
     * @returns {(string|WorkflowConfigBuilder)}
     */
  description(description) {
    return _getsetString(this, '_description', 'description', arguments, true);
  }

  /**
     * Gets or set the psgId for this workflow/config.
     * @param [psgId] {int}
     * @returns {(int|WorkflowConfigBuilder)}
     */
  psgId(psgId) {
    psgId = parseInt(psgId, 10);
    this._psgId = requireNonNegativeInteger(psgId, 'psgId');
    return this;
  }

  /**
     * Gets or set the psgVersion for this workflow/config.
     * @param [psgVersion] {int}
     * @returns {(int|WorkflowConfigBuilder)}
     */
  psgVersion(psgVersion) {
    psgVersion = parseInt(psgVersion, 10);
    this._psgVersion = requireNonNegativeInteger(psgVersion, 'psgVersion');
    return this;
  }

  /**
     * Gets or set the psgVersion for this workflow/config.
     * @param [psgVersion] {int}
     * @returns {(int|WorkflowConfigBuilder)}
     */
  correctionDays(correctionDays) {
    correctionDays = parseInt(correctionDays, 10);
    this._correctionDays = requireNonNegativeInteger(correctionDays, 'correctionDays');
    return this;
  }

  /**
     * Overwrites the ParameterSetGroup ID within the `ui` property of this builder.
     * This method was added as an after-thought, to accommodate the lack of a field to store PSG ID.
     * @param {int} id Non-negative parameter-set-group ID.
     * @returns {WorkflowConfigBuilder} `this`
     */
  overwriteParameterSetGroupId(id) {
    const psgId = requireNonNegativeInteger(id, 'id');
    this._ui.psg_id = psgId;
    this._psgId = psgId;
    return this;
  }

  /**
     * Overwrites the cron-expression within the `ui` property of this builder.
     * This method was added as an after-thought, to accommodate the lack of a field to store the
     * schedule info (cron-expression).
     * @param {string} cronExpr Cron expression.
     * @returns {WorkflowConfigBuilder} `this`
     */
  overwriteCronExpression(cronExpr) {
    this._ui.cron_expr = requireNonEmptyString(cronExpr, 'cronExpr');
  }

  /**
     * Returns the Config object from which this builder
     * was constructed, if any.
     * @returns {?Config}
     */
  config() {
    return this._config;
  }

  /**
     * Returns whether this builder represents a new workflow
     * (true) or an existing one (false).
     * @returns {boolean} *true* if workflow config was never persisted to Marketplace.
     */
  isNewConfig() {
    /** @type {WorkflowConfig} */
    const config = this._config;
    return (config === null
                || !config.isPersisted());
  }

  /**
     * Builds a Config instance based on the current values
     * of this WorkflowConfigBuilder.
     * @returns {Config}
     */
  build() {
    // Going negative, so we don't conflict with
    // real workflow configurations from the server.
    _localConfigSeq--;

    const workflow = new MpDataWorkflow(
      _localConfigSeq,
      this._name,
      getUserName(),
      0,
      0,
      this._tz,
      '',
      this._description,
    );

    return new Config(
      workflow,
      PermissionSet.ALL,
      this._targets,
      this._ui,
      this._psgId,
    );
  }

  /**
     * Returns the JSON payload to be posted to Marketplace API.
     * @returns {Object}
     */
  toJson() {
    /** @type {WorkflowConfig} */
    const config = this._config;
    const json = {
      name: this._name,
      timeZone: this._tz,
      targets: _.map(this._targets, Target.toJson),
      ui: Objects.clone(this._ui),
      description: this._description,
      psgId: this._psgId,
      psgVersion: this._psgVersion,
      correctionDays: this._correctionDays,
    };

    if (!this.isNewConfig()) {
      json['id'] = config.id();
      json['owner'] = config.owner();
    }

    return json;
  }
}

/**
 * @param {(WorkflowConfigBuilder|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is an instance of WorkflowConfigBuilder.
 */
export function isWorkflowConfigBuilder(arg) {
  return (arg instanceof WorkflowConfigBuilder);
}

/**
 * Validates that `arg` is an instance of WorkflowConfigBuilder.
 * @param {WorkflowConfigBuilder} arg Argument to validate.
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {WorkflowConfigBuilder} Always returns `arg`.
 * @throws {TypeError} If `arg` is not an instance of WorkflowConfigBuilder.
 */
export function requireWorkflowConfigBuilder(arg, argName) {
  if (!isWorkflowConfigBuilder(arg)) {
    throw new TypeError(`${argName }: WorkflowConfigBuilder`);
  }
  return arg;
}

/**
 * Workflow config consists of a list of targets (aka steps)
 * a workflow must go through, within a given period.
 *
 * This class is immutable; use WorkflowConfigBuilder to create
 * new mutable instances.
 *
 * @constructor
 * @alias WorkflowConfig
 *
 * @param {MpDataWorkflow} workflow
 * @param {(string[]|PermissionSet)} perms Non-empty.
 * @param {Target[]} targets Non-empty.
 * @param {Object} ui - UI properties.
 * @param [serverPayload] {Object} Server payload.
 * @param [psgId] {Object} parameter set group Id.
 */
function Config(workflow, perms, targets, ui, serverPayload, psgId) {
  if (!(workflow instanceof MpDataWorkflow)) { throw new TypeError('workflow: MpDataWorkflow'); }

  this._wf = workflow;

  if (perms instanceof PermissionSet) {
    if (perms.isEmpty()) { throw new Error('IllegalArgumentException: `perms` is empty'); }

    this._perms = perms;
  } else {
    this._perms = new PermissionSet(_validArrayPC(
      perms,
      'string',
      'perms',
      'String',
      false,
    ));
  }

  this._targets = Object.freeze(_validArrayPC(targets,
    Target,
    'targets',
    'Target',
    !workflow.isPersisted()));
  this._targetsByName = Object.freeze(_.indexBy(this._targets, target => target.name()));

  this._ui = Objects.freezeDeep(Objects.clone(requireObject(ui, 'ui')));
  this._fromServer = (arguments.length >= 5 ? serverPayload : null);
  this._psgId = psgId;
  // Object.freeze(this);
}

Object.assign(Config.prototype, /** @lends WorkflowConfig.prototype */ {

  /**
     * Returns a new WorkflowConfigBuilder instance,
     * pre-populated with this config's values.
     * @returns {WorkflowConfigBuilder}
     */
  builder() {
    const builder = ((this.isPersisted())
      ? new WorkflowConfigBuilder(this._name, this)
      : new WorkflowConfigBuilder(this._name));

    return builder.timezone(this._tz)
      .targets(this._targets)
      .ui(this._ui)
      .description(this._description)
      .psgId(this._psgId);
  },

  /** @returns {MpDataWorkflow} The workflow associated with this config. */
  workflow() { return this._wf; },

  /**
     * Returns whether this workflow has been saved (aka persisted)
     * at least once.  This method returns *true* if the
     * config ID is one recognized by the server.
     * @returns {boolean}
     */
  isPersisted() { return this._wf.isPersisted(); },

  /** @returns {int} Config's workflow ID. */
  id() { return this._wf.id(); },

  /** @returns {string} Workflow name. */
  name() { return this._wf.name(); },

  /** @returns {string} Time-zone associated with this workflow. */
  timezone() { return this._wf.timezone(); },

  /** @returns {string} Owner (user) ID of this workflow. */
  owner() { return this._wf.owner(); },

  /** @returns {Date} Date when this workflow was created. */
  timeCreated() { return this._wf.timeCreated(); },

  /** @returns {Date} Date when this workflow was last modified. */
  lastModified() { return this._wf.lastModified(); },

  /**
     * @returns {PermissionSet} Permissions of the current user against
     *         this workflow.
     */
  permissions() { return this._perms; },

  /**
     * @returns {Target[]} Targets of this workflow config,
     *         in no particular order.
     */
  targets() { return this._targets; },

  /** @returns {Object} UI properties related to this workflow. */
  ui() { return this._ui; },

  /** @returns {string} Workflow description. */
  description() { return this._wf.description(); },

  /** @returns {int} Config's parameter set group ID. */
  psgId() { return this._psgId; },

  /** @returns {string} String ID used by SmartCache. */
  toSmartCacheId() { return this._wf.toSmartCacheId(); },

  /**
     * Returns the named target.
     * @param {string} name
     * @param {*} [defaultValue]
     * @returns {Target}
     */
  target(name, defaultValue) {
    requireNonEmptyString(name, 'name');

    const idx = this._targetsByName;
    if (idx.hasOwnProperty(name)) return idx[name];

    if (arguments.length > 1) return defaultValue;

    throw new Error(`IllegalArgumentException: target name not found (${ name })`);
  },

  /**
     * Returns the stringified server payload.
     * @param {(string|int)} [indent]
     * @returns {string}
     */
  serverPayloadAsString(indent) {
    const fromServer = this._fromServer;
    if (fromServer === null) return '';


    const args = [fromServer];
    if (arguments.length > 0) args.push(null, indent);

    return JSON.stringify.apply(JSON, args);
  },
});

Object.assign(Config, /** @lends {WorkflowConfig} */ {
  Builder: WorkflowConfigBuilder,

  /**
     * @param {(WorkflowConfig|*)} arg
     * @returns {boolean} Whether `arg` is an instance of WorkflowConfig.
     */
  isWorkflowConfig(arg) {
    return (arg instanceof Config);
  },

  /**
     * Validates that `arg` is an instance of WorkflowConfig.
     * @param {WorkflowConfig} arg Argument to validate.
     * @param {string} argName Name given to `arg` for when error must be thrown.
     * @returns {WorkflowConfig} Always returns `arg`.
     * @throws {TypeError} If `arg` is not an instance of WorkflowConfig.
     */
  requireWorkflowConfig(arg, argName) {
    if (!(arg instanceof Config)) {
      throw new TypeError(`${argName }: WorkflowConfig`);
    }
    return arg;
  },

  /**
     * Compares two workflow config objects for the purpose of sorting them by name
     * (case-insensitive).
     * @param {WorkflowConfig} o1
     * @param {WorkflowConfig} o2
     * @returns {number}
     * @method
     */
  compare: _newNameComparator(Config),
});
Object.freeze(Config);
Object.freeze(Config.prototype);


/* ********************************************************
 * Private variables, static (of RunStatus)
 * ******************************************************** */
const PROPS_TO_EXCLUDE = {
  run_id: true,
  workflow_id: true,
  param_set_id: true,
  errors: true,
  dependencies: true,
};

/* ********************************************************
 * Private methods, static (of RunStatus)
 * ******************************************************** */

/**
 * Freezes a run error.  This method freezes the given object.
 * @param err {RunError}
 * @returns {RunError} Same object, frozen.
 * @private
 */
function _freezeRunError(err) {
  Object.freeze(err.data);
  Object.freeze(err);
  return err;
}

/**
 * Removes duplicate errors.  Our server keeps tracks of all errors
 * related to a formula, until that formula runs successfully.
 * This method consolidates the errors within one *run*; it only
 * keeps the latest error for each formula UUID.
 *
 * @param errors {RunError[]}
 * @returns {RunError[]} A frozen array of frozen RunError objects.
 * @private
 */
function _dedupErrors(errors) {
  const uuids = {};
  const rv = [];

  for (let i = 0, len = errors.length; i < len; i++) {
    const err = errors[i];

    if (err.type !== 'formula') { rv.push(err); } else {
      const { data } = err;
      const { uuid } = data;

      if (!uuids.hasOwnProperty(uuid)) {
        uuids[uuid] = rv.length;
        rv.push(err);
      } else {
        // If the existing error occurred before (or at the same time as)
        // the current error, then we replace it with the current error.
        // Otherwise, we keep the previous one.

        const idx = uuids[uuid];
        const prevErr = rv[idx];

        // ISO dates makes it easier to sort without converting
        // to actual dates...

        if (prevErr.date <= err.date) { rv[idx] = err; }
      }
    }
  }

  return Object.freeze(_.map(rv, _freezeRunError));
}

/**
 * Represents the status of a workflow run, usually associated with a parameter-set ID.
 * @class
 */
export class RunStatus {
  /**
     * @param configStatus {ConfigStatus}
     * @param serverPayload {Object}
     */
  constructor(configStatus, serverPayload) {
    const workflowId = _validProp(serverPayload, 'workflow_id', Numbers.isNonNegativeInteger);

    if (configStatus.config().id() !== workflowId) {
      throw new Error("`workflow_id` doesn't match `configStatus.config().id()`");
    }

    this._cfgStat = configStatus;
    this._runId = _validProp(serverPayload, 'run_id', isNonEmptyString, 'String, non-empty');
    this._paramSetId = _validProp(serverPayload, 'param_set_id', isInteger, 'Integer');
    this._paramSetUuid = _validProp(serverPayload, 'param_set_uuid', _isString, 'String');

    this._actionHistory = Objects.freezeDeep(
      _validProp(serverPayload, 'action_history', Arrays.isArrayLike, 'Array'),
    );
    this._errs = _dedupErrors(serverPayload.errors);

    this._dependencies = Object.freeze(
      _validProp(serverPayload, 'dependencies', Arrays.isArrayLike, 'Array')
        .map(dep => new CompletedDependency(this, dep)),
    );

    // Just batch copy the rest for now, excluding properties used above.
    this._stats = Object.freeze(_.reduce(serverPayload, (memo, val, name) => {
      if (!PROPS_TO_EXCLUDE.hasOwnProperty(name)) {
        if (val instanceof Object) {
          memo[name] = Object.freeze(val);
        } else {
          memo[name] = val;
        }
      }
      return memo;
    }, {}));

    Object.freeze(this);
  }

  /**
     * Returns the ConfigStatus (parent) object that contains this RunStatus instance.
     * @returns {ConfigStatus}
     */
  configStatus() { return this._cfgStat; }

  /**
     * Returns the run ID associated with this workflow run.
     * @returns {string}
     */
  runId() { return this._runId; }

  /**
     * @returns {int} Parameter-set ID associated with this workflow run, -1 if no
     *          parameter-set is associated with this run.
     */
  paramSetId() { return this._paramSetId; }

  /**
     * @returns {string} Parameter-set UUID associated with this workflow run, "" if no
     *          parameter-set is associated with this run.
     */
  paramSetUuid() { return this._paramSetUuid; }

  /**
     *
     * @return {Array} Array of manual user  action history object associated with the run
     */
  actionHistory() { return this._actionHistory; }

  /**
     * Returns true if the actionHistory array is empty
     * @return {boolean}
     */
  isActionHistoryEmpty() {
    return this._actionHistory.length === 0;
  }

  /** @returns {CompletedDependency[]} Immutable list of completed dependencies. */
  completedDependencies() {
    return this._dependencies;
  }

  /**
     * Returns the number of errors associated with this workflow run.
     * @returns {int}
     */
  numErrors() {
    return this._errs.length;
  }

  /**
     * Returns the error associated with `idx`.
     * @param idx {int} Non-negative, less than `numErrors()`.
     * @returns {Object}
     */
  error(idx) {
    const errs = this._errs;

    if (!Numbers.isNonNegativeInteger(idx)
            || idx >= errs.length) { throw new TypeError('idx: Integer, non-negative, less than `numErrors()`'); }

    return errs[idx];
  }

  /**
     * Returns an immutable list of errors.
     * @returns {Object[]}
     */
  errors() {
    return this._errs;
  }

  /**
     * Returns the stats associated with this run (raw from the server.)
     * @returns {Object}
     */
  stats() { return Objects.clone(this._stats); }

  /**
     * Returns one named stat.
     * @param name {string}
     * @param [defaultValue] {Object}
     * @returns {(string|Object)}
     */
  stat(name, defaultValue) {
    requireNonEmptyString(name, 'name');
    if (this._stats.hasOwnProperty(name)) {
      return this._stats[name];
    } if (arguments.length > 1) {
      return defaultValue;
    }
    throw new Error(`Named stat not found: ${ name}`);
  }

  /**
     * Returns whether the parameter-set associated with this status
     * is active (currently running) or not.
     * @returns {boolean}
     */
  isRunning() {
    return !isNonEmptyString(this._stats['finish_date']);
  }
}

/**
 * @param {(RunStatus|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is a RunStatus object.
 */
export function isRunStatus(arg) {
  return (arg instanceof RunStatus);
}

/**
 * Validates `arg` to be a RunStatus object.
 * @param {RunStatus} arg Argument to validate.
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {RunStatus} Always returns `arg`.
 * @throws {TypeError} If `arg` is not a RunStatus object.
 */
export function requireRunStatus(arg, argName) {
  if (!isRunStatus(arg)) {
    throw new TypeError(`${argName }: RunStatus`);
  }
  return arg;
}

/**
 * Reason for why a dependency was marked "complete": MESSAGE, TIMEOUT, UNKNOWN.
 * @enum {EnumItem}
 * @readonly
 */
const CompletedDependencyReason = Enum.finalize({
  MESSAGE: new EnumItem('MESSAGE', 'message'),
  TIMEOUT: new EnumItem('TIMEOUT', 'timeout'),
  UNKNOWN: new EnumItem('UNKNOWN', 'unknown'),
}, 'DependencyStatusReason');

/**
 * Record of a completed dependency, includes completion timestamp and reason.
 */
class CompletedDependency {
  /**
     * @param {RunStatus} runStat
     * @param {{id: string, completed: string, reason: string}} serverObject
     */
  constructor(runStat, serverObject) {
    this._runStat = requireRunStatus(runStat, 'runStat');
    requireObject(serverObject, 'serverObject');
    this._id = requireNonEmptyString(serverObject.id, 'serverObject.id');
    this._ts = requireDate(
      Dates.isoDate(requireNonEmptyString(
        serverObject.completed,
        'serverObject.completed',
      )),
      'serverObject.completed',
    ).getTime();
    this._reason = CompletedDependencyReason.valueOf(serverObject.reason, CompletedDependencyReason.UNKNOWN);
    Object.freeze(this);
  }

  /** @returns {string} For debugging purposes. */
  toString() {
    return `${'CompletedDependency{'
            + 'id: '}${ this._id
    }, completed: ${ Dates.dateToString(this.timestamp(), 5)
    }, reason: ${ this._reason
    }}`;
  }

  /** @returns {RunStatus} RunStatus object to which this CompletedDependency belongs to. */
  runStatus() { return this._runStat; }

  /**
     * @returns {string} ID that points to the dependency, unique to a workflow. Format:
     *          "<workflow_id>|<target_name>|<sequence_within_target>"
     */
  id() { return this._id; }

  /** @returns {Date} Timestamp for when the dependency was marked *completed*. */
  timestamp() { return new Date(this._ts); }

  /** @returns {EnumItem} {@link CompletedDependencyReason} */
  reason() { return this._reason; }
}

/**
 * A status object pertaining to a given workflow (config),
 * which may contain 0-n statuses (one for each run.)
 *
 * @constructor
 * @name ConfigStatus
 *
 * @param config {Config}
 * @param serverPayload {Object}
 */
function ConfigStatus(config, serverPayload) {
  if (!(config instanceof Config)) { throw new TypeError('config: Config'); }

  if (!Objects.is(serverPayload)
        || !isNonEmptyString(serverPayload.res)) { throw new TypeError('serverPayload: {{res: string, [value]: Array}}'); }

  this._config = config;
  this._status = serverPayload.res;

  let runs = Arrays.EMPTY;

  if (serverPayload.value instanceof Array) {
    const _this = this;
    runs = Object.freeze(_.map(serverPayload.value, value => new RunStatus(_this, value)));
  }

  /** @type {RunStatus[]} */
  this._runs = runs;

  Object.freeze(this);
}

ConfigStatus.prototype = /** @lends ConfigStatus.prototype */ {
  constructor: ConfigStatus,

  /**
     * Returns the workflow configuration associated with this status instance.
     * @returns {Config}
     */
  config() { return this._config; },

  /**
     * Returns the status of this workflow status request.
     * This method returns "OK" if the server didn't encounter
     * any error while processing the request.  Otherwise, the
     * status can be any of the following (not limited to):
     * <ol>
     *     <li> NOT_FOUND </li>
     *     <li> NOT_AUTHORIZED </li>
     *     <li> MISSING_FROM_RESPONSE - local status if server did
     *          not include this workflow in its response. </li>
     * </ol>
     * @returns {string}
     */
  status() { return this._status; },

  /**
     * Returns the number of runs included in this response.
     * @returns {int}
     */
  numRuns() { return this._runs.length; },

  /**
     * Returns the entire list of RunStatus objects.
     * The list is immutable; caller must make a copy
     * before mutating.
     * @returns {RunStatus[]}
     */
  runs() { return this._runs; },

  /**
     * Returns a list of RunStatus objects associated with `paramSetUuid`.  This method
     * returns a list because there are cases, albeit rare cases, when the workflow worker
     * might end up starting multiple runs for the same parameter-set UUID.
     *
     * @param {string} paramSetUuid
     * @returns {RunStatus[]} Array of RunStatus objects associated with
     *          `paramSetUuid`, may be empty. The array can be safely mutable.
     * @throws {TypeError} - If `paramSetUuid` is not a string or is empty.
     */
  runsByParamSetUuid(paramSetUuid) {
    requireNonEmptyString(paramSetUuid, 'paramSetUuid');

    return _.filter(this._runs,

      /** @param {RunStatus} run */
      run => (run.paramSetUuid() === paramSetUuid));
  },
};

Object.assign(ConfigStatus, /** @lends ConfigStatus */ {

  /**
     * Creates a ConfigStatus instance with status "MISSING_FROM_RESPONSE".
     * This method is used when the server forgets (or can't) send any information
     * related to a requested workflow ID.
     *
     * @param config {Config}
     * @returns {ConfigStatus}
     */
  missing(config) {
    return new ConfigStatus(config, {
      res: 'MISSING_FROM_RESPONSE',
      value: [],
    });
  },

  /**
     * Creates a ConfigStatus instance with status "PENDING_RESPONSE".
     * This method is used during the interim time when a parameter-set-group
     * has been received but workflow statuses have not been requested/received.
     *
     * @param config {Config}
     * @returns {ConfigStatus}
     */
  pending(config) {
    return new ConfigStatus(config, {
      res: 'PENDING_RESPONSE',
      value: [],
    });
  },
});

Object.freeze(ConfigStatus);
Object.freeze(ConfigStatus.prototype);


/**
 * Events pertaining to workflow operations: FIRST_START, LAST_COMPLETE, ANY_START, ANY_COMPLETE.
 *
 * @enum {EnumItem}
 * @readonly
 */
export const WorkflowEvent = Enum.finalize({
  FIRST_START: new EnumItem('FIRST_START', 'first_start'),
  LAST_COMPLETE: new EnumItem('LAST_COMPLETE', 'last_complete'),
  ANY_START: new EnumItem('ANY_START', 'any_start'),
  ANY_COMPLETE: new EnumItem('ANY_COMPLETE', 'any_complete'),
}, 'WorkflowEvent');

/**
 * An event configuration within a report, pertains to a workflow.
 *
 * @constructor
 * @name ReportEvent
 *
 * @param {int} wfId - Workflow ID.
 * @param {string[]} paramSetIds - Parameter-set UUIDs, may be empty but never null.
 * @param {string[]} eventNames - Name of events for when to trigger notifications,
 *        may be empty but never null.
 */
export function ReportEvent(wfId, paramSetIds, eventNames) {
  this._wfId = requireInteger(wfId, 'wfId');

  this._psetIds = Object.freeze(
    Arrays.slice(
      Arrays.requireValid(
        paramSetIds,
        isNonEmptyString,
        'paramSetIds',
      ),
    ),
  );

  if (!Arrays.isArrayOf(eventNames, 'string')) { throw new TypeError('eventNames: String[]'); }

  this._events = Object.freeze(_.map(eventNames, WorkflowEvent.valueOf));

  Object.freeze(this);
}

ReportEvent.prototype = /** @lends {ReportEvent.prototype} */ {
  constructor: ReportEvent,

  /** @returns {int} Workflow ID. */
  workflowId() {
    return this._wfId;
  },

  /** @returns {string[]} Array of parameter-set UUIDs. */
  parameterSetUUIDs() {
    return this._psetIds;
  },

  /**
     * @returns {WorkflowEvent[]} Array of events that pertain to this
     *          report entry.
     */
  events() {
    return this._events;
  },

  /** @returns {Object} Server-approved POJO object. */
  toJson() {
    return ReportEvent.toJson(this);
  },
};

Object.assign(ReportEvent, /** @lends {ReportEvent} */ {

  /**
     * Converts the given workflow report event into a server-approved POJO/JSON object.
     * @param {ReportEvent} rptEvent
     * @returns {Object}
     */
  toJson(rptEvent) {
    return {
      id: rptEvent._wfId,
      events: _.map(rptEvent._events, e => e.valueOf()),
      psets: rptEvent._psetIds,
    };
  },
});

Object.freeze(ReportEvent);
Object.freeze(ReportEvent.prototype);


/**
 * Represents a workflow report record.
 *
 * @constructor
 *
 * @param {int} id - ID.
 * @param {string} name - Name.
 * @param {string} descr - Description.
 * @param {ReportEvent[]} workflows - Workflows included in this report.
 * @param {string[]} recipients - Recipients email addresses.
 * @param {string} cronSchedule - Cron expression for when to force-trigger a notification,
 *                 may be empty.
 * @param {string} timeZone - Time-zone pertaining to cron expressions.
 */
export function Report(
  id,
  name,
  descr,
  // owner,
  recipients,
  workflows,
  cronSchedule,
  timeZone,
) {
  const validString = ((_isExternalReport)
    ? requireNonEmptyString
    : requireString);

  this._id = requireInteger(id, 'id');
  this._name = validString(name, 'name');
  this._descr = requireString(descr, 'descr');
  // this._owner = validString(owner, "owner");

  this._recipients = Object.freeze(
    Arrays.slice(
      Arrays.requireValid(
        recipients,
        isNonEmptyString,
        'recipients',
      ),
    ),
  );

  this._workflows = Object.freeze(
    Arrays.slice(
      Arrays.requireValid(
        workflows,
        item => (item instanceof ReportEvent),
        'workflows',
      ),
    ),
  );

  // TODO - Improve validation for cron expression explicitly.
  this._cronSchedule = requireString(cronSchedule, 'cronSchedule');
  this._tz = requireString(timeZone, 'timeZone');

  if (isNonEmptyString(cronSchedule)
        && !isNonEmptyString(timeZone)) { throw new Error('timeZone is required when cronSchedule is provided.'); }

  if (_isExternalReport) {
    // Extra validation for reports created by external programs.

    // if (this._id < 0)
    //    throw new Error("IllegalArgumentException: id is negative");

    if (this._recipients.length === 0) { throw new Error('IllegalArgumentException: recipients is empty'); }

    if (this._workflows.length === 0) { throw new Error('IllegalArgumentException: workflows is empty'); }
  }

  Object.freeze(this);
}

Report.prototype = /** @lends {Report.prototype} */ {
  constructor: Report,

  /** @returns {int} Report ID. */
  id() { return this._id; },

  /** @returns {string} Report name. */
  name() { return this._name; },

  /** @returns {string} Report description. */
  description() { return this._descr; },

  // /** @returns {string} Owner of this report. */
  // owner: function () { return this._owner; },

  /** @returns {string[]} Immutable list of recipient email addresses. */
  recipients() { return this._recipients; },

  /**
     * @returns {ReportEvent[]} Immutable list of workflow IDs
     *          and events of interest within each workflow.
     */
  workflows() { return this._workflows; },

  /**
     * @returns {string} Cron expression describing the time at which we force-send
     * a notification.
     */
  cronSchedule() { return this._cronSchedule; },

  /** @returns {string} Time-zone pertaining to the cron schedule. */
  timeZone() { return this._tz; },

  /** @returns {Object} Server-approved POJO object. */
  toJson() {
    return Report.toJson(this);
  },

  /**
     * @param {string} name - Name.
     * @param {string} descr - Description.
     * @param {ReportEvent[]} workflows - Workflows included in this report.
     * @param {string[]} recipients - Recipients email addresses.
     * @param {string} cronSchedule - Cron expression for when to force-trigger a notification,
     *                 may be empty.
     * @param {string} timeZone - Time-zone pertaining to cron expressions.
     * @returns {Report} A new Report instance with updated values;
     *          this method preserves the original report ID and owner.
     */
  withChanges(
    name,
    descr,
    recipients,
    workflows,
    cronSchedule,
    timeZone,
  ) {
    return new Report(
      this._id,
      name,
      descr,
      // this._owner,
      recipients,
      workflows,
      cronSchedule,
      timeZone,
    );
  },
};

let EMPTY_REPORT;
try {
  _isExternalReport = false;
  EMPTY_REPORT = new Report(-1, '', '', [], [], '', '');
} finally {
  _isExternalReport = true;
}

Object.assign(Report, /** @lends {Report} */ {

  /** @type {Report} */
  EMPTY: EMPTY_REPORT,

  /**
     * Converts the given workflow report into a server-approved POJO/JSON object.
     * @param {Report} rpt
     * @returns {Object}
     */
  toJson(rpt) {
    if (!(rpt instanceof Report)) throw new TypeError('rpt: Report');

    return {
      id: rpt._id,
      name: rpt._name,
      desc: rpt._descr,
      // owner: rpt._owner,
      recipients: rpt._recipients,
      workflows: _.map(rpt._workflows, ReportEvent.toJson),
      cron: rpt._cronSchedule,
      timezone: rpt._tz,
    };
  },

  /**
     * Compares two workflow report objects for the purpose of sorting them by name
     * (case-insensitive).
     * @param {Report} o1
     * @param {Report} o2
     * @returns {number}
     * @method
     */
  compare: _newNameComparator(Report),
});

Object.freeze(Report);
Object.freeze(Report.prototype);

/**
 * Represents a workflow report record.
 *
 * @constructor
 * @name ReportHeader
 *
 * @param {int} id - ID.
 * @param {string} name - Name.
 * @param {string} descr - Description.
 */
export function ReportHeader(id, name, descr) {
  this._id = requireInteger(id, 'id');
  this._name = requireNonEmptyString(name, 'name');
  this._descr = requireString(descr, 'descr');

  Object.freeze(this);
}

ReportHeader.prototype = /** @lends {ReportHeader.prototype} */ {
  constructor: ReportHeader,

  /** @returns {int} Report ID. */
  id() { return this._id; },

  /** @returns {string} Report name. */
  name() { return this._name; },

  /** @returns {string} Report description. */
  description() { return this._descr; },
};

Object.assign(ReportHeader, /** @lends {ReportHeader} */ {

  /**
     * Compares two workflow report header objects for the purpose of sorting them by name
     * (case-insensitive).
     * @param {ReportHeader} o1
     * @param {ReportHeader} o2
     * @returns {number}
     * @method
     */
  compare: _newNameComparator(ReportHeader),
});

Object.freeze(ReportHeader);
Object.freeze(ReportHeader.prototype);


/**
 *  Represents the response received from the GetJobStatus API
 *
 * @param {int} exitCode Exit code of the phase
 * @param {string} id Unique id to the phase
 * @param {string} jobId Job id used for the phase
 * @param {string} phase Phase of the job
 * @param {string} status Status of the job phase {COMPLETE,RUNNING}
 * @param {int} time Timestamp in epoch time when the phase started
 * @param {string} datasource Name of DataSource, may be empty.
 * @param {string} feed Name of the feed, may be empty.
 * @constructor
 */
export function JobStatus(exitCode, id, jobId, phase, status, time, datasource, feed) {
  this._exitCode = exitCode;
  this._id = requireInteger(id, 'id');
  this._jobId = requireNonEmptyString(jobId, 'jobId');
  this._phase = requireNonEmptyString(phase, 'phase');
  this._status = HistoryJobStatus.valueOf(status);
  this._time = requireInteger(time, 'time');
  this._datasource = requireString(datasource, 'datasource');
  this._feed = requireString(feed, 'feed');
  Object.freeze(this);
}

Object.assign(JobStatus.prototype, /** @lends {JobStatus.prototype} */ {
  /** @returns {string} Job status. */
  status() { return this._status; },

  /** @returns {string} Name of a Marketplace Phase. */
  phase() { return this._phase; },
});


/**
 *
 * @param {EnumItem} jobStatus WorkflowHistoryJobStatus
 * @param {number} percentComplete Range 0-100.
 * @constructor
 */
export function HistoryRunStatus(jobStatus, percentComplete) {
  this._jobStatus = jobStatus;
  this._percentComplete = requireInteger(percentComplete, 'percentComplete');
  Object.freeze(this);
}

Object.assign(HistoryRunStatus.prototype, /** @lends {HistoryRunStatus.prototype} */ {
  /** @returns {EnumItem} Job status, to display to users. */
  jobStatus() { return this._jobStatus; },

  /** @returns {number} Percentage of job complete. */
  percentComplete() { return this._percentComplete; },
});

/* ************************************************
 * Public object
 * ************************************************ */

Object.assign(MpDataWorkflow, /** @lends {MpDataWorkflow} */ {

  VarType,

  Config,
  ConfigBuilder: WorkflowConfigBuilder,
  ConfigStatus,
  Dependency,
  TopicDependency,
  KeyArrivalDependency,
  UserAction,
  Task,
  UserActionTask,
  Target,
  Timeout,

  ParameterSet,
  ParameterSetGroup,
  ScheduledJob,
  JobStatus,
  HistoryRunStatus,

  Permission,
  PermissionSet,
  UserPermissions,
  Permissions,

  Report,
  ReportHeader,
  ReportEvent,
  Event: WorkflowEvent,

  /**
     * Compares two workflow objects for the purpose of sorting them by name
     * (case-insensitive).
     * @param {MpDataWorkflow} o1
     * @param {MpDataWorkflow} o2
     * @returns {number}
     * @method
     */
  compare: _newNameComparator(MpDataWorkflow),
});

export default MpDataWorkflow;
export const
  WorkflowConfig = Config;
const WorkflowStatus = ConfigStatus;
const WorkflowVarType = VarType;

const config = {
  API_FEED_DATASOURCES: 'api/users/$[USER_NAME]/datasources',
  API_FEEDS: 'api/providers/LIM/datasources/$[SOURCE_NAME]',
  API_KEYS: 'api/feeds/$[FEED_NAME]/keys?page=$[PAGE]',
  API_COLS: 'api/feeds/$[FEED_NAME]/cols',
  API_METADATA: '/api/feed/$[FEED_NAME]/md',
  API_DATAPREVIEW: '/api/feeds/$[FEED_NAME]/ts',
  API_KEY_SEARCH: '/api/search/keys',
};

export default function getAPIConfigs() {
  return config;
}

import axios from 'axios';

const actions = {
  getResearchReports({ commit }) {
    const webApiUrl = '/api/research/reports';
    return axios.get(webApiUrl)
      .then((response) => {
        commit('ADD_REPORTS', response.data);
        commit('SET_REPORTS_STATUS', 'SUCCESS');
      })
      .catch((error) => {
        console.log('Failure', error);
        commit('SET_REPORTS_STATUS', 'ERROR');
      });
  },
  setReportStatus({ commit }, status) {
    commit('SET_REPORTS_STATUS', status);
  },
};

export default actions;

const mutations = {
  SET_LOGS: (state, logs) => {
    state.auditLogs = logs;
  },
  SET_PAGE_STATUS: (state, status) => {
    state.pageStatus = status;
  },
  SET_DATA_ALERT_LOGS: (state, logs) => {
    state.dataAlertLogs = logs;
  },
  SET_DA_FEEDS: (state, feeds) => {
    state.dataAlertFeeds = feeds;
  },
};

export default mutations;

const { format } = require('logform');
const { MESSAGE } = require('triple-beam');
const map = require('lodash/map');
const mapKeys = require('lodash/mapKeys');
const mapValues = require('lodash/mapValues');
const omit = require('lodash/omit');
const replace = require('lodash/replace');
const snakeCase = require('lodash/snakeCase');

module.exports.standard = format.combine(
  format.timestamp(),
  format.splat(),
);

module.exports.morningstar = format((info, opts) => {
  const { serviceName, env, trace } = opts;

  const {
    level, message, exception,
  } = info;

  const severities = {
    debug: 'debug',
    info: 'low',
    warn: 'medium',
    error: 'high',
    fatal: 'fatal',
  };

  const logAttrs = {
    serviceName,
    env,
    eventSeverity: severities[level],
    eventDescription: message,
    ...omit(info, 'timestamp', 'level', 'message', 'splat', 'exception'),
  };

  if (exception) {
    Object.assign(logAttrs, { exceptionMessage: exception.message });
    if (trace) {
      Object.assign(logAttrs, { exceptionStackTrace: exception.stack });
    }
  }

  const normalizedAttrs = mapValues(
    mapKeys(logAttrs, (v, k) => snakeCase(k)),
    (v) => {
      const singleLine = replace(v, /\n/g, ' ');
      return replace(singleLine, /"/g, '\'');
    },
  );

  const attrMessage = map(normalizedAttrs, (v, k) => `${k}="${v}"`).join(' ');
  info[MESSAGE] = `${info.timestamp} ${attrMessage}`;
  return info;
});

module.exports.request = format((info, opts) => {
  const { request } = opts;
  if (request) {
    info.requestId = request.headers['x-api-requestid'];
    info.requestMethod = request.method;
    info.requestURL = request.originalUrl;
    info.clientIP = request.headers['x-forwarded-for'] || request.connection.remoteAddress;
    info.userName = request.headers['x-user-name'];
    info.companyName = request.headers['x-company-name'];
  }

  return info;
});

module.exports.simple = format((info, opts) => {
  const { trace } = opts;

  const {
    level, message, exception,
  } = info;

  let exceptionMessage = '';
  if (exception) {
    if (trace) {
      exceptionMessage = `\n${exception.stack}`.replace(/\n/g, '\n\t');
    } else {
      exceptionMessage = `(${exception.message})`;
    }
  }

  info[MESSAGE] = `${info.timestamp} ${level.toUpperCase()}: ${message} ${exceptionMessage}`;
  return info;
});

module.exports.tag = format((info, opts) => {
  Object.assign(info, opts);

  return info;
});

/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
export default class FormulaGenerator {
  constructor(bubbleDataset, userVariables, currentFormula) {
    this.bubbleDataset = bubbleDataset;
    this.userVariables = userVariables;
    this.currentFormula = currentFormula;
  }

  formulaHeader() {
    return (
      '\n/*&ANALYZE_VERSION 1.0*/\n\n'
      + '// ///////////////////////////////////////////////////////////////////////\n'
      + '// DO NOT MODIFY THIS FILE; IT IS MAINTAINED BY MARKETS WORKFLOW WIDGET.\n'
      + '// ///////////////////////////////////////////////////////////////////////\n\n'
    );
  }

  formulaBuiltInVars() {
    return (
      '/*&ANALYZE_SUSPEND BUILT_IN_VARIABLES*/\n\n'
      + "var $TIME_ZONE = TimeZone.get(Parameters.getString('workflow.time_zone'));\n"
      + 'var $RUN_DATE = get_run_date();\nvar $TODAY = today($TIME_ZONE);\n'
      + 'var $NOW = now($TIME_ZONE);\n\n'
      + '/*&ANALYZE_RESUME BUILT_IN_VARIABLES*/\n\n'
    );
  }

  formulaUserVarsInputs() {
    const singleVars = this.userVariables;
    let userDefinedFormulaSnippet = '';

    const typeOfFormulaMap = {
      FLOAT: 'getFloat',
      STRING: 'getString',
      JSON: 'getJson',
      DATE: 'getDate',
      BOOLEAN: 'getBool',
    };

    for (let i = 0; i < singleVars.length; i++) {
      const currentVarObj = singleVars[i];
      const { varName, varType } = currentVarObj;
      const currentVar = `let $${varName} = Parameters.${typeOfFormulaMap[varType]}('udef.var.${varName}');\n`;
      userDefinedFormulaSnippet += currentVar;
    }

    console.log(this.bubbleDataset);

    return `/*&ANALYZE_SUSPEND USER_DEFINED_VARIABLES*/\n\n${userDefinedFormulaSnippet}\n/*&ANALYZE_RESUME USER_DEFINED_VARIABLES*/\n\n`;
  }

  getInputDataSetVars(type) {
    const { datasets } = this.bubbleDataset;
    const dataSetInputBubbles = this.bubbleDataset.bubbles.filter(b => b.type === type);

    const dataSetVariableNames = [];

    for (let i = 0; i < dataSetInputBubbles.length; ++i) {
      const bubbleId = dataSetInputBubbles[i].id;
      dataSetVariableNames.push(...datasets.filter(ds => ds.bubbleId === bubbleId));
    }

    return dataSetVariableNames;
  }

  formulaDatasetInputs() {
    let dataSetVarFormula = '';
    const dataSetVariableNames = this.getInputDataSetVars('data');

    for (let i = 0; i < dataSetVariableNames.length; ++i) {
      const element = dataSetVariableNames[i];
      console.log(element);
      const currentVariableFormula = `var $${element.varName} = morn.Product.create(\n`
      + `  Parameters.getString('udef.ds.${element.varName}.feed'),\n`
      + `  Parameters.getJson('udef.ds.${element.varName}.key'),\n`
      + `  Parameters.getJson('udef.ds.${element.varName}.cols')\n`
      + ');\n';

      dataSetVarFormula += currentVariableFormula.trim();
    }

    return `/*&ANALYZE_SUSPEND INPUT_DATASET_DECLARATION*/\n\n${dataSetVarFormula}\n\n/*&ANALYZE_RESUME INPUT_DATASET_DECLARATION*/\n\n`;
  }

  formulaDatasetOutputs() {
    let dataSetVarFormula = '';
    const dataSetVariableNames = this.getInputDataSetVars('save');

    for (let i = 0; i < dataSetVariableNames.length; ++i) {
      const element = dataSetVariableNames[i];
      console.log(element);
      const currentVariableFormula = `var $${element.varName} = morn.Product.create(\n`
      + `  Parameters.getString('udef.ds.${element.varName}.feed'),\n`
      + `  Parameters.getJson('udef.ds.${element.varName}.key'),\n`
      + `  Parameters.getJson('udef.ds.${element.varName}.cols')\n`
      + ');\n';

      dataSetVarFormula += currentVariableFormula.trim();
    }

    return `/*&ANALYZE_SUSPEND OUTPUT_DATASET_DECLARATION*/\n\n${dataSetVarFormula}\n\n/*&ANALYZE_RESUME OUTPUT_DATASET_DECLARATION*/\n\n`;
  }

  formulaBody(formulaBody) {
    if (formulaBody.includes('ANALYZE_SUSPEND FORMULA_BODY')) {
      return `/*&ANALYZE_SUSPEND FORMULA_BODY*/\n\n${this.formulaBodySanitised(formulaBody)}\n\n/*&ANALYZE_RESUME FORMULA_BODY*/\n\n`;
    }
    return `/*&ANALYZE_SUSPEND FORMULA_BODY*/\n\n${formulaBody}\n\n/*&ANALYZE_RESUME FORMULA_BODY*/\n\n`;
  }

  formulaBodySanitised(formulaBody) {
    try {
      return formulaBody
        .split('/*&ANALYZE_SUSPEND FORMULA_BODY*/')[1]
        .trim()
        .split('/*&ANALYZE_RESUME FORMULA_BODY*/')[0]
        .trim();
    } catch {
      return '';
    }
  }

  parseVariablesAtThisPoint(builtInVarsDataset) {
    const varsDataset = [];
    const dataSetVariableNames = this.getInputDataSetVars('data');
    const singleVars = this.userVariables;

    dataSetVariableNames.forEach((varItem) => {
      varsDataset.push({
        varName: `$${varItem.varName}`,
        varType: 'Product',
        varDesc: 'User-defined',
      });
    });

    singleVars.forEach((varItem) => {
      varsDataset.push({
        varName: `$${varItem.varName}`,
        varType: varItem.varType,
        varDesc: 'User-defined',
      });
    });

    varsDataset.push(...builtInVarsDataset);

    return varsDataset;
  }

  formulaPostScript(vars = []) {
    return [
      '',
      '// JSFormulaPreview: Post Script',
      vars.length > 1 ? `as.csv(union(${vars.join(', ')}));` : `${vars[0]}.toString();`,
    ].join('\n');
  }

  generate(newFormulaBody) {
    this.currentFormula = newFormulaBody;
    return [
      this.formulaHeader(),
      this.formulaUserVarsInputs(),
      this.formulaBuiltInVars(),
      [
        '/*&ANALYZE_SUSPEND FORMULA_BODY*/\n',
        newFormulaBody,
        '\n/*&ANALYZE_RESUME FORMULA_BODY*/',
      ].join('\n'),
    ].join('\n');
  }
}

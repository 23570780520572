const config = {
  SEARCH_WORKFLOWS: 'api/workflows/search?name=$[name]&feed=$[feed]&symbol=$[symbol]'
    + '&owner=$[owner]&company=$[company]&id=$[id]&formula=$[formula]',
  GET_WORKFLOWS_FOR_USER: 'api/workflows/$[username]',
  GET_WORKFLOW_STATUS: 'api/workflows/$[workflowId]/status',
  UPDATE_USER_WORKFLOWS: 'api/workflows/$[username]/update',
  GET_FORMULA_FOR_ID: 'api/workflows/formulas/id/$[id]',
  UPDATE_USER_WORKFLOW_DIAGRAM: 'api/workflows/updateDiagram',
  UPDATE_FORMULA: 'api/workflows/formulas',
  UPDATE_BUBBLE_DATASET: 'api/workflows/$[workflowId]',
  GET_ALL_WORKFLOWS: 'api/workflows/getAllWorkflows',
  RUN_FORMULA: 'api/workflows/formulas/run',
  UPDATE_WORKFLOW_BY_ID: 'api/workflows/updateById/$[workflowId]',
  SAVE_PARAMETRSET_MODEL: 'api/workflows/saveParametrset',
  CREATE_NEW_WORKFLOW: 'api/workflows/createNewWorkflow',
  CHANGE_WORKFLOW_OWNER: 'api/workflows/$[workflowId]/owner/$[newOwnerName]',
  GET_PARAMETER_SET_MODELS: 'api/workflows/$[workflowId]/parameterSetModel',
  GET_PERMISSIONS_BY_WORKFLOW_ID: 'api/workflows/$[workflowId]/permissions',
  UPDATE_PERMISSIONS_BY_WORKFLOW_ID: 'api/workflows/$[workflowId]/permissions',
  GET_FEEDS_FOR_USER: '/api/users/$[username]/feeds',
  GET_FEED_DETAILS: '/api/feeds/details/$[feedname]',
  UPDATE_WORKFLOW_SCHEDULE: '/api/workflows/$[workflowId]/updateSchedule',
  RUN_JS_FORMULA: '/api/workflows/runJSFormula',
};

export default function getAPIConfigs() {
  return config;
}

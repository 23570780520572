<template>
  <div>
    <router-link
      :to="{path: '../../feeds/new'}"
      class="mds-list-group__link hide-actions"
      replace
    >
      <button
        class="mds-button mds-button--flat"
        type="button"
      >
        <svg
          class="mds-icon mds-button__icon mds-button__icon--left"
          aria-hidden="true"
        >
          <use xlink:href="#plus" />
        </svg>
        <span
          class="mds-button__text mcd-page__content-hide--600"
        >{{ $t('labels.feed.newFeed') }}</span>
      </button>
    </router-link>
    <router-link
      :to="{path: '../../feeds'}"
      class="mds-list-group__link hide-actions"
      replace
    >
      <button
        class="mds-button mds-button--flat"
        type="button"
      >
        <svg
          class="mds-icon mds-button__icon mds-button__icon--left"
          aria-hidden="true"
        >
          <use xlink:href="#list" />
        </svg>
        <span
          class="mds-button__text mcd-page__content-hide--600"
        >{{ $t('labels.feed.feedList') }}</span>
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'QuickLinks',
};
</script>

import Vue from 'vue';
import vueScrollto from 'vue-scrollto';
import uuid from 'uuid/v1';
import axios from 'axios';
import MwcApplicationHelper from 'mwc-application-helper';
import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import VueApollo from 'vue-apollo';
import { Workbox } from 'workbox-window';
import VueObserveVisibility from 'vue-observe-visibility';
import CodeDiff from 'v-code-diff';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import { AppName } from './utils/constants';
import { getUserName, getUserCompany, setupMpCookie } from './utils/authService';
import MwcBasePlugin from '../packages/mwc-vue-shared/plugins/the-mwc-base-helper';
import LoginLayout from './components/Layouts/LoginLayout';
import Auth from './mixins/auth';
import filters from './filters';

const loggerInstance = require('../server/logger');
const { standard } = require('../server/logger/formats');

const logger = loggerInstance([
  standard,
]);

logger.info('Initiating Main');
const cache = new InMemoryCache();

const EventBus = new Vue();
export default EventBus;

Vue.prototype.$eventBus = new Vue();
Vue.use(vueScrollto, { offset: -120 });
MwcApplicationHelper.initializeMwc();
Vue.use(MwcBasePlugin);
Vue.use(filters);
Vue.use(CodeDiff);
Vue.use(VueObserveVisibility);
Vue.mixin(Auth);
axios.interceptors.response.use((response) => {
  // after successful verification, set MP cookie for seamless login
  if (response.config.url.includes('/api/verify') && Object.prototype.hasOwnProperty.call(response.data, 'token')) {
    setupMpCookie(response.data.token);
  }
  return response;
}, (error) => {
  const res = error.response;
  if (res && res.status === 401) {
    const isSessionExpired = store.state.loginModule.isAuthenticated;
    if (!(isSessionExpired || res.config.url.includes('/api/verify'))) {
      store.dispatch('loginModule/signOut');
      router.push(`login?session_expired=true&return_to=${router.currentRoute.path}`);
      EventBus.$emit('layoutChange', LoginLayout);
    }
  }
  return Promise.reject(error);
});
axios.interceptors.request.use((config) => {
  // Do something before request is sent
  const headers = {};
  headers['X-API-RequestId'] = uuid();
  headers['X-User-Agent'] = AppName;

  // don't include custom headers for CORS MP requests
  if (!config.url.includes('/marketplace')) {
    if (store.state.homeModule.userName) {
      const userName = getUserName();
      if (userName !== null) {
        headers['X-User-Name'] = userName;
      }
    }
    headers['X-Company-Name'] = getUserCompany();
  }
  Object.assign(config.headers, headers);
  return config;
}, error => Promise.reject(error));

Vue.config.productionTip = false;

Vue.use(VueApollo);
Vue.prototype.$logger = logger;
const apolloClient = new ApolloClient({
  cache,
  uri: process.env.VUE_APP_GRAPGHQL_SERVER,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'development') {
  console.warn = () => {};
  console.log = () => {};
}

if ('serviceWorker' in navigator) {
  console.log('Registering Service Worker');
  const wb = new Workbox('sw.js');
  wb.register();
} else {
  console.log('Did not register Service Worker');
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  apolloProvider,
}).$mount('#app');

window.Vue = Vue;
logger.info('App initiated');
// Auto-install when vue is found (eg. in browser via <script> tag)
let GlobalVue = null;
if (typeof window !== 'undefined') {
  GlobalVue = window.Vue;
} else if (typeof global !== 'undefined') {
  GlobalVue = global.Vue;
}

<template>
  <div>
    <mcd-card-section
      :key="$root.$i18n.locale"
      item-type="developer"
    />
  </div>
</template>

<script>
import McdCardSection from '../components/McdCard/McdCardSection';


export default {
  name: 'Developer',
  components: { McdCardSection },
};
</script>

<template>
  <div>
    <table class="mds-data-table mds-data-table--large">
      <tbody class="mds-data-table__body">
        <tr>
          <td class="mds-data-table__cell">
            <mds-button
              :id="'addFeed-btn' + index"
              icon="person-plus"
              variation="secondary"
              type="button"
              @click="addFeedPopOver(group)"
            >
              {{ $t('labels.feedGroup.addFeeds') }}
            </mds-button>
            <mds-popover
              :id="'addFeed' + index"
              :ref="'addFeed' + index"
              :triggered-by="'addFeed-btn' + index"
              position="right-center"
              :title="'Add Feed-' + index"
              title-hidden
              width="300px"
            >
              <div class="account-menu__user-info">
                <template v-if="noFeedError">
                  <mds-field-error
                    id="error-id"
                    visible
                  >
                    {{ $t('labels.feedGroup.noFeedLeftError') }}
                  </mds-field-error>
                </template>
                <template v-else>
                  <div class="mds-form__select demo-width-100">
                    <select
                      :id="'feedList' + index"
                      :ref="'feedList' + index"
                      class="mds-form__select-input col-75"
                    >
                      <option
                        v-for="(feed, index3) in feedListForGroup "
                        :key="index3"
                        class="mds-form__select-option"
                        :value="feed"
                      >
                        {{ feed }}
                      </option>
                    </select>
                    <div class="mds-form__select-visual-wrap" />
                    <span class="mds-form__select-open-indicator">
                      <svg
                        class="mds-icon mds-form__select-open-icon"
                        aria-hidden="true"
                      >
                        <use xlink:href="#caret-down--s" />
                      </svg>
                    </span>
                  </div>
                  <div
                    class="account-menu__action"
                    style="margin-top:10px"
                  >
                    <mds-button
                      variation="primary"
                      @click="addFeedToGroup(group,index)"
                    >
                      {{ $t('labels.feedGroup.addFeeds') }}
                    </mds-button>
                  </div>
                </template>
              </div>
            </mds-popover>
          </td>
          <td class="mds-data-table__cell">
            <mds-button
              v-if="group.isSaved"
              :id="'editFeed-btn' + index"
              icon="person-plus"
              variation="secondary"
              type="button"
              @click="showFeedEnt"
            >
              {{ $t('labels.feedGroup.editEntitlements') }}
            </mds-button>
          </td>
          <td class="mds-data-table__cell mds-data-table__cell--right">
            <mds-button
              icon="trash"
              variation="secondary"
              @click="deleteGroup(index)"
            >
              {{ $t('labels.group.deleteGroupButton') }}
            </mds-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <transition name="slide">
        <template v-if="showEnt">
          <FeedGroupEntilements
            :group-name="group.groupName"
            :group-feeds="group.feedNames"
          />
        </template>
      </transition>
    </div>
  </div>
</template>
<script>
import FeedGroupEntilements from './FeedGroupEntitlements.vue';
import EventBus from '../../main';

export default {
  name: 'GroupAction',
  components: { FeedGroupEntilements },
  props: {
    group: { type: Object, default: null },
    index: { type: Number, default: null },
    userFeeds: { type: Array, default: null },
    isSaved: { type: Boolean, default: false },
  },
  data() {
    return {
      feedListForGroup: '',
      noFeedError: false,
      showEnt: false,
    };
  },
  created() {
    EventBus.$on('hideFeedEntitlements', (value) => {
      this.showEnt = value;
    });
  },
  methods: {
    addFeedPopOver(group) {
      this.noFeedError = false;
      this.feedListForGroup = this.userFeeds.slice().sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .filter(feed => feed !== '');
      if (group.feedNames) {
        group.feedNames.forEach((element) => {
          this.feedListForGroup.splice(this.feedListForGroup.indexOf(element), 1);
        });
      }
      if (this.feedListForGroup.length === 0) {
        this.noFeedError = true;
      }
    },
    addFeedToGroup(group, index) {
      const userid = this.$refs[`feedList${index}`].value;
      group.feedNames.push(userid);
      this.feedListForGroup.splice(this.feedListForGroup.indexOf(userid), 1);
      const pop = this.$refs[`addFeed${index}`];
      pop.dismiss();
    },
    deleteGroup(index) {
      this.$parent.deleteGroup(index);
    },
    showFeedEnt() {
      this.showEnt = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/styles/components/custom/transition";
</style>

<template>
  <mds-layout-grid>
    <mds-row>
      <mds-col
        :cols-at-m="2"
        :cols="12"
      >
        <label
          class="mds-form__label key-selection__label custom-margin"
          for="datasource"
        >
          {{ $t('labels.feed.dataSource') }}
        </label>
      </mds-col>
      <mds-col
        :cols-at-m="9"
        :cols="12"
      >
        <mds-combo-box
          ref="dataSourceComboRef"
          label=""
          placeholder="Select Data Source"
          :on-dark="darkMode"
          :data-set="dataSourceOptions"
          @input="getSourceName"
        />
      </mds-col>
    </mds-row>

    <div class="custom-row-margin" />

    <mds-row>
      <mds-col
        :cols-at-m="2"
        :cols="12"
      >
        <label
          class="mds-form__label key-selection__label custom-margin"
          for="datasource"
        >
          {{ $t('labels.feed.feedName') }}
        </label>
      </mds-col>
      <mds-col
        :cols-at-m="9"
        :cols="12"
      >
        <mds-combo-box
          ref="feedComboRef"
          :key="selectedDataSource"
          label=""
          placeholder="Select Feed Name"
          :data-set="feedOptions"
          :on-dark="darkMode"
          @input="getKeyFilters"
        />
      </mds-col>
    </mds-row>

    <div class="custom-row-margin" />

    <mds-row>
      <mds-col
        :cols-at-m="2"
        :cols="12"
      >
        <label
          class="mds-form__label key-selection__label custom-margin"
          for="datasource"
        >
          {{ $t('labels.common.type') }}
        </label>
      </mds-col>

      <mds-col
        :cols-at-m="3"
        :cols="12"
      >
        <label
          data-mds-version="@mds/radio-button-3.0.11"
          class="mds-radio-button___Mcd-ui"
        >
          <input
            ref="timeSeries"
            v-model="chartType"
            name="type"
            type="radio"
            class="mds-radio-button__input___Mcd-ui"
            value="timeSeries"
            checked
            @click="typeSelection"
          >
          <span class="mds-radio-button__visible-wrap___Mcd-ui">
            <span class="mds-radio-button__visual___Mcd-ui" />
            <span class="mds-radio-button__text___Mcd-ui">
              {{ $t('labels.common.timeSeries') }}
            </span>
          </span>
        </label>
      </mds-col>

      <mds-col
        :cols-at-m="3"
        :cols="12"
      >
        <label
          data-mds-version="@mds/radio-button-3.0.11"
          class="mds-radio-button___Mcd-ui"
        >
          <input
            ref="forwardCurve"
            v-model="chartType"
            name="type"
            type="radio"
            class="mds-radio-button__input___Mcd-ui"
            value="forwardCurve"
            :disabled="enabled"
            @click="typeSelection"
          >
          <span class="mds-radio-button__visible-wrap___Mcd-ui">
            <span class="mds-radio-button__visual___Mcd-ui" />
            <span class="mds-radio-button__text___Mcd-ui">
              {{ $t('labels.common.forwardCurve') }}
            </span>
          </span>
        </label>
      </mds-col>
    </mds-row>

    <div class="custom-row-margin" />

    <mds-row>
      <mds-loader
        v-show="keyLoader"
        :on-dark="darkMode"
      />
      <template v-for="(keyColumn, itemIndex) in keyColumns">
        <mds-row
          v-show="hasKeys && hasKeyValues == 'symbolTable'"
          :key="itemIndex + keyColumn"
        >
          <div class="custom-space" />
          <mds-col :col="2">
            <mds-input
              :id="keyColumn.fieldName"
              :ref="keyColumn.fieldName"
              hidden-label
              label="Hidden Label"
              :on-dark="darkMode"
              @keyup="handleFilter($event)"
            />
          </mds-col>
        </mds-row>
      </template>
    </mds-row>

    <mds-row v-show="!enabled && hasKeyValues == 'rootContracts'">
      <mds-col
        :cols-at-m="1"
        :cols="12"
      />
      <mds-col
        :cols-at-m="2"
        :cols="12"
      >
        <mds-input
          id="roots"
          ref="roots"
          hidden-label
          label="Hidden Label"
          :on-dark="darkMode"
          @keyup="filterRoots"
        />
      </mds-col>
    </mds-row>

    <div class="custom-row-margin" />

    <mds-row>
      <mds-col>
        <div class="custom-margin-container">
          <span class="caption">{{ $t('dashboard.common.symbol') }}</span>
          <div
            class="container"
            @scroll.passive="onScroll"
          >
            <mds-loader
              v-if="hasKeyValues == 'loader' || loadMoreItems"
              :on-dark="darkMode"
              :class="{ infinityLoader: loadMoreItems }"
            />
            <mds-data-table
              v-if="hasKeyValues == 'symbolTable'"
              id="symbolTableId"
              :on-dark="darkMode"
              :responsive="respond"
              multiselection
              row-hover
              :header-configs="headersKey"
              :row-data="rowsKey"
              @mds-data-table-row-selection-change="onSymbolSelect"
            />
            <mds-data-table
              v-if="hasKeyValues == 'rootContracts'"
              id="rootContractsId"
              :on-dark="darkMode"
              :responsive="respond"
              multiselection
              row-hover
              :header-configs="rootHeaders"
              :row-data="rootRows"
              @mds-data-table-row-selection-change="onRootSelect"
            />
          </div>
        </div>
      </mds-col>
    </mds-row>

    <div class="custom-row-margin" />

    <mds-row>
      <mds-col>
        <selected-keys
          :searched-rows="searchedRows"
          :selected-rows="selectedRows"
          :rows-key="rowsKey"
          :dark-mode="darkMode"
          :selected-feed-name="selectedFeedName"
          :root-rows="rootRows"
          @updatedSelectedCols="updatedSelectedCols"
          @rowsKeySelection="rowsKeySelection"
          @searchedRowsSelection="searchedRowsSelection"
          @rootRowsSelection="rootRowsSelection"
          @deleteSelectedRows="deleteSelectedRows"
        />
      </mds-col>
    </mds-row>
  </mds-layout-grid>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { MdsDataTable } from '@mds/data-table-v3';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsLoader from '@mds/loader';
import MdsComboBox from '@mds/combo-box';
import uuidv4 from 'uuid/v4';
import MdsInput from '@mds/input';
import EventBus from '../../../main';
import SelectedKeys from './SelectedKeys.vue';
import { ThemeMixin } from '../../../mixins/themeMixin';

export default {
  name: 'KeysSelection',
  components: {
    MdsDataTable,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsLoader,
    MdsComboBox,
    SelectedKeys,
    MdsInput,
  },
  mixins: [ThemeMixin],
  props: {
    searchedRows: {
      type: Array,
      default: () => [],
    },
    searchDataArr: {
      type: Array,
      default: () => [],
    },
    dataSourceOptions: {
      type: Array,
      default: () => [],
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      respond: false,
      searchTimer: null,
      feedOptions: [],
      selectedFeedName: '',
      selectedDataSource: '',
      keyLoader: false,
      hasKeys: false,
      headersKey: [],
      rowsKey: [],
      hasKeyValues: '',
      keyCodes: [9, 13, 16, 17, 18, 27, 45, 122, 144, 20, 27],
      page: 1,
      loadMoreItems: false,
      generateId: uuidv4(),
      totalRecords: 0,
      noOfPages: 0,
      resultLimt: 50,
      keyInputFlag: false,
      selectedRowsWColumns: [],
      enabled: true,
      rootHeaders: [],
      chartType: '',
      rootRows: [],
      activeRootRows: [],
      friendlyFeed: false,
    };
  },
  computed: {
    ...mapGetters({
      feedList: 'feedModule/getUserFeedList',
      feedDetails: 'feedModule/getFeedDetails',
      pageStatus: 'feedModule/getPageStatus',
      keyColumns: 'feedModule/getKeyColumns',
      valueColumns: 'feedModule/getValueColumns',
      activeFeedName: 'feedModule/getActiveFeedname',
      rootContracts: 'feedModule/getRoots',
    }),
  },
  watch: {
    selectedRows: {
      handler(data) {
        this.selectedRowsWColumns = data; // update a spare list to be modified with selections
      },
      deep: true,
    },
  },
  created() {
    EventBus.$on('onSearchRowSelect', (selected) => {
      this.onSearchRowSelect(selected);
    });
    EventBus.$on('getKeyFilters', () => {
      this.getKeyFilters([this.selectedFeedName]);
    });
    this.chartType = 'timeSeries';
    window.showMetadata = this.showMetadata.bind(this);
  },

  methods: {
    ...mapActions('feedModule', ['getFeedDetailsByName', 'getKeyValuesList', 'getCurves']),
    // eslint-disable-consistent-return
    getSourceName(event) {
      this.hasKeys = false;
      this.enabled = true;
      const sourceName = event[0];
      this.selectedDataSource = sourceName;
      this.feedOptions = this.feedList
        .filter(element => element.datasource === sourceName)
        .flatMap(element => element.feeds)
        // eslint-disable-next-line max-len
        .map(element => ({
          text:
            `${this.friendlyFeed ? element.name
              .substring(element.name.indexOf('_') + 1)
              .match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g)
              .join(' ') : element.name
            } ${
              element.description.startsWith(`${sourceName }:`)
                ? element.description.replace(sourceName, '') : `: ${ element.description}`}`,
          value: element.name,
        }))
        .sort((a, b) => a.text.localeCompare(b.text), undefined, {
          sensitivity: 'base',
        });
    },

    getKeyFilters(event) {
      const feedName = event[0];
      this.selectedFeedName = feedName;
      this.keyLoader = true;
      this.hasKeys = false;
      this.enabled = true;
      this.chartType = 'timeSeries';
      if (this.selectedFeedName) {
        this.getFeedDetailsByName(this.selectedFeedName).then(() => {
          this.keyLoader = false;
          this.hasKeys = true;
          this.filterKeys(true);
          this.getCurves(feedName).then((response) => {
            if (response.data.root.length > 0) {
              this.enabled = false;
            }
          });
        });
      } else {
        this.keyLoader = false;
        this.hasKeys = false;
      }
    },

    handleFilter(event) {
      const keyCode = event.keyCode || event.which;
      // Don't validate the input if below arrow,tab capslock,shift and ctrl keys were pressed
      if (this.keyCodes.includes(keyCode) || (keyCode >= 35 && keyCode <= 40)) {
        return false;
      }
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => this.filterKeys(false), 3000);
      return true;
    },
    filterKeys(filterFlag) {
      this.page = 1;
      this.totalRecords = 0;
      this.noOfPages = 0;
      this.hasKeyValues = 'loader';
      this.rowsKey = [];
      this.headersKey = [];
      this.getKeysList(filterFlag);
    },

    getKeysList(flag) {
      let keyString = '';
      this.keyColumns.forEach((element) => {
        if (flag) {
          this.$refs[element.fieldName][0]['$el'].children[1].value = '';
        }
        const keyInputValue = typeof this.$refs[element.fieldName][0]['$el'].children[1].value === 'undefined'
          ? ''
          : this.$refs[element.fieldName][0]['$el'].children[1].value;
        keyString += `${element.fieldName}=${keyInputValue}&`;
        if (this.page === 1) {
          this.headersKey.push({ text: element.fieldName, fieldName: element.fieldName });
        }
      });
      if (this.page === 1) {
        this.headersKey.push({ text: 'Description', fieldName: 'description' });
        this.headersKey.push({ text: '', fieldName: '' });
      }
      keyString = keyString.concat(`limit=${this.resultLimt}&page=${this.page}&desc=true`);
      /* eslint-disable */
      this.getKeyValuesList({ feedName: this.activeFeedName, keys: keyString }).then(response => {
        this.hasKeyValues = 'symbolTable';
        const responseObj = [];
        if (response.data) {
          response.data.forEach((element, index) => {
            if (element.desc === 'totalRecords') {
              if (this.totalRecords === 0) {
                this.totalRecords = element.keys[0].value;
                this.noOfPages = Math.ceil(this.totalRecords / this.resultLimt);
                return '';
              }
              return '';
            }
            const keyObj = {};
            let keysValue = '';
            let keysValues = '';
            let keysValueEncoded = '';
            element.keys.forEach(key => {
              keyObj[key.key] = key.value;
              keysValue += `${key.key}=${key.value},`;
              keysValues += `${key.key}=${key.value}&`
            });
            keysValue = keysValue.slice(0, -1);
            keysValues = keysValues.slice(0, -1);
            keysValueEncoded = encodeURIComponent(keysValues).replace(/[!'()*]/g, escape);
            keyObj['id'] = ++index;
            if (
              this.selectedRows.findIndex(
                item => item.symbol === keysValue && item.feed === this.activeFeedName
              ) !== -1
            ) {
              keyObj['selected'] = true;
            } else {
              keyObj['selected'] = false;
            }
            keyObj['description'] = element.desc;
            keyObj[
              ''
            ] = `<a href='javascript:showMetadata("${keysValueEncoded}", "${this.activeFeedName}","timeSeries")' class="mds-list-group__link" id="metadataHref"><mds-icon name="info-circle" /></a>`;
            this.rowsKey.push(keyObj);
          });
          this.loadMoreItems = false;
        }
      });
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (
        !this.loadMoreItems &&
        this.hasKeyValues === 'symbolTable' &&
        scrollTop + clientHeight >= scrollHeight - 100
      ) {
        if (this.page <= this.noOfPages) {
          this.loadMoreItems = true;
          this.page++;
          this.getKeysList(false);
        }
      }
    },

    async onSymbolSelect(selected) {
      const selectedObj = this.rowsKey.find(obj => obj.id === selected.id);
      let keysValue = '';
      let keysValues = '';
      let keys = '';
      let options = [];
      if (selected.checked) {
        Object.entries(selectedObj).forEach(([key, value]) => {
          if (key !== 'id' && key !== 'selected' && key !== 'description') {
            keysValue += `${key}=${value},`;
            keysValues += `${key}=${value}&`;
            keys += `${key},`;
          }
        });
        keysValue = keysValue.slice(0, -1);
        keysValues = keysValues.slice(0, -1);
        keysValue = keysValue.substring(0, keysValue.lastIndexOf(',='));
        keysValues = keysValues.substring(0, keysValues.lastIndexOf('&='));

        keys = keys.slice(0, -1);
        if (selectedObj) {
          if (
            !this.selectedRows.find(
              element => element.symbol === keysValue && element.feed === this.activeFeedName
            )
          ) {
            if (this.activeFeedName !== this.selectedFeedName) {
              this.getFeedDetailsByName(this.selectedFeedName).then(async () => {
                options = this.loadOptionColumns(this.selectedFeedName);
                this.selectedRows.push({
                  keyName: keys,
                  keyValue: keysValue,
                  symbol: keysValue,
                  columns: options,
                  selectedColumns: [options[0]['text']],
                  feed: this.selectedFeedName,
                  id: selected.id,
                  feedDefinition: this.feedDetails,
                  keyType: 'symbolKeys',
                  keysValues:keysValues
                });
              });
            } else {
              options = this.loadOptionColumns(this.selectedFeedName);
              this.selectedRows.push({
                keyName: keys,
                keyValue: keysValue,
                symbol: keysValue,
                columns: options,
                selectedColumns: [options[0]['text']],
                feed: this.selectedFeedName,
                id: selected.id,
                feedDefinition: this.feedDetails,
                keyType: 'symbolKeys',
                keysValues:keysValues
              });
            }
          }
          this.$emit('selectedRows', this.selectedRows);
        }
      } else {
        const objIndex = this.selectedRows.findIndex(
          item =>
            item.id === selected.id &&
            item.feed === this.selectedFeedName &&
            item.keyType === 'symbolKeys'
        );
        if (objIndex !== -1) {
          this.selectedRows.splice(objIndex, 1);
        }
      }
    },
    updatedSelectedCols({ symbol, feed, data }) {
      for (let i = 0, len = this.selectedRows.length; i < len; i++) {
        if (this.selectedRows[i]['feed'] === feed && this.selectedRows[i]['symbol'] === symbol) {
          if (data) {
            this.selectedRows[i].selectedColumns = [];
          }

          for (let j = 0; j < this.selectedRows[i].columns.length; j += 1) {
            for (let a = 0; a < data.length; a += 1) {
              if (this.selectedRows[i].columns[j]['text'] === data[a]) {
                this.selectedRows[i].selectedColumns.push(this.selectedRows[i].columns[j]['text']);
              }
            }
          }
        }
      }
    },
    async onSearchRowSelect(selected) {
      if (this.searchDataArr.length !== 0) {
        let options = [];
        const selectedObj = this.searchDataArr.find(obj => obj.id === selected.id);
        if (selected.checked) {
          if (
            !this.selectedRows.find(
              element =>
                element.symbol === selectedObj.keysAndValues &&
                element.feed === selectedObj.feedName
            )
          ) {
            const symbolObj = selectedObj.keysAndValues.includes('=')
              ? selectedObj.keysAndValues.split('=')
              : keysValue;
            if (this.activeFeedName !== selectedObj.feedName) {
              this.getFeedDetailsByName(selectedObj.feedName).then(async () => {
                options = this.loadOptionColumns(selectedObj.feedName);
                this.selectedRows.push({
                  keyName: symbolObj[0] || selectedObj.keysAndValues,
                  keyValue: symbolObj[1],
                  symbol: selectedObj.keysAndValues,
                  feedDefinition: this.feedDetails,
                  selectedColumns: [options[0]['text']],
                  columns: options,
                  feed: selectedObj.feedName,
                  id: selected.id,
                  keyType: 'searchKeys',
                });
              });
            } else {
              options = this.loadOptionColumns(selectedObj.feedName);
              this.selectedRows.push({
                keyName: symbolObj[0] || selectedObj.keysAndValues,
                keyValue: symbolObj[1],
                symbol: selectedObj.keysAndValues,
                columns: options,
                feed: selectedObj.feedName,
                feedDefinition: this.feedDetails,
                selectedColumns: [options[0]['text']],
                id: selected.id,
                keyType: 'searchKeys',
              });
            }
            this.$emit('selectedRows', this.selectedRows);
          }
        } else {
          const objIndex = this.selectedRows.findIndex(
            item =>
              item.id === selected.id &&
              item.feed === selectedObj.feedName &&
              item.keyType === 'searchKeys'
          );
          if (objIndex !== -1) {
            this.selectedRows.splice(objIndex, 1);
          }
        }
      }
    },
    typeSelection(event) {
      this.rootRows = [];
      this.rootHeaders = [];
      this.hasKeyValues = 'loader';
      if (event.target.value === 'forwardCurve') {
        this.loadRootContracts();
        this.activeRootRows = this.rootRows;
      } else {
        this.hasKeyValues = 'symbolTable';
      }
    },
    onRootSelect(selected) {
      const selectedObj = this.rootRows.find(obj => obj.id === selected.id);
      let options = [];
      let keysValue = '';
      if (selected.checked) {
        keysValue = 'root=' + selectedObj.root;
        if (
          !this.selectedRows.find(
            element => element.symbol === keysValue && element.feed === this.activeFeedName
          )
        ) {
          if (this.activeFeedName !== this.selectedFeedName) {
            this.getFeedDetailsByName(this.selectedFeedName).then(async () => {
              options = this.loadOptionColumns(this.selectedFeedName);
              this.selectedRows.push({
                keyName: 'root',
                keyValue: keysValue,
                symbol: keysValue,
                columns: options,
                selectedColumns: [options[0]['text']],
                feed: this.selectedFeedName,
                id: selected.id,
                feedDefinition: this.feedDetails,
                keyType: 'rootKeys',
              });
            });
          } else {
            options = this.loadOptionColumns(this.selectedFeedName);
            this.selectedRows.push({
              keyName: 'root',
              keyValue: keysValue,
              symbol: keysValue,
              columns: options,
              selectedColumns: [options[0]['text']],
              feed: this.selectedFeedName,
              id: selected.id,
              feedDefinition: this.feedDetails,
              keyType: 'rootKeys',
            });
          }
        }
      } else {
        const objIndex = this.selectedRows.findIndex(
          item =>
            item.id === selected.id &&
            item.feed === this.selectedFeedName &&
            item.keyType === 'rootKeys'
        );
        if (objIndex !== -1) {
          this.selectedRows.splice(objIndex, 1);
        }
      }
    },
    filterRoots(event) {
      this.rootRows = this.activeRootRows.filter(
        item => item.root.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
      );
    },
    loadRootContracts() {
      let keysValue = '';
      this.rootHeaders.push({ text: 'root', fieldName: 'root' });
      this.rootHeaders.push({ text: '', fieldName: '' });
      this.rootContracts.root.forEach((element, index) => {
        const keyObj = {};
        keysValue = 'root=' + element;
        keyObj['root'] = element;
        keyObj['id'] = ++index;
        keyObj['keyType'] = 'rootKeys';
        if (
          this.selectedRows.findIndex(
            item => item.symbol === keysValue && item.feed === this.activeFeedName
          ) !== -1
        ) {
          keyObj['selected'] = true;
        } else {
          keyObj['selected'] = false;
        }
        keyObj[
          ''
        ] = `<a href='javascript:showMetadata("${keysValue}", "${this.activeFeedName}","forward")' class="mds-list-group__link" ><mds-icon name="info-circle" /></a>`;
        this.rootRows.push(keyObj);
      });
      this.hasKeyValues = 'rootContracts';
    },
    loadOptionColumns(feedName) {
      let options = [];
      this.valueColumns.forEach(element => {
        options.push({
          text: element.fieldName,
          value: element.fieldName,
          feed: feedName,
        });
      });
      return options;
    },
    showMetadata(symbol,feed,curveType){
      EventBus.$emit('showMetadataDialog', { symbol: symbol, feed: feed, curveType: curveType });
    },
    rowsKeySelection(value,index){
      this.rowsKey[index].selected = value;
    },
    searchedRowsSelection(value,index){
      this.searchedRows[index].selected = value;
    },
    rootRowsSelection(value,index){
      this.rootRows[index].selected = value;
    },
    deleteSelectedRows(){
      this.selectedRows.splice(
        this.selectedRows.findIndex(item => item.symbol === row.symbol && item.feed === row.feed),
        1,
      );
    },
  },
};
</script>

<style lang="scss">
.key-selection__label {
  color: var(--dynamic-subtitle-color);
}
</style>

// define a mixin object
// eslint-disable-next-line import/prefer-default-export
export const McdValidationMixin = {
  methods: {
    watchModelChange() {
      const requiredText = 'This Field is required';
      const minText = 'Minimum characters is 2';
      const maxText = 'Maximum characters is 20';
      const SpecialCharacters = 'Should not contains special characters';
      // eslint-disable-next-line no-unused-expressions
      this.$v.touch;
      if (!this.$v.name.required && this.errors.indexOf(requiredText) === -1) {
        this.errors.push(requiredText);
      } else if (this.$v.name.required && this.errors.indexOf(requiredText) !== -1) {
        this.errors.splice(this.errors.indexOf(requiredText), 1);
      }
      if (!this.$v.name.minLength && this.errors.indexOf(minText) === -1) {
        this.errors.push(minText);
      } else if (this.$v.name.minLength && this.errors.indexOf(minText) !== -1) {
        this.errors.splice(this.errors.indexOf(minText), 1);
      }
      if (!this.$v.name.maxLength && this.errors.indexOf(maxText) === -1) {
        this.errors.push(maxText);
      } else if (this.$v.name.maxLength && this.errors.indexOf(maxText) !== -1) {
        this.errors.splice(this.errors.indexOf(maxText), 1);
      }
      if (!this.$v.name.specialCharacterValidator && this.errors.indexOf(SpecialCharacters) === -1) {
        this.errors.push(SpecialCharacters);
      } else if (this.$v.name.specialCharacterValidator && this.errors.indexOf(SpecialCharacters) !== -1) {
        this.errors.splice(this.errors.indexOf(SpecialCharacters), 1);
      }
    },
  },
};

import { render, staticRenderFns } from "./WorkflowSearch.vue?vue&type=template&id=b156e532"
import script from "./WorkflowSearch.vue?vue&type=script&lang=js"
export * from "./WorkflowSearch.vue?vue&type=script&lang=js"
import style0 from "./WorkflowSearch.vue?vue&type=style&index=0&id=b156e532&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
/* eslint-disable camelcase */
/* eslint-disable indent */

import {
  _mathExprToJsCode, _genMdExtraMeth_follow_dataset, _genMdExtraMeth_repeat_last_cycle,
  _genCodeExtraMeth_follow_dataset, _genCodeExtraMeth_repeat_last_cycle,
} from './utils/mpfstep/mpfstep';
import Strings from './utils/strings.es6';
import MpFormulaStepText from './utils/mpfstep/lang/mpfstep_en-us.es6';

function getFormattedDate(date) {
  const year = date.getFullYear();
  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${ month}`;
  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${ day}`;

  return `${year}-${month}-${day}`;
}

function generateForwardCurveFormulaFromConfig(config) {
  const { variableName } = config;
  const { includeNans } = config;
  const { curveDaysBackValue } = config;
  const { curveFixedDate } = config;
  const { skipWeekends } = config;
  const curveDate = config.curveDate[0];
  const arbitrageFreeBasic = config.arbitrageFree.basic;
  const product = config.product[0];
  const delivery = config.delivery[0];
  const method = config.method[0];

  let formulaFunction = '';
  let runDateParam = '';

  let formula = '';

  if (method === 'Arbitrage-free') {
    if (arbitrageFreeBasic) {
      formulaFunction = 'forward_curve_arbitrage_free_basic';
    } else {
      formulaFunction = 'forward_curve_arbitrage_free';
    }
  } else {
    formulaFunction = 'forward_curve';
  }

  if (curveDate === 'today-minus') {
    if (!skipWeekends) {
      runDateParam = `$RUN_DATE.addDays(${-curveDaysBackValue})`;
    } else {
      runDateParam = `add_business_days($RUN_DATE, ${-curveDaysBackValue})`;
    }
  } else {
    runDateParam = `as.date("${getFormattedDate(curveFixedDate)}")`;
  }

  formula = [
    `var ${variableName} = ${formulaFunction}(${product}, ${runDateParam}, "${delivery.toLowerCase()}");`,
  ].join('\n');

  if (!includeNans) {
    formula = `${formula}\n${variableName} = drop_nans(${variableName});`;
  }

  return formula;
}

function generateTimeSeriesFormulaFromConfig(config) {
  const { howFarBack, variableName, intradayData } = config;
  const product = config.product[0];
  const time = config.time[0];
  const timeZone = config.timeZone[0];

  let formula = '';
  let formulaFunction = '';
  let runDateParam = '';

  const timeFuncMap = {
    hours: 'addHours',
    days: 'addDays',
    months: 'addMonths',
    years: 'addYears',
  };

  if (time === 'business days') {
    runDateParam = `add_business_days($RUN_DATE, ${-howFarBack})`;
  } else {
    runDateParam = +howFarBack === 0
      ? '$RUN_DATE'
      : `$RUN_DATE.${timeFuncMap[time]}(${-howFarBack})`;
  }

  if (intradayData && timeZone) {
    formulaFunction = 'time_series_tz';
  } else {
    formulaFunction = 'time_series';
  }

  formula = intradayData && timeZone
    ? `var ${variableName} = ${formulaFunction}(${product}, "${timeZone}", ${runDateParam});`
    : `var ${variableName} = ${formulaFunction}(${product}, ${runDateParam});`;

  return formula;
}

function generateBasicComputationFormulaFromConfig(config) {}

function generateFillFormulaFromConfig(config) {
  const direction = config.direction[0];
  const curve = config.curve[0];
  const { variableName } = config;

  return `var ${variableName} = fill_${direction}(${curve});`;
}

function generateMergeFormulaFromConfig(config) {
  const { additionalCurvesToMerge, variableName, func } = config;
  const intoCurve = config.intoCurve[0];
  const mergeCurve = config.mergeCurve[0];

  const formula = `
    var ${variableName} = ${func}(${[
      intoCurve,
      mergeCurve,
      ...additionalCurvesToMerge,
    ].join(', ')});
  `.trim();

  return formula;
}

function parseVariablesFromFormula() {}

function generateTimeSeriesCorrectionFormulaFromConfig(config) {
  const {
    lookBackFromDays, lookBackFromInsertionDays, lookBackToDays, varName,
  } = config;
  const timeZone = config.timeZone[0];

  const formula = [
    `var ${varName} = time_series($${varName}, $RUN_DATE);`,
    'var Corr = time_series_corr(',
    `  $${varName},`,
    `  ${timeZone ? `"${timeZone}"` : null},`,
    `  $RUN_DATE.addDays(${-lookBackFromDays}),`,
    `  $RUN_DATE.addDays(${-lookBackToDays}),`,
    `  $RUN_DATE.addDays(${-lookBackFromInsertionDays})`,
    ');',
    `${varName} = complement(${varName}, Corr);`,
    '',
  ].join('\n').trim();

  return formula;
}

/**
 * Generates and returns a QA error message.
 * @param {string} qaType - QA type
 * @param {string} tsVar - Name of the variable containing the data being tested.
 * @param {string} qaName - Name given to this test by user.
 * @param {string} ctxMsg - JavaScript expression that evaluates to a context message.
 * @param {string} usrMsg - Custom message entered by user to be
 *                          appended to end of QA message (raw,
 *                          not stringified.)  This method stringifies
 *                          this portion of the message.
 * @returns {string} A JavaScript expression that evaluates to a String.
 * @private
 */
function _genFailMsg(qaType, tsVar, qaName, ctxMsg, usrMsg) {
  let msg = `${qaType }: \`${ tsVar }\``;
  let msgConcat;

  if (Strings.isNonEmpty(qaName)) { msg = `${qaName } (${ msg })`; }

  if (Strings.isNonEmpty(ctxMsg)) {
    msgConcat = `${JSON.stringify(`${msg }\n`)
    } + ${
      ctxMsg}`;
  } else { msgConcat = JSON.stringify(msg); }

  if (Strings.isNonEmpty(usrMsg)) {
    msgConcat += ` + ${
      JSON.stringify(`\n\n${ MpFormulaStepText.UserMessage.enterCustomMessage }\n`)
    } + ${
      JSON.stringify(usrMsg)}`;
  }

  return msgConcat;
}

function generateFreeTextFormulaFromConfig(config) {
  const { code, variableName } = config;
  return `var ${variableName} = ${code.includes(';') ? code : `${code};`}`;
}

function generateMissingDataQAFormulaFromConfig(config) {
  const {
    variableName, curve, contains, customMessage,
  } = config;

  const errMsg = _genFailMsg('MISSING_DATA', curve[0], variableName,
    "lim.String.build('Expected at least {} numeric values, got [{}] instead.', minCnt, counts.join(','))",
    customMessage);

  const formula = [
    '(function (minCnt, counts) {',
    '  if (_.some(counts, function (cnt) { return cnt < minCnt; }))',
    `     Test.fail(${ errMsg });`,
    `})(${+contains}, reduce_to_count(${curve[0]}));`,
  ].join('\n');

  return formula;
}

function generateSpikeDataQAFormulaFromConfig(config) {
  const {
    curve, compareWith, spikeValue, customMessage, comparison, variableName,
  } = config;

  const isLinear = comparison[0] === 'linearity';

  const formula = [
    '(function (tsChg, maxChgNum) {',
    '',
    '    var tooHigh = when_value_greater_than(tsChg, maxChgNum),',
    '        tooLow  = when_value_less_than(tsChg, -maxChgNum);',
    '',
    '    if (   tooHigh.length() > 0 || tooLow.length() > 0 ) {',
    '        var details = (function (errorTs, maxRows) {',
    "    var more = '';",
    '    if (errorTs.length() > maxRows) {',
    "        more    = '[' + (errorTs.length() - maxRows).toString(10) + ' more...]';",
    '        errorTs = errorTs.slice(0, maxRows);',
    '    }',
    '',
    '    return errorTs.toCsv() + more;',
    !isLinear
      ? `})(when_date_exists_in_one_other(union(${curve[0]}, ${compareWith[0]}), tooHigh, tooLow), 2);`
      : `})(when_date_in(${curve[0]}, union(tooHigh, tooLow), 1), ${spikeValue});`,
      `        Test.fail("${variableName} (${isLinear ? 'SPIKE_LINEARITY' : 'SPIKE_SIDE_BY_SIDE'}: \`${curve[0]}\`)\\n" + lim.String.build('\`${curve[0]}\` contains variations of at least +/-${spikeValue} when compared to {}:\\n{}', ${!isLinear ? `'\`${compareWith[0]}\`'` : "'itself (from one date to the next)'"}, details)${customMessage ? ` + "\\n\\nCustom Message:\\n" + "${customMessage}"` : ''});`,
    '    }',
    !isLinear
      ? `})(cell_diff(${curve[0]}, ${compareWith[0]}), ${spikeValue});`
      : `})(change(${curve[0]}, 1), ${spikeValue});`,
  ].join('\n');

  return formula;
}

function generateInRangeDataQAFormulaFromConfig(config) {
  const {
    curve, lowest, highest, customMessage, variableName,
  } = config;

  const formula = [
    '(function(tsVar, low, high) {',
    '',
    '    var tooHigh = when_value_greater_than(tsVar, high),',
    '        tooLow  = when_value_less_than(tsVar, low);',
    '',
    '    if (   tooHigh.length() > 0',
    '        || tooLow.length() > 0 ) {',
    '        var details = (function (errorTs, maxRows) {',
    "    var more = '';",
    '    if (errorTs.length() > maxRows) {',
    "        more    = '[' + (errorTs.length() - maxRows).toString(10) + ' more...]';",
    '        errorTs = errorTs.slice(0, maxRows);',
    '    }',
    '',
    '    return errorTs.toCsv() + more;',
    '})(complement(tooHigh, tooLow), 2);',
    `        Test.fail("${variableName} (OUT_OF_RANGE: \`${curve[0]}\`)\\n" + lim.String.build('\`${curve[0]}\` contains data outside of range [{}, {}]:\\n{}', low, high, details)${customMessage ? ` + "\\n\\nCustom Message:\\n" + "${customMessage}"` : ''});`,
    '    }',
    '',
    `})(${curve[0]}, ${lowest}, ${highest});`,
  ].join('\n');

  return formula;
}

function genCodeBasicMath(config) {
  const { availVars, variableName } = config;
  const mathExpr = config.computation;
      const mathCode = _mathExprToJsCode(mathExpr, availVars);
      const code = `var ${ variableName } = ${ mathCode };`;

  return Strings.assembleAutoGenJS({
      expr: mathExpr.replace(new RegExp('[\r\n|\n\r|\n|\r]', 'g'), '\\n'),
  }, code);
}

function genExtrapolation(config) {
  const {
    availVars, variableName, method, timeSeriesVar, methCtrls,
  } = config;

  let md = {};
  let code;
  if (method[0] === 'follow_dataset') {
    md = _genMdExtraMeth_follow_dataset(methCtrls[0]);
    code = _genCodeExtraMeth_follow_dataset(config, variableName, availVars);
  } else {
    console.log(methCtrls[1]);
    md = _genMdExtraMeth_repeat_last_cycle(methCtrls[1]);
    code = _genCodeExtraMeth_repeat_last_cycle(config, variableName, availVars);
  }
  const metadata = Object.assign({
                    ts_var: timeSeriesVar[0],
                    method: method[0],
                  }, md);

  return Strings.assembleAutoGenJS(metadata, code);
}

export default function parseFormulaFromConfig(formulaType = '', config = {}) {
  switch (formulaType) {
    case 'forward_curve':
      return generateForwardCurveFormulaFromConfig(config);
    case 'time_series':
      return generateTimeSeriesFormulaFromConfig(config);
    case 'time_series_corr':
      return generateTimeSeriesCorrectionFormulaFromConfig(config);
    case 'fill':
      return generateFillFormulaFromConfig(config);
    case 'merge':
      return generateMergeFormulaFromConfig(config);
    case 'free_text':
      return generateFreeTextFormulaFromConfig(config);
    case 'missing_data_qa':
      return generateMissingDataQAFormulaFromConfig(config);
    case 'spike_qa':
      return generateSpikeDataQAFormulaFromConfig(config);
    case 'in_range_qa':
      return generateInRangeDataQAFormulaFromConfig(config);
    case 'basic_math':
      return genCodeBasicMath(config);
    case 'extrapolation':
      return genExtrapolation(config);
    default:
      return '';
  }
}

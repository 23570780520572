<template>
  <div>
    <div v-if="links.length !== 0 && !showError">
      <div class="mds-header__title component-title">
        <small>{{ title }}</small>
      </div>
      <div
        v-for="section in links"
        :key="section.name"
      >
        <div class="section-title">
          {{ section.name }}
        </div>
        <ul>
          <li
            v-for="link in section.links"
            :key="link.name"
          >
            <a :href="link.link"> {{ link.name }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <div
        v-if="showError"
        style="display: flex; align-items: center; justify-content: center; margin-top: 2%;"
      >
        <mds-empty-state
          title="No Results"
          message="Error while fetching add-in previous versions details."
          size="medium"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MdsEmptyState from '@mds/empty-state';

export default {
  name: 'AddInTemplate',
  components: {
    MdsEmptyState,
  },
  data() {
    return {
      links: [],
      title: '',
      errorMessage: '',
      showError: false,
    };
  },
  mounted() {
    if (this.$route.params.type === 'templates') {
      this.title = this.$t('allHomeData.products.excelAddIn.templates.title');
      this.links = this.$t('allHomeData.products.excelAddIn.templates.sections');
    } else if (this.$route.params.type === 'archive') {
      this.fetchAddInArchiveDetail();
    }
    window.scrollTo(0, 0);
  },
  methods: {
    async fetchAddInArchiveDetail() {
      try {
        const response = await axios.get(`https://${process.env.VUE_APP_STATIC_RESOURCES_S3_BUCKET_CF_URL}/products/mcd-ui-app/addIn-previous-versions.json`);

        this.title = 'Excel Add-in Previous Versions';
        this.links = response.data;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';
@import '@mds/link';

  .component-title {
    @include mds-level-1-heading($bold: false);
    color: #162b3d;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .section-title {
    @include mds-level-4-heading($bold: true);
    color: #162b3d;
    margin: 10px 10px 10px 20px;
  }

  ul {
    @include mds-unordered-list(large);
    margin-left: 20px;
  }

  li {
    padding: 5px;
  }

  a {
    @include mds-link($underline: false);
  }
</style>

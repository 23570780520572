<template>
  <mds-layout-grid class="cards-section">
    <div class="mds-header">
      <h1 class="mds-header__title component-title card-page-heading">
        Products
      </h1>
    </div>
    <mds-row>
      <template v-for="product in data">
        <mcd-card
          v-if="isEntitledFeatures(product.featureType) && product.isNotShowOnHomePage !== 'true'"
          :key="product.id"
          :product-data="product"
          :product-id="product.id"
          :image="images[0]"
        />
      </template>
    </mds-row>
  </mds-layout-grid>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import McdCard from './McdCard';

export default {
  name: 'McdCardSection',
  components: { McdCard, MdsLayoutGrid, MdsRow },
  props: {
    itemType: { type: String, default: '' },
  },
  data() {
    return {
      contentType: this.itemType,
      data: {},
      images: [
        require('../../assets/images/common/sign-in-access.png'),
        require('../../assets/images/common/sign-in-questions.png'),
        require('../../assets/images/common/sign-in-analysis.png'),
      ],
    };
  },
  mounted() {
    const vm = this;
    if (this.contentType === 'allHomeData') {
      const products = this.$t('allHomeData.products');
      delete products.dataAnalytics;
      const research = this.$t('allHomeData.research');
      const dataManagement = this.$t('allHomeData.dataManagement');
      const developer = this.$t('allHomeData.developer');
      const mergedData = { ...research, ...dataManagement, ...developer };
      const ordered = Object.keys(mergedData).sort().reduce(
        (obj, key) => {
          obj[key] = mergedData[key];
          return obj;
        },
        {},
      );

      vm.data = { ...products, ...ordered };
    } else {
      vm.data = this.$t(`allHomeData.${this.contentType}`);
    }
  },
  methods: {
    isEntitledFeatures(feature) {
      if (feature && Array.isArray(feature)) {
        return this.isUserEntitled(feature);
      }
      return [];
    },
  },
};
</script>
<style lang='scss'>
@import '~@mds/constants';
@import '~@mds/fonts';
@import '~@mds/typography';

h1.card-page-heading {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  line-height: 1.375;
  font-weight: 200;
  font-size: 45px;
  padding-left: 20px;
  padding-bottom: 20px;
}

</style>

<template>
  <div>
    <template v-if="currentStatus == 'SUCCESS'">
      <mds-search-field
        v-model="searchFilter"
        placeholder="Search"
        label="Search"
        :on-dark="darkMode"
        @keyup.prevent="filterKeywords($event.target.value)"
        @mds-search-field-input-cleared="clearSearchBar"
      />
      <div
        v-show="hasResult"
        id="searchResultDiv"
        class="search-results__canvas custom-padding-search"
      >
        <div
          v-closable="closeSearchResults"
          class="search-results__canvas mds-search-results"
          :class="{ 'custom-position-search': noResult }"
          @scroll="onSearchScroll"
        >
          <mds-loader
            v-if="searchLoader"
            class="infinityLoader"
            :on-dark="darkMode"
          />
          <mds-data-table
            v-if="!noResult"
            :responsive="respond"
            multiselection
            row-hover
            :header-configs="headers"
            :row-data="searchedRows"
            class="break-word"
            :on-dark="darkMode"
            @mds-data-table-sort-change="onSort($event)"
            @mds-data-table-row-selection-change="onSearchRowSelect"
          />

          <div
            v-if="noResult"
            class="custom-empty-state-class mds-empty-state mds-empty-state--small"
          >
            <div class="mds-empty-state__title">
              {{ $t('labels.common.noResults') }}
            </div>
            <div class="mds-empty-state__message">
              {{ $t('labels.common.refineSearch') }}
            </div>
          </div>
        </div>
      </div>

      <div class="custom-padding">
        <keys-selection
          :searched-rows="searchedRows"
          :search-data-arr="searchDataArr"
          :data-source-options="dataSourceOptions"
          :selected-rows="selectedRows"
          :dark-mode="darkMode"
          @selectedRows="handleSymSelection"
        />
      </div>
      <Metadata />
    </template>

    <template v-else-if="currentStatus == 'LOADING'">
      <mds-loader
        size="large"
        :on-dark="darkMode"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { MdsDataTable } from '@mds/data-table-v3';
import MdsSearchField from '@mds/search-field';
import MdsLoader from '@mds/loader';
import { ThemeMixin } from '../../../mixins/themeMixin';
import { replaceAll } from '../../../utils/stringUtils';
import KeysSelection from './KeysSelection.vue';
import Metadata from './Metadata.vue';
import EventBus from '../../../main';

export default {
  name: 'FeedDashboard',
  components: {
    MdsDataTable,
    MdsSearchField,
    MdsLoader,
    KeysSelection,
    Metadata,
  },
  directives: {
    closable: {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          // here I check that click was outside the el and his childrens
          if (!(el === event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
        document.body.addEventListener('touchstart', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
        document.body.removeEventListener('touchstart', el.clickOutsideEvent);
      },
      stopProp(event) {
        event.stopPropagation();
      },
    },
  },
  mixins: [ThemeMixin],
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchFilter: '',
      headers: [
        {
          text: 'Symbol',
          fieldName: 'keysAndValues',
          sortable: true,
          sorted: -1,
        },
        {
          text: 'Feed',
          fieldName: 'feedName',
          sortable: true,
        },
        {
          text: 'Description',
          fieldName: 'description',
          sortable: true,
        },
        {
          text: '',
          fieldName: '',
        },
      ],
      searchedRows: [],
      hasResult: false,
      respond: false,
      tempSearch: '',
      searchTimer: null,
      searchDataArr: [],
      dataSourceOptions: [],
      currentStatus: 'LOADING',
      searchLoader: false,
      stateCache: false,
      searchPage: 1,
      searchResult: false,
      changedSearchText: false,
      noResult: false,
    };
  },
  computed: {
    ...mapGetters({
      feedList: 'feedModule/getFeedList',
      pageStatus: 'feedModule/getPageStatus',
      userDatasource: 'feedModule/getUserDatasources',
    }),
  },

  watch: {
    searchFilter(newValue, oldValue) {
      if (!newValue) {
        EventBus.$emit('getKeyFilters');
      }
    },
  },

  created() {
    const actionArray = [];

    if (typeof this.feedList === 'undefined' || this.feedList.length <= 0) {
      this.stateCache = true;
      actionArray.push(this.getUserDatasources());
    }

    Promise.all(actionArray).finally(() => {
      if (this.stateCache) {
        this.initForm();
      } else {
        this.currentStatus = this.pageStatus;
        this.dataSourceOptions = this.userDatasource.sort((a, b) => a.value.localeCompare(b.value));
      }
    });
    window.showMd = this.showMd.bind(this);
  },

  methods: {
    ...mapActions('feedModule', ['getSearchKeywords', 'getUserDatasources']),
    // eslint-disable-consistent-return
    filterKeywords(txt) {
      if (!txt) {
        this.hasResult = false;
        this.tempSearch = '';
        this.searchFilter = '';
        this.noResult = false;
        this.searchLoader = false;
        return false;
      }
      this.searchLoader = false;
      this.searchFilter = txt;
      this.searchpage = 0;
      this.searchedRows.length = 0;
      this.searchDataArr.length = 0;
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => this.searchKeywords(txt), 1000);
      return true;
    },
    searchKeywords(txt) {
      if (this.tempSearch.trim() !== txt.trim() && this.searchFilter.length > 0) {
        this.tempSearch = txt;
        this.searchResult = false;
        this.getSearchResults(txt, false);
      }
    },
    onSort(arg) {
      const order = arg.sortOrder;
      this.showRows = this.searchedRows.sort(
        (a, b) => (a[`${arg.sortField}`] >= b[`${arg.sortField}`] ? order : -order),
        undefined,
        {
          sensitivity: 'base',
        },
      );
    },
    clearSearchBar() {
      this.searchFilter = '';
      this.filterKeywords('');
      EventBus.$emit('getKeyFilters');
    },
    filterResponse(text, results) {
      results.forEach((element, counter) => {
        const keysValueEncoded = encodeURIComponent(element.keysAndValues).replace(/[!'()*]/g, escape);
        let metdataHtml = `<a href='javascript:showMd("${keysValueEncoded}"`;
        metdataHtml += `, "${element.feedName}","timeSeries");'`;
        metdataHtml += 'class="mds-list-group__link" ><mds-icon name="info-circle" /></a>';
        element['id'] = counter + 1;
        element['selected'] = false;
        element['keyType'] = 'searchKeys';
        element[''] = metdataHtml;
        this.searchDataArr.push(JSON.parse(JSON.stringify(element)));
        Object.keys(element).map((key, index) => {
          if (key === 'id' || key === 'selected' || key === '') {
            return false;
          }
          const searchText = `${element[key]}`;
          const strIndex = searchText.toLowerCase().indexOf(text.toLowerCase());
          if (strIndex !== -1) {
            const replaceText = searchText.substring(strIndex, strIndex + text.length);
            const newString = replaceAll(
              text,
              `<span class="mds-search-results--text-match">${replaceText}</span>`,
              true,
              searchText,
            );
            element[key] = newString;
            if (
              this.selectedRows.findIndex(
                item => item.symbol === element.keysAndValues && item.feed === element.feedName,
              ) !== -1
            ) {
              element['selected'] = true;
            }
          }
          return element;
        });
        this.searchedRows.push(element);
      });
    },

    initForm() {
      this.currentStatus = this.pageStatus;
      this.dataSourceOptions = this.userDatasource.sort((a, b) => a.value.localeCompare(b.value));
    },
    handleSymSelection(arr) {
      this.$emit('onSymRowSelect', arr);
    },
    onSearchRowSelect(selected) {
      EventBus.$emit('onSearchRowSelect', selected);
    },
    onSearchScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (
        !this.searchLoader
        && !this.searchResult
        && scrollTop + clientHeight >= scrollHeight - 100
      ) {
        this.searchLoader = true;
        this.searchpage++;
        this.getSearchResults(this.searchFilter, true);
      }
    },
    getSearchResults(txt, infintyScroll) {
      this.getSearchKeywords(`${txt}&page=${this.searchpage}&limit=100`).then((response) => {
        if (response.data.length === 0 && !infintyScroll) {
          this.hasResult = true;
          this.searchedRows.length = 0;
          this.searchDataArr.length = 0;
          this.noResult = true;
        } else if (response.data.length <= 100) {
          this.hasResult = true;
          this.noResult = false;
          if (response.data.length === 0) {
            this.searchResult = true;
            this.searchLoader = false;
          } else if (response.data.length < 100) {
            this.filterResponse(txt, response.data);
            this.searchResult = true;
            this.searchLoader = false;
          } else {
            this.filterResponse(txt, response.data);
            this.searchResult = false;
            this.searchLoader = false;
          }
        }
      });
    },
    closeSearchResults(event) {
      if (this.hasResult) {
        this.hasResult = false;
        this.searchedRows.length = 0;
        this.searchDataArr.length = 0;
      }
    },
    showMd(symbol, feed, curveType) {
      EventBus.$emit('showMetadataDialog', { symbol, feed, curveType });
    },
  },
};
</script>
<style lang="scss">
@import 'src/assets/styles/components/custom/feed_dashboard';
.search-results__canvas {
  background-color: var(--app-background-color-light);
}
</style>

/* eslint no-tabs: 0 */
<template>
  <div class="page-body">
    <LeftNavigation
      :refresh-signal="refreshSignal"
      :dark-mode="darkMode"
      @mcd-left-menu-change="handNavChange"
    />
    <div class="content__container">
      <div
        id="mds-notification"
      >
        <mds-notification
          v-if="showSuccessDialog"
          :variation="feedSuccessMessage[refreshSignal - 1].responseStatus === 200 ? 'success': 'error'"
          title="Success"
          :dismiss-delay="dialogDelay"
          size="small"
          dismissible="true"
          class="z-index-9999"
          @mds-notification-dismissed="removeNotification()"
        >
          {{ feedSuccessMessage[refreshSignal - 1].responseStatus === 200 ?
            `The feed ${feedSuccessMessage[refreshSignal - 1].feedName} has been updated`
            : `error while updating the feed ${feedSuccessMessage[refreshSignal - 1].feedName}` }}
        </mds-notification>
      </div>
      <div class="content">
        <ToolBar
          ref="childComponentRef2"
          :dark-mode="darkMode"
          :class="{'disabled-search-box': isFeedsPage}"
          @handleSearchInputEvent="handleSearchData"
          @clearSearchInputEvent="reloadPage"
        />
        <feed-page
          v-if="isFeedsPage"
          :feeds-data="feedsPageData"
          @mcd-refresh-meta-data="handleRefreshSignal"
          @reloadMainCatalogPage="reloadPage"
        />
        <MainTable
          v-show="!isFeedsPage"
          ref="childComponentRef"
          :refresh-signal="refreshSignal"
          :dark-mode="darkMode"
          :search-data="searchField"
          :nav-data="navData"
          @mcd-feed-page-data="handleFeedPageView"
          @mcd-refresh-data="handleRefreshData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { MdsNotification } from '@mds/notification';
import LeftNavigation from './LeftNavigation';
import MainTable from './MainTable';
import ToolBar from './ToolBar';
import { ThemeMixin } from '../../mixins/themeMixin';
import FeedPage from './FeedPage';


export default {
  name: 'NavigationHome',
  components: {
    FeedPage,
    MainTable,
    LeftNavigation,
    MdsNotification,
    ToolBar,
  },
  mixins: [ThemeMixin],
  data() {
    return {
      searchField: '',
      modelSearchField1: '',
      feedsPageData: [],
      navData: {},
      isFeedsPage: false,
      refreshSignal: 0,
      dialogDelay: 2000,
      showSuccessDialog: false,
      feedSuccessMessage: [],
    };
  },
  mounted() {
    this.$eventBus.$on('from-link-trigger', (data) => {
      if (data === '/catalog') {
        this.isFeedsPage = false;
        this.searchField = '';
      }
    });
  },
  methods: {
    handleFeedPageView(feeds) {
      this.isFeedsPage = true;
      this.feedsPageData = feeds;
    },
    handleRefreshData(feeds) {
      this.feedsPageData = feeds;
    },
    handleSearchData(value) {
      this.searchField = value;
    },
    handNavChange(value) {
      this.isFeedsPage = false;
      this.navData = value;
    },
    handleRefreshSignal(obj) {
      this.refreshSignal += obj.refreshNo;
      this.feedSuccessMessage.push({ feedName: obj.feedName, responseStatus: obj.response.status });
      this.showSuccessDialog = true;
    },
    removeNotification() {
      this.showSuccessDialog = false;
    },
    reloadPage() {
      this.isFeedsPage = false;
      this.searchField = '';
      this.$refs.childComponentRef.clearData();
      this.$refs.childComponentRef2.modelSearchField1 = '';
    },
  },
};
</script>
<style lang="scss">
  @import '@mds/constants';
  @import '@mds/typography';
  @import '@mds/link';

  .page-body {
    display: flex;
  }

  .content__container {
    flex-grow: 1;
  }

  .content {
    max-width: 1200px;
    margin: 0 auto;
  }
  .disabled-search-box {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.4;
  }
</style>

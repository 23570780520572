
const DateText = {
    monthNames: [
        'January', 'February', 'March',
        'April',   'May',      'June',
        'July',    'August',   'September',
        'October', 'November', 'December'
    ],
    
    monthAbbrevs: [
        'Jan', 'Feb', 'Mar',
        'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ],
    
    dayNames: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ],
    
    dayAbbrevs: [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat'
    ],

    am: "AM",
    pm: "PM"
};

export default DateText;

<template>
  <div>
    <span class="caption"> {{ $t('dashboard.common.selected') }}</span>
    <div class="container">
      <table class="mds-data-table feed-navigation__selected-items">
        <thead class="mds-data-table__header">
          <tr class="mds-data-table__row">
            <th
              class="mds-data-table__header-cell"
              scope="col"
              width="40%"
            >
              <div class="mds-data-table__header-cell-inner">
                <span class="custom-header">
                  {{ $t('labels.common.symbol') }}
                </span>
              </div>
            </th>

            <th
              class="mds-data-table__header-cell"
              scope="col"
              width="29%"
            >
              <div
                class="mds-data-table__header-cell-inner mds-data-table__header-cell-inner--right"
              >
                <span class="custom-header">
                  {{ $t('labels.common.column') }}
                </span>
              </div>
            </th>

            <th
              class="mds-data-table__header-cell"
              scope="col"
              width="27%"
            >
              <div
                class="mds-data-table__header-cell-inner mds-data-table__header-cell-inner--right"
              >
                <span class="custom-header">
                  {{ $t('labels.common.feed') }}
                </span>
              </div>
            </th>

            <th
              class="mds-data-table__header-cell"
              scope="col"
              width="1%"
            >
              <div
                class="mds-data-table__header-cell-inner mds-data-table__header-cell-inner--right"
              >
                <span class="custom-header" />
              </div>
            </th>

            <th
              class="mds-data-table__header-cell"
              scope="col"
              width="2%"
            >
              <div
                class="mds-data-table__header-cell-inner mds-data-table__header-cell-inner--right"
              >
                <span class="custom-header" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="mds-data-table__body">
          <tr
            v-for="(row, index) in selectedRows"
            :key="row.symbol + index"
            class="mds-data-table__row"
          >
            <td
              class="mds-data-table__cell entitlement-body"
              scope="row"
            >
              {{ row.symbol }}
            </td>

            <td class="mds-data-table__cell mds-data-table__cell--right entitlement-body">
              <mds-combo-box
                class="tick-alignment-left"
                multiple
                placeholder="Select Option(s)"
                label=""
                :data-set="row.columns"
                :on-dark="darkMode"
                :selected-items="row.selectedColumns"
                @input="handleColSelection(row.symbol, row.feed, $event)"
              />
            </td>
            <td class="mds-data-table__cell mds-data-table__cell--right entitlement-body">
              {{ friendlyFeedName(row.feed) }}
            </td>
            <td class="mds-data-table__cell mds-data-table__cell--right entitlement-body">
              <a
                href="javascript:void(0);"
                class="mds-list-group__link"
                @click="showMetadataPopUp(row.symbol, row.feed,row.keyType,row.keysValues)"
              >
                <mds-icon name="info-circle" />
              </a>
            </td>
            <td class="mds-data-table__cell mds-data-table__cell--right entitlement-body">
              <mds-button
                variation="secondary"
                :on-dark="darkMode"
                icon="remove"
                @click="removeSymbol(row, index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import MdsComboBox from '@mds/combo-box';
import { MdsButton } from '@mds/button';
import EventBus from '../../../main';
import { ThemeMixin } from '../../../mixins/themeMixin';

export default {
  name: 'SelectedKeys',
  components: {
    MdsComboBox,
    MdsButton,
  },
  mixins: [ThemeMixin],
  props: {
    searchedRows: {
      type: Array,
      default: () => [],
    },
    rowsKey: {
      type: Array,
      default: () => [],
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    selectedFeedName: {
      type: String,
      default: '',
    },
    rootRows: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    removeSymbol(row, index) {
      let objIndex = -1;
      let searchObjIndex = -1;
      let rootIndex = -1;
      if (row.keyType === 'symbolKeys') {
        objIndex = this.rowsKey.findIndex(
          obj => obj.id === row.id && this.selectedFeedName === row.feed && obj.selected,
        );
      }
      if (row.keyType === 'searchKeys') {
        searchObjIndex = this.searchedRows.findIndex(
          obj => obj.id === row.id && obj.feedName === row.feed && obj.selected,
        );
      }
      if (row.keyType === 'rootKeys') {
        rootIndex = this.rootRows.findIndex(
          obj => obj.id === row.id && this.selectedFeedName === row.feed && obj.selected,
        );
      }
      if (objIndex !== -1) {
        this.$emit('rowsKeySelection', false, objIndex);
      } else if (searchObjIndex !== -1) {
        this.$emit('searchedRowsSelection', false, searchObjIndex);
      } else if (rootIndex !== -1) {
        this.$emit('rootRowsSelection', false, rootIndex);
      }
      this.$emit('deleteSelectedRows');
    },
    handleColSelection(symbol, feed, data) {
      this.$emit('updatedSelectedCols', { symbol, feed, data });
    },
    friendlyFeedName(feedName) {
      return feedName
        .substring(feedName.indexOf('_') + 1)
        .match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g)
        .join(' ');
    },
    showMetadataPopUp(symbol, feed, keyType, keysValues) {
      const type = keyType === 'rootKeys' ? 'forward' : 'timeSeries';
      EventBus.$emit('showMetadataDialog', { symbol: keysValues || symbol, feed, curveType: type });
    },
  },
};
</script>

<style lang="scss">
.feed-navigation__selected-items {
  color: var(--dynamic-subtitle-color);
}
</style>

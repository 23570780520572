<template>
  <div>
    <mds-button
      id="popover-group"
      icon="plus"
      variation="secondary"
      @click="resetGroupFlags()"
    >
      {{ $t('labels.group.addGroupButton') }}
    </mds-button>
    <mds-popover
      id="add-group"
      ref="add-group"
      triggered-by="popover-group"
      position="bottom-left"
      title="Add Group"
      title-hidden
      width="200px"
      class="responsive-popover-contents"
    >
      <div class="account-menu__user-info">
        <mds-input
          id="new-group"
          :ref="'new-group'"
          placeholder="Group Name"
        />
        <div style="padding-top:5px">
          <template v-if="groupExists || groupPatternError">
            <mds-field-error
              id="error-id"
              visible
            >
              {{
                (groupExists?$t('labels.group.groupDuplicateError'):
                  (groupPatternError?$t('labels.group.groupPatternError'):"")
                ) }}
            </mds-field-error>
          </template>
        </div>
        <div
          class="account-menu__action"
          style="margin-top:10px"
        >
          <mds-button
            id="add-group-btn"
            variation="primary"
            @click="addGroup()"
          >
            {{ $t('labels.group.addGroup') }}
          </mds-button>
        </div>
      </div>
    </mds-popover>
  </div>
</template>

<script>
import EventBus from '../../main';

export default {
  name: 'AddGroupPop',
  data() {
    return {
      groupExists: false,
      groupPatternError: false,
    };
  },
  methods: {
    resetGroupFlags() {
      this.groupExists = false;
      this.groupPatternError = false;
    },
    addGroup() {
      const groupName = this.$refs['new-group'].children[0].value;
      this.resetGroupFlags();
      const allowedGPattern = /[a-z, ,0-9]*/i;
      const allowedGLength = 100;
      const matchedString = groupName.match(allowedGPattern);
      if (!(matchedString && matchedString[0].length === groupName.length) || groupName.length > allowedGLength
                || groupName.length === 0) {
        this.groupPatternError = true;
      }
      if (this.$parent.getGroupIndex(this.$parent.companyGroups, groupName) >= 0) {
        this.groupExists = true;
      }
      if (!(this.groupExists || this.groupPatternError)) {
        EventBus.$emit('addGroup', groupName);
        const groupPop = this.$refs['add-group'];
        groupPop.dismiss();
      }
    },
  },
};
</script>

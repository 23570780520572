import { isNonEmptyString, requireNonEmptyString } from './strings.es6';

/**
 * @namespace
 * @alias EnumType
 * @mixin
 */

/**
 * Validates the given argument to be an Integer or a non-empty String.
 * If valid, this method returns that argument.  This method throws
 * otherwise.
 * @param {(int|string)} val
 * @returns {(int|string)}
 * @private
 */
const _validValue = function (val) {
  if (!((typeof val === 'string'
            && isNonEmptyString(val))
            || (typeof val === 'number'
                && val % 1 === 0))) { throw new TypeError('val: Integer or String (non-empty)'); }

  return val;
};

/**
 * An enum item.
 * @param {string} name - Name of this enum item.
 * @param {(string|int)} [val=name] - Value associated with this enum item, if other than name.
 * @constructor
 */
export function EnumItem(name, val) {
  requireNonEmptyString(name, 'name');

  this._name = name;

  if (arguments.length > 1) { this._val = _validValue(val); } else { this._val = name; }

  Object.freeze(this);
}

Object.assign(EnumItem.prototype, /** @lends EnumItem.prototype} */ {
  toString() { return this._name; },
  valueOf() { return this._val; },
});

Object.freeze(EnumItem);
Object.freeze(EnumItem.prototype);


/**
 * Finalizes an object made to be a public enum type.
 * @param {function} ctor - Constructor.
 * @param {Object} pubObj - Public reference to the enum type.
 * @param {string} pubName - Public name.
 * @returns {Object} `pubObj`, frozen.
 * @private
 */
const _finalize = function (ctor, pubObj, pubName) {
  requireNonEmptyString(pubName, 'pubName');

  const idx = {};
  const list = [];

  for (const prop in pubObj) {
    if (pubObj.hasOwnProperty(prop)) {
      const item = pubObj[prop];

      if (item instanceof ctor) {
        list.push(item);

        const val = item.valueOf();

        idx[val] = item;
        idx[item.toString()] = item;
        idx[item.toString().toLowerCase()] = item;

        if (typeof val === 'string') { idx[val.toLowerCase()] = item; }
      }
    }
  }

  const _isEnumOf = function (item) {
    return (item instanceof ctor
            && idx[item.valueOf()] === item);
  };

  Object.assign(pubObj, /** @lends {EnumType} */ {

    /**
         * Returns the enum item associated with <code>value</code>.
         * If value is not recognize, this method returns
         * <code>defaultValue</code> if provided, otherwise
         * this method throws an Error.
         *
         * @param {(string|int|EnumItem|*)} value
         * @param {*} [defaultValue]
         * @returns {(EnumItem|*)}
         * @throws Error - If <code>value</code> is not recognized
         *         and <code>defaultValue</code> is not provided.
         */
    valueOf(value, defaultValue) {
      if (_isEnumOf(value)) return value;

      _validValue(value);

      if (idx.hasOwnProperty(value)) return idx[value];


      if (typeof value === 'string') {
        const lcVal = value.toLowerCase();
        if (idx.hasOwnProperty(lcVal)) return idx[lcVal];
      }

      if (arguments.length > 1) return defaultValue;
      throw new Error(`Unrecognize value for enum type ${ pubName } [${ value }]`);
    },

    /**
         * Returns whether the given object is an enum item
         * that belongs to this (parent) enum type.
         * @param item {Object}
         * @return {boolean}
         */
    isEnumOf: _isEnumOf,

    /**
         * Validates `arg` to be an enum item of this type, throws otherwise.
         * @param {(EnumItem|*)} arg Argument to validate.
         * @param {string} argName Name given to `arg`, for when an error must be thrown.
         * @returns {(EnumItem|*)} Always returns `arg`.
         * @throws {TypeError} If `arg` is not an enum of this type.
         */
    requireEnumOf(arg, argName) {
      if (!_isEnumOf(arg)) {
        throw new TypeError(`${argName }: Enum item of ${ pubName}`);
      }

      return arg;
    },

    /**
         * Returns the list of enum item within this (parent) enum type,
         * in no particular order.
         * @return {EnumItem[]|Object[]}
         */
    list() {
      return list.slice(0);
    },
  });

  return Object.freeze(pubObj);
};

/**
 * @namespace
 */
export const EnumBase = Object.freeze(/** @lends {EnumBase} */ {
  Item: EnumItem,

  /**
     * @param {Object} pubObj
     * @param {string} pubName
     * @returns {Object} Always returns `pubObj`, with adding new static methods to and
     *          and freezing it.
     */
  finalize(pubObj, pubName) {
    return _finalize(EnumItem, pubObj, pubName);
  },
});

/* *************************************************
 * Public object
 * ************************************************* */


/** @namespace */
const Enums = Object.freeze(/** @lends {Enums} */ {
  Base: EnumBase,

  /**
     * Finalizes a constructor to be used as an enum type.
     * @param {function} enumObj
     * @param {string} pubName
     * @returns {Object}
     */
  finalize(enumObj, pubName) {
    if (typeof enumObj !== 'function') throw new TypeError('enumObj: Function (constructor)');

    return _finalize(enumObj, enumObj, pubName);
  },
});

export default Enums;

<template>
  <div
    id="overlayDataalertId"
    class="overlayFeedEnt"
  />
</template>

<script>
export default {
  name: 'Overlay',
};
</script>

<template>
  <div
    role="contentinfo"
    class="footer"
  >
    <mds-layout-grid>
      <mds-row>
        <mds-col>
          <div class="footer__copyright">
            {{ $t("footer.copyRightSymbol") }}{{ new Date().getFullYear() }}{{ $t("footer.copyRightText") }}
            <code class="code__tag">v{{ $store.getters.appVersion }}</code>
          </div>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';

export default {
  name: 'PageFooter',
  components: {
    MdsLayoutGrid, MdsRow, MdsCol,
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete' && document.querySelector('div.footer > mds-popover > section')) {
        document.querySelector('div.footer > mds-popover > section').removeAttribute('role');
      }
    };
  },
  methods: {
    changeToolTip() {
      document.querySelector('section').removeAttribute('role');
    },
  },
};
</script>

<style >
.custom-pad-left {
  padding-left: 10px;
}

mds-popover.custom_width section.mds-popover {
  width: 400px;
  overflow: scroll;
  display: none;
}

mds-popover.custom_width section.mds-popover.mds-popover--visible {
  display: block;
  height: 450px !important;
}

@media screen and (max-width: 700px) {
  .custom_width-at-700 section.mds-popover {
    width: 300px;
    overflow:scroll;
  }
}

@media screen and (max-width: 900px) {
  .custom_width-at-900 section.mds-popover {
    width: 350px;
    overflow:scroll;
  }
}
</style>

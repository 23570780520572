<template>
  <div
    id="mds-modal"
    class="mds-modal mds-modal--600px mds-modal--width-600px"
    :class="{ 'mds-modal--open  mds-modal--active': saveEntitlementsPopOver }"
  >
    <div
      id="mds-modal-wrapper"
      class="mds-modal__wrapper"
    >
      <section
        role="dialog"
        class="mds-modal__container"
        aria-hidden="false"
      >
        <div class="mds-modal__inner">
          <header class="mds-modal__header">
            <h1 class="mds-modal__title">
              {{ $t('labels.dataAlert.saveConfiguration') }}
            </h1>
          </header>
          <div class="mds-modal__content">
            <template
              v-if="feedList.length > 0"
            >
              <p>{{ $t('labels.group.confirmMessage') }}</p>
            </template>
            <template v-else>
              <p>{{ $t('labels.group.noChanges') }}</p>
            </template>
            <div
              v-if="feedList.length > 0"
              class="group-changes-block"
            >
              <table
                class="mds-data-table"
                aria-label="data-alerts"
              >
                <th id="empty" />
                <tbody class="mds-data-table__body">
                  <tr class="mds-data-table__row save-group-row update">
                    <td
                      class="mds-data-table__cell border-top"
                      style="width: 3%"
                    >
                      <mds-icon name="pencil--s" />
                    </td>
                    <td class="mds-data-table__cell border-top">
                      <strong>{{ $t('labels.dataAlert.feeds') }}</strong>
                    </td>
                  </tr>
                  <tr
                    v-for="(feed, index) in feedListComputed"
                    :key="feed.name.text + '_read_' + index"
                    class="save-group-row"
                    :class="feed.isRemoved ? 'REMOVE' : 'ADD'"
                  >
                    <td
                      class="mds-data-table__cell border-top"
                      style="width: 3%"
                    >
                      <template v-if="!feed.isRemoved">
                        <mds-icon name="plus--s" />
                      </template>
                      <template v-if="feed.isRemoved">
                        <mds-icon name="minus--s" />
                      </template>
                    </td>
                    <td class="save-group-sec-col border-top">
                      {{ feed.name.text }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mds-button__container save-group-actions">
            <mds-button
              v-show="
                feedList.length > 0
              "
              id="save-btn"
              variation="primary"
              type="button"
              @click="saveEntitlements()"
            >
              {{ $t('labels.group.saveButton') }}
            </mds-button>
            <mds-button
              id="cancel-btn"
              variation="secondary"
              type="button"
              @click="cancelPopOver"
            >
              {{ $t('labels.group.cancelButton') }}
            </mds-button>
          </div>
        </div>
      </section>
    </div>

    <div
      id="mds-notification"
      class="mds-notification mds-notification--top-center mds-notification--below-masthead mds-notification--active custom-top"
    >
      <mds-notification-group>
        <mds-notification
          ref="successDialog"
          :list="JSON.stringify(successText)"
          role="alertdialog"
          status="success"
          size="small"
          dismiss-delay="60000"
          dismissible="true"
          removable="false"
          class="z-index-9999"
        >
          {{ $t('labels.feed.successDialogTex') }}
        </mds-notification>
        <mds-notification
          ref="errorDialog"
          :list="JSON.stringify(errorText)"
          role="alertdialog"
          status="error"
          size="small"
          dismiss-delay="60000"
          dismissible="true"
          removable="false"
          class="z-index-9999"
        >
          {{ $t('labels.feed.errorDialogTex') }}
        </mds-notification>
      </mds-notification-group>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EventBus from '../../main';

export default {
  name: 'EntitlementsPopOver',
  props: {
    feedEntitlements: {
      type: Array,
      default: () => [],
    },
    initialFeedEntitlements: {
      type: Array,
      default: () => [],
    },
    removedList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      feedList: [],
      saveEntitlementsPopOver: false,
      successText: [],
      errorText: [],
    };
  },
  computed: {
    feedListComputed() {
      return this.feedList;
    },
  },
  created() {
    EventBus.$on('saveEntitlements', this.saveEntitlementsPop);
  },
  methods: {
    ...mapActions('auditLogModule', [
      'saveFeedsForUser',
      'deleteFeedsForUser',
    ]),
    cancelPopOver() {
      this.saveEntitlementsPopOver = false;
    },
    saveEntitlementsPop() {
      this.readPermissionUser();
      this.saveEntitlementsPopOver = true;
    },

    readPermissionUser() {
      this.feedList = Object.assign([], []);
      this.feedEntitlements.forEach((ent) => {
        if (!this.initialFeedEntitlements.some(item => item.text === ent.text)) {
          this.feedList.push({ name: ent, type: 'feed', isRemoved: false });
        }
      });

      this.initialFeedEntitlements.forEach((ent) => {
        if (!this.feedEntitlements.some(item => item.text === ent.text)) {
          this.feedList.push({ name: ent, type: 'feed', isRemoved: true });
        }
      });
    },


    async saveEntitlements() {
      this.successText.length = 0;
      this.errorText.length = 0;
      this.saveEntitlementsPopOver = false;
      EventBus.$emit('showLoader', true);

      const userFeeds = [];
      const feedsDelete = [];

      this.feedList.forEach((feed) => {
        if (!feed.isRemoved) {
          userFeeds.push(feed.name.text);
        } else {
          feedsDelete.push(feed.name.text);
        }
      });

      if (userFeeds.length > 0) {
        await this.saveDataalertFeeds(userFeeds);
      }

      if (feedsDelete.length > 0) {
        await this.deleteDataalertFeeds(feedsDelete);
      }
      EventBus.$emit('hideOverLay', false);
      EventBus.$emit('hideFeedEntitlements', false);
      EventBus.$emit('refreshPage');
    },

    async saveDataalertFeeds(feeds) {
      await this.saveFeedsForUser(feeds).then((response) => {
        if (response.status === 200) {
          this.$refs.successDialog.open();
          this.successText.push('Added data alert configuration.');
        } else {
          this.errorText.push(response.data);
          this.$refs.errorDialog.open();
        }
      });
    },

    async deleteDataalertFeeds(feeds) {
      await this.deleteFeedsForUser(feeds).then((response) => {
        if (response.status === 200) {
          this.$refs.successDialog.open();
          this.successText.push('Updated data alert configuration.');
        } else {
          this.errorText.push(response.data);
          this.$refs.errorDialog.open();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/popover';
</style>

<template>
  <div>
    <mds-page-shell
      ref="pageShell"
      hidden-menu
      sticky
      :menu-text="isMobile?'':getMenuHeading"
      class="shell-wrapper"
      :logo="require('../../assets/images/common/Mstar-logo-50px.svg')"
      :logo-responsive="require('../../assets/images/common/Mstar-logo-50px.svg')"
      logo-href="/#/all"
      logo-alt="Back to all products page"
    >
      <template #mds-page-shell-hidden-nav>
        <nav>
          <mds-list-group
            variation="navigation"
          >
            <template v-for="item in siteMenuItems">
              <mds-list-group-item
                v-if="item.sublist !== undefined"
                :id="item.id"
                :key="item.id"
                sublist
                toggle-active
                :text="item.text"
                :href="item.href"
              >
                <mds-list-group variation="navigation">
                  <template v-for="sub in item.sublist">
                    <mds-list-group-item
                      v-if="isEntitledFeatures(sub.featureType)"
                      :id="sub.id"
                      :key="sub.id"
                      :text="sub.text"
                      :href="sub.href"
                      :active="isItemSelected(sub.id)"
                      @mds-list-item-clicked="handleMenuSelection(sub.id)"
                    />
                  </template>
                </mds-list-group>
              </mds-list-group-item>
              <mds-list-group-item
                v-else
                :id="item.id"
                :key="item.id"
                :text="item.text"
                :href="item.href"
                :active="isItemSelected(item.id)"
                @mds-list-item-clicked="handleMenuSelection(item.id,item.text)"
              />
            </template>
          </mds-list-group>
        </nav>
      </template>

      <template #mds-page-shell-masthead-right>
        <right-masthead />
      </template>
      <section class="content-wrapper">
        <slot />
      </section>
    </mds-page-shell>
    <page-footer />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import MdsPageShell from '@mds/page-shell';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group-v4';
import PageFooter from '../PageFooter';
import { isLoggedIn } from '../../utils/authService.js';
import RightMasthead from '../Navigation/SiteBar/RightMasthead';
import EventBus from '../../main';

const htmlElement = document.documentElement;
export default {
  name: 'LayoutDefault',
  components: {
    PageFooter,
    MdsPageShell,
    MdsListGroup,
    MdsListGroupItem,
    RightMasthead,
  },
  data() {
    return {
      activeItemId: '',
    };
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.loginModule.isAuthenticated,
      activeHeading: state => state.navigation.activeHeading,
    }),
    ...mapGetters({
      siteMenuItems: 'navigation/getSiteMenuItems',
    }),
    isUserLoggedIn() {
      return isLoggedIn();
    },
    isMobile() {
      return ((window.innerWidth <= 490) && (window.innerHeight <= 1000));
    },
    getMenuHeading() {
      return this.activeHeading;
    },
  },
  methods: {
    handleMenuSelection(selectedItemId) {
      this.activeItemId = selectedItemId;
      if (selectedItemId.toLocaleLowerCase() === 'pjm' || selectedItemId.toLocaleLowerCase() === 'ercot') {
        EventBus.$emit('handleDaTabs', selectedItemId);
      }
      this.$refs['pageShell'].hideHiddenNav();
    },
    isItemSelected(itemId) {
      return this.activeItemId === itemId;
    },
    isEntitledFeatures(feature) {
      if (feature && Array.isArray(feature)) {
        return this.isUserEntitled(feature);
      }
      return [];
    },
  },
};
</script>
<style lang="scss" module>
@import "src/assets/styles/theme/mds-overrides/layout-default";
</style>

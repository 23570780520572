import { requireNonEmptyString } from './strings.es6';
import Arrays from './arrays.es6';
import Task from './task.es6';
import UserActionTask from './user_action_task.es6';

/**
 * UserAction represents an action a user could take against a workflow.
 * The action is defined by its task(s).
 */
export default class UserAction {
  /**
     * @param {string} type Type of action. (TODO - possible values?)
     * @param {string} msg Message shown to users.
     * @param {UserActionTask[]} tasks List of task(s) that define the action.
     */
  constructor(type, msg, tasks) {
    this._type = requireNonEmptyString(type, 'type');
    this._msg = requireNonEmptyString(msg, 'msg');
    this._tasks = Object.freeze(Arrays.requireArrayOf(tasks, UserActionTask, 'tasks', 'UserActionTask'));
    Object.freeze(this);
  }

  /**
     * Returns the JSON payload for the given `task` argument.
     * @param {UserAction} action
     * @returns {Object}
     */
  static toJson(action) {
    if (!(action instanceof UserAction)) {
      throw new TypeError('action: UserAction');
    }
    return action.toJson();
  }

  /** @returns {Object} JSON payload, to be posted to MP API(s). */
  toJson() {
    return {
      type: this._type,
      msg: this._msg,
      tasks: this._tasks.map(Task.toJson),
    };
  }

  /**
     * TODO - list possible values
     * @returns {string} Type of action.
     */
  type() { return this._type; }

  /** @returns {string} Message to show to users before they take (trigger) this action. */
  msg() { return this._msg; }

  /** @returns {UserActionTask[]} Immutable array of tasks that define this action. */
  tasks() { return this._tasks; }
}

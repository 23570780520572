/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-restricted-properties */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-use-before-define */

import moment from 'moment';
import Text from './lang/dates_en-us.es6';
import Numbers, { leadingZeros } from '../numbers.es6';
import { requireString, requireNonEmptyString } from '../strings.es6';
import 'moment-timezone';

const Globalize = require('../../lib/globalize_all');
/* **************************************************
 * Interfaces
 * ************************************************** */
/**
 * @callback Dates.ValueGetter
 * @param {Date} Valid date object
 * @returns {int} Value of the field associated with the getter (hours, minutes, day-of-month, etc.)
 */

/**
 * @callback Dates.ValueSetter
 * @param {Date} Valid date object
 * @param {int} Value to assign to the field associated with the setter.
 * @returns {int} Epoch value, milliseconds since midnight.
 */

/**
 * @typedef Object Dates.ValueGetters
 * @property {Dates.ValueGetter} year
 * @property {Dates.ValueGetter} month
 * @property {Dates.ValueGetter} day
 * @property {Dates.ValueGetter} dayOfWeek
 * @property {Dates.ValueGetter} hours
 * @property {Dates.ValueGetter} minutes
 * @property {Dates.ValueGetter} seconds
 * @property {Dates.ValueGetter} milliseconds
 * @property {Dates.ValueGetter} utcOffset
 */

/**
 * @typedef Object Dates.ValueSetters
 * @property {Dates.ValueSetter} year
 * @property {Dates.ValueSetter} month
 * @property {Dates.ValueSetter} day
 * @property {Dates.ValueSetter} hours
 * @property {Dates.ValueSetter} minutes
 * @property {Dates.ValueSetter} seconds
 * @property {Dates.ValueSetter} milliseconds
 */

/**
 * @typedef Object Dates.ValueHandlers
 * @property {Dates.ValueGetters} getter
 * @property {Dates.ValueSetters} setter
 */

/* **************************************************
 * Private variables
 * ************************************************** */

export const
  HOURS_PER_DAY = 24;
const MINUTES_PER_DAY = 1440;
const SECONDS_PER_DAY = 86400;
const MILLIS_PER_SECOND = 1000;
const MILLIS_PER_MINUTE = (60 * MILLIS_PER_SECOND);
const MILLIS_PER_HOUR = (60 * MILLIS_PER_MINUTE);
const MILLIS_PER_DAY = (24 * MILLIS_PER_HOUR);
const MILLIS_PER_MONTH = (28 * MILLIS_PER_DAY);
const MILLIS_PER_YEAR = (365 * MILLIS_PER_DAY);

const CONTRACT_LETTERS = 'FGHJKMNQUVXZ';


const ISO_DATE_STRING = '(\\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])';
const ISO_DATE_REGEX = new RegExp(`^${ ISO_DATE_STRING }$`);

const ALL_DIGIT_REGEX = new RegExp('^\\d+$');

/** Used to find letters (one-by-one) within Strings. It's a (g)lobal regex, reset before use. */
const ANY_LETTER_CASE_INSENSITIVE = new RegExp('[A-Za-z]', 'g');

/** Regex used by 1Date.getFormatter()`.  It's (g)lobal, requires reset before use.  */
const DATE_FORMATTER_LETTERS = new RegExp('(y+|M+|d+|H+|h+|m+|s+|S+|a+|E+|Z+)', 'g');

const TIME_REGEX = new RegExp('^(\\d{1,2}):(\\d{2})(?::(\\d{2})(?:\\.(\\d{1,3}))?)?$');
const SERIALIZED_DATE_REGEX = new RegExp('^@Date:(\\d+)$');

/** Used by <code>Date.isoDateInfo</code>. */
const ISO_DATETIME_REGEX = new RegExp('^((\\d{4})\\-?(\\d{2})\\-?(\\d{2}))(?:T((\\d{2})'
  + '(?::?(\\d{2})(?::?(\\d{2})(?:\\.(\\d{1,3}))?)?)?)([\\+\\-]\\d{1,2}:?(?:\\d{2})?)?(Z)?)?');

/** Used by <code>Dates.stdDateInfo</code>. */
const STANDARD_DATETIME_REGEX = new RegExp('^((\\d{2}(?:\\d{2})?)\\-(\\d{1,2})\\-(\\d{1,2}))'
  + '(?: +((\\d{1,2})(?::(\\d{2})(?::(\\d{2})(?:\\.(\\d{1,3}))?)?)?))?$');

/**
 * Cache of truncating functions.
 * @type {Object.<int, Function>}
 */
const _TRUNCATES = {};

/**
 * Cache of rounding functions.
 * @type {Object.<int, Function>}
 */
const _ROUNDS = {};

/**
 * Cache of formatter functions, specific to *local* and *UTC* dates.
 * @type {{local: Object.<string, Function>, utc: Object.<string, Function>}}
 */
const _FORMATTERS = {
  local: {},
  utc: {},
  localToCst: {},
};
const diffToCst = Math.abs((moment().tz('America/Chicago').utcOffset() / 60));
/**
 * @type {{local: Dates.ValueHandlers, utc: Dates.ValueHandlers}}
 * @private
 */
const _HANDLERS = {
  localToCst: {
    getter: {
      year(date) { return moment.utc(date).local().add(diffToCst, 'hour').year(); },
      month(date) { return moment.utc(date).local().add(diffToCst, 'hour').month(); },
      day(date) { return moment.utc(date).local().add(diffToCst, 'hour').date(); },
      dayOfWeek(date) { return moment.utc(date).local().add(diffToCst, 'hour').weekday(); },
      hours(date) { return moment.utc(date).local().add(diffToCst, 'hour').hours(); },
      minutes(date) { return moment.utc(date).local().add(diffToCst, 'hour').minutes(); },
      seconds(date) { return moment.utc(date).local().add(diffToCst, 'hour').seconds(); },
      milliseconds(date) { return moment.utc(date).local().add(diffToCst, 'hour').milliseconds(); },
      utcOffset(date) { return (-1 * date.getTimezoneOffset()); },
    },

    setter: {
      year(date, val) { return moment(date).toDate().setFullYear(val); },
      month(date, val) { return moment(date).toDate().setMonth(val); },
      day(date, val) { return moment(date).toDate().setDate(val); },
      hours(date, val) { return moment(date).toDate().setHours(val); },
      minutes(date, val) { return moment(date).toDate().setMinutes(val); },
      seconds(date, val) { return moment(date).toDate().setSeconds(val); },
      milliseconds(date, val) { return date.setMilliseconds(val); },
    },
  },

  local: {
    getter: {
      year(date) { return date.getFullYear(); },
      month(date) { return date.getMonth(); },
      day(date) { return date.getDate(); },
      dayOfWeek(date) { return date.getDay(); },
      hours(date) { return date.getHours(); },
      minutes(date) { return date.getMinutes(); },
      seconds(date) { return date.getSeconds(); },
      milliseconds(date) { return date.getMilliseconds(); },
      utcOffset(date) { return (-1 * date.getTimezoneOffset()); },
    },

    setter: {
      year(date, val) { return date.setFullYear(val); },
      month(date, val) { return date.setMonth(val); },
      day(date, val) { return date.setDate(val); },
      hours(date, val) { return date.setHours(val); },
      minutes(date, val) { return date.setMinutes(val); },
      seconds(date, val) { return date.setSeconds(val); },
      milliseconds(date, val) { return date.setMilliseconds(val); },
    },
  },

  utc: {
    getter: {
      year(date) { return date.getUTCFullYear(); },
      month(date) { return date.getUTCMonth(); },
      day(date) { return date.getUTCDate(); },
      dayOfWeek(date) { return date.getUTCDay(); },
      hours(date) { return date.getUTCHours(); },
      minutes(date) { return date.getUTCMinutes(); },
      seconds(date) { return date.getUTCSeconds(); },
      milliseconds(date) { return date.getUTCMilliseconds(); },
      utcOffset() { return 0; },
    },

    setter: {
      year(date, val) { return date.setUTCFullYear(val); },
      month(date, val) { return date.setUTCMonth(val); },
      day(date, val) { return date.setUTCDate(val); },
      hours(date, val) { return date.setUTCHours(val); },
      minutes(date, val) { return date.setUTCMinutes(val); },
      seconds(date, val) { return date.setUTCSeconds(val); },
      milliseconds(date, val) { return date.setUTCMilliseconds(val); },
    },
  },
};


let _shortYear = null;
let _currCentury = null;
let _prevCentury = null;
let _nextCentury = null;

(function (year) {
  _shortYear = year % 100;
  _currCentury = year - _shortYear;
  _prevCentury = _currCentury - 100;
  _nextCentury = _currCentury + 100;
}((new Date()).getUTCFullYear()));


/* **************************************************
 * Private methods
 * ************************************************** */

const _now = ((Date.now)
  ? () => Date.now()
  : () => (new Date()).getTime()
);

/** @returns {int} Integer representation of `s`, may be NaN. */
function _intOf(s) {
  return parseInt(s, 10);
}

/**
 * @param {(Date|*)} d1
 * @param {(Date|*)} d2
 * @returns {boolean} Whether `d1` and `d2` are two Date objects that represent the same instant,
 *          false if either argument is not a Date object.
 * @private
 */
function _areEqual(d1, d2) {
  return ((d1 instanceof Date)
        && (d2 instanceof Date)
        && d1.getTime() === d2.getTime());
}

/**
 * Returns whether a given year is a leap year or not.
 * @param {int} year - Cannot be zero.
 * @return {boolean}
 */
function _isLeapYearUnchecked(year) {
  return (year % 4 === 0
            && (year % 100 !== 0
            || year % 400 === 0));
}

/**
 * Returns whether a given year is a leap year or not.
 * @param {int} year - Cannot be zero.
 * @return {boolean}
 * @throws {TypeError} If `year` is not an integer.
 */
function _isLeapYear(year) {
  return _isLeapYearUnchecked(Numbers.requireInteger(year, 'year'));
}

/**
 * @param {int} year
 * @returns {int} Number of days in February, 28 or 29.
 * @private
 */
function _numDaysInFeb(year) {
  return (_isLeapYear(year) ? 29 : 28);
}

/**
 * Returns the number of days in a month.
 * @param {int} month - month value as defined by the Calendar object (0-11);
 * @param {int} year - full 4-digit year
 * @return {int} 28, 29, 30 or 31
 */
function _numDaysInMonth(month, year) {
  _validMonthInt(month);

  if (month === 3 // April
        || month === 5 // June
        || month === 8 // September
        || month === 10) { // November
    return 30;
  }
  if (month !== 1) { // Not February
    return 31;
  }
  return _numDaysInFeb(year);
}


function _tzOffsetToStringBuild(offset, hhmmDelim) {
  let sign;

  if (offset < 0) {
    offset = Math.abs(offset);
    sign = '-';
  } else { sign = '+'; }

  return (sign
        + leadingZeros(Math.floor(offset / 60), 2)
        + hhmmDelim
        + leadingZeros(offset % 60, 2));
}

function _tzOffsetToString(offset) {
  return _tzOffsetToStringBuild(offset, '');
}

function _tzOffsetToLongString(offset) {
  return _tzOffsetToStringBuild(offset, ':');
}

/** @returns {boolean} Whether `month` is an integer within 0-11 range. */
function _isValidMonth(month) {
  return Numbers.isIntegerBetween(month, 0, 11);
}

/**
 * Validates `month` to be an integer between 0 (January) and 11 (December), inclusive.
 * @param {int} month
 * @returns {int} Always returns `month`.
 * @throws {TypeError} If `month` is not valid.
 * @private
 */
function _validMonthInt(month) {
  if (!_isValidMonth(month)) { throw new TypeError('month: Integer, 0-11'); }
  return month;
}


/** @returns {boolean} Whether `dayOfWeek` is an integer in 0-6 range, inclusive. */
function _isDayOfWeek(dayOfWeek) {
  return Numbers.isIntegerBetween(dayOfWeek, 0, 6);
}

/**
 * Validates `dayOfWeek` to be an integer between 0 (Sunday) and 6 (Saturday), inclusive.
 * @param {int} dayOfWeek 0-6
 * @returns {int} Always returns `dayOfWeek`.
 * @throws {Error} If `dayOfWeek` is not valid.
 * @private
 */
function _validDayOfWeek(dayOfWeek) {
  if (!_isDayOfWeek(dayOfWeek)) { throw new Error('dayOfWeek: Integer, 0-6'); }

  return dayOfWeek;
}


/**
 * Validates that `arg` is a Date object, throws if it isn't.
 * @param {Date} arg
 * @param {string} name
 * @returns {Date}
 * @throws {TypeError} - If `arg` is not a Date.
 * @private
 */
function _requireDate(arg, name) {
  if (!(arg instanceof Date)) { throw new TypeError(`${name }: Date`); }

  return arg;
}

/**
 * Validates that `time` is an integer between 0 and MILLIS_PER_DAY (exclusive).
 * If valid, this method returns the given argument.  Otherwise it throws.
 * @param {int} time
 * @param {string} name
 * @returns {int} - `time`
 * @private
 */
function _requireTime(time, name) {
  return Numbers.requireIntegerBetween(time, 0, MILLIS_PER_DAY - 1, name);
}

/**
 * Returns the number of time-units found in `millis`.
 * @param {int} millis - Range from 0 to MILLIS_PER_DAY (exclusive).
 * @param {int} unit - Unit size.
 * @param {int} nextUnit - Higher unit size.
 * @returns {int}
 * @private
 */
function _toTimeUnit(millis, unit, nextUnit) {
  return Math.floor((millis % nextUnit) / unit);
}

/**
 *
 * @param {int} year Short year, 0-99 inclusive.
 * @returns {int} Long, 4-digit year.
 * @private
 */
function _toLongYear(year) {
  if ((_shortYear < 50 && year < 50) // If input year is in same half of century
        || (_shortYear >= 50 && year >= 50)) {
    // as current year, return current century.
    return year + _currCentury;
  }

  const diff = _shortYear - year;
  if (diff < 50 && diff > -50) return year + _currCentury;
  if (_shortYear < 50 && year >= 50) return year + _prevCentury;
  return year + _nextCentury;
}

/**
 * Expands a 2-digit year value to a 4-digit year.
 *
 * @param {(int|string)} year - 2-digit year value
 * @return {int} 4-digit year value
 */
function _getFullYear(year) {
  if (typeof year === 'number') {
    Numbers.requireInteger(year, 'year');

    // We can only calculate the full year on a 2-digit year value.
    if (year < 0 || year > 99) {
      return year;
    }
    return _toLongYear(year);
  }
  if (typeof year === 'string') {
    if (!ALL_DIGIT_REGEX.test(year)) {
      throw new Error('year: String that contains digits only');
    }
    const len = year.length;
    const yr = parseInt(year, 10);
    if (len !== 2) {
      return yr;
    }
    return _toLongYear(yr);
  }
  throw new TypeError('year: Integer or String (digits-only)');
}

/**
 * @param {int} epoch Epoch value, in millis seconds.
 * @param {int} periodSize Period size, in millis.
 * @returns {int} Number of millis that pertains to the last period, determined by `periodSize`.
 *          This method correctly handles times before 1970.
 * @private
 */
function _millisInPeriod(epoch, periodSize) {
  let ms = epoch % periodSize;
  if (ms < 0) {
    ms += periodSize;
  }
  return ms;
}

/**
 * @param {Date} date Date object for which UTC values are the only values considered.
 * @param {int} periodSize Period size, in millis.
 * @returns {int} Number of millis that pertains to the last period, determined by `periodSize`.
 *          This method correctly handles times before 1970.
 * @private
 */
function _millisInPeriodUTC(date, periodSize) {
  return _millisInPeriod(date.getTime(), periodSize);
}

/**
 * @param {Date} date Date object for which local values are the only values considered.
 * @param {int} periodSize Period size, in millis.
 * @returns {int} Number of millis that pertains to the last period, determined by `periodSize`.
 *          This method correctly handles times before 1970.
 * @private
 */
function _millisInPeriodLocal(date, periodSize) {
  return _millisInPeriod(
    date.getTime() - (date.getTimezoneOffset() * MILLIS_PER_MINUTE),
    periodSize,
  );
}

/**
 * Adds months to the given date object.
 * @param {Dates.ValueHandlers} handlers
 * @param {Date} date
 * @param {int} numMonths
 * @returns {Date} Modified `date` object.
 * @private
 */
function _addMonth(handlers, date, numMonths) {
  _requireDate(date, 'date');
  Numbers.requireInteger(numMonths, 'numMonths');

  if (numMonths === 0) { return date; }

  const { getter } = handlers;
  const { setter } = handlers;
  const mosInYear = 12;
  let yr = getter.year(date);
  let mo = getter.month(date);

  mo += numMonths;
  if (numMonths > 0) {
    yr += Math.floor(mo / mosInYear);
    mo %= mosInYear;
  } else if (mo < 0) {
    yr += Math.floor(mo / mosInYear);
    mo = (mosInYear + (mo % mosInYear)) % mosInYear;
  }

  const max = _numDaysInMonth(mo, yr);
  if (getter.day(date) > max) { setter.day(date, max); }

  setter.year(date, yr);
  setter.month(date, mo);
  return date;
}

/**
 * Converts a date object into a string.
 * @param {Dates.ValueGetters} getter
 * @param {Date} date
 * @param {string} delim Delimiter used between date and time.
 * @param {int} [level=2] Level of details to included in the resulting string:
 * 0=year, 1=month, 2=day of month, 3=hours, 4=minutes, 5=seconds, 6=milliseconds, 7=UTC offset.
 * @returns {string} String representation of `date`, as "9999-12-31<delim>HH:mm:ss.SSSZ",
 *          truncated to the specified level.
 * @private
 */
function _dateToString(getter, date, delim, level) {
  if (date === null) { return 'null'; }

  if (typeof level === 'undefined') { level = 2; }

  let str = leadingZeros(getter.year(date), 4);
  if (level > 0) {
    str += `-${ leadingZeros(getter.month(date) + 1, 2)}`;
    if (level > 1) {
      str += `-${ leadingZeros(getter.day(date), 2)}`;
      if (level > 2) { str += delim + _timeToString(getter, date, level - 3); }
    }
  }
  return str;
}

/**
 * Converts a date object into a time-only string.
 * @param {Dates.ValueGetters} getter
 * @param {Date} date
 * @param {int} [level=2] Level of details to included in the resulting string:
 * 0=hours, 1=minutes, 2=seconds, 3=milliseconds, 4=UTC offset.
 * @returns {string} Time representation of `date`, as string, formatted "HH:mm:ss.SSSZ",
 *          truncated to the specified level.
 * @private
 */
function _timeToString(getter, date, level) {
  let str = leadingZeros(getter.hours(date), 2);
  if (level > 0) {
    str += `:${ leadingZeros(getter.minutes(date), 2)}`;
    if (level > 1) {
      str += `:${ leadingZeros(getter.seconds(date), 2)}`;
      if (level > 2) {
        str += `.${ leadingZeros(getter.milliseconds(date), 3)}`;

        if (level > 3) { str += _tzOffsetToString(getter.utcOffset(date)); }
      }
    }
  }
  return str;
}

/**
 * @param {Dates.ValueGetters} getter
 * @param {Date} date
 * @returns {int} Precision level for the given `date` object, range 2-6 inclusive:
 *          6=milliseconds, 5=seconds, 4=minutes, 3=hours, 2=day of month.
 * @private
 */
function _getDatePrecision(getter, date) {
  if (!(date instanceof Date)) throw new Error('IllegalArgumentException: date must be a valid Date object.');

  if (getter.milliseconds(date) > 0) return 6;
  if (getter.seconds(date) > 0) return 5;
  if (getter.minutes(date) > 0) return 4;
  if (getter.hours(date) > 0) return 3;
  return 2;
}

/**
 * @param {Dates.ValueGetters} getter
 * @param {?Date} date
 * @returns {?int} Integer representation of `date`, as yyyyMMdd, null if `date` is null.
 * @private
 */
function _dateToInt(getter, date) {
  if (date === null) { return null; }

  _requireDate(date, 'date');

  return (getter.year(date) * 10000)
        + ((getter.month(date) + 1) * 100)
        + getter.day(date);
}

/**
 * Converts a ParsedDate object into a local-time Date object.
 * @param {?ParsedDate} parsedDate
 * @returns {?Date} Local-time Date object, or null.
 * @private
 */
function _dateInfoToDate(parsedDate) {
  if (parsedDate === null) {
    return null;
  }
  return new Date(
    parsedDate.year(),
    parsedDate.month() - 1,
    parsedDate.day(),
    parsedDate.hours(),
    parsedDate.minutes(),
    parsedDate.seconds(),
    parsedDate.milliseconds(),
  );
}

/**
 * Converts a ParsedDate object into a UTC-time Date object.
 * @param {?ParsedDate} parsedDate
 * @param {boolean} [applyTimeOffset=false] Whether to apply UTC-offset from `parsedDate`.
 * @returns {?Date} UTC-time Date object, or null.
 * @private
 */
function _dateInfoToUTCDate(parsedDate, applyTimeOffset) {
  if (parsedDate === null) {
    return null;
  }

  const offset = ((applyTimeOffset === true)
    ? parsedDate.timezoneOffset()
    : 0
  );

  const utcMillis = Date.UTC(
    parsedDate.year(),
    parsedDate.month() - 1,
    parsedDate.day(),
    parsedDate.hours(),
    parsedDate.minutes(),
    parsedDate.seconds(),
    parsedDate.milliseconds(),
  );

  return new Date(utcMillis - offset); // Negate the offset results in a true UTC date.
}

/**
 * Converts a given string into a ParsedDate object.  This method returns null if the string
 * is not a recognized format, contains out-of-range values, or if `strDate` is null.
 * The only recognized format is "yyyy-MM-dd HH:mm:ss.SSSZ", with optional time values.
 * @param {?string} strDate
 * @returns {?ParsedDate} Null if unrecognized format, contains out-of-range values,
 *          or `strDate==null`.
 * @private
 */
function _validDateInfo(strDate) {
  const dateInfo = _parseStandardDate(strDate);
  if (dateInfo === null) return null;

  if (dateInfo.year() !== 0
        && dateInfo.month() > 0
        && dateInfo.month() < 13
        && dateInfo.day() > 0
        && dateInfo.day() <= _numDaysInMonth(dateInfo.month() - 1, dateInfo.year())
        && dateInfo.hours() < 24
        && dateInfo.minutes() < 60
        && dateInfo.seconds() < 60) return dateInfo;
  return null;
}


/**
 * Reads a standard date (yyyy-MM-dd HH:mm:ss.SSS) and returns its values, or null.
 * @param {(string|*)} stdDate String value to parse for date information.
 * @returns {?ParsedDate} New ParsedDate object, null if `stdDate` cannot be parsed.
 */
function _parseStandardDate(stdDate) {
  if (typeof stdDate !== 'string') return null;

  const match = STANDARD_DATETIME_REGEX.exec(stdDate);
  if (match === null) return null;
  return new ParsedDateStd(match);
}

/**
 * @param {Arguments} dates May contain non-date objects. WARNING: Not an array!
 * @param {Function<boolean, Date, Date>} predicate
 * @returns {Date}
 * @throws {Error} If no date found in `dates`.
 * @private
 */
function _getBest(dates, predicate) {
  let best = null;
  for (let i = 0, len = dates.length; i < len; i++) {
    const date = dates[i];
    if (Dates.isDate(date)) {
      if (best === null || predicate(best, date)) {
        best = date;
      }
    }
  }
  if (best !== null) {
    return best;
  }
  throw new Error('no dates found in argument list');
}
/**
 *
 * @param {Dates.ValueGetters} getter
 * @param {Date} lowDate
 * @param {Date} highDate
 * @returns {int} Number of months between `highDate` and `lowDate`, ignoring day-of-month.
 * @private
 */
function _diffMos(getter, lowDate, highDate) {
  _requireDate(lowDate, 'lowDate');
  _requireDate(highDate, 'highDate');

  return ((getter.year(highDate) * 12) + getter.month(highDate))
         - ((getter.year(lowDate) * 12) + getter.month(lowDate));
}


/**
 * Creates a new date-formatter function for `format`.
 * @param {string} format
 * @param {Dates.ValueGetters} getter
 * @returns {Function} Formatter function which takes Date object and returns String.
 * @private
 */
function _newDateFormatter(format, getter) {
  // y+|M+|d+|H+|h+|m+|s+|S+|a+|E+|Z+
  DATE_FORMATTER_LETTERS.exec(''); // reset "global" RegExp object.

  const regex = DATE_FORMATTER_LETTERS;
  const instr = [];
  let lastIdx = 0;
  let match = regex.exec(format);
  let idx;
  let len;
  let chr;

  while (match !== null) {
    idx = match.index;
    len = match[0].length;
    chr = match[0].charAt(0);

    if (idx > lastIdx) { instr.push(format.substring(lastIdx, idx)); }

    switch (chr) {
      case 'y': // year
        instr.push(_formatYear(getter, len));
        break;

      case 'M': // month
        instr.push(_formatMonth(getter, len));
        break;

      case 'd': // day of month
        instr.push(_formatDayOfMonth(getter, len));
        break;

      case 'E': // day of week
        instr.push(_formatDayOfWeek(getter, len));
        break;

      case 'H': // hour (0-23)
        instr.push(_formatHourOfDay(getter, len));
        break;

      case 'h': // hour (1-12)
        instr.push(_formatHourOfDay12(getter, len));
        break;

      case 'm': // minutes
        instr.push(_formatMinutes(getter, len));
        break;

      case 's': // seconds
        instr.push(_formatSeconds(getter, len));
        break;

      case 'S': // milliseconds
        instr.push(_formatMillis(getter, len));
        break;

      case 'a': // am/pm
        instr.push(_formatAmPm(getter, len));
        break;

      case 'Z': // time offset (ex.: "-0500")
        instr.push(_formatTzOffset(getter, len));
        break;
    }

    lastIdx = idx + len;
    match = regex.exec(format);
  }

  if (lastIdx < format.length) { instr.push(format.substring(lastIdx)); }

  return _getFormatterFn(instr, getter);
}

/**
 * Converts a list of instruction into a date-formatter function.
 * @param {Array.<(string|function)>} instr
 * @returns {Function}
 * @private
 */
function _getFormatterFn(instr) {
  const len = instr.length;

  return function (date) {
    if (date === null) return 'null';

    if (!(date instanceof Date)) return '?';


    let str = '';
    let entry;

    for (let i = 0; i < len; i++) {
      entry = instr[i];
      if (typeof entry === 'string') str += entry;
      else { str += entry(date); }
    }

    return str;
  };
}

/** Date formatter utility method for "y". */
function _formatYear(getter, len) {
  const yr = getter.year;

  if (len >= 4) {
    return function (date) {
      return leadingZeros(yr(date), len);
    };
  }

  switch (len) {
    // In order of likeliness...
    case 2:
      return function (date) {
        return leadingZeros(yr(date) % 100, 2);
      };

    case 1:
      return function (date) {
        return (yr(date) % 10).toString(10);
      };

    case 3:
      return function (date) {
        return leadingZeros(yr(date) % 1000, 3);
      };
  }
}

/** Date formatter utility method for "M". */
function _formatMonth(getter, len) {
  const mo = getter.month;

  switch (len) {
    case 1:
      return function (date) {
        return (mo(date) + 1).toString(10);
      };

    case 2:
      return function (date) {
        return leadingZeros(mo(date) + 1, 2);
      };

    case 3: // 3 means we want to see the month's abbreviation
      return function (date) {
        return Text.monthAbbrevs[mo(date)];
      };

    default: // 4 or more, display month's full name
      return function (date) {
        return Text.monthNames[mo(date)];
      };
  }
}

/** Date formatter utility method for "E". */
function _formatDayOfWeek(getter, len) {
  const dow = getter.dayOfWeek;

  switch (len) {
    case 1:
      return function (date) {
        return (dow(date) + 1).toString(10);
      };

    case 2:
      return function (date) {
        return leadingZeros(dow(date) + 1, 2);
      };

    case 3: // 3 means we want to see the day's abbreviation
      return function (date) {
        return Text.dayAbbrevs[dow(date)];
      };

    default: // 4 or more, display day's full name
      return function (date) {
        return Text.dayNames[dow(date)];
      };
  }
}

/**
 * Date-formatter utility method, handles a numeric values  such as "dd", "HH", "mm", "ss", "SSS".
 */
function _formatDateNumeric(getterFn, len) {
  if (len === 1) {
    return function (date) {
      return getterFn(date).toString(10);
    };
  }

  return function (date) {
    return leadingZeros(getterFn(date), len);
  };
}

/** Date formatter utility method for "d". */
function _formatDayOfMonth(getter, len) {
  return _formatDateNumeric(getter.day, len);
}

/** Date formatter utility method for "H". */
function _formatHourOfDay(getter, len) {
  return _formatDateNumeric(getter.hours, len);
}

/** Date formatter utility method for "h". */
function _formatHourOfDay12(getter, len) {
  return _formatDateNumeric((date) => {
    const h = getter.hours(date) % 12;
    return ((h === 0) ? 12 : h);
  }, len);
}

/** Date formatter utility method for "a". */
function _formatAmPm(getter, len) {
  return function (date) {
    const h = getter.hours(date);
    let rv = ((h < 12) ? Text.am : Text.pm);

    if (len < 2) { rv = rv.charAt(0); }

    return rv;
  };
}

/** Date formatter utility method for "m". */
function _formatMinutes(getter, len) {
  return _formatDateNumeric(getter.minutes, len);
}

/** Date formatter utility method for "s". */
function _formatSeconds(getter, len) {
  return _formatDateNumeric(getter.seconds, len);
}

/** Date formatter utility method for "S". */
function _formatMillis(getter, len) {
  const ms = getter.milliseconds;

  switch (len) {
    case 2: return date => leadingZeros(Math.floor(ms(date) / 10), 2);
    case 1: return date => Math.floor(ms(date) / 100).toString(10);
    default: return date => leadingZeros(ms(date), len);
  }
}

/** Date formatter utility method for "Z". */
function _formatTzOffset(getter, len) {
  const tzToStr = ((len > 1)
    ? _tzOffsetToLongString
    : _tzOffsetToString
  );

  return date => tzToStr(getter.utcOffset(date));
}


/* *****************************************************
 * Inner class (private): ParsedDate
 * ***************************************************** */

/**
 * @constructor
 * @private
 */
function ParsedDate(
  idxYr, idxMo, idxDay,
  idxHr, idxMin, idxSec, idxMs,
  idxOffset, idxZ,
  idxDate, idxTime,
) {
  this._yr = idxYr;
  this._mo = idxMo;
  this._day = idxDay;
  this._hr = idxHr;
  this._min = idxMin;
  this._sec = idxSec;
  this._ms = idxMs;
  this._off = idxOffset;
  this._z = idxZ;
  this._date = idxDate;
  this._time = idxTime;
}

Object.assign(ParsedDate.prototype, /** @lends {ParsedDate.prototype} */ {

  _ifInt(idx) {
    if (this._m[idx]) return _intOf(this._m[idx]);
    return 0;
  },

  year() {
    return _getFullYear(this._m[this._yr]);
  },

  month() { return _intOf(this._m[this._mo]); },
  day() { return _intOf(this._m[this._day]); },

  date() { return this._m[this._date]; },
  time() { return this._m[this._time]; },

  hours() { return this._ifInt(this._hr); },
  minutes() { return this._ifInt(this._min); },
  seconds() { return this._ifInt(this._sec); },

  milliseconds() {
    const m = this._m[this._ms];
    if (m) return parseInt(m, 10) * Math.pow(10, (3 - m.length));
    return 0;
  },

  isUTC() {
    return (this._m[this._z] === 'Z');
  },

  timezoneOffset() {
    if (this.isUTC()) return 0;


    const tzOffset = this._m[this._off];
    if (typeof tzOffset !== 'string'
                || tzOffset === '') throw new Error(`IllegalStateException: time offset not found (${ this._m[0] })`);

    const idx = tzOffset.indexOf(':');
    let // Default format is "-05:00"
      hrs;
    let mins;

    if (idx >= 0) {
      hrs = parseInt(tzOffset.substring(1, idx), 10);
      mins = parseInt(tzOffset.substring(idx + 1), 10);
    } else { // no colon found.
      hrs = parseInt(tzOffset.substring(1, 3), 10);

      if (tzOffset.length > 3) // format is "-0500"
      { mins = parseInt(tzOffset.substring(3), 10); } else mins = 0; // format is "-05"
    }

    let offset = ((hrs * MILLIS_PER_HOUR)
                + (mins * MILLIS_PER_MINUTE));

    if (tzOffset.charAt(0) === '-') offset *= -1;

    return offset;
  },

});


/* *****************************************************
 * Inner class (private): ParsedDateIso
 * ***************************************************** */
/**
 * @constructor
 * @extends {ParsedDate}
 */
function ParsedDateIso(match) {
  this._m = match;
}

ParsedDateIso.prototype = Object.assign(
  new ParsedDate(2, 3, 4, 6, 7, 8, 9, 10, 11, 1, 5),
  { constructor: ParsedDateIso },
);

/* *****************************************************
 * Inner class (private): ParsedDateStd
 * ***************************************************** */
/**
 * @constructor
 * @extends {ParsedDate}
 */
function ParsedDateStd(match) {
  this._m = match;
}

ParsedDateStd.prototype = Object.assign(
  new ParsedDate(2, 3, 4, 6, 7, 8, 9, -1, -1, 1, 5),
  { constructor: ParsedDateStd },
);


/* **************************************************
 * Dates (prototype)
 * ************************************************** */

/** @namespace */
const Dates = Object.freeze(/** @lends {Dates} */ {

  /* ****************************************************
     * STATIC MEMBERS - public
     * **************************************************** */

  /**
     * @deprecated
     * Use MILLIS_PER_SECOND instead.
     *
     * Number of milliseconds per second (1000).
     * @type {int}
     */
  millisPerSecond: MILLIS_PER_SECOND,

  /**
     * Number of milliseconds per second (1000).
     * @type {int}
     */
  MILLIS_PER_SECOND,

  /**
     * @deprecated
     * Use MILLIS_PER_MINUTE instead.
     *
     * Number of milliseconds per minute (60,000).
     * @type {int}
     */
  millisPerMinute: MILLIS_PER_MINUTE,

  /**
     * Number of milliseconds per minute (60,000).
     * @type {int}
     */
  MILLIS_PER_MINUTE,

  /**
     * @deprecated
     * Use MILLIS_PER_HOUR instead.
     *
     * Number of milliseconds per hour (3,600,000).
     * @type {int}
     */
  millisPerHour: MILLIS_PER_HOUR,

  /**
     * Number of milliseconds per hour (3,600,000).
     * @type {int}
     */
  MILLIS_PER_HOUR,

  /**
     * @deprecated
     * Use MILLIS_PER_DAY instead.
     *
     * Number of milliseconds per day (86,400,000).
     * @type {int}
     */
  millisPerDay: MILLIS_PER_DAY,

  /**
     * Number of milliseconds per day (86,400,000).
     * @type {int}
     */
  MILLIS_PER_DAY,

  /**
     * Number of seconds per day (86,400).
     * @type {int}
     */
  SECONDS_PER_DAY,

  /**
     * Number of minutes per day (1,440).
     * @type {int}
     */
  MINUTES_PER_DAY,

  /**
     * Number of minutes per day (24).
     * @type {int}
     */
  HOURS_PER_DAY,

  /** List of contract letters used for Futures data. */
  CONTRACT_LETTERS,

  /* ****************************************************
     * METHODS
     * **************************************************** */

  /** @return {int} Current time as an epoch value (milliseconds since midnight UTC 1/1/1970). */
  currentTimeMillis: _now,

  /** @return {int} Current time as an epoch value (milliseconds since midnight UTC 1/1/1970). */
  now: _now,

  clone(date) {
    return new Date(_requireDate(date, 'date').getTime());
  },


  compare(d1, d2) {
    if (d1 instanceof Date) {
      if (d2 instanceof Date) return d1 - d2; // Normal comparison.
      return -1; // Keep null at the end of the array.
    } if (d2 instanceof Date) return 1; // Push null at the end of the array.
    return 0; // Nothing to compare.
  },

  /**
     * This function returns whether two Date objects represent
     * the same point in time.  This function returns *false*
     * if either (or both) argument(s) is not a Date object.
     *
     * This function is made available because Date objects
     * cannot be compared using the "===" operator.
     */
  equals: _areEqual,

  areEqual: _areEqual,

  /**
     * This function returns whether two Date objects represent
     * the same point in time.  This function also returns *true*
     * if both arguments are null.
     *
     * This function is made available because Date objects
     * cannot be compared using the "===" operator.
     */
  areEqualOrNull(d1, d2) {
    return ((d1 === null
                    && d2 === null)
                || ((d1 instanceof Date)
                    && (d2 instanceof Date)
                    && d1.getTime() === d2.getTime()));
  },

  /**
     * Returns the local time as a UTC date (not the current time in the UTC timezone).
     * That is, if local time is "2012-03-01T09:45:23.789 CST", the return
     * Date object will be "2012-03-01T09:45:23.789 UTC".
     */
  nowInUTC() {
    return Dates.inUTC(new Date()); // now;
  },


  /**
     * Returns an equivalent Date object in the UTC timezone.
     * That is, given "2012-03-01T09:45:23.789 CST", this function
     * returns "2012-03-01T09:45:23.789 UTC".
     */
  inUTC(date) {
    return new Date(Date.UTC(date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()));
  },


  /**
     * Returns an equivalent Date object in the local timezone.
     * This function assumes that <code>date</code> is in UTC time zone.
     * That is, given "2012-03-01T09:45:23.789 UTC", this function
     * returns "2012-03-01T09:45:23.789 CST".
     */
  inLocal(date) {
    const hrsUtc = date.getUTCHours();
    const dt = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      hrsUtc,
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds(),
    );

    if (dt.getHours() < hrsUtc) {
      /* On Spring DST day, we move the clocks 1 hour
             * (a.k.a. Spring Forward).
             *
             * If we request 2AM that day, Firefox returns
             * 3AM while all other browsers return 1AM.
             *
             * We need to have consistency. Because I believe
             * Firefox has its thinking straight, we go their path.
             */
      dt.setTime(dt.getTime() + MILLIS_PER_HOUR);
    }

    return dt;
  },

  /**
     * @param {(Date|*)} arg Argument to validate.
     * @returns {boolean} Whether `arg` is a Date object, never fails.
     */
  isDate(arg) {
    return (arg instanceof Date);
  },

  /**
     * Validates `arg` to be a Date object. If valid, this method returns that
     * argument.  Otherwise it throws.
     * @param {Date} arg - Argument to validate.
     * @param {string} [name="date"] - Name given to the argument.
     * @returns {Date} `arg`
     * @throws {TypeError} - If `arg` is not a Date.
     */
  requireDate(arg, name) {
    return _requireDate(arg, (typeof name === 'string') ? name : 'date');
  },

  /**
     * Validates `arg` to be a Date object or null. If valid, this method returns that
     * argument.  Otherwise it throws.
     * @param {?Date} arg - Argument to validate.
     * @param {string} name - Name given to the argument.
     * @returns {?Date} Always returns `arg`.
     * @throws {TypeError} - If `arg` is not null and not a Date.
     */
  requireDateOrNull(arg, name) {
    if (arg !== null && !(arg instanceof Date)) throw new TypeError(`${name }: Date or null`);

    return arg;
  },

  /**
     * Validates that `arg` is an integer between 0 and MILLIS_PER_DAY (exclusive).
     * If valid, this method returns the given argument.  Otherwise it throws.
     * @param {int} arg - Argument to validate.
     * @param {string} [name="time"] - Name given to the argument.
     * @returns {int} `arg`
     * @throws {TypeError} - If `arg` is not valid.
     */
  requireTime(arg, name) {
    return _requireTime(arg, (typeof name === 'string') ? name : 'time');
  },

  /**
     * @param {...(Date|*)} args
     * @returns {Date} The first Date object found in argument list, from left to right.
     * @throws {Error} If no Date object found in list.
     */
  firstDate(args) {
    const firstDate = Dates.firstDateOrNull(...arguments);
    if (firstDate !== null) {
      return firstDate;
    }
    throw new Error('No date found in argument list');
  },

  /**
     * @param {...(Date|*)} args
     * @returns {?Date} The first Date object found in argument list (left to right), or null.
     */
  firstDateOrNull(args) {
    const list = arguments;
    for (let i = 0, len = list.length; i < len; i++) {
      if (Dates.isDate(list[i])) {
        return list[i];
      }
    }
    return null;
  },

  /**
     * Returns the lowest date found within the list of arguments. This method
     * ignores non-date arguments.
     * @param {...(Date|*)} dates
     * @returns {Date}
     * @throws {Error} If no valid dates were found in the argument list.
     */
  lowest(dates) {
    return _getBest(arguments, (lowest, date) => (lowest > date));
  },

  /**
     * Returns the highest date found within the list of arguments. This method
     * ignores non-date arguments.
     * @param {...(Date|*)} dates
     * @returns {Date}
     * @throws {Error} If no valid dates were found in the argument list.
     */
  highest(dates) {
    return _getBest(arguments, (highest, date) => (highest < date));
  },

  isSerialized(serializedDate) {
    return (typeof serializedDate === 'string'
                && SERIALIZED_DATE_REGEX.test(serializedDate));
  },

  serialize(date) {
    return `@Date:${ _requireDate(date, 'date').getTime()}`;
  },

  /** @returns {Date} New Date object created from previously serialized value. */
  deserialize(serializedDate) {
    if (!Dates.isSerialized(serializedDate)) {
      throw new Error('IllegalArgumentException: serializedDate is not a serialized Date object.');
    }

    return new Date(parseInt(serializedDate.substring(6), 10));
  },

  /**
     * Adds months to the given Date object, or subtracts months if <code>numMonths</code>
     * is negative.  This function modifies the <code>date</code> object.
     * @param {Date} date - local timezone
     * @param {int} numMonths
     * @returns {Date} `date`
     */
  addMonth(date, numMonths) {
    return _addMonth(_HANDLERS.local, date, numMonths);
  },


  /**
     * Adds years to the given Date object, or subtracts years if <code>numYears</code>
     * is negative.  This function modifies the <code>date</code> object.
     * @param {Date} date - local timezone
     * @param {int} numYears
     * @returns {Date} `date`
     */
  addYear(date, numYears) {
    return Dates.addMonth(
      _requireDate(date, 'date'),
      Numbers.requireInteger(numYears, 'numYears') * 12,
    );
  },


  /**
     * Adds months to the given Date object, or subtracts months if <code>numMonths</code>
     * is negative.  This function modifies the <code>date</code> object.
     * @param {Date} date - UTC timezone
     * @param {int} numMonths
     * @returns {Date} `date`
     */
  addMonthUTC(date, numMonths) {
    return _addMonth(_HANDLERS.utc, date, numMonths);
  },


  /**
     * Adds years to the given Date object, or subtracts years if <code>numYears</code>
     * is negative.  This function modifies the <code>date</code> object.
     * @param {Date} date - UTC timezone
     * @param {int} numYears
     * @returns {Date} `date`
     */
  addYearUTC(date, numYears) {
    return Dates.addMonthUTC(
      _requireDate(date, 'date'),
      Numbers.requireInteger(numYears, 'numYears') * 12,
    );
  },

  /** @returns {boolean} Whether `month` is an integer within 0-11 range. */
  isValidMonth(month) {
    return _isValidMonth(month);
  },

  /** @returns {boolean} Whether `dayOfWeek` is an integer in 0-6 range, inclusive. */
  isDayOfWeek(dayOfWeek) {
    return _isDayOfWeek(dayOfWeek);
  },

  /**
     * Returns whether a given year is a leap year or not.
     * @param {int} year - Cannot be zero.
     * @return {boolean}
     */
  isLeapYear(year) {
    return _isLeapYear(year);
  },


  /**
     * Returns the number of days in the month of February of a given year.
     * @param {int} year - Cannot be zero.
     * @returns {int} Number of days in February, 28 or 29.
     */
  numDaysInFebruary: _numDaysInFeb,


  /**
     * Returns the number of days in a month.
     * @param {int} month - month value as defined by the Calendar object (0-11);
     * @param {int} year - full 4-digit year
     * @return {int} 28, 29, 30 or 31
     */
  numDaysInMonth: _numDaysInMonth,

  /**
     * Returns the number of days in the given year.
     * @param {int} year
     * @returns {int} 365 or 366.
     */
  numDaysInYear(year) {
    return (_isLeapYear(year) ? 366 : 365);
  },

  /**
     * Expands a 2-digit year value to a 4-digit year.
     *
     * @param {(int|string)} year - 2-digit year value
     * @return {int} 4-digit year value
     */
  getFullYear: year => _getFullYear(year),

  /**
     * @param {string} str
     * @returns {boolean} Whether the given string is a parsable ISO date value.
     */
  isIsoDate(str) {
    requireString(str, 'str');
    return ISO_DATETIME_REGEX.test(str);
  },

  /**
     * @param {string} str String to test.
     * @returns {boolean} Whether the given string is formatted "9999-12-31".
     * @throws {TypeError} If `str` is not a string.
     */
  isIsoDateOnly(str) {
    requireString(str, 'str');
    return ISO_DATE_REGEX.test(str);
  },

  /**
     * Reads an ISO date and return its individual values.
     * Call this method with a 2nd parameter of <code>true</code>
     * to get more useful information about the ISO date.
     * @returns {ParsedDate}
     */
  isoDateInfo(isoDateString, allValues) {
    if (typeof isoDateString !== 'string') return null;

    const match = ISO_DATETIME_REGEX.exec(isoDateString);
    if (match === null) return null;
    return new ParsedDateIso(match);
  },

  /**
     * Reads a standard date (yyyy-MM-dd HH:mm:ss.SSS) and returns its values, or null.
     * @param {(string|*)} stdDate String value to parse for date information.
     * @returns {?ParsedDate} New ParsedDate object, null if `stdDate` cannot be parsed.
     */
  stdDateInfo: _parseStandardDate,


  dateInfo(date, isUTC) {
    _requireDate(date, 'date');

    const g = ((isUTC) ? _HANDLERS.utc.getter : _HANDLERS.local.getter);

    return {
      year: g.year(date),
      month: g.month(date),
      day: g.day(date),
      hour: g.hours(date),
      minute: g.minutes(date),
      second: g.seconds(date),
      millisecond: g.milliseconds(date),
    };
  },


  dateInfoGetter(useUTC) {
    if (useUTC === true) return _HANDLERS.utc.getter;
    return _HANDLERS.local.getter;
  },


  /**
     * @param {Date} date
     * @param {int} level Level of information: 0=HH, 1=HH:mm, 2=HH:mm:ss, 3=HH:mm:ss.SSS.
     * @returns {string} String representation of time value of `date`.
     */
  timeToString(date, level) {
    return _timeToString(_HANDLERS.local.getter, date, level);
  },
  /**
     * @param {Date} date
     * @param {int} level Level of information: 0=HH, 1=HH:mm, 2=HH:mm:ss, 3=HH:mm:ss.SSS.
     * @returns {string} String representation of time value of UTC `date`.
     */
  utcTimeToString(date, level) {
    return _timeToString(_HANDLERS.utc.getter, date, level);
  },

  dateToString(date, level) {
    return _dateToString(_HANDLERS.local.getter, date, ' ', level);
  },
  dateToIsoString(date, level) {
    return _dateToString(_HANDLERS.local.getter, date, 'T', level);
  },

  utcDateToString(date, level) {
    return _dateToString(_HANDLERS.utc.getter, date, ' ', level);
  },

  utcDateToIsoString(date, level) {
    return _dateToString(_HANDLERS.utc.getter, date, 'T', level);
  },

  /**
     * Returns an integer between in the 0-6 range,
     * indicating the significant precision of a measurement
     * in milliseconds.
     *
     * <ul>
     *  <li> 0: year </li>
     *  <li> 1: month </li>
     *  <li> 2: day </li>
     *  <li> 3: hours </li>
     *  <li> 4: minutes </li>
     *  <li> 5: seconds </li>
     *  <li> 6: milliseconds </li>
     * </ul>
     *
     * @param {int} msMeasure Positive integer.
     * @return {int} Range 0-6.
     */
  getMillisPrecision(msMeasure) {
    Numbers.requirePositiveInteger(msMeasure, 'msMeasure');

    if (msMeasure >= MILLIS_PER_YEAR) return 0;
    if (msMeasure >= MILLIS_PER_MONTH) return 1;
    if (msMeasure >= MILLIS_PER_DAY) return 2;
    if (msMeasure >= MILLIS_PER_HOUR) return 3;
    if (msMeasure >= MILLIS_PER_MINUTE) return 4;
    if (msMeasure >= MILLIS_PER_SECOND) return 5;
    return 6;
  },

  /**
     * Returns an integer between in the 2-6 range,
     * indicating the significant precision of a (local) date object.
     *
     * <ul>
     *  <li> 2: day </li>
     *  <li> 3: hours </li>
     *  <li> 4: minutes </li>
     *  <li> 5: seconds </li>
     *  <li> 6: milliseconds </li>
     * </ul>
     *
     * @param {Date} date
     * @return {int} Range 2-6.
     */
  getDatePrecision(date) {
    return _getDatePrecision(_HANDLERS.local.getter, date);
  },

  /**
     * Returns an integer between in the 2-6 range,
     * indicating the significant precision of a UTC date object.
     *
     * <ul>
     *  <li> 2: day </li>
     *  <li> 3: hours </li>
     *  <li> 4: minutes </li>
     *  <li> 5: seconds </li>
     *  <li> 6: milliseconds </li>
     * </ul>
     *
     * @param {Date} date
     * @return {int} Range 2-6.
     */
  getUtcDatePrecision(date) {
    return _getDatePrecision(_HANDLERS.utc.getter, date);
  },

  /** Returns an Number (integer) in the format of yyyyMMdd. */
  dateToDateInt(date) {
    return _dateToInt(_HANDLERS.local.getter, date);
  },


  /** Returns an Number (integer) in the format of yyyyMMdd. */
  utcDateToDateInt(date) {
    return _dateToInt(_HANDLERS.utc.getter, date);
  },

  /**
     * Computes the milliseconds-since-midnight value.
     * @param {int} [hr=0] - Hour of the day (0-23)
     * @param {int} [min=0] - Minute of the hour (0-59)
     * @param {int} [sec=0] - Second of the minute (0-59)
     * @param {int} [ms=0] - Millisecond of the second (0-999)
     * @returns {int} Milliseconds since midnight, range 0 to MILLIS_PER_DAY (exclusive).
     * @throws {TypeError} - If any of the argument provided is invalid.
     */
  toTime(hr, min, sec, ms) {
    let h = 0;
    let m = 0;
    let s = 0;
    let z = 0;
    const numArgs = arguments.length;

    if (numArgs > 0) {
      h = Numbers.requireIntegerBetween(hr, 0, 23, 'hr');
      if (numArgs > 1) {
        m = Numbers.requireIntegerBetween(parseInt(min, 10), 0, 59, 'min');
        if (numArgs > 2) {
          s = Numbers.requireIntegerBetween(sec, 0, 59, 'sec');
          if (numArgs > 3) {
            z = Numbers.requireIntegerBetween(ms, 0, 999, 'ms');
          }
        }
      }
    }

    return (
      (h * MILLIS_PER_HOUR)
            + (m * MILLIS_PER_MINUTE)
            + (s * MILLIS_PER_SECOND)
            + z
    );
  },

  /**
     * @param {int} time - Milliseconds since midnight, 0 - MILLIS_PER_DAY (exclusive).
     * @returns {int} Hour of the day (0-23)
     * @throws {TypeError} - If `time` is not an integer within acceptable range.
     */
  extractHours(time) {
    return _toTimeUnit(
      _requireTime(time, 'time'),
      MILLIS_PER_HOUR,
      MILLIS_PER_DAY,
    );
  },

  /**
     * @param {int} time - Milliseconds since midnight, 0 - MILLIS_PER_DAY (exclusive).
     * @returns {int} Minute of the hour (0-59)
     * @throws {TypeError} - If `time` is not an integer within acceptable range.
     */
  extractMinutes(time) {
    return _toTimeUnit(
      _requireTime(time, 'time'),
      MILLIS_PER_MINUTE,
      MILLIS_PER_HOUR,
    );
  },

  /**
     * @param {int} time - Milliseconds since midnight, 0 - MILLIS_PER_DAY (exclusive).
     * @returns {int} Second of the minute (0-59)
     * @throws {TypeError} - If `time` is not an integer within acceptable range.
     */
  extractSeconds(time) {
    return _toTimeUnit(
      _requireTime(time, 'time'),
      MILLIS_PER_SECOND,
      MILLIS_PER_MINUTE,
    );
  },

  /**
     * @param {int} time - Milliseconds since midnight, 0 - MILLIS_PER_DAY (exclusive).
     * @returns {int} Millisecond of the second (0-999)
     * @throws {TypeError} - If `time` is not an integer within acceptable range.
     */
  extractMilliseconds(time) {
    return _toTimeUnit(
      _requireTime(time, 'time'),
      1,
      MILLIS_PER_SECOND,
    );
  },

  /** Returns the contract letter that corresponds to the given month (0-11 range). */
  monthToContract(month) {
    if (month < 0 || month > 11) throw new Error(`IllegalArgumentException: month must be in 0-11 range (${ month })`);
    return CONTRACT_LETTERS.charAt(month);
  },

  /**
     * Returns the month (0-11 range) associated with <code>contractLetter</code>,
     * or <code>-1</code> if <code>contractLetter</code> is invalid.
     */
  contractToMonth(contractLetter) {
    let idx = -1;
    if (typeof contractLetter === 'string'
            && contractLetter.length === 1) idx = CONTRACT_LETTERS.indexOf(contractLetter);
    return idx;
  },


  /**
     * Returns the full name for the day-of-week in the current language.
     * @param {int} day - 0=Sunday, 1=Monday, ... 6=Saturday
     * @returns {string}
     */
  daysOfWeek(day) {
    return Text.dayNames[_validDayOfWeek(day)];
  },

  /**
     * Returns the abbreviation for the day-of-week in the current language.
     * @param {int} day - 0=Sun, 1=Mon, ... 6=Sat
     * @returns {string}
     */
  daysOfWeekAbbrev(day) {
    return Text.dayAbbrevs[_validDayOfWeek(day)];
  },

  months(month) {
    return Text.monthNames[_validMonthInt(month)];
  },

  /** Returns the abbreviation that corresponds to the given month (0-11 range). */
  monthsAbbrev(month) {
    return Text.monthAbbrevs[_validMonthInt(month)];
  },


  millisInUTCPeriod: _millisInPeriodUTC,

  /**
     * Returns the milliseconds since midnight UTC.
     * @param {Date} date
     * @return {int} Range from 0 to MILLIS_PER_DAY (exclusive).
     */
  millisSinceMidnightUTC(date) {
    // UTC does not honor Daylight Savings Time; all days
    // have the exact same amount of milliseconds.
    // This is why we can simplify the calculation below.
    // This same calculation would not work with a Date object
    // that is timezone specific.  For that situation,
    // we would need to use getHours(), getMinutes(), getSeconds()
    // and getMilliseconds() to calculate the correct value.
    return _millisInPeriodUTC(date, MILLIS_PER_DAY);
  },

  /**
     * Return the current time of day (in local time zone) represented
     * as a millisecond value.
     * @param {Date} date
     * @return {int} Range from 0 to MILLIS_PER_DAY (exclusive).
     */
  millisSinceMidnight(date) {
    return _millisInPeriodLocal(date, MILLIS_PER_DAY);
  },

  /** Returns the milliseconds passed the last hour UTC. */
  millisSinceLastHourUTC(date) {
    /* UTC does not honor Daylight Savings Time; all days
         * have the exact same amount of milliseconds.
         * This is why we can simplify the calculation below.
         * This same calculation would not work with a Date object
         * that is timezone specific.  For that situation,
         * we would need to use getHours(), getMinutes(), getSeconds()
         * and getMilliseconds() to calculate the correct value.
         */
    return _millisInPeriodUTC(date, MILLIS_PER_HOUR);
  },

  /** Returns the milliseconds passed the last minute UTC. */
  millisSinceLastMinuteUTC(date) {
    /* UTC does not honor Daylight Savings Time; all days
         * have the exact same amount of milliseconds.
         * This is why we can simplify the calculation below.
         * This same calculation would not work with a Date object
         * that is timezone specific.  For that situation,
         * we would need to use getHours(), getMinutes(), getSeconds()
         * and getMilliseconds() to calculate the correct value.
         */
    return _millisInPeriodUTC(date, MILLIS_PER_MINUTE);
  },

  /**
     * Returns the day as an integer value,
     * where 1970-01-01 is day 0, 1970-01-02 is day 1
     * and 1969-12-31 is day -1.
     */
  utcDateToDayInt(date) {
    return Math.floor(date.getTime() / MILLIS_PER_DAY);
  },

  /**
     * Returns the hour as an integer value,
     * where 1970-01-01 00 is hour 0, 1970-01-01 01 is hour 1
     * and 1969-12-31 23 is hour -1.
     */
  utcDateToHourInt(date) {
    return Math.floor(date.getTime() / MILLIS_PER_HOUR);
  },

  /**
     * Returns the minute as an integer value,
     * where 1970-01-01 00:00 is minute 0, 1970-01-01 00:01 is minute 1
     * and 1969-12-31 23:59 is minute -1.
     */
  utcDateToMinuteInt(date) {
    return Math.floor(date.getTime() / MILLIS_PER_MINUTE);
  },

  /**
     * Returns the second as an integer value,
     * where 1970-01-01 00:00:00 is second 0, 1970-01-01 00:00:01 is second 1
     * and 1969-12-31 23:59:59 is second -1.
     */
  utcDateToSecondInt(date) {
    return Math.floor(date.getTime() / MILLIS_PER_SECOND);
  },

  /**
     * Converts an ISO date string into a local date, ignoring time-offset information.
     * @param {string} dateString
     * @returns {?Date} May be <em>null</em>
     */
  isoStringToDate(dateString) {
    return _dateInfoToDate(Dates.isoDateInfo(dateString, true));
  },
  /**
     * Converts an ISO date string into a UTC date, ignoring time-offset information.
     * @param {string} dateString
     * @returns {?Date} May be <em>null</em>
     */
  isoStringToUTCDate(dateString) {
    return _dateInfoToUTCDate(Dates.isoDateInfo(dateString, true), false);
  },

  /**
     * Converts an ISO date string into a Date, honoring time-offset information
     * (absolute Date object).  The returned Date can be displayed in local time or UTC time.
     * @param {string} dateString
     * @returns {?Date} May be <em>null</em>
     */
  isoDate(dateString) {
    return _dateInfoToUTCDate(Dates.isoDateInfo(dateString, true), true);
  },

  /**
     * Reads a standard date (yyyy-MM-dd HH:mm:ss.SSS) and returns a Date object, or null.
     */
  stdStringToDate(stdDateString) {
    return _dateInfoToDate(_validDateInfo(stdDateString));
  },


  /**
     * Reads a standard date (yyyy-MM-dd HH:mm:ss.SSS) and returns a UTC Date object, or null.
     */
  stdStringToUTCDate(stdDateString) {
    return _dateInfoToUTCDate(_validDateInfo(stdDateString), false);
  },

  /**
     * Returns a Date in local time, or null.
     * @param {string} strDate
     * @returns {?Date} May be null.
     */
  stringToDate(strDate) {
    return Globalize.parseDate(strDate);
  },


  /**
     * Returns a Date in UTC time, or null.
     * @param {string} strDate
     * @returns {?Date} May be null.
     */
  stringToUTCDate(strDate) {
    let date = Globalize.parseDate(strDate, 'yyyy-MM-dd'); // Returns a Date in local time, or null.
    if (date !== null) date = Dates.inUTC(date); // Convert to UTC

    return date;
  },


  diffDays(lowDate, highDate) {
    return (highDate.getTime() - lowDate.getTime())
            / MILLIS_PER_DAY;
  },

  /**
     * <p>
     *  Returns the number of months between <code>lowDate</code>
     *  and <code>highDate</code>.  Both arguments are expected
     *  to be values in the local time zone.  This method disregards all
     *  values but <em>year</em> and <em>month</em>.
     * </p>
     *
     * <p>
     *  Examples:
     * </p>
     * <ul>
     *  <li> diffMonths(2013-01-01, 2014-01-01) == 12 </li>
     *  <li> diffMonths(2013-01-31, 2014-01-01) == 12 </li>
     *  <li> diffMonths(2013-02-01, 2014-01-01) == 11 </li>
     *  <li> diffMonths(2013-01-31, 2013-12-31) == 11 </li>
     * </ul>
     *
     * <p>
     *  This function does not handle B.C. dates correctly.
     * </p>
     * @param {Date} lowDate
     * @param {Date} highDate
     * @return {int}
     */
  diffMonths(lowDate, highDate) {
    return _diffMos(_HANDLERS.local.getter, lowDate, highDate);
  },


  /**
     * <p>
     *  Returns the number of months between <code>lowDate</code>
     *  and <code>highDate</code>.  Both arguments are expected to
     *  be values in the UTC time zone.  This method disregards all
     *  values but <em>year</em> and <em>month</em>.
     * </p>
     *
     * <p>
     *  Examples:
     * </p>
     * <ul>
     *  <li> diffMonths(2013-01-01, 2014-01-01) == 12 </li>
     *  <li> diffMonths(2013-01-31, 2014-01-01) == 12 </li>
     *  <li> diffMonths(2013-02-01, 2014-01-01) == 11 </li>
     *  <li> diffMonths(2013-01-31, 2013-12-31) == 11 </li>
     * </ul>
     *
     * <p>
     *  This function does not handle B.C. dates correctly.
     * </p>
     * @param {Date} lowDate
     * @param {Date} highDate
     * @return {int}
     */
  diffMonthsUTC(lowDate, highDate) {
    return _diffMos(_HANDLERS.utc.getter, lowDate, highDate);
  },

  /**
     * Takes a time string in the format of HH:mm[:ss[.SSS]]
     * and converts it to value of milliseconds-since-midnight.
     * While seconds and milliseconds are optional, seconds must
     * be specified in order to specify milliseconds.
     *
     * This method does not throw; it returns `defaultVal` if `timeString` is not a string or unrecognized format.
     *
     * @param {string} timeString String representation of time, "H:mm[:ss[.SSS]]"
     * @param {?int} [defaultVal=null] Default value for when `timeString` is not a string or not a recognized
     *        time format.
     * @returns {?int} Millisecond-since-midnight value (0-86,399,999), or null.
     */
  stringToTime(timeString, defaultVal) {
    if (arguments.length < 2) defaultVal = null;

    if (typeof timeString !== 'string') return defaultVal;

    const match = TIME_REGEX.exec(timeString);
    if (match === null) return defaultVal;

    const hr = parseInt(match[1], 10);
    const min = parseInt(match[2], 10);
    const sec = (match[3] ? parseInt(match[3], 10) : 0);
    const ms = (match[4] ? parseInt(match[4], 10) * Math.pow(10, (3 - match[4].length))
      : 0);

    if (hr > 23
            || min > 59
            || sec > 59) return defaultVal;

    return (hr * MILLIS_PER_HOUR)
             + (min * MILLIS_PER_MINUTE)
             + (sec * MILLIS_PER_SECOND)
             + ms;
  },

  /**
     * Converts a millisecond value to represent a fraction of a day
     * (ms * 1/86400000)
     * @param {int} ms
     * @returns {number} Double.
     */
  millisToDays(ms) {
    return (ms / MILLIS_PER_DAY);
  },

  /**
     * Converts a second value to represent a fraction of a day
     * (secs * 1/86400).
     * @param {number} secs
     * @returns {number}
     */
  secsToDays(secs) {
    return (secs / SECONDS_PER_DAY);
  },

  /**
     * Converts a minute value to represent a fraction of a day
     * (mins * 1/1440).
     * @param {number} mins
     * @returns {number} Double
     */
  minsToDays(mins) {
    return (mins / MINUTES_PER_DAY);
  },

  /**
     * Converts an hour value to represent a fraction of a day
     * (hrs * 1/24).
     * @param {number} hrs
     * @returns {number}
     */
  hrsToDays(hrs) {
    return (hrs / HOURS_PER_DAY);
  },

  /**
     * Convert a value that represents a fraction of a day
     * into milliseconds.
     * @param {number} days
     * @returns {int}
     */
  daysToMillis(days) {
    return Math.round(days * MILLIS_PER_DAY);
  },


  /**
     * Returns a function that will truncate a date
     * to the specified <code>factor</code>.
     * @param {int} factor - Positive integer.
     * @returns {Function}
     */
  truncateFunction(factor) {
    Numbers.requirePositiveInteger(factor, 'factor');

    let fn = _TRUNCATES[factor];
    if (typeof fn === 'undefined') {
      fn = (date) => {
        if (date instanceof Date) return new Date(Math.floor(date.getTime() / factor) * factor);
        return date;
      };
      _TRUNCATES[factor] = fn;
    }

    return fn;
  },


  /**
     * Returns a function that will round a date
     * to the specified <code>factor</code>.
     * @param {int} factor - Positive integer.
     * @returns {Function}
     */
  roundFunction(factor) {
    Numbers.requirePositiveInteger(factor, 'factor');

    let fn = _ROUNDS[factor];
    if (typeof fn === 'undefined') {
      fn = (date) => {
        if (date instanceof Date) return new Date(Math.round(date.getTime() / factor) * factor);
        return date;
      };
      _ROUNDS[factor] = fn;
    }

    return fn;
  },


  /**
     * Returns the index of the first unsupported pattern letter,
     * within <code>dateFormat</code>.  This method returns <em>-1</em>
     * if all letters within <code>dateFormat</code> are supported, or
     * if no letters are found.
     * @param {string} dateFormat
     * @returns {int} Index position of the first unsupported letter.
     */
  findUnsupportedLetterPattern(dateFormat) {
    if (typeof dateFormat !== 'string') throw new Error('IllegalArgumentException: dateFormat must be a String.');

    const regexL = ANY_LETTER_CASE_INSENSITIVE;
    const regexP = DATE_FORMATTER_LETTERS;

    regexL.exec(''); // reset "global" RegExp object.

    let m = regexL.exec(dateFormat);
    while (m !== null) {
      /* We need to reset regexP for every character.
             * This is inefficient, but we don't expect this
             * method to be executed very often.
             * If this becomes a bottleneck, it should be
             * easy to improve the logic. */
      regexP.exec(''); // reset "global" RegExp object.

      if (regexP.exec(m[0]) === null) return m.index;

      m = regexL.exec(dateFormat);
    }

    return -1;
  },


  /**
     * Returns a function that takes a Date object and returns a String
     * representation of its value, based on <code>format</code>.
     * @param {string} format - "MM/dd/yy", "MMM d, yyyy", "yyyy-MM-ddTHH:mm:ss.SSS", etc.
     * @param {boolean} [isUTC=false] - Whether to use the UTC values from the
     *                        Date objects passed to the returned function.
     * @param isMpSymbol
     */
  getFormatter(format, isUTC, isMpSymbol = false) {
    requireNonEmptyString(format, 'format');


    let type;
    if (isMpSymbol) {
      type = 'localToCst';
    } else if (isUTC) {
      type = 'utc';
    } else {
      type = 'local';
    }
    const { getter } = _HANDLERS[type];
    const formatters = _FORMATTERS[type];
    let fn = null;

    if (Object.hasOwnProperty.call(formatters, format)) fn = formatters[format];

    else {
      fn = _newDateFormatter(format, getter);
      formatters[format] = fn;
    }

    return fn;
  },
});


export default Dates;
export { _requireDate as requireDate };

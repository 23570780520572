import qaEditActionsConstants from '../constants/qaEditActions';

export default function formulaNodeValidator(formulaNodeType, formulaConfig) {
  const errors = [];
  const formulaValidatorTemplateObject = qaEditActionsConstants
    .formulaNodeTemplateValidationObject[formulaNodeType];

  const requiredFields = Object.keys(formulaConfig).filter(
    ck => formulaValidatorTemplateObject[ck] && formulaValidatorTemplateObject[ck].required === true,
  );

  requiredFields.forEach((field) => {
    if (Array.isArray(formulaConfig[field])) {
      if (formulaValidatorTemplateObject[field].required && formulaConfig[field].length === 0) {
        errors.push(formulaValidatorTemplateObject[field].errorMessage);
      }
    } else if (formulaValidatorTemplateObject[field].required === !formulaConfig[field]) {
      errors.push(formulaValidatorTemplateObject[field].errorMessage);
    }
  });

  return [errors.length > 0, errors];
}

<template>
  <div>
    <div>
      <h2 class="custom-header-ent border-bottom-solid break-word">
        {{ $t('labels.feed.entitlements') }}
      </h2>
    </div>
    <mds-layout-grid class="entitlement-container">
      <mds-row
        v-for="(entitlement, index) in siteEntitlements"
        :key="entitlement.name + '_group' + index"
        class="custom-border"
      >
        <mds-col
          :cols="8"
          :cols-at-m="11"
        >
          <mds-layout-grid>
            <mds-row>
              <mds-col
                :cols="12"
                :cols-at-m="8"
                class="break-word"
              >
                <strong> {{ entitlement }} </strong>
              </mds-col>
              <mds-col
                :cols="6"
                :cols-at-m="2"
              >
                <mds-checkbox
                  name="checkbox-name"
                  class="checkbox-inline"
                  value="accept"
                  checked="true"
                  disabled="true"
                  @change="updateEntitlementsPermission(entitlement.name, 'read', $event)"
                >
                  Read
                </mds-checkbox>
              </mds-col>
              <mds-col
                :cols="6"
                :cols-at-m="2"
              >
                <mds-checkbox
                  name="checkbox-name"
                  class="checkbox-inline"
                  value="accept"
                  disabled="true"
                  @change="updateEntitlementsPermission(entitlement.name, 'write', $event)"
                >
                  Write
                </mds-checkbox>
              </mds-col>
            </mds-row>
          </mds-layout-grid>
        </mds-col>
        <mds-col
          :cols="4"
          :cols-at-m="1"
        >
          <a class="ic-add">
            <mds-icon
              name="remove--s"
              title="Remove"
              class="checkbox-inline"
              @click="removeEntitlements(entitlement, 'site', true, false)"
            />
          </a>
        </mds-col>
      </mds-row>
      <mds-row
        v-for="(entitlement, index) in userEntitlements"
        :key="entitlement.name + '_' + index"
        class="custom-border"
      >
        <mds-col
          :cols="8"
          :cols-at-m="11"
        >
          <mds-layout-grid>
            <mds-row>
              <mds-col
                :cols="12"
                :cols-at-m="8"
                class="break-word"
              >
                {{ entitlement.name }}
              </mds-col>
              <mds-col
                v-if="!isFeedGroup() && !entitlement.isFeedGroupLicense"
                :cols="6"
                :cols-at-m="2"
              >
                <mds-checkbox
                  name="checkbox-name"
                  class="checkbox-inline"
                  value="accept"
                  :disabled="entitlement.check"
                  :checked="entitlement.read"
                  @change="updateEntitlementsPermission(entitlement, 'read', $event)"
                >
                  Read
                </mds-checkbox>
              </mds-col>
              <mds-col
                v-if="!isFeedGroup() && !entitlement.isFeedGroupLicense"
                :cols="6"
                :cols-at-m="2"
              >
                <mds-checkbox
                  name="checkbox-name"
                  class="checkbox-inline"
                  value="accept"
                  :checked="entitlement.write"
                  @change="updateEntitlementsPermission(entitlement, 'write', $event)"
                >
                  Write
                </mds-checkbox>
              </mds-col>
            </mds-row>
          </mds-layout-grid>
        </mds-col>
        <mds-col
          :cols="4"
          :cols-at-m="1"
        >
          <div v-if="isUserForFeedGroup || (!isFeedGroup() && !entitlement.isFeedGroupLicense)">
            <a class="ic-add">
              <mds-icon
                name="remove--s"
                title="Remove"
                class="checkbox-inline"
                @click="
                  removeEntitlements(
                    entitlement,
                    'user',
                    entitlement.read,
                    entitlement.write
                  )"
              />
            </a>
          </div>
          <div v-else>
            <a class="ic-add">
              <mds-icon
                name="info-circle"
                title="Feed Group Entitlement"
                class="checkbox-inline"
              />
            </a>
          </div>
        </mds-col>
      </mds-row>
      <mds-row
        v-for="(entitlement, index) in groupEntitlements"
        :key="entitlement + '_group' + index"
        class="custom-border"
      >
        <mds-col
          :cols="8"
          :cols-at-m="11"
        >
          <mds-layout-grid>
            <mds-row>
              <mds-col
                :cols="12"
                :cols-at-m="8"
                class="break-word"
              >
                <strong> {{ entitlement }} </strong>
              </mds-col>
              <mds-col
                :cols="6"
                :cols-at-m="2"
              >
                <mds-checkbox
                  name="checkbox-name"
                  class="checkbox-inline"
                  value="accept"
                  checked="true"
                  disabled="true"
                  @change="updateEntitlementsPermission(entitlement.name, 'read', $event)"
                >
                  Read
                </mds-checkbox>
              </mds-col>
              <mds-col
                :cols="6"
                :cols-at-m="2"
              >
                <mds-checkbox
                  name="checkbox-name"
                  class="checkbox-inline"
                  value="accept"
                  disabled="true"
                  @change="updateEntitlementsPermission(entitlement.name, 'write', $event)"
                >
                  Write
                </mds-checkbox>
              </mds-col>
            </mds-row>
          </mds-layout-grid>
        </mds-col>
        <mds-col
          :cols="4"
          :cols-at-m="1"
        >
          <a class="ic-add">
            <mds-icon
              name="remove--s"
              title="remove"
              class="checkbox-inline"
              @click="removeEntitlements(entitlement, 'group', true, false)"
            />
          </a>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
    <div>
      <span class="pull-left margin-top-10">
        <button
          id="cancelEntitlementsButton"
          class="mds-button mds-button--primary test font-size-small"
          type="button"
          @click="hideEntitlements"
        >
          {{ $t('labels.group.groupBackButton') }}
        </button>
      </span>
      <span class="pull-right margin-top-10">
        <button
          id="saveEntitlementsButton"
          class="mds-button mds-button--primary test font-size-small"
          type="button"
          @click="showEntitlementsPopUp"
        >
          {{ $t('labels.feed.saveEntitlements') }}
        </button>
      </span>
    </div>
    <entitlements-pop-over
      :feed-entitlements="userEntitlements"
      :initial-feed-entitlements="initialFeedEntitlements"
      :group-entitlements="groupEntitlements"
      :site-entitlements="siteEntitlements"
      :initial-group-entitlements="initialGroupEntitlements"
      :initial-site-entitlements="initialSiteEntitlements"
      :removed-list="removedList"
      :feed-name="feedName"
      :feed-group-name="feedGroupName"
      :group-feeds="groupFeeds"
    />
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import EventBus from '../../main';
import EntitlementsPopOver from './EntitlementsPopOver.vue';

export default {
  name: 'EntitledUser',
  components: {
    EntitlementsPopOver, MdsLayoutGrid, MdsRow, MdsCol,
  },
  props: {
    userEntitlements: {
      type: Array,
      default: () => [],
    },
    groupEntitlements: {
      type: Array,
      default: () => [],
    },
    siteEntitlements: {
      type: Array,
      default: () => [],
    },
    initialFeedEntitlements: {
      type: Array,
      default: () => [],
    },
    initialGroupEntitlements: {
      type: Array,
      default: () => [],
    },
    initialSiteEntitlements: {
      type: Array,
      default: () => [],
    },
    removedList: {
      type: Array,
      default: () => [],
    },
    feedName: {
      type: String,
      default: '',
    },
    feedGroupName: {
      type: String,
      default: '',
    },
    groupFeeds: {
      type: Array,
      default: () => [],
    },
    isUserForFeedGroup: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateEntitlementsPermission(ent, permission, event) {
      EventBus.$emit('updateEntitlementsPermission', ent, permission, event);
    },
    removeEntitlements(ent, type, read, write) {
      EventBus.$emit('removeEntitlements', ent, type, read, write);
    },
    hideEntitlements() {
      EventBus.$emit('hideOverLay', false);
      EventBus.$emit('hideFeedEntitlements', false);
    },
    showEntitlementsPopUp() {
      EventBus.$emit('saveEntitlements', EntitlementsPopOver);
    },
    isFeedGroup() {
      return this.feedGroupName !== '';
    },
  },
};
</script>

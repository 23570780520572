<template>
  <div v-if="loadingStatus==='Loading'">
    <mds-loader
      size="large"
      class="loader"
      :on-dark="darkMode"
    />
  </div>
  <div
    v-else
    class="time-series-line-chart-container"
  >
    <div
      v-if="!chartData"
      class="empty-state-container"
    />
    <div v-if="chartData">
      <power-forcase-chart
        :chart-data="chartData"
        :config="chartOptions"
      />
    </div>
    <b v-if="loadingStatus==='Loading'">Refresh's in progress</b>
  </div>
</template>
<script>
import * as d3 from 'd3';
import MdsLoader from '@mds/loader';
import PowerForcaseChart from '@/components/Dashboard/Charts/PowerForecastChart';

export default {
  name: 'DataAnalyticsChartSection',
  components: {
    PowerForcaseChart,
    MdsLoader,
  },
  props: {
    loadingStatus: {
      type: String,
      default: undefined,
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
    chartData: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    chartOptions() {
      return {
        legendValuesFormat: d3.format('$,.0f'),
        y1TickSymbol: '',
        y1AxisOrient: 'left',
        useDefaultParsers: true,
        showLegendValues: false,
        showToolTip: true,
        firstTickDate: true, // true or false
        timelineLevel: 'day',
        xTickFormat: d3.time.format('%b %d'),
        tooltipTimeFormat: d3.time.format('%m/%d/%y %I:%M%p <span class="tooltip-chart__condensed">CST</span>'),
        y2: false,
        y2AxisLabel: 'y2 label',
        colors: [
          '#ff0000',
          '#1f55a5',
          '#f5c400',
          '#518428',
          '#00af41',
          '#a50032',
        ],
      };
    },
  },
  watch: {
    darkMode: {
      handler(val, oldVal) {
        if (val) {
          this.chartOptions.skin = 'dark';
        } else {
          this.chartOptions.skin = '';
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">

    .empty-state-container{
      margin: calc(22% - 100px);
      display:flex;
      justify-content: center;
      min-width: 300px;
    }

    .intervals-btn-group {
        padding: 15px;
    }
  .mcd__chart {

  }
  .time-series-line-chart-container{

  }

  .loader{
    min-height: 420px;
  }
</style>

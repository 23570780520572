
<template>
  <div class="grid-2of3 grid-1of1--tablet_xl">
    <div class="slide-carousel-controls">
      <button
        aria-label="previous-slide"
        class="slide-carousel-nav"
        :style="'background-image: url(' + getImgUrlNavi('left.svg') + ');'"
        @click="prevSlide"
      />
      <button
        aria-label="play-pause-slide"
        class="slide-carousel-nav"
        :style="'background-image: url(' + getImgUrlNaviPlayPause() + ');'"
        @click="playPauseSlide"
      />
      <button
        aria-label="next-slide"
        class="slide-carousel-nav"
        :style="'background-image: url(' + getImgUrlNavi('right.svg') + ');'"
        @click="nextSlide"
      />
    </div>
    <carousel
      ref="crsl"
      :pagination-enabled="true"
      :center-mode="true"
      :autoplay="true"
      :autoplay-timeout="5000"
      :loop="true"
      pagination-position="bottom-overlay"
      pagination-color="#cccccc"
      pagination-active-color="#696969"
      :autoplay-hover-pause="false"
      :per-page="1"
      @page-change="currentActivePage"
    >
      <slide
        v-for="(slide, index) in slides"
        :key="slide.url"
      >
        <a
          aria-label="slide-hyperlink"
          :href="slide.url"
          :tabindex="index === activeCarouselPage ? '0' : '-1'"
        >
          <div
            class="carousel-slide"
            :style="'background: url(' + getImgUrl(slide.backgroundURL) + ');'"
          >
            <div class="slideshow__overlay">
              <div class="slideshow__content">
                <span class="content-type">{{ slide.type }}</span>
                <div class="slideshow__heading">
                  {{ slide.heading }}
                </div>
                <span class="content-date">{{ slide.date }}</span>
              </div>
            </div>
          </div>
        </a>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'CarouselSlide',
  components: {
    Carousel,
    Slide,
  },
  props: {
    slides: { type: Array, default: () => [] },
  },
  data() {
    return {
      playing: true,
      activeCarouselPage: 0,
    };
  },
  methods: {
    getImgUrl(pic) {
      return `${process.env.VUE_APP_LANDING_PAGE_RESOURCE}/images/carousel/${pic}`;
    },
    getImgUrlNavi(pic) {
      return require(`../../assets/images/common/${pic}`);
    },
    getImgUrlNaviPlayPause() {
      if (this.playing) {
        return this.getImgUrlNavi('pause.svg');
      }
      return this.getImgUrlNavi('play.svg');
    },
    currentActivePage(value) {
      this.activeCarouselPage = value;
    },
    playPauseSlide() {
      const carousel = this.$refs['crsl'];
      if (this.playing) {
        carousel.pauseAutoplay();
      } else {
        carousel.restartAutoplay();
      }
      this.playing = !this.playing;
    },
    prevSlide() {
      const carousel = this.$refs['crsl'];
      carousel.advancePage('backward');
      if (!this.playing) {
        carousel.pauseAutoplay();
      }
    },
    nextSlide() {
      const carousel = this.$refs['crsl'];
      carousel.advancePage();
      if (!this.playing) {
        carousel.pauseAutoplay();
      }
    },
  },
};
</script>

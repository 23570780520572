<template>
  <div>
    <section class="login-wrapper">
      <slot />
    </section>
    <page-footer />
  </div>
</template>

<script>
import PageFooter from '../PageFooter';

export default {
  name: 'LoginLayout',
  components: { PageFooter },
};
</script>

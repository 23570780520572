
import { requireNonEmptyString } from './strings.es6';
import { requireIntegerBetween } from './numbers.es6';
import Dates from './dates/dates.es6';

/**
 * Extracts the time value from a cron expression, as a millisecond-since-midnight value.
 * This method only works on a very limited number of cron expressions, those that only have
 * integer values as the first 3 items.  This method throws if `cronExpr` is not recognized or
 * not supported.
 * @param {string} cronExpr Space-delimited string in which the first three items are integers
 *        that represent seconds, minutes and hours, in that order.
 * @returns {int} Time extracted from `cronExpr`, as a millisecond-since-midnight value.
 * @throws {TypeError} If `cronExpr` is not a string, or if any of the first 3 items is not an integer
 *         or is outside the expected range.
 * @throws {Error} If `cronExpr` contains fewer than 3 space-delimited items.
 */
// eslint-disable-next-line import/prefer-default-export
export function extractTime(cronExpr) {
  requireNonEmptyString(cronExpr, 'cronExpr');
  const items = cronExpr.split(new RegExp(' +', 'g'));
  if (items.length < 3) {
    throw new Error('cronExpr: must contain at least 3 items');
  }
  const ints = items.slice(0, 3).map(parseFloat);
  return (requireIntegerBetween(ints[0], 0, 59, 'cronExpr[0]') * Dates.MILLIS_PER_SECOND)
         + (requireIntegerBetween(ints[1], 0, 59, 'cronExpr[1]') * Dates.MILLIS_PER_MINUTE)
         + (requireIntegerBetween(ints[2], 0, 23, 'cronExpr[2]') * Dates.MILLIS_PER_HOUR);
}

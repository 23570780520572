<template>
  <div>
    <div
      v-if="loader"
      id="mds-overlay"
      class="mds-overlay"
    >
      <mds-loader class="loader-vertically-center" />
    </div>
    <template v-if="pageStatus == 'SUCCESS'">
      <div class="mds-header border-bottom-solid-details">
        <h1 class="mds-header__title component-title">
          <small>{{ account.name }}</small>
        </h1>
        <div class="mds-header__actions">
          <button
            id="deleteIcon"
            class="mds-button mds-button--flat"
            type="button"
            @click="displayDialogBox('deleteBox')"
          >
            <svg
              class="mds-icon mds-button__icon mds-button__icon--left"
              aria-hidden="true"
            >
              <use xlink:href="#trash" />
            </svg>
            <span class="mds-button__text mcd-page__content-hide--600 ">
              {{ $t('labels.common.delete') }}
            </span>
          </button>
          <router-link
            :to="{ path: '/bb/account/list' }"
            class="mds-list-group__link hide-actions"
            replace
          >
            <button
              class="mds-button mds-button--flat"
              type="button"
            >
              <svg
                class="mds-icon mds-button__icon mds-button__icon--left"
                aria-hidden="true"
              >
                <use xlink:href="#list" />
              </svg>
              <span class="mds-button__text mcd-page__content-hide--600">
                {{
                  $t('labels.bbLoader.accountList')
                }}
              </span>
            </button>
          </router-link>
        </div>
      </div>


      <div class="feed-description">
        <h2 class="custom-header alignment font-size-larger">
          {{ $t('labels.common.company') }}
        </h2>
        <p class="para-margin-top">
          {{ account.company_name }}
        </p>
      </div>

      <div class="feed-description">
        <h2 class="custom-header alignment font-size-larger">
          {{ $t('labels.common.enabled') }}
        </h2>
        <p class="para-margin-top">
          {{ (account && account.enabled && account.enabled===1)?true:false }}
        </p>
      </div>

      <div class="feed-description">
        <h2 class="custom-header alignment font-size-larger">
          {{ $t('labels.common.create_time') }}
        </h2>
        <p class="para-margin-top">
          {{ account.create_time }}
        </p>
      </div>

      <div class="feed-description">
        <h2 class="custom-header alignment font-size-larger">
          {{ $t('labels.common.update_time') }}
        </h2>
        <p class="para-margin-top">
          {{ account.update_time?account.update_time:$t('labels.common.na') }}
        </p>
      </div>
    </template>
    <template v-else-if="pageStatus == 'ERROR'">
      <div class="details-error">
        <mds-notification
          visible
          title="ERROR"
          status="error"
          dismissible="false"
        >
          {{ errorMessage }}
        </mds-notification>
      </div>
    </template>

    <mds-alert
      v-if="dialogType == 'deleteBox'"
      title="Delete Account"
      role="alertdialog"
      variation="warning"
      class="div-centered"
      dismissible="false"
    >
      Are you sure you want to delete <strong>{{ account.name }}</strong>?<br><br>
      <button
        id="deleteCancel"
        class="mds-button mds-button--small mds-button--primary"
        type="button"
        @click="displayDialogBox('')"
      >
        Cancel
      </button>&nbsp;
      <button
        id="deleteConfirm"
        class="mds-button mds-button--small mds-button--primary"
        type="button"
        @click="deleteAccountDetails()"
      >
        Yes, Proceed
      </button>
    </mds-alert>

    <mds-alert
      v-show="dialogType == 'successBox'"
      role="alertdialog"
      variation="success"
      class="div-centered"
      dismissible="false"
    >
      <strong>{{ account.name }}</strong> {{ $t('labels.bbLoader.accountDeleted') }}.
      <mds-button
        id="adSuccessButton"
        slot="mds-alert-custom-action"
        size="small"
        variation="primary"
        @click="
          () => {
            $router.push('/bb/account/list');
          }
        "
      >
        {{ $t('labels.common.okay') }}
      </mds-button>
    </mds-alert>
    <mds-alert
      v-show="dialogType == 'errorBox'"
      variation="error"
      dismissible="false"
      class="div-centered"
    >
      <strong>{{ account.name }}</strong>
      {{ $t('labels.bbLoader.accountNotDeleted') + '. ' + errorMessage }}.
      <mds-button
        id="adErrorButton"
        slot="mds-alert-custom-action"
        size="small"
        variation="primary"
        @click="displayDialogBox('')"
      >
        {{ $t('labels.common.okay') }}
      </mds-button>
    </mds-alert>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'BBAccountDetails',
  data() {
    return {
      loader: true,
      errorMessage: '',
      pageStatus: 'SUCCESS',
      account: {},
      dialogType: '',
    };
  },
  created() {
    this.accountName = this.$route.params.accountName;
    this.getAccountDetails(this.accountName).then((response) => {
      if (response && response.data) {
        const resp = response.data;
        if (resp.name) {
          this.account = response.data;
        } else {
          this.pageStatus = 'ERROR';
          this.errorMessage = resp.Message;
        }
      } else {
        this.pageStatus = 'ERROR';
        this.errorMessage = 'Unable to fetch account Details';
      }
      this.loader = false;
    }).catch((error) => {
      this.pageStatus = 'ERROR';
      this.errorMessage = error;
      this.loader = false;
    });
  },
  methods: {
    ...mapActions('bloombergModule', ['getAccountDetails', 'deleteAccount']),

    getKeyLabel(key) {
      return this.$t(`labels.common.${key}`);
    },
    displayDialogBox(dialogValue) {
      this.dialogType = dialogValue;
    },
    deleteAccountDetails() {
      this.loader = true;
      this.deleteAccount(this.accountName).then((response) => {
        if (response && response.status === 200 && !response.data[0]) {
          this.dialogType = 'successBox';
        } else {
          this.errorMessage = response.Message || response.data[0][0].Message;
          this.dialogType = 'errorBox';
        }
        this.loader = false;
      })
        .catch((error) => {
          this.errorMessage = error;
          this.dialogType = 'errorBox';
          this.loader = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/feed_details';
</style>

<template>
  <div class="entitlements-slidein">
    <div>
      <div class="border-bottom-solid">
        <h1 class="mds-header__title component-title">
          <small>{{ groupName }}</small>
        </h1>
      </div>
      <template v-if="loader">
        <overlay />
      </template>
      <div
        v-show="loader == true"
        class="div-centered"
      >
        <mds-loader />
      </div>
      <mds-layout-grid>
        <mds-row>
          <mds-col :cols="4">
            <user-group
              :user-list="userList"
              :is-feed-group="true"
            />
          </mds-col>
          <mds-col :cols="8">
            <entitled-user
              :key="siteLicenseCheck"
              :feed-group-name="groupName"
              :user-entitlements="entitledUsers"
              :initial-feed-entitlements="initialFeedEntitlements"
              :removed-list="removedList"
              :group-feeds="groupFeeds"
              :is-user-for-feed-group="true"
            />
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import EventBus from '../../main';
import Overlay from './Overlay.vue';
import UserGroup from './UserGroup.vue';
import EntitledUser from './EntitledUser.vue';

export default {
  name: 'FeedGroupEntilements',
  components: {
    Overlay, UserGroup, EntitledUser, MdsLayoutGrid, MdsRow, MdsCol,
  },
  props: {
    groupName: { type: String, default: '' },
    groupFeeds: { type: Array, default: () => [] },
  },
  data() {
    return {
      loader: '',
      userList: [],
      removedList: [],
      initialFeedEntitlements: [],
      readUsers: [],
      siteLicenseCheck: false,
      friendlyFeed: false,
    };
  },
  computed: {
    ...mapGetters({
      feedName: 'feedModule/getActiveFeedname',
      feedGroupEntitlements: 'feedModule/getFeedGroupEntitlements',
    }),
    entitledUsers() {
      return this.getEntitledUsers();
    },
  },
  created() {
    EventBus.$on('addEntitlements', (value, type) => {
      this.addEntitlements(value, type);
    });

    EventBus.$on('removeEntitlements', (ent, type, read, write) => {
      this.removeEntitlements(ent, type, read, write);
    });
    this.loader = true;
    EventBus.$emit('hideOverLay', true);
    this.$store.state.feedModule.feedGroupEntitlements = [];
    this.initialFeedEntitlements = [];
    this.getActiveUsers().then((responses) => {
      if (responses.status === 200) {
        this.userList = responses.data.userList;
        this.getGroupEntitlements(this.groupName).then((response) => {
          if (response.status === 200) {
            this.initialFeedEntitlements = Object.assign([], this.getEntitledUsers());
            this.removesEntitledFromUserList();
            this.userList = this.userList.filter(Boolean); // Ignore Blank Entry from the user list
          } else if (response.status === 500) {
            EventBus.$emit('showErrorNotification');
          } else if (response.status >= 400) {
            EventBus.$emit('showWarningNotification');
          }
          this.loader = false;
        });
      }
    });

    EventBus.$on('showLoader', (value) => {
      this.loader = value;
    });
    EventBus.$on('refreshPage', this.loadPage);
  },
  methods: {
    ...mapActions('feedModule', ['getActiveUsers', 'getGroupEntitlements']),
    getEntitledUsers() {
      const users = [];
      this.feedGroupEntitlements.forEach((username) => {
        if (username != null) {
          this.readUsers.push(username);
          users.push({
            name: username,
            license: '',
            read: true,
            write: false,
            check: false,
          });
        }
      });
      return users.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    },
    addEntitlements(name, type) {
      let license = this.initialFeedEntitlements
        .filter(ent => ent.name === name)
        .map(ent => ent.license)[0];
      license = license === undefined || license === '' ? null : license;
      this.entitledUsers.push({
        name,
        license,
        read: true,
        write: false,
        check: false,
      });
      this.userList = this.userList
        .filter(user => user !== name)
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
      this.entitledUsers.sort((a, b) => a.name.localeCompare(b.name), undefined, {
        sensitivity: 'base',
      });
      this.removedList = this.removedList.filter(user => user.name !== name);
    },
    removeEntitlements(ent, type, read, write) {
      this.entitledUsers.splice(
        this.entitledUsers.findIndex(user => user.name === ent.name),
        1,
      );
      if (this.entitledUsers.filter(u => u.name === ent.name).length <= 0) {
        this.userList.push(ent.name);
        this.userList.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
      }

      this.removedList.push({
        name: ent.name,
        license: ent.license,
        type,
        read,
        write,
      });
      this.entitledUsers.sort((a, b) => a.name.localeCompare(b.name), undefined, {
        sensitivity: 'base',
      });
    },
    removesEntitledFromUserList() {
      const userNames = [...this.readUsers];
      this.userList = this.userList
        .filter(user => !userNames.includes(user))
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
    },
    loadPage() {
      this.getEntitlements(this.feedName).then((response) => {
        this.removedList.length = 0;
        this.removesEntitledFromUserList();
        this.initialFeedEntitlements = Object.assign([], this.entitledUsers);
        this.loader = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/feed_entitlements';
</style>

<template>
  <div>
    <div
      v-if="loader"
      id="mds-overlay"
      class="mds-overlay"
    >
      <mds-loader class="loader-vertically-center" />
    </div>
    <template v-if="pageStatus == 'SUCCESS'">
      <div class="mds-header border-bottom-solid-details">
        <h1 class="mds-header__title component-title">
          <small>{{ yellowKey.name }}</small>
        </h1>
        <div class="mds-header__actions">
          <button
            id="deleteIcon"
            class="mds-button mds-button--flat"
            type="button"
            @click="displayDialogBox('deleteBox')"
          >
            <svg
              class="mds-icon mds-button__icon mds-button__icon--left"
              aria-hidden="true"
            >
              <use xlink:href="#trash" />
            </svg>
            <span class="mds-button__text mcd-page__content-hide--600 ">
              {{ $t('labels.common.delete') }}
            </span>
          </button>
          <router-link
            :to="{ path: '/bb/yellowKey/list' }"
            class="mds-list-group__link hide-actions"
            replace
          >
            <button
              class="mds-button mds-button--flat"
              type="button"
            >
              <svg
                class="mds-icon mds-button__icon mds-button__icon--left"
                aria-hidden="true"
              >
                <use xlink:href="#list" />
              </svg>
              <span class="mds-button__text mcd-page__content-hide--600">
                {{
                  $t('labels.bbLoader.yellowKeyList')
                }}
              </span>
            </button>
          </router-link>
        </div>
      </div>

      <div class="feed-description">
        <h2 class="custom-header alignment font-size-larger">
          {{ $t('labels.common.description') }}
        </h2>
        <p class="para-margin-top">
          {{ yellowKey.desc?yellowKey.desc:$t('labels.common.na') }}
        </p>
      </div>

      <div class="feed-description">
        <h2 class="custom-header alignment font-size-larger">
          {{ $t('labels.bbLoader.ykValueFields') }}
        </h2>
        <ul>
          <li
            v-for="(key, index) in yellowKey.valueFields"
            :key="index"
          >
            {{ key }}
          </li>
        </ul>
      </div>

      <div
        v-if="yellowKey.metaFields && yellowKey.metaFields.length > 0"
        class="feed-description"
      >
        <h2 class="custom-header alignment font-size-larger">
          {{ $t('labels.bbLoader.ykMetaFields') }}
        </h2>
        <ul>
          <li
            v-for="(key, index) in yellowKey.metaFields"
            :key="index"
          >
            {{ key }}
          </li>
        </ul>
      </div>


      <div class="mds-accordion">
        <input
          id="ykInfoAccordion"
          type="checkbox"
          role="button"
          aria-controls="ykInfoAccordionData"
          class="mds-accordion__trigger"
        >
        <label
          for="ykInfoAccordion"
          class="mds-accordion__label"
        >
          <div class="mds-header mds-header--secondary mds-header--level-3">
            <h2 class="mds-header__title ent-header">{{ $t('labels.common.moreInformation') }}</h2>
          </div>
        </label>
        <section
          id="ykInfoAccordionData"
          class="mds-accordion__content"
        >
          <table class="mds-data-table">
            <tbody class="mds-data-table__body">
              <template v-for="(value, key, index) in yellowKey">
                <tr
                  v-if="!excludedInfoItems.includes(key)"
                  :key="index"
                  class="mds-data-table__row"
                >
                  <td
                    class="mds-data-table__cell"
                    scope="row"
                  >
                    {{ getKeyLabel(key) }}
                  </td>

                  <td
                    class="mds-data-table__cell custom-text-align"
                  >
                    {{ value == false ? 'No' : value == true ? 'Yes' : value ? value : $t('labels.common.na') }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </section>
      </div>
    </template>
    <template v-else-if="pageStatus == 'ERROR'">
      <div class="details-error">
        <mds-notification
          visible
          title="ERROR"
          status="error"
          dismissible="false"
        >
          {{ errorMessage }}
        </mds-notification>
      </div>
    </template>

    <mds-alert
      v-if="dialogType == 'deleteBox'"
      title="Delete Yellow Key"
      role="alertdialog"
      variation="warning"
      class="div-centered"
      dismissible="false"
    >
      Are you sure you want to delete <strong>{{ yellowKey.name }}</strong>?<br><br>
      <button
        id="deleteCancel"
        class="mds-button mds-button--small mds-button--primary"
        type="button"
        @click="displayDialogBox('')"
      >
        Cancel
      </button>&nbsp;
      <button
        id="deleteConfirm"
        class="mds-button mds-button--small mds-button--primary"
        type="button"
        @click="deleteKey()"
      >
        Yes, Proceed
      </button>
    </mds-alert>

    <mds-alert
      v-show="dialogType == 'successBox'"
      role="alertdialog"
      variation="success"
      class="div-centered"
      dismissible="false"
    >
      <strong>{{ yellowKey.name }}</strong> {{ $t('labels.bbLoader.yellowKeyDeleted') }}.
      <mds-button
        id="ykSuccessButton"
        slot="mds-alert-custom-action"
        size="small"
        variation="primary"
        @click="
          () => {
            $router.push('/bb/yellowKey/list');
          }
        "
      >
        {{ $t('labels.common.okay') }}
      </mds-button>
    </mds-alert>
    <mds-alert
      v-show="dialogType == 'errorBox'"
      variation="error"
      dismissible="false"
      class="div-centered"
    >
      <strong>{{ yellowKey.name }}</strong>
      {{ $t('labels.bbLoader.yellowKeyNotDeleted') + '. ' + errorMessage }}.
      <mds-button
        id="ykErrorButton"
        slot="mds-alert-custom-action"
        size="small"
        variation="primary"
        @click="displayDialogBox('')"
      >
        {{ $t('labels.common.okay') }}
      </mds-button>
    </mds-alert>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'BBYellowKeyDetails',
  data() {
    return {
      loader: false,
      errorMessage: '',
      pageStatus: 'SUCCESS',
      yellowKey: {},
      dialogType: '',
      excludedInfoItems: ['name', 'value', 'desc', 'valueFields', 'metaFields'],
    };
  },

  created() {
    this.yellowKeyName = this.$route.params.yellowKeyName;
    this.getYellowKeyDetails(this.yellowKeyName).then((response) => {
      if (response && response.name) {
        this.yellowKey = response;
      } else if (response[0][0] && response[0][0].StatusCode >= 400) {
        this.pageStatus = 'ERROR';
        this.errorMessage = response[0][0].Message;
      } else {
        this.pageStatus = 'ERROR';
        this.errorMessage = 'Unable to fetch Yellow Key Details';
      }
      this.loader = false;
    }).catch((error) => {
      this.pageStatus = 'ERROR';
      this.errorMessage = error.response.data.Message;
      this.loader = false;
    });
  },
  methods: {
    ...mapActions('bloombergModule', ['getYellowKeyDetails', 'deleteYellowKey']),

    getKeyLabel(key) {
      return this.$t(`labels.common.${key}`);
    },
    displayDialogBox(dialogValue) {
      this.dialogType = dialogValue;
    },
    deleteKey() {
      this.loader = true;
      this.deleteYellowKey(this.yellowKeyName).then((response) => {
        if (response && response.status === 200 && !response.data[0]) {
          this.dialogType = 'successBox';
        } else {
          this.errorMessage = response.Message || response.data[0][0].Message;
          this.dialogType = 'errorBox';
        }
        this.loader = false;
      }).catch((error) => {
        this.errorMessage = error;
        this.dialogType = 'errorBox';
        this.loader = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/feed_details';
</style>

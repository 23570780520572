import {
  required, email, minLength, maxLength,
} from 'vuelidate/lib/validators';

const fields = labeler => [
  {
    title: labeler.getLabel('emailText'),
    id: 'emailInput',
    described: 'email-error',
    error: {
      email: labeler.getLabel('emailErrorText'),
    },
    type: 'email',
  },
  {
    title: labeler.getLabel('userNameText'),
    id: 'userNameInput',
    described: 'username-error',
    error: {
      default: labeler.getLabel('usernameErrorText'),
    },
    type: 'text',
  },
  {
    title: labeler.getLabel('passwordText'),
    id: 'passwordInput',
    described: 'pw-error',
    error: {
      minLength: labeler.getLabel('minLengthError'),
      maxLength: labeler.getLabel('maxLengthError'),
      oneDigit: labeler.getLabel('oneDigit'),
      upCaseLetter: labeler.getLabel('upCaseLetter'),
      repeatCharacter: labeler.getLabel('repeatCharacter'),
      specialCharacter: labeler.getLabel('specialCharacter'),
      consecutiveCharacter: labeler.getLabel('consecutiveCharacter'),
      pwdStrength: labeler.getLabel('pwdStrength'),
    },
    type: 'password',
  },
];

export function pwdStrength(value) {
  if (value !== null) {
    let s = 0;
    s += value.match(/[0-9]/g) ? 25 : 0;
    s += value.match(/\W/g) ? 25 : 0;
    s += value.match(/[A-Z]/g) ? 25 : 0;
    s += value.match(/[a-z]/g) ? 25 : 0;
    s += value.length > 7 ? 25 : -10;

    return s >= 100;
  }
  return true;
}

const validations = {
  emailInput: {
    required,
    email,
  },

  passwordInput: {
    required,
    minLength: minLength(8),
    maxLength: maxLength(160),
    // oneDigit,
    // upCaseLetter,
    // repeatCharacter,
    // specialCharacter,
    // // consecutiveCharacter,
    // pwdStrength,
  },
  userNameInput: {
    required,
  },
};


const emailCompare = v => (
  !v.emailInput.$error
    && !v.passwordInput.$error
    && !v.emailInput.$invalid
    && !v.passwordInput.$invalid
    && v.emailInput.$dirty
    && v.passwordInput.$dirty
);
const userNameCompare = v => (
  !v.passwordInput.$error
    && !v.userNameInput.$error
    && !v.userNameInput.$invalid
    && !v.passwordInput.$invalid
    && v.passwordInput.$dirty
    && v.userNameInput.$dirty
);

const comparingMethod = {
  email: emailCompare,
  userName: userNameCompare,
};

export default {
  fields,
  validations,
  comparingMethod,
};

<template>
  <div>
    <div
      v-if="loader"
      id="mds-overlay"
      class="mds-overlay"
    >
      <mds-loader class="loader-vertically-center" />
    </div>
    <div class="mds-header border-bottom-solid">
      <h1 class="mds-header__title component-title">
        {{ $t('labels.bbLoader.newAccount') }}
      </h1>
      <div class="mds-header__actions">
        <router-link
          :to="{ path: '/bb/account/list' }"
          class="mds-list-group__link hide-actions"
          replace
        >
          <button
            class="mds-button mds-button--flat"
            type="button"
          >
            <svg
              class="mds-icon mds-button__icon mds-button__icon--left"
              aria-hidden="true"
            >
              <use xlink:href="#list" />
            </svg>
            <span class="mds-button__text mcd-page__content-hide--600">
              {{
                $t('labels.bbLoader.accountList')
              }}
            </span>
          </button>
        </router-link>
      </div>
    </div>
    <form
      class="mds-form mds-form--medium-layout custom_form"
      method
      action
    >
      <fieldset class="mds-form__fieldset">
        <legend class="mds-form__legend" />
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="accountName"
          >
            {{ $t('labels.bbLoader.accountName') }}
            <abbr
              class="mds-form__label-required-indicator"
            >*</abbr>
          </label>
          <input
            id="accountName"
            v-model="accountName"
            class="mds-form__input custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
            type="text"
            maxlength="45"
          >
          <span
            v-if="!isValidAccountName"
            id="accountNameError"
            class="mds-form__field-error"
            role="alert"
          >
            <span class="mds-form__field-error-text">{{ $t('labels.common.propNameCheck') }}</span>
          </span>
        </div>
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="userNumber"
          >
            {{ $t('labels.bbLoader.userNumber') }}
            <abbr class="mds-form__label-required-indicator">*</abbr>
          </label>
          <input
            id="userNumber"
            v-model="userNumber"
            class="mds-form__input custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
            type="text"
            maxlength="20"
          >
          <span
            v-if="!isValidUserNumber"
            id="userNumberError"
            class="mds-form__field-error"
            role="alert"
          >
            <span class="mds-form__field-error-text">{{ $t('labels.common.onlyNumbers') }}</span>
          </span>
        </div>
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="userSN"
          >
            {{ $t('labels.bbLoader.userSN') }}
            <abbr class="mds-form__label-required-indicator">*</abbr>
          </label>
          <input
            id="userSN"
            v-model="userSN"
            class="mds-form__input custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
            type="text"
            maxlength="20"
          >
          <span
            v-if="!isValidUserSN"
            id="userSNError"
            class="mds-form__field-error"
            role="alert"
          >
            <span class="mds-form__field-error-text">{{ $t('labels.common.onlyNumbers') }}</span>
          </span>
        </div>
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="userWS"
          >
            {{ $t('labels.bbLoader.userWS') }}
            <abbr class="mds-form__label-required-indicator">*</abbr>
          </label>
          <input
            id="userWS"
            v-model="userWS"
            class="mds-form__input custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
            type="text"
            maxlength="20"
          >
          <span
            v-if="!isValidUserWS"
            id="userWSError"
            class="mds-form__field-error"
            role="alert"
          >
            <span class="mds-form__field-error-text">{{ $t('labels.common.onlyNumbers') }}</span>
          </span>
        </div>
        <div class="mds-form__field-group">
          <label
            class="mds-form__label"
            for="pricingSource"
          >{{ $t('labels.bbLoader.pricingSource') }}</label>
          <input
            id="pricingSource"
            v-model="pricingSource"
            class="mds-form__input custom_width custom_width-at-600 custom_width-at-490 custom_width-at-390"
            type="text"
            maxlength="20"
          >
        </div>
      </fieldset>
      <div class="demo-button-container">
        <button
          id="btnBack"
          class="mds-button val_pad mds-button--primary font-size-small new-feed-save-button"
          type="button"
          @click="goBack"
        >
          {{ $t('labels.group.groupBackButton') }}
        </button>
        <button
          id="btnSave"
          class="mds-button mds-button--primary font-size-small new-feed-save-button"
          type="button"
          @click="formSubmit"
        >
          {{ $t('labels.bbLoader.saveAccount') }}
        </button>
        <span
          v-if="formError"
          id="formError"
          class="mds-form__field-error"
          role="alert"
        >
          <span class="mds-form__field-error-text">{{ $t('labels.common.invalidForm') }}</span>
        </span>
        <span
          v-if="errorText != ''"
          id="formError"
          class="mds-form__field-error"
          role="alert"
        >
          <span class="mds-form__field-error-text">{{ errorText }}</span>
        </span>
      </div>
    </form>
    <div
      id="mds-notification"
      class="mds-notification mds-notification--top-center mds-notification--below-masthead mds-notification--active custom-top"
    >
      <mds-notification-group>
        <mds-notification
          ref="successDialog"
          role="alertdialog"
          status="success"
          size="small"
          dismiss-delay="45000"
          dismissible="true"
          removable="false"
          class="z-index-9999"
        >
          {{ $t('labels.feed.successDialogMsg') }}
        </mds-notification>
        <mds-notification
          ref="errorDialog"
          :list="JSON.stringify(errorText)"
          role="alertdialog"
          status="error"
          size="small"
          dismiss-delay="35000"
          dismissible="true"
          removable="false"
          class="z-index-9999"
        >
          {{ $t('labels.feed.errorDialogMsg') }}
        </mds-notification>
      </mds-notification-group>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { checkSpecialChars, checkNumberOnly } from '../../utils/stringUtils';

export default {
  name: 'BBNewAccount',
  data() {
    return {
      loader: false,
      accountName: '',
      userNumber: '',
      userSN: '',
      userWS: '',
      pricingSource: '',
      errorText: '',
      formError: false,
    };
  },
  computed: {
    isValidAccountName() {
      return !this.accountName || this.accountName === '' || checkSpecialChars(this.accountName);
    },
    isValidUserNumber() {
      return !this.userNumber || checkNumberOnly(this.userNumber);
    },
    isValidUserSN() {
      return !this.userSN || checkNumberOnly(this.userSN);
    },
    isValidUserWS() {
      return !this.userWS || checkNumberOnly(this.userWS);
    },
  },
  created() {},
  methods: {
    ...mapActions('bloombergModule', ['addUserAccount', 'pageStatus']),
    validateForm() {
      if (
        !(
          this.isValidAccountName
          && this.isValidUserNumber
          && this.isValidUserSN
          && this.isValidUserWS
        )
        || this.accountName.length <= 0
        || this.userNumber.length <= 0
        || this.userSN.length <= 0
        || this.userWS.length <= 0
      ) {
        this.formError = true;
        return false;
      }

      this.formError = false;
      return true;
    },
    formSubmit() {
      this.errorText = '';
      if (!this.validateForm()) {
        return;
      }

      this.loader = true;

      const payload = {
        account_name: this.accountName,
        user_number: this.userNumber,
        user_sn: this.userSN,
        user_ws: this.userWS,
        pricing_source: this.pricingSource,
      };
      this.addUserAccount(payload)
        .then((response) => {
          if (response.StatusCode === 200) {
            this.goToAccountDetails(this.accountName);
          } else {
            this.errorText = response.Message;
            this.loader = false;
          }
        })
        .catch((error) => {
          this.loader = false;
          this.errorText = error.response.data.Message || '';
        });
    },
    goToAccountDetails(accountName) {
      this.$router.push({
        path: `/bb/account/${accountName}`,
        params: {
          accountName,
        },
      });
      this.loader = false;
    },
    goBack() {
      this.$router.push({
        path: '/bb/account/list',
      });
      this.loader = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/new_feed';
</style>

const mutations = {
  SYNC_WORKSPACE: (state, workspace) => {
    state.workspaces.push(workspace);
  },
  ADD_WORKSPACE: (state, workspace) => {
    state.workspaces.push(workspace);
  },
  ADD_WIDGET: (state, payload) => {
    state.workspaces.forEach((item) => {
      if (item.id === payload.parentId) {
        item.widgets[payload.item.slotName] = payload.item;
        item.filledWidgetCount += 1;
      }
    });
    // state.workspaces.push(workspace);
  },
  DELETE_WORKSPACE: (state, workspaceId) => {
    const workspace = state.workspaces;
    workspace.forEach((item, index) => {
      if (item.id === workspaceId) {
        workspace.splice(index, 1);
      }
    });
    state.workspaces = workspace;
  },
  RENAME_WORKSPACE: (state, obj) => {
    const workspace = state.workspaces;
    workspace.forEach((item) => {
      if (item.id === obj.id) {
        item.title = obj.name;
      }
    });
    state.workspaces = workspace;
  },
};

export default mutations;

/* eslint-disable no-use-before-define */
import Text from './lang/bubbletype_en-us.es6';
import Arrays from './arrays.es6';
import { requireInteger } from './numbers.es6';
import { requireString } from './strings.es6';
import LinkType, { requireLinkTypes } from './linktype.es6';

let _canConstructBubbleType = true;

/**
 * @param {Object} targetIndex
 * @param {Array.<{valueOf: function}>} enumList
 * @returns {Object} Always returns `targetIndex` after calling `Object.freeze` on it.
 * @private
 */
function _frozenEnumIndex(targetIndex, enumList) {
  enumList.forEach((item) => {
    targetIndex[item.valueOf()] = item;
  });
  return Object.freeze(targetIndex);
}

/**
 * @class
 */
class BubbleType {
  /**
     * @param value {string}
     * @param maxIn {int} Maximum number of bubbles that can flow into this bubble type.
     * @param accepts {lim.MpDataWorkflowGui.LinkType[]}
     * @private
     */
  constructor(value, maxIn, accepts) {
    if (!_canConstructBubbleType) {
      throw new Error('IllegalAccessException: private constructor, access denied');
    }
    this._val = requireString(value, 'value');
    this._maxIn = requireInteger(maxIn, 'maxIn');
    this._inAs = _frozenEnumIndex({}, requireLinkTypes(accepts, 'accepts'));
    this._outAs = {};
    this._outTo = {};
    Object.freeze(this);
  }

  /** @returns {string} Value of this enum item. */
  valueOf() { return this._val; }

  /** @returns {string} Name of this enum item. */
  toString() { return this._val.toUpperCase(); }

  /** @returns {string} Language-specific name of this bubble type. */
  text() { return Text[this.toString()]; }

  /**
     * @returns {int} Maximum number of links allowed to go into this type of bubbles;
     *           negative number indicates unlimited inputs.
     */
  maxInputs() {
    return this._maxIn;
  }

  /**
     * @param {LinkType} linkType
     * @returns {boolean} Whether this type of bubble can be linked into another bubble using the given link type.
     */
  canLinkAs(linkType) {
    LinkType.requireEnumOf(linkType, 'linkType');
    return (this._outAs[linkType.valueOf()] === linkType);
  }

  /**
     * @param {LinkType} linkType
     * @returns {boolean} Whether this type of bubble can be linked into using the given link type.
     */
  accepts(linkType) {
    LinkType.requireEnumOf(linkType, 'linkType');
    return (this._inAs[linkType.valueOf()] === linkType);
  }

  /**
     * @param {(string|BubbleType)} type A string that represents one of the named BubbleType.
     * @param {?BubbleType} [defaultValue] Default value to return if `type` is invalid.
     * @return {?BubbleType} BubbleType object associated with `type`, may be `type` itself.
     * @throws {TypeError} If `type` is neither a string nor an instance of BubbleType.
     * @throws {Error} If `type` does not represent a named BubbleType.
     */
  static valueOf(type, defaultValue) {
    if (type instanceof BubbleType) {
      return type;
    }
    requireString(type, 'type');
    const item = BubbleType[type.toUpperCase()];
    if (item instanceof BubbleType) {
      return item;
    } if (arguments.length > 1) {
      return defaultValue;
    }
    throw new Error(`strType: not a valid lim.MpDataWorkflowGui.BubbleType (${type})`);
  }
}

(function () {
  // This is where we define the rules for how bubbles can be linked:
  // 1) The type of output (success or failure)
  // 2) The other bubble types that can consume the output.

  const successOnly = [LinkType.SUCCESS];
  const allTypes = [LinkType.SUCCESS,
    LinkType.QA_FAILURE,
    LinkType.FAILURE];
  const empty = [];

  const data = new BubbleType('data', 0, empty);
  const qa = new BubbleType('qa', -1, allTypes);
  const formula = new BubbleType('formula', -1, allTypes);
  const save = new BubbleType('save', 1, successOnly);
  const notif = new BubbleType('notification', -1, allTypes);
  const single = new BubbleType('single_var', 0, empty);
  const amqpTask = new BubbleType('amqp_task', -1, allTypes);

  _finalizeBubbleType(data, successOnly, [qa, formula, notif, amqpTask]);
  _finalizeBubbleType(qa, allTypes, [qa, formula, notif, amqpTask]);
  _finalizeBubbleType(formula, allTypes, [save, notif, amqpTask]);
  _finalizeBubbleType(save, successOnly, [qa, formula, notif, amqpTask]);
  _finalizeBubbleType(notif, empty, empty);
  _finalizeBubbleType(single, empty, empty);
  _finalizeBubbleType(amqpTask, empty, empty);

  Object.assign(BubbleType, /** @lends BubbleType */ {
    /** @type BubbleType */ DATA: data,
    /** @type BubbleType */ QA: qa,
    /** @type BubbleType */ FORMULA: formula,
    /** @type BubbleType */ SAVE: save,
    /** @type BubbleType */ NOTIFICATION: notif,
    /** @type BubbleType */ SINGLE_VAR: single,
    /** @type BubbleType */ AMQP_TASK: amqpTask,
  });
}());

_canConstructBubbleType = false; // lock down the constructor.

/**
 * @param {(BubbleType|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is a BubbleType object.
 */
export function isBubbleType(arg) {
  return (arg instanceof BubbleType);
}

/**
 * Validates `arg` to be a BubbleType instance.
 * @param {BubbleType} arg Argument to validate.
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {BubbleType} Always returns `arg`.
 * @throws {TypeError} If `arg` is not a BubbleType object.
 */
export function requireBubbleType(arg, argName) {
  if (!isBubbleType(arg)) {
    throw new TypeError(`${argName }: BubbleType`);
  }
  return arg;
}

/**
 * Validates an argument to be an array (or array-like object) of BubbleType.
 * If valid, this method returns that argument. Otherwise it throws.
 * @param {BubbleType[]} arg Argument to be validated.
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {BubbleType[]} Always returns `arg`.
 * @throws {TypeError} If `arg` is not an array-like object or contains anything other than
 *         BubbleType objects.
 */
export function requireBubbleTypes(arg, argName) {
  const bad = Arrays.indexOfInvalid(arg, isBubbleType);
  if (bad >= 0) {
    throw new TypeError(`${argName}[${bad}]: BubbleType`);
  }
  return arg;
}

/**
 * Sets BubbleType lists and freezes them.
 * @param {BubbleType} bubbleType
 * @param {LinkType[]} outAs
 * @param {BubbleType[]} outTo
 * @returns {BubbleType} `bubbleType`
 * @private
 */
function _finalizeBubbleType(bubbleType, outAs, outTo) {
  requireBubbleType(bubbleType, 'bubbleType');
  requireLinkTypes(outAs, 'outAs');
  requireBubbleTypes(outTo, 'outTo');
  _frozenEnumIndex(bubbleType._outAs, outAs);
  _frozenEnumIndex(bubbleType._outTo, outTo);
  return bubbleType;
}

/**
   * @param {BubbleType} thatBubbleType
   * @returns {boolean} Whether this type of bubble can be linked into `thatBubbleType`.
   */
function canLinkInto(thatBubbleType) {
  requireBubbleType(thatBubbleType, 'thatBubbleType');
  return (this._outTo[thatBubbleType.valueOf()] === thatBubbleType);
}

export default BubbleType;

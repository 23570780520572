<template>
  <div class="mcd-page-shell__content mds-page-shell__content">
    <template v-if="errorMessage !== ''">
      <mds-notification
        visible
        title="ERROR"
        status="error"
        dismissible="false"
      >
        {{ errorMessage }}
      </mds-notification>
    </template>
    <template v-else>
      <div
        v-if="overlay"
        id="mds-overlay"
        class="mds-overlay"
      />
      <mds-loader
        v-show="showloader"
        class="div-centered"
        size="large"
      />
      <mds-layout-grid>
        <div class="border-bottom-solid">
          <mds-row id="deleteIcon">
            <mds-col :cols="8">
              <h1 class="mds-header__title component-title">
                <small>{{ $t('labels.dataAlert.name') }}</small>
              </h1>
            </mds-col>
            <mds-col :cols="4">
              <mds-search-field
                v-model="searchFilter"
                placeholder="Search"
                label="Search"
                @keyup.prevent="filterKeywords($event)"
                @mds-search-field-input-cleared="clearSearchBar"
              />
            </mds-col>
          </mds-row>
        </div>
        <div class="custom-padding">
          <mds-row>
            <mds-col :cols="4">
              <mds-row>
                <mds-col :cols="5">
                  <label class="mds-form__label custom-margin">
                    {{ $t('labels.auditLog.timeFrame') }}
                  </label>
                </mds-col>
                <mds-col :cols="7">
                  <mds-combo-box
                    id="deleteIcon"
                    ref="timeFrameComboRef"
                    label=""
                    placeholder="Select Time Frame"
                    :data-set="timeFrames"
                    @input="filterTime"
                  />
                </mds-col>
              </mds-row>
            </mds-col>
            <mds-col :cols="4" />
            <mds-col :cols="4">
              <mds-button
                :id="'config-btn'"
                icon="person-plus"
                variation="secondary"
                type="button"
                @click="showFeedEnt"
              >
                {{ $t('labels.dataAlert.configurations') }}
              </mds-button>
            </mds-col>
          </mds-row>
          <div class="padding-top">
            <mds-row>
              <mds-col>
                <mds-loader v-show="dataLoader" />
                <mds-data-table
                  v-if="rowLogs.length > 0"
                  ref="logTable"
                  name="test"
                  size="medium"
                  :fixed-first-col="respond"
                  row-hover
                  :header-configs="dataEnt ? adminHeaders : headers"
                  :row-data="rowLogs"
                  class="break-word"
                  :pagination-config="paginationOpts"
                  @mds-data-table-page-change="paginateTable"
                  @mds-data-table-sort-change="onSort"
                >
                  <template
                    v-if="dataEnt"
                    #body-cell="cellData"
                  >
                    <template v-if="cellData.colIndex === 6">
                      <mds-link
                        id="data_alert_link"
                        :href="mpLogUrl + `${cellData.rowData.log_id}`"
                        target="_blank"
                      >
                        {{ cellData.rowData.log_id }}
                      </mds-link>
                    </template>
                  </template>
                </mds-data-table>
                <p v-if="noResult && rowLogs.length === 0">
                  No records found.
                </p>
                <template v-if="pagination">
                  <mds-pagination
                    show-items-info
                    show-items-select
                    :total-items="paginationOpts.totalItems"
                    :page-sizes="paginationOpts.pageSizes"
                    :page="paginationOpts.page"
                    :page-size="paginationOpts.pageSize"
                    @mds-pagination-page-changed="paginateTable"
                  />
                </template>
              </mds-col>
            </mds-row>
          </div>
        </div>
      </mds-layout-grid>
    </template>
    <div>
      <transition name="slide">
        <template v-if="showEnt">
          <DataAlertEntilements />
        </template>
      </transition>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsLoader from '@mds/loader';
import MdsComboBox from '@mds/combo-box';
import { MdsDataTable } from '@mds/data-table-v3';
import { mapGetters, mapActions } from 'vuex';
import MdsSearchField from '@mds/search-field';
import MdsPagination from '@mds/pagination';
import EventBus from '../../main';
import { getUserRoles } from '../../utils/authService';
import DataAlertEntilements from './DataAlertEntitlements.vue';

export default {
  name: 'DataAlert',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsLoader,
    MdsComboBox,
    MdsDataTable,
    MdsSearchField,
    MdsPagination,
    DataAlertEntilements,
  },
  data() {
    return {
      mpLogUrl: String,
      showEnt: false,
      activeDatasource: [],
      feedOptions: [],
      selectedDataSource: '',
      selectedFeedName: '',
      overlay: false,
      showloader: false,
      loader: false,
      selectedTimeFrame: '',
      noResult: false,
      dataLoader: false,
      respond: false,
      paginationOpts: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        page: 1,
        totalItems: this.totalRecords,
        showItemsInfo: true,
        showItemsSelect: true,
      },
      searchFilter: '',
      keyCodes: [9, 13, 16, 17, 18, 27, 45, 122, 144, 20, 27],
      rowLogs: [],
      pagination: false,
      authenticated: false,
      dataEnt: false,
      errorMessage: '',
      headers: [
        {
          text: 'Issue Date',
          fieldName: 'issue_date',
          sortable: true,
        },
        {
          text: 'Data Source',
          fieldName: 'data_source',
          sortable: true,
        },
        {
          text: 'Feed Name',
          fieldName: 'feed_name',
          sortable: true,
        },
        {
          text: 'Resolution Date',
          fieldName: 'resolution_date',
          sortable: true,
        },
        {
          text: 'Status',
          fieldName: 'status',
          sortable: true,
        },
        {
          text: 'Impacted Publisher List',
          fieldName: 'pub_list',
          sortable: true,
        },
        {
          text: 'Comment',
          fieldName: 'comment',
          sortable: true,
        },
      ],
      adminHeaders: [
        {
          text: 'Issue Date',
          fieldName: 'issue_date',
          sortable: true,
        },
        {
          text: 'Data Source',
          fieldName: 'data_source',
          sortable: true,
        },
        {
          text: 'Feed Name',
          fieldName: 'feed_name',
          sortable: true,
        },
        {
          text: 'Resolution Date',
          fieldName: 'resolution_date',
          sortable: true,
        },
        {
          text: 'Status',
          fieldName: 'status',
          sortable: true,
          style: {
            width: '8%',
          },
        },
        {
          text: 'Impacted Publisher List',
          fieldName: 'pub_list',
          sortable: true,
          style: {
            width: '12%',
          },
        },
        {
          text: 'Log Id',
          fieldName: 'log_id',
          sortable: true,
        },
        {
          text: 'Comment',
          fieldName: 'comment',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      dataAlertLogs: 'auditLogModule/getDataAlertLogs',
      totalRecords: 'auditLogModule/getDaTotalRecords',
      timeFrames: 'auditLogModule/getTimeFrames',
      dataAlertFeeds: 'auditLogModule/getDataAlertFeeds',
    }),
    dataAlerts: {
      get() {
        return this.dataAlertLogs;
      },
      set() {
        return this.dataAlertLogs;
      },
    },
  },
  created() {
    this.overlay = true;
    this.getDataAlertFeeds().catch((error) => {
      this.errorMessage = typeof error.response.data === 'object' ? error.response.data.Message : error.response.data;
      this.$refs.reportNotification.open();
    }).finally(() => {
      this.overlay = false;
    });
    if (
      getUserRoles().includes('ROLE_LDS_ADMIN')
      || getUserRoles().includes('ROLE_LDS_SUPPORT_ADMIN')
    ) {
      this.dataEnt = true;
      this.mpLogUrl = `${process.env.VUE_APP_LDS_HOST}/marketplace/admin/log_results?jobId=`;
    }

    EventBus.$on('hideOverLay', (value) => {
      this.overlay = value;
    });
    EventBus.$on('hideFeedEntitlements', (value) => {
      this.showEnt = value;
    });
    EventBus.$on('refreshPage', this.loadPage);
  },

  methods: {
    ...mapActions('auditLogModule', ['getDataAlertLogs', 'getDataAlertFeeds']),
    filterTime(event) {
      this.$store.dispatch('filterTime');
      this.rowLogs = [];
      this.noResult = false;
      [this.selectedTimeFrame] = event;
      if (!this.loader) {
        this.dataLoader = true;
        this.pagination = false;
        this.getDataAlertLogs({
          timeFrame: event[0],
          pageNo: 1,
          noOfRecords: 10,
          feed: this.dataAlertFeeds,
        }).then((response) => {
          if (response.data) {
            if (response.status === 204) {
              this.noResult = true;
            }
            this.rowLogs = this.processDataAlertsData(this.dataAlerts);
            if (
              this.dataAlerts !== undefined
              && this.dataAlerts.length > 0
              && this.selectedTimeFrame !== 'last_edit'
            ) {
              this.pagination = true;
            }
            this.onSort({ sortField: 'issue_date', sortOrder: -1 });
            this.modifyPagination({
              page: 1,
              pageSize: 10,
              totalItems: this.totalRecords,
            });
          }
          this.loader = false;
          this.dataLoader = false;
        });
      }
      return true;
    },
    onSort(arg) {
      const order = arg.sortOrder;
      if (arg.sortField === 'issue_date') {
        this.dataAlerts = this.dataAlerts.sort((a, b) => (order === -1
          ? new Date(b[`${arg.sortField}`]).getTime() - new Date(a[`${arg.sortField}`]).getTime()
          : new Date(a[`${arg.sortField}`]).getTime() - new Date(b[`${arg.sortField}`]).getTime()));
      } else {
        this.showRows = this.dataAlerts.sort(
          (a, b) => (a[`${arg.sortField}`] >= b[`${arg.sortField}`] ? order : -order),
          undefined,
          {
            sensitivity: 'base',
          },
        );
      }
      return true;
    },
    paginateTable(arg) {
      this.loader = true;
      this.pagination = false;
      this.getDataAlertLogs({
        timeFrame: this.selectedTimeFrame,
        pageNo: arg.page,
        noOfRecords: arg.pageSize,
        feed: this.dataAlertFeeds,
      }).then((response) => {
        if (response.data) {
          this.rowLogs = this.processDataAlertsData(this.dataAlerts);
          if (
            this.dataAlerts !== undefined
            && this.dataAlerts.length > 0
            && this.selectedTimeFrame !== 'last_edit'
          ) {
            this.pagination = true;
          }
          this.onSort({ sortField: 'issue_date', sortOrder: -1 });
          this.modifyPagination({
            page: arg.page,
            pageSize: arg.pageSize,
            totalItems: this.totalRecords,
          });
        }
        this.loader = false;
      });
      return true;
    },
    modifyPagination(arg) {
      this.paginationOpts['page'] = arg.page;
      this.paginationOpts['pageSize'] = arg.pageSize;
      this.paginationOpts['totalItems'] = arg.totalItems;
      return true;
    },
    filterKeywords(event) {
      if (this.searchFilter === '') {
        this.pagination = true;
      } else {
        this.pagination = false;
      }
      const keyCode = event.keyCode || event.which;
      // Don't validate the input if below arrow,tab capslock,shift and ctrl keys were pressed
      if (this.keyCodes.includes(keyCode) || (keyCode >= 35 && keyCode <= 40)) {
        return false;
      }
      this.searchKeyword();
      return true;
    },
    clearSearchBar() {
      this.searchFilter = '';
      this.pagination = true;
      this.searchKeyword();
      return true;
    },
    searchKeyword() {
      this.rowLogs = this.dataAlerts.filter(obj => Object.keys(obj).some(
        key => key !== 'id'
            && key !== 'sudo_user'
            && obj[key] !== null
            && obj[key].toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1,
      ));
    },
    showFeedEnt() {
      this.showEnt = true;
    },
    loadPage() {
      this.getDataAlertFeeds();
    },
    processDataAlertsData(data) {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].pub_list.split(',').length > 4) {
            data[i].pub_list = data[i].pub_list
              .split(',')
              .slice(0, 4)
              .toString()
              .replace(/,/gi, ',</br>')
              .concat(this.gereratePopoverData(data[i]));
          } else {
            data[i].pub_list = data[i].pub_list.toString().replace(/,/gi, ',</br>');
          }
        }
      }
      return data;
    },
    gereratePopoverData(data) {
      return (
        `</br><div><span id="popover-group${data.log_id}" icon="plus" variation="secondary"><b>more..</b>`
        + `</span><mds-popover id="pub_list_pop" ref="add-group" triggered-by="popover-group${data.log_id}"`
        + ' position="top-center" title="Impacted Publisher List" width="200px">'
        + `<div class="pub_list_pop_lst">${data.pub_list
          .toString()
          .replace(/,/gi, ',</br>')}</div></mds-popover></div>`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/audit_log';
@import 'src/assets/styles/components/custom/transition';
</style>

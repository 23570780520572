const getters = {
  getCompany: state => state.company,
  getUserName: state => state.userName,
  getTheme: state => state.darkMode,
  getUserNotifs: state => state.userNotifs,
  getNewNotifsCount: state => state.newNotifsCount,
  getNotifSettings: state => state.notifSettings,
  getGlobalSettings: state => state.globalSettings,
  getDataAlertsCron: state => state.dataAlertsCron,
};

export default getters;

const config = {
  API_USER_NOTIFICATIONS: 'api/users/notifications',
  API_NOTIFICATIONS_READ: 'api/users/notifications/read/$[USER_NAME]',
  API_SET_GLOBAL_SETTINGS: 'api/users/globalSettings',
  API_GET_GLOBAL_SETTINGS: 'api/users/globalSettings/$[USER_NAME]',
};


export default function getAPIConfigs() {
  return config;
}

/**
 * retrieve an mwc project namespace
 * @param {string} ns namespace for mwc project
 * @example getNamespace('abc')
 * @returns {object|map} namespace
 */
export function getNamespace(ns = 'UIA') {
  if (typeof ns !== 'string') throw new Error('Namespace should be a string');

  window.mwc[ns] = window.mwc[ns] || new Map();
  const namespace = window.mwc[ns];

  return namespace;
}

/**
 * Loads a dependency and stores in namespace cache for later use
 * @param {string} dependencyName Dependency Key
 * @param {function} promiseFn Function that returns a promise
 * @param {object} options
 * @param {string} options.namespace Custom namespace object or string
 * @param {boolean} options.deferLoading Store promise function to resolve when called again later
 */
export async function loadDependency(
  dependencyName,
  promiseFn,
  { namespace, deferLoading = false } = {}
) {
  let ns = getNamespace();

  if (namespace) {
    ns =
      typeof namespace === 'string'
        ? getNamespace(namespace)
        : namespace(namespace);
  }

  if (ns.has(dependencyName)) {
    const dependency = ns.get(dependencyName);

    if (deferLoading) {
      return dependency;
    }

    if (typeof dependency === 'function' && dependency.deferredResolve) {
      ns.set(dependencyName, await dependency());
    }

    return ns.get(dependencyName);
  }

  if (deferLoading) {
    promiseFn.deferredResolve = true; // eslint-disable-line no-param-reassign
    return ns.set(dependencyName, promiseFn);
  }

  ns.set(dependencyName, await promiseFn());

  return ns.get(dependencyName);
}

/**
 *
 * @param {string} tagName custom element tag name
 * @param {promise} importPromise async chunk
 */
export async function loadCustomElement(tagName, importPromise) {
  const element = window.customElements.get(tagName);

  if (window.customElements.get(tagName)) {
    return element;
  }

  return loadDependency(`components.${tagName}`, importPromise);
}

export default {
  getNamespace,
  loadDependency,
  loadCustomElement,
};

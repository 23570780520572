<template>
  <div class="workspace-tabs__add-modal-form">
    <mds-modal
      ref="navigationModal"
      title="Navigation Information"
      class="tabs__navigation--modal"
    >
      <span slot="mds-modal-actions">
        <mds-button-container>
          <mds-button
            type="button"
            class="modal-actions__cancel-btn"
            variation="secondary"
            :on-dark="darkMode"
            @click.prevent="closeModal"
          >{{ $t('dashboard.common.cancel') }}</mds-button>
          <mds-button
            class="modal-actions__save-btn"
            type="submit"
            variation="primary"
            :on-dark="darkMode"
            :disabled="!(selectedRows.length > 0)"
            @click.prevent="onFeedsSubmit"
          >{{ $t('dashboard.common.save') }}</mds-button>
        </mds-button-container>
      </span>
      <div>
        <feed-dashboard
          :dark-mode="darkMode"
          :selected-rows="selectedRows"
          @onSymRowSelect="handleSelectedSymbols"
        />
      </div>
    </mds-modal>
  </div>
</template>
<script>
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsModal from '@mds/modal';
import FeedDashboard from '../FeedNavigation/FeedDashboard';
import { ThemeMixin } from '../../../mixins/themeMixin';

export default {
  name: 'FeedNavigationModal',

  components: {
    MdsButton,
    MdsButtonContainer,
    MdsModal,
    FeedDashboard,
  },
  mixins: [ThemeMixin],
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedFeeds: [],
    };
  },
  watch: {
    selectedRows: {
      handler() {},
      deep: true,
    },
  },
  methods: {
    closeModal() {
      this.$refs.navigationModal.hide();
    },
    onFeedsSubmit() {
      this.$emit('handleSelectedSymbols', this.selectedFeeds);
      this.$refs.navigationModal.hide();
    },
    handleSelectedSymbols(value) {
      this.selectedFeeds = value;
    },
  },
};
</script>
<style scoped lang="scss">
</style>

<style lang="scss" module>
  @import "src/assets/styles/theme/mds-overrides/feed-navigation-modal";
</style>


import ServerError from '../servererror.es6';
import { isSupportUser } from './wf/issupportuser.es6';
import { MILLIS_PER_HOUR } from '../dates/dates.es6';
import SmartCache from '../smartcache.es6';
import Console from '../console.es6';
import MpUserFeatureSet from './userfeatures/userfeatureset.es6';
import MpUserFeatureNames from './userfeatures/userfeaturenames.es6';

/** @type {?SmartCache} */
let _userFeaturesCache = null;

/**
 * Returns an error message for when server response is an
 * error we can handle.  This method returns null if
 * `payload` is not a handled error.
 * @param {*} payload
 * @return {?string}
 * @private
 */
function _getHandledErrorMsg(payload) {
  if (ServerError.isError(payload)) {
    return ServerError.displayText(payload);
  }
  return null;
}

/**
 * Wraps API lim.MpApi.GetUserFeatures with caching.
 * @param {function(MpUserFeatureSet)} callback Callback to pass current user's feature-set to.
 * @private
 */
function _getUserFeatures(callback) {
  if (_userFeaturesCache === null) {
    _userFeaturesCache = new SmartCache('lim.MpApi.GetUserFeatures', MILLIS_PER_HOUR);
  }
  _userFeaturesCache.get(
    /** @param {(int|ServerError|MpUserFeatureSet)} resp */
    (resp) => {
      if (ServerError.isError(resp)) {
        Console.error(
          'Failed to load user-feature-set, assuming user has no rights to LDS features, due to: {}',
          ServerError.displayText(resp),
        );
        callback(MpUserFeatureSet.EMPTY);
      } else {
        callback(resp);
      }
    },
  );
}

/**
 * Returns whether a user has necessary LDS/MP privileges to maintain feeds, asynchronously.
 * @param {function(boolean)} callback Callback to communicate whether current user can maintain feed.
 */
export function canUserMaintainFeeds(callback) {
  _getUserFeatures(
    /** @param {MpUserFeatureSet} featureSet */
    (featureSet) => {
      callback(
        featureSet.contains(MpUserFeatureNames.FEED_CREATE)
                && featureSet.contains(MpUserFeatureNames.FEED_ENTITLEMENTS_EDIT),
      );
    },
  );
}

/**
 * @namespace
 */
const MpUtils = Object.freeze(/** @lends {MpUtils} */ {

  /**
     * Returns whether the given `payload` represents an error from MP server.
     * @param {*} payload - Server response payload to consider as potential error.
     * @returns {boolean} Whether the given `payload` represents an error from MP server.
     */
  isServerError(payload) {
    return ServerError.isError(payload);
  },

  /**
     * Returns an error message for when server response is an
     * error we can handle.  This method returns null if
     * `payload` is not a handled error.
     * @param {*} payload
     * @returns {?string} User-friendly error message, or null.
     */
  getErrorMessage(payload) {
    return _getHandledErrorMsg(payload);
  },

  /**
     * Analyzes the payload to see if it represents a Marketplace server error. If an error,
     * this method shows it to the user and returns true.  Otherwise, it returns false.
     *
     * @param {Object} payload - Payload which could be an error.
     * @param {(lim.Window|{error: function})} [errorHandler] - An object with an `error`
     *        method, use to display such error to user.  Most likely a Window instance.
     *        If not provided, errors are displayed on top of the entire web page.
     * @returns {boolean} Whether `payload` is an error that's been shown to the user.
     */
  isHandledError(payload, errorHandler) {
    const errMsg = _getHandledErrorMsg(payload);
    if (typeof errMsg !== 'string') {
      return false;
    }
    if (arguments.length > 1) {
      errorHandler.error(errMsg);
    } else {
      lim.DialogBox.error(errMsg);
    }
    return true;
  },

  /**
     * Asynchronous call to whether current user has Support privileges around MP workflows.
     * Only one server call is made, ever; the response is cached permanently.  All callbacks are executed in order
     * they are passed to this caller.
     * @param {Function.<boolean>} callback Callback that receives a boolean argument: whether current user has
     *        Support privileges.
     */
  isWorkflowSupportUser(callback) {
    isSupportUser(callback);
  },
});

export default MpUtils;

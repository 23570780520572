import { parseScript } from 'esprima';
import {
  _listFormulaSteps,
  _getDefaultFormulaCode,
} from './utils/Manager';

function getAlreadyDeclaredVariables(
  args = {
    manager: null,
    nodeIds: [],
  },
) {
  const { manager, nodeIds } = args;
  const declaredVars = [];

  nodeIds.forEach((nodeId) => {
    const currentBubble = manager._priv.bubbles[nodeId];
    let defaultCode = _getDefaultFormulaCode(currentBubble);
    const { formulas } = manager._priv;
    const uuid = currentBubble.data('formula_id');

    if (uuid !== null) {
      if (!Object.hasOwn(formulas, uuid)) {
        throw new Error(`IllegalStateException: unregistered formula ID (${uuid})`);
      }
      defaultCode = formulas[uuid].content;
    }

    const vars = _listFormulaSteps(defaultCode).map(v => ({
      varName: v._name,
      varType: v._type._name,
      bubbleType: currentBubble._type._val,
    }));

    declaredVars.push(...vars);
  });

  return declaredVars;
}

function checkFormulaSyntaxError(code) {
  let errorMessage = '';
  try {
    parseScript(code);
  } catch (error) {
    errorMessage = `Error: Line ${error.lineNumber}: ${error.description}`;
  }
  return errorMessage;
}

const commonUtils = {
  getAlreadyDeclaredVariables,
  checkFormulaSyntaxError,
};

export default commonUtils;

<template>
  <div class="whats-new-slidein">
    <div v-if="version !== '' && releaseDate !== ''">
      <div
        class="mds-header border-bottom-solid whats-new-header"
      >
        <h1 class="mds-header__title component-title whats-new-header-content">
          {{ $t('labels.whatsNew.uiApp') }} {{ version }} ({{ releaseDate }})
        </h1>
        <div class="whats-new-close">
          <mds-button
            icon="remove"
            variation="flat"
            size="touch"
            @click="hideWhatsNew"
          />
        </div>
      </div>
      <div class="whats-new-body">
        <div
          v-if="newFeatures.length > 0"
          class="whats-new-sub-header"
        >
          <span>
            {{ $t('labels.whatsNew.enhancement') }}
          </span>
        </div>
        <ul>
          <li
            v-for="(feature, index) in newFeatures"
            :key="index"
            v-html="feature"
          />
        </ul>
        <div
          v-if="bugFixes.length > 0"
          class="whats-new-sub-header"
        >
          <span>
            {{ $t('labels.whatsNew.bugFixes') }}
          </span>
        </div>
        <ul>
          <li
            v-for="(bug, index) in bugFixes"
            :key="index"
            v-html="bug"
          />
        </ul>
      </div>
    </div>
    <div v-else>
      <div
        v-if="error"
        class="whats-new-error"
      >
        <div
          class="mds-header border-bottom-solid whats-new-header"
        >
          <h1 class="mds-header__title component-title whats-new-header-content">
            {{ $t('navigation.error') }}
          </h1>
          <div class="whats-new-close">
            <mds-button
              icon="remove"
              variation="flat"
              size="touch"
              @click="hideWhatsNew"
            />
          </div>
        </div>
        <div class="whats-new-body">
          {{ $t('labels.whatsNew.error') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { MdsButton } from '@mds/button';
import EventBus from '../../main';

export default {
  name: 'WhatsNew',
  components: {
    MdsButton,
  },
  data() {
    return {
      newFeatures: [],
      bugFixes: [],
      version: '',
      releaseDate: '',
      error: false,
    };
  },
  async created() {
    const whatsNewLink = `https://${process.env.VUE_APP_STATIC_RESOURCES_S3_BUCKET_CF_URL}/products/mcd-ui-app/config.json`;
    await axios.get(whatsNewLink)
      .then((response) => {
        let configs = response.data;
        configs = configs.filter(config => config.isCurrent === true);
        this.version = configs[0].version;
        this.releaseDate = configs[0].releaseDate;
        if ((configs[0].new_features).length > 0) {
          this.newFeatures = configs[0].new_features;
        }
        if (configs[0].bug_fixes.length > 0) {
          this.bugFixes = configs[0].bug_fixes;
        }
      })
      .catch((error) => {
        this.error = true;
      });
  },
  methods: {
    hideWhatsNew() {
      EventBus.$emit('hideWhatsNew');
    },
  },
};
</script>
<style lang="scss">
@import "src/assets/styles/components/custom/whats_new";
</style>

import { requireBoolean } from './booleans.es6';
import { requirePositiveInteger } from './numbers.es6';
import Arrays from './arrays.es6';
import Events from './events.es6';
import { Client, Request } from './ajax.es6';
import Functions, { isFunction, requireFunctionOrNull } from './functions.es6';
import { requireNonEmptyString } from './strings.es6';
import Console from './console.es6';


/* ******************************************************
 * Private variables
 * ****************************************************** */
const REMOTE_URI_HEADER = 'x-mmcd-mim-uri';

const NO_CALL_DATA = { toString() { return 'NO_CALL_DATA'; } };

/* ******************************************************
 * Private methods
 * ****************************************************** */

/**
 *
 * @param {Ajax.Request} request
 * @param {*} payload
 * @returns {*}
 * @private
 */
function _formatPayload(request, payload) {
  const api = request.data('api');
  const apiCtx = request.data('apiContext');
  const params = request.data('callerParams');

  return api.formatResponse(payload, params, apiCtx);
}


/**
 *
 * @param {Ajax.Request} request
 * @private
 */
function _processResponse(request) {
  const callback = request.data('callerCallback');
  const callData = request.data('callerData');
  const api = request.data('api');
  const resp = request.response();
  const httpCode = resp.statusCode();
  const isCustom = (typeof api.isParsableResponse === 'function');
  const isSuccess = ((isCustom
                         && api.isParsableResponse(httpCode))
                     || (!isCustom
                         && httpCode >= 200
                         && httpCode < 300));

  if (!isSuccess) {
    Console.warn(
      'Received [{}] in response to [{} {}] (sub-system URI [{}]).',
      httpCode, request.methodType(), request.url(), resp.header(REMOTE_URI_HEADER),
    );
  }

  if (callback !== null) {
    let payload;

    if (!isSuccess) {
      payload = httpCode;
    } else if (typeof api.isFullResponseHandler === 'function'
                   && api.isFullResponseHandler() === true) {
      payload = _formatPayload(request, resp);
    } else if (resp.isJson()) {
      payload = _formatPayload(request, resp.payloadAsJson());
    } else if (resp.isXml()) {
      payload = _formatPayload(request, resp.payloadAsXml());
    } else {
      payload = _formatPayload(request, resp.payloadAsString());
    }

    // send payload to callback function.
    if (callData !== NO_CALL_DATA) {
      callback(payload, callData);
    } else {
      callback(payload);
    }
  }
}

/**
 * Gets and returns the current API handler's call data,
 * and resets the API's value.
 * @param {(ApiHandler|LimitlessApiHandler)} apiHandler
 * @returns {*}
 * @private
 */
function _getCallData(apiHandler) {
  /* Reset `this._callData` now, in case an exception occurs
     * we won't have old "callData" lingering around. */
  const callData = apiHandler._callData;
  apiHandler._callData = NO_CALL_DATA;
  return callData;
}

/**
 * Creates a new AJAX Request object.
 *
 * In some cases, `api.getUrl()` can return null.  If that happens,
 * `callerCallback` is given that null value immediately and this method
 * returns `null` to indicate to caller to cease-and-desist.
 *
 * @param {{getUrl: function, [requestContext]: function, [async]: function,
 *          [methodType]: function, [requestBody]: function, [contentType]: function,
  *          [headers]: function }} api
 * @param {Array} apiArgs
 * @param {function} apiHandlerCallback
 * @param {function} callerCallback
 * @param {*} callData
 * @returns {?Ajax.Request}
 * @private
 */
function _newRequest(api, apiArgs, apiHandlerCallback, callerCallback, callData) {
  const url = api.getUrl.apply(api, apiArgs);

  if (url === null) {
    if (callerCallback !== null) {
      // If getUrl returned null, pass that value directly to callback function.
      if (callData !== NO_CALL_DATA) { callerCallback(null, callData); } else { callerCallback(null); }
    }
    return null;
  }

  const request = new Request();

  request.url(url)
    .callback(apiHandlerCallback)
    .data('api', api)
    .data('callerCallback', callerCallback)
    .data('callerData', callData)
    .data('callerParams', apiArgs);

  if (typeof api.requestContext === 'function') { request.data('apiContext', api.requestContext()); }

  if (typeof api.async === 'function') { request.async(api.async()); }

  if (typeof api.methodType === 'function') {
    request.methodType(api.methodType());

    if (typeof api.requestBody === 'function') {
      request.payload(api.requestBody());

      if (typeof api.contentType === 'function') { request.contentType(api.contentType()); }
    }
  }

  if (typeof api.headers === 'function') {
    const hdrs = api.headers();
    for (const name of Object.keys(hdrs)) {
      request.header(name, hdrs[name]);
    }
  }

  return request;
}


class ApiRegistrar {
  constructor() {
    this._apis = {};
  }

  _loadedApi(name) {
    let api = null;
    const lib = this._apis;

    if (lib.hasOwnProperty(name)) {
      api = lib[name];
    } else {
      const apiClass = Functions.findClass(name);
      if (apiClass === null || !isFunction(apiClass)) {
        throw new Error(`API not a function: ${ name}`);
      }

      api = new apiClass();
      this._register(name, api);
    }

    return api;
  }

  /**
     * This method is used to register APIs to this handler.
     * API calls cannot be made until that API is registered with
     * the handler.
     * @param {string} name Name of the API.
     * @param {{getUrl: function, formatResponse: function}} api
     */
  _register(name, api) {
    if (!isFunction(api.getUrl)
            || !isFunction(api.formatResponse)) {
      throw new TypeError(
        `${name }: invalid API immplementation, must implement getUrl(), formatResponse()`,
      );
    }

    this._apis[name] = api;
  }

  /**
     * Returns the named API instance.
     * @param {string} apiName Name of API.
     * @returns {*}
     */
  get(apiName) {
    return this._loadedApi(apiName);
  }

  /**
     * Loads the name API.
     * @param {string} apiName Name of API.
     * @returns {ApiRegistrar}
     */
  load(apiName) {
    this._loadedApi(apiName);
    return this;
  }
}


/**
 * <p>
 *  ApiHandler is a generic object that makes API calls
 *  on behalf of the user.  Using an API handler
 *  will ensure that server requests can be controlled,
 *  and that new requests automatically cancel previous
 *  ones.  This in turns helps keeping the UI under control.
 * </p>
 *
 * <p>
 *  HOW IT WORKS
 *  During construction, a set number of channels are defined.
 *  Calls made on a given channel will automatically cancel
 *  the previous call made on that same channel.  If simultaneous
 *  calls are desired, simply use a different channel.
 * </p>
 */
export class ApiHandler {
  /**
     * @param {int} [numChannels] - Number of concurrent
     */
  constructor(numChannels) {
    const numC = (arguments.length < 1) ? 1 : requirePositiveInteger(numChannels, 'numChannels');

    this._client = new Client().maxConnections(numC);
    this._apiReg = new ApiRegistrar();
    this._callData = NO_CALL_DATA;
    this._channels = Arrays.newInstance(numC, null);

    this._primaryResponseHandler = this._primaryResponseHandler.bind(this);
  }

  /**
     * Returns the API Registrar instance associated with this handler.
     * @returns {ApiRegistrar}
     */
  apiRegistrar() {
    return this._apiReg;
  }

  /**
     * <p>
     *  This method is private to ApiHandler.
     * </p>
     *
     * <p>
     *  This method is set as the primary callback function for all API calls.
     *  It offers default behaviour for all APIs - such as transforming the
     *  response into a JSON object and reformatting the response based
     *  upon the API's formatResponse() method - and then passes
     *  the reformatted JSON response to the client.
     * </p>
     *
     * @param request {Ajax.Request}
     */
  _primaryResponseHandler(request) {
    const channel = request.data('apiChannel');
    this._channels[channel] = null;
    _processResponse(request);
  }

  /**
     * <p>
     *  This method makes a call to the API.  The first three parameters
     *  are used by <code>ApiHandler</code> while subsequent parameters
     *  are passed to the API's <code>getUrl()</code> method.
     * </p>
     *
     * <p>
     *  This method makes calls on channel <code>0</code> only.
     * </p>
     *
     * @param name (String) - The name of the API being called.
     * @param callback (Function) - The callback function.
     * @param apiSpecificParams (Object...)
     */
  call(name, callback, apiSpecificParams) {
    const args = Arrays.slice(arguments);
    args.splice(1, 0, 0); // Insert `0` as 2nd argument (arguments[1])
    this.callInChannel.apply(this, args);
  }

  /**
     * <p>
     *  This method makes a call to the API.  The first four parameters
     *  are used by <code>ApiHandler</code> while subsequent parameters
     *  are passed to the API's <code>getUrl()</code> method.
     * </p>
     *
     * @param {string} name The name of the API being called.
     * @param {int} channel The channel on which to make the call.
     * @param {Function} callback The callback function.
     * @param {...(*)} apiSpecificParams Arguments to be passed to the API's getUrl method.
     */
  callInChannel(name, channel, callback, apiSpecificParams) {
    const callData = _getCallData(this);

    const api = this._apiReg.get(name);
    this._isValidChannel(channel);

    if (callback !== null
            && typeof callback !== 'function') { throw new TypeError('callback: Function'); }

    const apiArgs = Arrays.slice(arguments, 3);
    const request = _newRequest(api, apiArgs, this._primaryResponseHandler, callback, callData);

    if (request !== null) {
      request.data('apiChannel', channel);

      this.cancelRequest(channel);
      this._channels[channel] = request;

      this._client.execute(request);
    }
  }


  /**
     * Sets call-specific data.  This data will be
     * passed to the next call's callback function
     * as its 2nd argument.
     * @param callData {Object}
     * @returns {ApiHandler}
     */
  setCallData(callData) {
    this._callData = callData;
    return this;
  }


  destroy() {
    this.cancelAllRequests();
    this._client.destroy();
  }

  /**
     * Cancels outstanding requests on all channels.
     */
  cancelAllRequests() {
    for (let cnt = 0; cnt < this._channels.length; cnt++) {
      this.cancelRequest(cnt);
    }
  }


  /**
     * Cancel an outstanding request within a given <code>channel</code>.
     * @param channel (int)
     */
  cancelRequest(channel) {
    this._isValidChannel(channel);

    const request = this._channels[channel];
    if (request !== null) {
      this._channels[channel] = null;
      if (request.isPending()) { request.abort(); }
    }
  }

  /**
     * Returns whether <code>channel</code> contains an outstanding request.
     * @param channel (int)
     * @returns boolean - Busy (true), or not busy (false)
     */
  isBusyChannel(channel) {
    this._isValidChannel(channel);

    const request = this._channels[channel];
    return (request !== null);
  }

  /**
     * Returns whether this instance is currently busy with at least one
     * server request.
     * @returns {boolean} Busy (true) or not busy (false).
     */
  isBusy() {
    const channels = this._channels;
    let isBusy = false;

    for (let i = 0, len = channels.length;
      i < len && isBusy === false;
      i++) {
      if (this.isBusyChannel(i)) {
        isBusy = true;
      }
    }

    return isBusy;
  }

  /**
     * Private. Validates the <code>channel</code> parameter and throws
     * an exception if invalid.
     * @param channel (int)
     */
  _isValidChannel(channel) {
    if (!Arrays.isValidIndex(channel, this._channels)) { throw 'IllegalArgumentException: channel must be a number between 0 and numChannels.'; }
  }
}


/**
 * <p>
 *  This "limitless" API handler executes all API calls
 *  as requested by the caller; no call is ever cancelled.
 *  This API also supports events (onBusy, onIdle)
 *  to notify the caller when it is "busy" and "idle";
 *  the caller should use these events to prevent
 *  conflicting API calls from being executed simultaneously.
 * </p>
 */

export class LimitlessApiHandler {
  /**
     * @param {int} [maxConcurrentRequests=1] Maximum number of concurrent requests allowed.
     */
  constructor(maxConcurrentRequests) {
    this._client = new Client().maxConnections(
      (arguments.length < 1)
        ? 1
        : requirePositiveInteger(maxConcurrentRequests, 'maxConcurrentRequests'),
    );
    this._apiReg = new ApiRegistrar();
    this._callData = NO_CALL_DATA;
    this._numResponses = 0;

    this._canDestroy = true;
    this.events = new Events('onBusy', 'onIdle');

    this._primaryResponseHandler = this._primaryResponseHandler.bind(this);
  }

  /**
     * Returns the API Registrar instance associated with this handler.
     * @returns {ApiRegistrar}
     */
  apiRegistrar() {
    return this._apiReg;
  }

  canDestroy(canDestroy) {
    if (arguments.length < 1) {
      return this._canDestroy;
    }
    this._canDestroy = requireBoolean(canDestroy, 'canDestroy');
    return this;
  }

  /**
     * <p>
     *  This method is private to ApiHandler.
     * </p>
     *
     * <p>
     *  This method is set as the primary callback function for all API calls.
     *  It offers default behaviour for all APIs - such as transforming the
     *  response into a JSON object and reformatting the response based
     *  upon the API's formatResponse() method - and then passes
     *  the reformatted JSON response to the client.
     * </p>
     *
     * @param request {Ajax.Request}
     */
  _primaryResponseHandler(request) {
    this._numResponses++;

    _processResponse(request);

    this._numResponses--;
    if (this._client.size() === 0) {
      this.events.send('onIdle');
    }
  }

  /**
     * <p>
     *  This method makes a call to the API.  The first three parameters
     *  are used by <code>ApiHandler</code> while subsequent parameters
     *  are passed to the API's <code>getUrl()</code> method.
     * </p>
     *
     * @param name (String) - The name of the API being called.
     * @param callback (Function) - The callback function.
     * @param apiSpecificParams (Object...)
     */
  call(name, callback, apiSpecificParams) {
    const callData = _getCallData(this);

    const api = this._apiReg.get(name);

    requireFunctionOrNull(callback, 'callback');

    if (this._client.size() === 0
            && this._numResponses === 0) {
      this.events.send('onBusy');
    }

    const apiArgs = Arrays.slice(arguments, 2);
    const request = _newRequest(api, apiArgs, this._primaryResponseHandler, callback, callData);

    if (request !== null) {
      this._client.execute(request);
    }
  }


  /**
     * Sets call-specific data.  This data will be
     * passed to the next call's callback function
     * as its 2nd argument.
     * @param callData {Object}
     * @returns {LimitlessApiHandler}
     */
  setCallData(callData) {
    this._callData = callData;
    return this;
  }

  /**
     * Destroys the instance.  Note that LimitlessApiHandler instances intended to be
     * re-usable should not be destroyed; they should be _released_.
     * @see release(callbacks)
     */
  destroy() {
    if (!this._canDestroy) {
      throw new Error('cannot destroy this instance, use `release()` instead');
    }
    this._client.destroy();
  }

  /**
     * Call this method when you're done using this API handler.
     * The method's arguments should be all of the possible
     * callback functions that might have been used during
     * previous calls.  If you're not sure if a callback
     * function has been used, pass it anyway; it won't hurt.
     *
     * @param {(...Function|Function[])} callbacks Callback functions can be specified as an Array of Function,
     *        or each function can be passed as a separate argument.
     */
  release(callbacks) {
    if (arguments.length === 0) {
      throw new Error('must provide at least one callback function to release');
    }

    const list = ((Array.isArray(callbacks)) ? callbacks : Arrays.slice(arguments));
    Arrays.requireValid(list, isFunction, 'callbacks');

    for (const request of this._client.requests()) {
      const callback = request.data('callerCallback');
      if (Arrays.indexOf(callback, list) >= 0) {
        request.abort();
      }
    }
  }

  /**
     * Returns whether this instance has any active request(s).
     * @returns {boolean} Busy (true), or idle (false)
     */
  isBusy() {
    return (this._client.size() > 0);
  }

  /**
     * Returns whether this instance is idle.  An instance is
     * idle when there are no active request (opposite of
     * <code>isBusy()</code>.
     * @returns {boolean} Idle (true), or busy (false)
     */
  isIdle() {
    return (this._client.size() === 0);
  }
}


/**
 * ApiHandlerFactory is meant to be a singleton class
 * that constructs instances of LimitlessApiHandler
 * as needed.  This factory decides when new instances
 * are necessary, and how API handler instances are shared
 * amongst multiple users.  Using this factory helps
 * pace our AJAX requests to the server, so that we don't
 * overwhelm the browsers with too many open connections.
 */
export class ApiHandlerFactory {
  constructor() {
    /** @type {Object.<string, LimitlessApiHandler>} */
    this._handlers = {};
  }

  /**
     * Returns an instance of an API handler object.
     * The instance returned may be new and/or shared
     * with other callers.  Users of this
     * function should call release() on the returned
     * API handler to dispose of it; release() instead of
     * destroy(), to allow the API handler to remain
     * usable to other users of the same handler.
     *
     * Users of this method should only call this method
     * in a conservative manner, as each call could create
     * a new API handler instance.  Typically, users
     * should only call this method once.
     *
     * @param {string} purpose
     */
  get(purpose) {
    requireNonEmptyString(purpose, 'purpose');

    /*
         * NOTE: Never return ApiHandler!!!
         *
         * This method must always return instances of LimitlessApiHandler.
         * More specifically, it cannot return instances of ApiHandler.
         * That's because by design, ApiHandler cannot be shared amongst
         * users (it automatically cancels requests as new requests are being made).
         */

    let bucket = null;
    switch (purpose) {
      case 'symbol-cache':
      case 'weather-info':
        bucket = 'meta-info';
        break;

      case 'email-server':
      case 'sharing-server':
        bucket = 'email/sharing';
        break;

      default:
        bucket = purpose;
    }

    const handlers = this._handlers;
    let handler = handlers[bucket];

    if (!(handler instanceof LimitlessApiHandler)) {
      handler = new LimitlessApiHandler(1);
      handler.canDestroy(false);

      handlers[bucket] = handler;
    }

    return handler;
  }
}

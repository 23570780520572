<template>
  <section
    slot="pdf-content"
    style="width: 100%;"
  >
    <section
      v-for="(page, index) in canvasContent"
      :key="'pdf-page-' + (index + 1)"
      :ref="'pdf-page-' + (index + 1)"
    >
      <section
        v-if="index == 0"
        class="pdf-item"
        style="height: 300px; overflow: hidden;"
      >
        <mds-layout-grid :ref="'logo'">
          <mds-row>
            <mds-col :cols="12">
              <img
                :src="require('../../assets/images/common/Mstar-logo-50px.svg')"
                class="center"
                alt="morningstar"
              >
            </mds-col>
          </mds-row>
          <mds-row>
            <mds-col :cols="12">
              <h2 style="text-align:center;">
                {{ title }}
              </h2>
              <h3 style="text-align:center;">
                {{ getTodaysDate }}
              </h3>
              <div v-html="getFilter" />
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </section>
      <section
        v-for="(source, sourceIndex) in page"
        :key="'pdf_' + sourceIndex"
        :ref="'pdf_' + sourceIndex"
        class="pdf-item"
      >
        <mds-table
          :style="{ 'height': source['sourceDescription'].length > 400 ? '300px': '150px',
                    'max-height': source['sourceDescription'].length > 400 ? '300px': '150px',
                    'overflow': 'hidden', 'margin-top': '5px' }"
        >
          <mds-thead hidden-header>
            <mds-th
              v-for="(header, pdfIndex) in pdfHeaders"
              :key="pdfIndex"
              :class="header.text === 'Description' ? 'customWidthForPdf' : 'defaultWidthForPdf'"
            >
              {{ header.text }}
            </mds-th>
          </mds-thead>
          <mds-tbody>
            <mds-tr>
              <mds-td
                v-for="(header, i) in pdfHeaders"
                :key="i"
              >
                <div
                  v-if="header.fieldName !== 'sourceUrl' "
                >
                  <div class="block-with-text">
                    {{ source[header.fieldName] }}
                  </div>
                  <div style="height: 30px; margin-top: 5px">
                    <mds-layout-grid>
                      <mds-row>
                        <mds-col v-if="source['market'] && source['market'] != ''">
                          <strong>Market: </strong>{{ source['market'] | parseMarkets }}
                        </mds-col>
                      </mds-row>
                    </mds-layout-grid>
                  </div>
                </div>
                <div
                  v-if="header.fieldName === 'sourceUrl'"
                >
                  <img
                    crossorigin="anonymous"
                    class="img-pdf"
                    alt="Go to Source"
                    aria-label="Source Provider Image"
                    :src="getImgUrl(source['source'].toLowerCase())"
                  >
                  <h5 style="text-align: center;">
                    {{ source['source'] }}
                  </h5>
                </div>
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
        <div class="line-2" />
        <div
          v-if="sourceToBreakPage.includes(source['source'])"
          class="html2pdf__page-break"
        />
      </section>
    </section>
  </section>
</template>

<script>
import {
  MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr,
} from '@mds/data-table';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import EventBus from '../../main';

export default {
  name: 'VendorReportPdf',
  components: {
    MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr, MdsLayoutGrid, MdsRow, MdsCol,
  },
  filters: {
    parseMarkets(marketStr) {
      return marketStr.replace(/;/g, ', ');
    },
  },
  props: {
    sources: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Commodity Data Vendor Report',
    },
    navData: {
      type: Object,
      default: () => {},
    },
    searchData: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pdfHeaders: [
        {
          fieldName: 'sourceUrl',
          text: 'Link',
          class: 'mds_test__column-class',
        },
        {
          fieldName: 'sourceDescription',
          text: 'Description',
        },
      ],
      pageContent: [],
      canvasContent: [],
      sourceToBreakPage: [],
      pdfOptions: {
        margin: 5,
        filename: 'Commodity Vendor Report.pdf',
        image: { type: 'jpg', quality: 0.98 },
        html2canvas: {
          useCORS: true,
        },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
      },
      currentHeight: 305,
      maxPageHeight: 1100,
    };
  },
  computed: {
    getFilter() {
      let filterStr = '';
      if (this.navData && Object.keys(this.navData).length > 0) {
        filterStr = this.getFilterStr(this.navData.market, filterStr, 'Market');
        filterStr = this.getFilterStr(this.navData.country, filterStr, 'Country');
        filterStr = this.getFilterStr(this.navData.licensingType, filterStr, 'Licensing Type');
      } else {
        filterStr = this.getFilterStr([], filterStr, 'Market');
        filterStr = this.getFilterStr([], filterStr, 'Country');
        filterStr = this.getFilterStr([], filterStr, 'Licensing Type');
      }

      if (this.searchData !== '') {
        filterStr += `<span style='font-size: 18px;'>Search:</span><span style='font-size: 16px'> ${this.searchData} </span><br>`;
      }
      return filterStr;
    },
    getTodaysDate() {
      return moment().format('MMMM D, YYYY');
    },
  },
  beforeDestroy() {
    EventBus.$off('generatePdf');
  },
  mounted() {
    EventBus.$on('generatePdf', this.generatePdf);
  },
  created() {
    this.getPages();
    this.getCanvasContent();
  },
  methods: {
    getPages() {
      let height = 300;
      const maxPageHeight = 1100;
      let singlePageContent = [];
      this.sourceToBreakPage = [];

      this.sources.forEach((source) => {
        if (source['sourceDescription'].length > 400) {
          height += 300;
          singlePageContent.push(source);
        } else {
          height += 150;
          singlePageContent.push(source);
        }

        if (height > maxPageHeight) {
          const tempSrc = singlePageContent.pop();
          this.pageContent.push(singlePageContent);
          this.sourceToBreakPage.push(singlePageContent[singlePageContent.length - 1].source);
          singlePageContent = [tempSrc];
          height = tempSrc['sourceDescription'].length > 400 ? 300 : 150;
        }
      });
      this.pageContent.push(singlePageContent);
    },
    getCanvasContent() {
      let canvas7 = [];
      this.pageContent.forEach((page, index) => {
        if ((index + 1) % 12 === 0) {
          this.canvasContent.push(canvas7.flat());
          canvas7 = [];
        }
        canvas7.push(page.flat());
      });
      this.canvasContent.push(canvas7.flat());
    },
    getImgUrl(name) {
      return `https://${process.env.VUE_APP_STATIC_RESOURCES_S3_BUCKET_CF_URL}/images/${name}.PNG`;
    },
    getFilterStr(filterArr, filterStr, keyStr) {
      if (filterArr.length > 0) {
        filterStr += `<span style='font-size: 18px;'>${keyStr}:</span><span style='font-size: 16px'>`;
        filterArr.forEach((market) => {
          filterStr += ` ${market[0].toUpperCase() + market.substring(1)},`;
        });
      } else {
        filterStr += `<span style='font-size: 18px;'>${keyStr}:</span><span style='font-size: 16px'> All `;
      }
      filterStr = filterStr.slice(0, filterStr.length - 1);
      filterStr += '</span><br>';
      return filterStr;
    },
    async generatePdf() {
      this.$forceUpdate();
      this.getPages();
      this.getCanvasContent();
      const pages = Object.keys(this.$refs).filter(ref => /^pdf-page-/.test(ref)).map(val => this.$refs[val][0]);

      let worker = html2pdf().set(this.pdfOptions).from(pages[0]);

      if (pages.length > 1) {
        worker = worker.toPdf();

        pages.slice(1).forEach((page, index) => {
          worker = worker.get('pdf').then((pdf) => {
            pdf.addPage();
          }).from(page)
            .toCanvas()
            .toPdf();
        });
      }
      worker = await worker.save();
      EventBus.$emit('pdfGenerated');
    },
  },
};
</script>

<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
@import '@mds/link';

  h3,h2 {
    color : $mds-text-color-primary;
  }
  .text-format{
    @include mds-body-text-m();
    text-align: left !important;
    color: #1e1e1e !important;
  }
  .img-init{
    float: left;
    width:  100%;
    height: 100%;
    object-fit: cover;
  }
  .img-pdf{
    max-width: 100%;
    max-height: 70px;
    object-fit: contain;
    flex: 1;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  }
  .customWidthForPdf {
    width:80%
  }
  .defaultWidthForPdf {
    width:20%
  }

  .html2pdf__page-break {
    margin: 0;
    background-color:black;
  }

  .line-2 {
  border-top: 1px solid #cccccc;
  }

  .block-with-text {
  text-align: justify;
  overflow: hidden;
  max-height: 75%;
}
</style>

const state = {
  activeHeading: 'Home',
  siteMenuItems: [
    {
      id: 'home',
      text: 'Home',
      href: '/#/all',
      order: 1,
    },
    {
      id: 'data',
      text: 'Data',
      sublist: [
        {
          id: 'catalog',
          text: 'Catalog',
          href: '/#/catalog',
        },
        {
          id: 'vendors',
          text: 'Vendors',
          href: 'https://documentations-prod.morningstar.com/MCD_Vendors_Catalog.pdf',
        },
        {
          id: 'coredata',
          text: 'Core Data',
          href: 'https://documentations-prod.morningstar.com/Marketplace+Core+Data+List.pdf',
        },
        {
          id: 'calendars',
          text: 'Calendars',
          href: 'https://documentations-prod.morningstar.com/Mstar_CoppClark_Calendar+list.pdf',
        },
        {
          id: 'dataalert',
          text: 'Data Alert',
          href: '/#/dataalert',
        },
        {
          id: 'dashboard',
          text: 'Dashboard',
          href: '/#/dashboard',
          featureType: ['FEATURE_DASHBOARD_BETA', 'FEATURE_DASHBOARD_ALPHA', 'FEATURE_DASHBOARD_PROD'],
        },
      ],
      order: 2,
    },
    // {
    //   id: 'dataAnalytics',
    //   text: 'Data Analytics & Science',
    //   sublist: [
    //     {
    //       id: 'overview',
    //       text: 'Overview',
    //       href: '/#/all/dataAnalytics',
    //     },
    //     {
    //       id: 'pjm',
    //       text: 'PJM',
    //       href: '/#/dataAnalytics/pjm',
    //       featureType: ['POWER_FORECAST_PJM'],
    //     },
    //     {
    //       id: 'ercot',
    //       text: 'ERCOT',
    //       href: '/#/dataAnalytics/ercot',
    //       featureType: ['POWER_FORECAST_ERCOT'],
    //     },
    //   ],
    //   order: 3,
    // },
    {
      id: 'excel',

      text: 'Excel Add-In',
      sublist: [
        {
          id: 'excelOverview',
          text: 'Overview',
          href: '/#/all/excelAddIn',
        },
        {
          id: 'excelDownload',
          text: 'Download',
          href: '/#/all/excelAddIn#resource_0',
        },
      ],
      order: 4,
    },
    {
      id: 'system',
      text: 'System Integration',
      sublist: [
        {
          id: 'overviewsystem',
          text: 'Overview',
          href: '/#/all/systemIntegration',
        },
        {
          id: 'publisher',
          text: 'Publisher',
          href: 'https://mp.morningstarcommodity.com/marketplace/loginPage',
        },
        {
          id: 'scheduler',
          text: 'Scheduler',
          href: '/#/scheduler',
        },
      ],
      order: 5,
    },
    {
      id: 'workflow',
      text: 'Workflow Manager',
      sublist: [
        {
          id: 'workflowOverview',
          text: 'Overview',
          href: '/#/all/workflowManager',
        },
        {
          id: 'workflowHome',
          text: 'Workflow Home',
          href: '/#/workflow',
          featureType: ['WORKFLOW_MANAGER'],
        },
        {
          id: 'workflowSite',
          text: 'Visit Site',
          href: 'https://markets.morningstarcommodity.com',
        },
      ],
      order: 6,
    },
    {
      id: 'developer',
      text: 'Developer',
      iconLeft: 'wrench-padless',
      open: false,
      sublist: [
        {
          id: 'webservice',
          text: 'Web Services',
          href: '/#/all/webServices',
        },
        {
          id: 'python',
          text: 'Python',
          href: '/#/all/python',
        },
        {
          id: 'rPackage',
          text: 'R Package',
          href: '/#/all/rPackage',
        },
      ],
      href: '/#/developer',
      order: 7,
    },
    {
      id: 'administration',
      text: 'Administration',
      sublist: [
        {
          id: 'internaldata',
          text: 'Internal Data',
          href: '/#/feeds',
          featureType: ['FEED_MANAGEMENT'],
        },
        {
          id: 'user',
          text: 'User',
          href: '/#/user',
        },
        {
          id: 'auditlog',
          text: 'Audit Log',
          href: '/#/auditlog',
        },
        {
          id: 'reports',
          text: 'Reports',
          href: '/#/reports',
        },
      ],
      order: 8,
    },
  ],
  siteMenuItemsMobile: [
    {
      id: 'all',
      text: 'All',
      iconLeft: 'grab-handle',
      href: '/#/all',
      order: 1,
    },
    {
      id: 'products',
      text: 'Products',
      iconLeft: 'grab-handle',
      href: '/#/products',
      order: 2,
    },
    {
      id: 'research',
      text: 'Research',
      iconLeft: 'bullhorn-padless',
      href: '/#/research',
      order: 3,
    },
    {
      id: 'datamanagement',
      text: 'Data Management',
      iconLeft: 'news',
      href: '/#/datamanagement',
      order: 4,
    },
    {
      id: 'developer',
      text: 'Developer',
      iconLeft: 'wrench-padless',
      href: '/#/developer',
      order: 5,
    },
  ],
};

export default state;


const actions = {
  addSiteMenuItems({ commit }, menuItems) {
    commit('ADD_SITE_MENU_ITEMS', menuItems);
  },
  removeSiteMenuItem({ commit }, itemID) {
    commit('DEL_SITE_MENU_ITEM', itemID);
  },
  updateActiveHeading({ state }, toPath) {
    const { meta } = toPath;
    state.activeHeading = meta.heading ? meta.heading : 'Menu';
    const routePath = `/#${ toPath.path}`;
    state.siteMenuItems.forEach((item) => {
      if (item.sublist && item.sublist.length > 0) {
        const subListAr = item.sublist.filter(subItem => subItem.href === routePath);
        if (subListAr.length > 0) {
          state.activeHeading = `${item.text }/${ subListAr[0].text}`;
        }
      }
    });
  },
};

export default actions;

<template>
  <div
    class="dashboard dynamic-theme"
  >
    <router-view
      name="home"
      class="us__content"
    />
  </div>
</template>
<script>

export default {
  name: 'Dashboard',
};
</script>

<style lang="scss">
  @import "src/assets/styles/styles";
  @import "src/assets/styles/components/dashboard/dashboard";
</style>

<template>
  <div>
    <div
      v-if="loader && (hasError === null || hasError === '')"
      id="mds-overlay"
      class="mds-overlay"
    />
    <login-app
      mwc-id="login-container"
      :config="config"
      @signInRequested="handleSignInRequest"
    >
      <!--TODO:add logo and supplemntal content in next login update</p>-->
    </login-app>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import LoginApp from '../LoginForm/mwcLoginPage.vue';

export default {
  name: 'LoginPage',
  components: { LoginApp },
  data() {
    return {
      loader: false,
    };
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.loginModule.isAuthenticated,
      hasError: state => state.loginModule.loginError,
    }),
    config() {
      return {
        settings: {
          useEmail: true,
          languageId: 'en',
          errorText: this.hasError,
          resetPasswordUrl: 'https://access.lim.com/authws/pages/pw_reset.jsp',
        },
      };
    },
  },
  methods: {
    ...mapActions(['dispatchAction']),
    async signIn(signInInfo) {
      this.loader = true;
      const results = this.$store
        .dispatch('loginModule/doLogin', {
          userName: signInInfo.userName,
          password: signInInfo.password,
        })
        .then((res) => {
          this.loader = false;
          if (
            this.$store.state.loginModule.accessToken
            && this.$store.state.loginModule.isAuthenticated
          ) {
            if (this.$route.query['return_to']) {
              this.loader = false;
              this.$router.push(this.$route.query['return_to']);
            } else {
              this.loader = false;
              this.$router.push('all');
            }
          }
        });
      // if there is no error go to home page
    },
    handleKeepLogged() {
      this.dispatchAction('toggleRememberMe');
    },
    handleShowPassword() {
      this.$emit('handleShowPassword');
    },
    handleSignInRequest(customEvent) {
      if (customEvent && customEvent.keepUserLoggedIn) {
        this.handleKeepLogged();
      }
      if (customEvent && customEvent.userName) {
        this.signIn(customEvent);
      }
    },
  },
};
</script>

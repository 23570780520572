import axios from 'axios';

const actions = {
  getUserFeeds({ commit }) {

  },
  addWorkspace({ commit }, payload) {
    commit('ADD_WORKSPACE', payload);
  },
  addWidget({ commit }, payload) {
    commit('ADD_WIDGET', payload);
  },
  deleteWorkspace({ commit }, payload) {
    commit('DELETE_WORKSPACE', payload);
  },
  renameWorkspace({ commit }, payload) {
    commit('RENAME_WORKSPACE', payload);
  },
};

export default actions;

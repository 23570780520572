const mutations = {
  ADD_FEEDS: (state, feeds) => {
    state.feedList = feeds;
  },
  SET_PAGE_STATUS: (state, status) => {
    state.pageStatus = status;
  },
  SET_FEED_DETAILS: (state, feedDetails) => {
    state.feedDetails = feedDetails;
  },
  SET_USER_ROLES: (state, userRoles) => {
    state.userRoles = userRoles;
  },
  SET_ERROR_STATUS: (state, status) => {
    state.errorStatus = status;
  },
  SET_ERROR_MESSAGE: (state, exception) => {
    state.errorMessage = exception;
  },
  SET_ACTIVE_FEED: (state, feedName) => {
    state.activeFeedName = feedName;
  },
  SET_FEED_ENTITLEMENTS: (state, feedEntitlements) => {
    state.feedEntitlements = Object.assign({}, feedEntitlements);
  },
  SET_FEED_GROUP_ENTITLEMENTS: (state, feedEntitlements) => {
    state.feedGroupEntitlements = [...feedEntitlements];
  },
  SET_PRIVATE_FEED_USERS: (state, payload) => {
    state.privateFeedUsers = payload && payload.length > 0 ? payload[0].users.slice() : [];
  },
  SET_USER_DATASOURCE: (state, userDatasource) => {
    state.userDatasource = userDatasource;
  },
  CORE_FEED: (state, coreFeed) => {
    state.coreFeed = coreFeed;
  },
  SET_ROOT_CONTRACTS: (state, rootContracts) => {
    state.rootContracts = rootContracts;
  },
  SET_METADATA: (state, metadata) => {
    state.metadata = metadata;
  },
  SET_FEED_GROUPS: (state, feedGroups) => {
    state.feedGroups = [];
    feedGroups.forEach((element) => {
      const groupObj = state.feedGroups.filter(c => c.groupName === element);
      if (groupObj.length === 0) {
        state.feedGroups.push({
          groupName: element,
          // userList: [],
          feedNames: [],
          loaded: false,
          loader: false,
          checked: false,
        });
      }
    });
  },
  SET_GROUP_FEEDS: (state, groupFeeds) => {
    const groupObj = state.feedGroups.filter(c => c.groupName === groupFeeds.groupName);
    if (groupObj.length > 0) {
      groupObj[0].feedNames = groupFeeds.feedList;
      groupObj[0].loaded = true;
    }
  },
  SET_FEED_GROUP_PAGE: (state, feedGroupPageStatus) => {
    state.feedGroupPageStatus = feedGroupPageStatus;
  },
  USER_FEEDS: (state, userFeeds) => {
    const groupFeeds = [];
    userFeeds
      .filter(feed => feed.privateFeed === true)
      .forEach((element) => {
        groupFeeds.push(element.name);
      });
    state.userFeeds = groupFeeds;
  },
  SET_GROUP_ERROR_MESSAGE: (state, exception) => {
    state.groupErrorMessage = exception;
  },
  ADD_DATASOURCES_FEEDS: (state, userDatasources) => {
    state.userDatasources = userDatasources;
  },
  ADD_FEED_ROOTS: (state, rootsData) => {
    state.feedRoots[rootsData.feedName] = rootsData.rootDesc;
  },
  // ADD_FEED_KEYS: (state, keysData) => {
  //   state.feedKeys[keysData.feedName] = keysData.keys;
  // },
};

export default mutations;

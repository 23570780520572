import Text from './lang/feed_en-us.es6';
import { requireBoolean} from "../../booleans.es6";
import { isInteger} from "../../numbers.es6";
import Strings, { requireString, requireNonEmptyString } from "../../strings.es6";
import Functions, { requireFunction } from "../../functions.es6";
import HttpCode from "../../httpcodes.es6";
import ServerError from '../../servererror.es6';
import Dates from '../../dates/dates.es6';
import Arrays from '../../arrays.es6';
import { requireObject } from '../../objects.es6';
import MpField, { MpFieldType } from '../field/field.es6';
import MpRoot from '../root/root.es6';
import SmartCache from '../../smartcache.es6'
import {LimitlessApiHandler} from "../../apihandler.es6";
import { groupBy } from 'underscore';
import Console from '../../console.es6';


/**
 * A MpFeedCallback function receives one argument:
 * a MpFeedController, which is a wrapper with
 * convenient methods to access feed information
 * for which the current user is entitled to.
 *
 * @callback MpFeedCallback
 * @param feedController {MpFeedController}
 */

/**
 * A MpFeedFieldsCallback function receives one argument: an array of MpField objects,
 * sorted by name.  That array is mutable; recipients are free to modify it.
 *
 * @callback MpFeedFieldsCallback
 * @param {(MpField[]|ServerError)} fields
 */

/**
 * A MpFeedRootsCallback function receives one argument: an array of MpRoot objects,
 * sorted by name. That array is immutable; recipients cannot modify it, they must
 * create a copy first.
 *
 * @callback MpFeedRootsCallback
 * @param {(MpRoot[]|ServerError)} roots
 */


/* **************************************************
 * Private variables
 * ************************************************** */
let _canConstructFeed = false;
let _canConstructController = false;

const _pending = [];

/** @type {?MpFeed[]} */
let _feeds = null;

/** @type {?Object.<string, MpFeed>} */
let _feedsByName = null;

/** @type {?Object.<string, MpFeed[]>} */
let _feedsBySrc = null;

/** @type {(MpFeedController|ServerError)} */
let _access = null;

let _fldCache = null;
let _rootCache = null;

/* **************************************************
 * Private methods
 * ************************************************** */

/**
 * Returns the cache used for MP fields (lazily initialized).
 * @returns {SmartCache}
 * @private
 */
function _getFieldCache () {
    if (_fldCache === null) {
        _fldCache = new SmartCache("lim.MpApi.GetFeedFields", Dates.MILLIS_PER_HOUR);
    }
    return _fldCache;
}

/**
 * Returns the cache used for MP roots (lazily initialized).
 * @returns {SmartCache}
 * @private
 */
function _getRootCache () {
    if (_rootCache === null) {
        _rootCache = new SmartCache("lim.MpApi.GetFeedRoots", Dates.MILLIS_PER_HOUR);
    }
    return _rootCache;
}

/* **************************************************
 * FeedController (class)
 * ************************************************** */

/**
 * All entitled feeds, grouped in a wrapper that offers convenient methods.
 */
class MpFeedController {
    constructor () {
        if (!_canConstructController) {
            throw new Error("Private constructor, access denied.");
        }
        _canConstructController = false;

        // Now this is my best trick so far: an object without any properties.
        // The "magic" is in that its methods have access to hidden variables
        // (through closure) which gives this object ability to return singleton
        // objects.

        Object.freeze(this);
    }

    /**
     * @returns {string[]} Array of entitled data-source names, sorted.
     */
    dataSources () {
        return Object.keys(_feedsBySrc).sort(Strings.compare);
    }

    /**
     * Returns a list of feeds that are entitled to the user.
     * The list is sorted by feed name and is read-only.  If caller
     * wants to sort differently, it must first copy the array.
     *
     * @param {string} [dataSourceName] Limits the list of feeds to that of the provided `dataSourceName`.
     * @returns {MpFeed[]} List of MP feed objects.
     */
    feeds (dataSourceName) {

        if (arguments.length < 1) {
            return _feeds;
        } else {
            requireNonEmptyString(dataSourceName, "dataSourceName");
            if (!_feedsBySrc.hasOwnProperty(dataSourceName)) {
                throw new Error("dataSourceName does not exist: " + dataSourceName);
            }
            return _feedsBySrc[dataSourceName];
        }
    }

    /**
     * Returns the MpFeed object associated with `feedName`.
     * @param feedName {string} The name of the feed.
     * @param [defaultValue] {*} The value to return if `feedName` is not found.
     * @returns {(MpFeed|*)}
     * @throws {Error} - If `feedName` is not found and `defaultValue` is not provided.
     */
    feed (feedName, defaultValue) {
        let feedNameLC = requireNonEmptyString(feedName, "feedName").toLowerCase();
        if (_feedsByName.hasOwnProperty(feedNameLC)) {
            return _feedsByName[feedNameLC];
        } else if (arguments.length > 1) {
            return defaultValue;
        } else {
            throw new Error("feedName not found: " + feedName);
        }
    }
}

/**
 * @param feeds {MpFeed[]}
 * @returns {Object.<string, MpFeed[]>} Dictionary of feeds per data-source.
 * @private
 */
function _organizeBySource (feeds) {
    let meta = groupBy(feeds, (mpFeed) => mpFeed.dataSource());
    for (let mpFeeds of Object.values(meta)) {
        Object.freeze(mpFeeds);
    }
    return meta;
}

/**
 * Sends a payload to a list of callbacks.
 * @param {function[]} callbacks
 * @param {*} payload
 * @private
 */
function _sendToAll (callbacks, payload) {
    for (let callback of callbacks) {
        callback(payload);
    }
}

/**
 * Callback for list of entitled feeds.
 * @param payload {(Object[]|ServerError|number)}
 * @private
 */
function _feedsCB (payload) {

    const callbacks = Arrays.removeAll(_pending);

    if (payload instanceof ServerError) {
        _access = payload;
        _sendToAll(callbacks, payload);
    } else {
        const isHttpError = isInteger(payload);

        if (   isHttpError
            && payload !== HttpCode.UNAUTHORIZED
            && payload !== HttpCode.FORBIDDEN) {

            // HTTP error not due to password/entitlements

            const error = new ServerError(
                payload,
                [Text.httpError.replace("[code]", payload.toString(10))],
                "lim.MpApi.GetEntitledFeeds"
            );

            _access = error;
            _sendToAll(callbacks, error);
        } else if (isHttpError) {
            // Don't report *unauthorized* or *forbidden*: fail quietly, respond with empty list (aka "no feed").
            _buildCacheAndRespond([], callbacks);
        } else {
            // Received valid payload, cache it and proceed with notifications.
            _buildCacheAndRespond(payload.map(_newMpFeed), callbacks);
        }
    }
}

/**
 * Build the local cache objects and respond to all pending requests.
 * @param {MpFeed[]} list
 * @param {Function[]} callbacks
 * @private
 */
function _buildCacheAndRespond (list, callbacks) {
    _buildCache(list);

    _canConstructController = true;
    _access = new MpFeedController();

    _sendToAll(callbacks, _feeds);
}

/**
 * Builds the private, static indices and lists.
 * @param {MpFeed[]} list
 * @private
 */
function _buildCache (list) {

    list.sort((f1, f2) => Strings.compareIgnoreCase(f1._name, f2._name));
    _feeds = list;
    _feedsByName = _feeds.reduce(function (dict, feed) {
        dict[feed._name.toLowerCase()] = feed;
        return dict;
    }, {});
    _feedsBySrc = _organizeBySource(_feeds);

    Object.freeze(_feeds);
}

/**
 * Retrieves the list of feeds.
 * @param callback
 * @returns {boolean} Whether a call was made to the server (true) or not (false).
 * @private
 */
function _getEntitledList (callback) {

    if (_feeds === null) {
        const isFirst = (_pending.length === 0);
        _pending.push(callback);
        if (isFirst) {
            // Create throwaway API handler, for one-time call.
            const ah = new LimitlessApiHandler(1);
            ah.call("lim.MpApi.GetEntitledFeeds", function () {
                _feedsCB(...arguments);
                Functions.delay(function () {
                    ah.destroy();
                });
            });
        }
        return true;
    } else {
        Functions.delay(function () {
            callback(_feeds);
        });
        return false;
    }
}

/**
 *
 * @param serverObj {{
 *     name: string,
 *     description: string,
 *     dataSource: string,
 *     provider: string,
 *     timeUnits: string,
 *     partialUpdates: boolean,
 *     multiValue: boolean,
 *     privateFeed: boolean,
 *     privateCF: boolean
 * }}
 * @returns {MpFeed} Newly created MpFeed instance.
 * @private
 */
function _newMpFeed (serverObj) {
    _canConstructFeed = true;
    return new MpFeed(serverObj);
}

/**
 * Creates a field-sender function, used to forward
 * a list of fields (of the specified type) to caller.
 *
 * @param {MpFeedFieldsCallback} callback
 * @param {?EnumItem} fieldType
 * @returns {Function} Internal callback.
 * @private
 */
function _newFieldSender (callback, fieldType) {
    return function (payload) {
        let fields = payload;
        if (Arrays.isArrayOf(fields, MpField)) {
            if (fieldType === null){
                fields = Arrays.slice(fields);
            } else {
                fields = fields.filter(mpField => (mpField.type() === fieldType));
            }
        }
        callback(fields);
    };
}

/**
 * Retrieves MP fields from the *feed* API.
 *
 * @param {MpFeed} mpFeed
 * @param {MpFeedFieldsCallback} callback
 * @param {?EnumItem} fieldType
 * @returns {boolean} Whether a call was made to the server (true) or information was found
 *          in cache (false).
 * @private
 */
function _getFields (mpFeed, callback, fieldType) {
    requireMpFeed(mpFeed, "mpFeed");
    requireFunction(callback, "callback");
    if (fieldType !== null) {
        MpFieldType.requireEnumOf(fieldType, "fieldType");
    }

    const sender = _newFieldSender(callback, fieldType);
    let foundInCache = _getFieldCache().get(sender, mpFeed.name(), null);
    return !foundInCache;
}

/**
 * Retrieves MP roots from the *contractroots* API.
 *
 * @param {MpFeed} mpFeed
 * @param {MpFeedRootsCallback} callback
 * @returns {boolean} Whether a call was made to the server (true) or information was found
 *          in cache (false).
 * @private
 */
function _getRoots (mpFeed, callback) {
    requireMpFeed(mpFeed, "mpFeed");
    requireFunction(callback, "callback");
    let foundInCache = _getRootCache().get(callback, mpFeed.name());
    return !foundInCache;
}

/**
 * Updates the cache that contains MP fields.
 * @param {string} feedName
 * @param {Object[]} fields
 * @private
 */
function _addToFieldsCache (feedName, fields) {

    let mpFields = MpField.fromServerArray(fields);
    Object.freeze(mpFields);

    let cache = _getFieldCache().simpleCache();
    cache.set(mpFields, feedName, null);
}

/**
 * A Marketplace feed.
 * @param serverObj {{
 *     name: string,
 *     description: string,
 *     dataSource: string,
 *     provider: string,
 *     timeUnits: string,
 *     partialUpdates: boolean,
 *     multiValue: boolean,
 *     privateFeed: boolean,
 *     privateCF: boolean
 * }}
 * @constructor
 * @name MpFeed
 */
export default class MpFeed {
    constructor (serverObj) {
        if (!_canConstructFeed) {
            throw new Error("IllegalAccess: private constructor, access denied");
        }
        _canConstructFeed = false;

        requireObject(serverObj, "serverObj");

        this._name = requireString(serverObj.name, "serverObj.name");
        this._descr = requireString(serverObj.description, "serverObj.description");
        this._ds = requireString(serverObj.dataSource, "serverObj.dataSource");
        this._prov = requireString(serverObj.provider, "serverObj.provider");
        this._tu = requireString(serverObj.timeUnits, "serverObj.timeUnits");
        this._partUpd = requireBoolean(serverObj.partialUpdates, "serverObj.partialUpdates");
        this._multiVal = requireBoolean(serverObj.multiValue, "serverObj.multiValue");
        this._privateFeed = requireBoolean(serverObj.privateFeed, "serverObj.privateFeed");
        this._privateCF = requireBoolean(serverObj.privateCF, "serverObj.privateCF");

        Object.freeze(this);
    }

    /** @returns {string} Feed name. */
    name () { return this._name; }

    /** @returns {string} Feed description */
    description () { return this._descr; }

    /** @returns {string} Feed's data-source. */
    dataSource () { return this._ds; }

    /** @returns {string} Provider */
    provider () { return this._prov; }

    /** @returns {string} Feed's time-units, as specified by DataDev. */
    timeUnits () { return this._tu; }

    /** @returns {boolean} Whether this feed has *partial updates* enabled by default. */
    isPartialUpdates () { return this._partUpd; }

    /** @returns {boolean} Whether this feed is a multi-value feed. */
    isMultiValue () { return this._multiVal; }

    /** @returns {boolean} Whether this feed is a private feed. */
    isPrivateFeed () { return this._privateFeed; }

    /** @returns {boolean} What does `privateCF` mean? */
    isPrivateCF () { return this._privateCF; }

    /**
     * Retrieves all fields (key, value, data).
     *
     * @param {MpFeedFieldsCallback} callback
     * @returns {boolean} Whether a call was made to the server (true)
     *                    or information was found in cache (false).
     */
    fields (callback) {
        return _getFields(this, callback, null);
    }

    /**
     * Retrieves key fields (aka keys).
     *
     * @param {MpFeedFieldsCallback} callback
     * @returns {boolean} Whether a call was made to the server (true)
     *                    or information was found in cache (false).
     */
    keyFields (callback) {
        return _getFields(this, callback, MpFieldType.KEY);
    }

    /**
     * Retrieves value fields (aka columns).
     *
     * @param {MpFeedFieldsCallback} callback
     * @returns {boolean} Whether a call was made to the server (true)
     *                    or information was found in cache (false).
     */
    valueFields (callback) {
        return _getFields(this, callback, MpFieldType.VALUE);
    }

    /**
     * Retrieves data fields (aka meta-data).
     *
     * @param {MpFeedFieldsCallback} callback
     * @returns {boolean} Whether a call was made to the server (true)
     *                    or information was found in cache (false).
     */
    dataFields (callback) {
        return _getFields(this, callback, MpFieldType.DATA);
    }

    /**
     * Retrieves the roots of this feed, if any.
     * @param {MpFeedRootsCallback} callback
     * @returns {boolean} Whether a call was made to the server (true)
     *                    or information was found in cache (false).
     */
    roots (callback) {
        return _getRoots(this, callback);
    }

    /**
     * Returns whether *that* is a MpFeed object that represents
     * the same feed.
     * @param {*} that
     * @returns {boolean} Returns true if *that* instance represents
     *                    the same feed as this instance.
     */
    equals (that) {
        return (isMpFeed(that) && this._name === that._name);
    }

    /**
     * Ensures the user-entitled feeds are loaded,
     * and executes `callback` when loaded.
     * @param {MpFeedCallback} callback
     * @returns {boolean} Whether a call was made to the server (true)
     *                    or information was found in cache (false).
     */
    static load (callback) {
        requireFunction(callback, "callback");
        return _getEntitledList(function () {
            callback(_access);
        });
    }

    /**
     *
     * @param serverObj {{
     *     name: string,
     *     description: string,
     *     dataSource: string,
     *     provider: string,
     *     timeUnits: string,
     *     partialUpdates: boolean,
     *     multiValue: boolean,
     *     privateFeed: boolean,
     *     privateCF: boolean,
     *     fields: ?(Array.<{fieldName: string, fieldDataType: string, type: string}>)
     * }}
     * @returns {MpFeed} MP feed object.
     */
    static addToCache (serverObj) {
        let mpFeed = _newMpFeed(serverObj);

        Console.log("Adding feed to local cache: [{}]", mpFeed._name);

        let list = _feeds.slice(0);  // mutable copy
        list.push(mpFeed);

        _buildCache(list);
        if (Array.isArray(serverObj.fields)) {
            _addToFieldsCache(mpFeed.name(), serverObj.fields);
        }
        return mpFeed;
    }
}

/**
 * @param {(MpFeed|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is a MpFeed instance.
 */
export function isMpFeed (arg) {
    return (arg instanceof MpFeed);
}

/**
 * Validates `arg` to be a MpFeed object, throws otherwise.
 * @param {MpFeed} arg Argument to validate.
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {MpFeed} Always returns `arg`.
 * @throws {TypeError} If `arg` is not a MpFeed object.
 */
export function requireMpFeed (arg, argName) {
    if (!isMpFeed(arg)) {
        throw new TypeError(argName + ": MpFeed");
    }
    return arg;
}

<template>
  <div>
    <div
      v-if="dialogType !== '' || overlay"
      id="overlayDiv"
      class="overlay"
    />
    <template v-if="pageStatus == 'SUCCESS'">
      <div class="mds-header border-bottom-solid-details">
        <h1 class="mds-header__title component-title">
          <small v-if="friendlyFeed">{{ feedName | friendlyFeed }}</small>
          <small v-else>{{ feedName }}</small>
        </h1>
        <h1
          v-if="coreFeed"
          class="mds-header__title component-title margin-left-10"
        >
          <small>&nbsp; ({{ $t('labels.feed.coreFeed') }})</small>
        </h1>
        <div class="mds-header__actions">
          <button
            v-if="userAdminRoles.length > 0"
            id="userAdminRole"
            class="mds-button mds-button--flat"
            type="button"
            @click="displayDialogBox('deleteBox')"
          >
            <svg
              class="mds-icon mds-button__icon mds-button__icon--left"
              aria-hidden="true"
            >
              <use xlink:href="#trash" />
            </svg>
            <span class="mds-button__text mcd-page__content-hide--600">
              {{ $t('labels.common.delete') }}
            </span>
          </button>
          <router-link
            :to="{ path: '/feeds' }"
            class="mds-list-group__link hide-actions"
            replace
          >
            <button
              class="mds-button mds-button--flat"
              type="button"
            >
              <svg
                class="mds-icon mds-button__icon mds-button__icon--left"
                aria-hidden="true"
              >
                <use xlink:href="#list" />
              </svg>
              <span class="mds-button__text mcd-page__content-hide--600">{{
                $t('labels.feed.feedList')
              }}</span>
            </button>
          </router-link>
          <template v-if="!adminUser">
            <router-link
              :to="{ path: '/feeds/clone' }"
              class="mds-list-group__link hide-actions"
              replace
            >
              <button
                class="mds-button mds-button--flat"
                type="button"
              >
                <svg
                  class="mds-icon mds-button__icon mds-button__icon--left"
                  aria-hidden="true"
                >
                  <use xlink:href="#open-new" />
                </svg>
                <span class="mds-button__text mcd-page__content-hide--600">{{
                  $t('labels.feed.cloneFeed')
                }}</span>
              </button>
            </router-link>
          </template>
        </div>
      </div>

      <div>
        <mds-button-group
          v-if="adminUser"
          class="hide-actions margin-top-20"
        >
          <mds-button
            icon="document--s"
            variation="secondary"
            type="radio"
            name="with-icons-mdswc"
            value="entitlement"
            checked
            @click="editEntitlements"
          >
            {{ $t('labels.feed.editEntitlements') }}
          </mds-button>
          <mds-button
            icon="open-new--s"
            variation="secondary"
            type="radio"
            name="with-icons-mdswc"
            value="clone"
            @click="goToCloneFeed"
          >
            {{ $t('labels.feed.cloneFeed') }}
          </mds-button>
          <mds-button
            icon="person-group--s"
            variation="secondary"
            type="radio"
            name="with-icons-mdswc"
            value="groups"
            @click="goToGroupPage"
          >
            {{ $t('labels.feed.editGroups') }}
          </mds-button>
        </mds-button-group>
      </div>
      <div class="feed-description">
        <h2 class="custom-header alignment font-size-larger">
          {{ $t('labels.common.description') }}
        </h2>
        <p class="para-margin-top">
          {{ feedDetails.description }}
        </p>
      </div>

      <div class="header-bottom">
        <h2 class="custom-header alignment">
          {{ $t('labels.feed.feedDefinition') }}
        </h2>
      </div>

      <mds-layout-grid>
        <mds-row>
          <mds-col
            :cols="12"
            :cols-at-s="6"
          >
            <h3 class="mds-header__title custom-header columns-header">
              {{ $t('labels.feed.keyColumn') }}
            </h3>
            <ul class="mds-list-group">
              <li
                v-for="(keyColumn, index) in keyColumns"
                :key="index"
                class="mds-list-group__item component-title"
              >
                <a class="mds-list-group__link">
                  <span class="mds-list-group__item-text">
                    {{ keyColumn.fieldName }}({{ keyColumn.fieldDataType }})
                  </span>
                </a>
              </li>
            </ul>
          </mds-col>
          <mds-col
            :cols="12"
            :cols-at-s="6"
            class="custom-padding-at-600"
          >
            <h3 class="mds-header__title custom-header columns-header">
              {{ $t('labels.feed.valueColumn') }}
            </h3>
            <ul class="mds-list-group">
              <li
                v-for="(valueColumn, index) in valueColumns"
                :key="index"
                class="mds-list-group__item component-title"
              >
                <a class="mds-list-group__link">
                  <span class="mds-list-group__item-text">
                    {{ valueColumn.fieldName }}({{ valueColumn.fieldDataType }})
                  </span>
                </a>
              </li>
            </ul>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
      <div>
        <div class="mds-accordion">
          <input
            id="entitlementsAccordion"
            type="checkbox"
            role="button"
            aria-controls="entAccordionData"
            class="mds-accordion__trigger"
            @click="getEntitlementsForFeed"
          >
          <label
            for="entitlementsAccordion"
            class="mds-accordion__label"
          >
            <div class="mds-header mds-header--secondary mds-header--level-3">
              <h2 class="mds-header__title ent-header">{{ $t('labels.feed.entitlements') }}</h2>
            </div>
          </label>
          <section
            id="entAccordionData"
            class="mds-accordion__content"
          >
            <template v-if="loader == true">
              <div>
                <mds-loader />
              </div>
            </template>
            <template v-else>
              <template v-if="entitlementFlag">
                <mds-data-table
                  row-hover
                  size="large"
                  :header-configs="[{
                                      fieldName: 'name',
                                      text: 'Name',
                                      width: '70%'
                                    },
                                    {
                                      fieldName: 'permission',
                                      text: 'Permission',
                                      width: '30%',
                                      align: 'right',
                                    }]"
                  :row-data="getUserObjects()"
                />
              </template>

              <template v-else-if="!entitlementFlag">
                <mds-field-error
                  id="error-id"
                  size="large"
                  visible
                >
                  {{ $t('labels.feed.noEntitlements') }}
                </mds-field-error>
              </template>
            </template>
          </section>
        </div>

        <div class="mds-accordion">
          <input
            id="feedInfoAccordion"
            type="checkbox"
            role="button"
            aria-controls="feedInfoAccordionData"
            class="mds-accordion__trigger"
          >
          <label
            for="feedInfoAccordion"
            class="mds-accordion__label"
          >
            <div class="mds-header mds-header--secondary mds-header--level-3">
              <h2 class="mds-header__title ent-header">{{ $t('labels.feed.information') }}</h2>
            </div>
          </label>
          <section
            id="feedInfoAccordionData"
            class="mds-accordion__content"
          >
            <mds-data-table
              row-hover
              size="large"
              :header-configs="[{
                                  fieldName: 'fieldName',
                                  text: '',
                                  width: '70%'
                                },
                                {
                                  fieldName: 'fieldValue',
                                  text: '',
                                  width: '30%',
                                  align: 'right',
                                }]"
              :row-data="getFeedDetailsObjects(feedDetails)"
            >
              <template #header>
                <span class="group-hide-header" />
              </template>
            </mds-data-table>
          </section>
        </div>
      </div>

      <mds-alert
        v-if="dialogType == 'deleteBox'"
        title="Delete Feed"
        role="alertdialog"
        variation="warning"
        class="div-centered"
        dismissible="false"
      >
        Are you sure you want to delete <strong>{{ feedName }}</strong>?<br><br>
        <button
          id="deleteCancel"
          class="mds-button mds-button--small mds-button--primary"
          type="button"
          @click="displayDialogBox('')"
        >
          Cancel
        </button>&nbsp;
        <button
          id="deleteConfirm"
          class="mds-button mds-button--small mds-button--primary"
          type="button"
          @click="retiredFeed()"
        >
          Yes, Proceed
        </button>
      </mds-alert>

      <mds-alert
        v-show="dialogType == 'successBox'"
        role="alertdialog"
        variation="success"
        class="div-centered"
        dismissible="false"
      >
        <strong>{{ feedName }}</strong> {{ $t('labels.feed.feedDeleted') }}.
        <mds-button
          id="feedDetailsSuccessButton"
          slot="mds-alert-custom-action"
          size="small"
          variation="primary"
          @click="
            () => {
              $router.push('/feeds');
            }
          "
        >
          {{ $t('labels.common.okay') }}
        </mds-button>
      </mds-alert>
      <mds-alert
        v-show="dialogType == 'errorBox'"
        title="Server Error"
        variation="error"
        dismissible="false"
        class="div-centered"
      >
        {{ $t('labels.common.serverError') }} <strong>{{ feedName }}</strong>
        {{ $t('labels.feed.feedNotDeleted') }}.
        <mds-button
          id="feedDetailsErrorButton"
          slot="mds-alert-custom-action"
          size="small"
          variation="primary"
          @click="displayDialogBox('')"
        >
          {{ $t('labels.common.okay') }}
        </mds-button>
      </mds-alert>
      <mds-alert
        v-show="dialogType == 'entitlementErrorBox'"
        title="Feed Entitlement Error"
        variation="error"
        dismissible="false"
        class="div-centered"
      >
        <strong>{{ feedName }}</strong> {{ $t('labels.error.feedEntitled') }}
        <mds-button
          id="feedGroupErrorButton"
          slot="mds-alert-custom-action"
          size="small"
          variation="primary"
          @click="displayDialogBox('')"
        >
          {{ $t('labels.common.okay') }}
        </mds-button>
      </mds-alert>
      <mds-alert
        v-show="dialogType == 'permissionErrorBox'"
        title="Feed Delete Permission Error"
        variation="error"
        dismissible="false"
        class="div-centered"
      >
        <strong>{{ feedName }}</strong> {{ $t('labels.error.permissionError') }}
        <mds-button
          id="feedPermissionErrorButton"
          slot="mds-alert-custom-action"
          size="small"
          variation="primary"
          @click="displayDialogBox('')"
        >
          {{ $t('labels.common.okay') }}
        </mds-button>
      </mds-alert>
      <transition name="slide">
        <template v-if="showFeedEnt">
          <FeedEntitlements />
        </template>
      </transition>
    </template>
    <template v-else-if="pageStatus == 'ERROR'">
      <div class="details-error">
        <mds-notification
          visible
          title="ERROR"
          status="error"
          dismissible="false"
        >
          {{ errorMessage }}
        </mds-notification>
      </div>
    </template>
    <template v-else-if="pageStatus == 'LOADING'">
      <mds-loader />
    </template>
    <div
      id="mds-notification"
      class="mds-notification mds-notification--top-center mds-notification--below-masthead mds-notification--active custom-top"
    >
      <mds-notification-group>
        <mds-notification
          ref="errorDialog"
          role="alertdialog"
          status="error"
          size="small"
          dismiss-delay="10000"
          dismissible="true"
          removable="false"
          class="z-index-9999"
          title="Important Failure"
        >
          {{ $t('labels.common.internalServerError') }}
        </mds-notification>

        <mds-notification
          ref="warningDialog"
          role="alertdialog"
          status="warning"
          size="small"
          dismiss-delay="10000"
          dismissible="true"
          removable="false"
          class="z-index-9999"
          title="Permission Issue"
        >
          {{ $t('labels.common.permissionError') }}
        </mds-notification>
      </mds-notification-group>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { MdsDataTable } from '@mds/data-table-v3';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import FeedEntitlements from './FeedEntitlements.vue';
import EventBus from '../../main';
import { getUserRoles } from '../../utils/authService';

export default {
  name: 'FeedDetails',
  components: {
    FeedEntitlements, MdsDataTable, MdsLayoutGrid, MdsRow, MdsCol,
  },
  data() {
    return {
      feedName: '',
      dialogType: '',
      loader: '',
      noEntitlements: 'No Entitlements Found.',
      itemsExcluded: new Map(),
      entitlementFlag: false,
      showFeedEnt: false,
      overlay: false,
      adminUser: false,
      readUsers: [],
      writeUsers: [],
      siteLicense: false,
      friendlyFeed: false,
    };
  },

  computed: {
    ...mapGetters({
      keyColumns: 'feedModule/getKeyColumns',
      valueColumns: 'feedModule/getValueColumns',
      pageStatus: 'feedModule/getPageStatus',
      feedDetails: 'feedModule/getFeedDetails',
      userAdminRoles: 'feedModule/getUserAdminRoles',
      errorMessage: 'feedModule/getErrorMessage',
      feedEntitlements: 'feedModule/getFeedEntitlements',
      coreFeed: 'feedModule/getCoreFeed',
    }),
    entitledUsers() {
      let users = Object.assign([], []);
      if (this.feedEntitlements.readers && this.feedEntitlements.writers) {
        this.feedEntitlements.readers
          .filter(ent => ent.numsubs !== '-1')
          .map(ent => ent.users.map((user) => {
            users.push(user.login);
            return user;
          }));
        this.feedEntitlements.writers
          .map((ent) => {
            if (!users.includes(ent.username)) {
              users.push(ent.username);
            }
            return ent;
          });
      }
      if (this.siteLicense) {
        users = users.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        users.splice(0, 0, 'Site License (All Users)');
        return users;
      }
      return users.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    },
  },
  created() {
    this.setCompanyName();
    this.$store.state.feedModule.feedEntitlements = {};
    this.valuesToBeIgnored();
    this.feedName = this.$route.params.feedName;
    this.getFeedDetailsByName(this.feedName);
    this.getUserRoles();
    this.checkCoreFeed(this.feedName);
    EventBus.$on('hideOverLay', (value) => {
      this.overlay = value;
    });
    EventBus.$on('hideFeedEntitlements', (value) => {
      this.showFeedEnt = value;
    });

    EventBus.$on('showErrorNotification', (value) => {
      if (this.$refs.errorDialog) {
        this.$refs.errorDialog.open();
      }
      this.hideEntitlements();
    });

    EventBus.$on('showWarningNotification', (value) => {
      if (this.$refs.warningDialog) {
        this.$refs.warningDialog.open();
      }
      this.hideEntitlements();
    });

    if (
      getUserRoles().includes('ROLE_LDS_ADMIN')
      || getUserRoles().includes('ROLE_LDS_SUPPORT_ADMIN')
      || getUserRoles().includes('ROLE_LDS_CO_ADMIN')
    ) {
      this.adminUser = true;
    }
  },
  methods: {
    ...mapActions('feedModule', [
      'getFeedDetailsByName',
      'getEntitlements',
      'deleteFeed',
      'getUserRoles',
      'setCompanyName',
      'checkCoreFeed',
    ]),
    getPermissions(userName) {
      if (this.readUsers.includes(userName) && this.writeUsers.includes(userName)) {
        return 'Read | Write';
      }
      if (!this.readUsers.includes(userName) && this.writeUsers.includes(userName)) {
        return 'Write';
      }
      return 'Read';
    },
    getUserObjects() {
      let userList = [];
      if (this.feedEntitlements.groupEnt) {
        userList = this.entitledUsers.concat(this.feedEntitlements.groupEnt.map(ent => `<strong>${ ent }</strong>`));
      } else {
        userList = this.entitledUsers;
      }
      const userObjs = [];
      userList.forEach((o, i) => userObjs.push({ name: o, permission: this.getPermissions(o), id: i++ }));
      return userObjs;
    },
    getFeedDetailsObjects(feedDetails) {
      const feedDetailObjs = [];
      let i = 0;
      /* eslint-disable */
        // Need to decide if we are going to allow nested ternary or not
      for (const [key, value] of Object.entries(feedDetails)) {
        if (this.itemsExcluded.get(key) === undefined) {
          feedDetailObjs.push({
            fieldName: this.getKeyLabel(key),
            fieldValue: value === false
              ? 'No' : value === true ? 'Yes' : value,
            id: i++,
          });
        }
      }
        /* eslint-disable */
      return feedDetailObjs;
    },
    getEntitlementsForFeed() {
      if (this.feedEntitlements && this.entitlementFlag) {
        return;
      }

      this.loader = true;
      this.getEntitlements(this.feedName).then((response) => {
        if (response.status === 200) {
          if (response.data !== null && (response.data.groupEnt.length !== 0 || response.data.readers.length !== 0
            || response.data.writers.length !== 0)) {
            this.entitlementFlag = true;
          }
        }
        this.loader = false;
        this.updateReadWriteUserList();
      });
    },

    getKeyLabel(key) {
      return this.$t(`labels.feed.${key}`);
    },
    retiredFeed() {
      this.deleteFeed(this.feedName)
        .then((response) => {
          if (response.status === 200) {
            this.dialogType = 'successBox';
          } else if (response.status === 403) {
            this.dialogType = 'permissionErrorBox';
          } else if (response.data.includes('javax.persistence.OptimisticLockException')) {
            this.dialogType = 'entitlementErrorBox';
          }
        })
        .catch((error) => {
          this.dialogType = 'errorBox';
        });
    },
    valuesToBeIgnored() {
      this.itemsExcluded.set('fields', 'fields');
      this.itemsExcluded.set('multiValue', 'multiValue');
      this.itemsExcluded.set('partialUpdates', 'partialUpdates');
      this.itemsExcluded.set('privateCF', 'privateCF');
      this.itemsExcluded.set('privateSrc', 'privateSrc');
    },
    displayDialogBox(dialogValue) {
      this.dialogType = dialogValue;
    },
    editEntitlements() {
      this.showFeedEnt = true;
      this.overlay = true;
    },
    goToCloneFeed() {
      this.$router.push({
        path: '/feeds/clone',
      });
    },
    goToGroupPage() {
      this.$router.push({
        path: `/feeds/groups/${this.feedName}`,
        params: {
          feedName: this.feedName,
        },
      });
    },
    hideEntitlements() {
      EventBus.$emit('hideOverLay', false);
      EventBus.$emit('hideFeedEntitlements', false);
    },
    updateReadWriteUserList() {
      this.readUsers = Object.assign([], []);
      this.writeUsers = Object.assign([], []);
      if (this.feedEntitlements.readers && this.feedEntitlements.writers) {
        this.feedEntitlements.readers
          .filter(ent => ent.numsubs !== '-1')
          .map(ent => ent.users.map((user) => {
            this.readUsers.push(user.login);
            return user;
          }));
        this.feedEntitlements.writers
          .map((ent) => {
            this.writeUsers.push(ent.username);
            return ent;
          });
        this.feedEntitlements.readers
          .filter(ent => ent.numsubs === '-1')
          .map((ent) => {
            if (ent.users.length === 0) {
              this.siteLicense = true;
            }
            return ent;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/feed_details';
@import "src/assets/styles/components/custom/transition";
.group-hide-header{
  display: block;
  visibility: hidden;
}

</style>

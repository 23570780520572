const translations = {
  ar: {
    userNameText: 'اسم المستخدم',
    emailText: 'البريد الالكتروني',
    emailErrorText: 'الرجاء إدخال البريد الإلكتروني الصحيح',
    usernameErrorText: 'الرجاء ادخال اسم المستخدم',
    passwordText: 'كلمة المرور ',
    loginText: 'دخول',
    rememberMeText: 'احتفظ  بدخولي',
    showPasswordText: 'اظهر كلمة المرور',
    forgotPwdText: 'نسيت كلمة المرور؟',
    minLengthError: 'يجب أن تكون كلمة المرور 8 أحرف على الأقل.',
    maxLengthError: 'يجب ألا تكون كلمة المرور أكثر من 160 حرفًا.',
    oneDigit: 'يجب أن تحتوي كلمة المرور على رقم واحد على الأقل.',
    upCaseLetter: 'يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل.',
    repeatCharacter: 'يجب ألا تحتوي كلمة المرور على 4 أحرف أو أكثر.',
    specialCharacter: 'يجب أن تحتوي كلمة المرور على شخصية خاصة واحدة على الأقل.',
    consecutiveCharacter: 'يجب ألا تحتوي كلمة المرور على 4 أحرف أو أكثر.',
    pwdStrength: 'الطول> 7 ويحتوي على 3 مجموعات من 4 أحرف (الأحرف الكبيرة والصغيرة والأرقام والرموز).',
  },
  en: {
    userNameText: 'User Name',
    emailText: 'Email',
    emailErrorText: 'Email must be formatted abc@xyz.com',
    usernameErrorText: 'Please enter your user name.',
    passwordText: 'Password',
    loginText: 'Sign In',
    rememberMeText: 'Keep me signed in',
    showPasswordText: 'Show/Hide password',
    forgotPwdText: 'Forgot your password?',
    minLengthError: 'Password must be at least 8 characters.',
    maxLengthError: 'Password must not be greather than 160 characters.',
    oneDigit: 'Password must contain at least one digit.',
    upCaseLetter: 'Password must contain at least one uppercase letter.',
    repeatCharacter: 'Password must not contain 4 or more repeating characters.',
    specialCharacter: 'Password must contain at least one special character.',
    consecutiveCharacter: 'Password must not contain 4 or more consecutive characters.',
    pwdStrength: 'Length > 7 and contains 3 of the 4 character sets (upper and lower case letters, numbers and symbols).',
  },
  chi: {
    userNameText: '用户名',
    emailText: '电子邮件',
    emailErrorText: '请输入正确的电子邮件',
    usernameErrorText: '请输入您的用户名',
    passwordText: '密码',
    loginText: '登录',
    rememberMeText: '保持登录状态',
    showPasswordText: '显示/隐藏 密码',
    forgotPwdText: '显示/隐藏 密码',
    minLengthError: '密码必须至少8个字符.',
    maxLengthError: '密码不能超过160个字符.',
    oneDigit: '密码必须至少包含一位数字.',
    upCaseLetter: '密码必须至少包含一个大写字母.',
    repeatCharacter: '密码不能包含4个或更多的重复字符.',
    specialCharacter: '密码必须至少包含一个特殊字符.',
    consecutiveCharacter: '密码不得包含4个或更多的连续字符.',
    pwdStrength: '长度> 7，包含4个字符集中的3个（大写和小写字母，数字和符号.',
  },
  fr: {
    userNameText: "Nom d'utilisateur",
    emailText: 'Email',
    emailErrorText: 'Veuillez saisir le bon e-mail',
    usernameErrorText: " S'il vous plaît entrez votre nom d'utilisateur",
    passwordText: 'Mot de passe',
    loginText: 'se connecter',
    rememberMeText: 'Me garder connecté',
    showPasswordText: 'Afficher / masquer le mot de passe',
    forgotPwdText: 'masquer le mot de passe',
    minLengthError: "Mot de passe doit être d'au moins 8 caractères.",
    maxLengthError: 'Le mot de passe ne doit pas dépasser 160 caractères.',
    oneDigit: 'Le mot de passe doit contenir au moins un chiffre.',
    upCaseLetter: 'Le mot de passe doit contenir au moins une lettre majuscule.',
    repeatCharacter: 'Le mot de passe ne doit pas contenir 4 caractères répétitifs ou plus.',
    specialCharacter: 'Le mot de passe doit contenir au moins un caractère spécial.',
    consecutiveCharacter: 'Le mot de passe ne doit pas contenir 4 caractères consécutifs ou plus.',
    pwdStrength: 'Longueur> 7 et contient 3 des 4 jeux de caractères (lettres majuscules et minuscules, chiffres et symboles).',
  },
};

export default translations;

<template>
  <div>
    <div>
      <h2 class="custom-header-ent border-bottom-solid break-word">
        <div v-if="isFeedGroup">
          {{ $t('labels.feed.user') }}
        </div>
        <div v-else>
          {{ $t('labels.feed.user/groups') }}
        </div>
      </h2>
    </div>
    <div
      class="mds-search-field "
      role="search"
    >
      <input
        id="feedEntitlementSearchField"
        v-model="searchFilter"
        type="text"
        class="mds-search-field__input"
        aria-label="Search"
        :placeholder="isFeedGroup ? $t('labels.feed.userSearch') : $t('labels.feed.userListSearch')"
      >
      <svg
        class="mds-icon mds-search-field__search-icon"
        aria-hidden="true"
      >
        <use xlink:href="#search--s" />
      </svg>
      <span class="mds-search-field__input-focus-outline" />
    </div>
    <div class="over-flow break-word">
      <ul class="mds-list-group">
        <li
          v-for="(site, siteIndex) in activeSiteList"
          :key="'site' + siteIndex"
          class="mds-list-group__item"
        >
          <a
            class="mds-list-group__link"
            @click="addEntitlements(site, 'site')"
          >
            <span class="mds-list-group__item-text custom-width-ent">
              <strong>  {{ site }} </strong>
            </span>
            <span
              class="pull-right"
            ><span class="ic-add">
              <mds-icon
                name="plus--s"
                title="Add"
              />
            </span>
            </span>
          </a>
        </li>
        <li
          v-for="(user, userIndex) in activeUserList"
          :key="userIndex"
          class="mds-list-group__item"
        >
          <a
            class="mds-list-group__link"
            @click="addEntitlements(user, 'user')"
          >
            <span class="mds-list-group__item-text custom-width-ent">
              {{ user }}
            </span>
            <span
              class="pull-right"
            ><span class="ic-add">
              <mds-icon
                name="plus--s"
                title="Add"
              />
            </span>
            </span>
          </a>
        </li>
        <li
          v-for="(group, groupIndex) in activeGroupList"
          :key="groupIndex + 'g'"
          class="mds-list-group__item "
        >
          <a
            class="mds-list-group__link"
            @click="addEntitlements(group, 'group')"
          >
            <span class="mds-list-group__item-text custom-width-ent">
              <strong> {{ group }} </strong>
            </span>
            <span
              class="pull-right"
            ><span class="ic-add">
              <mds-icon
                name="plus--s"
                title="Add"
              />
            </span>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import EventBus from '../../main';

export default {
  name: 'UserList',
  props: {
    userList: {
      type: Array,
      default: () => [],
    },
    groupList: {
      type: Array,
      default: () => [],
    },
    siteList: {
      type: Array,
      default: () => [],
    },
    isFeedGroup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchFilter: '',
    };
  },
  computed: {
    activeUserList() {
      return this.searchFilter === ''
        ? this.userList
        : this.userList.filter(
          item => item.toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1,
        );
    },
    activeGroupList() {
      return this.searchFilter === ''
        ? this.groupList
        : this.groupList.filter(
          item => item.toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1,
        );
    },
    activeSiteList() {
      return this.siteList;
    },
  },
  methods: {
    addEntitlements(value, type) {
      EventBus.$emit('addEntitlements', value, type);
    },
  },
};
</script>

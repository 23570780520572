export const countryOptions = [
  {
    text: 'Afghanistan',
    value: 'Afghanistan',
    continent: 'Asia',
  }, {
    text: 'Albania',
    value: 'Albania',
    continent: 'Europe',
  }, {
    text: 'Algeria',
    value: 'Algeria',
    continent: 'Africa',
  }, {
    text: 'Andorra',
    value: 'Andorra',
    continent: 'Europe',
  }, {
    text: 'Angola',
    value: 'Angola',
    continent: 'Africa',
  }, {
    text: 'Antarctica',
    value: 'Antarctica',
    continent: 'Antarctica',
  }, {
    text: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
    continent: 'North America',
  }, {
    text: 'Argentina',
    value: 'Argentina',
    continent: 'South America',
  }, {
    text: 'Armenia',
    value: 'Armenia',
    continent: 'Asia',
  }, {
    text: 'Aruba',
    value: 'Aruba',
    continent: 'North America',
  }, {
    text: 'Australia',
    value: 'Australia',
    continent: 'Oceania',
  }, {
    text: 'Austria',
    value: 'Austria',
    continent: 'Europe',
  }, {
    text: 'Azerbaijan',
    value: 'Azerbaijan',
    continent: 'Asia',
  }, {
    text: 'Bahamas',
    value: 'Bahamas',
    continent: 'North America',
  },
  {
    text: 'Bahrain',
    value: 'Bahrain',
    continent: 'Asia',
  },
  {
    text: 'Bangladesh',
    value: 'Bangladesh',
    continent: 'Asia',
  },
  {
    text: 'Barbados',
    value: 'Barbados',
    continent: 'North America',
  },
  {
    text: 'Belarus',
    value: 'Belarus',
    continent: 'Europe',
  },
  {
    text: 'Belgium',
    value: 'Belgium',
    continent: 'Europe',
  },
  {
    text: 'Belize',
    value: 'Belize',
    continent: 'North America',
  },
  {
    text: 'Benin',
    value: 'Benin',
    continent: 'Africa',
  },
  {
    text: 'Bermuda',
    value: 'Bermuda',
    continent: 'North America',
  }, {
    text: 'Bhutan',
    value: 'Bhutan',
    continent: 'Asia',
  }, {
    text: 'Bolivia',
    value: 'Bolivia',
    continent: 'South America',
  }, {
    text: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
    continent: 'Europe',
  }, {
    text: 'Botswana',
    value: 'Botswana',
    continent: 'Africa',
  }, {
    text: 'Brazil',
    value: 'Brazil',
    continent: 'South America',
  }, {
    text: 'Brunei',
    value: 'Brunei',
    continent: 'Asia',
  }, {
    text: 'Bulgaria',
    value: 'Bulgaria',
    continent: 'Europe',
  }, {
    text: 'Burkina Faso',
    value: 'Burkina Faso',
    continent: 'Africa',
  }, {
    text: 'Burundi',
    value: 'Burundi',
    continent: 'Africa',
  }, {
    text: 'Cabo Verde',
    value: 'Cabo Verde',
    continent: 'Africa',
  }, {
    text: 'Cambodia',
    value: 'Cambodia',
    continent: 'Asia',
  }, {
    text: 'Cameroon',
    value: 'Cameroon',
    continent: 'Africa',
  }, {
    text: 'Canada',
    value: 'Canada',
    continent: 'North America',
  }, {
    text: 'Central African Republic',
    value: 'Central African Republic',
    continent: 'Africa',
  }, {
    text: 'Chad',
    value: 'Chad',
    continent: 'Africa',
  }, {
    text: 'Chile',
    value: 'Chile',
    continent: 'South America',
  }, {
    text: 'China',
    value: 'China',
    continent: 'Asia',
  }, {
    text: 'Colombia',
    value: 'Colombia',
    continent: 'South America',
  }, {
    text: 'Comoros',
    value: 'Comoros',
    continent: 'Africa',
  }, {
    text: 'Congo',
    value: 'Congo',
    continent: 'Africa',
  }, {
    text: 'Cook Islands',
    value: 'Cook Islands',
    continent: 'Oceania',
  }, {
    text: 'Costa Rica',
    value: 'Costa Rica',
    continent: 'North America',
  }, {
    text: 'Côte d’Ivoire',
    value: 'Côte d’Ivoire',
    continent: 'Africa',
  }, {
    text: 'Croatia',
    value: 'Croatia',
    continent: 'Europe',
  }, {
    text: 'Cuba',
    value: 'Cuba',
    continent: 'North America',
  }, {
    text: 'Curaçao',
    value: 'Curaçao',
    continent: 'North America',
  }, {
    text: 'Cyprus',
    value: 'Cyprus',
    continent: 'Asia',
  }, {
    text: 'Czechia',
    value: 'Czechia',
    continent: 'Europe',
  }, {
    text: 'Democratic Republic of the Congo',
    value: 'Democratic Republic of the Congo',
    continent: 'Africa',
  }, {
    text: 'Denmark',
    value: 'Denmark',
    continent: 'Europe',
  }, {
    text: 'Djibouti',
    value: 'Djibouti',
    continent: 'Africa',
  }, {
    text: 'Dominica',
    value: 'Dominica',
    continent: 'North America',
  }, {
    text: 'Dominican Republic',
    value: 'Dominican Republic',
    continent: 'North America',
  }, {
    text: 'Ecuador',
    value: 'Ecuador',
    continent: 'South America',
  }, {
    text: 'Egypt',
    value: 'Egypt',
    continent: 'Africa',
  }, {
    text: 'El Salvador',
    value: 'El Salvador',
    continent: 'North America',
  }, {
    text: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
    continent: 'Africa',
  }, {
    text: 'Eritrea',
    value: 'Eritrea',
    continent: 'Africa',
  }, {
    text: 'Estonia',
    value: 'Estonia',
    continent: 'Europe',
  }, {
    text: 'Eswatini',
    value: 'Eswatini',
    continent: 'Africa',
  }, {
    text: 'Ethiopia',
    value: 'Ethiopia',
    continent: 'Africa',
  }, {
    text: 'Federated States of Micronesia',
    value: 'Federated States of Micronesia',
    continent: 'Oceania',
  }, {
    text: 'Fiji',
    value: 'Fiji',
    continent: 'Oceania',
  }, {
    text: 'Finland',
    value: 'Finland',
    continent: 'Europe',
  }, {
    text: 'France',
    value: 'France',
    continent: 'Europe',
  }, {
    text: 'French Guiana',
    value: 'French Guiana',
    continent: 'South America',
  }, {
    text: 'French Polynesia',
    value: 'French Polynesia',
    continent: 'Oceania',
  }, {
    text: 'French Southern Territories',
    value: 'French Southern Territories',
    continent: 'Africa',
  }, {
    text: 'Gabon',
    value: 'Gabon',
    continent: 'Africa',
  }, {
    text: 'Gambia',
    value: 'Gambia',
    continent: 'Africa',
  }, {
    text: 'Georgia',
    value: 'Georgia',
    continent: 'Asia',
  }, {
    text: 'Germany',
    value: 'Germany',
    continent: 'Europe',
  }, {
    text: 'Ghana',
    value: 'Ghana',
    continent: 'Africa',
  }, {
    text: 'Gibraltar',
    value: 'Gibraltar',
    continent: 'Europe',
  }, {
    text: 'Greece',
    value: 'Greece',
    continent: 'Europe',
  }, {
    text: 'Greenland',
    value: 'Greenland',
    continent: 'North America',
  }, {
    text: 'Grenada',
    value: 'Grenada',
    continent: 'North America',
  }, {
    text: 'Guadeloupe',
    value: 'Guadeloupe',
    continent: 'North America',
  }, {
    text: 'Guam',
    value: 'Guam',
    continent: 'Oceania',
  }, {
    text: 'Guatemala',
    value: 'Guatemala',
    continent: 'North America',
  }, {
    text: 'Guinea',
    value: 'Guinea',
    continent: 'Africa',
  }, {
    text: 'Guinea-Bissau',
    value: 'Guinea-Bissau',
    continent: 'Africa',
  }, {
    text: 'Guyana',
    value: 'Guyana',
    continent: 'South America',
  }, {
    text: 'Haiti',
    value: 'Haiti',
    continent: 'North America',
  }, {
    text: 'Honduras',
    value: 'Honduras',
    continent: 'North America',
  }, {
    text: 'Hungary',
    value: 'Hungary',
    continent: 'Europe',
  }, {
    text: 'Iceland',
    value: 'Iceland',
    continent: 'Europe',
  }, {
    text: 'India',
    value: 'India',
    continent: 'Asia',
  }, {
    text: 'Indonesia',
    value: 'Indonesia',
    continent: 'Asia',
  }, {
    text: 'Iran',
    value: 'Iran',
    continent: 'Asia',
  }, {
    text: 'Iraq',
    value: 'Iraq',
    continent: 'Asia',
  }, {
    text: 'Ireland',
    value: 'Ireland',
    continent: 'Europe',
  }, {
    text: 'Israel',
    value: 'Israel',
    continent: 'Asia',
  }, {
    text: 'Italy',
    value: 'Italy',
    continent: 'Europe',
  }, {
    text: 'Jamaica',
    value: 'Jamaica',
    continent: 'North America',
  }, {
    text: 'Japan',
    value: 'Japan',
    continent: 'Asia',
  }, {
    text: 'Jordan',
    value: 'Jordan',
    continent: 'Asia',
  }, {
    text: 'Kazakhstan',
    value: 'Kazakhstan',
    continent: 'Asia',
  }, {
    text: 'Kenya',
    value: 'Kenya',
    continent: 'Africa',
  }, {
    text: 'Kiribati',
    value: 'Kiribati',
    continent: 'Oceania',
  }, {
    text: 'Kuwait',
    value: 'Kuwait',
    continent: 'Asia',
  }, {
    text: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
    continent: 'Asia',
  }, {
    text: 'Laos',
    value: 'Laos',
    continent: 'Asia',
  }, {
    text: 'Latvia',
    value: 'Latvia',
    continent: 'Europe',
  }, {
    text: 'Lebanon',
    value: 'Lebanon',
    continent: 'Asia',
  }, {
    text: 'Lesotho',
    value: 'Lesotho',
    continent: 'Africa',
  }, {
    text: 'Liberia',
    value: 'Liberia',
    continent: 'Africa',
  }, {
    text: 'Libya',
    value: 'Libya',
    continent: 'Africa',
  }, {
    text: 'Liechtenstein',
    value: 'Liechtenstein',
    continent: 'Europe',
  }, {
    text: 'Lithuania',
    value: 'Lithuania',
    continent: 'Europe',
  }, {
    text: 'Luxembourg',
    value: 'Luxembourg',
    continent: 'Europe',
  }, {
    text: 'Madagascar',
    value: 'Madagascar',
    continent: 'Africa',
  },
  {
    text: 'Malawi',
    value: 'Malawi',
    continent: 'Africa',
  },
  {
    text: 'Malaysia',
    value: 'Malaysia',
    continent: 'Asia',
  },
  {
    text: 'Maldives',
    value: 'Maldives',
    continent: 'Asia',
  },
  {
    text: 'Mali',
    value: 'Mali',
    continent: 'Africa',
  },
  {
    text: 'Malta',
    value: 'Malta',
    continent: 'Europe',
  },
  {
    text: 'Marshall Islands',
    value: 'Marshall Islands',
    continent: 'Oceania',
  },
  {
    text: 'Martinique',
    value: 'Martinique',
    continent: 'North America',
  },
  {
    text: 'Mauritania',
    value: 'Mauritania',
    continent: 'Africa',
  },
  {
    text: 'Mauritius',
    value: 'Mauritius',
    continent: 'Africa',
  },
  {
    text: 'Mayotte',
    value: 'Mayotte',
    continent: 'Africa',
  },
  {
    text: 'Mexico',
    value: 'Mexico',
    continent: 'North America',
  },
  {
    text: 'Moldova',
    value: 'Moldova',
    continent: 'Europe',
  },
  {
    text: 'Monaco',
    value: 'Monaco',
    continent: 'Europe',
  }, {
    text: 'Mongolia',
    value: 'Mongolia',
    continent: 'Asia',
  }, {
    text: 'Montenegro',
    value: 'Montenegro',
    continent: 'Europe',
  }, {
    text: 'Morocco',
    value: 'Morocco',
    continent: 'Africa',
  }, {
    text: 'Mozambique',
    value: 'Mozambique',
    continent: 'Africa',
  }, {
    text: 'Myanmar',
    value: 'Myanmar',
    continent: 'Asia',
  }, {
    text: 'Namibia',
    value: 'Namibia',
    continent: 'Africa',
  }, {
    text: 'Nauru',
    value: 'Nauru',
    continent: 'Oceania',
  }, {
    text: 'Nepal',
    value: 'Nepal',
    continent: 'Asia',
  }, {
    text: 'Netherlands',
    value: 'Netherlands',
    continent: 'Europe',
  }, {
    text: 'New Zealand',
    value: 'New Zealand',
    continent: 'Oceania',
  }, {
    text: 'Nicaragua',
    value: 'Nicaragua',
    continent: 'North America',
  }, {
    text: 'Niger',
    value: 'Niger',
    continent: 'Africa',
  }, {
    text: 'Nigeria',
    value: 'Nigeria',
    continent: 'Africa',
  }, {
    text: 'Niue',
    value: 'Niue',
    continent: 'Oceania',
  }, {
    text: 'North Korea',
    value: 'North Korea',
    continent: 'Asia',
  }, {
    text: 'North Macedonia',
    value: 'North Macedonia',
    continent: 'Europe',
  }, {
    text: 'Norway',
    value: 'Norway',
    continent: 'Europe',
  }, {
    text: 'Oman',
    value: 'Oman',
    continent: 'Asia',
  }, {
    text: 'Pakistan',
    value: 'Pakistan',
    continent: 'Asia',
  }, {
    text: 'Palau',
    value: 'Palau',
    continent: 'Oceania',
  }, {
    text: 'Palestine',
    value: 'Palestine',
    continent: 'Asia',
  }, {
    text: 'Panama',
    value: 'Panama',
    continent: 'North America',
  }, {
    text: 'Papua New Guinea',
    value: 'Papua New Guinea',
    continent: 'Oceania',
  }, {
    text: 'Paraguay',
    value: 'Paraguay',
    continent: 'South America',
  }, {
    text: 'Peru',
    value: 'Peru',
    continent: 'South America',
  }, {
    text: 'Philippines',
    value: 'Philippines',
    continent: 'Asia',
  }, {
    text: 'Poland',
    value: 'Poland',
    continent: 'Europe',
  }, {
    text: 'Portugal',
    value: 'Portugal',
    continent: 'Europe',
  }, {
    text: 'Puerto Rico',
    value: 'Puerto Rico',
    continent: 'North America',
  }, {
    text: 'Qatar',
    value: 'Qatar',
    continent: 'Asia',
  }, {
    text: 'Réunion',
    value: 'Réunion',
    continent: 'Africa',
  }, {
    text: 'Romania',
    value: 'Romania',
    continent: 'Europe',
  }, {
    text: 'Russia',
    value: 'Russia',
    continent: 'Europe',
  }, {
    text: 'Rwanda',
    value: 'Rwanda',
    continent: 'Africa',
  }, {
    text: 'Saint Barthélemy',
    value: 'Saint Barthélemy',
    continent: 'North America',
  }, {
    text: 'Saint Helena',
    value: 'Saint Helena',
    continent: 'Africa',
  }, {
    text: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
    continent: 'North America',
  }, {
    text: 'Saint Lucia',
    value: 'Saint Lucia',
    continent: 'North America',
  }, {
    text: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
    continent: 'North America',
  }, {
    text: 'Samoa',
    value: 'Samoa',
    continent: 'Oceania',
  }, {
    text: 'San Marino',
    value: 'San Marino',
    continent: 'Europe',
  }, {
    text: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
    continent: 'Africa',
  }, {
    text: 'Saudi Arabia',
    value: 'Saudi Arabia',
    continent: 'Asia',
  }, {
    text: 'Senegal',
    value: 'Senegal',
    continent: 'Africa',
  }, {
    text: 'Serbia',
    value: 'Serbia',
    continent: 'Europe',
  }, {
    text: 'Seychelles',
    value: 'Seychelles',
    continent: 'Africa',
  }, {
    text: 'Sierra Leone',
    value: 'Sierra Leone',
    continent: 'Africa',
  }, {
    text: 'Singapore',
    value: 'Singapore',
    continent: 'Asia',
  }, {
    text: 'Sint Maarten',
    value: 'Sint Maarten',
    continent: 'North America',
  }, {
    text: 'Slovakia',
    value: 'Slovakia',
    continent: 'Europe',
  }, {
    text: 'Slovenia',
    value: 'Slovenia',
    continent: 'Europe',
  }, {
    text: 'Solomon Islands',
    value: 'Solomon Islands',
    continent: 'Oceania',
  }, {
    text: 'Somalia',
    value: 'Somalia',
    continent: 'Africa',
  }, {
    text: 'South Africa',
    value: 'South Africa',
    continent: 'Africa',
  }, {
    text: 'South Korea',
    value: 'South Korea',
    continent: 'Asia',
  }, {
    text: 'South Sudan',
    value: 'South Sudan',
    continent: 'Africa',
  }, {
    text: 'Spain',
    value: 'Spain',
    continent: 'Europe',
  },
  {
    text: 'Sri Lanka',
    value: 'Sri Lanka',
    continent: 'Asia',
  },
  {
    text: 'Sudan',
    value: 'Sudan',
    continent: 'Africa',
  },
  {
    text: 'Suriname',
    value: 'Suriname',
    continent: 'South America',
  },
  {
    text: 'Sweden',
    value: 'Sweden',
    continent: 'Europe',
  },
  {
    text: 'Switzerland',
    value: 'Switzerland',
    continent: 'Europe',
  },
  {
    text: 'Syria',
    value: 'Syria',
    continent: 'Asia',
  },
  {
    text: 'Taiwan',
    value: 'Taiwan',
    continent: 'Asia',
  },
  {
    text: 'Tajikistan',
    value: 'Tajikistan',
    continent: 'Asia',
  },
  {
    text: 'Tanzania',
    value: 'Tanzania',
    continent: 'Africa',
  },
  {
    text: 'Thailand',
    value: 'Thailand',
    continent: 'Asia',
  },
  {
    text: 'Timor-Leste',
    value: 'Timor-Leste',
    continent: 'Asia',
  },
  {
    text: 'Togo',
    value: 'Togo',
    continent: 'Africa',
  }, {
    text: 'Tonga',
    value: 'Tonga',
    continent: 'Oceania',
  },
  {
    text: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
    continent: 'North America',
  },
  {
    text: 'Tunisia',
    value: 'Tunisia',
    continent: 'Africa',
  },
  {
    text: 'Turkey',
    value: 'Turkey',
    continent: 'Asia',
  },
  {
    text: 'Turkmenistan',
    value: 'Turkmenistan',
    continent: 'Asia',
  },
  {
    text: 'Tuvalu',
    value: 'Tuvalu',
    continent: 'Oceania',
  },
  {
    text: 'Uganda',
    value: 'Uganda',
    continent: 'Africa',
  },
  {
    text: 'Ukraine',
    value: 'Ukraine',
    continent: 'Europe',
  }, {
    text: 'United Arab Emirates',
    value: 'United Arab Emirates',
    continent: 'Asia',
  }, {
    text: 'United Kingdom',
    value: 'United Kingdom',
    continent: 'Europe',
  }, {
    text: 'United States',
    value: 'United States',
    continent: 'North America',
  }, {
    text: 'Uruguay',
    value: 'Uruguay',
    continent: 'South America',
  }, {
    text: 'Uzbekistan',
    value: 'Uzbekistan',
    continent: 'Asia',
  }, {
    text: 'Vanuatu',
    value: 'Vanuatu',
    continent: 'Oceania',
  }, {
    text: 'Venezuela',
    value: 'Venezuela',
    continent: 'South America',
  }, {
    text: 'Vietnam',
    value: 'Vietnam',
    continent: 'Asia',
  }, {
    text: 'Yemen',
    value: 'Yemen',
    continent: 'Asia',
  }, {
    text: 'Zambia',
    value: 'Zambia',
    continent: 'Africa',
  }, {
    text: 'Zimbabwe',
    value: 'Zimbabwe',
    continent: 'Africa',
  },
];
export const frequencyOptions = [
  {
    text: 'Tick',
    value: 'Tick',
  },
  {
    text: 'Minutely',
    value: 'Minutely',
  },
  {
    text: '5-Minutely',
    value: '5-Minutely',
  },
  {
    text: '15-Minutely',
    value: '15-Minutely',
  },
  {
    text: 'Hourly',
    value: 'Hourly',
  },
  {
    text: 'Daily',
    value: 'Daily',
  },
  {
    text: 'Weekly',
    value: 'Weekly',
  },
  {
    text: 'Monthly',
    value: 'Monthly',
  },
  {
    text: 'Half-Monthly',
    value: 'Half-Monthly',
  },
  {
    text: 'Quarterly',
    value: 'Quarterly',
  },
  {
    text: 'Seasonally',
    value: 'Seasonally',
  },
  {
    text: 'Half-Yearly',
    value: 'Half-Yearly',
  },
  {
    text: 'Yearly',
    value: 'Yearly',
  },
];
export const frequencyShorted = [
  'Tick',
  'Minutely',
  '5-Minutely',
  '15-Minutely',
  'Hourly',
  'Daily',
  'Weekly',
  'Monthly',
  'half-Monthly',
  'Quarterly',
  'Seasonally',
  'half-Yearly',
  'Yearly',
];
export const licenceTypeShorted = [
  'Core',
  'Morningstar Pricing',
  'Vendor Negotiated',
];

export const marketsOptions = [
  {
    text: 'Agriculture',
    value: 'Agriculture',
  }, {
    text: 'Coal',
    value: 'Coal',
  }, {
    text: 'Currency',
    value: 'Currency',
  }, {
    text: 'Economic Indicator',
    value: 'Economic Indicator',
  }, {
    text: 'Electricity',
    value: 'Electricity',
  },
  {
    text: 'Emissions',
    value: 'Emissions',
  },
  {
    text: 'Equity',
    value: 'Equity',
  },
  {
    text: 'Financial',
    value: 'Financial',
  },
  {
    text: 'Freight',
    value: 'Freight',
  },
  {
    text: 'Interest Rates',
    value: 'Interest Rates',
  },
  {
    text: 'Metals',
    value: 'Metals',
  },
  {
    text: 'Natural Gas',
    value: 'Natural Gas',
  },
  {
    text: 'LNG',
    value: 'LNG',
  },
  {
    text: 'LPGs',
    value: 'LPGs',
  },
  {
    text: 'Hydrogen',
    value: 'Hydrogen',
  },
  {
    text: 'Agriculture',
    value: 'Agriculture',
  },
  {
    text: 'NGLs',
    value: 'NGLs',
  },
  {
    text: 'Oil',
    value: 'Oil',
  },
  {
    text: 'Petrochemical',
    value: 'Petrochemical',
  },
  {
    text: 'Refined Products',
    value: 'Refined Products',
  },
  {
    text: 'Climate Statistics',
    value: 'Climate Statistics',
  },
];
export const marketShorted = [
  'Agriculture',
  'Coal',
  'Currency',
  'Economic Indicator',
  'Electricity',
  'Emissions',
  'Equity',
  'Financial',
  'Freight',
  'Interest Rates',
  'Metals',
  'Natural Gas',
  'LNG',
  'LPGs',
  'Hydrogen',
  'NGLs',
  'Oil',
  'Petrochemical',
  'Refined Products',
  'Climate Statistics',
];
export const continentOptions = [
  {
    text: 'Africa',
    value: 'Africa',
  },
  {
    text: 'Antarctica',
    value: 'Antarctica',
  },
  {
    text: 'Asia',
    value: 'Asia',
  },
  {
    text: 'Europe',
    value: 'Europe',
  },
  {
    text: 'North America',
    value: 'North America',
  },
  {
    text: 'Oceania',
    value: 'Oceania',
  },
  {
    text: 'South America',
    value: 'South America',
  },
];
export const licensingTypeOptions = [
  {
    text: 'Core',
    value: 'Core',
  },
  {
    text: 'Morningstar Pricing',
    value: 'Morningstar Pricing',
  },
  {
    text: 'Vendor Negotiated',
    value: 'Vendor Negotiated',
  },
];
export const continentsShorted = ['Africa', 'Antarctica', 'Asia', 'Europe', 'North America', 'Oceania', 'South America'];
export const timezoneShorted = [
  'Etc/GMT+12',
  'Etc/GMT+11',
  'Pacific/Apia',
  'Pacific/Midway',
  'Pacific/Niue',
  'Pacific/Pago_Pago',
  'America/Adak',
  'Etc/GMT+10',
  'HST',
  'Pacific/Fakaofo',
  'Pacific/Honolulu',
  'Pacific/Johnston',
  'Pacific/Rarotonga',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'America/Anchorage',
  'America/Juneau',
  'America/Nome',
  'America/Yakutat',
  'Etc/GMT+9',
  'Pacific/Gambier',
  'America/Dawson',
  'America/Los_Angeles',
  'America/Santa_Isabel',
  'America/Tijuana',
  'America/Vancouver',
  'America/Whitehorse',
  'Etc/GMT+8',
  'PST8PDT',
  'Pacific/Pitcairn',
  'America/Boise',
  'America/Cambridge_Bay',
  'America/Chihuahua',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Edmonton',
  'America/Hermosillo',
  'America/Inuvik',
  'America/Mazatlan',
  'America/Ojinaga',
  'America/Phoenix',
  'America/Yellowknife',
  'Etc/GMT+7',
  'MST',
  'MST7MDT',
  'America/Bahia_Banderas',
  'America/Belize',
  'America/Cancun',
  'America/Chicago',
  'America/Costa_Rica',
  'America/El_Salvador',
  'America/Guatemala',
  'America/Indiana/Knox',
  'America/Indiana/Tell_City',
  'America/Managua',
  'America/Matamoros',
  'America/Menominee',
  'America/Merida',
  'America/Mexico_City',
  'America/Monterrey',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Winnipeg',
  'CST6CDT',
  'Etc/GMT+6',
  'Pacific/Easter',
  'Pacific/Galapagos',
  'America/Atikokan',
  'America/Bogota',
  'America/Cayman',
  'America/Detroit',
  'America/Grand_Turk',
  'America/Guayaquil',
  'America/Havana',
  'America/Indiana/Indianapolis',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Lima',
  'America/Montreal',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Panama',
  'America/Pangnirtung',
  'America/Port-au-Prince',
  'America/Resolute',
  'America/Thunder_Bay',
  'America/Toronto',
  'EST',
  'EST5EDT',
  'Etc/GMT+5',
  'America/Caracas',
  'America/Anguilla',
  'America/Antigua',
  'America/Argentina/San_Luis',
  'America/Aruba',
  'America/Asuncion',
  'America/Barbados',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Curacao',
  'America/Dominica',
  'America/Eirunepe',
  'America/Glace_Bay',
  'America/Goose_Bay',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guyana',
  'America/Halifax',
  'America/La_Paz',
  'America/Manaus',
  'America/Martinique',
  'America/Moncton',
  'America/Montserrat',
  'America/Port_of_Spain',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Rio_Branco',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Thule',
  'America/Tortola',
  'Antarctica/Palmer',
  'Atlantic/Bermuda',
  'Atlantic/Stanley',
  'Etc/GMT+4',
  'America/St_Johns',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Bahia',
  'America/Belem',
  'America/Cayenne',
  'America/Fortaleza',
  'America/Godthab',
  'America/Maceio',
  'America/Miquelon',
  'America/Montevideo',
  'America/Paramaribo',
  'America/Recife',
  'America/Santarem',
  'America/Sao_Paulo',
  'Antarctica/Rothera',
  'Etc/GMT+3',
  'America/Noronha',
  'Atlantic/South_Georgia',
  'Etc/GMT+2',
  'America/Scoresbysund',
  'Atlantic/Azores',
  'Atlantic/Cape_Verde',
  'Etc/GMT+1',
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Bamako',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Casablanca',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Lome',
  'Africa/Monrovia',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Sao_Tome',
  'America/Danmarkshavn',
  'Atlantic/Canary',
  'Atlantic/Faroe',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/St_Helena',
  'Etc/GMT',
  'Etc/UCT',
  'Etc/UTC',
  'Europe/Dublin',
  'Europe/Lisbon',
  'Europe/London',
  'UTC',
  'WET',
  'Africa/Algiers',
  'Africa/Bangui',
  'Africa/Brazzaville',
  'Africa/Ceuta',
  'Africa/Douala',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Luanda',
  'Africa/Malabo',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Porto-Novo',
  'Africa/Tunis',
  'Africa/Windhoek',
  'CET',
  'Etc/GMT-1',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Brussels',
  'Europe/Budapest',
  'Europe/Copenhagen',
  'Europe/Gibraltar',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Monaco',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Prague',
  'Europe/Rome',
  'Europe/Stockholm',
  'Europe/Tirane',
  'Europe/Vaduz',
  'Europe/Vienna',
  'Europe/Warsaw',
  'Europe/Zurich',
  'MET',
  'Africa/Blantyre',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Kigali',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Tripoli',
  'Asia/Amman',
  'Asia/Beirut',
  'Asia/Damascus',
  'Asia/Gaza',
  'Asia/Jerusalem',
  'Asia/Nicosia',
  'EET',
  'Etc/GMT-2',
  'Europe/Athens',
  'Europe/Bucharest',
  'Europe/Chisinau',
  'Europe/Helsinki',
  'Europe/Istanbul',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Minsk',
  'Europe/Riga',
  'Europe/Simferopol',
  'Europe/Sofia',
  'Europe/Tallinn',
  'Europe/Uzhgorod',
  'Europe/Vilnius',
  'Europe/Zaporozhye',
  'Africa/Addis_Ababa',
  'Africa/Asmara',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Mogadishu',
  'Africa/Nairobi',
  'Antarctica/Syowa',
  'Asia/Aden',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Kuwait',
  'Asia/Qatar',
  'Asia/Riyadh',
  'Etc/GMT-3',
  'Europe/Moscow',
  'Europe/Samara',
  'Europe/Volgograd',
  'Indian/Antananarivo',
  'Indian/Comoro',
  'Indian/Mayotte',
  'Asia/Tehran',
  'Asia/Baku',
  'Asia/Dubai',
  'Asia/Muscat',
  'Asia/Tbilisi',
  'Asia/Yerevan',
  'Etc/GMT-4',
  'Indian/Mahe',
  'Indian/Mauritius',
  'Indian/Reunion',
  'Asia/Kabul',
  'Antarctica/Mawson',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Dushanbe',
  'Asia/Karachi',
  'Asia/Oral',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'Asia/Yekaterinburg',
  'Etc/GMT-5',
  'Indian/Kerguelen',
  'Indian/Maldives',
  'Asia/Colombo',
  'Asia/Kolkata',
  'Asia/Kathmandu',
  'Antarctica/Vostok',
  'Asia/Almaty',
  'Asia/Bishkek',
  'Asia/Dhaka',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Qyzylorda',
  'Asia/Thimphu',
  'Etc/GMT-6',
  'Indian/Chagos',
  'Asia/Rangoon',
  'Indian/Cocos',
  'Antarctica/Davis',
  'Asia/Bangkok',
  'Asia/Ho_Chi_Minh',
  'Asia/Hovd',
  'Asia/Jakarta',
  'Asia/Krasnoyarsk',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Vientiane',
  'Etc/GMT-7',
  'Indian/Christmas',
  'Antarctica/Casey',
  'Asia/Brunei',
  'Asia/Choibalsan',
  'Asia/Chongqing',
  'Asia/Harbin',
  'Asia/Hong_Kong',
  'Asia/Irkutsk',
  'Asia/Kashgar',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Macau',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Taipei',
  'Asia/Ulaanbaatar',
  'Asia/Urumqi',
  'Australia/Perth',
  'Etc/GMT-8',
  'Australia/Eucla',
  'Asia/Dili',
  'Asia/Jayapura',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Asia/Tokyo',
  'Asia/Yakutsk',
  'Etc/GMT-9',
  'Pacific/Palau',
  'Australia/Adelaide',
  'Australia/Broken_Hill',
  'Australia/Darwin',
  'Antarctica/DumontDUrville',
  'Asia/Sakhalin',
  'Asia/Vladivostok',
  'Australia/Brisbane',
  'Australia/Currie',
  'Australia/Hobart',
  'Australia/Lindeman',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Etc/GMT-10',
  'Pacific/Chuuk',
  'Pacific/Guam',
  'Pacific/Port_Moresby',
  'Pacific/Saipan',
  'Australia/Lord_Howe',
  'Antarctica/Macquarie',
  'Asia/Anadyr',
  'Asia/Kamchatka',
  'Asia/Magadan',
  'Etc/GMT-11',
  'Pacific/Efate',
  'Pacific/Guadalcanal',
  'Pacific/Kosrae',
  'Pacific/Noumea',
  'Pacific/Pohnpei',
  'Pacific/Norfolk',
  'Antarctica/McMurdo',
  'Etc/GMT-12',
  'Pacific/Auckland',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Nauru',
  'Pacific/Tarawa',
  'Pacific/Wake',
  'Pacific/Wallis',
  'Pacific/Chatham',
  'Etc/GMT-13',
  'Pacific/Enderbury',
  'Pacific/Tongatapu',
  'Etc/GMT-14',
  'Pacific/Kiritimati',
];
export const timezoneOptions = [
  {
    text: 'Etc/GMT+12',
    value: 'Etc/GMT+12',
  },
  {
    text: 'Etc/GMT+11',
    value: 'Etc/GMT+11',
  },
  {
    text: 'Pacific/Apia',
    value: 'Pacific/Apia',
  },
  {
    text: 'Pacific/Midway',
    value: 'Pacific/Midway',
  },
  {
    text: 'Pacific/Niue',
    value: 'Pacific/Niue',
  },
  {
    text: 'Pacific/Pago_Pago',
    value: 'Pacific/Pago_Pago',
  },
  {
    text: 'America/Adak',
    value: 'America/Adak',
  },
  {
    text: 'Etc/GMT+10',
    value: 'Etc/GMT+10',
  },
  {
    text: 'HST',
    value: 'HST',
  },
  {
    text: 'Pacific/Fakaofo',
    value: 'Pacific/Fakaofo',
  },
  {
    text: 'Pacific/Honolulu',
    value: 'Pacific/Honolulu',
  },
  {
    text: 'Pacific/Johnston',
    value: 'Pacific/Johnston',
  },
  {
    text: 'Pacific/Rarotonga',
    value: 'Pacific/Rarotonga',
  },
  {
    text: 'Pacific/Tahiti',
    value: 'Pacific/Tahiti',
  },
  {
    text: 'Pacific/Marquesas',
    value: 'Pacific/Marquesas',
  },
  {
    text: 'America/Anchorage',
    value: 'America/Anchorage',
  },
  {
    text: 'America/Juneau',
    value: 'America/Juneau',
  },
  {
    text: 'America/Nome',
    value: 'America/Nome',
  },
  {
    text: 'America/Yakutat',
    value: 'America/Yakutat',
  },
  {
    text: 'Etc/GMT+9',
    value: 'Etc/GMT+9',
  },
  {
    text: 'Pacific/Gambier',
    value: 'Pacific/Gambier',
  },
  {
    text: 'America/Dawson',
    value: 'America/Dawson',
  },
  {
    text: 'America/Los_Angeles',
    value: 'America/Los_Angeles',
  },
  {
    text: 'America/Santa_Isabel',
    value: 'America/Santa_Isabel',
  },
  {
    text: 'America/Tijuana',
    value: 'America/Tijuana',
  },
  {
    text: 'America/Vancouver',
    value: 'America/Vancouver',
  },
  {
    text: 'America/Whitehorse',
    value: 'America/Whitehorse',
  },
  {
    text: 'Etc/GMT+8',
    value: 'Etc/GMT+8',
  },
  {
    text: 'PST8PDT',
    value: 'PST8PDT',
  },
  {
    text: 'Pacific/Pitcairn',
    value: 'Pacific/Pitcairn',
  },
  {
    text: 'America/Boise',
    value: 'America/Boise',
  },
  {
    text: 'America/Cambridge_Bay',
    value: 'America/Cambridge_Bay',
  },
  {
    text: 'America/Chihuahua',
    value: 'America/Chihuahua',
  },
  {
    text: 'America/Dawson_Creek',
    value: 'America/Dawson_Creek',
  },
  {
    text: 'America/Denver',
    value: 'America/Denver',
  },
  {
    text: 'America/Edmonton',
    value: 'America/Edmonton',
  },
  {
    text: 'America/Hermosillo',
    value: 'America/Hermosillo',
  },
  {
    text: 'America/Inuvik',
    value: 'America/Inuvik',
  },
  {
    text: 'America/Mazatlan',
    value: 'America/Mazatlan',
  },
  {
    text: 'America/Ojinaga',
    value: 'America/Ojinaga',
  },
  {
    text: 'America/Phoenix',
    value: 'America/Phoenix',
  },
  {
    text: 'America/Yellowknife',
    value: 'America/Yellowknife',
  },
  {
    text: 'Etc/GMT+7',
    value: 'Etc/GMT+7',
  },
  {
    text: 'MST',
    value: 'MST',
  },
  {
    text: 'MST7MDT',
    value: 'MST7MDT',
  },
  {
    text: 'America/Bahia_Banderas',
    value: 'America/Bahia_Banderas',
  },
  {
    text: 'America/Belize',
    value: 'America/Belize',
  },
  {
    text: 'America/Cancun',
    value: 'America/Cancun',
  },
  {
    text: 'America/Chicago',
    value: 'America/Chicago',
  },
  {
    text: 'America/Costa_Rica',
    value: 'America/Costa_Rica',
  },
  {
    text: 'America/El_Salvador',
    value: 'America/El_Salvador',
  },
  {
    text: 'America/Guatemala',
    value: 'America/Guatemala',
  },
  {
    text: 'America/Indiana/Knox',
    value: 'America/Indiana/Knox',
  },
  {
    text: 'America/Indiana/Tell_City',
    value: 'America/Indiana/Tell_City',
  },
  {
    text: 'America/Managua',
    value: 'America/Managua',
  },
  {
    text: 'America/Matamoros',
    value: 'America/Matamoros',
  },
  {
    text: 'America/Menominee',
    value: 'America/Menominee',
  },
  {
    text: 'America/Merida',
    value: 'America/Merida',
  },
  {
    text: 'America/Mexico_City',
    value: 'America/Mexico_City',
  },
  {
    text: 'America/Monterrey',
    value: 'America/Monterrey',
  },
  {
    text: 'America/North_Dakota/Center',
    value: 'America/North_Dakota/Center',
  },
  {
    text: 'America/North_Dakota/New_Salem',
    value: 'America/North_Dakota/New_Salem',
  },
  {
    text: 'America/Rainy_River',
    value: 'America/Rainy_River',
  },
  {
    text: 'America/Rankin_Inlet',
    value: 'America/Rankin_Inlet',
  },
  {
    text: 'America/Regina',
    value: 'America/Regina',
  },
  {
    text: 'America/Swift_Current',
    value: 'America/Swift_Current',
  },
  {
    text: 'America/Tegucigalpa',
    value: 'America/Tegucigalpa',
  },
  {
    text: 'America/Winnipeg',
    value: 'America/Winnipeg',
  },
  {
    text: 'CST6CDT',
    value: 'CST6CDT',
  },
  {
    text: 'Etc/GMT+6',
    value: 'Etc/GMT+6',
  },
  {
    text: 'Pacific/Easter',
    value: 'Pacific/Easter',
  },
  {
    text: 'Pacific/Galapagos',
    value: 'Pacific/Galapagos',
  },
  {
    text: 'America/Atikokan',
    value: 'America/Atikokan',
  },
  {
    text: 'America/Bogota',
    value: 'America/Bogota',
  },
  {
    text: 'America/Cayman',
    value: 'America/Cayman',
  },
  {
    text: 'America/Detroit',
    value: 'America/Detroit',
  },
  {
    text: 'America/Grand_Turk',
    value: 'America/Grand_Turk',
  },
  {
    text: 'America/Guayaquil',
    value: 'America/Guayaquil',
  },
  {
    text: 'America/Havana',
    value: 'America/Havana',
  },
  {
    text: 'America/Indiana/Indianapolis',
    value: 'America/Indiana/Indianapolis',
  },
  {
    text: 'America/Indiana/Marengo',
    value: 'America/Indiana/Marengo',
  },
  {
    text: 'America/Indiana/Petersburg',
    value: 'America/Indiana/Petersburg',
  },
  {
    text: 'America/Indiana/Vevay',
    value: 'America/Indiana/Vevay',
  },
  {
    text: 'America/Indiana/Vincennes',
    value: 'America/Indiana/Vincennes',
  },
  {
    text: 'America/Indiana/Winamac',
    value: 'America/Indiana/Winamac',
  },
  {
    text: 'America/Iqaluit',
    value: 'America/Iqaluit',
  },
  {
    text: 'America/Jamaica',
    value: 'America/Jamaica',
  },
  {
    text: 'America/Kentucky/Louisville',
    value: 'America/Kentucky/Louisville',
  },
  {
    text: 'America/Kentucky/Monticello',
    value: 'America/Kentucky/Monticello',
  },
  {
    text: 'America/Lima',
    value: 'America/Lima',
  },
  {
    text: 'America/Montreal',
    value: 'America/Montreal',
  },
  {
    text: 'America/Nassau',
    value: 'America/Nassau',
  },
  {
    text: 'America/New_York',
    value: 'America/New_York',
  },
  {
    text: 'America/Nipigon',
    value: 'America/Nipigon',
  },
  {
    text: 'America/Panama',
    value: 'America/Panama',
  },
  {
    text: 'America/Pangnirtung',
    value: 'America/Pangnirtung',
  },
  {
    text: 'America/Port-au-Prince',
    value: 'America/Port-au-Prince',
  },
  {
    text: 'America/Resolute',
    value: 'America/Resolute',
  },
  {
    text: 'America/Thunder_Bay',
    value: 'America/Thunder_Bay',
  },
  {
    text: 'America/Toronto',
    value: 'America/Toronto',
  },
  {
    text: 'EST',
    value: 'EST',
  },
  {
    text: 'EST5EDT',
    value: 'EST5EDT',
  },
  {
    text: 'Etc/GMT+5',
    value: 'Etc/GMT+5',
  },
  {
    text: 'America/Caracas',
    value: 'America/Caracas',
  },
  {
    text: 'America/Anguilla',
    value: 'America/Anguilla',
  },
  {
    text: 'America/Antigua',
    value: 'America/Antigua',
  },
  {
    text: 'America/Argentina/San_Luis',
    value: 'America/Argentina/San_Luis',
  },
  {
    text: 'America/Aruba',
    value: 'America/Aruba',
  },
  {
    text: 'America/Asuncion',
    value: 'America/Asuncion',
  },
  {
    text: 'America/Barbados',
    value: 'America/Barbados',
  },
  {
    text: 'America/Blanc-Sablon',
    value: 'America/Blanc-Sablon',
  },
  {
    text: 'America/Boa_Vista',
    value: 'America/Boa_Vista',
  },
  {
    text: 'America/Campo_Grande',
    value: 'America/Campo_Grande',
  },
  {
    text: 'America/Cuiaba',
    value: 'America/Cuiaba',
  },
  {
    text: 'America/Curacao',
    value: 'America/Curacao',
  },
  {
    text: 'America/Dominica',
    value: 'America/Dominica',
  },
  {
    text: 'America/Eirunepe',
    value: 'America/Eirunepe',
  },
  {
    text: 'America/Glace_Bay',
    value: 'America/Glace_Bay',
  },
  {
    text: 'America/Goose_Bay',
    value: 'America/Goose_Bay',
  },
  {
    text: 'America/Grenada',
    value: 'America/Grenada',
  },
  {
    text: 'America/Guadeloupe',
    value: 'America/Guadeloupe',
  },
  {
    text: 'America/Guyana',
    value: 'America/Guyana',
  },
  {
    text: 'America/Halifax',
    value: 'America/Halifax',
  },
  {
    text: 'America/La_Paz',
    value: 'America/La_Paz',
  },
  {
    text: 'America/Manaus',
    value: 'America/Manaus',
  },
  {
    text: 'America/Martinique',
    value: 'America/Martinique',
  },
  {
    text: 'America/Moncton',
    value: 'America/Moncton',
  },
  {
    text: 'America/Montserrat',
    value: 'America/Montserrat',
  },
  {
    text: 'America/Port_of_Spain',
    value: 'America/Port_of_Spain',
  },
  {
    text: 'America/Porto_Velho',
    value: 'America/Porto_Velho',
  },
  {
    text: 'America/Puerto_Rico',
    value: 'America/Puerto_Rico',
  },
  {
    text: 'America/Rio_Branco',
    value: 'America/Rio_Branco',
  },
  {
    text: 'America/Santiago',
    value: 'America/Santiago',
  },
  {
    text: 'America/Santo_Domingo',
    value: 'America/Santo_Domingo',
  },
  {
    text: 'America/St_Kitts',
    value: 'America/St_Kitts',
  },
  {
    text: 'America/St_Lucia',
    value: 'America/St_Lucia',
  },
  {
    text: 'America/St_Thomas',
    value: 'America/St_Thomas',
  },
  {
    text: 'America/St_Vincent',
    value: 'America/St_Vincent',
  },
  {
    text: 'America/Thule',
    value: 'America/Thule',
  },
  {
    text: 'America/Tortola',
    value: 'America/Tortola',
  },
  {
    text: 'Antarctica/Palmer',
    value: 'Antarctica/Palmer',
  },
  {
    text: 'Atlantic/Bermuda',
    value: 'Atlantic/Bermuda',
  },
  {
    text: 'Atlantic/Stanley',
    value: 'Atlantic/Stanley',
  },
  {
    text: 'Etc/GMT+4',
    value: 'Etc/GMT+4',
  },
  {
    text: 'America/St_Johns',
    value: 'America/St_Johns',
  },
  {
    text: 'America/Araguaina',
    value: 'America/Araguaina',
  },
  {
    text: 'America/Argentina/Buenos_Aires',
    value: 'America/Argentina/Buenos_Aires',
  },
  {
    text: 'America/Argentina/Catamarca',
    value: 'America/Argentina/Catamarca',
  },
  {
    text: 'America/Argentina/Cordoba',
    value: 'America/Argentina/Cordoba',
  },
  {
    text: 'America/Argentina/Jujuy',
    value: 'America/Argentina/Jujuy',
  },
  {
    text: 'America/Argentina/La_Rioja',
    value: 'America/Argentina/La_Rioja',
  },
  {
    text: 'America/Argentina/Mendoza',
    value: 'America/Argentina/Mendoza',
  },
  {
    text: 'America/Argentina/Rio_Gallegos',
    value: 'America/Argentina/Rio_Gallegos',
  },
  {
    text: 'America/Argentina/Salta',
    value: 'America/Argentina/Salta',
  },
  {
    text: 'America/Argentina/San_Juan',
    value: 'America/Argentina/San_Juan',
  },
  {
    text: 'America/Argentina/Tucuman',
    value: 'America/Argentina/Tucuman',
  },
  {
    text: 'America/Argentina/Ushuaia',
    value: 'America/Argentina/Ushuaia',
  },
  {
    text: 'America/Bahia',
    value: 'America/Bahia',
  },
  {
    text: 'America/Belem',
    value: 'America/Belem',
  },
  {
    text: 'America/Cayenne',
    value: 'America/Cayenne',
  },
  {
    text: 'America/Fortaleza',
    value: 'America/Fortaleza',
  },
  {
    text: 'America/Godthab',
    value: 'America/Godthab',
  },
  {
    text: 'America/Maceio',
    value: 'America/Maceio',
  },
  {
    text: 'America/Miquelon',
    value: 'America/Miquelon',
  },
  {
    text: 'America/Montevideo',
    value: 'America/Montevideo',
  },
  {
    text: 'America/Paramaribo',
    value: 'America/Paramaribo',
  },
  {
    text: 'America/Recife',
    value: 'America/Recife',
  },
  {
    text: 'America/Santarem',
    value: 'America/Santarem',
  },
  {
    text: 'America/Sao_Paulo',
    value: 'America/Sao_Paulo',
  },
  {
    text: 'Antarctica/Rothera',
    value: 'Antarctica/Rothera',
  },
  {
    text: 'Etc/GMT+3',
    value: 'Etc/GMT+3',
  },
  {
    text: 'America/Noronha',
    value: 'America/Noronha',
  },
  {
    text: 'Atlantic/South_Georgia',
    value: 'Atlantic/South_Georgia',
  },
  {
    text: 'Etc/GMT+2',
    value: 'Etc/GMT+2',
  },
  {
    text: 'America/Scoresbysund',
    value: 'America/Scoresbysund',
  },
  {
    text: 'Atlantic/Azores',
    value: 'Atlantic/Azores',
  },
  {
    text: 'Atlantic/Cape_Verde',
    value: 'Atlantic/Cape_Verde',
  },
  {
    text: 'Etc/GMT+1',
    value: 'Etc/GMT+1',
  },
  {
    text: 'Africa/Abidjan',
    value: 'Africa/Abidjan',
  },
  {
    text: 'Africa/Accra',
    value: 'Africa/Accra',
  },
  {
    text: 'Africa/Bamako',
    value: 'Africa/Bamako',
  },
  {
    text: 'Africa/Banjul',
    value: 'Africa/Banjul',
  },
  {
    text: 'Africa/Bissau',
    value: 'Africa/Bissau',
  },
  {
    text: 'Africa/Casablanca',
    value: 'Africa/Casablanca',
  },
  {
    text: 'Africa/Conakry',
    value: 'Africa/Conakry',
  },
  {
    text: 'Africa/Dakar',
    value: 'Africa/Dakar',
  },
  {
    text: 'Africa/El_Aaiun',
    value: 'Africa/El_Aaiun',
  },
  {
    text: 'Africa/Freetown',
    value: 'Africa/Freetown',
  },
  {
    text: 'Africa/Lome',
    value: 'Africa/Lome',
  },
  {
    text: 'Africa/Monrovia',
    value: 'Africa/Monrovia',
  },
  {
    text: 'Africa/Nouakchott',
    value: 'Africa/Nouakchott',
  },
  {
    text: 'Africa/Ouagadougou',
    value: 'Africa/Ouagadougou',
  },
  {
    text: 'Africa/Sao_Tome',
    value: 'Africa/Sao_Tome',
  },
  {
    text: 'America/Danmarkshavn',
    value: 'America/Danmarkshavn',
  },
  {
    text: 'Atlantic/Canary',
    value: 'Atlantic/Canary',
  },
  {
    text: 'Atlantic/Faroe',
    value: 'Atlantic/Faroe',
  },
  {
    text: 'Atlantic/Madeira',
    value: 'Atlantic/Madeira',
  },
  {
    text: 'Atlantic/Reykjavik',
    value: 'Atlantic/Reykjavik',
  },
  {
    text: 'Atlantic/St_Helena',
    value: 'Atlantic/St_Helena',
  },
  {
    text: 'Etc/GMT',
    value: 'Etc/GMT',
  },
  {
    text: 'Etc/UCT',
    value: 'Etc/UCT',
  },
  {
    text: 'Etc/UTC',
    value: 'Etc/UTC',
  },
  {
    text: 'Europe/Dublin',
    value: 'Europe/Dublin',
  },
  {
    text: 'Europe/Lisbon',
    value: 'Europe/Lisbon',
  },
  {
    text: 'Europe/London',
    value: 'Europe/London',
  },
  {
    text: 'UTC',
    value: 'UTC',
  },
  {
    text: 'WET',
    value: 'WET',
  },
  {
    text: 'Africa/Algiers',
    value: 'Africa/Algiers',
  },
  {
    text: 'Africa/Bangui',
    value: 'Africa/Bangui',
  },
  {
    text: 'Africa/Brazzaville',
    value: 'Africa/Brazzaville',
  },
  {
    text: 'Africa/Ceuta',
    value: 'Africa/Ceuta',
  },
  {
    text: 'Africa/Douala',
    value: 'Africa/Douala',
  },
  {
    text: 'Africa/Kinshasa',
    value: 'Africa/Kinshasa',
  },
  {
    text: 'Africa/Lagos',
    value: 'Africa/Lagos',
  },
  {
    text: 'Africa/Libreville',
    value: 'Africa/Libreville',
  },
  {
    text: 'Africa/Luanda',
    value: 'Africa/Luanda',
  },
  {
    text: 'Africa/Malabo',
    value: 'Africa/Malabo',
  },
  {
    text: 'Africa/Ndjamena',
    value: 'Africa/Ndjamena',
  },
  {
    text: 'Africa/Niamey',
    value: 'Africa/Niamey',
  },
  {
    text: 'Africa/Porto-Novo',
    value: 'Africa/Porto-Novo',
  },
  {
    text: 'Africa/Tunis',
    value: 'Africa/Tunis',
  },
  {
    text: 'Africa/Windhoek',
    value: 'Africa/Windhoek',
  },
  {
    text: 'CET',
    value: 'CET',
  },
  {
    text: 'Etc/GMT-1',
    value: 'Etc/GMT-1',
  },
  {
    text: 'Europe/Amsterdam',
    value: 'Europe/Amsterdam',
  },
  {
    text: 'Europe/Andorra',
    value: 'Europe/Andorra',
  },
  {
    text: 'Europe/Belgrade',
    value: 'Europe/Belgrade',
  },
  {
    text: 'Europe/Berlin',
    value: 'Europe/Berlin',
  },
  {
    text: 'Europe/Brussels',
    value: 'Europe/Brussels',
  },
  {
    text: 'Europe/Budapest',
    value: 'Europe/Budapest',
  },
  {
    text: 'Europe/Copenhagen',
    value: 'Europe/Copenhagen',
  },
  {
    text: 'Europe/Gibraltar',
    value: 'Europe/Gibraltar',
  },
  {
    text: 'Europe/Luxembourg',
    value: 'Europe/Luxembourg',
  },
  {
    text: 'Europe/Madrid',
    value: 'Europe/Madrid',
  },
  {
    text: 'Europe/Malta',
    value: 'Europe/Malta',
  },
  {
    text: 'Europe/Monaco',
    value: 'Europe/Monaco',
  },
  {
    text: 'Europe/Oslo',
    value: 'Europe/Oslo',
  },
  {
    text: 'Europe/Paris',
    value: 'Europe/Paris',
  },
  {
    text: 'Europe/Prague',
    value: 'Europe/Prague',
  },
  {
    text: 'Europe/Rome',
    value: 'Europe/Rome',
  },
  {
    text: 'Europe/Stockholm',
    value: 'Europe/Stockholm',
  },
  {
    text: 'Europe/Tirane',
    value: 'Europe/Tirane',
  },
  {
    text: 'Europe/Vaduz',
    value: 'Europe/Vaduz',
  },
  {
    text: 'Europe/Vienna',
    value: 'Europe/Vienna',
  },
  {
    text: 'Europe/Warsaw',
    value: 'Europe/Warsaw',
  },
  {
    text: 'Europe/Zurich',
    value: 'Europe/Zurich',
  },
  {
    text: 'MET',
    value: 'MET',
  },
  {
    text: 'Africa/Blantyre',
    value: 'Africa/Blantyre',
  },
  {
    text: 'Africa/Bujumbura',
    value: 'Africa/Bujumbura',
  },
  {
    text: 'Africa/Cairo',
    value: 'Africa/Cairo',
  },
  {
    text: 'Africa/Gaborone',
    value: 'Africa/Gaborone',
  },
  {
    text: 'Africa/Harare',
    value: 'Africa/Harare',
  },
  {
    text: 'Africa/Johannesburg',
    value: 'Africa/Johannesburg',
  },
  {
    text: 'Africa/Kigali',
    value: 'Africa/Kigali',
  },
  {
    text: 'Africa/Lubumbashi',
    value: 'Africa/Lubumbashi',
  },
  {
    text: 'Africa/Lusaka',
    value: 'Africa/Lusaka',
  },
  {
    text: 'Africa/Maputo',
    value: 'Africa/Maputo',
  },
  {
    text: 'Africa/Maseru',
    value: 'Africa/Maseru',
  },
  {
    text: 'Africa/Mbabane',
    value: 'Africa/Mbabane',
  },
  {
    text: 'Africa/Tripoli',
    value: 'Africa/Tripoli',
  },
  {
    text: 'Asia/Amman',
    value: 'Asia/Amman',
  },
  {
    text: 'Asia/Beirut',
    value: 'Asia/Beirut',
  },
  {
    text: 'Asia/Damascus',
    value: 'Asia/Damascus',
  },
  {
    text: 'Asia/Gaza',
    value: 'Asia/Gaza',
  },
  {
    text: 'Asia/Jerusalem',
    value: 'Asia/Jerusalem',
  },
  {
    text: 'Asia/Nicosia',
    value: 'Asia/Nicosia',
  },
  {
    text: 'EET',
    value: 'EET',
  },
  {
    text: 'Etc/GMT-2',
    value: 'Etc/GMT-2',
  },
  {
    text: 'Europe/Athens',
    value: 'Europe/Athens',
  },
  {
    text: 'Europe/Bucharest',
    value: 'Europe/Bucharest',
  },
  {
    text: 'Europe/Chisinau',
    value: 'Europe/Chisinau',
  },
  {
    text: 'Europe/Helsinki',
    value: 'Europe/Helsinki',
  },
  {
    text: 'Europe/Istanbul',
    value: 'Europe/Istanbul',
  },
  {
    text: 'Europe/Kaliningrad',
    value: 'Europe/Kaliningrad',
  },
  {
    text: 'Europe/Kiev',
    value: 'Europe/Kiev',
  },
  {
    text: 'Europe/Minsk',
    value: 'Europe/Minsk',
  },
  {
    text: 'Europe/Riga',
    value: 'Europe/Riga',
  },
  {
    text: 'Europe/Simferopol',
    value: 'Europe/Simferopol',
  },
  {
    text: 'Europe/Sofia',
    value: 'Europe/Sofia',
  },
  {
    text: 'Europe/Tallinn',
    value: 'Europe/Tallinn',
  },
  {
    text: 'Europe/Uzhgorod',
    value: 'Europe/Uzhgorod',
  },
  {
    text: 'Europe/Vilnius',
    value: 'Europe/Vilnius',
  },
  {
    text: 'Europe/Zaporozhye',
    value: 'Europe/Zaporozhye',
  },
  {
    text: 'Africa/Addis_Ababa',
    value: 'Africa/Addis_Ababa',
  },
  {
    text: 'Africa/Asmara',
    value: 'Africa/Asmara',
  },
  {
    text: 'Africa/Dar_es_Salaam',
    value: 'Africa/Dar_es_Salaam',
  },
  {
    text: 'Africa/Djibouti',
    value: 'Africa/Djibouti',
  },
  {
    text: 'Africa/Kampala',
    value: 'Africa/Kampala',
  },
  {
    text: 'Africa/Khartoum',
    value: 'Africa/Khartoum',
  },
  {
    text: 'Africa/Mogadishu',
    value: 'Africa/Mogadishu',
  },
  {
    text: 'Africa/Nairobi',
    value: 'Africa/Nairobi',
  },
  {
    text: 'Antarctica/Syowa',
    value: 'Antarctica/Syowa',
  },
  {
    text: 'Asia/Aden',
    value: 'Asia/Aden',
  },
  {
    text: 'Asia/Baghdad',
    value: 'Asia/Baghdad',
  },
  {
    text: 'Asia/Bahrain',
    value: 'Asia/Bahrain',
  },
  {
    text: 'Asia/Kuwait',
    value: 'Asia/Kuwait',
  },
  {
    text: 'Asia/Qatar',
    value: 'Asia/Qatar',
  },
  {
    text: 'Asia/Riyadh',
    value: 'Asia/Riyadh',
  },
  {
    text: 'Etc/GMT-3',
    value: 'Etc/GMT-3',
  },
  {
    text: 'Europe/Moscow',
    value: 'Europe/Moscow',
  },
  {
    text: 'Europe/Samara',
    value: 'Europe/Samara',
  },
  {
    text: 'Europe/Volgograd',
    value: 'Europe/Volgograd',
  },
  {
    text: 'Indian/Antananarivo',
    value: 'Indian/Antananarivo',
  },
  {
    text: 'Indian/Comoro',
    value: 'Indian/Comoro',
  },
  {
    text: 'Indian/Mayotte',
    value: 'Indian/Mayotte',
  },
  {
    text: 'Asia/Tehran',
    value: 'Asia/Tehran',
  },
  {
    text: 'Asia/Baku',
    value: 'Asia/Baku',
  },
  {
    text: 'Asia/Dubai',
    value: 'Asia/Dubai',
  },
  {
    text: 'Asia/Muscat',
    value: 'Asia/Muscat',
  },
  {
    text: 'Asia/Tbilisi',
    value: 'Asia/Tbilisi',
  },
  {
    text: 'Asia/Yerevan',
    value: 'Asia/Yerevan',
  },
  {
    text: 'Etc/GMT-4',
    value: 'Etc/GMT-4',
  },
  {
    text: 'Indian/Mahe',
    value: 'Indian/Mahe',
  },
  {
    text: 'Indian/Mauritius',
    value: 'Indian/Mauritius',
  },
  {
    text: 'Indian/Reunion',
    value: 'Indian/Reunion',
  },
  {
    text: 'Asia/Kabul',
    value: 'Asia/Kabul',
  },
  {
    text: 'Antarctica/Mawson',
    value: 'Antarctica/Mawson',
  },
  {
    text: 'Asia/Aqtau',
    value: 'Asia/Aqtau',
  },
  {
    text: 'Asia/Aqtobe',
    value: 'Asia/Aqtobe',
  },
  {
    text: 'Asia/Ashgabat',
    value: 'Asia/Ashgabat',
  },
  {
    text: 'Asia/Dushanbe',
    value: 'Asia/Dushanbe',
  },
  {
    text: 'Asia/Karachi',
    value: 'Asia/Karachi',
  },
  {
    text: 'Asia/Oral',
    value: 'Asia/Oral',
  },
  {
    text: 'Asia/Samarkand',
    value: 'Asia/Samarkand',
  },
  {
    text: 'Asia/Tashkent',
    value: 'Asia/Tashkent',
  },
  {
    text: 'Asia/Yekaterinburg',
    value: 'Asia/Yekaterinburg',
  },
  {
    text: 'Etc/GMT-5',
    value: 'Etc/GMT-5',
  },
  {
    text: 'Indian/Kerguelen',
    value: 'Indian/Kerguelen',
  },
  {
    text: 'Indian/Maldives',
    value: 'Indian/Maldives',
  },
  {
    text: 'Asia/Colombo',
    value: 'Asia/Colombo',
  },
  {
    text: 'Asia/Kolkata',
    value: 'Asia/Kolkata',
  },
  {
    text: 'Asia/Kathmandu',
    value: 'Asia/Kathmandu',
  },
  {
    text: 'Antarctica/Vostok',
    value: 'Antarctica/Vostok',
  },
  {
    text: 'Asia/Almaty',
    value: 'Asia/Almaty',
  },
  {
    text: 'Asia/Bishkek',
    value: 'Asia/Bishkek',
  },
  {
    text: 'Asia/Dhaka',
    value: 'Asia/Dhaka',
  },
  {
    text: 'Asia/Novokuznetsk',
    value: 'Asia/Novokuznetsk',
  },
  {
    text: 'Asia/Novosibirsk',
    value: 'Asia/Novosibirsk',
  },
  {
    text: 'Asia/Omsk',
    value: 'Asia/Omsk',
  },
  {
    text: 'Asia/Qyzylorda',
    value: 'Asia/Qyzylorda',
  },
  {
    text: 'Asia/Thimphu',
    value: 'Asia/Thimphu',
  },
  {
    text: 'Etc/GMT-6',
    value: 'Etc/GMT-6',
  },
  {
    text: 'Indian/Chagos',
    value: 'Indian/Chagos',
  },
  {
    text: 'Asia/Rangoon',
    value: 'Asia/Rangoon',
  },
  {
    text: 'Indian/Cocos',
    value: 'Indian/Cocos',
  },
  {
    text: 'Antarctica/Davis',
    value: 'Antarctica/Davis',
  },
  {
    text: 'Asia/Bangkok',
    value: 'Asia/Bangkok',
  },
  {
    text: 'Asia/Ho_Chi_Minh',
    value: 'Asia/Ho_Chi_Minh',
  },
  {
    text: 'Asia/Hovd',
    value: 'Asia/Hovd',
  },
  {
    text: 'Asia/Jakarta',
    value: 'Asia/Jakarta',
  },
  {
    text: 'Asia/Krasnoyarsk',
    value: 'Asia/Krasnoyarsk',
  },
  {
    text: 'Asia/Phnom_Penh',
    value: 'Asia/Phnom_Penh',
  },
  {
    text: 'Asia/Pontianak',
    value: 'Asia/Pontianak',
  },
  {
    text: 'Asia/Vientiane',
    value: 'Asia/Vientiane',
  },
  {
    text: 'Etc/GMT-7',
    value: 'Etc/GMT-7',
  },
  {
    text: 'Indian/Christmas',
    value: 'Indian/Christmas',
  },
  {
    text: 'Antarctica/Casey',
    value: 'Antarctica/Casey',
  },
  {
    text: 'Asia/Brunei',
    value: 'Asia/Brunei',
  },
  {
    text: 'Asia/Choibalsan',
    value: 'Asia/Choibalsan',
  },
  {
    text: 'Asia/Chongqing',
    value: 'Asia/Chongqing',
  },
  {
    text: 'Asia/Harbin',
    value: 'Asia/Harbin',
  },
  {
    text: 'Asia/Hong_Kong',
    value: 'Asia/Hong_Kong',
  },
  {
    text: 'Asia/Irkutsk',
    value: 'Asia/Irkutsk',
  },
  {
    text: 'Asia/Kashgar',
    value: 'Asia/Kashgar',
  },
  {
    text: 'Asia/Kuala_Lumpur',
    value: 'Asia/Kuala_Lumpur',
  },
  {
    text: 'Asia/Kuching',
    value: 'Asia/Kuching',
  },
  {
    text: 'Asia/Macau',
    value: 'Asia/Macau',
  },
  {
    text: 'Asia/Makassar',
    value: 'Asia/Makassar',
  },
  {
    text: 'Asia/Manila',
    value: 'Asia/Manila',
  },
  {
    text: 'Asia/Shanghai',
    value: 'Asia/Shanghai',
  },
  {
    text: 'Asia/Singapore',
    value: 'Asia/Singapore',
  },
  {
    text: 'Asia/Taipei',
    value: 'Asia/Taipei',
  },
  {
    text: 'Asia/Ulaanbaatar',
    value: 'Asia/Ulaanbaatar',
  },
  {
    text: 'Asia/Urumqi',
    value: 'Asia/Urumqi',
  },
  {
    text: 'Australia/Perth',
    value: 'Australia/Perth',
  },
  {
    text: 'Etc/GMT-8',
    value: 'Etc/GMT-8',
  },
  {
    text: 'Australia/Eucla',
    value: 'Australia/Eucla',
  },
  {
    text: 'Asia/Dili',
    value: 'Asia/Dili',
  },
  {
    text: 'Asia/Jayapura',
    value: 'Asia/Jayapura',
  },
  {
    text: 'Asia/Pyongyang',
    value: 'Asia/Pyongyang',
  },
  {
    text: 'Asia/Seoul',
    value: 'Asia/Seoul',
  },
  {
    text: 'Asia/Tokyo',
    value: 'Asia/Tokyo',
  },
  {
    text: 'Asia/Yakutsk',
    value: 'Asia/Yakutsk',
  },
  {
    text: 'Etc/GMT-9',
    value: 'Etc/GMT-9',
  },
  {
    text: 'Pacific/Palau',
    value: 'Pacific/Palau',
  },
  {
    text: 'Australia/Adelaide',
    value: 'Australia/Adelaide',
  },
  {
    text: 'Australia/Broken_Hill',
    value: 'Australia/Broken_Hill',
  },
  {
    text: 'Australia/Darwin',
    value: 'Australia/Darwin',
  },
  {
    text: 'Antarctica/DumontDUrville',
    value: 'Antarctica/DumontDUrville',
  },
  {
    text: 'Asia/Sakhalin',
    value: 'Asia/Sakhalin',
  },
  {
    text: 'Asia/Vladivostok',
    value: 'Asia/Vladivostok',
  },
  {
    text: 'Australia/Brisbane',
    value: 'Australia/Brisbane',
  },
  {
    text: 'Australia/Currie',
    value: 'Australia/Currie',
  },
  {
    text: 'Australia/Hobart',
    value: 'Australia/Hobart',
  },
  {
    text: 'Australia/Lindeman',
    value: 'Australia/Lindeman',
  },
  {
    text: 'Australia/Melbourne',
    value: 'Australia/Melbourne',
  },
  {
    text: 'Australia/Sydney',
    value: 'Australia/Sydney',
  },
  {
    text: 'Etc/GMT-10',
    value: 'Etc/GMT-10',
  },
  {
    text: 'Pacific/Chuuk',
    value: 'Pacific/Chuuk',
  },
  {
    text: 'Pacific/Guam',
    value: 'Pacific/Guam',
  },
  {
    text: 'Pacific/Port_Moresby',
    value: 'Pacific/Port_Moresby',
  },
  {
    text: 'Pacific/Saipan',
    value: 'Pacific/Saipan',
  },
  {
    text: 'Australia/Lord_Howe',
    value: 'Australia/Lord_Howe',
  },
  {
    text: 'Antarctica/Macquarie',
    value: 'Antarctica/Macquarie',
  },
  {
    text: 'Asia/Anadyr',
    value: 'Asia/Anadyr',
  },
  {
    text: 'Asia/Kamchatka',
    value: 'Asia/Kamchatka',
  },
  {
    text: 'Asia/Magadan',
    value: 'Asia/Magadan',
  },
  {
    text: 'Etc/GMT-11',
    value: 'Etc/GMT-11',
  },
  {
    text: 'Pacific/Efate',
    value: 'Pacific/Efate',
  },
  {
    text: 'Pacific/Guadalcanal',
    value: 'Pacific/Guadalcanal',
  },
  {
    text: 'Pacific/Kosrae',
    value: 'Pacific/Kosrae',
  },
  {
    text: 'Pacific/Noumea',
    value: 'Pacific/Noumea',
  },
  {
    text: 'Pacific/Pohnpei',
    value: 'Pacific/Pohnpei',
  },
  {
    text: 'Pacific/Norfolk',
    value: 'Pacific/Norfolk',
  },
  {
    text: 'Antarctica/McMurdo',
    value: 'Antarctica/McMurdo',
  },
  {
    text: 'Etc/GMT-12',
    value: 'Etc/GMT-12',
  },
  {
    text: 'Pacific/Auckland',
    value: 'Pacific/Auckland',
  },
  {
    text: 'Pacific/Fiji',
    value: 'Pacific/Fiji',
  },
  {
    text: 'Pacific/Funafuti',
    value: 'Pacific/Funafuti',
  },
  {
    text: 'Pacific/Kwajalein',
    value: 'Pacific/Kwajalein',
  },
  {
    text: 'Pacific/Majuro',
    value: 'Pacific/Majuro',
  },
  {
    text: 'Pacific/Nauru',
    value: 'Pacific/Nauru',
  },
  {
    text: 'Pacific/Tarawa',
    value: 'Pacific/Tarawa',
  },
  {
    text: 'Pacific/Wake',
    value: 'Pacific/Wake',
  },
  {
    text: 'Pacific/Wallis',
    value: 'Pacific/Wallis',
  },
  {
    text: 'Pacific/Chatham',
    value: 'Pacific/Chatham',
  },
  {
    text: 'Etc/GMT-13',
    value: 'Etc/GMT-13',
  },
  {
    text: 'Pacific/Enderbury',
    value: 'Pacific/Enderbury',
  },
  {
    text: 'Pacific/Tongatapu',
    value: 'Pacific/Tongatapu',
  },
  {
    text: 'Etc/GMT-14',
    value: 'Etc/GMT-14',
  },
  {
    text: 'Pacific/Kiritimati',
    value: 'Pacific/Kiritimati',
  },
];
export const countriesShorted = [
  'Algeria',
  'Angola',
  'Benin',
  'Botswana',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cameroon',
  'Central African Republic',
  'Chad',
  'Comoros',
  'Congo',
  'Côte d’Ivoire',
  'Democratic Republic of the Congo',
  'Djibouti',
  'Egypt',
  'Equatorial Guinea',
  'Eritrea',
  'Eswatini',
  'Ethiopia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Ghana',
  'Guinea',
  'Guinea-Bissau',
  'Kenya',
  'Lesotho',
  'Liberia',
  'Libya',
  'Madagascar',
  'Malawi',
  'Mali',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Niger',
  'Nigeria',
  'Réunion',
  'Rwanda',
  'Saint Helena',
  'Sao Tome and Principe',
  'Senegal',
  'Seychelles',
  'Sierra Leone',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Sudan',
  'Togo',
  'Tunisia',
  'Uganda',
  'Tanzania',
  'Zambia',
  'Zimbabwe',
  'Antarctica',
  'Afghanistan',
  'Armenia',
  'Azerbaijan',
  'Bahrain',
  'Bangladesh',
  'Bhutan',
  'Brunei',
  'Cambodia',
  'China',
  'Cyprus',
  'North Korea',
  'Georgia',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Israel',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Lebanon',
  'Malaysia',
  'Maldives',
  'Mongolia',
  'Myanmar',
  'Nepal',
  'Oman',
  'Pakistan',
  'Philippines',
  'Qatar',
  'South Korea',
  'Saudi Arabia',
  'Singapore',
  'Sri Lanka',
  'Palestine',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Thailand',
  'Timor-Leste',
  'Turkey',
  'Turkmenistan',
  'United Arab Emirates',
  'Uzbekistan',
  'Vietnam',
  'Yemen',
  'Albania',
  'Andorra',
  'Austria',
  'Belarus',
  'Belgium',
  'Bosnia and Herzegovina',
  'Bulgaria',
  'Croatia',
  'Czechia',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Gibraltar',
  'Greece',
  'Hungary',
  'Iceland',
  'Ireland',
  'Italy',
  'Latvia',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Monaco',
  'Montenegro',
  'Netherlands',
  'North Macedonia',
  'Norway',
  'Poland',
  'Portugal',
  'Moldova',
  'Romania',
  'Russia',
  'San Marino',
  'Serbia',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland',
  'Ukraine',
  'United Kingdom',
  'Antigua and Barbuda',
  'Aruba',
  'Bahamas',
  'Barbados',
  'Belize',
  'Bermuda',
  'Canada',
  'Costa Rica',
  'Cuba',
  'Curaçao',
  'Dominica',
  'Dominican Republic',
  'El Salvador',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guatemala',
  'Haiti',
  'Honduras',
  'Jamaica',
  'Martinique',
  'Mexico',
  'Nicaragua',
  'Panama',
  'Puerto Rico',
  'Saint Barthélemy',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Sint Maarten',
  'Trinidad and Tobago',
  'United States',
  'Australia',
  'Cook Islands',
  'Fiji',
  'French Polynesia',
  'Guam',
  'Kiribati',
  'Marshall Islands',
  'Federated States of Micronesia',
  'Nauru',
  'New Zealand',
  'Niue',
  'Palau',
  'Papua New Guinea',
  'Samoa',
  'Solomon Islands',
  'Tonga',
  'Tuvalu',
  'Vanuatu',
  'Argentina',
  'Bolivia',
  'Brazil',
  'Chile',
  'Colombia',
  'Ecuador',
  'French Guiana',
  'Guyana',
  'Paraguay',
  'Peru',
  'Suriname',
  'Uruguay',
  'Venezuela',
];
export const countriesShortedOceania = [
  'Australia',
  'Cook Islands',
  'Federated States of Micronesia',
  'Fiji',
  'French Polynesia',
  'Kiribati',
  'Marshall Islands',
  'Nauru',
  'New Zealand',
  'Niue',
  'Palau',
  'Papua New Guinea',
  'Samoa',
  'Solomon Islands',
  'Tonga',
  'Tuvalu',
  'Vanuatu',
];
export const countriesShortedAsia = [
  'Afghanistan',
  'Armenia',
  'Azerbaijan',
  'Bahrain',
  'Bangladesh',
  'Bhutan',
  'Brunei',
  'Cambodia',
  'China',
  'Cyprus',
  'Georgia',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Israel',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Lebanon',
  'Malaysia',
  'Maldives',
  'Mongolia',
  'Myanmar',
  'Nepal',
  'North Korea',
  'Oman',
  'Pakistan',
  'Palestine',
  'Philippines',
  'Qatar',
  'Saudi Arabia',
  'Singapore',
  'South Korea',
  'Sri Lanka',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Thailand',
  'Timor-Leste',
  'Turkey',
  'Turkmenistan',
  'United Arab Emirates',
  'Uzbekistan',
  'Vietnam',
  'Yemen',
];
export const countriesShortedAfrica = [
  'Algeria',
  'Angola',
  'Benin',
  'Botswana',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cameroon',
  'Central African Republic',
  'Chad',
  'Comoros',
  'Congo',
  'Côte d’Ivoire',
  'Democratic Republic of the Congo',
  'Djibouti',
  'Egypt',
  'Equatorial Guinea',
  'Eritrea',
  'Eswatini',
  'Ethiopia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Ghana',
  'Guinea',
  'Guinea-Bissau',
  'Kenya',
  'Lesotho',
  'Liberia',
  'Libya',
  'Madagascar',
  'Malawi',
  'Mali',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Niger',
  'Nigeria',
  'Réunion',
  'Rwanda',
  'Saint Helena',
  'Sao Tome and Principe',
  'Senegal',
  'Seychelles',
  'Sierra Leone',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Sudan',
  'Tanzania',
  'Togo',
  'Tunisia',
  'Uganda',
  'Zambia',
  'Zimbabwe',
];
export const countriesShortedEurope = [
  'Albania',
  'Andorra',
  'Austria',
  'Belarus',
  'Belgium',
  'Bosnia and Herzegovina',
  'Bulgaria',
  'Croatia',
  'Czechia',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Gibraltar',
  'Greece',
  'Hungary',
  'Iceland',
  'Ireland',
  'Italy',
  'Latvia',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Moldova',
  'Monaco',
  'Montenegro',
  'Netherlands',
  'North Macedonia',
  'Norway',
  'Poland',
  'Portugal',
  'Romania',
  'Russia',
  'San Marino',
  'Serbia',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland',
  'Ukraine',
  'United Kingdom',
];
export const countriesShortedNorthAmerica = [
  'Antigua and Barbuda',
  'Aruba',
  'Bahamas',
  'Barbados',
  'Belize',
  'Bermuda',
  'Canada',
  'Costa Rica',
  'Cuba',
  'Curaçao',
  'Dominica',
  'Dominican Republic',
  'El Salvador',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guatemala',
  'Haiti',
  'Honduras',
  'Jamaica',
  'Martinique',
  'Mexico',
  'Nicaragua',
  'Panama',
  'Puerto Rico',
  'Saint Barthélemy',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Sint Maarten',
  'Trinidad and Tobago',
  'United States',
];
export const countriesShortedSouthAmerica = [
  'Argentina',
  'Bolivia',
  'Brazil',
  'Chile',
  'Colombia',
  'Ecuador',
  'French Guiana',
  'Guyana',
  'Paraguay',
  'Peru',
  'Suriname',
  'Uruguay',
  'Venezuela',
];
export const countriesShortedAntarctica = ['Antarctica'];
export const dataTypeShorted = [
  'Futures',
  'Options',
  'Spot',
  'Forecast',
];
export const dataTypeOptions = [
  {
    text: 'Futures',
    value: 'Futures',
  },
  {
    text: 'Options',
    value: 'Options',
  },
  {
    text: 'Spot',
    value: 'Spot',
  },
  {
    text: 'Forecast',
    value: 'Forecast',
  }];

<template>
  <div>
    <not-entitled-user v-if="showPageError" />
    <div v-else>
      <mds-layout-grid class="layout-grid">
        <div class="mds-header">
          <h1 class="mds-header__title alignment component-title">
            {{ $t('labels.dashboard.name') }}
          </h1>
        </div>
        <mds-smart-row>
          <dashboard-chart-widget
            :id="'chartCard'"
            slot="firstCard"
            :dark-mode="darkMode"
          />
        </mds-smart-row>
      </mds-layout-grid>
      <addModalChart
        :handle-cancel="handleCancel"
        @handleAddChart="handleAddChart"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MdsLayoutGrid } from '@mds/layout-grid';
import { FEATURES } from '@/utils/constants.js';
import MdsSmartRow from './MdsSmartRow';
import AddModalChart from './Modals/AddWorkspaceModal';
import DashboardChartWidget from './Widgets/ChartWidget/DashboardChartWidget';
import NotEntitledUser from '../Errors/NotEntitledUser';

export default {
  name: 'DashboardHome',
  components: {
    NotEntitledUser,
    DashboardChartWidget,
    MdsLayoutGrid,
    MdsSmartRow,
    AddModalChart,
  },
  data() {
    return {
      charts: [{
        name: 'chart1',
        symbol: 'sym1',
      }, {
        name: 'chart2',
        symbol: 'sym2',
      }],
      showPageError: false,
    };
  },
  computed: {
    ...mapGetters({
      darkMode: 'homeModule/getTheme',
    }),
  },
  created() {
    if ((this.isUserEntitled && !this.isUserEntitled([
      FEATURES.FEATURE_DASHBOARD_BETA,
      FEATURES.FEATURE_DASHBOARD_ALPHA,
      FEATURES.FEATURE_DASHBOARD_PROD]))) {
      this.showPageError = true;
      this.pageError = this.$t('labels.common.featureNotEntitled');
    }
  },
  methods: {
    handleAddChart(obj) {
      this.charts.push({
        name: obj.name,
        symbol: obj.symbol,
      });
    },
    handleRemoveChart(index) {
      this.charts.splice(index, 1);
    },
    confirmModal(obj) {
      this.charts.push({
        name: obj.name,
        symbol: obj.symbol,
      });
    },
    handleCancel() {
      this.$refs.addChartBtn.$el.click();
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/assets/styles/components/custom/dashboard';
</style>

// https://msstash.morningstar.com/projects/FP/repos/cwp-vue-components/browse/packages/cwp-dev-shared/helpers/dom.js

export function getNamespace(ns = 'PFM') {
  if (typeof ns !== 'string') throw new Error('Namespace should be a string');

  window.mwc[ns] = window.mwc[ns] || new Map();
  const namespace = window.mwc[ns];

  return namespace;
}

/**
 * Verifies that an item is a valid HTML Element
 * @param {*} element The element to validate
 * @returns {boolean} Is the element a valid HTML Element?
 */
export function isValidElement(element) {
  return typeof element === 'object'
    && element !== null
    && (element instanceof HTMLElement || element.nodeType === 1);
}

/**
 * Loads a dependency and stores in namespace cache for later use
 * @param {string} dependencyName Dependency Key
 * @param {function} promiseFn Function that returns a promise
 * @param {object} options
 * @param {string} options.namespace Custom namespace object or string
 * @param {boolean} options.deferLoading Store promise function to resolve when called again later
 */
export async function loadDependency(dependencyName, promiseFn, {
  namespace,
  deferLoading = false,
} = {}) {
  const ns = namespace ? getNamespace(namespace) : getNamespace();

  if (ns.has(dependencyName)) {
    const dependency = ns.get(dependencyName);

    if (deferLoading) return dependency;
    if (typeof dependency === 'function' && dependency.deferredResolve) {
      ns.set(dependencyName, await dependency());
    }

    return ns.get(dependencyName);
  }

  if (deferLoading) {
    promiseFn.deferredResolve = true; // eslint-disable-line no-param-reassign
    return ns.set(dependencyName, promiseFn);
  }

  ns.set(dependencyName, await promiseFn());

  return ns.get(dependencyName);
}

export async function loadCustomElement(tagName, importPromise) {
  const element = window.customElements.get(tagName);

  if (window.customElements.get(tagName)) {
    return element;
  }

  return loadDependency(
    `components.${tagName}`,
    importPromise,
  );
}

export default {
  getNamespace,
  loadDependency,
  loadCustomElement,
};

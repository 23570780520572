<template>
  <div>
    <mds-table v-if="operation !== 'create'">
      <mds-thead hidden-header>
        <mds-th />
      </mds-thead>
      <mds-tbody>
        <div
          v-for="(row, index) in rows"
          :key="index"
        >
          <mds-tr
            type="expandable"
            :expanded="row.expanded"
            :aria-controls="index+1"
            @click.native="toggleRow(index)"
            @keyup.enter.native="toggleRow(index)"
          >
            <mds-td>
              <h3 style="display: inline;">
                {{ headers[index].text }}
              </h3>
            </mds-td>
          </mds-tr>
          <mds-tr
            v-if="row.expanded"
            :id="index+1"
            type="container"
          >
            <mds-td>
              <pre>
                <span
                  style="font-size: medium;"
                  v-html="row[headers[index].fieldName]"
                />
              </pre>
            </mds-td>
          </mds-tr>
        </div>
      </mds-tbody>
    </mds-table>
    <div
      v-else
      style="margin-top: 2%;"
    >
      <mds-empty-state
        title="Nothing to show"
        message=""
        size="large"
        style="margin: auto;"
      />
    </div>
  </div>
</template>

<script>
import MdsEmptyState from '@mds/empty-state';
import { mapGetters } from 'vuex';
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';

export default {
  name: 'WorkflowDebug',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsEmptyState,
  },
  filters: {
    pretty(value) {
      if (value) {
        return JSON.stringify(value, null, 2);
      }
      return '';
    },
  },
  props: {
    parameterSetData: {
      type: Object,
      default: null,
    },
    formulas: {
      type: Array,
      default: null,
    },
    workflowConfigData: {
      type: Object,
      default: null,
    },
    operation: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      headers: [],
      rows: [],
      errorMessage: 'Needs a maximum duration. <br /><br /> No Publish, no Notification: this workflow does nothing.',
      manager: {},
      coordMap: {},
    };
  },
  computed: {
    ...mapGetters('workflowModule', ['getCurrentWorkflowManager', 'getEditableWorkflowDetails']),
    formatedParameterSetData() {
      return JSON.stringify(this.parameterSetData, null, 4);
    },
  },
  mounted() {
    this.manager = this.getCurrentWorkflowManager;
    this.getEditableWorkflowDetails.workflowUI.ui.bubbles.forEach((b) => {
      this.coordMap[b.id] = b.coord;
    });

    const debugData = this.manager.getDebugPayloads(this);
    debugData.split(/^\s*=+$/gm).filter(s => s.trim().length > 0).forEach((section) => {
      const lines = section.split('\n').filter(s => s.trim().length > 0);
      this.headers.push({ text: lines[0], fieldName: 'data' });
      this.rows.push({ data: section.replace(lines[0], '') });
    });
  },
  methods: {
    toggleRow(index) {
      const status = this.rows[index].expanded ? !this.rows[index].expanded : true;
      this.$set(this.rows[index], 'expanded', status);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@mds/typography';
  @import '@mds/constants';

  h3 {
      @include mds-level-5-heading();

      color: $mds-text-color-primary;
  }

</style>

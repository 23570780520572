
import { EnumBase, EnumItem } from '../enums.es6';

/**
 * Marketplace field type.
 * @enum {Type}
 * @readonly
 */
const Type = EnumBase.finalize({
  VALUE: new EnumItem('VALUE', 'v'),
  KEY: new EnumItem('KEY', 'k'),
  DATA: new EnumItem('DATA', 'd'),
  UNKNOWN: new EnumItem('UNKNOWN', 'unknown'),
}, 'MpField.Type');

export default Type;

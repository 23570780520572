/* eslint-disable no-use-before-define */

import { requireNonEmptyString } from '../strings.es6';

let _canConstruct = false;

/**
 * @param {Object.<string, {name: string}>} db
 * @returns {MpUserFeatureSet} New feature-set.
 * @private
 */
function _newFeatureSet(db) {
  _canConstruct = true;
  return new MpUserFeatureSet(db);
}

class MpUserFeatureSet {
  /** @param {Object.<string, {name: string}>} db */
  constructor(db) {
    if (!_canConstruct) {
      throw new Error('Private constructor, access denied');
    }
    _canConstruct = false;
    this._db = Object.freeze(db);
    Object.freeze(this);
  }

    static EMPTY = _newFeatureSet({});

    /**
     * @param {Array.<{name: string}>} resp LDS response to `GET /lds/users/{USER}/features`.
     * @returns {MpUserFeatureSet} Marketplace user-feature set.
     */
    static fromMpResponse(resp) {
      if (!Array.isArray(resp)) {
        throw new TypeError('resp: Array');
      }
      const db = resp.reduce((db, feature) => {
        const name = requireNonEmptyString(feature.name, 'feature.name');
        // noinspection JSCheckFunctionSignatures
        db[name] = Object.freeze(feature);
        return db;
      }, {});
      return _newFeatureSet(db);
    }

    /**
     * @param {string} featureName
     * @returns {boolean} Whether this feature-set contains the named feature.
     */
    contains(featureName) {
      return this._db.hasOwnProperty(requireNonEmptyString(featureName, 'featureName'));
    }

    /** @returns {int} Number of features the current user has access to, may be none (0). */
    count() {
      return Object.keys(this._db).length;
    }
}

export default MpUserFeatureSet;

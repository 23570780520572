import Cookies from 'js-cookie';
import axios from 'axios';
import getAPIConfigs from './config.js';
import templateString from '../../../utils/stringUtils';

const config = getAPIConfigs();

const actions = () => ({
  routerChanged: {
    handler({ commit }, payload) {
      commit('setRouterChange', payload);
    },
  },
  fetchUserInfo: {
    handler({ commit }) {
      const userInfoVue = Cookies.get('userInfoVue');
      commit('setUserInfo', userInfoVue);
    },
  },
  setTheme: {
    handler({ commit, payload }) {
      commit('setTheme', payload);
    },
  },
  getUserNotifications({ commit, state }) {
    const payload = {
      userName: state.userName,
      companyName: state.company,
      notifSettings: state.notifSettings,
      notificationsKeys: state.notificationsKeys,
    };
    return axios.post(templateString(config.API_USER_NOTIFICATIONS), payload)
      .then((response) => {
        commit('SET_USER_NOTIFS', response.data);
        return response;
      })
      .catch((errors) => {
        console.log('user notifications', errors);
      });
  },
  setNotifsRead({ commit, state }) {
    const replacement = {
      USER_NAME: state.userName,
    };
    return axios.put(templateString(config.API_NOTIFICATIONS_READ, replacement))
      .then((response) => {
        commit('MARK_NOTIFS_READ');
        return response;
      })
      .catch((errors) => {
        console.log('user notifications last read update', errors);
      });
  },
  getUserGlobalSettings({ commit, state }) {
    const replacement = {
      USER_NAME: state.userName,
    };
    return axios.get(templateString(config.API_GET_GLOBAL_SETTINGS, replacement))
      .then((response) => {
        commit('setGlobalSettings', response.data);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
  setUserGlobalSettings({ commit, state }, payload) {
    const data = {
      userName: state.userName,
      keyId: payload.keyId,
      subKeyId: payload.subKeyId,
      action: payload.action,
      isEmailDataAlert: payload.isEmailDataAlert,
      cronExpression: payload.cronExpression,
    };
    return axios.put(templateString(config.API_SET_GLOBAL_SETTINGS), data)
      .then((response) => {
        commit('updateGlobalSettings', data);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
});
export default actions;

/* eslint no-param-reassign: 0 */
import setMethods from './methods';
import { getNamespace } from '../../helpers/dom';

/**
 * Vue plugin wrapper around the mwc core
 *  @module mwc-base-helper
 * 
 */
export default {
  install(Vue) {
    // cache all MWC root components
    if (!Vue.$mwc__cache) {
      Vue.$mwc__cache = {};
    }

    function _registerHelpers(config) {
      const ns = getNamespace();
      const { id, helpers } = config;

      Object.keys(helpers).forEach(helperKey => {
        ns.set(`${id}.${helperKey}`, helpers[helperKey]);
      });
    }
    // called in each component that wants mwc functionality in its created hook

    /**
     * called in each mwc component (anything that will be a custom element)
     * @memberof mwc-base-helper
     * @param {function} vm the vue instance
     */
    Vue.prototype.$mwcRegister = vm => {
      if (vm.$_mwcHelpers) {
        _registerHelpers(vm.$_mwcHelpers);
      }
      vm.$mwc = {};
      vm.$mwc.logger =
        window.mwc && window.mwc.logger
          ? window.mwc.logger
          : window.console.log;

      setMethods(vm);

      const cache = Vue.$mwc__cache[vm.mwcId];

      // if cached root component exists...
      if (cache) {
        const { labels, settings } = vm.$mwc.getComponentConfig();
        vm.$mwc.labeller = cache.labeller;
        vm.$mwc.formatter = cache.formatter;
        vm.$mwc.settings = settings;
        vm.$mwc.labeller.initLabels(labels);

        if (settings.languageId || !vm.$mwc.labeller.languageId) {
          vm.$mwc.labeller.updateLanguage(settings.languageId);
          vm.$mwc.formatter.languageId = settings.languageId;
        }

        if (settings.currencyId || !vm.$mwc.labeller.currencyCode) {
          vm.$mwc.formatter.currencyCode = settings.currencyId;
        }

        if (settings.formats) {
          vm.$mwc.formatter.formats = Object.assign(
            vm.$mwc.formatter.formats,
            settings.formats
          );
        }
      } else {
        const { mwc } = window;

        if (!mwc) {
          window.console.warn(
            'Morningstar Web Components requires instantiation ' +
              'of helpers on the window.mwc namespace.'
          );
          return;
        }

        // if this component is in a mix-use env and has no relation to MWC
        if (!vm.mwcId) {
          const localSettings =
            vm.localConfig && vm.localConfig.settings
              ? vm.localConfig.settings
              : {};
          const localNamespace =
            vm.localConfig && vm.localConfig.intlNamespace
              ? vm.localConfig.intlNamespace
              : vm.$options._componentTag || vm._uid;
          const localLabels =
            vm.localConfig && vm.localConfig.labels
              ? vm.localConfig.labels
              : {};
          const localLangId = localSettings.languageId
            ? localSettings.languageId
            : 'en';

          const FormatterClass = mwc.Formatter;
          vm.$mwc.formatter = new FormatterClass(
            localLangId,
            localSettings.format,
            localSettings.currencyId || 'USD'
          );

          const LabellerClass = mwc.Labeller;
          vm.$mwc.labeller = new LabellerClass(
            localLangId,
            localLabels,
            localNamespace
          );

          return;
        }

        const componentConfig = vm.$mwc.getComponentConfig();
        const { settings } = componentConfig;
        vm.$mwc.settings = settings || {};

        const languageId = vm.$mwc.settings
          ? vm.$mwc.settings.languageId
          : null;
        const FormatterClass = mwc.Formatter;
        vm.$mwc.formatter = new FormatterClass(
          languageId,
          componentConfig.format
        );

        const LabellerClass = mwc.Labeller;
        const intlNamespace = componentConfig.intlNamespace || vm.mwcId;

        vm.$mwc.labeller = new LabellerClass(
          languageId,
          componentConfig.labels,
          intlNamespace
        );

        // cache the root component
        Vue.$mwc__cache[vm.mwcId] = {
          formatter: vm.$mwc.formatter,
          labeller: vm.$mwc.labeller,
        };
      }
    };
  },
};

<template>
  <div class="prev-res-table">
    <mds-table
      v-if="formulaPreviewData"
      row-hover
    >
      <mds-thead>
        <mds-th class="formula-prev-result">
          Date
        </mds-th>
        <mds-th
          v-for="(_, index) in formulaPreviewData[0].slice(1)"
          :key="index"
          class="formula-prev-result"
          style="text-align: center;"
        >
          {{ index }}
        </mds-th>
      </mds-thead>
      <mds-tbody>
        <mds-tr
          v-for="(dataRow, index) in formulaPreviewData"
          :key="index"
        >
          <mds-td
            v-for="(data, dIndex) in dataRow"
            :key="dIndex"
            style="text-align: center;"
            :class="getClassesForPreviewData(data)"
          >
            {{ data }}
          </mds-td>
        </mds-tr>
        <mds-tr v-if="formulaPreviewDataOccurences">
          <mds-td>
            <b>{{ formulaPreviewDataOccurences }}</b> Occurences
          </mds-td>
        </mds-tr>
      </mds-tbody>
    </mds-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import { generatePreviewResultsData, generateSummaryDataForPreviewResults } from '../scripts/PreviewResultsUtility';

export default {
  name: 'WorkflowPreviewResults',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
  },
  props: {
    previewDataResponse: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formulaPreviewData: [],
      formulaPreviewDataOccurences: 0,
    };
  },
  computed: {
    ...mapGetters('workflowModule', ['getUserWorkflows']),
  },
  mounted() {
    if (this.previewDataResponse.split('\n').length > 0) {
      const formulaPreviewRawData = this.previewDataResponse.split('\n')
        .map(line => line.split(','))
        .filter(line => line[0])
        .map(dataRow => [this.prettifyDate(dataRow[0]), ...dataRow.slice(1)]);

      this.formulaPreviewData = formulaPreviewRawData;

      const previewResultsSummaryData = generateSummaryDataForPreviewResults(
        generatePreviewResultsData(this.previewDataResponse).valuesColumn,
      );

      this.formulaPreviewDataOccurences = this.formulaPreviewData.length;
      this.formulaPreviewData.push(...previewResultsSummaryData);
    }
  },
  methods: {
    ...mapActions('workflowModule', ['searchWorkflows', 'updateUserWorkflows']),
    formatDate(dateString) {
      const date = dateString.split('T')[0].split('-');
      return `${date[1]}/${+date[2]}/${date[0]}`;
    },
    isDate(date) {
      return (new Date(date) !== 'Invalid Date') && !isNaN(new Date(date));
    },
    getClassesForPreviewData(data) {
      return [
        'incons',
        // !data.includes(',') && isFinite(data) && 'green',
        // (data.includes(',') || (data.includes('-') || data.includes('/'))) && 'blue',
        // !data.includes(',') && data === 'NaN' && 'red',
        [
          'Max', 'Min',
          'Avg', 'AvgPos', 'AvgNeg',
          'PctPos', 'PctNeg',
          'Variance', 'StdDev', 'ZStat',
        ].includes(data) && 'blue',
      ]
        .filter(c => c)
        .join(' ');
    },
    prettifyDate(dateString) {
      if (!dateString) return dateString;
      if (!dateString.includes('T')) return dateString;
      const [rawDate, rawTime] = dateString.split('T');
      const date = rawDate.split('-');
      const time = rawTime.split('-')[0] || rawTime.split('+')[0];
      return `${date[1]}/${date[2]}/${date[0]}, ${time}`;
    },
  },
};
</script>

<style>

</style>

import Url from '../url.es6';

/** @namespace */
const MpFileUtils = Object.freeze(/** @lends {MpFileUtils} */ {

  /** @returns {string} URL used to retrieve a file, without query parameter. */
  getBasePath() {
    return `${Url.rootPath(1) }func/files`;
  },
});

export default MpFileUtils;
